import React, {useContext, useEffect, useState} from "react";
import UserListContext from "../../../context/list/UserListContext";
import NewsItem from "../../../model/news/NewsItem";
import ScrollPaginationContext from "../../../context/ScrollPaginationContext";
import axios from "axios";
import {socialHostName} from "../../../utils/Configuration";
import {Alert} from "react-bootstrap";
import translate from "../../../i18n/translate";
import NewsFeed from "../../news/NewsFeed";
import IqLoadingIcon from "../../common/IqLoadingIcon";

const UserListNews: React.FC = () => {

    const {list, setList} = useContext(UserListContext);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [news, setNews] = useState<NewsItem[]>([]);

    const {page, setPage} = useContext(ScrollPaginationContext);

    useEffect(() => {
        const fetchNews = async () => {
            setLoading(true);

            await axios.post(socialHostName + "news/organisations", list.organisations, {
                    params: {
                        page: page,
                        size: 18
                    }
                })
                .then(r => {
                    if (page === 0) {
                        setNews([...r.data]);
                    }
                    else {
                        setNews((previousNews: NewsItem[]) => {
                            return [...previousNews, ...r.data];
                        });
                    }
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));

        };

        if (list && list.organisations?.length > 0) {
            fetchNews();
        }

        return () => {
            setPage(0);
        }
    }, [list, page]);

    return (
        <div style={{maxHeight: "100%", width: "100%", overflowY: "scroll", overflowX: "hidden", marginLeft: 0, marginRight: 0, marginTop: 15 }}>
            {error ? (
                <Alert variant="danger">
                    {translate("errors.loading")}
                </Alert>
            ) : (
                <>
                    <NewsFeed news={news}/>
                    {loading && <IqLoadingIcon />}
                </>
            )}
        </div>
    );
};

export default UserListNews;