import React, {ChangeEvent, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Alert, Button, Form, InputGroup, Spinner} from "react-bootstrap";

import './LoginForm.css';
import axios from 'axios';
import {organisationHostname} from '../../utils/Configuration';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight, faUser} from '@fortawesome/free-solid-svg-icons';
import translate from "../../i18n/translate";


const ForgotPasswordForm: React.FC = () => {

    const [mail, setMail] = useState("");
    const [success, setSuccess] = useState<boolean | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const history = useHistory();

    const LoginLogo = () => {
        return (
            <div id="login-logo">
                <img src="/logo-large.png" alt="logo" />
            </div>
        );
    };

    const recover = (e: React.MouseEvent) => {
        e.preventDefault();
        setLoading(true);
        axios.post(organisationHostname + 'users/account/reset-password/init', mail,
            {
                headers: {
                    'Content-Type': 'text/plain',
                }
            })
            .then(() => setSuccess(true))
            .catch(() => {
                setError(true);
            })
            .finally(() => setLoading(false));
    };

    return (
        <div id="login-container">
            <div id="login-form-container">
                <LoginLogo />
                <div id="login-form-header">
                    <div className="title">{translate("login.recoverpassword")}</div>
                    <div className="description">{translate("login.enteremail")}</div>
                </div>
                <Form id="login-form">
                    <Form.Group controlId="formUserName" className="mb-3">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faUser} color="#3177c8" size="1x" /></InputGroup.Text>
                            <Form.Control name="mail" type="text" className="login-input" placeholder="E-mail"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => { setMail(e.target.value) }} />
                        </InputGroup>
                    </Form.Group>
                    {loading &&
                        <div style={{ display: "flex", flexFlow: "center" }}><Spinner style={{ marginLeft: "auto", marginRight: "auto" }} animation="border" variant="primary" /></div>
                    }
                    {error && (
                        <Alert variant="danger">
                            {translate("errors.generic")}
                        </Alert>
                    )}
                    {success && (
                        <Alert variant="success">
                            {translate("login.emailsent")}
                        </Alert>
                    )}
                    <span onClick={() => history.push("/login")} className="forgot-password">{translate("login.backtologin")}<FontAwesomeIcon style={{ marginLeft: 5 }} icon={faChevronRight} color="#3177c8" size="1x" /></span>
                    {success ?
                        null
                        :
                        <Button style={{ marginTop: 35 }} variant="primary" onClick={(e: React.MouseEvent<Element, MouseEvent>) => { recover(e) }}>{translate("login.recoverpassword")}</Button>
                    }
                </Form>
            </div>
        </div >
    );
};

export default ForgotPasswordForm;
