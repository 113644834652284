import React, {useContext, useEffect, useState} from "react";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {contractsHostName} from "../../../utils/Configuration";
import axios from "axios";
import {Alert, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import DateFormatter from "../../../utils/formatters/DateFormatter";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import translate from "../../../i18n/translate";
import "./PublicSectorContracts.css";

import {faFilter, faSort, faSortAmountDown, faSortAmountDownAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ContractWrapperDto from "../../../model/contracts/ContractWrapperDto";
import Pagination from "../../../utils/PaginationHook";
import {
    CONTRACT_VALUE_OPTIONS,
    CPVHierarchy,
    NewSelectedFilters,
    SHOW_PARENT,
    treeDFSPreorderTraverse,
    treeDFSSearchForNodeValue
} from "./ContractFilters";
import {Switch, TreeSelect} from "antd";
import FollowContractButtonFake from "./FollowContractButtonFake";
import ContractSearchDto from "../../../model/contracts/ContractSearchDto";
import OrganisationContext from "../../../context/organisation/OrganisationContext";
import {SelectOption} from "../../news/GlobalNews";
import {format} from "d3-format";
import Select from "react-select";
import ContractSuppliers from "./ContractSuppliers";

interface Props {
    achieved?: boolean
    searchFilters?: NewSelectedFilters
    passedSearch?: boolean
}

enum Sort {
    TITLE = "content.tender.title.keyword",
    ORGANISATION = "content.buyer.name.keyword",
    START_DATE = "content.tender.contractPeriod.startDate",
    END_DATE = "content.tender.contractPeriod.endDate",
    AWARDED = "content.tender.datePublished",
    VALUE = "content.tender.value.amount",
    STATUS = "content.tender.status.keyword"
}

const ContractsSearch: React.FC<Props> = (props: Props) => {
    const location = useLocation();
    const organisationContractContext = useContext(OrganisationContext);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [sort, setSort] = useState<Sort>(Sort.END_DATE);
    const [desc, setDesc] = useState<boolean>(false);

    const [foundContracts, setFoundContracts] = useState<ContractWrapperDto>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    const [numberOfSuppliersLimitBeforeCounter] = useState<number>(2);

    const [currentDate] = useState<string>(new Date().toISOString().slice(0,10));
    const [hideExpired, setHideExpired] = useState<boolean>(true);

    const [followedContractsList, setFollowedContractsList] = useState<String[]>([]);
    const [followedContractsLoading, setFollowedContractsLoading] = useState<boolean>(false);

    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [filters, setFilters] = useState<NewSelectedFilters>({} as NewSelectedFilters);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [minValue, setMinValue] = useState<number | undefined>(undefined);
    const [maxValue, setMaxValue] = useState<number | undefined>(undefined);

    const [supplierFilters, setSupplierFilters] = useState<string[]>([]);
    const updateSupplierFilters = (selection: SelectOption[]) => {
        setSupplierFilters(selection?.map((option: SelectOption) => option.value));
    };

    const [supplierOptions, setSupplierOptions] = useState<SelectOption[]>([]);
    const [loadingSuppliers, setLoadingSuppliers] = useState<boolean>(false);
    const [supplierError, setSupplierError] = useState<boolean>(false);
    useEffect(() => {
        const fetchSupplierFilters = async () => {
            setLoadingSuppliers(true);
            await axios.get(contractsHostName + "elastic/suppliers")
                .then((r) =>
                    setSupplierOptions(
                        r.data.map((option: string) => {
                            return {
                                value: option,
                                label: option
                            }
                        })
                    )
                )
                .catch(() => setSupplierError(true))
                .finally(() => setLoadingSuppliers(false))
        };

        fetchSupplierFilters();
    }, []);

    // Fetch Cpv Code Hierarchy
    const [cpvHierarchyOptions, setCpvHierarchyOptions] = useState<CPVHierarchy[]>([]);
    const [loadingCpvCodesHierarchy, setLoadingCpvCodesHierarchy] = useState<boolean>(false);
    const [cpvHierarchyError, setCpvHierarchyError] = useState<boolean>(false);
    useEffect(() => {
        const fetchCpvCodeHierarchyFilters = async () => {
            setLoadingCpvCodesHierarchy(true);
            await axios.get(contractsHostName + "elastic/cpvcodehierarchy")
                .then(r =>
                    setCpvHierarchyOptions(
                        r.data.map((cpv: CPVHierarchy) => {
                            return {
                                key: cpv.key,
                                value: cpv.value,
                                title: cpv.title,
                                children: cpv.children
                            }
                        })
                    )
                )
                .catch(() => setCpvHierarchyError(true))
                .finally(() => setLoadingCpvCodesHierarchy(false))
        };

        fetchCpvCodeHierarchyFilters();
    }, []);

    const [cpvFilters, setCpvFilters] = useState<string[]>([]);
    const searchFilterTreeNode= (search: string, item: any) => {
        return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
    }

    const onChange = (newValue: string[]) => {
        let arrOfNodes: CPVHierarchy[] = [];

        // Loop through values in onChange, then push the node to array
        for (let val of newValue) {
            let returnVal = findCpvTreeNodeFromCode(val);
            if (returnVal) {
                arrOfNodes.push(returnVal);
            }
        }

        // Loop through nodes and gather their DFS results in a new array
        let arrOfValuesToAdd: string[] = [];
        for (let node of arrOfNodes) {
            arrOfValuesToAdd = treeDFSPreorderTraverse(node, arrOfValuesToAdd);
        }

        setCpvFilters(arrOfValuesToAdd);
    };

    const findCpvTreeNodeFromCode = (inputCpvCodeValueOfNode: string) => {

        if (inputCpvCodeValueOfNode === null || cpvHierarchyOptions === null) {
            return null;
        }

        let treeNode: CPVHierarchy | null = null;
        for (const topLevelNode of cpvHierarchyOptions) {
            // Find node in CPV Hierarchy
            treeNode = treeDFSSearchForNodeValue(topLevelNode, inputCpvCodeValueOfNode);
            if (treeNode !== null) {
                break
            }
        }

        if (treeNode === undefined) {
            return null;
        }

        return treeNode;
    }

    const tProps = {
        treeData: cpvHierarchyOptions,
        value: cpvFilters,
        onChange: onChange,
        showSearch: true,
        autoClearSearchValue: true,
        allowClear: true,
        treeCheckable: true,
        bordered: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: 'CPV Codes',
        style: {
            width: '100%',
            height: '100%',
            fontSize: '16px',
            lineHeight: 2,
            borderRadius: '4px'
        },
        filterTreeNode: searchFilterTreeNode
    };

    const followedContractCheckUrl: string = contractsHostName + "mysql/follow/contracts/follow-check";
    let savedSearchDTOUrl = "";
    let passedSearchFilterFlag = false;

    if (props.searchFilters || location.pathname.includes("organisation")) {
        savedSearchDTOUrl = contractsHostName + "elastic/search/dto"
        passedSearchFilterFlag = true;
    } else {
        const childMatchedPath: any = matchPath(location.pathname, {
            path: '/contracts/:filterId',
            exact: false,
            strict: false
        });

        savedSearchDTOUrl = contractsHostName + "mysql/getsavedsearchdto/" + childMatchedPath?.params.filterId;
    }

    // Fetches Contracts depending on whether we're using contract search or if we're in an organisation
    const fetchContracts = async () => {
        setLoading(true);
        let params: any = {
            page: currentPage - 1,
            size: pageSize,
            sort: sort + "," + (desc ? "DESC" : "ASC")
        };

        if (hideExpired) {
            params = {
                page: currentPage - 1,
                size: pageSize,
                sort: sort + "," + (desc ? "DESC" : "ASC"),
                minEndDateParam: currentDate
            };
        }

        // Get search filters from passed props.
        if (passedSearchFilterFlag) {
            // Initialise parameter values
            let organisationId: string | null;
            let organisationName: string | null;

            // If values are coming from Context or Props, set the values accordingly
            if (props.searchFilters != undefined && props.searchFilters?.buyerName != undefined && props.searchFilters?.buyerName.length > 0 && props.searchFilters?.gbCohNumber != undefined) {
                organisationId = props.searchFilters.gbCohNumber;
                organisationName = props.searchFilters.buyerName[0];
            } else {
                organisationId = organisationContractContext.organisation.id;
                organisationName = organisationContractContext.organisation.companyName;
            }

            // Initialise Number of Contracts and Contracts total from both search results
            let numberOfContracts: number;
            let mergedArr: ContractSearchDto[];

            // Strip and format GB-COH number
            if (organisationId.includes("UK")) {
                organisationId = organisationId.replace("UK", "GB-COH-");
            }

            // Initialise newSelectedFilters
            let searchFilters: NewSelectedFilters = {
                followed: false,
                searchTerm: filters.searchTerm,
                status: ["complete"],
                cpvCode: filters.cpvCode,
                buyerName: [organisationName],
                supplierName: filters.supplierName,
                minValue: filters.minValue,
                maxValue: filters.maxValue,
                minStartDate: null,
                maxStartDate: null,
                minEndDate: null,
                maxEndDate: null,
                minPublishedDate: null,
                maxPublishedDate: null,
                sme: null,
                vcse: null,
                gbCohNumber: organisationId
            }

            let contracts1: ContractWrapperDto = {
                contract: [],
                numberOfContracts: 0
            };
            let  contracts2: ContractWrapperDto = {
                contract: [],
                numberOfContracts: 0
            };

            // Get Contracts from Buyer Name only
            searchFilters.gbCohNumber = null;
            await axios.post(
                savedSearchDTOUrl,
                searchFilters,
                {
                    params: params
                })
                .then((res) => {
                    contracts1 = res.data;
                })
                .catch(() => setError(true));

            // Get Contracts from COH Supplier name only
            searchFilters.buyerName = [];
            // Reassign GB COH Number back to search filters once we're done
            searchFilters.gbCohNumber = organisationId;

            await axios.post(
                savedSearchDTOUrl,
                searchFilters,
                {
                    params: params
                })
                .then((res) => {
                    contracts2 = res.data;
                })
                .catch(() => setError(true));

            // Reassign values back to search filters once we're done
            searchFilters.buyerName = [organisationName];

            // Get Number of Contracts and Contracts total from both search results
            numberOfContracts = contracts1.numberOfContracts + contracts2.numberOfContracts;
            mergedArr = contracts1.contract.concat(contracts2.contract);

            // Define new Contract Wrapper
            const fetchedContractWrapper: ContractWrapperDto = {
                contract: mergedArr,
                numberOfContracts: numberOfContracts
            }

            // Set found contracts to new accumulation
            setFoundContracts(fetchedContractWrapper);
            setLoading(false);
        } else {
            await axios.get(savedSearchDTOUrl, {
                params: params
            })
                .then((res) => {
                    setFoundContracts(res.data);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }
    };

    let history = useHistory();

    useEffect(() => {
        fetchContracts();
    }, [currentPage, pageSize, sort, desc, hideExpired, filters]);

    // Get followed contracts list
    useEffect(() => {
        const fetchFollowedContracts = async () => {

            setFollowedContractsLoading(true);
            const listOfContractsToFind = foundContracts?.contract?.map(contract => contract.releaseId);

            await axios.post(
                followedContractCheckUrl,
                listOfContractsToFind)
                .then((res) => {
                    setFollowedContractsList(res.data);
                    setFollowedContractsLoading(false);
                })
                .catch(() => setError(true))
                .finally(() => setFollowedContractsLoading(false));
        }

        if (foundContracts !== undefined) {
            fetchFollowedContracts();
        }
    }, [foundContracts]);

    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== pageSize) {
            setPageSize(size);
        }
    };

    const getSortButton = (s: Sort) => {
        if (s === sort) {
            return (
                <a className="sort-button pull-right"
                   onClick={() => {
                       setDesc(!desc);
                   }}>
                    <FontAwesomeIcon icon={desc ? faSortAmountDown : faSortAmountDownAlt} />
                </a>
            );
        }

        return (
            <a className="sort-button pull-right"
               onClick={() => {
                   setSort(s);
                   setDesc(true);
               }}>
                <FontAwesomeIcon icon={faSort} />
            </a>
        );
    };

    const formatValue = (value: number): string => {
        if (!value) {
            return "-";
        }
        let formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0
        });
        return formatter.format(value);
    };

    // Large Number Formatter
    const formatNonCurrencyValue = (value: number): string => {
        if (!value) {
            return "-";
        }
        let formatter = new Intl.NumberFormat(undefined, {
            style: 'decimal',
            minimumFractionDigits: 0
        });
        return formatter.format(value);
    };

    const toggleHideExpiredContracts = () => {
        setHideExpired(!hideExpired);
    }

    return (
        <div
            style={ passedSearchFilterFlag ?  { paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10, marginRight: 10 }
            : { paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10 }}>
            <div>
                <div>
                    <div className={"row mb-3"}>
                        <h5 className={"col col-9"}>
                            Number of Contracts found: {foundContracts?.numberOfContracts ? formatNonCurrencyValue(foundContracts?.numberOfContracts) : 0}
                        </h5>
                        {foundContracts?.contract.find(contract => contract.tenderStatus === "planning") ?
                            null
                            :
                            <div className="col col-2" style={{textAlign: "end"}}>
                                <span className="me-2">Hide expired</span>
                                <Switch checked={hideExpired} onChange={() => toggleHideExpiredContracts()}/>
                            </div>
                        }
                        {organisationContractContext.organisation?.id ? (
                            <div className="col col-1">
                                <button
                                    className="iqx-button primary iconic sm-size pull-right"
                                    onClick={() => {
                                        setShowFilters(true);
                                    }}>
                                    <span className="me-2">Filters</span>
                                    <FontAwesomeIcon icon={faFilter} />
                                </button>
                            </div>
                        ) : null}
                    </div>

                        {loading ? (
                            <IqLoadingIcon />
                        ) : (
                            error ? (
                                <div className="justify-content-md-center">
                                    <Alert variant="danger">
                                        {translate("errors.loading")}
                                    </Alert>
                                </div>
                            ) : (
                                (foundContracts && foundContracts.numberOfContracts > 0) ? (
                                    <>
                                        <Table striped hover>
                                            <thead>
                                            <tr>
                                                <th>
                                                    {translate("contracts.contracttitle")}
                                                    {getSortButton(Sort.TITLE)}
                                                </th>
                                                <th>
                                                    {translate("contracts.organisation")}
                                                    {getSortButton(Sort.ORGANISATION)}
                                                </th>
                                                <th>
                                                    {translate("contracts.status")}
                                                    {getSortButton(Sort.STATUS)}
                                                </th>
                                                <th>
                                                    {translate("contracts.awarded")}
                                                    {getSortButton(Sort.AWARDED)}
                                                </th>
                                                <th>
                                                    {translate("contracts.startdate")}
                                                    {getSortButton(Sort.START_DATE)}
                                                </th>
                                                <th>
                                                    {translate("contracts.enddate")}
                                                    {getSortButton(Sort.END_DATE)}
                                                </th>
                                                <th className="text-end">
                                                    {translate("contracts.value")}
                                                    {getSortButton(Sort.VALUE)}
                                                </th>
                                                {props.achieved ?
                                                    <th>
                                                        {translate("contracts.organisation")}
                                                        {getSortButton(Sort.ORGANISATION)}
                                                    </th>
                                                    :
                                                    <th>
                                                        {translate("contracts.suppliername")}
                                                    </th>
                                                }
                                                <th>
                                                    {translate("organisation.followed")}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {foundContracts.contract?.map((contract) => (
                                                    <tr key={contract.releaseId}>
                                                        <td>
                                                            <span className="iqx-link"
                                                                  onClick={() => history.push(`/contract/${contract.releaseId}`)}>
                                                                {contract.tenderTitle}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {contract.buyerName ?
                                                                <span className="text-capitalize">{contract.buyerName.toUpperCase()}</span>
                                                                :
                                                                <span className="text-capitalize">{contract.buyerName}</span>}
                                                        </td>
                                                        <td>
                                                            <span className="text-capitalize">
                                                                {contract.tenderStatus}
                                                            </span>
                                                        </td>
                                                        <td>{DateFormatter.formatDate(contract.tenderDatePublished)}</td>
                                                        <td>{DateFormatter.formatDate(contract.tenderContractPeriodStartDate)}</td>
                                                        <td>{DateFormatter.formatDate(contract.tenderContractPeriodEndDate)}</td>
                                                        <td className="text-end">{contract?.tenderValueAmount!= null ? formatValue(contract.tenderValueAmount) : undefined}</td>
                                                        <td>
                                                            <ContractSuppliers suppliers={contract.tenderPartiesSuppliers} />
                                                        </td>
                                                        <td className="text-center">
                                                            {followedContractsLoading ?
                                                                <Spinner style={{   display: "block",
                                                                    marginLeft: "auto",
                                                                    marginRight: "auto",
                                                                    height: "1.5rem",
                                                                    width: "1.5rem",}}
                                                                         animation="border"
                                                                         variant="primary"/>
                                                                :
                                                                followedContractsList.length > 0 ?

                                                                    followedContractsList.includes(contract.releaseId) ?
                                                                        <FollowContractButtonFake
                                                                            contractReleaseId={ contract.releaseId }
                                                                            isAlreadyFollowedFlag={ true }  />
                                                                        :
                                                                        <FollowContractButtonFake
                                                                            contractReleaseId={ contract.releaseId }
                                                                            isAlreadyFollowedFlag={ false }  />

                                                                    :
                                                                    <FollowContractButtonFake
                                                                        contractReleaseId={ contract.releaseId }
                                                                        isAlreadyFollowedFlag={ false }  />
                                                            }

                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        <div className="justify-content-md-end pagination mt-n2 pe-2">
                                            <span className="me-2">
                                                <Form.Control as="select"
                                                              className=""
                                                              defaultValue={pageSize}
                                                              onChange={(event) => changePageSize(event)}
                                                >
                                                    <option value={5}>5</option>
                                                    <option value={10}>10</option>
                                                    <option value={15}>15</option>
                                                </Form.Control>
                                            </span>
                                            <span>
                                                <Pagination
                                                    totalRecords={foundContracts?.numberOfContracts ? foundContracts.numberOfContracts : 0}
                                                    pageLimit={pageSize}
                                                    pageRangeDisplayed={1}
                                                    initPage={currentPage}
                                                    onChangePage={setCurrentPage}
                                                />
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <div className="justify-content-md-center mt-3">
                                        <Alert variant="info">
                                            {translate("contracts.notfound")}
                                        </Alert>
                                    </div>
                                )
                            )
                        )}
                </div>
            </div>

            <Modal size="lg" show={showFilters}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Filters
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-2">
                        <Col className="pt-2" xs={4} sm={4} md={4} lg={4} xl={4}>
                            <span className="organisation-card-label">{translate("contracts.searchterm")}</span>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                            <input type="text"
                                   className="form-control"
                                   placeholder={translate("advancedsearch.search")}
                                   value={searchTerm}
                                   onChange={(e: any) => setSearchTerm(e.target.value)} />
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <Col className="pt-2" xs={4} sm={4} md={4} lg={4} xl={4}>
                            <span className="organisation-card-label">{translate("contracts.cpvcodes")}</span>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} >
                            <TreeSelect dropdownStyle={{ zIndex:"100000"}} {...tProps} disabled={loadingCpvCodesHierarchy || cpvHierarchyError} />
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <Col className="pt-2" xs={4} sm={4} md={4} lg={4} xl={4}>
                            <span className="organisation-card-label">{translate("contracts.suppliers")}</span>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                            <Select isMulti
                                    isDisabled={loadingSuppliers || supplierError}
                                    options={supplierOptions}
                                    value={
                                        supplierFilters?.map((supplier: string) => {
                                            return {
                                                value: supplier,
                                                label: supplier
                                            }
                                        })
                                    }
                                    onChange={(e: any) => updateSupplierFilters(e)}
                                    placeholder={translate("contracts.suppliers")}
                                    className="basic-multi-select"
                                    classNamePrefix="select" />
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <Col className="pt-2" xs={4} sm={4} md={4} lg={4} xl={4}>
                            <span className="organisation-card-label">{translate("contracts.value")}</span>
                        </Col>
                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <select className="form-control" value={minValue} onChange={(e: any) => setMinValue(e.target.value)}>
                                <option value={undefined}>{translate("contracts.nomin")}</option>
                                { maxValue ? maxValue > 0 ? (
                                    CONTRACT_VALUE_OPTIONS
                                        .filter((option: number) => option != maxValue && option < maxValue)
                                        .map((opt: number) => (
                                            <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                        ))
                                ) : (
                                    CONTRACT_VALUE_OPTIONS.map((opt: number) => (
                                        <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                    ))
                                ) : (
                                    CONTRACT_VALUE_OPTIONS.map((opt: number) => (
                                        <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                    ))
                                )}
                            </select>
                        </Col>
                        <Col className="text-center pt-2" xs={2} sm={2} md={2} lg={2} xl={2}>
                            <span>{translate("advancedsearch.to")}</span>
                        </Col>
                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <select className="form-control" value={maxValue} onChange={(e: any) => setMaxValue(e.target.value)}>
                                <option value={undefined}>{translate("contracts.nomax")}</option>
                                { minValue ? minValue > 0 ? (
                                    CONTRACT_VALUE_OPTIONS
                                        .filter((option: number) => option != minValue && option > minValue)
                                        .map((opt: number) => (
                                            <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                        ))
                                ) : (
                                    CONTRACT_VALUE_OPTIONS.map((opt: number) => (
                                        <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                    ))
                                ) : (
                                    CONTRACT_VALUE_OPTIONS.map((opt: number) => (
                                        <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                    ))
                                ) }
                            </select>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button className="iqx-button sm-size secondary" onClick={() => setShowFilters(false)}>Cancel</button>
                    <button className="iqx-button sm-size primary"
                            onClick={() => {
                                let suppliers = supplierFilters ? [...supplierFilters] : [];
                                let cpvCodes = cpvFilters ? [...cpvFilters] : [];
                                setShowFilters(false);
                                setFilters({
                                    ...filters,
                                    searchTerm: searchTerm,
                                    minValue: minValue,
                                    maxValue: maxValue,
                                    cpvCode: cpvCodes,
                                    supplierName: suppliers
                                })
                            }}>
                        Apply
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );

};

export default ContractsSearch;