import React, {PropsWithChildren} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";

import './ProfileCard.css';


interface ProfileCardProps extends PropsWithChildren {
    heading: string;
    iconDefinition: IconDefinition;
}

const ProfileCard: React.FC<ProfileCardProps> = ({heading, iconDefinition, children}) => {
    return (
        <div className="profile-card">
            <div className="title">
                <FontAwesomeIcon icon={iconDefinition} color="black" size="1x"/>
                <span>{heading}</span>
            </div>
            {children}
        </div>
    );
};

export default ProfileCard;
