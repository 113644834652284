import React, {useContext, useEffect, useState} from "react";
import {hasUserRole, Role} from "../../utils/Security";
import {Route, useHistory, useRouteMatch} from "react-router-dom";
import ContractList from "./contracts/ContractList";
import CreateFilters from "./contracts/CreateFilters";
import SubmenuContext from "../../context/SubmenuContext";
import {faFileContract, faMoneyCheck, faToolbox} from "@fortawesome/free-solid-svg-icons";
import translate from "../../i18n/translate";
import {Col, Container, Row} from "react-bootstrap";
import Frameworks from "./framework/Frameworks";
import PublicSectorContext, {
    CCSFilters,
    CCSSort,
    FundingSort,
    GCloudFilters,
    GCloudSort,
    ResearchFundingFilters
} from "../../context/PublicSectorContext";
import CCSFramework from "../../model/publicsector/CCSFramework";
import axios from "axios";
import {contractsHostName} from "../../utils/Configuration";
import GCloudSupplier from "../../model/publicsector/GCloudSupplier";
import CCSFrameworkView from "./framework/CCSFrameworkView";
import GCloudView from "./framework/GCloudView";
import PublicResearchFunding from "./funding/PublicResearchFunding";
import Funding from "../../model/publicsector/Funding";
import PublicResearchFundingView from "./funding/PublicResearchFundingView";
import CCSFrameworkIdsAndName from "../../model/publicsector/CCSFrameworkIdsAndName";
import CCSFrameworks from "./framework/CCSFrameworks";
import GCloud from "./framework/GCloud";

const PublicSectorContainer: React.FC = () => {

    let history = useHistory();
    let match = useRouteMatch();
    const { setSubmenuTitle, setSubmenuItems } = useContext(SubmenuContext);

    const [ccsFrameworks, setCCSFrameworks] = useState<CCSFramework[]>([]);
    const [loadingCCS, setLoadingCCS] = useState<boolean>(false);

    const [ccsPage, setCCSPage] = useState<number>(1);
    const [ccsPageSize, setCCSPageSize] = useState<number>(10);
    const [ccsTotal, setCCSTotal] = useState<number>(0);

    const [ccsSort, setCCSSort] = useState<CCSSort>(CCSSort.END_DATE);
    const [ccsDesc, setCCSDesc] = useState<boolean>(false);

    const [gCloudSuppliers, setGCloudSuppliers] = useState<GCloudSupplier[]>([]);
    const [loadingGCloud, setLoadingGCloud] = useState<boolean>(false);
    const [gCloudPage, setGCloudPage] = useState<number>(1);
    const [gCloudPageSize, setGCloudPageSize] = useState<number>(10);
    const [gCloudTotal, setGCloudTotal] = useState<number>(0);

    const [gCloudSort, setGCloudSort] = useState<GCloudSort>(GCloudSort.NAME);
    const [gCloudDesc, setGCloudDesc] = useState<boolean>(false);
    const [gCloudFilters, setGCloudFilters] = useState<GCloudFilters>({} as GCloudFilters);

    const [funding, setFunding] = useState<Funding[]>([]);
    const [loadingFunding, setLoadingFunding] = useState<boolean>(false);
    const [fundingPage, setFundingPage] = useState<number>(1);
    const [fundingPageSize, setFundingPageSize] = useState<number>(10);
    const [fundingTotal, setFundingTotal] = useState<number>(0);

    const [fundingSort, setFundingSort] = useState<FundingSort>(FundingSort.END_DATE);
    const [fundingDesc, setFundingDesc] = useState<boolean>(true);

    const [researchFundingFilters, setResearchFundingFilters] = useState<ResearchFundingFilters>({} as ResearchFundingFilters);

    const [frameworkIds, setFrameworkIds] = useState<string[]>([]);
    const [frameworkIdsAndName, setFrameworkIdsAndName] = useState<CCSFrameworkIdsAndName[]>([])

    const [ccsFilters, setCCSFilters] = useState<CCSFilters>({} as CCSFilters);

    useEffect(() => {
        setSubmenuTitle(translate("contracts.nav"));
        setSubmenuItems([
            {
                name: "Contracts",
                url: `${match.url}/contracts`,
                icon: faFileContract
            },
            {
                name: "Frameworks",
                url: `${match.url}/frameworks`,
                icon: faToolbox
            },
            {
                name: "Funding",
                url: `${match.url}/funding`,
                icon: faMoneyCheck
            }
        ]);

        return () => {
            setSubmenuItems([]);
            setSubmenuTitle("");
        }
    }, []);

    useEffect(() => {
        const fetchCCSFrameworks = async () => {
            setLoadingCCS(true);
            let params: any = {
                page: ccsPage - 1,
                size: ccsPageSize,
                sort: ccsSort + "," + (ccsDesc ? "DESC" : "ASC")
            }

            await axios.post(contractsHostName + "ccs-framework/search", ccsFilters, {params: params})
                .then(r => {
                    setCCSFrameworks(r.data.content);
                    setCCSTotal(r.data.totalElements);
                })
                .finally(() => setLoadingCCS(false));
        }

        fetchCCSFrameworks();
    }, [ccsPage, ccsPageSize, ccsSort, ccsDesc, ccsFilters]);

    const fetchGCloudSuppliers = async () => {
        setLoadingGCloud(true);
        let params: any = {
            page: gCloudPage - 1,
            size: gCloudPageSize,
            sort: gCloudSort + "," + (gCloudDesc ? "DESC" : "ASC")
        }

        await axios.post(contractsHostName + "g-cloud", gCloudFilters, {params: params})
            .then(r => {
                setGCloudSuppliers(r.data.content);
                setGCloudTotal(r.data.totalElements);
            })
            .finally(() => setLoadingGCloud(false));
    }

    useEffect(() => {
        if (gCloudPage != 1) {
            setGCloudPage(1);
        }
        else {
            fetchGCloudSuppliers();
        }
    }, [gCloudFilters]);

    useEffect(() => {
        fetchGCloudSuppliers();
    }, [gCloudPage, gCloudPageSize, gCloudSort, gCloudDesc]);

    useEffect(() => {
        const fetchFunding = async () => {
            setLoadingFunding(true);
            let params: any = {
                page: fundingPage - 1,
                size: fundingPageSize,
                sort: fundingSort + "," + (fundingDesc ? "DESC" : "ASC")
            }

            await axios.post(contractsHostName + "public-research-funding", researchFundingFilters, {params: params})
                .then(r => {
                    setFunding(r.data.content);
                    setFundingTotal(r.data.totalElements);
                })
                .finally(() => setLoadingFunding(false));
        }

        fetchFunding();
    }, [fundingPage, fundingPageSize, fundingSort, fundingDesc, researchFundingFilters]);

    useEffect(() => {
        const fetchFrameworkIds = async () => {
            await axios.get(contractsHostName + "ccs-framework/framework-ids")
                .then((r) => setFrameworkIds(r.data));
        }

        fetchFrameworkIds();
    }, []);

    useEffect(() => {
        const fetchFrameworkIdsAndName = async () => {
            await axios.get(contractsHostName + "ccs-framework/name")
                .then((r) => setFrameworkIdsAndName(r.data));
        }

        fetchFrameworkIdsAndName();
    }, []);

    return (
        <PublicSectorContext.Provider value={{
            ccsFrameworks, setCCSFrameworks,
            loadingCCS, setLoadingCCS,
            ccsPage, setCCSPage,
            ccsPageSize, setCCSPageSize,
            ccsTotal, setCCSTotal,
            ccsSort, setCCSSort,
            ccsDesc, setCCSDesc,
            ccsFilters, setCCSFilters,
            frameworkIds, setFrameworkIds,
            frameworkIdsAndName, setFrameworkIdsAndName,
            gCloudSuppliers, setGCloudSuppliers,
            loadingGCloud, setLoadingGCloud,
            gCloudPage, setGCloudPage,
            gCloudPageSize, setGCloudPageSize,
            gCloudTotal, setGCloudTotal,
            gCloudSort, setGCloudSort,
            gCloudDesc, setGCloudDesc,
            gCloudFilters, setGCloudFilters,
            funding, setFunding,
            loadingFunding, setLoadingFunding,
            fundingPage, setFundingPage,
            fundingPageSize, setFundingPageSize,
            fundingTotal, setFundingTotal,
            fundingSort, setFundingSort,
            fundingDesc, setFundingDesc,
            researchFundingFilters, setResearchFundingFilters
        }}>
            <Container fluid style={{ marginTop: 15, marginRight: "auto", paddingLeft: 15 }}>
                <Row className="mb-3">
                    <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                        <div className="iq-headline">
                            <span className="text">
                                {translate("contracts.nav")}
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row className="" style={{ marginLeft: 0, marginRight: 0, marginBottom: 15 }}>
                    <div style={{ marginLeft: "auto", marginRight: "auto" }} className="iq-button-group">
                        <button className={window.location.pathname.includes("contracts") ? "selected-inverted" : ""}
                                onClick={() => { history.push("/public-sector/contracts") }}>
                            Contracts
                        </button>
                        <button className={window.location.pathname.includes("ccs") ? "selected-inverted" : ""}
                                onClick={() => { history.push(`/public-sector/frameworks/ccs`) }}>
                            CCS Frameworks
                        </button>
                        <button className={window.location.pathname.includes("g-cloud") ? "selected-inverted" : ""}
                                onClick={() => { history.push(`/public-sector/frameworks/g-cloud`) }}>
                            G-Cloud Suppliers
                        </button>
                        <button className={window.location.pathname.includes("funding") ? "selected-inverted" : ""}
                                onClick={() => { history.push("/public-sector/funding") }}>
                            R&D Funding
                        </button>
                    </div>
                </Row>
                {hasUserRole(Role.GEO_UK) &&
                    <Route exact strict path={`${match.url}/frameworks/ccs`}>
                        <div style={{
                            paddingTop: "10px",
                            backgroundColor: "white",
                            borderRadius: "0.45rem",
                            padding: 15,
                            marginTop: 10
                        }}>
                            <CCSFrameworks/>
                        </div>

                    </Route>}
                {hasUserRole(Role.GEO_UK) &&
                    <Route exact strict path={`${match.url}/frameworks/g-cloud`}>
                        <div style={{
                            paddingTop: "10px",
                            backgroundColor: "white",
                            borderRadius: "0.45rem",
                            padding: 15,
                            marginTop: 10
                        }}>
                            <GCloud/>
                        </div>

                    </Route>}
                {hasUserRole(Role.GEO_UK) && <Route exact strict path={`${match.url}/funding`} component={PublicResearchFunding}/>}
                {hasUserRole(Role.GEO_UK) && <Route exact path={`${match.url}/frameworks/ccs/:frameworkId`} component={CCSFrameworkView}/>}
                {hasUserRole(Role.GEO_UK) && <Route exact path={`${match.url}/frameworks/g-cloud/:supplierId`} component={GCloudView}/>}
                {hasUserRole(Role.GEO_UK) && <Route exact path={`${match.url}/funding/project/:projectId`} component={PublicResearchFundingView}/>}
                {hasUserRole(Role.GEO_UK) && <Route exact strict path={`${match.url}/contracts`} component={ContractList}/>}
                {hasUserRole(Role.GEO_UK) && <Route exact strict path={`${match.url}/contracts/filter/create`} component={CreateFilters}/>}
            </Container>
        </PublicSectorContext.Provider>
    );
}

export default PublicSectorContainer;