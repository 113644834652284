import React, {useContext, useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {organisationHostname, socialHostName} from "../../utils/Configuration";
import IqLoadingIcon from "../common/IqLoadingIcon";
import ScrollPaginationContext from "../../context/ScrollPaginationContext";
import './ContactContainer.css'
import {
    faBuilding,
    faCommentDollar,
    faFileContract,
    faHandshake,
    faMicrochip,
    faNewspaper,
    faPercentage,
    faRss,
    faStamp,
    faTools,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import NylasContact from "../../model/organisation/social/NylasContact";
import ContactNavigation from "./ContactNavigation";
import Organisation from "../../model/organisation/Organisation";
import ContactHeader from "./ContactHeader";
import SubmenuContext from "../../context/SubmenuContext";
import SearchContext from "../../context/SearchContext";
import {hasUserRole, Role} from "../../utils/Security";
import I18nContext from "../../context/I18nContext";

const ContactContainer: React.FC = () => {
    const myContacts = useLocation().state;
    const history = useHistory();
    const location = useLocation();

    const [contact, setContact] = useState<NylasContact>({} as NylasContact);
    const [contacts, setContacts] = useState<NylasContact[]>([]);
    const [emailId, setEmailId] = useState<string>('');
    const [contactId, setContactId] = useState<string>('');
    const [contactName, setContactName] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [organisation, setOrganisation] = useState<Organisation>({} as Organisation);

    const [isVendor, setIsVendor] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const {setSubmenuTitle, setSubmenuItems} = useContext(SubmenuContext);
    const {searchQuery, setSearchQuery} = useContext(SearchContext);
    const {lang} = useContext(I18nContext);

    const params = new URLSearchParams(location.search);
    const listId = params.get("list");


    useEffect(() => {
        setSearchQuery("");
    }, [organisation]);



    useEffect(() => {
        if (organisation) {
            setSubmenuTitle("Organisation");

            let initialSubmenu = [
                {
                    name: "organisation.summary.title",
                    url: "/organisation/" + organisation.id + (listId ? "?list=" + listId : ""),
                    icon: faBuilding
                },
                {
                    name: "organisation.people.title",
                    url: "/organisation/" + organisation.id + "/people",
                    icon: faUsers
                },
                {
                    name: "organisation.techstack.title",
                    url: "/organisation/" + organisation.id + "/techstack",
                    icon: faMicrochip
                },
                {
                    name: "organisation.news.title",
                    url: "/organisation/" + organisation.id + "/news",
                    icon: faNewspaper
                },
                {
                    name: "organisation.social.title",
                    url: "/organisation/" + organisation.id + "/social",
                    icon: faRss
                }
            ];

            if (organisation?.countryCode === "UK" && organisation?.primaryType !== "PublicSector") {
                initialSubmenu.push({
                    name: "organisation.shareholders.title",
                    url: "/organisation/" + organisation.id + "/shareholders",
                    icon: faFileContract
                });
            }

            if (organisation?.primaryType === "PublicSector") {
                initialSubmenu.push({
                    name: "organisation.contracts.title",
                    url: "/organisation/" + organisation.id + "/contracts",
                    icon: faStamp
                });
            }

            if (organisation?.primaryType !== "PublicSector" && organisation?.countryCode === "UK") {
                initialSubmenu.push({
                    name: "organisation.contracts.title",
                    url: "/organisation/" + organisation.id + "/achieved-contracts",
                    icon: faStamp
                });
            }

            if (organisation?.primaryType === "PublicSector") {
                initialSubmenu.push({
                    name: "organisation.spend.title",
                    url: "/organisation/" + organisation.id + "/spend",
                    icon: faCommentDollar
                });
            }

            if (isVendor) {
                initialSubmenu.push({
                    name: "organisation.channelpartners.title",
                    url: "/organisation/" + organisation.id + "/channel-partners",
                    icon: faHandshake
                });
            }

            if (hasUserRole(Role.ADMIN)) {
                initialSubmenu.push({
                    name: "organisation.edit.title",
                    url: "/organisation/" + organisation.id + "/edit",
                    icon: faTools
                });
            }

            if (hasFinancials() && organisation?.primaryType !== "PublicSector") {
                let itemExists = initialSubmenu.find((i: any) => i.name === "Financials");

                if (!itemExists) {
                    initialSubmenu.splice(1, 0, {
                        name: "organisation.financials.title",
                        url: "/organisation/" + organisation.id + "/financials",
                        icon: faPercentage
                    });
                }
            }

            setSubmenuItems(initialSubmenu);
        }

        return () => {
            setSubmenuTitle("");
            setSubmenuItems([]);
        };
    }, [organisation]);

    const hasFinancials = (): boolean => {
        let yearValues = organisation.financialSummary;
        return yearValues && yearValues.length > 0;
    };

    let scroller = useRef<any>();
    const [page, setPageState] = useState<number>(0);
    const setPage = (p: number) => {
        setPageState(p);
    };

    const handleScroll = () => {
        if (!loading) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            let currentScroll = scroller.current.scrollTop;

            if (currentScroll === maxScroll) {
                setPage(page + 1);
            }
        }
    };


    useEffect(() => {
        setLoading(true)
        let locationStateData: any;
        if (myContacts) {
            locationStateData = myContacts;
            setContactName(locationStateData.contactName);
            setEmailId(locationStateData.contactEmailId);
            setContactId(locationStateData.contactId);
            setOrganisation(locationStateData.organisation)
            setContacts(locationStateData.contacts)

        }

        var bodyFormData = new FormData();
        bodyFormData.append("lang", lang)
        bodyFormData.append("email", locationStateData.contactEmailId)
        const fetchContact = async () => {
            await axios.post(socialHostName + "contact", bodyFormData)
                .then(r => setContact(r.data))
                .finally(() => setLoading(false));
        };
        fetchContact();
        
        const fetchCompany = async () => {
            await axios.get(organisationHostname + "organisation/" + locationStateData.organisation.id)
                .then(response => setOrganisation(response.data))
                .catch(error => {
                    if (error.response.status === 404 || error.response.status === 400) {
                        setNotFound(true);
                    } else {
                        setError(true);
                    }
                })

        };

        fetchCompany()

        const fetchVendorCheck = async () => {
            await axios.get(organisationHostname + "organisation/" + locationStateData.organisation.id + "/is-vendor")
                .then((response) => setIsVendor(response.data))
                .catch(error => {
                    console.log(error);
                })
        };
        fetchVendorCheck();


    }, [history.location, lang, myContacts])

    return (
        <ScrollPaginationContext.Provider value={{page, setPage}}>
            <div style={{maxHeight: "100%", overflowY: "scroll", overflowX: "hidden"}} ref={scroller}
                 onScroll={handleScroll}>
                {loading ? <IqLoadingIcon/> : (
                    <div>
                        <div>
                            <ContactHeader organisation={organisation} emailId={emailId} contactName={contactName}
                                           contact={contact}/>
                        </div>
                        {contacts && (
                            <div>
                                <ContactNavigation contactsList={contacts} contact={contactId}
                                                   organisation={organisation}/>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </ScrollPaginationContext.Provider>
    )
};

export default ContactContainer;
