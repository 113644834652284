import React, {useState} from "react";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt,
    faChevronDown,
    faChevronUp,
    faEnvelope,
    faPhoneSquareAlt
} from "@fortawesome/free-solid-svg-icons";
import {OrganisationContact} from "../../../model/organisation/OrganisationContact";
import translate from "../../../i18n/translate";
import "./OrganisationContactEntry.css"
import {Button, Col, Image, Row, Tab, Table, Tabs} from "react-bootstrap";
import DateFormatter from "../../../utils/formatters/DateFormatter";
import {hasUserRole, Role} from "../../../utils/Security";

interface Props {
    contact: OrganisationContact
    showOrganisation: boolean
}

const OrganisationContactEntry: React.FC<Props> = (props: Props) => {
    const [key, setKey] = useState<string | null>("employment");
    const [show, setShow] = useState<boolean>(false);

    const getPhoneNumFormatUK = () => {
        if (props.contact.officePhoneNumber !== null) {
            let phoneNumFormatString = props.contact.officePhoneNumber.replace(/(\s+|-)/g, '');
            if (phoneNumFormatString.length >= 13) {
                return phoneNumFormatString.slice(0, 3) + ' ' + phoneNumFormatString.slice(3, 6) + ' ' + phoneNumFormatString.slice(6, 10) + ' ' + phoneNumFormatString.slice(10);
            } else {
                return phoneNumFormatString;
            }
        } else {
            return null;
        }
    }


    const getCityAndCountry = (contact: OrganisationContact) => {
        if (contact.personCity && contact.personCountry) {
            return contact.personCity + ", " + contact.personCountry;
        }
        if (contact.personCity) {
            return contact.personCity
        }
        if (contact.personCountry) {
            return contact.personCountry
        }
    }

    const getLinkedinHandle = (contact: OrganisationContact) => {
        let index = contact.linkedInUrl.lastIndexOf("/");
        if (contact.linkedInUrl.substring(index + 1) !== "") {
            return decodeURIComponent(contact.linkedInUrl.substring(index + 1));
        } else {
            let secondLastIndex = contact.linkedInUrl.lastIndexOf('/', contact.linkedInUrl.lastIndexOf('/') - 1)
            return decodeURIComponent(contact.linkedInUrl.substring(secondLastIndex + 1));
        }
    }

    const ShowEducationAndEmployment = () => {
        return (
            <div>
                <Tabs
                    id="education-employment"
                    className="education-employment-tab"
                    activeKey={key || "employment"}
                    onSelect={(k: React.SetStateAction<string | null>) => {
                        setKey(k);
                    }}
                >
                    <Tab eventKey="employment" title="Employment History">
                        <EmploymentHistoryTable />
                    </Tab>
                </Tabs>
            </div>
        )
    }

    const EmploymentHistoryTable = () => {
        return (
            <div>
                {props.contact.employmentHistory.length === 0 ? translate("contact.noemployment") :
                    <Table responsive borderless className="contacts-employment-table">
                        <th>Company</th>
                        <th>Role</th>
                        <th>Timeframe</th>
                        <tbody className="contacts-table-body">
                            {props.contact.employmentHistory.map((employment, index) =>
                                <tr key={index}>
                                    <td>{employment.name}</td>
                                    <td>{employment.title}</td>
                                    <td>From: {employment.start} - To: {employment.end != null ? employment.end : "Present"}</td>
                                </tr>
                            )}

                        </tbody>
                    </Table>
                }
            </div>
        )
    }

    const CompanyLogo: React.FC<OrganisationContact> = (orgContact: OrganisationContact) => {
        const [src, setSrc] = useState<string>(orgContact.logoUrl ? orgContact.logoUrl : "./icons_company.svg");

        return (
            <Image src={src}
                style={{
                    height: "50px",
                    width: "50px",
                    alignItems: "center",
                    objectFit: "cover",
                    borderRadius: "50%",
                    borderColor: "#377dc8",
                    borderStyle: "solid",
                    marginRight: "10px"
                }}
                title={orgContact.companyName}
                onError={() => setSrc("/icons_company.svg")} />
        );
    };

    const ShowContactsSocial = () => {

        return (
            <div className="organisation-contact-social">
                {props.contact.linkedInUrl && (
                    <a className="iqx-link"
                        href={["https://", props.contact.linkedInUrl?.replace("http://", "").replace("https://", "")].join("")}>
                        <div>
                            <FontAwesomeIcon icon={faLinkedin} size="sm" />
                            <span style={{ paddingLeft: "5px" }}>{getLinkedinHandle(props.contact)}</span>
                        </div>
                    </a>
                )}
                {props.contact.emailAddress && (
                    <a target="_blank"
                        className="iqx-link"
                        href={"mailto:" + props.contact.emailAddress}
                    >
                        <div style={{ paddingLeft: "5px", overflowWrap: "anywhere", wordBreak: "break-all" }}>
                            <FontAwesomeIcon icon={faEnvelope} size="sm" />
                            <span style={{ paddingLeft: "5px" }}>{props.contact.emailAddress}</span>
                        </div>
                    </a>
                )}
                {props.contact.officePhoneNumber && (
                    <a target="_blank"
                        className="iqx-link"
                        href={"tel:" + getPhoneNumFormatUK()?.replaceAll(" ", "")}>
                        <div style={{ paddingLeft: "5px" }}>
                            <FontAwesomeIcon icon={faPhoneSquareAlt} size="sm" />
                            <span style={{ paddingLeft: "5px" }}>{getPhoneNumFormatUK()}</span>
                        </div>
                    </a>
                )}
            </div>
        );
    }
    return (
        <div className="organisation-contacts-container mb-2 mt-2 shadow-sm p-3 bg-white rounded">

            <Row className="organisation-contact-row justify-content-between align-content-center" style={{marginLeft: 0, marginRight: 0}}>
                <Col xs={0} sm={0} md={0} lg={0} xl={1} className="d-none d-xl-block">
                    <div className="organisation-contact-logo-container justify-content-center align-content-center">
                        <div className="organisation-contact-logo">

                            {props.contact.firstName?.charAt(0)}{props.contact.lastName?.charAt(0)}
                        </div>
                    </div>
                </Col>

                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                    <div className="organisation-contact-details justify-content-center align-content-center" style={{ fontSize: "0.95rem" }}>
                        <div className="organisation-contact-name">
                            <span>{props.contact.firstName + " " + props.contact.lastName}</span>
                        </div>
                        <div className="job-title mt-1">{props.contact.jobTitle}</div>
                        <span>{getCityAndCountry(props.contact)}</span>
                    </div>
                </Col>

                <Col xs={4} sm={4} md={4} lg={4} xl={3}>
                    <ShowContactsSocial />
                </Col>

                <Col xs={4} sm={4} md={4} lg={4} xl={2}>
                    {props.showOrganisation && props.contact.organisationId ?
                        <a className="organisation-contact-company justify-content-left align-content-center"
                            href={"/organisation/" + props.contact.organisationId}>
                            <CompanyLogo {...props.contact} />
                            <div style={{ fontSize: "0.9rem" }}>
                                <span>{props.contact.companyName}</span>
                                <div>
                                    <span>{props.contact.website}</span>
                                </div>
                            </div>
                        </a> :
                        <div className="organisation-contact-company justify-content-left align-content-center" />
                    }
                </Col>

                <Col xs={0} sm={0} md={0} lg={0} xl={1} className="d-none d-xl-block me-2">
                <div className="organisation-contact-discovered justify-content-center align-content-center" style={{ fontSize: "0.85rem" }}>
                    <span className="me-1">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </span>
                    <span >
                        {translate("contacts.lastmodified")}
                        <p> {DateFormatter.formatDate(props.contact.lastModifiedDate)} {hasUserRole(Role.ADMIN) && `(${props.contact.source})`}</p>
                    </span>
                    <span>

                    </span>
                </div>
                </Col>

                <Col xs={1} sm={1} md={1} lg={1} xl={1} className="justify-content-center align-content-center align-items-center">
                <div className="organisation-contact-moreinfo-button justify-content-center align-content-center align-items-center" style={{
                    marginTop: "0.5rem"
                }}>
                    {show ? <Button variant="primary" style={{ paddingLeft: "0.2rem", paddingRight:  "0.2rem" }} onClick={() => setShow(false)}>
                        More Information
                        <FontAwesomeIcon icon={faChevronUp} size="sm" style={{ paddingLeft: "3px" }} />
                    </Button> :
                        <Button variant="primary" style={{ paddingLeft:  "0.2rem", paddingRight:  "0.2rem" }} onClick={() => setShow(true)}>
                            More Information
                            <FontAwesomeIcon icon={faChevronDown} size="sm" style={{ paddingLeft: "3px" }} />
                        </Button>
                    }
                </div>
                </Col>


            </Row>
            {show && <ShowEducationAndEmployment />}
        </div>
    );
};
export default OrganisationContactEntry;
