import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {Alert, Dropdown, Row} from "react-bootstrap";
import translate from "../../../i18n/translate";
import ContactSortSelect from "../../contacts/ContactSortSelect";
import ContactFiltersModal, {ContactFilters} from "../../contacts/ContactFiltersModal";
import axios from "axios";
import {organisationHostname} from "../../../utils/Configuration";
import {OrganisationContact} from "../../../model/organisation/OrganisationContact";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import OrganisationContactEntry from "../../organisation/people/OrganisationContactEntry";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../../utils/PaginationHook";
import {matchPath, useLocation, useRouteMatch} from "react-router-dom";
import {getUserUuid, hasAnyRole, Role} from "../../../utils/Security";
import UserListContext from "../../../context/list/UserListContext";
import FileDownload from "js-file-download";
import ListRequestContactsModal from "../home/ListRequestContactsModal";


const UserListContacts: React.FC = () => {

    const match = useRouteMatch();
    const location = useLocation();
    const [list, setList] = useState<string>();
    const [sort, setSort] = useState<string>();
    const [filters, setFilters] = useState<ContactFilters>({
        userId: getUserUuid()
    } as ContactFilters);
    const [currentPage, setCurrentPage] = useState(1);
    const contactsPerPageLimit: number = 15;
    const [totalRecords, setTotalRecords] = useState(1);
    const [exporting, setExporting] = useState<boolean>(false);


    const {
        setShowRequestContactsModal,
    } = useContext(UserListContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [contacts, setContacts] = useState<OrganisationContact[]>([]);

    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/lists/:listId',
        exact: false,
        strict: false
    });


    const setSortBy = (e: ChangeEvent<HTMLSelectElement>) => {
        setSort(e.target.value);
    };

    const setListFilter = (e: ChangeEvent<HTMLSelectElement>) => {
        setList(e.target.value);
    };


    useEffect(() => {
        const fetchContacts = async () => {
            setLoading(true);

            let params: any = {
                sort: sort,
                page: currentPage - 1,
                size: contactsPerPageLimit
            };

            let postData: any = {
                jobTitles: filters?.jobTitles,
                roles: filters?.roles,
                seniority: filters?.seniority,
                countries: filters?.countries,
                userId: filters?.userId,
                from: filters?.from,
                to: filters?.to
            };

            postData["list"] = childMatchedPath?.params.listId;


            await axios.post(organisationHostname + "organisation/contacts/imported", postData, { params: params })
                .then(r => {
                    setContacts(r.data.content);
                    setTotalRecords(r.data.totalElements);
                })
                .finally(() => setLoading(false));
        };

        fetchContacts();
    }, [sort, filters, list, currentPage]);

    const exportContacts = async () => {
        setExporting(true);
        let params: any = {
            sort: sort,
            page: currentPage - 1,
            size: 1000
        };

        let postData: any = {
            jobTitles: filters?.jobTitles,
            roles: filters?.roles,
            seniority: filters?.seniority,
            countries: filters?.countries,
            userId: filters?.userId,
            from: filters?.from,
            to: filters?.to
        };

        postData["list"] = childMatchedPath?.params.listId;

        await axios.post(organisationHostname + "organisation/export-contacts", postData, { params: params })
            .then(r => {
                let now = new Date().toLocaleDateString();
                let fileName = `user_list_${childMatchedPath?.params.listId +"_"+ now}.csv`;
                FileDownload(r.data, fileName);
            })
            .finally(() => setExporting(false));
    };

    const exportRecentContacts = async () => {
        setExporting(true);

        const params = {
            salesforce: false,
            listId: childMatchedPath?.params.listId
        };

        const endpointUrl = `${organisationHostname}organisation/export-contacts/recent`;
        await axios.get(endpointUrl, { params: params })
            .then((queryResponse) => {
                let now = new Date().toLocaleDateString();
                let fileName = `contacts_list_${childMatchedPath?.params.listId}_${now}.csv`;
                FileDownload(queryResponse.data, fileName);
            }).finally(() => {
                setExporting(false);
            });
    };


    return (
        <div style={{ paddingTop: 10, padding: 15, marginTop: 10, width: "100%" }}>
            <Row className="justify-content-md-end mb-4">
                {hasAnyRole([Role.ADMIN, Role.DISCOVERY_ONE, Role.DISCOVERY_TWO, Role.DISCOVERY_THREE]) && (
                    <div className="col">
                        <button className="iqx-button primary md-size me-2"
                                style={{ marginLeft: 5 }}
                                onClick={() => setShowRequestContactsModal(true)}>
                            {translate("lists.requestcontacts")}
                        </button>
                    </div>
                )}

                {hasAnyRole([Role.ADMIN, Role.DISCOVERY_ONE, Role.DISCOVERY_TWO, Role.DISCOVERY_THREE]) && (
                    <div className="col">
                        <Dropdown>
                            <Dropdown.Toggle className="iqx-button primary md-size justify-content-center align-items-center align-content-center ms-xl-0 ms-lg-1"
                                             variant="link" as="button" id="export-dropdown">
                                {translate("lists.exportcontacts")}
                                {exporting &&
                                    <div className="spinner-border spinner-border-sm" style={{marginLeft:3}} role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => exportRecentContacts()}>
                                    Export My Recent Contacts
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={() => exportContacts()}>
                                    Export All Contacts
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )}

                <div className="col">
                    <ContactSortSelect setSortBy={setSortBy} />
                </div>
                <div className="col">
                    <ContactFiltersModal setFilters={setFilters} />
                </div>
            </Row>
            {loading ? <IqLoadingIcon /> : (
                <div>
                    {contacts?.length > 0 ? (
                        <div className="contacts-container">
                            {contacts.map((contact, index) => <OrganisationContactEntry key={index} contact={contact} showOrganisation={true} />)}
                        </div>
                    ) : (
                        <Alert variant="warning" style={{ width: "100%", marginTop: 25 }}>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", alignContent: "center" }}>
                                <FontAwesomeIcon icon={faExclamationCircle} color="#367CDB" size="2x" />
                                <span style={{ marginLeft: 15 }}>{translate("contacts.nocontacts.lists")}</span>
                            </div>
                        </Alert>
                    )}
                    <div style={{marginTop: 10}} className="pull-right">
                        <Pagination
                            totalRecords={totalRecords}
                            pageLimit={contactsPerPageLimit}
                            initPage={currentPage}
                            pageRangeDisplayed={1}
                            onChangePage={setCurrentPage}
                        />
                    </div>
                </div>
            )}
            <ListRequestContactsModal />
        </div>
    )
}

export default UserListContacts;