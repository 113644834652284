import React from "react";
import OrganisationFinancialSummary from "../../model/organisation/OrganisationFinancialSummary";
import {Row} from "react-bootstrap";
import FinancialStat from "./common/FinancialStat";
import OrganisationLineSeriesChart from "./common/OrganisationLineSeriesChart";
import {format} from "d3-format";
import OrganisationBarChart from "./common/OrganisationBarChart";
import translate from "../../i18n/translate";

interface Props {
    financialSummary: OrganisationFinancialSummary,
    years: number,
    revenueGraphData: any[],
    employeesGraphData: any[]
}

const HeadlineNumbers: React.FC<Props> = (props: Props) => {

    const hasEmployees = props.employeesGraphData.find(item => item.y !== null && item.x !== null);
    const hasRevenue = props.revenueGraphData.find(item => item.y !== null && item.x !== null);

    const hasFinancials = () => {
        return props.financialSummary.turnover ||
            props.financialSummary.threeYearCagr ||
            props.financialSummary.grossProfit ||
            props.financialSummary.operatingProfit;
    };

    return (
        <div>
            {(hasFinancials()) && (
                <div>
                    <Row className="pt-3">
                        <div className="col col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="iq-headline">
                                <span className="text">{translate("organisation.summary.headlinenumbers.title")}</span>
                            </div>
                        </div>
                    </Row>
                    <Row className="pt-3">
                        {props.financialSummary.turnover && (
                            <FinancialStat name="organisation.financials.turnover"
                                value={props.financialSummary.turnover?.toString()}
                                diff={props.financialSummary.turnoverChange?.toString()}
                                symbol="currency" />
                        )}

                        {props.financialSummary.threeYearCagr && (
                            <FinancialStat name={props.years > 3 ? "organisation.financials.cagr" : "organisation.financials.turnoverchange"}
                                value={props.financialSummary.threeYearCagr?.toString()}
                                diff=""
                                symbol="%" />
                        )}

                        {props.financialSummary.grossProfit && (
                            <FinancialStat name="organisation.financials.grossprofit"
                                value={props.financialSummary.grossProfit?.toString()}
                                diff={props.financialSummary.grossProfitChange?.toString()}
                                symbol="currency" />
                        )}

                        {props.financialSummary.operatingProfit && (
                            <FinancialStat name="organisation.financials.operatingprofit"
                                value={props.financialSummary.operatingProfit?.toString()}
                                diff={props.financialSummary.operatingProfitChange?.toString()}
                                symbol="currency" />
                        )}
                    </Row>
                        <Row className="pt-3">
                            {hasRevenue && (
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                    <div className="organisation-card" style={{ height: 350 }}>
                                        <div className="organisation-card-label">
                                            {translate("organisation.summary.headlinenumbers.revenues")}
                                    </div>
                                        <OrganisationLineSeriesChart data={props.revenueGraphData}
                                            colour="#52CAF9"
                                            title={translate("organisation.summary.headlinenumbers.revenues")}
                                            yAxisFormat={(value: any) => `${value}`}
                                            formatValue={(value: any) => {
                                                return format('.2s')(value) + " £";
                                            }} />
                                    </div>
                                </div>
                            )}

                            {hasEmployees && (
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                    <div className="organisation-card" style={{ height: 350 }}>
                                        <div className="organisation-card-label">
                                            {translate("organisation.summary.headlinenumbers.employees")}
                                        </div>
                                        <OrganisationBarChart data={props.employeesGraphData}
                                            colour="#52CAF9"
                                            title={translate("organisation.summary.headlinenumbers.employees")}
                                            yAxisFormat={(value: any) => `${Math.round(value)}`}
                                            formatValue={(value: string) => {
                                                return value + " employees";
                                            }} />
                                    </div>
                                </div>
                            )}
                        </Row>
                </div>
            )}
        </div>
    )
};

export default HeadlineNumbers;
