import React, {useContext, useEffect, useState} from "react";
import OfficerContext from "../../context/officer/OfficerContext";
import {Button, Col, Form, Image, Row, Spinner} from "react-bootstrap";
import OrganisationOfficer from "../../model/organisation/officer/OrganisationOfficer";
import {Select} from "antd";
import OfficerInterest from "../../model/organisation/officer/OfficerInterest";
import {organisationHostname} from "../../utils/Configuration";
import axios from "axios";
import {faRobot, faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTwitter} from "@fortawesome/free-brands-svg-icons";
import {showErrorWithMessage, showGenericErrorDialog, showGenericSuccessDialog} from "../common/CommonAlerts";

const OfficerEdit: React.FC = () => {

    const { officer, setOfficer } = useContext(OfficerContext);

    const [editOfficer, setEditOfficer] = useState<OrganisationOfficer>(officer);
    const onPropertyChange = (event: any) => {
        setEditOfficer({
            ...editOfficer,
            [event.target.name]: event.target.value
        });
    };

    const seconds: number = 10;
    const [timeLeft, setTimeLeft] = useState(seconds);
    const [hasTwitterUser, setHasTwitterUser] = useState(false);

    useEffect(() => {
        if (!timeLeft) {
            axios.get(organisationHostname + "officer/" + officer.twitter + "/has-twitter")
                .then(r => { setHasTwitterUser(true); return; })
                .catch(() => { setHasTwitterUser(false); setTimeLeft(seconds); return;})
        }

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const handleInterestsChange = (interests: string[]) => {
        let officerInterests =
            interests.map(i => {
                return {
                    id: i,
                    officerId: editOfficer.id,
                    name: i,
                    score: 1
                } as OfficerInterest
            });

        setEditOfficer({
            ...editOfficer,
            ["interests"]: officerInterests
        });
    };

    const [saving, setSaving] = useState<boolean>(false);
    const save = async () => {
        setSaving(true);
        await axios.put(organisationHostname + "officer", editOfficer)
            .then(r => {
                showGenericSuccessDialog();
                setOfficer(r.data);
                setEditOfficer(r.data);
            })
            .catch(() => showGenericErrorDialog())
            .finally(() => {
                setSaving(false);
            });
    };

    const gatherDataFromTwitter = async () => {
        setSaving(true);
        await axios.put(organisationHostname + "officer/gather-info-from-twitter", editOfficer)
            .then(r => {
                showGenericSuccessDialog();
                setOfficer(r.data);
                setEditOfficer(r.data);
            })
            .catch(() => showGenericErrorDialog())
            .finally(() => {
                setSaving(false);
            });
    };

    const [loadingAutoGather, setLoadingAutogather] = useState<boolean>(false);
    const fullContactUpdate = () => {
        setLoadingAutogather(true);
        axios.get(organisationHostname + "officer/" + officer.id + "/full-contact")
            .then(r => {
                setOfficer(r.data);
                setEditOfficer(r.data);
                showGenericSuccessDialog();
            })
            .catch((error) => {
                let response = error.response;
                showErrorWithMessage("Error!", "Full contact update failed due to error: " + response.data.message);
            })
            .finally(() => setLoadingAutogather(false));
    };

    const ProfileImage: React.FC = () => {
        const [src, setSrc] = useState<string>(officer.profileImageURL);
        return (
            <Image src={src}
                onError={() => setSrc("/icons_profil.svg")}
                style={{
                    height: "125px",
                    width: "125px",
                    objectFit: "cover",
                    marginRight: "auto",
                    marginLeft: "auto",
                    marginBottom: 15,
                    border: "2px solid #5FD1F9"
                }}
                roundedCircle />
        );
    };

    return (
        <div className="mt-4">
            <Row>
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <div className="organisation-card">
                        <div className="organisation-card-label">
                            Edit {editOfficer.forname} {editOfficer.surname}
                        </div>

                        <ProfileImage />

                        <Form.Group controlId="imageUrl">
                            <Form.Label>Profile Image URL</Form.Label>
                            <Form.Control name="profileImageURL"
                                type="text"
                                onChange={onPropertyChange}
                                value={editOfficer.profileImageURL} />
                        </Form.Group>

                        <Form.Group controlId="biography">
                            <Form.Label>Introduction</Form.Label>
                            <Form.Control name="biography"
                                as="textarea"
                                rows={3}
                                onChange={onPropertyChange}
                                value={editOfficer.biography} />
                        </Form.Group>

                        <Form.Group controlId="nationality">
                            <Form.Label>Nationality</Form.Label>
                            <Form.Control name="nationality"
                                type="text"
                                onChange={onPropertyChange}
                                value={editOfficer.nationality} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Interests</Form.Label>
                            <Select disabled={false}
                                mode="tags"
                                style={{
                                    width: "100%"
                                }}
                                key="widget-multiselect"
                                placeholder="Input interests"
                                size="large"
                                value={editOfficer.interests?.map(i => i.name)}
                                onChange={(val: string[]) => { handleInterestsChange(val) }}
                                filterOption={true} />
                        </Form.Group>
                    </div>
                </Col>
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <div className="organisation-card">
                        <div className="organisation-card-label">
                            Social Handles
                        </div>

                        <Form.Group controlId="twitter">
                            <Form.Label>Twitter</Form.Label>
                            <Form.Control name="twitter"
                                type="text"
                                onChange={onPropertyChange}
                                value={editOfficer.twitter} />
                        </Form.Group>

                        <Form.Group controlId="medium">
                            <Form.Label>Medium</Form.Label>
                            <Form.Control name="medium"
                                type="text"
                                onChange={onPropertyChange}
                                value={editOfficer.medium} />
                        </Form.Group>

                        <Form.Group controlId="crunchbase">
                            <Form.Label>Crunchbase</Form.Label>
                            <Form.Control name="crunchbase"
                                type="text"
                                onChange={onPropertyChange}
                                value={editOfficer.crunchbase} />
                        </Form.Group>

                        <Form.Group controlId="linkedIn">
                            <Form.Label>LinkedIn</Form.Label>
                            <Form.Control name="linkedIn"
                                type="text"
                                onChange={onPropertyChange}
                                value={editOfficer.linkedIn} />
                        </Form.Group>

                        <div>
                            <Button variant="warning"
                                type="submit"
                                disabled={!officer.twitter || loadingAutoGather}
                                onClick={(e: any) => fullContactUpdate()}>
                                <span className="me-2">Auto Gather</span>
                                {loadingAutoGather ? (
                                    <Spinner as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true" />
                                ) : (
                                    <FontAwesomeIcon icon={faRobot} />
                                )}
                            </Button>
                            <button className="iq-button iq-button-primary float-end"
                                onClick={() => save()}>
                                {saving ? (
                                    <span>
                                        <span className="me-2">Saving...</span>
                                        <Spinner as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true" />
                                    </span>
                                ) : (
                                    <span>
                                        <span className="me-2">Save</span>
                                        <FontAwesomeIcon icon={faSave} />
                                    </span>
                                )}
                            </button>
                            <button disabled={!hasTwitterUser} style={{marginRight: 5}} className="iq-button iq-button-primary float-end"
                                onClick={() => gatherDataFromTwitter()}>
                                {saving ? (
                                    <span>
                                        <span className="me-2">Gathering...</span>
                                        <Spinner as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true" />
                                    </span>
                                ) : (
                                    <span>
                                        <span className="me-2">Twitter Gather {!hasTwitterUser && <span>({timeLeft})</span>}</span>
                                        <FontAwesomeIcon icon={faTwitter} />
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>

        </div>
    );
};

export default OfficerEdit;