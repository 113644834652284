import React, {ChangeEvent, useEffect, useState} from "react";
import List from "../../model/list/List";
import {frontEndHostName} from "../../utils/Configuration";
import axios from "axios";
import translate from "../../i18n/translate";
import Form from "react-bootstrap/Form"

interface Props {
    setList: (e: ChangeEvent<HTMLSelectElement>) => void
}

const ContactListFilter: React.FC<Props> = (props: Props) => {

    const [lists, setLists] = useState<List[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        const fetchLists = async () => {
            setLoading(true);

            await axios.get(frontEndHostName + "user-lists")
                .then(r => setLists(r.data))
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchLists();
    }, []);

    return (
        <div>
            <Form.Select className="form-control" disabled={error || loading} onChange={props.setList}>
                <option selected={true} value="null">{translate("contacts.filter.alllists")}</option>
                {lists.map((list: List) => (
                    <option value={list.id}>{list.title} - {translate("lists.companies", { count: list.organisations.length })}</option>
                ))}
            </Form.Select>
        </div>
    );
};

export default ContactListFilter;