import organisation_de from "./organisation_de";
import notifications_de from "./notifications_de";

export default {
    lang: "Deutsch",
    signout: "Ausloggen",
    signingout: "Abmelden",
    months: {
        jan: "Januar",
        feb: "Februar",
        mar: "März",
        apr: "April",
        may: "Mai",
        jun: "Juni",
        jul: "Juli",
        aug: "August",
        sep: "September",
        oct: "Oktober",
        nov: "November",
        dec: "Dezember"
    },
    locations: {
        UK: "United Kingdom",
        FR: "France",
        DE: "Germany",
        US: "United States"
    },
    onboarding: {
        welcome: "Welcome {name}!",
        vendors: "We found the following vendors for your organisation. Would you like to follow them?",
        continue: "Continue",
        typeandselect: "Eingeben und auswählen"
    },
    nav: {
        filters: "Filter",
        recentsearches: "Letzte Suchanfragen"
    },
    errors: {
        loading: "Ladefehler. Bitte den Support kontaktieren.",
        generic: "Ein Fehler ist aufgetreten, bitte versuche es nochmal."
    },
    login: {
        signin: "Login",
        subtitle: "Trage deine Daten unten ein um fortzufahren",
        email: "E-Mail",
        password: "Passwort",
        forgotpassword: "Passwort vergessen?",
        rememberme: "Erinnere mich",
        recoverpassword: "Ihr Passwort wiederhererstellen",
        enteremail: "Geben Sie Ihre Email Adresse unten ein, um das Passwort wiederherzustellen",
        emailsent: "Wiederhergestellte E-mail wurde gesendet",
        backtologin: "Zurück zum Login",
        error: {
            incorrectpassword: "Falsches Passwort",
            maxfailedattempts: "Sie haben Ihre maximale fehlgeschlagene Anmeldeversuche überschritten und wurden gesperrt. Bitte wenden Sie sich an den Support.",
            notactivatedpart1: "User is not activated, please contact our Customer Success Team ",
            notactivatedpart2: " at IQBlade to activate",
            concurrentsession: "Benutzer {user} ist bereits woanders angemeldet.",
            endsession: "Hier stattdessen anmelden?",
            confirmendsession: "Hier anmelden",
            other: "Es gibt Probleme mit der Authentifizierung. Versuchen Sie es nochmal."
        }
    },
    dashboard: {
        additem: "Artikel hinzufügen",
        addnewitem: "Füge einen Artikel zum Dashboard hinzu",
        close: "Schliessen",
        add: "Hinzufügen",
        viewall: "Alle ansehen",
        view: "Ansicht",
        profile: {
            title: "Mein Profil",
            welcome: "Willkommen zurück {name}!",
            listscreated: "{count} Listen kreiert",
            listcompanies: "{count} Unternehmen in listen",
            followedorganisations: "{count} gefolgte Unternehmen",
            followedofficers: "{count} gefolgte Offiziere",
            searchcredits: "{count} suche verbrauchte Kredite",
            creditrefresh: "wird aktualisiert in {days} Tagen"
        },
        followedorganisations: {
            title: "Gefolgte Unternehmen",
            shownames: "Zeige Firmenname"
        },
        twitter: {
            title: "X",
            filteredTitle: "X nach Begriffen gefiltert",
            followed: "Gefolgt",
            all: "Alle",
            organisation: "Organisation",
            officer: "Offizier",
            popoverTitle: "Bedingungen laden",
            popoverMessage: "Gespeicherte Interessen werden geladen"
        },
        globalnews: {
            title: "Globale Nachrichten",
        },
        followednews: {
            title: "Meine Nachrichten"
        },
        filinghistory: {
            title: "Eingereichte Unterlagen"
        },
        videos: {
            title: "Neueste Youtube-Videos",
            filteredTitle: "Neueste YouTube-Videos nach Begriffen gefiltert",
        },
        followedorganisationsyearend: {
            title: "Gefolgte Unternehmen mit bevorstehendem Jahresende",
            limit: "{count} Unternehmen"
        },
        welcome: {
            title: "Willkommen auf Ihrem Dashboard"
        }
    },
    news: {
        title: "Nachrichten"
    },
    lists: {
        title: "Listen",
        createdby: "Kreiert von {username}",
        createdon: "Kreiert am {date}",
        companies: "{count} Unternehmen",
        yours: "Ihres",
        shared: "Geteilt",
        jobs: "Jobs",
        requestjobs: "Request Jobs",
        viewlist: "Liste anzeigen",
        deletelist: "Liste löschen",
        companynumber: "Firmennummer",
        companyname: "Firmenname",
        established: "Gegründet",
        employees: "Mitarbeiter",
        turnover: "Umsatz",
        growth: "Wachstum",
        decline: "Abfall",
        companytype: "Unternehmenstyp",
        companylegaltype: "Company Legal Type",
        deleteselected: "Ausgewählte löschen",
        selectall: "Alle auswählen",
        deselectall: "Alle abwählen",
        overview: "Übersicht",
        dashboard: "Dashboard",
        contacts: "Kontakte",
        listactivity: "Listen Aktivität",
        averagegrossprofit: "Durchschnittlicher Bruttogewinn",
        averagerevenue: "Durchschnittliches Einkommen",
        averageemployees: "Durchschnittliche Mitarbeiter",
        distributionbyrevenue: "Verteilung nach Einnahmen",
        financial: "Finanziell",
        classification: "Klassifikation",
        listcreated: "Listen kreiert",
        createlist: "Liste kreieren",
        transferlist: "Liste transferieren",
        exportselected: "ausgewählte exportieren",
        exportlinkedin: "Für LinkedIn exportieren",
        exportPopoverTitle: "Higher Role Permission Needed",
        exportPopoverMessage:"Please subscribe to a Discovery role, to access this feature.",
        freemiumPopoverTitle: "Upgrade to a paid account",
        freemiumPopoverMessage:"Please, upgrade to a paid account to see full list",
        exportfailed: "Liste kann nicht exportiert werden",
        rowsselected: "{zähle} ausgewählte Reihen",
        deleting: "löschen ...",
        requestcontacts: "Kontakte anfragen",
        exportcontacts: "Kontakte exportieren",
        top5revenue: "Top 5 Einnahmen",
        top5growth: "Top 5 - 3 Jahreswachstum",
        top5decline: "Top 5 - 3 Jahressenkung",
        top5growth1year: "Top 5 - 1 Jahreswachstum",
        top5decline1year: "Top 5 - 1 Jahressenkung",
        deleteconfirm: "Bist du dir sicher, dass du {title} löschen möchtest?",
        deleteconfirmtitle: "Bist du dir sicher?",
        confirmdelete: "Ja, lösche es!",
        deletesuccess: "Erfolgreich gelöscht!",
        deletefailed: "Liste kann nicht gelöscht werden",
        deletecompaniesfailed: "Unable to delete companies",
        deletecompaniesfailedcampaignlsit: "You cannot delete companies from this list because it is being used in a campaign.",
        deletefailedcampaignlist: "You cannot delete this list because it is being used in a campaign.",
        companiesadded: "Unternehmen hinzugefügt von {firstname} {lastname}",
        companiesremoved: "Unternehmen entfernt von {firstname} {lastname}",
        comment: "Kommentar",
        next: "Nächste in der Liste",
        previous: "Zuvor in der Liste"
    },
    teams: {
        title: "Gruppen",
        allteams: "Alle Gruppen",
        createteam: "Gruppe kreieren",
        main: {
            createdby: "Kreiert von {username}",
            createdon: "Kreiert am {date}",
            members: "{count} Mitglieder",
            viewteam: "Gruppe ansehen",
            manage: "Verwalten",
            deleteteam: "Gruppe löschen",
            backtoteams: "Zurück zum Gruppen"
        },
        common: {
            username: "Benuztername",
            total: "Gesamt",
            searches: "Suchen",
        },
        overview: {
            title: "Übersicht",
            lists: "Listen",
            recentactivity: "Letzte Aktivität",
            addedcompanies: "Unternehmen hinzugefügt zu",
            removedcompanies: "Unternehmen entfernt aus",
            commentedon: "kommentiert"
        },
        logins: {
            title: "Logins",
            loginactivity: "Login Aktivität",
            lastloggedin: "Zuletzt eingeloggt",
            time: "Zeit",
            totallogins: "Gesamte Logins",
            usercreatedon: "Benutzer kreiert am",
        },
        lists: {
            user: "Benutzer",
            listevents: "Listen Event",
            listname: "Listenname",
            eventtype: "Event Art",
            sourced: "Sourced from",
            date: "Datum",
            description: "Beschreibung",
            organisations: "Organisationen",
            totallists: "Gesamte Listen",
            totalcompaniesinlists: "Gesamte Unternehmen in der Liste",
            totalfollowedcompanies: "Gesamte gefolgte Unternehmen",
            companiesadded: "Unternehmen hinzugefügt",
            companiesremoved: "Unternehmen entfernt",
            comment: "Kommentar"
        },
        searches: {
            advancedsearchesshared: "Fortgeschrittene Suchen geteilt",
            searchcreditusage: "Suche Kreditverwendung",
            lastrun: "Zuletzt gelaufen",
            search: "Suche Kreditverwendung"
        },
        manage: {
            teamdetails: "Gruppen Details",
            teamname: "Gruppenname",
            savechanges: "Speicher Veränderung",
            addmembers: "Mitglieder hinzufügen",
            usersinteam: "Benutzer in der Gruppe",
            addmember: "Mitglied hinzufügen",
            removemember: "Mitglied entfernen",
            promote: "Als Admin befördern ",
            demote: "Als Admin entfernen"
        }
    },
    advancedsearch: {
        title: "Fortgeschrittene Suche",
        name: "Gebe deiner Suche einen Namen",
        confirmtitle: "Bist du dir sicher?",
        confirm: "Eine neue Suche wird Kredite aufbrauchen. Bist du dir sicher, dass du weiterverfahren willst?",
        unappliedtitle: "You have unsaved changes!",
        unappliedfilters: "You have some unsaved filters in your configuration. Are you sure you want to continue?",
        goback: "Go back",
        search: "Suche",
        enduser: "Endbenutzer",
        channel: "Kanal",
        quickfilters: "Schnelle Filter",
        searchtype: "Suchtyp",
        remainingcredits: "Kredite übrig",
        companiesfound: "Unternehmen gefunden",
        employees: "Mitarbeiter",
        established: "Gegründet",
        hostingproviders: "Hosting-Anbieter",
        locations: "Orte",
        country: "Land",
        reset: "Zurücksetzen",
        morefilters: "Gestalte deine Suche noch weiter unten",
        createdon: "Kreiert am {date}",
        expiringon: "Läuft aus am{date}",
        expiredon: "abgelaufen am {date}",
        to: "bis",
        new: "Neue Suche",
        reuse: "Wiederverwenden",
        rerun: "Wiederholen",
        delete: "Löschen",
        view: "Suche anzeigen",
        expired: "Diese Suche ist am {date} abgelaufen. Bitte führen Sie die Suche erneut aus, um die Ergebnisse anzuzeigen.",
        rerunconfirm: "Wenn Sie eine Suche wiederholen, wird das Guthaben verbraucht und das Ablaufdatum verlängert.",
        createsuccess: "Ihre Suche wurde erfolgreich erstellt",
        perpage: "{count} pro Seite",
        sort: {
            relevance: "Relevanz",
            nameasc: "Firmenname A-Z",
            namedesc: "Firmenname Z-A",
            revenueasc: "Umsatz: Niedrig-Hoch",
            revenuedesc: "Umsatz: Hoch-Niedrig",
            investmentasc: "Investition erworben: Hoch-Niedrig",
            investmentdesc: "Investition erworben: Niedrig-Hoch",
            employeesasc: "Angestellte: Niedrig-Hoch",
            employeesdesc: "Angestellte: Hoch-Niedrig"
        },
        vendors: "Anbieter",
        products: "Produkte",
        location: "Standort (NUTS)",
        tags: "Stichworte",
        accreditations: "Akkreditierungen",
        competencies: "Kompetenzen",
        investmentraised: "Investition angehoben"
    },
    organisation: organisation_de,
    contracts: {
        nav: "Öffentlicher Sektor",
        title: "Aufträge des öffentlichen Sektors",
        notfound: "No contracts found",
        back: "Zurück zum Aufträge",
        contracttitle: "Titel",
        organisation: "Organisation",
        startdate: "Anfangsdatum",
        enddate: "Endtermin",
        publisheddate: "Published Date",
        awarded: "Zuerkannt",
        value: "Wert",
        suppliername: "Name des Anbieters",
        description: "Bezeichnung",
        awardedsupplier: "Zuerkannter Lieferant",
        estimatedvalue: "Geschätzter Gesamtwert",
        follow: "Folgen",
        followed: "Gefolgt",
        showfollowed: "Zeige nur die gefolgten",
        status: "Status",
        tender: "Ausschreibung",
        contractawarded: "Auftrag vergeben",
        unsuccessful: "Erfolglos",
        buyers: "Buyers",
        suppliers: "Anbeiters",
        nomin: "No min",
        nomax: "No max",
        cpvcodes: "CPV Codes",
        save: "Save",
        clearfilters: "Clear Filters",
        filtername: "Filter Name",
        contractsfound: "Contracts found",
        create: "Create Filter",
        searchterm: "Search Term"
    },
    campaigns: {
        nav: "Kampagnen",
        manager: "Kampagnenmanager",
        title: "Kampagnen"
    },
    marketintelligence: {
        title: "Marktinformationen",
        abbr: "M&A",
        investments: "Investitionen",
        sic: {
            title: "Industry Analysis",
            header: "Active Companies by Industry",
            category: "Category",
            description: "Description",
            date: "Date",
            count: "Count",
            prev: "Previous Month",
            variance: "Variance",
            pctchange: "% Change"
        },
        appointments: {
            title: "Ernennungen",
            name: "Name",
            position: "Berufsbezeichnung"
        }
    },
    contacts: {
        title: "Kontakte",
        employment: "Beschäftigung",
        education: "Bildung",
        discoveredon: "Entdeckt am",
        lastmodified: "Geändert am",
        noemployment: "Kontakt hat keine berufliche Laufbahn",
        noeducation: "Kontakt hat keine Ausbildung",
        sort: {
            sortby: "Sortiere nach",
            datediscoveredasc: "Entdeckungsdatum - Aufsteigend",
            datediscovereddesc: "Entdeckungsdatum - Absteigend",
            lastnameasc: "Nachname - Aufsteigend",
            lastnamedesc: "Nachname - Absteigend",
            firstnameasc: "Vorname - Aufsteigend",
            firstnamedesc: "Vorname - Absteigend",
            jobtitleasc: "Berufsbezeichnung - Aufsteigend",
            jobtitledesc: "Berufsbezeichnung - Absteigend"
        },
        filter: {
            filters: "Filter",
            jobtitles: "Berufsbezeichnungen",
            jtplaceholder: "Geben Sie Ihre Berufsbezeichnungen ein",
            seniority: "Seniorität",
            seniorityplaceholder: "Gewünschte Seniorität eingeben",
            roles: "Rollen",
            roleplaceholder: "Gewünschte Rollen eingeben",
            country: "Land",
            countryplaceholder: "Gewünschte Länder eingeben",
            alllists: "Alle Listen",
            apply: "Filter anwenden"
        },
        nocontacts: {
            imported: "Leider haben wir keine Kontaktdaten von Benutzern in Ihrer Organisation",
            organisation: "Leider haben wir keine Kontaktdaten für diese Organisation"
        }
    },
    techsales: {
        nav: "Unsere TD Sales",
        month: "Sales Over Time",
        calendarmonth: "Calendar Month",
        manufacturer: "Sales By Manufacturer",
        category: "Sales By Category",
        enduser: "Sales By End User"
    },
    renewals: {
        nav: "Renewals"
    },
    profile: {
        changepassword: "Passwort ändern",
        currentpassword: "Aktuelles Passwort",
        inputpassword: "Geben Sie Ihr aktuelles Passwort ein",
        newpassword: "Neues Passwort",
        enternewpassword: "Geben Sie Ihr neues Passwort ein",
        repeatpassword: "Passwort wiederholen",
        repeatnewpassword: "Neues Passwort wiederholen",
        avatartwitter: "Avatar von meinem X-Profil hochladen",
        avatardevice: "Avatar von meinem Gerät hochladen",
        chooseavatar: "Avatar auswählen",
        social: {
            twitter: "X (nur der handle/tag)"
        }
    },
    notifications: notifications_de
}
