import React from "react";
import OrganisationOfficer from "../../model/organisation/officer/OrganisationOfficer";
import OfficerAppointment from "../../model/organisation/officer/OfficerAppointment";

const OfficerContext = React.createContext({
    officer: {} as OrganisationOfficer,
    setOfficer: (officer: OrganisationOfficer) => {},
    appointments: [] as OfficerAppointment[],
    setAppointments: (appointments: OfficerAppointment[]) => {}
});

export default OfficerContext;
