import React, {useContext, useEffect, useState} from "react";
import PublicSectorContext, {GCloudFilters, GCloudSort} from "../../../context/PublicSectorContext";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {Form, Image, Modal, Table} from "react-bootstrap";
import Pagination from "../../../utils/PaginationHook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronRight,
    faFilter,
    faSort,
    faSortAmountDown,
    faSortAmountDownAlt
} from "@fortawesome/free-solid-svg-icons";
import {useHistory, useRouteMatch} from "react-router-dom";
import GCloudFiltersModal from "./GCloudFiltersModal";

const GCloud: React.FC = () => {

    let history = useHistory();
    let match = useRouteMatch();

    const {
        gCloudSuppliers, setGCloudSuppliers,
        loadingGCloud, setLoadingGCloud,
        gCloudPage, setGCloudPage,
        gCloudPageSize, setGCloudPageSize,
        gCloudTotal, setGCloudTotal,
        gCloudSort, setGCloudSort,
        gCloudDesc, setGCloudDesc,
        gCloudFilters, setGCloudFilters
    } = useContext(PublicSectorContext);

    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== gCloudPageSize) {
            setGCloudPageSize(size);
            setGCloudPage(1);
        }
    };

    const getSortButton = (s: GCloudSort) => {
        if (s === gCloudSort) {
            return (
                <a className="sort-button pull-right"
                   onClick={() => {
                       setGCloudDesc(!gCloudDesc);
                   }}>
                    <FontAwesomeIcon icon={gCloudDesc ? faSortAmountDown : faSortAmountDownAlt} />
                </a>
            );
        }

        return (
            <a className="sort-button pull-right"
               onClick={() => {
                   setGCloudSort(s);
                   setGCloudDesc(true);
               }}>
                <FontAwesomeIcon icon={faSort} />
            </a>
        );
    };

    interface CompanyLogo {
        imageUrl: string | undefined,
        companyName: string | undefined
    }

    const CompanyLogo: React.FC<CompanyLogo> = (props: CompanyLogo) => {
        const [src, setSrc] = useState<string>(props.imageUrl ? props.imageUrl : "./icons_company.svg");

        return (
            <Image title={props.companyName}
                   style={{
                       height: "25px",
                       width: "25px",
                       objectFit: "cover",
                       border: "2px solid #5FD1F9"
                   }}
                   src={src}
                   onError={() => setSrc("/icons_company.svg")}
                   roundedCircle />
        )
    };

    const [showFilters, setShowFilters] = useState<boolean>(false);
    useEffect(() => {
        setShowFilters(false);
    }, [gCloudFilters]);

    return (
        <div>
            <div className="row row-reverse mb-3 pull-right me-2">
                {(gCloudFilters.searchTerm || gCloudFilters.frameworks?.length > 0) && (
                    <button className="iqx-button secondary md-size pull-right me-3"
                            onClick={() => setGCloudFilters({} as GCloudFilters)}>
                        Clear Filters
                        <FontAwesomeIcon icon={faFilter} className="ms-2"/>
                    </button>
                )}

                <button className="iqx-button primary md-size pull-right"
                        onClick={() => setShowFilters(true)}>
                    Filters
                    <FontAwesomeIcon icon={faFilter} className="ms-2"/>
                </button>
            </div>
            {loadingGCloud ? <IqLoadingIcon /> : (
                <div>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>Supplier Name {getSortButton(GCloudSort.NAME)}</th>
                                <th>Supplier Domain {getSortButton(GCloudSort.DOMAIN)}</th>
                                <th className="text-end">Products</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gCloudSuppliers.map(supplier => (
                                <tr key={supplier.id}>
                                    <td>
                                        <span className="me-2">
                                            <CompanyLogo imageUrl={supplier.organisation?.imageUrl} companyName={supplier.supplierName} />
                                        </span>
                                        <span onClick={() => history.push(`/organisation/${supplier.organisation.id}`)} className="iqx-link">
                                            {supplier.supplierName}
                                        </span>
                                    </td>
                                    <td>{supplier.supplierDomain}</td>
                                    <td className="text-end">{supplier.products.length}</td>
                                    <td className="text-end">
                                        <button className="iqx-button sm-size primary"
                                                onClick={() => history.push(`${match.url}/${supplier.id}`)}>
                                            <span className="me-2">View</span>
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <div className="mt-4">
                        <div className="justify-content-md-end pagination mt-n2">
                            <span className="me-2">
                                <Form.Control as="select"
                                              style={{ minWidth: "13%" }}
                                              defaultValue={gCloudPageSize}
                                              onChange={(event) => changePageSize(event)}
                                              >
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                </Form.Control>
                            </span>
                            <span>
                                {!!gCloudTotal && (
                                    <Pagination totalRecords={gCloudTotal}
                                                pageLimit={gCloudPageSize}
                                                pageRangeDisplayed={1}
                                                initPage={gCloudPage}
                                                onChangePage={setGCloudPage}
                                    />
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            <Modal show={showFilters}
                   onHide={() => setShowFilters(false)}
                   size="lg">
                <GCloudFiltersModal />
            </Modal>
        </div>
    );
}

export default GCloud;