import React, {useContext, useEffect, useState} from 'react';
import {Row, Table} from 'react-bootstrap';
import './OrganisationSummary.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";
import L, {latLngBounds, LatLngTuple, Layer} from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import Organisation from "../../model/organisation/Organisation";
import UserOrganisationContext from '../../context/organisation/OrganisationContext';
import IqLoadingIcon from "../common/IqLoadingIcon";
import OrganisationSocialHandles from './social/OrganisationSocialHandles';
import OrganisationSummaryPeople from './OrganisationSummaryPeople';
import OrganisationSummaryDetails from './OrganisationSummaryDetails';
import HeadlineNumbers from "./HeadlineNumbers";
import EmployeeEstimations from "../../model/organisation/EmployeeEstimations";
import OrganisationSummaryFilingHistory from './OrganisationSummaryFilingHistory';
import translate from "../../i18n/translate";
import OrganisationSocialContext from "../../context/organisation/OrganisationSocialContext";
import OrganisationAccreditations from "./accreditations/OrganisationAccreditations";
import GeoLocation from "../../model/organisation/GeoLocation";
import {organisationHostname} from "../../utils/Configuration";
import axios from "axios";
import FundingDetails from "../../model/organisation/FundingDetails";
import {format} from "d3-format";
import DateFormatter from "../../utils/formatters/DateFormatter";
import {useHistory} from "react-router-dom";
import {Switch} from "antd";
import {faNewspaper} from "@fortawesome/free-solid-svg-icons";
import ArticleLink from "../marketintelligence/ArticleLink";
import OrganisationIsicClassification from './summary/OrganisationIsicClassification';
import PreviousName from "../../model/organisation/PreviousName";
import OrganisationCompanyStructure from "./OrganisationCompanyStructure";
import {MergerDetail} from "../../model/organisation/MergerDetail";
import OrganisationMergerDetails from "./OrganisationMergerDetails";
import AppointmentDetails from "../../model/organisation/AppointmentDetails";
import {GazetteDetail} from '../../model/organisation/GazetteDetail';
import OrganisationGazetteDetails from './OrganisationGazetteDetails';

let DefaultIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: icon,
    iconRetinaUrl: iconRetina,
    shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;

interface Props {
    temporary: boolean
}

const OrganisationSummary: React.FC<Props> = (props: Props) => {
    let history = useHistory();

    const [revenueGraphData, setRevenueGraphData] = useState([] as any);
    const [employeesGraphData, setEmployeesGraphData] = useState([] as any);
    const [loading, setLoading] = useState(true);
    const {organisation, setOrganisation} = useContext(UserOrganisationContext);
    const [numOfGeoLocations, setNumOfGeoLocations] = useState<number>(0);
    const [formattedAddress, setFormattedAddress] = useState("");
    const [geoPosition, setGeoPosition] = useState<LatLngTuple>([51.505, -0.09]);
    const [showAllLocations, setShowAllLocations] = useState<boolean>(true);
    const [mergeDetails, setMergeDetails] = useState<MergerDetail[]>([]);
    const [gazetteDetails, setGazetteDetails] = useState<GazetteDetail[]>([]);
    const [apptDetails, setApptDetails] = useState<AppointmentDetails[]>([]);

    const formatOfficeAddress = (location: GeoLocation) => {
        let formattedAddress = [];
        if (location.address1 != null && location.address1.trim() !== "") formattedAddress.push(location.address1);
        if (location.address2 != null && location.address2.trim() !== "") formattedAddress.push(location.address2);
        if (location.town != null && location.town.trim() !== "") formattedAddress.push(location.town);
        if (location.postCode != null && location.postCode.trim() !== "") formattedAddress.push(location.postCode);
        return formattedAddress.join(", ");
    };

    useEffect(() => {
        const getNumberOfGeoLocations = async () => {
            await axios.get(organisationHostname + "organisation/geolocations?id=" + organisation.id)
                .then(response => setNumOfGeoLocations(response.data));
        }

        if (organisation && organisation.id) {
            getNumberOfGeoLocations()
        }
    }, [organisation])

    useEffect(() => {
        let formattedAddress = [];
        if (organisation.addressLine1 != null && organisation.addressLine1.trim() !== "") formattedAddress.push(organisation.addressLine1);
        if (organisation.addressLine2 != null && organisation.addressLine2.trim() !== "") formattedAddress.push(organisation.addressLine2);
        if (organisation.postTown != null && organisation.postTown.trim() !== "") formattedAddress.push(organisation.postTown);
        if (organisation.postCode != null && organisation.postCode.trim() !== "") formattedAddress.push(organisation.postCode);

        setFormattedAddress(formattedAddress.join(", "));
        if (organisation.latitude != null && organisation.longitude != null) {
            setGeoPosition([organisation.latitude, organisation.longitude]);
        }
    }, [organisation]);

    useEffect(() => {
        const buildGraphs = (data: Organisation) => {
            data.detailedFinancials?.forEach((oyv: { turnover: any; financialYear: any; numberOfEmployees: any }) => {
                setRevenueGraphData((revenueGraphData: any) => [
                        ...revenueGraphData,
                        {
                            y: oyv.turnover,
                            x: oyv.financialYear
                        }
                    ]
                        .sort((a, b) => (a.x > b.x) ? 1 : ((b.x > a.x) ? -1 : 0)) // sort by year in ascending order
                );
            });

            data.employeeEstimations?.forEach((estimate: EmployeeEstimations) => {
                setEmployeesGraphData((existing: any) => [
                        ...existing,
                        {
                            x: estimate.financialYear,
                            y: estimate.estimatedNumber
                        }
                    ]
                        .sort((a, b) => (a.x > b.x) ? 1 : ((b.x > a.x) ? -1 : 0))
                )
            });

            //Populate remaining years since 2011
            const existingYears: number[] = data.detailedFinancials?.map((oyv: { turnover: any; financialYear: any; numberOfEmployees: any }) => {
                return oyv.financialYear;
            });

            let currentYear = new Date().getFullYear();
            let startYear = organisation?.id?.startsWith("DE") || organisation?.id?.startsWith("FR") ? currentYear - 3 : currentYear - 5;
            for (let i = startYear; i <= currentYear; i++) {
                if (!existingYears?.includes(i)) {
                    setRevenueGraphData((revenueGraphData: any) => [
                            ...revenueGraphData,
                            {
                                y: null,
                                x: i
                            }
                        ]
                            .sort((a, b) => (a.x > b.x) ? 1 : ((b.x > a.x) ? -1 : 0)) // sort by year in ascending order
                    );

                    setEmployeesGraphData((employeesGraphData: any) => [
                            ...employeesGraphData,
                            {
                                y: null,
                                x: i
                            }
                        ]
                            .sort((a, b) => (a.x > b.x) ? 1 : ((b.x > a.x) ? -1 : 0)) // sort by year in ascending order
                    );
                }
            }
        };

        const formatData = async () => {
            buildGraphs(organisation);
            setLoading(false);
        };

        formatData();
    }, []);

    const stripHtml = (bio: string) => {
        if (bio) {
            return bio.replace(/(<([^>]+)>)/gi, "").trim();
        }
    };

    const IndustrialClassification: React.FC = () => {

        enum ClassificationType {
            Sector = "organisation.summary.classification.sector",
            IndustryGroup = "organisation.summary.classification.industrygroup",
            Industry = "organisation.summary.classification.industry",
            SubIndustry = "organisation.summary.classification.subindustry",
        }

        const getInitialSelection = (): string => {
            let selection = ClassificationType.Sector;
            if (organisation.industryClassification?.industryGroup != null) {
                selection = ClassificationType.IndustryGroup;
            }
            if (organisation.industryClassification?.industry != null) {
                selection = ClassificationType.Industry;
            }
            if (organisation.industryClassification?.subIndustry != null) {
                selection = ClassificationType.SubIndustry;
            }
            return selection;
        }
        const [selected, setSelected] = useState<string>(getInitialSelection());


        return (
            <div className={hasSocials() ? "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4" : "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"}>
                <div className="organisation-card" style={{height: "100%"}}>
                    <div className="organisation-card-label">
                        <span onClick={() => setSelected(ClassificationType.Sector)}
                              style={{cursor: "pointer"}}
                              className={selected === ClassificationType.Sector ? "selected" : ""}>
                            {translate(ClassificationType.Sector)}
                        </span>
                        <span> / </span>
                        <span onClick={() => setSelected(ClassificationType.IndustryGroup)}
                              style={{cursor: "pointer"}}
                              className={selected === ClassificationType.IndustryGroup ? "selected" : ""}>
                            {translate(ClassificationType.IndustryGroup)}
                        </span>
                        <span> / </span>
                        <span onClick={() => setSelected(ClassificationType.Industry)}
                              style={{cursor: "pointer"}}
                              className={selected === ClassificationType.Industry ? "selected" : ""}>
                            {translate(ClassificationType.Industry)}
                        </span>
                        <span> / </span>
                        <span onClick={() => setSelected(ClassificationType.SubIndustry)}
                              style={{cursor: "pointer"}}
                              className={selected === ClassificationType.SubIndustry ? "selected" : ""}>
                            {translate(ClassificationType.SubIndustry)}
                        </span>
                    </div>
                    <div className="organisation-card-text">
                        {selected === ClassificationType.Sector && (organisation.industryClassification?.sector?.name || "-")}
                        {selected === ClassificationType.IndustryGroup && (organisation.industryClassification?.industryGroup?.name || "-")}
                        {selected === ClassificationType.Industry && (organisation.industryClassification?.industry?.name || "-")}
                        {selected === ClassificationType.SubIndustry && (organisation.industryClassification?.subIndustry?.name || "-")}

                    </div>
                </div>
            </div>
        )
    };

    const {socialHandles} = useContext(OrganisationSocialContext);
    const hasSocials = () => {
        return socialHandles.id != null;

    };

    const [fundingDetails, setFundingDetails] = useState<FundingDetails[]>([]);
    useEffect(() => {
        const fetchFunding = async () => {
            await axios.get(organisationHostname + "organisation/" + organisation.id + "/funding")
                .then(r => setFundingDetails(r.data));
        };

        if (organisation && organisation.id) {
            fetchFunding();
        }
    }, [organisation]);

    const showMap = () => {
        return organisation.geoLocations &&
            organisation.geoLocations
                .filter(location => location.latitude !== null && location.longitude !== null).length > 0;
    };

    const [map, setMap] = useState<L.Map>();
    useEffect(() => {
        const createMap = () => {
            let m = L.map("map", {
                center: geoPosition,
                zoom: 17
            });

            L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                attribution: "&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            }).addTo(m);

            setMap(m);
        };

        if (showMap() && !loading) {
            createMap();
        }
    }, [loading]);

    useEffect(() => {
        const updateLocations = () => {
            const blueIcon = new L.Icon({
                iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
                shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
            });

            const orangeIcon = new L.Icon({
                iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
                shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
            });

            if (map) {
                map.eachLayer((l: Layer) => {
                    if (!('_url' in l)) {
                        l.removeFrom(map);
                    }
                });

                let markerBounds = latLngBounds([]);
                organisation.geoLocations
                    .filter(location => location.latitude !== null && location.longitude !== null)
                    .filter(location => location.addressType === 'HEAD_OFFICE')
                    .forEach(location => {
                        let popup = L.popup()
                            .setLatLng([location.latitude, location.longitude])
                            .setContent('<div>' + formatOfficeAddress(location) + '<br>' + (!!location.officerIdentifier ?
                                "NIC Code: " + location.officerIdentifier : "") + '</div>');

                        L.marker([location.latitude, location.longitude], {icon: blueIcon, zIndexOffset: 9999})
                            .bindPopup(popup)
                            .addTo(map);

                        markerBounds.extend([location.latitude, location.longitude]);
                    });

                if (showAllLocations) {
                    organisation.geoLocations
                        .filter(location => location.latitude !== null && location.longitude !== null)
                        .filter(location => location.addressType !== 'HEAD_OFFICE')
                        .forEach(location => {
                            let popup = L.popup()
                                .setLatLng([location.latitude, location.longitude])
                                .setContent('<div>' + formatOfficeAddress(location) + '</div>');

                            L.marker([location.latitude, location.longitude], {icon: orangeIcon})
                                .bindPopup(popup)
                                .addTo(map);

                            markerBounds.extend([location.latitude, location.longitude]);
                        });
                }

                map.fitBounds(markerBounds);
                map.zoomOut(1);
            }
        };

        updateLocations();
    }, [map, showAllLocations]);

    useEffect(() => {
        const getMergerDetails = async () => {
            await axios.get(organisationHostname + "organisation/mergerdetails?id=" + organisation.id)
                .then(r => setMergeDetails(r.data))
                .finally(() => setLoading(false));
        };

        if (organisation && organisation.id) {
            getMergerDetails();
        }
    },[organisation])

    useEffect(() => {
        const getGazetteDetails = async () => {
            await axios.get(organisationHostname + "gazette/organisation/" + organisation.id)
                .then(r => setGazetteDetails(r.data))
                .finally(() => setLoading(false));
        };

        if (organisation && organisation.id) {
            getGazetteDetails();
        }
    },[organisation])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await axios.get(organisationHostname + "organisation/appointment-details-merged?id=" + organisation.id)
                .then(r => setApptDetails(r.data))
                .finally(() => setLoading(false));
        };

        if (organisation && organisation.id) {
            fetchData();
        }
    }, [organisation]);

    return (
        <div style={{marginLeft: 0, marginRight: 10, overflow: "hidden"}}>
            {loading ? <div style={{ paddingTop: 35 }}><IqLoadingIcon/></div> : (
                <div>
                    <Row className="pt-3">

                        <div className={hasSocials() ? "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 " : " col-12 col-sm-12 col-md-12 col-lg-12  col-xl-12"}>
                            <div className="organisation-card">
                                <div className="organisation-card-label">
                                    {translate("organisation.summary.introduction")}
                                </div>
                                <div className="organisation-card-text">
                                    {!organisation.companyIntroduction ? (
                                        <>There is no introduction available for this company.</>
                                    ) : (
                                        <div>
                                            {stripHtml(organisation.companyIntroduction)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {(!props.temporary && hasSocials()) && (
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                <div className="organisation-card" style={{height: "auto"}}>
                                    <div className="organisation-card-label">
                                        {translate("organisation.summary.socials")}
                                    </div>
                                    <OrganisationSocialHandles/>
                                </div>
                            </div>
                        )}
                    </Row>
                    <Row className="pt-3">
                        <IndustrialClassification/>
                        {(organisation.sicCodes?.length > 0
                        || organisation.wz2008Codes?.length > 0
                        || organisation.usSicCodes?.length > 0
                        || organisation.nafCodes?.length > 0) &&
                            <OrganisationIsicClassification/>}

                        {organisation.tags?.length !== 0 && (
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                                <div className="organisation-card">
                                    <div className="organisation-card-label">
                                        {translate("organisation.summary.tags")}
                                    </div>
                                    <div className="organisation-card-text">
                                        {organisation.tags?.map((tag: any) => (
                                            <div className="badge badge-primary badge-tag">{tag.name}</div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Row>

                    <Row className="pt-3">

                        {organisation.competencies?.length !== 0 && (
                            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">

                                <div className="organisation-card">
                                    <div className="organisation-card-label">
                                        {translate("organisation.summary.competencies")}
                                    </div>
                                    <div className="organisation-card-text">
                                        {organisation.competencies?.map((competency: string) => (
                                            <div className="badge badge-primary badge-tag">{competency}</div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                        {organisation.accreditations?.length !== 0 && (
                            <OrganisationAccreditations accreditations={organisation.accreditations}/>
                        )}
                    </Row>

                    {(organisation && organisation.financialSummary?.length > 0) &&
                        <HeadlineNumbers financialSummary={organisation?.financialSummary[0]}
                                         years={organisation?.detailedFinancials?.length || 0}
                                         revenueGraphData={revenueGraphData}
                                         employeesGraphData={employeesGraphData}
                        />
                    }

                    <Row className="pt-3">
                        <div className="col col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="iq-headline">
                                <span className="text">
                                    {translate("organisation.summary.companydetails")}
                                </span>
                            </div>
                        </div>
                    </Row>
                    <Row className="pt-3">
                        <div className="col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="organisation-card" style={{height: "auto"}}>
                                <div className="organisation-card-label">
                                    {translate("organisation.summary.companyinfo.title")}
                                </div>
                                <OrganisationSummaryDetails address={formattedAddress}/>
                            </div>

                            {organisation?.previousNames?.length > 0 && (
                                <div className="organisation-card mt-2" style={{height: "auto"}}>
                                    <div className="organisation-card-label">
                                        {translate("organisation.previousnames.title")}
                                    </div>
                                    <Table striped hover>
                                        <thead>
                                        <tr>
                                            <th>{translate("organisation.previousnames.name")}</th>
                                            <th>{translate("organisation.previousnames.effectivefrom")}</th>
                                            <th>{translate("organisation.previousnames.ceased")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {organisation?.previousNames
                                            .sort((a: PreviousName, b: PreviousName) => new Date(b.ceased).getTime() - new Date(a.ceased).getTime())
                                            .map((name: PreviousName) => (
                                                <tr>
                                                    <td>
                                                <span className="text-uppercase">
                                                    {name.companyName}
                                                </span>
                                                    </td>
                                                    <td>{DateFormatter.formatDate(name.effectiveFrom)}</td>
                                                    <td>{DateFormatter.formatDate(name.ceased)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            )}

                            {fundingDetails?.length > 0 && (
                                <div className="organisation-card mt-2" style={{height: "auto"}}>
                                    <div className="organisation-card-label">
                                        {translate("organisation.summary.funding.title")}
                                    </div>

                                    <Table striped hover>
                                        <thead>
                                        <tr>
                                            <th>{translate("organisation.summary.funding.date")}</th>
                                            <th>{translate("organisation.summary.funding.round")}</th>
                                            <th>{translate("organisation.summary.funding.value")}</th>
                                            <th>{translate("organisation.summary.funding.leadinvestor")}</th>
                                            <th className="text-end">
                                                <span>
                                                    <FontAwesomeIcon icon={faNewspaper} size="1x"/>
                                                </span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {fundingDetails.map((details: FundingDetails) => (
                                            <tr>
                                                <td>{DateFormatter.formatDate(details.identifiedOn)}</td>
                                                <td>
                                                    <span className="text-uppercase">
                                                        {details.type}
                                                    </span>
                                                </td>
                                                <td>
                                                    {format(',')(details.value)} {details.currency}
                                                </td>
                                                <td>
                                                    {details.leadingOrganisationId ? (
                                                        <a className="iqx-link" onClick={() => history.push("/organisation/" + details.leadingOrganisationId)}>
                                                            {details.leadingOrganisation}
                                                        </a>
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </td>
                                                <td className="text-end">
                                                    <ArticleLink articleId={details.articleUuid}/>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </div>
                            )}
                        </div>
                        <div className="col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="organisation-card" style={{height: "auto"}}>
                                <div className="organisation-card-label">
                                    {translate("organisation.people.title")}
                                    <span style={{float: "right"}}>
                                        <span
                                            className="pe-2">{translate("organisation.summary.linkedinsearch.poweredby")}</span>
                                        <FontAwesomeIcon icon={faLinkedin} color="#2E67C2" size="lg"/>
                                    </span>
                                </div>
                                <OrganisationSummaryPeople/>
                            </div>
                            {organisation?.filingHistoryFR?.length > 0 && (
                                <div className="organisation-card mt-2" style={{height: "auto"}}>
                                    <div className="organisation-card-label">
                                        {translate("organisation.summary.filinghistory.name")}
                                    </div>
                                    <OrganisationSummaryFilingHistory/>
                                </div>
                            )}
                            {organisation?.filingHistory?.length > 0 && (
                                <div className="organisation-card mt-2" style={{height: "auto"}}>
                                    <div className="organisation-card-label">
                                        {translate("organisation.summary.filinghistory.name")}
                                    </div>
                                    <OrganisationSummaryFilingHistory/>
                                </div>
                            )}
                        </div>
                    </Row>

                    {(/*mergeDetails.length > 0 || */apptDetails.length > 0) &&
                        <div className="pt-3">
                            <div className="organisation-card mt-2" style={{height: "auto"}}>
                                <div className="organisation-card-label">
                                    {translate("Market Intelligence")}
                                </div>
                                <OrganisationMergerDetails mergerDetails={mergeDetails} appointmentDetails={apptDetails}/>
                            </div>
                        </div>
                    }

                    {(gazetteDetails.length > 0) &&
                        <div className="pt-3">
                            <div className="organisation-card mt-2" style={{height: "auto"}}>
                                <div className="organisation-card-label">
                                    {translate("Market Intelligence Risk")}
                                </div>
                                <OrganisationGazetteDetails gazetteDetails={gazetteDetails}/>
                            </div>
                        </div>
                    }

                    {organisation?.organisationGroupStructure?.length > 0 &&
                        <Row className="pt-3">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="organisation-card" style={{height: 200}}>
                                    <div className="organisation-card-label">
                                        {"Company Structure"}
                                    </div>
                                    <OrganisationCompanyStructure/>
                                </div>
                            </div>
                        </Row>
                    }

                    {showMap() && (
                        <Row className="pt-3">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="organisation-card" style={{height: 800}}>
                                    <div className="organisation-card-label">
                                        {translate("organisation.summary.map.title")}
                                    </div>
                                    <div className="mb-2">
                                        <span className="float-end">
                                            <span className="pe-2">
                                                {translate("organisation.summary.map.locations")}
                                            </span>
                                            <span>
                                                <Switch checked={showAllLocations}
                                                        onChange={(value: boolean) => setShowAllLocations(value)}/>
                                            </span>
                                        </span>
                                        {numOfGeoLocations > 50 && (
                                            <span>
                                                There are {numOfGeoLocations} Sub Offices for this organisation, only displaying 50.
                                            </span>
                                        )}
                                    </div>

                                    <div id="map" style={{height: "100%", width: "100%"}}/>
                                </div>
                            </div>
                        </Row>
                    )}
                </div>
            )}
        </div>
    );
};

export default OrganisationSummary;
