import React, {useEffect, useState} from 'react';
import './Lists.css';
import {Alert, Button, Col, Container, Modal, OverlayTrigger, Popover, Row} from 'react-bootstrap';
import ListLink from "./ListLink";
import axios from "axios";
import List from "../../model/list/List";
import {frontEndHostName} from "../../utils/Configuration";
import IqLoadingIcon from "../common/IqLoadingIcon";
import translate from "../../i18n/translate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from 'react-router-dom';
import {getUserUuid} from "../../utils/Security";
import {showErrorWithMessage, showGenericErrorDialog, showSuccessWithMessage} from "../common/CommonAlerts";

const Lists: React.FC = () => {

    let history = useHistory();
    const [userLists, setUserLists] = useState<List[]>([]);
    const [sharedLists, setSharedLists] = useState<List[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const fetchLists = async () => {
        setIsLoading(true);
        let login: string | null = localStorage?.getItem("login");
        if (login != null) {

            await axios.get(frontEndHostName + "lists")
                .then(r => {
                    setUserLists(r.data as List[]);
                    setSharedLists(r.data as List[]);
                })
                .catch(error => console.error(error.message))
                .finally(() => setIsLoading(false));
        }
    };

    useEffect(() => {
        fetchLists();
    }, []);

    const deleteList = (id: number) => {
        let filtered = userLists.filter(l => l.id !== id);
        setUserLists(filtered);
    };

    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [newListName, setNewListName] = useState<string>();
    const [hasCreateCapacity, setCreateCapacity] = useState<boolean>(false);
    const [loadingCreateCapacity, setLoadingCreateCapacity] = useState<boolean>(false);
    useEffect(() => {
        const getCreateCapacity = () => {
            setLoadingCreateCapacity(true);
            axios.get(frontEndHostName + "has-create-capacity")
                .then(r => setCreateCapacity(r.data))
                .finally(() => setLoadingCreateCapacity(false));
        };

        getCreateCapacity();
    }, [userLists]);

    const handleClose = () => {
        setShowCreateModal(false);
        setNewListName("");
    };

    const [creatingList, setCreatingList] = useState<boolean>(false);
    const showSuccessDialog = () => {
        showSuccessWithMessage("Success!", translate("lists.listcreated"))
            .then((result) => {
                accept();
            });
    }

    const createList = () => {
        setCreatingList(true);
        let list = {
            title: newListName,
            listType: "USER_DEFINED",
            organisations: []
        };

        axios.post(frontEndHostName + "user-lists", list)
            .then(() => {
                showSuccessDialog();
                setShowCreateModal(false);
                setNewListName("");
            })
            .catch((error) => {
                let responseData = error.response.data;
                if (responseData.errorKey === "listexists") {
                    showErrorWithMessage("List already exists.", translate("organisation.addtolist.duplicatename"))
                }
                else {
                    showGenericErrorDialog();
                }
            })
            .finally(() => setCreatingList(false));
    };

    const accept = () => {
        setNewListName("");
        fetchLists();
    };

    const enterHandler = (e: any) => {
        if (e.key === 'Enter' && newListName?.length !== 0) {
            createList();
        }
    };

    const getShowSharedLists = () => {
        const sharedLists = localStorage.getItem(SHARED_LISTS_KEY);
        if (!sharedLists) {
            return false;
        }

        return Boolean(JSON.parse(sharedLists));
    }

    const SHARED_LISTS_KEY = "SHOW_SHARED_LISTS";
    const [showShared, setShowShared] = useState<boolean>(getShowSharedLists());
    const toggleShowShared = () => {
        const value = !showShared;
        localStorage.setItem(SHARED_LISTS_KEY, String(value));
        setShowShared(value);
    }

    const filterSharedLists = (list: List) => {
        if (showShared) {
            return true;
        }

        return list.userUuid === getUserUuid();
    }

    return (
        <Container fluid style={{ marginTop: 15 }}>
            <Row className="" style={{ marginLeft: 0, marginRight: 0 }}>
                <Col>
                    <div className="iq-headline">
                        <span className="text">
                            {translate("lists.title")}
                        </span>
                    </div>
                </Col>

                <Col className="text-end col-9">
                    <span style={{ marginLeft: "auto" }}>
                        <span className="me-2">
                            <button onClick={toggleShowShared} className="iqx-button md-size dark-blue">
                                <span style={{ paddingRight: 10 }}>{showShared ? "Hide" : "Show"} shared lists</span>
                                {showShared ?
                                    <FontAwesomeIcon icon={faEyeSlash} color="white" size="1x"/> :
                                    <FontAwesomeIcon icon={faEye} color="white" size="1x"/>
                                }
                            </button>
                        </span>
                        <span className="me-2">
                            <button onClick={() => { history.push("/upload") }} className="iqx-button md-size dark-blue mt-1">
                                <span style={{ paddingRight: 10 }}>Upload CSV</span>
                                <FontAwesomeIcon icon={faPlusCircle} color="white" size="1x" />
                            </button>
                        </span>
                        <span className="me-2">
                            {!hasCreateCapacity ? (
                                <OverlayTrigger placement="top"
                                                overlay={
                                                    <Popover id="list-capacity">
                                                        <div>
                                                            {translate("organisation.addtolist.maxlists")}
                                                        </div>
                                                    </Popover>
                                                }>
                                    <button onClick={() => { setShowCreateModal(true) }} className="iqx-button md-size dark-blue mt-1" disabled={true}>
                                        <span style={{ paddingRight: 10 }}>{translate("lists.createlist")}</span>
                                        <FontAwesomeIcon icon={faPlusCircle} color="white" size="1x" />
                                    </button>
                                </OverlayTrigger>
                            ) : (
                                <button onClick={() => { setShowCreateModal(true) }} className="iqx-button md-size dark-blue mt-1" disabled={!hasCreateCapacity}>
                                    <span style={{ paddingRight: 10 }}>{translate("lists.createlist")}</span>
                                    <FontAwesomeIcon icon={faPlusCircle} color="white" size="1x" />
                                </button>
                            )}
                        </span>
                    </span>
                </Col>
            </Row>

            {isLoading ? <IqLoadingIcon /> : (
                error ?
                    <Alert className="mt-2" variant="danger">
                        {translate("errors.loading")}
                   </Alert>
                    :
                    <div id="lists-list-container">
                        {userLists.filter(filterSharedLists).map(list => (
                            <ListLink list={list}
                                      key={list.id}
                                      ribbonText={list.shares.length > 0 ? "lists.shared" : "lists.yours"}
                                      deleteList={deleteList} />
                        ))}
                    </div>
            )}

            <Modal show={showCreateModal} onHide={handleClose} dialogClassName="modal-dialog modal-full-height modal-right modal-notify modal-success">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {translate("lists.createlist")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2">
                        <input type="text"
                               className="form-control"
                               onKeyPress={enterHandler}
                               placeholder={translate("teams.lists.listname")}
                               value={newListName}
                               onChange={(e: any) => setNewListName(e.target.value)} />
                    </div>

                    {creatingList && <IqLoadingIcon />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {translate("dashboard.close")}
                    </Button>
                    <Button variant="success"
                            disabled={!newListName || newListName.length === 0}
                            onClick={createList}>
                        {translate("lists.createlist")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default Lists;
