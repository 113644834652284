import {Hint, HorizontalGridLines, VerticalBarSeries, XAxis, XYPlot, YAxis} from "react-vis";
import React, {useState} from "react";
import {AutoSizer} from "react-virtualized";

interface Props {
    data: any[],
    colour: string,
    title: string,
    formatValue: (value: string) => {},
    yAxisFormat: (value: any) => string
}

const DashboardBarChart: React.FC<Props> = (props: Props) => {

    const [hover, setHover] = useState<any>();

    const xMin = props.data.reduce((min: number, p: { x: number; }) => p.x < min ? p.x : min, props.data[0].x);
    const xMax = props.data.reduce((max: number, p: { x: number; }) => p.x > max ? p.x : max, props.data[0].x);

    const getData = () => {
        let data = props.data;

        if (data.length > 1) {
            return data;
        }
        else {
            return [
                {
                    x: xMin - 1,
                    y: null
                },
                ...data
            ];
        }
    };

    return (
        <AutoSizer>
            {({ height, width}) => (
                <XYPlot width={width}
                        height={300}
                        xDomain={[xMin, xMax]}>
                    <HorizontalGridLines />
                    <VerticalBarSeries color={props.colour}
                                       barWidth={0.9}
                                       data={getData()}
                                       onValueMouseOver={(v: any) => {
                                           setHover(v);
                                       }}
                                       onValueMouseOut={(v: any) => {
                                           setHover(null);
                                       }}/>

                    <XAxis tickFormat={val => Math.round(val) === val ? val : ""} title="Year"/>
                    <YAxis tickFormat={v => props.yAxisFormat(v)} title={props.title}/>

                    {hover && (
                        <Hint value={hover}>
                            <div style={{
                                display: 'flex',
                                color: '#fff',
                                background: '#000',
                                opacity: 0.9,
                                alignItems: 'center',
                                padding: '5px',
                                borderRadius: '5px'
                            }}>
                                <div>{hover.x}: {props.formatValue(hover.y)}</div>
                            </div>
                        </Hint>
                    )}
                </XYPlot>
            )}
        </AutoSizer>
    );
};

export default DashboardBarChart;
