import React, {useEffect, useState} from "react";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {frontEndHostName, organisationHostname} from "../../utils/Configuration";
import {OrganisationUser} from "../teams/edit/EditTeam";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import translate from "../../i18n/translate";
import {Typeahead} from "react-bootstrap-typeahead";
import {useForm} from "react-hook-form";
import {faChevronLeft, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ResetPasswordFormForUser from "../auth/ResetPasswordForUser";
import {Role} from "../../utils/Security";
import {ListOrganisation} from "../../model/organisation/ListOrganisation";
import {showErrorWithMessage, showGenericErrorDialog, showGenericSuccessDialog} from "../common/CommonAlerts";

enum PermissionMapping {
    LIST_CAPACITY = "NUMBER OF LISTS ALLOWED",
    TOTAL_COMPANIES_IN_LISTS = "MAX NUMBER OF COMPANIES PER LIST",
    FOLLOWED_COMPANIES = "MAX FOLLOWED COMPANIES",
    INTERESTS_CAPACITY = "MAX NUMBER OF INTERESTS",
    SEARCH_CAPACITY = "MAX NUMBER OF SEARCHES",
    SEARCH_ACCESS_USE_CAPACITY = "MAX SEARCHES PER MONTH",
    FOLLOWED_OFFICERS = "MAX FOLLOWED OFFICERS",
    NEWS_PREFERENCES = "MAX NEWS FILTERS",
    CONTACT_REQUESTS_CAPACITY = "MAX NUMBER OF CONTACT REQUESTS",
    FOLLOWED_CONTRACTS = "MAX FOLLOWED CONTRACTS",
    PDF_EXPORT = "PDF EXPORTS PER MONTH"
}

type PermissionMappingAsKey = keyof typeof PermissionMapping;

const permissionStyle = {
    fontSize: "0.7rem",
    fontWeight: 700,
    fontStyle: "italic",
    color: "#0069d9"
}

const UserEdit: React.FC = () => {
    let history = useHistory();
    let location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/admin/users/:userId',
        exact: false,
        strict: false
    });

    const { register, handleSubmit, errors, setValue } = useForm();

    const [user, setUser] = useState<OrganisationUser>({} as OrganisationUser);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true);

            await axios.get(frontEndHostName + "users/" + childMatchedPath?.params.userId)
                .then(r => setUser(r.data))
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchUser();
    }, []);

    const [loadingOrganisation, setLoadingOrganisation] = useState<boolean>(false);
    const [userOrganisation, setUserOrganisation] = useState<ListOrganisation>();
    useEffect(() => {
        const fetchUserOrganisation = async () => {
            setLoadingOrganisation(true);
            await axios.get(organisationHostname + "organisation/" + user.organisationId)
                .then(r => setUserOrganisation(r.data))
                .finally(() => setLoadingOrganisation(false));
        };

        if (user && user.organisationId) {
            fetchUserOrganisation();
        }
    }, [user.organisationId]);

    const [loadingAuthorities, setLoadingAuthorities] = useState<boolean>(false);
    const [authoritiesError, setAuthoritiesError] = useState<boolean>(false);
    const [authorities, setAuthorities] = useState<string[]>([]);
    useEffect(() => {
        const fetchAuthorities = async () => {
            setLoadingAuthorities(true);

            await axios.get(frontEndHostName + 'authorities')
                .then((response) => {
                    setAuthorities(response.data);
                })
                .catch(() => showErrorWithMessage("Incompatible role", "Discovery role and Account Manager role cant be selected together, please select only one."))
                .finally(() => setLoadingAuthorities(false));

        };

        fetchAuthorities();
    }, []);

    interface Permission {
        name: string,
        value: number
    }

    const [permissions, setPermissions] = useState<Permission[]>([]);
    const [loadingPermissions, setLoadingPermissions] = useState<boolean>(false);
    useEffect(() => {
        const fetchPermissions = async () => {
            setLoadingPermissions(true);
            await axios.post(frontEndHostName + "authorities/permissions", user.authorities)
                .then(r => setPermissions(r.data))
                .catch((e) => console.log(e.message))
                .finally(() => setLoadingPermissions(false));
        };

        fetchPermissions();

    }, [user.authorities]);

    const [overriddenPermissions, setOverriddenPermissions] = useState<Permission[]>([]);
    const [loadingOverriddenPermissions, setLoadingOverriddenPermissions] = useState<boolean>(false);
    const [overriddenPermissionsError, setOverriddenPermissionError] = useState<boolean>(false);
    useEffect(() => {
        const fetchOverriddenPermissions = async () => {
            setLoadingOverriddenPermissions(true);
            await axios.get(frontEndHostName + "users/" + childMatchedPath?.params.userId + "/permissions")
                .then(r => setOverriddenPermissions(r.data))
                .catch(() => setOverriddenPermissionError(true))
                .finally(() => setLoadingOverriddenPermissions(false));
        };

        fetchOverriddenPermissions();
    }, []);

    const handleValueChange = (e: { target: { name: any; value: any; }; }) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    };

    const handleCheckboxChange = (e: { target: { name: any; checked: any; }; }) => {
        setUser({
            ...user,
            [e.target.name]: e.target.checked
        })
    };

    const handleAuthoritiesChange = (authorities: any[]) => {
        let discoveryRoles: string[] = [Role.DISCOVERY_THREE, Role.DISCOVERY_TWO, Role.DISCOVERY_ONE];
        let accountManager: string[] = [Role.BASIC_ACCOUNT_MANAGER, Role.ADVANCED_ACCOUNT_MANAGER];

        if (authorities.some(role => discoveryRoles.includes(role as string))
            && authorities.some(role => accountManager.includes(role as string))) {
            showErrorWithMessage("Incompatible role", "Discovery role and Account Manager role cant be selected together, please select only one.");
        } else {
            let auth = authorities as string[];
            setUser({
                ...user,
                authorities: [...auth]
            });
            setValue("authorities", authorities);
        }
    };


    const isOverridden = (name: string) => {
        let existing = overriddenPermissions.find(p => p.name === name);
        return existing !== null && existing !== undefined;
    };

    const [permissionsChanged, setPermissionsChanged] = useState<boolean>(false);
    const updateOverrideValue = (index: number, value: number) => {
        let existing = overriddenPermissions[index];
        existing.value = value;

        overriddenPermissions.splice(index, 1, existing);
        setOverriddenPermissions([...overriddenPermissions]);
        setPermissionsChanged(true);
    };

    const overridePermission = (name: string) => {
        let p = {
            name: name,
            value: 0
        };

        setOverriddenPermissions([
            ...overriddenPermissions,
            p
        ]);
        setPermissionsChanged(true);
    };

    const removeOverride = (name: string) => {
        let permission = overriddenPermissions.find(p => p.name === name);

        axios.post(frontEndHostName + "users/" + childMatchedPath?.params.userId + "/permissions/delete", permission)
            .then(() => {
                let permissions = overriddenPermissions.filter(p => p.name !== name);

                setOverriddenPermissions([
                    ...permissions
                ]);
            })
            .catch(() => showGenericErrorDialog());
    };

    const onSubmit = () => {
        const savePermissions = () => {
            axios.post(frontEndHostName + "users/" + childMatchedPath?.params.userId + "/permissions/save", overriddenPermissions)
                .catch(() => showGenericErrorDialog());
        };

        axios.put(frontEndHostName + 'users', user,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(() => {
                if (permissionsChanged) {
                    savePermissions();
                }

                showGenericSuccessDialog();
            })
            .catch(() => showGenericErrorDialog());
    };

    const resetPassword = (e: React.MouseEvent) => {
        e.preventDefault();

        axios.post(frontEndHostName + 'users/account/reset-password/init', user.email,
            {
                headers: {
                    'Content-Type': 'text/plain',
                }
            })
            .then(() => showGenericSuccessDialog())
            .catch(() => {
                showGenericErrorDialog();
            });
    };

    const [domains, setDomains] = useState<string[]>([]);
    useEffect(() => {
        const fetchDomains = async () => {
            await axios.get(organisationHostname + "organisation/" + userOrganisation!.id + "/domains")
                .then(r => {
                    setDomains(r.data);
                });
        };

        if (userOrganisation) {
            fetchDomains();
        }
    }, [userOrganisation]);

    return (
        <div style={{ padding: 15 }}>

            <div className="mb-2">
                <a className="iq-link pull-right" onClick={() => history.push("/admin/users")}>
                    <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: 10 }} />
                    Back to all users
                </a>
            </div>

            {loading ? <IqLoadingIcon /> : (
                <div>
                    {error ? (
                        <Alert variant="danger">
                            {translate("errors.loading")}
                        </Alert>
                    ) : (
                        <div>
                            <div className="organisation-card-label pb-2">
                                Edit {user.login}
                            </div>
                            <Form className="mb-3" onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group controlId="formUserName">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control name="login" type="text" value={user.login}
                                        placeholder="Enter username"
                                        readOnly
                                        ref={register({ required: true, minLength: 1, maxLength: 50 })}
                                        isInvalid={errors.login} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.login && errors.login.type === "required" && "Please choose a username."}
                                        {errors.login && errors.login.type === "minLength" && "Minimum length of username is 1."}
                                        {errors.login && errors.login.type === "maxLength" && "Maximum length of username is 50."}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="formFirstName">
                                    <Form.Label>First name</Form.Label>
                                    <Form.Control name="firstName" type="text" value={user.firstName} onChange={handleValueChange} placeholder="Enter first name"
                                        ref={register({ required: true, maxLength: 50 })}
                                        isInvalid={errors.firstName} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.firstName && errors.firstName.type === "required" && "Please fill in the first name."}
                                        {errors.firstName && errors.firstName.type === "maxLength" && "Maximum length of first name is 50."}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="formLastName">
                                    <Form.Label>Last name</Form.Label>
                                    <Form.Control name="lastName" type="text" value={user.lastName} onChange={handleValueChange} placeholder="Enter last name"
                                        ref={register({ required: true, maxLength: 50 })}
                                        isInvalid={errors.lastName} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.lastName && errors.lastName.type === "required" && "Please fill in the last name."}
                                        {errors.lastName && errors.lastName.type === "maxLength" && "Maximum length of last name is 50."}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="formEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control name="email" type="email" value={user.email} onChange={handleValueChange} placeholder="Enter email"
                                        ref={register({
                                            required: true,
                                            minLength: 5,
                                            maxLength: 254,
                                            validate: {
                                                validDomain: (value: string) => domains.some((d: string) => value.endsWith(d))
                                            }
                                        })}
                                        isInvalid={errors.email} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email && errors.email.type === "required" && "Please fill in the email address."}
                                        {errors.email && errors.email.type === "minLength" && "Minimum length of email is 5."}
                                        {errors.email && errors.email.type === "maxLength" && "Maximum length of email is 254."}
                                        {errors.email && errors.email.type === "validDomain" && `Email domain must match a company domain: ${domains.join(", ")}`}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Organisation</Form.Label>

                                    {(loadingOrganisation || !userOrganisation) ? <IqLoadingIcon /> : (
                                        <Form.Control type="text" readOnly={true} value={userOrganisation?.companyName} />
                                    )}
                                </Form.Group>

                                <Form.Group controlId="formAuthorities">
                                    <Form.Label>Choose authorities</Form.Label>
                                    <Typeahead
                                        clearButton
                                        selected={user.authorities}
                                        id="selection-authorities"
                                        multiple
                                        options={authorities}
                                        onChange={handleAuthoritiesChange}
                                        placeholder="Choose authorities..."
                                        ref={register}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formTermsAgreed">
                                    <Form.Label>Terms & Conditions Agreed</Form.Label>
                                    <Form.Check name="termsAgreed" type="checkbox" checked={user.termsAgreed} onChange={handleCheckboxChange}
                                        ref={register} label="Tick/untick the box to set terms agreed" />
                                </Form.Group>

                                <Form.Group controlId="formActivated">
                                    <Form.Label>Activated</Form.Label>
                                    <Form.Check name="activated" type="checkbox" checked={user.activated} onChange={handleCheckboxChange}
                                        ref={register} label="Tick the box to activate, untick to deactivate" />
                                </Form.Group>


                                <Row className="mt-3">
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <div className="organisation-card-label mb-3">
                                            Default Permissions
                                        </div>
                                        {loadingPermissions ? <IqLoadingIcon /> :
                                            permissions.length > 0 ? (
                                                <div>
                                                    {permissions.map(p => (
                                                        <Row className="mb-2">
                                                            <Col style={{ display: "flex", flexDirection: "column" }}>
                                                                <div>
                                                                    {PermissionMapping[p.name as PermissionMappingAsKey]}
                                                                </div>
                                                                <div style={permissionStyle}>
                                                                    {p.name as PermissionMappingAsKey}
                                                                </div>

                                                            </Col>
                                                            <Col>
                                                                <Form.Control name="value"
                                                                    type="number"
                                                                    size="sm"
                                                                    value={p.value}
                                                                    disabled />
                                                            </Col>
                                                            <Col>
                                                                <Button variant="primary"
                                                                    size="sm"
                                                                    disabled={isOverridden(p.name)}
                                                                    onClick={() => overridePermission(p.name)}>
                                                                    Override
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </div>
                                            ) : (
                                                <Alert variant="info" style={{ width: "100%", marginTop: 25 }}>
                                                    No permissions found for selected roles.
                                                </Alert>
                                            )
                                        }
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <div className="organisation-card-label mb-3">
                                            Overridden Permissions
                                        </div>
                                        {loadingOverriddenPermissions ? <IqLoadingIcon /> :
                                            overriddenPermissions.length > 0 ? (
                                                <div>
                                                    {overriddenPermissions.map((p: Permission, index: number) => (
                                                        <Row className="mb-3">
                                                            <Col style={{ display: "flex", flexDirection: "column" }}>
                                                                <div>
                                                                    {PermissionMapping[p.name as PermissionMappingAsKey]}
                                                                </div>
                                                                <div style={permissionStyle}>
                                                                    {p.name as PermissionMappingAsKey}
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control name="value"
                                                                    type="number"
                                                                    size="sm"
                                                                    value={p.value}
                                                                    onChange={(e: any) => updateOverrideValue(index, e.target.value)}
                                                                    placeholder="Enter value" />
                                                            </Col>
                                                            <Col>
                                                                <Button variant="danger"
                                                                    size="sm"
                                                                    onClick={() => removeOverride(p.name)}>
                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </div>
                                            ) : (
                                                <Alert variant="info" style={{ width: "100%", marginTop: 25 }}>
                                                    No overridden permissions for this user.
                                                </Alert>
                                            )

                                        }

                                    </Col>
                                </Row>

                                <div className="float-end flex">
                                    <Button className="mb-3" variant="primary" type="submit" disabled={loadingOrganisation}>
                                        Save Changes
                                    </Button>
                                </div>


                            </Form>

                            <div>
                                <div className="organisation-card-label mb-3">
                                    Password Reset
                                </div>
                                <ResetPasswordFormForUser email={user.email} />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default UserEdit;
