import React, {useContext, useEffect, useState} from "react";
import UserOrganisationContext from "../../../context/organisation/OrganisationContext";
import {Alert, Col, Row} from "react-bootstrap";
import axios from "axios";
import {socialHostName} from "../../../utils/Configuration";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import translate from "../../../i18n/translate";
import AdvertisedJob from "../../../model/organisation/AdvertisedJob";
import ActiveJobs from "./ActiveJobs";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {sinOut} from "@amcharts/amcharts4/.internal/core/utils/Ease";
import OrganisationJobsModal from "./OrganisationJobsModal";


const OrganisationJob: React.FC = () => {

    const {organisation} = useContext(UserOrganisationContext);

    const [jobs, setJobs] = useState<AdvertisedJob[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [filteredJobs, setFilteredJobs] = useState<AdvertisedJob[]>([]);

    let requestParams = {
        params: {
            orgId: organisation.id
        }
    };

    const fetchData = async () => {
        setLoading(true);
        console.log(requestParams)
        await axios.get(socialHostName + "jobs", requestParams)
            .then(r => {
                setJobs(r.data)
                setFilteredJobs(r.data)
            })
            .catch(() => setError(true))
            .finally(() => {setLoading(false);
                            setRefresh(false)});
    };


    useEffect(() => {
        fetchData();
    }, [refresh]);


    return (
        <div style={{
            paddingTop: 10,
            backgroundColor: "white",
            borderRadius: "0.45rem",
            padding: 15,
            marginTop: 10
        }}>
            <div>
                <Row className="mb-3">
                    <Col lg={{span: 3, offset: 7}} md={{span: 3, offset: 7}} sm={{span: 4, offset: 6}}
                         xs={{span: 4, offset: 4}}>
                        <input className="form-control float-right" type="text"
                               placeholder={translate("advancedsearch.search")}
                               onChange={(e: any) => {
                                   let value = e.target.value.toLowerCase();
                                   let result = jobs.filter((data) => {
                                       return data.jobTitle.toLowerCase().search(value.toLowerCase()) !== -1;
                                   });
                                   setFilteredJobs(result);
                               }}/>
                    </Col>
                    <Col>
                        <OrganisationJobsModal refresh={refresh} setRefresh={setRefresh}></OrganisationJobsModal>
                    </Col>
                </Row>
            </div>
            {loading || refresh ? <IqLoadingIcon/> : (
                <div>
                    {error ? (
                        <Alert variant="warning">
                            {translate("organisation.jobs.nojobs")}
                        </Alert>

                    ) : (
                        <div>
                            <div style={{height: "34px", color: "#212121", fontSize: "28px", fontWeight: 100}}>
                                {filteredJobs.length}&nbsp;{filteredJobs.length === 1 ? "Job" : "Jobs"}
                            </div>
                            <div className="officers-containter mt-2">
                                {filteredJobs.map(job => <ActiveJobs key={job.id} job={job}/>)}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
};

export default OrganisationJob;