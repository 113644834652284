
import React, { Dispatch, useContext, useEffect } from 'react';
import { StateHandler } from '../../model/organisation/editor/StateHandler';
import { CaseStudiesResponse } from '../../model/organisation/editor/CaseStudiesResponse';
import UserOrganisationContext from '../../context/organisation/OrganisationContext';
import axios from 'axios';
import { caseStudiesHostName } from '../../utils/Configuration';
import { Dropdown } from 'react-bootstrap';
import { ErrorHandler } from '../../model/organisation/editor/ErrorHandler';

interface Props {

    //Global Loading, Error, Success
    setLoading: Dispatch<boolean>,
    setSuccess: Dispatch<boolean>,
    setError: Dispatch<ErrorHandler>,

    run: boolean,
    setRun: Dispatch<boolean>,

    // Case Study

    setGatheringCaseStudiesStateData: Dispatch<StateHandler>,
    gatheringCaseStudiesStateData: StateHandler,
    caseStudiesResponse: CaseStudiesResponse
    setCaseStudiesResponse: Dispatch<CaseStudiesResponse>

    setProcessingCaseStudiesStateData: Dispatch<StateHandler>,
    processingCaseStudiesStateData: StateHandler,

    // White Paper

    setGatheringWhitePaperStateData: Dispatch<StateHandler>,
    gatheringWhitePaperStateData: StateHandler,
    whitePaperResponse: CaseStudiesResponse
    setWhitePaperResponse: Dispatch<CaseStudiesResponse>

    setProcessingWhitePaperStateData: Dispatch<StateHandler>,
    processingWhitePaperStateData: StateHandler,

}

const CaseStudiesWhitePaperFunctions: React.FC<Props> = (props: Props) => {

    const intialStateHandler = {loading: false, success:false, error: {state: false, message: ""}}

    const {organisation, setOrganisation, fetchCompany} = useContext(UserOrganisationContext)

    const gathererHandler = async (e: any, updateButton: boolean, url: string,
                                   setStateData: Dispatch<StateHandler>, stateData: StateHandler,
                                   setResponseData: Dispatch<CaseStudiesResponse>) => {

        setStateData({...intialStateHandler})
        setStateData({...stateData, loading: true});
        if(!updateButton){
            props.setLoading(true);
        }

        let request = {
            id: organisation.id,
            url: organisation.companyUrl
        };

        await axios.post(url, request)
            .then((r) => {

                setResponseData(r.data);
                console.log(r.data.documents.length)
                if (r.data.documents.length > 0) {
                    console.log("Here")
                    setStateData({...stateData, loading: false, success: true});
                    if(!updateButton){
                        props.setSuccess(true);
                    }
                }
                else {
                   setStateData({...stateData, loading: false, error: {state: true, message: " No Data Found"}});
                    if(!updateButton){
                        props.setError({state: true, message: " No Data Found"});
                    }
                }
            })
            .catch((reason) => {
                setStateData(
                    {...stateData, loading: false, error: {state: true, message: reason.response.data.title}});
            }).finally(() => props.setLoading(false));
    }

    const processHandler = async (e: any, updateButton: boolean,
                                   setStateData: Dispatch<StateHandler>, stateData: StateHandler) => {
        if(!updateButton){
            props.setLoading(true);
        }
        setStateData({...intialStateHandler})
        setStateData({...stateData, loading: true});

        await axios.get(caseStudiesHostName + "process/" + organisation.id)
            .then(() => {
                setStateData({...stateData, loading: false, success: true});
                if(!updateButton){
                    props.setSuccess(true);
                }
            })
            .catch((reason) => {
                setStateData(
                    {...stateData, loading: false, error: {state: true, message: reason.response.data.title}});

                if(!updateButton){
                    props.setError({state: true, message: reason.response.data.title});
                }
            }).finally(() => props.setLoading(false));
    }

    const processCaseStudyThumbnails = async () => {
        let params = {
            organisationId: organisation.id
        };

        props.setLoading(true)

        await axios.get(caseStudiesHostName + "case-study/thumbnails", { params: params })
            .then(() => props.setProcessingCaseStudiesStateData({...props.processingCaseStudiesStateData, success: true}));

        props.setLoading(false)
    }

    const gatherAndProcessCaseStudies = async (e: any, updateButton: boolean) => {
        if (e){
            e.preventDefault();
        }
        await gathererHandler(e, updateButton, caseStudiesHostName + "gather",
            props.setGatheringCaseStudiesStateData, props.gatheringCaseStudiesStateData, props.setCaseStudiesResponse)
            .then(() => {
                processHandler(e, updateButton, props.setProcessingCaseStudiesStateData, props.processingCaseStudiesStateData)
            });
    }

    const gatherAndProcessWhitePaper = async (e: any, updateButton: boolean) => {
        if (e){
            e.preventDefault();
        }
        await gathererHandler(e, updateButton, caseStudiesHostName + "white-paper/gather",
            props.setGatheringWhitePaperStateData, props.gatheringWhitePaperStateData, props.setWhitePaperResponse)
            .then(() => {
                processHandler(e, updateButton, props.setProcessingWhitePaperStateData, props.processingWhitePaperStateData)
            });
    }


    useEffect(() => {
        if(props.run){
            props.setGatheringCaseStudiesStateData({...intialStateHandler})
            props.setGatheringWhitePaperStateData({...intialStateHandler})
            props.setProcessingCaseStudiesStateData({...intialStateHandler})
            props.setProcessingWhitePaperStateData({...intialStateHandler})
            gatherAndProcessWhitePaper(null, true)
            gatherAndProcessCaseStudies(null, true)
            props.setRun(false)
        }
    }, [props.run]);


    return (
        <>
            {organisation?.companyUrl && (
                <>
                    <div>
                        <Dropdown>
                            <Dropdown.Toggle style={{ marginLeft: "15px", marginTop: 15 }} variant="warning" id="case-studies">
                                Case Studies
                            </Dropdown.Toggle>
                            <Dropdown.Menu as="span">
                                <Dropdown.Item onClick={(e) => gathererHandler(e, false, caseStudiesHostName + "gather",
                                    props.setGatheringCaseStudiesStateData, props.gatheringCaseStudiesStateData, props.setCaseStudiesResponse)}>
                                    Gather
                                </Dropdown.Item>
                                <Dropdown.Item onClick={(e) => processHandler(e, false, props.setProcessingCaseStudiesStateData, props.processingCaseStudiesStateData)}>
                                    Process
                                </Dropdown.Item>
                                <Dropdown.Item onClick={processCaseStudyThumbnails}>
                                    Fetch Thumbnails
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div>
                        <Dropdown>
                            <Dropdown.Toggle style={{ marginLeft: "15px", marginTop: 15 }} variant="warning" id="whitepaper">
                                Whitepaper
                            </Dropdown.Toggle>
                            <Dropdown.Menu as="span">
                                <Dropdown.Item onClick={(e) => gathererHandler(e, false, caseStudiesHostName + "white-paper/gather",
                                    props.setGatheringWhitePaperStateData, props.gatheringWhitePaperStateData, props.setWhitePaperResponse)}>
                                    Gather
                                </Dropdown.Item>
                                <Dropdown.Item onClick={(e) => processHandler(e, false, props.setProcessingWhitePaperStateData, props.processingWhitePaperStateData)}>
                                    Process
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </>
            )}
        </>
    )

}

export default CaseStudiesWhitePaperFunctions;