import React, {useContext, useEffect, useState} from "react";
import {matchPath, Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import {Team} from "./Teams";
import SubmenuContext from "../../context/SubmenuContext";
import {faHome, faList, faSearch, faSignInAlt, faTools} from "@fortawesome/free-solid-svg-icons";
import EditTeam from "./edit/EditTeam";
import TeamContext from "../../context/teams/TeamContext";
import TeamHeader from "./TeamHeader";
import TeamOverview from "./TeamOverview";
import TeamSearches from "./dashboard/TeamSearches";
import TeamListActivity from "./dashboard/TeamListActivity";
import TeamLogins from "./dashboard/TeamLogins";
import "./Teams.css";
import axios from "axios";
import {frontEndHostName} from "../../utils/Configuration";
import IqLoadingIcon from "../common/IqLoadingIcon";
import translate from "../../i18n/translate";
import {Alert} from "react-bootstrap";

const TeamContainer: React.FC = () => {
    const {setSubmenuTitle, setSubmenuItems} = useContext(SubmenuContext);
    const match = useRouteMatch();
    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/team/:teamId',
        exact: false,
        strict: false
    });

    const [team, setTeamState] = useState<Team>({} as Team);

    const setTeam = (team: Team) => {
        setTeamState(team);
    };

    const [error, setError] = useState<boolean>(false);
    const successHandler = (t: Team) => {
        setTeam(t);

        setSubmenuTitle(t.name);
        let submenuItems = [
            {
                name: "teams.overview.title",
                url: "/team/" + childMatchedPath?.params.teamId,
                icon: faHome
            },
            {
                name: "teams.logins.title",
                url: "/team/" + childMatchedPath?.params.teamId + "/logins",
                icon: faSignInAlt
            },
            {
                name: "lists.title",
                url: "/team/" + childMatchedPath?.params.teamId + "/lists",
                icon: faList
            },
            {
                name: "teams.common.searches",
                url: "/team/" + childMatchedPath?.params.teamId + "/searches",
                icon: faSearch
            },
            {
                name: "teams.main.manage",
                url: "/team/" + childMatchedPath?.params.teamId +  "/edit",
                icon: faTools
            }
        ];

        setSubmenuItems(submenuItems);
    };

    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        const fetchTeam = async () => {
            setLoading(true);
            await axios.get(frontEndHostName + "teams/" + childMatchedPath?.params.teamId)
                .then(r => successHandler(r.data))
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchTeam();
        return () => {
            setSubmenuTitle("");
            setSubmenuItems([]);
        };
    }, []);

    return (
        <TeamContext.Provider value={{team, setTeam}}>
            <div>
                {loading ? <IqLoadingIcon /> :
                    error ? (
                        <Alert variant="danger">
                            {translate("errors.loading")}
                        </Alert>
                    ) : (
                        <div>
                            <TeamHeader />
                            <Switch>
                                <Route exact path={`${match.url}/:teamId`} component={TeamOverview} />
                                <Route path={`${match.url}/:teamId/searches`} component={TeamSearches} />
                                <Route path={`${match.url}/:teamId/lists`} component={TeamListActivity} />
                                <Route path={`${match.url}/:teamId/logins`} component={TeamLogins} />
                                <Route path={`${match.url}/:teamId/edit`} component={EditTeam} />
                            </Switch>
                        </div>
                    )
                }
            </div>
        </TeamContext.Provider>
    );
};

export default TeamContainer;
