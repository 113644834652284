import React, {useEffect, useState} from "react";
import {Alert, Col, Container, Row} from "react-bootstrap";
import TeamLink from "./TeamLink";
import IqLoadingIcon from "../common/IqLoadingIcon";
import axios from "axios";
import {frontEndHostName} from "../../utils/Configuration";
import {hasCreatePermission, Team} from "./Teams";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import './AllTeams.css';
import translate from "../../i18n/translate";

const AllTeams: React.FC = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [teams, setTeams] = useState<Team[]>([]);

    let history = useHistory();

    const fetchTeams = async () => {
        setLoading(true);
        await axios.get(frontEndHostName + "teams")
            .then(r => setTeams(r.data))
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchTeams();
    }, []);

    const deleteTeam = () => {
        fetchTeams();
    };

    return (
        <Container fluid style={{ marginTop: 15 }}>
            <Row className="" style={{ marginLeft: 0, marginRight: 0 }}>
                <Col>
                    <div className="iq-headline">
                        <span className="text">
                            {translate("teams.title")}
                        </span>
                    </div>
                </Col>
                {hasCreatePermission() && (
                    <Col className="text-end">
                        <span style={{ marginLeft: "auto" }}>
                            <button onClick={() => { history.push("/teams/create") }} className="iqx-button md-size dark-blue">
                                <span style={{ paddingRight: 10 }}>{translate("teams.createteam")}</span>
                                <FontAwesomeIcon icon={faPlusCircle} color="white" size="1x" />
                            </button>
                        </span>
                    </Col>
                )}
            </Row>
            {loading ? <IqLoadingIcon /> : (
                error ?
                    <Alert className="mt-2" variant="danger">
                        {translate("errors.loading")}
                    </Alert>
                    :
                    <div id="team-list-container">
                        {teams?.map(team => (
                            <TeamLink team={team} deleteTeam={deleteTeam} />
                        ))}
                    </div>
            )}
        </Container>
    );
};

export default AllTeams;
