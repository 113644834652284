import {Button, ListGroup, Modal} from "react-bootstrap";
import React, {useContext, useState} from "react";
import ItemType from "./item/ItemType";
import DashboardContext from "../../context/dashboard/DashboardContext";
import translate from "../../i18n/translate";

interface Props {
    widgetExists: (type: ItemType) => boolean
    addItem: (type: ItemType) => void
}

const AddModal: React.FC<Props> = (props: Props) => {
    const { showModal, setShowModal } = useContext(DashboardContext);
    const [ newType, setNewType ] = useState<ItemType | null>(null);

    const handleClose = () => {
        setNewType(null);
        setShowModal(false);
    };

    return (
        <Modal show={showModal} onHide={handleClose} dialogClassName="modal-dialog modal-full-height modal-right modal-notify modal-success">
            <Modal.Header closeButton>
                <Modal.Title>
                    {translate("dashboard.addnewitem")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {Object.values(ItemType.ALL_TYPES).filter(type => !type.hidden).map((type) => {
                        let className = "fa-lg widget-icon pull-right";
                        if (type === newType) {
                            return (
                                <ListGroup.Item key={type.key}
                                                variant="success"
                                                disabled={props.widgetExists(type)}
                                                onClick={() => setNewType(null)}
                                                action>
                                    {translate(type.i18nKey)}
                                    <div className={className} aria-hidden="true">
                                        {type.icon}
                                    </div>
                                </ListGroup.Item>
                            );
                        }
                        else {
                            return (
                                <ListGroup.Item key={type.key}
                                                onClick={() => setNewType(type)}
                                                disabled={props.widgetExists(type)}
                                                action>
                                    {translate(type.i18nKey)}
                                    {/*<i className={className} aria-hidden="true"/>*/}
                                    <div className={className} aria-hidden="true">
                                        {type.icon}
                                    </div>

                                </ListGroup.Item>
                            );
                        }
                    })}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {translate("dashboard.close")}
                </Button>
                <Button variant="success"
                        disabled={newType === null}
                        onClick={() => {
                            props.addItem(newType as ItemType);
                            handleClose();
                        }}>
                    {translate("dashboard.add")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddModal;
