import React, {useEffect, useState} from "react";
import TechDataSalesAggregate from "../../model/techsales/TechDataSalesAggregate";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {format} from "d3-format";
import translate from "../../i18n/translate";
import {organisationHostname} from "../../utils/Configuration";
import axios from "axios";
import {AutoSizer} from "react-virtualized";
import {Hint, HorizontalGridLines, LabelSeries, VerticalBarSeries, XAxis, XYPlot, YAxis} from "react-vis";

const SalesOverTime: React.FC = () => {

    const [loadingTechSales, setLoadingTechSales] = useState<boolean>(false);
    const [techSalesByMonth, setTechSalesByMonth] = useState<TechDataSalesAggregate[]>([]);

    let initialStartDate = new Date();
    initialStartDate.setMonth(initialStartDate.getMonth() - 12);

    let initialStartDateString = initialStartDate.toISOString();
    let initialEndDate = new Date().toISOString();
    const [startDate, setStartDate] = useState<string>(initialStartDateString.substr(0, initialStartDateString.indexOf("T")));
    const [endDate, setEndDate] = useState<string>(initialEndDate.substr(0, initialEndDate.indexOf("T")));

    useEffect(() => {
        const fetchTechSalesByMonth = async () => {
            setLoadingTechSales(true);

            let postData = {
                from: startDate,
                to: endDate
            };

            await axios.post(organisationHostname + "tech-sales/month", postData)
                .then(r => setTechSalesByMonth(r.data))
                .finally(() => setLoadingTechSales(false));
        };

        if (startDate && endDate) {
            fetchTechSalesByMonth();
        }
    }, [startDate, endDate]);

    const [hover, setHover] = useState<any>();
    const formatValue = (value: any) => {
        return "£" + value.toLocaleString();
    };

    const getYDomainMinimum = () => {
        const minValue = Math.min(...techSalesByMonth.map((item: TechDataSalesAggregate) => item.totalRevenueLocal));
        const domainMinimum = minValue - (0.2 * minValue);

        return Math.max(0, domainMinimum);
    };

    const getYDomainMaximum = () => {
        return Math.max(...techSalesByMonth.map((item: TechDataSalesAggregate) => item.totalRevenueLocal));
    };

    return (
        <div>
            <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10, minHeight: 400 }}>
                <div className="organisation-card-label mb-2">
                    {translate("techsales.month")}
                </div>

                <div className="mb-3" style={{display: "flex", justifyContent: "flex-end"}}>
                    <div className="me-2 pt-2">
                        <span>{translate("contracts.startdate")}</span>
                    </div>
                    <div className="me-4">
                        <input type="date" className="form-control" value={startDate} onChange={(e: any) => setStartDate(e.target.value)}/>
                    </div>
                    <div className="me-2 pt-2">
                        <span>{translate("contracts.enddate")}</span>
                    </div>
                    <div className="me-2">
                        <input type="date" className="form-control" value={endDate} onChange={(e: any) => setEndDate(e.target.value)}/>
                    </div>
                </div>

                {loadingTechSales ? <IqLoadingIcon /> : (
                    <div>
                        {techSalesByMonth.length !== 0 && (
                            <AutoSizer>
                                {({height, width}) => (
                                    <XYPlot width={width}
                                            height={300}
                                            xType="ordinal"
                                            yDomain={[getYDomainMinimum(), getYDomainMaximum()]}>

                                        <HorizontalGridLines/>
                                        <VerticalBarSeries color="#52CAF9"
                                                           barWidth={0.7}
                                                           data={techSalesByMonth.map((item: TechDataSalesAggregate) => ({ x: item.label, y: item.totalRevenueLocal }))}
                                                           onValueMouseOver={(v: any) => {
                                                               setHover(v);
                                                           }}
                                                           onValueMouseOut={(v: any) => {
                                                               setHover(null);
                                                           }}/>
                                        <LabelSeries data={techSalesByMonth.map((item: any) => item.label)} />
                                        <XAxis title={translate("techsales.calendarmonth")}/>
                                        <YAxis title="Sales By Month"
                                               tickFormat={
                                                   (value: any) => {
                                                       return "£" + format('.2s')(value);
                                                   }
                                               }/>

                                        {hover && (
                                            <Hint value={hover}>
                                                <div className="chart-hint fade-in">
                                                    <div className="x">{hover.x}</div>
                                                    <div className="y">{formatValue(hover.y)}</div>
                                                </div>
                                            </Hint>
                                        )}
                                    </XYPlot>
                                )}
                            </AutoSizer>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SalesOverTime;