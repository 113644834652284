import React, {useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Container, Row} from 'react-bootstrap';
import queryString from 'query-string';
import OrganisationChannelPartnersList from './OrganisationChannelPartnersList';
import OrganisationChannelPartnersDashboard from './OrganisationChannelPartnersDashboard';
import OrganisationChannelPartnersNews from './OrganisationChannelPartnersNews';

const OrganisationChannelPartners: React.FC = () => {

    let { organisationId } = useParams<{organisationId: string}>();
    let location = useLocation();
    const history = useHistory();
    const parsed = queryString.parse(location.search);

    const [partnersView, setPartnersView] = useState(parsed.view);

    enum Partners {
        List = "list",
        Dashboard = "dashboard",
        News = "news",
    }

    useEffect(() => {
        if (parsed.view) {
            const parsed = queryString.parse(location.search);
            setPartnersView(parsed.view);
        } else {
            redirectToFinancialView(Partners.List);
        }
    }, [location]);

    const redirectToFinancialView = (view: string) => {
        var link: string = "/organisation/" + organisationId + "/channel-partners";
        if (view !== "") {
            link += "?view=" + view;
        }
        history.push(link);
    };


    return (
        <div>
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0, marginTop: 15 }}>
                <Row className="" style={{ marginLeft: 0, marginRight: 0 }}>
                    <div style={{ marginLeft: "auto", marginRight: "auto" }} className="iq-button-group">
                        <button className={partnersView === Partners.List ? "selected-inverted" : ""}
                            onClick={(e) => { redirectToFinancialView(Partners.List) }}>List</button>
                        <button className={partnersView === Partners.Dashboard ? "selected-inverted" : ""}
                            onClick={(e) => { redirectToFinancialView(Partners.Dashboard) }}>Dashboard</button>
                        <button className={partnersView === Partners.News ? "selected-inverted" : ""}
                            onClick={(e) => { redirectToFinancialView(Partners.News) }}>News</button>
                    </div>
                </Row>
                {partnersView === Partners.List &&
                    <Row className="" style={{ marginLeft: 15, marginRight: 15, paddingTop: 15 }}>
                        <OrganisationChannelPartnersList />
                    </Row>
                }
                {(partnersView === Partners.Dashboard) &&
                        <OrganisationChannelPartnersDashboard/>
                }
                {partnersView === Partners.News &&
                    <Row className="" style={{ marginLeft: 0, marginRight: 0, paddingTop: 15 }}>
                        <OrganisationChannelPartnersNews/>
                    </Row>
                }

            </Container>
        </div >
    );
}

export default OrganisationChannelPartners;
