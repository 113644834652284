import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import '../node_modules/font-awesome/css/font-awesome.css'
import * as serviceWorker from './serviceWorker';
import 'react-vis/dist/style.css';
import TimeAgo from 'javascript-time-ago'
// import 'bootstrap/dist/css/bootstrap.css';

import en from 'javascript-time-ago/locale/en.json'
import de from 'javascript-time-ago/locale/de.json'
import fr from 'javascript-time-ago/locale/fr.json'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(de)
TimeAgo.addLocale(fr)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
