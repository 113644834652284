import React, {useEffect, useState} from "react";
import FundingDetails from "../../model/organisation/FundingDetails";
import axios from "axios";
import {organisationHostname} from "../../utils/Configuration";
import translate from "../../i18n/translate";
import DateFormatter from "../../utils/formatters/DateFormatter";
import {format} from "d3-format";
import {Col, Form, Row, Table} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import IqLoadingIcon from "../common/IqLoadingIcon";
import Pagination from "../../utils/PaginationHook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPlus, faChevronRight, faEdit, faNewspaper, faSort, faSortAmountDown, faSortAmountDownAlt, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import ArticleLink from "./ArticleLink";
import {CompanyLogo} from "./MarketIntelligenceContainer";
import {Role, hasUserRole } from "../../utils/Security";
import InvestmentEditModal from "./InvesmentEditModal";

enum Sort {
    VALUE = "value",
    TYPE = "type",
    LEADING_ORGANISATION = "leadingOrganisation",
    FUNDED_ORGANISATION = "fundedOrganisation",
    IDENTIFIED_ON = "identifiedOn"
}

const Investments: React.FC = () => {
    let history = useHistory();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [sort, setSort] = useState<string>(Sort.IDENTIFIED_ON);
    const [desc, setDesc] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [fundingDetails, setFundingDetails] = useState<FundingDetails[]>([]);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [chosenArticle, setChosenArticle] = useState<FundingDetails>();
    const [detailsId, setDetailsId] = useState<number>();
    const [refresh, setRefresh] = useState<boolean>(false);
    
    const [showAddModal, setShowAddModal] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let page = currentPage - 1;
            let url = `${organisationHostname}funding-details?page=${page}&size=${pageSize}&sort=${sort + "," + (desc ? "DESC" : "ASC")}`;

            await axios.get(url)
                .then(r => {
                    let data = r.data;
                    setFundingDetails(data.content);
                    setTotalRecords(data.totalElements);


                    if(refresh){
                        setRefresh(false)
                    }

                })
                .finally(() => setLoading(false));
        };

        fetchData();
    }, [currentPage, pageSize, sort, desc, refresh]);

    const getSortButton = (s: Sort) => {
        if (s === sort) {
            return (
                <a className="sort-button pull-right"
                   onClick={() => {
                       setDesc(!desc);
                   }}>
                    <FontAwesomeIcon icon={desc ? faSortAmountDown : faSortAmountDownAlt}/>
                </a>
            );
        }

        return (
            <a className="sort-button pull-right"
               onClick={() => {
                   setSort(s);
                   setDesc(true);
               }}>
                <FontAwesomeIcon icon={faSort}/>
            </a>
        );
    };

    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== pageSize) {
            setPageSize(size);
        }
    };

    const getCurrencySymbol = (currency: string) => {
        if (currency === "USD") {
            return "$";
        }
        else if (currency === "GBP") {
            return "£";
        }
        else {
            return "€";
        }
    };


    return (
        <div style={{paddingTop: 35}}>
            <div className="organisation-card">
                <div className="organisation-card-label">
                    {translate("marketintelligence.investments")}
                </div>
                <button style={{marginLeft: "auto"}}  className="iqx-button sm-size primary"
                    onClick={() => {
                        setShowAddModal(true)
                    }}
                >
                    <span className="me-2">Add</span>
                    <FontAwesomeIcon icon={faPlus}/>
                </button>
                <div className="mt-4">
                    <Table striped hover>
                        <thead>
                        <tr>
                            <th>
                                {translate("organisation.summary.funding.date")}
                                {getSortButton(Sort.IDENTIFIED_ON)}
                            </th>
                            <th>
                                {translate("organisation.summary.funding.round")}
                                {getSortButton(Sort.TYPE)}
                            </th>
                            <th className="text-end">
                                {translate("organisation.summary.funding.value")}
                                {getSortButton(Sort.VALUE)}
                            </th>
                            <th style={{width: "30%"}}>
                                {translate("contracts.organisation")}
                                {getSortButton(Sort.FUNDED_ORGANISATION)}
                            </th>
                            <th style={{width: "30%"}}>
                                {translate("organisation.summary.funding.leadinvestor")}
                                {getSortButton(Sort.LEADING_ORGANISATION)}
                            </th>
                            <th className="text-end">
                                    <span>
                                        <FontAwesomeIcon icon={faNewspaper} size="1x"/>
                                    </span>
                            </th>
                            {hasUserRole(Role.ADMIN) && (
                                <th className="text-end">
                                    <span>
                                        <FontAwesomeIcon icon={faUserCircle} size="1x"/>
                                    </span>
                                </th>
                            )}

                        </tr>
                        </thead>
                        {!loading && (
                            <tbody>
                            {fundingDetails.map((details: FundingDetails) => (
                                <tr key={details.id}>
                                    <td>{DateFormatter.formatDate(details.identifiedOn)}</td>
                                    <td>
                                            <span className="text-uppercase">
                                                {details.type ? details.type : "-"}
                                            </span>
                                    </td>
                                    <td className="text-end">
                                        {getCurrencySymbol(details.currency)}{format(',')(details.value)}
                                    </td>
                                    <td>
                                            <span className="pe-3">
                                                <CompanyLogo {...details.fundedOrganisation} />
                                            </span>
                                        <span className="iqx-link pt-2"
                                              onClick={() => history.push("/organisation/" + details.fundedOrganisationId)}>
                                                {details.fundedOrganisation.companyName}
                                            </span>
                                    </td>
                                    <td>
                                        {details.leadingOrganisationId ? (
                                            <a className="iqx-link"
                                               onClick={() => history.push("/organisation/" + details.leadingOrganisationId)}>
                                                {details.leadingOrganisation}
                                            </a>
                                        ) : (
                                            <span>-</span>
                                        )}
                                    </td>
                                    <td className="text-right" style={{textAlign: 'center', verticalAlign: 'middle'}}>
                                        <ArticleLink articleId={details.articleUuid}/>
                                    </td>
                                    <td className="text-right" style={{textAlign: 'center', verticalAlign: 'middle'}}>
                                        <FontAwesomeIcon icon={faEdit} size="1x"
                                                         style={{color: "#206fc3"}}
                                                         onClick={() => {
                                                             setShowEditModal(true);
                                                             setChosenArticle(details)
                                                             setDetailsId(details.id)
                                                         }}
                                        />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        )}
                    </Table>

                    {loading && (
                        <div className="row text-center">
                            <IqLoadingIcon/>
                        </div>
                    )}

                    <Row className="justify-content-md-end">
                        <Col md={1}>
                            <Form.Control as="select"
                                          className="pull-right"
                                          defaultValue={pageSize}
                                          onChange={(event) => changePageSize(event)}
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                            </Form.Control>
                        </Col>
                        <Col md={2}>
                            <div className="pull-right">
                                {totalRecords > 0 && (
                                    <Pagination totalRecords={totalRecords}
                                                pageLimit={pageSize}
                                                pageRangeDisplayed={1}
                                                initPage={currentPage}
                                                onChangePage={setCurrentPage}
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            {(showEditModal || showAddModal) && (
                <InvestmentEditModal setRefresh={setRefresh}
                                     article={chosenArticle} setArticle={setChosenArticle}
                                     showEditModal={showEditModal} setShowEditModal={setShowEditModal}
                                     showAddModal={showAddModal} setShowAddModal={setShowAddModal}
                                     detailsId={detailsId}/>
            )}
        </div>
    );
};

export default Investments;