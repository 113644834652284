import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {organisationHostname} from "../../utils/Configuration";
import axios from "axios";
import {Renewal} from "../../model/renewals/Renewal";
import {Table} from "react-bootstrap";
import IqLoadingIcon from "../common/IqLoadingIcon";
import "./RenewalView.css";
import {format} from "d3-format";
import DateFormatter from "../../utils/formatters/DateFormatter";

const RenewalView: React.FC = () => {

    const {renewalId} = useParams<{renewalId: string | undefined}>();
    let history = useHistory();

    const [renewal, setRenewal] = useState<Renewal>();
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        const fetchRenewal = async () => {
            setLoading(true);
            await axios.get(organisationHostname + "renewals/" + renewalId)
                .then(r => {
                    setRenewal(r.data);
                })
                .finally(() => setLoading(false));
        }

        fetchRenewal();
    }, []);

    const getResellerCityAndPostCode = () => {
        let city = renewal?.resellerCity;
        let postCode = renewal?.resellerPostCode;

        let hasBoth = (city && postCode);
        return (
            <div>
                {city && <span className="address">{city.toLowerCase()}</span>}{hasBoth && <span>, </span>}{postCode && <span>{postCode}</span>}
            </div>
        );
    }

    const getEndUserCityAndPostCode = () => {
        let city = renewal?.endUserCity;
        let postCode = renewal?.endUserPostCode;

        let hasBoth = (city && postCode);
        return (
            <div>
                {city && <span className="address">{city.toLowerCase()}</span>}{hasBoth && <span>, </span>}{postCode && <span>{postCode}</span>}
            </div>
        );
    }

    const getTotal = () => {
        const unitPrice = (renewal?.productSellPrice || 0);
        const quantity = (renewal?.quantity || 0);

        if (quantity > 0) {
            return format(",.2f")(unitPrice * quantity);
        }

        return "-"
    }

    const getPrice = (price: number) => {
        if (price){
            return format(",.2f")(price);
        }

        return "-";
    }

    return (
        <div style={{
            paddingTop: "10px",
            backgroundColor: "white",
            borderRadius: "0.45rem",
            padding: 15,
            marginTop: 10
        }}>
            {loading ? <IqLoadingIcon/> : (
                <div className="p-2">
                    <div className="mb-2" style={{display: "flex", flexDirection: "row"}}>
                        <div className="organisation-card-label mb-3 pt-2">
                            Sales Quote
                        </div>
                        <div style={{marginLeft: "auto", marginRight: 0}}>
                            <button className="iqx-button iconic md-size" onClick={() => {history.goBack()}}>
                                <span className="me-2"><FontAwesomeIcon icon={faChevronLeft} /></span>
                                <span>Back to all renewals</span>
                            </button>
                        </div>
                    </div>
                    <div className="renewal-info">
                        <div className="info-item organisation-card">
                            <div className="organisation-card-label mb-3">Reseller</div>
                            <div className="mb-2"><span className="item-headline">{renewal?.resellerName}</span></div>
                            <div className="address">{renewal?.resellerAddress1?.toLowerCase()}</div>
                            {renewal?.resellerAddress2 && <div className="address">{renewal?.resellerAddress2.toLowerCase()}</div>}
                            {renewal?.resellerAddress3 && <div className="address">{renewal?.resellerAddress3.toLowerCase()}</div>}
                            {getResellerCityAndPostCode()}
                            <div className="address">{renewal?.resellerCountry?.toLowerCase()}</div>
                            <div className="mt-2"><span className="item-footer">Customer account #: </span>{renewal?.resellerAccountNo}</div>
                        </div>
                        <div className="info-item organisation-card">
                            <div className="organisation-card-label  mb-3">End User</div>
                            <div className="mb-2"><span className="item-headline">{renewal?.endUser}</span></div>
                            <div className="address">{renewal?.endUserAddress1?.toLowerCase()}</div>
                            {renewal?.endUserAddress2 && <div className="address">{renewal?.endUserAddress2.toLowerCase()}</div>}
                            {renewal?.endUserAddress3 && <div className="address">{renewal?.endUserAddress3.toLowerCase()}</div>}
                            {getEndUserCityAndPostCode()}
                            <div className="mt-2 address">{renewal?.endUserContact?.toLowerCase()}</div>
                            <div>{renewal?.endUserEmail}</div>
                            <div className="mt-2"><span className="item-footer">End user type: </span>{renewal?.endUserType || "-"}</div>
                        </div>
                    </div>
                    <div className="renewal-info mt-4">
                        <div className="info-item-full organisation-card">
                            <div className="organisation-card-label mb-3">Agreement information</div>
                            <div className="renewal-info">
                                <div className="agreement-info-item">
                                    <div><span className="item-footer">Agreement Number: </span> {renewal?.agreementNumber}</div>
                                    <div><span className="item-footer">Program: </span> {renewal?.productProgram}</div>
                                    {renewal?.supportLevel && <div><span className="item-footer">Support level: </span> {renewal.supportLevel}</div>}
                                </div>
                                <div className="agreement-info-item">
                                    <div className="mt-2"><span className="item-footer">Vendor: </span> {renewal?.vendor}</div>
                                    <div><span className="item-footer">Vendor Quote ID: </span> {renewal?.vendorQuoteId}</div>
                                    {renewal?.duration && <div><span className="item-footer">Duration: </span> {renewal.duration}</div>}
                                </div>
                                <div className="agreement-info-item">
                                    {renewal?.createdDate && <div className="mt-2"><span className="item-footer">Creation Date: </span> {DateFormatter.formatDate(renewal.createdDate)}</div>}
                                    {renewal?.agreementStartDate && <div><span className="item-footer">Agreement Start Date: </span> {DateFormatter.formatDate(renewal?.agreementStartDate)}</div>}
                                    {renewal?.agreementEndDate && <div><span className="item-footer">Agreement End Date: </span> {DateFormatter.formatDate(renewal?.agreementEndDate)}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>Line</th>
                                    <th style={{width: "35%"}}>Product Details</th>
                                    <th>Vendor Part #</th>
                                    <th className="text-end">List Price</th>
                                    <th className="text-end">Discount (%)</th>
                                    <th className="text-end">Unit Price ({renewal?.productSellCurrency})</th>
                                    <th className="text-end">Qty</th>
                                    <th className="text-end">Total exc VAT ({renewal?.productSellCurrency})</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{renewal?.lineItemId}</td>
                                    <td>{renewal?.productDescription}</td>
                                    <td>{renewal?.vendorPartNo}</td>
                                    <td className="text-end">{getPrice(renewal?.listPrice || 0)}</td>
                                    <td className="text-end">{renewal?.discount}</td>
                                    <td className="text-end">{getPrice(renewal?.productSellPrice || 0)}</td>
                                    <td className="text-end">{renewal?.quantity}</td>
                                    <td className="text-end">{getTotal()}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            )}
        </div>
    );
}

export default RenewalView;