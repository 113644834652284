import React from 'react';
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import EnhancedButton from "../../utils/EnhancedButton";


interface OnboardingPreferencesControlProps {
    page: number;
    /**
     * The last page (inclusive) that this control should appear on.
     */
    lastPage: number;
    nextButtonEnabled: boolean;
    nextPageCallback: Function;
    prevPageCallback: Function;
}

const OnboardingPreferencesControl: React.FC<OnboardingPreferencesControlProps> = ({
                                                                                       page,
                                                                                       lastPage,
                                                                                       nextButtonEnabled,
                                                                                       nextPageCallback,
                                                                                       prevPageCallback
                                                                                   }) => {
    return (
        <div>
            {(page <= lastPage) && (
                <div className="me-4">
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div>
                            {page > 0 &&
                                <EnhancedButton onClick={() => prevPageCallback()} icon={faChevronLeft} tooltip="Previous Page" tooltipPosition="left"/>
                            }
                        </div>
                        <EnhancedButton onClick={() => nextPageCallback()} icon={faChevronRight} tooltip="Next Page" tooltipPosition="right" disabled={!nextButtonEnabled}/>
                    </div>
                </div>
            )}
        </div>
    )
}

export default OnboardingPreferencesControl;