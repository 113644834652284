import React, {useContext, useEffect, useState} from "react";
import {Card, Col, Row, Table} from "react-bootstrap";
import DashboardPieChart from "./common/DashboardPieChart";
import PieChartData from "../../../model/list/PieChartData";
import UserListContext from "../../../context/list/UserListContext";
import axios from "axios";
import {frontEndHostName} from "../../../utils/Configuration";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {matchPath, useLocation} from "react-router-dom";

interface ClassificationData {
    salesModelChartData: PieChartData[],
    sicChartData: PieChartData[],
    sicTableData: ClassificationTableEntry[],
    sectorTextChartData: PieChartData[],
    organisationTagTableData: ClassificationTableEntry[]
}

interface ClassificationTableEntry {
    name: string,
    description: string,
    count: number
}

const ListClassification: React.FC = () => {
    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/lists/:listId',
        exact: false,
        strict: false
    });

    const [data, setData] = useState<ClassificationData>();
    const {list, setList} = useContext(UserListContext);

    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await axios.get(frontEndHostName + "user-lists/" + childMatchedPath?.params.listId + "/dashboard/classification")
                .then(r => setData(r.data))
                .finally(() => setLoading(false));
        };

        fetchData();
    }, []);

    return (
        loading
            ?
            <>
                <div style={{marginTop: 15}}/>
                <IqLoadingIcon/>
            </>
            :
            <>
                <Row className="mt-3">
                    {(data?.salesModelChartData && data.salesModelChartData.length > 0) &&
                    <Col md={6}>
                        <Card style={{height: 400, borderRadius: "0.35rem"}}>
                            <Card.Body>
                                <Card.Title>
                                    <h4>Sales Model</h4>
                                </Card.Title>
                                {data ? (
                                    <DashboardPieChart title="Sales Model"
                                                       chartId="saleSModelChart"
                                                       data={data.salesModelChartData}
                                                       height={320}/>
                                ) : (
                                    <IqLoadingIcon/>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                    }

                    {data?.sicChartData && data.sicChartData.length > 0 &&
                    <Col md={6}>
                        <Card style={{height: 400, borderRadius: "0.35rem"}}>
                            <Card.Body>
                                <Card.Title>
                                    <h4>SIC Code</h4>
                                </Card.Title>
                                {data ? (
                                    <DashboardPieChart title="Sic Code"
                                                       chartId="sicCodeChart"
                                                       data={data.sicChartData}
                                                       height={320}/>
                                ) : (
                                    <IqLoadingIcon/>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                    }
                </Row>

                <Row className="mt-3">
                    {data?.sectorTextChartData && data.sectorTextChartData.length > 0 &&
                    <Col md={6}>

                        <Card style={{height: 400, borderRadius: "0.35rem"}}>
                            <Card.Body>
                                <Card.Title>
                                    <h4>Sector Text</h4>
                                </Card.Title>
                                {data ? (
                                    <DashboardPieChart title="Sector Text"
                                                       chartId="sectorChart"
                                                       data={data.sectorTextChartData}
                                                       height={320}/>
                                ) : (
                                    <IqLoadingIcon/>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                    }
                </Row>
                <Row className="mt-3">
                    {data?.sicTableData && data.sicTableData.length > 0 &&
                        <Col md={4}>
                            <Card style={{ height: 400, borderRadius: "0.35rem"}}>
                                <Card.Body className="overflow-auto">
                                    <Card.Title>
                                        <h4>Top 5 SIC Codes</h4>
                                    </Card.Title>
                                    <div style={{
                                        backgroundColor: "white",
                                        borderRadius: "0.45rem",
                                        padding: 15,
                                        width: "100%"
                                    }}>
                                        <Table responsive striped hover size="sm">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>SIC</th>
                                                <th>Description</th>
                                                <th>Count</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {data?.sicTableData.map((item: any, index: number) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.description}</td>
                                                    <td>{item.count}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    }

                    {data?.organisationTagTableData && data.organisationTagTableData.length > 0 && (
                        <Col md={4}>

                            <Card style={{height: 400, borderRadius: "0.35rem"}}>
                                <Card.Body className="overflow-auto">
                                    <Card.Title>
                                        <h4>Top 5 Organisation Tags</h4>
                                    </Card.Title>
                                    <div style={{
                                        backgroundColor: "white",
                                        borderRadius: "0.45rem",
                                        padding: 15,
                                        width: "100%"
                                    }}>
                                        <Table responsive striped hover size="sm">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Description</th>
                                                <th>Count</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {data?.organisationTagTableData.map((item: any, index: number) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.count}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Row>
            </>
    );
};

export default ListClassification;
