import AntdConfig from "react-awesome-query-builder-opti/lib/config/antd";
import Filters from "../../model/advanced-search/Filters";
import {hasUserRole, Role} from "../../utils/Security";

const InitialConfig = AntdConfig; // or BasicConfig

interface IsicCode {
    code: string,
    label: {
        en: string,
        fr: string,
        de: string
    },
    parent: string | null
}

export const getEndUserConfig: any = (filters: Filters, language: "en" | "fr" | "de") => {
    return {
        ...InitialConfig,
        fields: {
            "financialSummary.turnover": {
                label: 'Revenue',
                type: 'number',
                operators: ['between', 'not_between', 'greater', 'less'],
                fieldSettings: {
                    min: 0,
                },
                valueSources: ['value'],
                preferWidgets: ['number'],
            },
            "financialSummary.investmentRaised": {
                label: 'Investment Raised',
                type: 'number',
                operators: ['between', 'not_between', 'greater', 'less'],
                fieldSettings: {
                    min: 0,
                },
                valueSources: ['value'],
                preferWidgets: ['number'],
            },
            "financialSummary.turnoverChange": {
                label: '1 Year CAGR',
                type: 'number',
                operators: ['between', 'not_between', 'greater', 'less'],
                fieldSettings: {
                    min: 0,
                },
                valueSources: ['value'],
                preferWidgets: ['number'],
            },
            "financialSummary.threeYearCagr": {
                label: '3 Year CAGR',
                type: 'number',
                operators: ['between', 'not_between', 'greater', 'less'],
                fieldSettings: {
                    min: 0,
                },
                valueSources: ['value'],
                preferWidgets: ['number'],
            },
            "financialSummary.numberOfEmployees": {
                label: 'Employees',
                type: 'number',
                operators: ['between', 'not_between', 'greater', 'less'],
                fieldSettings: {
                    min: 0,
                },
                valueSources: ['value'],
                preferWidgets: ['number'],
            },
            "hgData.vendor.keyword": {
                label: 'Vendor',
                type: 'select',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                fieldSettings: {
                    listValues:
                        filters.vendors?.map(vendor =>
                            ({ value: vendor, title: vendor })
                        ),
                }
            },
            "hgData.product.keyword": {
                label: 'Products',
                type: 'select',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                fieldSettings: {
                    listValues:
                        filters.products?.map(product =>
                            ({ value: product, title: product })
                        ),
                }
            },
            // "postcode.keyword": {
            //     label: 'Location',
            //     type: 'treeselect',
            //     valueSources: ['value'],
            //     operators: [
            //         'multiselect_equals',
            //         'multiselect_not_equals',
            //         'select_any_in',
            //         'select_not_any_in'
            //     ],
            //     subfields: { //only for type == '!struct'
            //         subname: { //key of subfield
            //             label: 'Subname', //label for list of fields
            //             //label for field menu's toggler (for config.renderFieldAndOpAsDropdown == true)
            //             label2: 'MemberName',
            //             type: 'text', //one of types described below in section 'types'
            //         },
            //
            //     },
            //     fieldSettings: {
            //         treeSelectOnlyLeafs: false,
            //         listValues: locations
            //     }
            // },
            // "country.keyword": {
            //     label: 'Country',
            //     type: 'treeselect',
            //     valueSources: ['value'],
            //     operators: [
            //         'multiselect_equals',
            //         'multiselect_not_equals',
            //         'select_any_in',
            //         'select_not_any_in'
            //     ],
            //     subfields: { //only for type == '!struct'
            //         subname: { //key of subfield
            //             label: 'Subname', //label for list of fields
            //             //label for field menu's toggler (for config.renderFieldAndOpAsDropdown == true)
            //             label2: 'MemberName',
            //             type: 'text', //one of types described below in section 'types'
            //         },
            //
            //     },
            //     fieldSettings: {
            //         treeSelectOnlyLeafs: false,
            //         listValues: countries
            //     }
            // },
            "nutsClassification.nuts*Code.keyword": {
                label: 'Location (NUTS)',
                type: 'treeselect',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                fieldSettings: {
                    treeExpandAll: false,
                    treeSelectOnlyLeafs: false,
                    listValues: getNutsFilterValues(filters)
                }
            },
            "headOffice": {
                label: 'Head Office',
                type: 'treeselect',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                subfields: { //only for type == '!struct'
                    subname: { //key of subfield
                        label: 'Subname', //label for list of fields
                        //label for field menu's toggler (for config.renderFieldAndOpAsDropdown == true)
                        label2: 'MemberName',
                        type: 'text', //one of types described below in section 'types'
                    }
                },
                fieldSettings: {
                    treeSelectOnlyLeafs: true,
                    listValues: locations
                }
            },
            "isicClassification.isicLevel*Code.keyword": {
                label: 'ISIC Codes',
                type: 'treeselect',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                fieldSettings: {
                    treeExpandAll: false,
                    treeSelectOnlyLeafs: false,
                    listValues:
                        filters.isicCodes?.map(isicCode => {
                            let jsonIsic: IsicCode = JSON.parse(isicCode);
                            return Object.assign({ value: jsonIsic.code, title: jsonIsic.label[language] }, jsonIsic.parent === null ? null : { parent: jsonIsic.parent })
                        })
                }
            },
            "tags.name.keyword": {
                label: 'Tags',
                type: 'select',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                fieldSettings: {
                    listValues:
                        filters.tags?.map(tag =>
                            ({ value: tag, title: tag })
                        ),
                }
            },
            "companyStatus.keyword": {
                label: 'Status',
                type: 'select',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                fieldSettings: {
                    listValues:
                        filters.statuses?.filter(s => s !== '').map(status =>
                            ({ value: status, title: status })
                        ),
                }
            },
            "yearEstablished": {
                label: 'Established',
                type: 'number',
                operators: ['between', 'not_between', 'greater', 'less'],
                fieldSettings: {
                    min: 0
                }
            }
        }
    }
};

export const getChannelConfig: any = (filters: Filters) => {
    return {
        ...InitialConfig,
        fields: {
            "financialSummary.turnover": {
                label: 'Revenue',
                type: 'number',
                operators: ['between', 'not_between', 'greater', 'less'],
                fieldSettings: {
                    min: 0,
                },
                valueSources: ['value'],
                preferWidgets: ['number'],
            },
            "financialSummary.investmentRaised": {
                label: 'Investment Raised',
                type: 'number',
                operators: ['between', 'not_between', 'greater', 'less'],
                fieldSettings: {
                    min: 0,
                },
                valueSources: ['value'],
                preferWidgets: ['number'],
            },
            "financialSummary.turnoverChange": {
                label: '1 Year CAGR',
                type: 'number',
                operators: ['between', 'not_between', 'greater', 'less'],
                fieldSettings: {
                    min: 0,
                },
                valueSources: ['value'],
                preferWidgets: ['number'],
            },
            "financialSummary.threeYearCagr": {
                label: '3 Year CAGR',
                type: 'number',
                operators: ['between', 'not_between', 'greater', 'less'],
                fieldSettings: {
                    min: 0,
                },
                valueSources: ['value'],
                preferWidgets: ['number'],
            },
            "financialSummary.numberOfEmployees": {
                label: 'Employees',
                type: 'number',
                operators: ['between', 'not_between', 'greater', 'less'],
                fieldSettings: {
                    min: 0,
                },
                valueSources: ['value'],
                preferWidgets: ['number'],
            },
            "accreditations.accreditation.concatenatedName.keyword": {
                label: 'Accreditations',
                type: 'treeselect',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                fieldSettings: {
                    treeExpandAll: false,
                    treeSelectOnlyLeafs: false,
                    listValues: getAccreditationFilterValues(filters)
                }
            },
            "competencies.keyword": {
                label: 'Competencies',
                type: 'select',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                fieldSettings: {
                    listValues:
                        filters.competencies?.map(competency =>
                            ({ value: competency, title: competency })
                        ),
                }
            },
            "companyStatus.keyword": {
                label: 'Status',
                type: 'select',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                fieldSettings: {
                    listValues:
                        filters.statuses?.filter(s => s !== '').map(status =>
                            ({ value: status, title: status })
                        ),
                }
            },
            "tags.name.keyword": {
                label: 'Tags',
                type: 'select',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                fieldSettings: {
                    listValues:
                        filters.tags?.map(tag =>
                            ({ value: tag, title: tag })
                        ),
                }
            },
            "primaryType.keyword": {
                label: 'Company Types',
                type: 'select',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                fieldSettings: {
                    listValues:
                        filters.companyTypes?.filter(companyType => companyType != "EndUser").map(companyType =>
                            ({ value: companyType, title: companyType })
                        ),
                }
            },
            "companyType.keyword": {
                label: 'Company Legal Types',
                type: 'select',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                fieldSettings: {
                    listValues:
                        filters.companyLegalTypes?.map(companyType =>
                            ({ value: companyType, title: companyType })
                        ),
                }
            },
            "hgData.vendor.keyword": {
                label: 'Vendor',
                type: 'select',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                fieldSettings: {
                    listValues:
                        filters.vendors?.map(vendor =>
                            ({ value: vendor, title: vendor })
                        ),
                }
            },
            "hgData.product.keyword": {
                label: 'Products',
                type: 'select',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                fieldSettings: {
                    listValues:
                        filters.products?.map(product =>
                            ({ value: product, title: product })
                        ),
                }
            },
            "nutsClassification.nuts*Code.keyword": {
                label: 'Location (NUTS)',
                type: 'treeselect',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                fieldSettings: {
                    treeExpandAll: false,
                    treeSelectOnlyLeafs: false,
                    listValues: getNutsFilterValues(filters)
                }
            },
            "headOffice": {
                label: 'Head Office',
                type: 'treeselect',
                valueSources: ['value'],
                operators: [
                    'multiselect_equals',
                    'multiselect_not_equals',
                    'select_any_in',
                    'select_not_any_in'
                ],
                subfields: { //only for type == '!struct'
                    subname: { //key of subfield
                        label: 'Subname', //label for list of fields
                        //label for field menu's toggler (for config.renderFieldAndOpAsDropdown == true)
                        label2: 'MemberName',
                        type: 'text', //one of types described below in section 'types'
                    }
                },
                fieldSettings: {
                    treeSelectOnlyLeafs: true,
                    listValues: locations
                }
            },
        }
    }
};

export const getAccreditationFilterValues = (filters: Filters) => {
    let parents: string[] = [];
    let structured = filters.accreditations?.map((accreditation: string) => {
        let family = JSON.parse(accreditation);

        parents.push(family.familyName);
        return Object.assign({ value: `${family.familyName} - ${family.name}`, title: family.name, parent: family.familyName })
    });

    let uniqueParents = new Set<string>(parents);

    uniqueParents.forEach((parent: string) => {
        let option = Object.assign({ value: parent, title: parent });
        structured.push(option);
    });

    return structured;
};

export const getNutsFilterValues = (filters: Filters) => {
    let allNutsCodes =
        filters.nutsCodes?.map(nutsCode => {
            let jsonNuts = JSON.parse(nutsCode);
            return Object.assign({ value: jsonNuts.code, title: jsonNuts.label }, jsonNuts.parent === null ? null : { parent: jsonNuts.parent })
        });

    if (hasUserRole(Role.ADMIN)) {
        return allNutsCodes;
    }

    let filteredNutsCodes: any[] = [];
    if (allNutsCodes) {
        if (hasUserRole(Role.GEO_UK)) {
            filteredNutsCodes.push(...allNutsCodes.filter(nutsCode => nutsCode.value.startsWith("UK")));
        }

        if (hasUserRole(Role.GEO_DE)) {
            filteredNutsCodes.push(...allNutsCodes.filter(nutsCode => nutsCode.value.startsWith("DE")));
        }

        if (hasUserRole(Role.GEO_FR)) {
            filteredNutsCodes.push(...allNutsCodes.filter(nutsCode => nutsCode.value.startsWith("FR")));
        }
    }

    return filteredNutsCodes;
};

export const countries: { value: string; title: string; parent?: string | undefined; }[] = [
    { value: 'GB', title: 'United Kingdom' },
    { value: 'DE', title: 'Germany' },
];

export const regions: string[] = [
    "East Midlands",
    "Greater London",
    "North East",
    "North West",
    "South East",
    "South West",
    "West Midlands",
    'Brandenburg',
    'Berlin',
    'Baden-Württemberg',
    'Bayern',
    'Bremen',
    'Hessen',
    'Hamburg',
    'Mecklenburg-Vorpommern',
    'Niedersachsen',
    'Nordrhein-Westfalen'
]

export const locations: { value: string; title: string; parent?: string | undefined; }[] = [
    { value: 'United Kingdom', title: 'United Kingdom'},
    { value: 'Channel Islands', title: 'Channel Islands', parent: 'United Kingdom' },
    { value: 'England', title: 'England', parent: 'United Kingdom' },
    { value: 'Isle of Man', title: 'Isle of Man', parent: 'United Kingdom' },
    { value: 'Northern Ireland', title: 'Northern Ireland', parent: 'United Kingdom' },
    { value: 'Scotland', title: 'Scotland', parent: 'United Kingdom' },
    { value: 'Wales', title: 'Wales', parent: 'United Kingdom' },
    { value: 'East Midlands', title: 'East Midlands', parent: 'England' },
    { value: 'Greater London', title: 'Greater London', parent: 'England' },
    { value: 'North East', title: 'North East', parent: 'England' },
    { value: 'North West', title: 'North West', parent: 'England' },
    { value: 'South East', title: 'South East', parent: 'England' },
    { value: 'South West', title: 'South West', parent: 'England' },
    { value: 'West Midlands', title: 'West Midlands', parent: 'England' },
    { value: 'AB', title: 'Aberdeen', parent: 'Scotland' },
    { value: 'BA', title: 'Bath', parent: 'South West' },
    { value: 'BT', title: 'Belfast', parent: 'Northern Ireland' },
    { value: 'B', title: 'Birmingham', parent: 'West Midlands' },
    { value: 'BB', title: 'Blackburn', parent: 'North West' },
    { value: 'FY', title: 'Blackpool', parent: 'North West' },
    { value: 'BL', title: 'Bolton', parent: 'North West' },
    { value: 'GIR', title: 'Bootle', parent: 'North West' },
    { value: 'BH', title: 'Bournemouth', parent: 'South West' },
    { value: 'BD', title: 'Bradford', parent: 'North West' },
    { value: 'BS', title: 'Bristol', parent: 'South West' },
    { value: 'BR', title: 'Bromley', parent: 'Greater London' },
    { value: 'CB', title: 'Cambridge', parent: 'East Midlands' },
    { value: 'CT', title: 'Canterbury', parent: 'South East' },
    { value: 'CF', title: 'Cardiff', parent: 'Wales' },
    { value: 'CA', title: 'Carlisle', parent: 'North West' },
    { value: 'CM', title: 'Chelmsford', parent: 'South East' },
    { value: 'CH', title: 'Chester', parent: 'North West' },
    { value: 'CO', title: 'Colchester', parent: 'East Midlands' },
    { value: 'CV', title: 'Coventry', parent: 'West Midlands' },
    { value: 'CW', title: 'Crewe', parent: 'North West' },
    { value: 'CR', title: 'Croydon', parent: 'Greater London' },
    { value: 'DL', title: 'Darlington', parent: 'North East' },
    { value: 'DA', title: 'Dartford', parent: 'Greater London' },
    { value: 'DE', title: 'Derby', parent: 'East Midlands' },
    { value: 'DN', title: 'Doncaster', parent: 'East Midlands' },
    { value: 'DT', title: 'Dorchester', parent: 'South West' },
    { value: 'DY', title: 'Dudley', parent: 'West Midlands' },
    { value: 'DG', title: 'Dumfries', parent: 'Scotland' },
    { value: 'DD', title: 'Dundee', parent: 'Scotland' },
    { value: 'DH', title: 'Durham', parent: 'North East' },
    { value: 'EH', title: 'Edinburgh', parent: 'Scotland' },
    { value: 'EN', title: 'Enfield', parent: 'Greater London' },
    { value: 'EX', title: 'Exeter', parent: 'South West' },
    { value: 'FK', title: 'Falkirk', parent: 'Scotland' },
    { value: 'G', title: 'Glasgow', parent: 'Scotland' },
    { value: 'GL', title: 'Gloucester', parent: 'South West' },
    { value: 'GY', title: 'Guernsey', parent: 'Channel Islands' },
    { value: 'GU', title: 'Guilford', parent: 'South East' },
    { value: 'HX', title: 'Halifax', parent: 'North West' },
    { value: 'HG', title: 'Harrogate', parent: 'North East' },
    { value: 'HA', title: 'Harrow', parent: 'Greater London' },
    { value: 'HP', title: 'Hemel and Hempsted', parent: 'South East' },
    { value: 'HR', title: 'Hereford', parent: 'West Midlands' },
    { value: 'HD', title: 'Huddersfield', parent: 'North West' },
    { value: 'HU', title: 'Hull', parent: 'North East' },
    { value: 'IG', title: 'Ilford', parent: 'Greater London' },
    { value: 'IV', title: 'Inverness', parent: 'Scotland' },
    { value: 'IP', title: 'Ipswich', parent: 'East Midlands' },
    { value: 'IM', title: 'Isle of Man', parent: 'Isle of Man' },
    { value: 'JE', title: 'Jersey', parent: 'Channel Islands' },
    { value: 'KA', title: 'Kilmarnock', parent: 'Scotland' },
    { value: 'KT', title: 'Kingston', parent: 'Greater London' },
    { value: 'KY', title: 'Kirkaldy', parent: 'Scotland' },
    { value: 'KW', title: 'Kirkwall', parent: 'Scotland' },
    { value: 'LA', title: 'Lancaster', parent: 'North West' },
    { value: 'LS', title: 'Leeds', parent: 'North East' },
    { value: 'LE', title: 'Leicester', parent: 'East Midlands' },
    { value: 'LN', title: 'Lincoln', parent: 'East Midlands' },
    { value: 'L', title: 'Liverpool', parent: 'North West' },
    { value: 'LD', title: 'Llandrindod', parent: 'Wales' },
    { value: 'LL', title: 'Llandudno', parent: 'Wales' },
    { value: 'E', title: 'East London', parent: 'Greater London' },
    { value: 'EC', title: 'East Central London', parent: 'Greater London' },
    { value: 'N', title: 'North London', parent: 'Greater London' },
    { value: 'NW', title: 'North West London', parent: 'Greater London' },
    { value: 'SE', title: 'South East London', parent: 'Greater London' },
    { value: 'SW', title: 'South West London', parent: 'Greater London' },
    { value: 'W', title: 'West London', parent: 'Greater London' },
    { value: 'WC', title: 'West Central London', parent: 'Greater London' },
    { value: 'LU', title: 'Luton', parent: 'South East' },
    { value: 'M', title: 'Manchester', parent: 'North West' },
    { value: 'ME', title: 'Medway', parent: 'South East' },
    { value: 'MK', title: 'Milton Keynes', parent: 'South East' },
    { value: 'ML', title: 'Motherwell', parent: 'Scotland' },
    { value: 'NE', title: 'Newcastle', parent: 'North East' },
    { value: 'NP', title: 'Newport', parent: 'Wales' },
    { value: 'NN', title: 'Northampton', parent: 'West Midlands' },
    { value: 'NR', title: 'Norwich', parent: 'East Midlands' },
    { value: 'NG', title: 'Nottingham', parent: 'East Midlands' },
    { value: 'OL', title: 'Oldham', parent: 'North West' },
    { value: 'OX', title: 'Oxford', parent: 'South East' },
    { value: 'PA', title: 'Paisley', parent: 'Scotland' },
    { value: 'PH', title: 'Perth', parent: 'Scotland' },
    { value: 'PE', title: 'Peterborough', parent: 'East Midlands' },
    { value: 'PL', title: 'Plymouth', parent: 'South West' },
    { value: 'PO', title: 'Portsmouth', parent: 'South East' },
    { value: 'PR', title: 'Preston', parent: 'North West' },
    { value: 'RG', title: 'Reading', parent: 'South East' },
    { value: 'RH', title: 'Redhill', parent: 'South East' },
    { value: 'RM', title: 'Romford', parent: 'Greater London' },
    { value: 'SP', title: 'Salisbury', parent: 'South West' },
    { value: 'S', title: 'Sheffield', parent: 'East Midlands' },
    { value: 'SY', title: 'Shrewsbury', parent: 'Wales' },
    { value: 'SL', title: 'Slough', parent: 'South East' },
    { value: 'UB', title: 'Southall', parent: 'Greater London' },
    { value: 'SO', title: 'Southampton', parent: 'South East' },
    { value: 'SS', title: 'Southend', parent: 'East Midlands' },
    { value: 'AL', title: 'St. Albans', parent: 'South East' },
    { value: 'SG', title: 'Stevenage', parent: 'South East' },
    { value: 'SK', title: 'Stockport', parent: 'North West' },
    { value: 'ST', title: 'Stoke on Trent', parent: 'West Midlands' },
    { value: 'SR', title: 'Sunderland', parent: 'North East' },
    { value: 'SM', title: 'Sutton', parent: 'Greater London' },
    { value: 'SA', title: 'Swansea', parent: 'Wales' },
    { value: 'SN', title: 'Swindon', parent: 'South West' },
    { value: 'TA', title: 'Taunton', parent: 'South West' },
    { value: 'TF', title: 'Telford', parent: 'West Midlands' },
    { value: 'TN', title: 'Tunbridge', parent: 'South East' },
    { value: 'TQ', title: 'Torquay', parent: 'South West' },
    { value: 'TR', title: 'Truro', parent: 'South West' },
    { value: 'TW', title: 'Twickenham', parent: 'Greater London' },
    { value: 'WF', title: 'Wakefield', parent: 'North East' },
    { value: 'WS', title: 'Walsall', parent: 'West Midlands' },
    { value: 'WA', title: 'Warrington', parent: 'North West' },
    { value: 'WD', title: 'Watford', parent: 'Greater London' },
    { value: 'WN', title: 'Wigan', parent: 'North West' },
    { value: 'WV', title: 'Wolverhampton', parent: 'West Midlands' },
    { value: 'WR', title: 'Worcester', parent: 'West Midlands' },
    { value: 'YO', title: 'York', parent: 'North East' },
];