import React, {useEffect, useState} from "react";
import List from "../../model/list/List";
import {Alert, Card, ListGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faTrashAlt, faUser, faUsers} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {frontEndHostName} from "../../utils/Configuration";
import translate from "../../i18n/translate";

interface Props {
    organisationId: string;
    delete: (list: List) => void;
}

const FeaturedInLists: React.FC<Props> = (props: Props) => {
    const [featuredInLists, setFeaturedInLists] = useState<List[]>([]);
    const [loadingLists, setLoadingLists] = useState<boolean>(false);
    const [loadingError, setLoadingError] = useState<boolean>(false);
    useEffect(() => {
        const fetchLists = () => {
            setLoadingLists(true);
            axios.get(frontEndHostName + "user-lists/organisation/" + props.organisationId)
                .then(r => setFeaturedInLists(r.data))
                .catch(() => setLoadingError(true))
                .finally(() => setLoadingLists(false));
        };

        fetchLists();
    }, []);

    return (
        <Card style={{ width: 450 }}>
            <Card.Body>
                {loadingLists ? <IqLoadingIcon /> : (
                    <ListGroup>
                        {featuredInLists.length === 0 ? (
                            <Alert variant="info">
                                {translate("organisation.featuredon.nolists")}
                            </Alert>
                        ) :
                            featuredInLists.map((list: List) => (
                                <Card className="featured-on mb-2">
                                    <Card.Body className="featured-on-body">
                                        <span className="list-title">
                                            {list.id}: {list.title}
                                            <OverlayTrigger
                                                overlay={
                                                    <Tooltip id="radio-tooltip">
                                                        {list.shares?.length > 0 ? "Shared" : "Private"}
                                                    </Tooltip>
                                                }
                                                delay={{ show: 100, hide: 200 }}
                                                placement="right">
                                                <span style={{marginLeft: "0.4rem"}}>{list.shares?.length > 0
                                                    ? <FontAwesomeIcon icon={faUsers} color={"#3078c7"} />
                                                    : <FontAwesomeIcon icon={faUser} color={"#3078c7"} />}</span>
                                            </OverlayTrigger>
                                        </span>
                                        <div className="list-actions">
                                            <div className="list-action">
                                                <a className="iq-button iq-button-primary mb-2 pull-right" href={`/lists/${list.id}`}>
                                                    <span style={{ paddingRight: 26 }}>{translate("lists.viewlist")}</span>
                                                    <FontAwesomeIcon icon={faChevronRight} size="lg" />
                                                </a>
                                            </div>
                                            <div className="list-action">
                                                <button className="iq-button iq-button-secondary pull-right" onClick={() => props.delete(list)}>
                                                    <span style={{ paddingRight: 10 }}>{translate("organisation.featuredon.remove")}</span>
                                                    <FontAwesomeIcon icon={faTrashAlt} size="1x" />
                                                </button>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            )
                            )}
                    </ListGroup>
                )}

                {loadingError && (
                    <Alert variant="danger">
                        {translate("errors.loading")}
                    </Alert>
                )}
            </Card.Body>
        </Card>
    );
};

export default FeaturedInLists;
