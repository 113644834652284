import React, {useEffect, useState} from 'react';
import {Alert, Button, FormControl, InputGroup, Table} from 'react-bootstrap';
import axios from 'axios';
import {organisationHostname} from '../../utils/Configuration';

interface Props {
}

const AdminAuthoritiesContainer: React.FC<Props> = (props: Props) => {

    const [authorities, setAuthorities] = useState<[]>([]);
    const [authority, setAuthority] = useState<string>("");
    const [renderSwitch, setRenderSwitch] = useState<boolean>(false);
    const [serverResponse, setServerResponse] = useState<number>(0);

    useEffect(() => {
        const fetchAuthorities = async () => {
            const result = await axios.get(organisationHostname + 'authorities/')
                .then((response) => {
                    setAuthorities(response.data);
                }).catch(error => console.log(error.message));
        };
        fetchAuthorities();
    }, [renderSwitch]);

    const onSubmit = () => {

        axios.post(organisationHostname + 'authorities/create', authority, {
            headers: { 'Content-Type': 'text/plain' }
        }).then((response) => {
            if (response.status === 200) {
                setRenderSwitch(!renderSwitch);
                setServerResponse(2);
            } else {
                setServerResponse(1);
            }
        }).catch(error => console.log(error.message));

    };

    const handleAuthorityChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setAuthority(e.target.value);
        setServerResponse(0);
    }

    return (
        <div>
            <InputGroup className="mb-3">
                <FormControl
                    type="text"
                    placeholder="New authority name"
                    aria-label="Authority"
                    onChange={handleAuthorityChange}
                />
                {/*<InputGroup.Append>*/}
                    <Button onClick={onSubmit} variant="outline-primary">Add</Button>
                {/*</InputGroup.Append>*/}
            </InputGroup>
            {serverResponse === 1 &&
                <Alert variant="danger">
                    Error! Make sure you format the authority name correctly with prefix ROLE_, alphabetic characters and underscores.
                            </Alert>}
            {serverResponse === 2 &&
                <Alert variant="success">
                    You have successfully added the new authority.
                            </Alert>}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Authority name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        authorities
                            .map(authority => (
                                <tr key={authority}>
                                    <td>{authority}</td>
                                </tr>
                            ))
                    }
                </tbody>
            </Table>
        </div>
    );
}

export default AdminAuthoritiesContainer;