import React, {useEffect, useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import AsyncSelect from "react-select/async";
import {Typeahead} from "react-bootstrap-typeahead";
import {Role} from "../../utils/Security";
import axios from "axios";
import {frontEndHostName, organisationHostname, searchHostname, socialHostName} from "../../utils/Configuration";
import {OrganisationUser} from "../teams/edit/EditTeam";
import {useForm} from "react-hook-form";


interface Props{
    closeProp: boolean
    isCreated(created: boolean): void
}


const NewUserForm: React.FC<Props> = (props: Props) => {

    const { register, watch, handleSubmit, errors, triggerValidation, getValues, setValue } = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    });
    const [responseError, setResponseError] = useState("");

    const [password, setPassword] = useState<string>(Math.random().toString(36).slice(-8));
    const [organisation, setOrganisation] = useState<string>("");
    const [domains, setDomains] = useState<string[]>([]);

    const [modalShow, setModalShow] = useState(false);
    const [roleAuthorities, setRoleAuthorities] = useState<string[]>([]);
    const [loadingAuthorities, setLoadingAuthorities] = useState<boolean>(false);
    const [authoritiesError, setAuthoritiesError] = useState<boolean>(false);
    const [user, setUser] = useState<OrganisationUser>({} as OrganisationUser);
    const [contact, setContact] = useState<HubspotContact>();
    const [isCreatedFlag, setIsCreatedFlag] = useState<boolean>(false);
    const [isManual, setIsManual] = useState<boolean>(false)

    interface SearchCompany {
        companyId: string,
        companyNumber: string,
        companyName: string,
        imageUrl: string,
        country: string,
        employees: number,
        revenues: number
    }

    interface SelectOption {
        value: string,
        label: string
    }

    interface HubspotContact {
        login: string,
        email: string,
        firstName: string,
        lastName: string,
        organisationId: string,
        authorities: string[],
        activated: boolean

    }

    useEffect(() => {
        if (isCreatedFlag) {
            console.log("Inside Hubspot")
            if (contact !== undefined && contact !== null) {
                addHubspotContact(contact)
            }
            console.log("Outside Hubspot")
            setIsCreatedFlag(false)

        }
    }, [isCreatedFlag])

    const saveUser = () => {

        let tempUser = {
            login: getValues("login"),
            email: getValues("email"),
            organisationId: organisation,
            firstName: getValues("firstName"),
            lastName: getValues("lastName"),
            authorities: user.authorities,
            activated: user.activated
        };

        let postData = {
            user: tempUser,
            password: password
        };

        setContact(tempUser);


        axios.post(frontEndHostName + 'user/register', postData,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(() => {
                if (isManual) {
                    props.isCreated(true)
                } else {
                    setIsCreatedFlag(true);
                }
            })
            .catch(error => {
                let responseData = error.response.data;
                switch (responseData.errorKey) {
                    case "userexists": {
                        setResponseError("Username already exists.");
                        break;
                    }
                    case "emailexists": {
                        setResponseError("Email already exists.");
                        break;
                    }
                    default: {
                        setResponseError("Could not create the user account. Try again.");
                    }
                }
            });
    };

    const submit = () => {
        triggerValidation()
            .then(success => {
                if (success) {
                    saveUser();
                }
            });
    };

    const addHubspotContact = (tempUser: HubspotContact) => {
        axios.post(socialHostName + "add-hubspot-contact", tempUser,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(() => props.isCreated(true))
            .catch(error => {
            let responseData = error.response.data;
            switch (responseData.errorKey) {
                case "noemail": {
                    setResponseError("The email passed down to the service is not valid");
                    break;
                }
                case "nocomphub": {
                    setResponseError("The company listed was not found on hubspot");
                    break;
                }
                default: {
                    setResponseError("Could not create the user account on Hubspot System. Try again.");
                }
            }
        });

    }


    const loadOrganisations = (q: string) => {
        let data = {
            searchQuery: q,
            filters: ["Active"]
        };

        return new Promise<SelectOption[]>((resolve: any, reject: any) => {
            axios.post(searchHostname + "quick-search", data)
                .then((r) => {
                    resolve(
                        r.data.map((o: SearchCompany) => {
                            return {
                                label: o.companyName,
                                value: o.companyId
                            }
                        })
                    );
                })
                .catch(reject);
        });
    };

    useEffect(() => {
        const fetchAuthorities = async () => {
            setLoadingAuthorities(true);

            await axios.get(frontEndHostName + 'authorities')
                .then((response) => {
                    setRoleAuthorities(response.data);
                })
                .catch(() => setAuthoritiesError(true))
                .finally(() => setLoadingAuthorities(false));

        };

        fetchAuthorities();
    }, []);

    const handleAuthoritiesChange = (authorities: any[]) => {
        let discoveryRoles: string[] = [Role.DISCOVERY_THREE, Role.DISCOVERY_TWO, Role.DISCOVERY_ONE];
        let accountManager: string[] = [Role.BASIC_ACCOUNT_MANAGER, Role.ADVANCED_ACCOUNT_MANAGER];

        if (authorities.some(role => discoveryRoles.includes(role as string))
            && authorities.some(role => accountManager.includes(role as string))){
            setAuthoritiesError(true)
        } else {
            let auth = authorities as string[];
            setUser({
                ...user,
                authorities: [...auth]
            });

        }
    };

    const handleManualCheckboxChange = (e: { target: { name: any; checked: any; }; }) => {
        setIsManual(!isManual)
    }

    const handleCheckboxChange = (e: { target: { name: any; checked: any; }; }) => {
        setUser({
            ...user,
            [e.target.name]: e.target.checked
        })
    };


    const fetchDomains = async (organisationId: string) => {
        await axios.get(organisationHostname + "organisation/" + organisationId + "/domains")
            .then(r => {
                setDomains(r.data);
            });
    };


    const updateOrganisation = (value: string) => {
        setOrganisation(value);
        fetchDomains(value);
    };



    return (
        <div>
        <Form>
            <Form.Group className="mb-3" controlId="formUserName">
                <Form.Label>Username</Form.Label>
                <Form.Control name="login"
                              type="text"
                              placeholder="Enter username"
                              ref={register({ required: true, minLength: 1, maxLength: 50 })}
                              isInvalid={errors.login} />
                <Form.Control.Feedback type="invalid">
                    {errors.login && errors.login.type === "required" && "Please choose a username."}
                    {errors.login && errors.login.type === "minLength" && "Minimum length of username is 1."}
                    {errors.login && errors.login.type === "maxLength" && "Maximum length of username is 50."}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>First name</Form.Label>
                <Form.Control name="firstName" type="text" placeholder="Enter first name"
                              ref={register({ required: true, maxLength: 50 })}
                              isInvalid={errors.firstName} />
                <Form.Control.Feedback type="invalid">
                    {errors.firstName && errors.firstName.type === "required" && "Please fill in the first name."}
                    {errors.firstName && errors.firstName.type === "maxLength" && "Maximum length of first name is 50."}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label>Last name</Form.Label>
                <Form.Control name="lastName" type="text" placeholder="Enter last name"
                              ref={register({ required: true, maxLength: 50 })}
                              isInvalid={errors.lastName} />
                <Form.Control.Feedback type="invalid">
                    {errors.lastName && errors.lastName.type === "required" && "Please fill in the last name."}
                    {errors.lastName && errors.lastName.type === "maxLength" && "Maximum length of last name is 50."}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formOrganisationId">
                <Form.Label>Organisation</Form.Label>

                <AsyncSelect
                             defaultOptions={[]}
                             onChange={(e: any) => updateOrganisation(e.value)}
                             loadOptions={loadOrganisations}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control name="email" type="email" placeholder="Enter email"
                              ref={register({
                                  required: true,
                                  minLength: 5,
                                  maxLength: 254,
                                  validate: {
                                      validDomain: (value: string) => domains.some((d: string) => value.endsWith(d))
                                  }
                              })}
                              isInvalid={errors.email} />
                <Form.Control.Feedback type="invalid">
                    {errors.email && errors.email.type === "required" && "Please fill in the email address."}
                    {errors.email && errors.email.type === "minLength" && "Minimum length of email is 5."}
                    {errors.email && errors.email.type === "maxLength" && "Maximum length of email is 254."}
                    {errors.email && errors.email.type === "validDomain" && `Email domain must match a company domain: ${domains.join(", ")}`}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label>Autogenerated Password</Form.Label>
                <Form.Control name="password" type="text" value={password} readOnly />
                <Form.Text className="text-muted">
                    Save this password before submit. You will thank me later.
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formAuthorities">
                <Form.Label>Choose authorities</Form.Label>
                <Typeahead
                    clearButton
                    selected={user.authorities}
                    id="selection-authorities"
                    multiple
                    options={roleAuthorities}
                    onChange={handleAuthoritiesChange}
                    placeholder="Choose authorities..."
                    ref={register}
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formManual">
                <Form.Label>Hubspot</Form.Label>
                <Form.Check name="manual-hubspot" type="checkbox" checked={isManual} onChange={handleManualCheckboxChange}
                            ref={register} label="Disable automatic Hubspot intergration" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formActivated">
                <Form.Label>Activated</Form.Label>
                <Form.Check name="activated" type="checkbox" checked={user.activated} onChange={handleCheckboxChange}
                            ref={register} label="Tick the box to activate, untick to deactivate" />
            </Form.Group>

            {responseError !== "" &&
                <Alert variant="danger">
                    {responseError}
                </Alert>
            }
        </Form>

        <Button className="pull-right" variant="success" type="button" onClick={() => submit()}>
            Submit
        </Button>
    </div>
    )



}


export default NewUserForm;