export const IQBLADE_HOSTNAME = process.env.REACT_APP_IQBLADE_PROXY_HOSTNAME || "http://localhost:3000/v1";

export const frontEndHostName = IQBLADE_HOSTNAME + "/frontend-service/api/";
export const organisationHostname = IQBLADE_HOSTNAME + "/organisation-service/api/";
export const searchHostname = IQBLADE_HOSTNAME + "/search-service/api/";
export const socialHostName = IQBLADE_HOSTNAME + "/social-service/api/";
export const notificationHostName = IQBLADE_HOSTNAME + "/notification-service/api/";
export const contractsHostName = IQBLADE_HOSTNAME + "/contract-service/api/";
export const caseStudiesHostName = IQBLADE_HOSTNAME + "/casestudies-service/";


export const gatewayRoutes = IQBLADE_HOSTNAME.replace("v1", "api/gateway/routes");


export const csvUploadHostName = IQBLADE_HOSTNAME + "/csvuploader-service/csvupload";
