import React, {ReactElement} from 'react';
import {Card, OverlayTrigger, Popover} from 'react-bootstrap';
import {Placement} from "react-bootstrap/types";


interface HoverTipProps {
    id: string

    tooltip: string;
    tooltipPosition: Placement;
    children: ReactElement

}



const HoverToolTip: React.FC<HoverTipProps> = ({id,tooltip, tooltipPosition, children}) => {
    return (
        <OverlayTrigger trigger="hover"
                        placement={tooltipPosition}
                        rootClose={true}
                        overlay={
                            <Popover id={id}>
                                <Card>
                                    <Card.Body>
                                        <Card.Text>{tooltip}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Popover>
                        }>
            {children}
        </OverlayTrigger>
    )
}

export default HoverToolTip;