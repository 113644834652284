import React, {useContext} from "react";
import {Row} from "react-bootstrap";
import TeamContext from "../../context/teams/TeamContext";
import translate from "../../i18n/translate";


interface Props {
    totalLogins: number,
    totalLists: number,
    totalSearches: number
}

const OverviewSummary: React.FC<Props> = (props: Props) => {
    const {team, setTeam} = useContext(TeamContext);

    return (
        <div>
            <Row>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="organisation-card purple">
                        <div className="team-overview-label">
                            {translate("teams.common.searches")}
                        </div>
                        <div className="team-overview-value">
                            {props.totalSearches}
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="organisation-card pink">
                        <div className="team-overview-label">
                            {translate("teams.logins.totallogins")}
                        </div>
                        <div className="team-overview-value">
                            {props.totalLogins}
                        </div>
                    </div>
                </div>
            </Row>
            <Row className="mt-4">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="organisation-card green">
                        <div className="team-overview-label">
                            {translate("teams.manage.usersinteam")}
                        </div>
                        <div className="team-overview-value">
                            {team?.users?.length}
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="organisation-card orange">
                        <div className="team-overview-label">
                            {translate("teams.lists.totallists")}
                        </div>
                        <div className="team-overview-value">
                            {props.totalLists}
                        </div>
                    </div>
                </div>
            </Row>
        </div>
    );
};

export default OverviewSummary;
