import React, {useContext, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import I18nContext from '../../../context/I18nContext';
import UserOrganisationContext from '../../../context/organisation/OrganisationContext';
import translate from '../../../i18n/translate';
import IsicClassification from '../../../model/organisation/IsicClassification';
import Organisation from '../../../model/organisation/Organisation';
import OrganisationSocialContext from "../../../context/organisation/OrganisationSocialContext";

const OrganisationIsicClassification: React.FC = () => {

    const { organisation } = useContext(UserOrganisationContext);
    const { lang } = useContext(I18nContext);

    enum ClassificationType {
        ISIC = "organisation.summary.classification.isic",
        SIC = "organisation.summary.classification.sic",
        WZ = "organisation.summary.classification.wz",
        NAF = "organisation.summary.classification.naf",
        USSIC = "organisation.summary.classification.ussic"
    }

    const getInitialSelection = (): string => {
        let selection = organisation.countryCode === "UK" ?
            ClassificationType.SIC : organisation.countryCode === "DE" ?
                ClassificationType.WZ : organisation.countryCode === "FR" ?
                    ClassificationType.NAF : ClassificationType.ISIC;

        return selection;
    }
    const [selected, setSelected] = useState<string>(getInitialSelection());
    const [expanded, setExpanded] = useState<boolean>(false);

    const onExpandView = (e: any) => {
        e.preventDefault();
        setExpanded(true);
    }

    const handleClose = () => {
        setExpanded(false);
    }

    const {socialHandles} = useContext(OrganisationSocialContext);
    const hasSocials = () => {
        return socialHandles.id != null;
    };

    return (
        <div className={hasSocials() ? "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4" : "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6" }>
            <div className="organisation-card" style={{ height: "100%" }}>
                <div className="organisation-card-label">
                    <span onClick={() => setSelected(ClassificationType.ISIC)}
                          style={{ cursor: "pointer" }}
                          className={selected === ClassificationType.ISIC ? "selected" : ""}>
                        {translate(ClassificationType.ISIC)}
                    </span>
                    <span> / </span>
                    {organisation.countryCode === "UK" &&
                        <>
                            <span onClick={() => setSelected(ClassificationType.SIC)}
                                  style={{ cursor: "pointer" }}
                                  className={selected === ClassificationType.SIC ? "selected" : ""}>
                                {translate(ClassificationType.SIC)}
                            </span>
                        </>
                    }
                    {organisation.countryCode === "DE" &&
                        <>
                            <span onClick={() => setSelected(ClassificationType.WZ)}
                                  style={{ cursor: "pointer" }}
                                  className={selected === ClassificationType.WZ ? "selected" : ""}>
                                {translate(ClassificationType.WZ)}
                            </span>
                        </>
                    }
                    {organisation.countryCode === "FR" &&
                        <>
                            <span onClick={() => setSelected(ClassificationType.NAF)}
                                  style={{ cursor: "pointer" }}
                                  className={selected === ClassificationType.NAF ? "selected" : ""}>
                                {translate(ClassificationType.NAF)}
                            </span>
                        </>
                    }
                    {organisation.countryCode === "US" &&
                        <>
                            <span onClick={() => setSelected(ClassificationType.USSIC)}
                                  style={{ cursor: "pointer" }}
                                  className={selected === ClassificationType.USSIC ? "selected" : ""}>
                                {translate(ClassificationType.USSIC)}
                            </span>
                        </>
                    }
                </div>
                <div className="organisation-card-text">
                    {selected === ClassificationType.ISIC &&
                        <>
                            {organisation.isicClassification?.length > 0 ? organisation.isicClassification?.map((isicCode: IsicClassification) => (
                                <>{
                                    lang === 'de' ?
                                        [isicCode.isicLevelThreeCode, ' - ', isicCode.isicLevelThreeNameDe].join("")
                                        : lang === 'fr' ?
                                            [isicCode.isicLevelThreeCode, ' - ', isicCode.isicLevelThreeNameFr].join("")
                                            : [isicCode.isicLevelThreeCode, ' - ', isicCode.isicLevelThreeNameEn].join("")
                                }<br /></>
                            )) : '-'}
                            {organisation.isicClassification?.length > 0 &&
                                <a href="#" onClick={onExpandView}>{translate('organisation.summary.classification.expand')}</a>}
                        </>
                    }
                    {selected === ClassificationType.SIC &&
                        (organisation.sicCodes?.length > 0 ? organisation.sicCodesV2?.map((sicCode: {
                            code: string,
                            description: string,
                            section: {
                                code: string,
                                description: string
                            }
                        }) => (
                            <>{[sicCode.section.code, ' - ',
                            sicCode.section.description, ' > ',
                            sicCode.code, ' - ',
                            sicCode.description].join("")}<br /></>
                        )) : '-')
                    }
                    {selected === ClassificationType.WZ &&
                        (organisation.wz2008Codes?.length > 0 ? organisation.wz2008Codes?.map((wzCode: Organisation['wz2008Codes'][0]) => (
                            <>{[wzCode.code, lang === 'de' ? wzCode.nameDe : wzCode.nameEn].filter(val => val !== null).join(" - ")}<br /></>
                        )) : '-')
                    }
                    {selected === ClassificationType.USSIC &&
                        (organisation.usSicCodes?.length > 0 ? organisation.usSicCodes?.map((usSicCode: Organisation['usSicCodes'][0]) => (
                            <>{[usSicCode.code, usSicCode.description].filter(val => val !== null).join(" - ")}<br /></>
                        )) : '-')
                    }
                    {selected === ClassificationType.NAF &&
                        (organisation.nafCodes?.length > 0 ? organisation.nafCodes?.map((nafCode: Organisation['nafCodes'][0]) => (
                            <>{[nafCode.code, lang === 'fr' ? nafCode.nameFr : nafCode.nameEn].filter(val => val !== null).join(" - ")}<br /></>
                        )) : '-')
                    }
                    <Modal show={expanded} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{color: '#38435c'}}>{translate('organisation.summary.classification.isichierarchy')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {organisation.isicClassification?.length > 0 ? organisation.isicClassification?.map((isicCode: IsicClassification) => (
                                <ul style={{ backgroundColor: '#577cd9', fontWeight: 'lighter', borderRadius: '0.4em', color:'#ffffff'}}>
                                    <li>
                                        {
                                            lang === 'de' ?
                                                [isicCode.isicLevelZeroCode, ' - ', isicCode.isicLevelZeroNameDe].join("")
                                                : lang === 'fr' ?
                                                    [isicCode.isicLevelZeroCode, ' - ', isicCode.isicLevelZeroNameFr].join("")
                                                    : [isicCode.isicLevelZeroCode, ' - ', isicCode.isicLevelZeroNameEn].join("")
                                        }
                                    </li>
                                    <ul style={{ backgroundColor: '#628fe2', borderRadius: '0.4em'}}>
                                        <li>
                                            {
                                                lang === 'de' ?
                                                    [isicCode.isicLevelOneCode, ' - ', isicCode.isicLevelOneNameDe].join("")
                                                    : lang === 'fr' ?
                                                        [isicCode.isicLevelOneCode, ' - ', isicCode.isicLevelOneNameFr].join("")
                                                        : [isicCode.isicLevelOneCode, ' - ', isicCode.isicLevelOneNameEn].join("")
                                            }
                                        </li>
                                        <ul style={{ backgroundColor: '#6da1ea', borderRadius: '0.4em'}}>
                                            <li>
                                                {
                                                    lang === 'de' ?
                                                        [isicCode.isicLevelTwoCode, ' - ', isicCode.isicLevelTwoNameDe].join("")
                                                        : lang === 'fr' ?
                                                            [isicCode.isicLevelTwoCode, ' - ', isicCode.isicLevelTwoNameFr].join("")
                                                            : [isicCode.isicLevelTwoCode, ' - ', isicCode.isicLevelTwoNameEn].join("")
                                                }
                                            </li>
                                            <ul style={{ backgroundColor: '#78b3f3', borderRadius: '0.4em'}}>
                                                <li>{
                                                    lang === 'de' ?
                                                        [isicCode.isicLevelThreeCode, ' - ', isicCode.isicLevelThreeNameDe].join("")
                                                        : lang === 'fr' ?
                                                            [isicCode.isicLevelThreeCode, ' - ', isicCode.isicLevelThreeNameFr].join("")
                                                            : [isicCode.isicLevelThreeCode, ' - ', isicCode.isicLevelThreeNameEn].join("")
                                                }</li>
                                            </ul>
                                        </ul>
                                    </ul>
                                </ul>
                            )) : '-'}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            </div>
        </div>
    )
};

export default OrganisationIsicClassification;