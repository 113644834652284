import React, {useContext, useEffect, useRef, useState} from "react";
import {Card, OverlayTrigger, Popover, Image, Modal} from "react-bootstrap";
import "./ActiveOfficer.css";
import AdvertisedJob from "../../../model/organisation/AdvertisedJob";
import {faCalendar} from "@fortawesome/free-solid-svg-icons/faCalendar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarked} from "@fortawesome/free-solid-svg-icons";
import './ActiveJobs.css'
import translate from "../../../i18n/translate";
import UserOrganisationContext from "../../../context/organisation/OrganisationContext";
import Organisation from "../../../model/organisation/Organisation";
import ActiveJobInfo from "./ActiveJobInfo";
import DateFormatter from "../../../utils/formatters/DateFormatter"

interface Props {
    job: AdvertisedJob
}

const ActiveJobs: React.FC<Props> = (props: Props) => {

    const {organisation} = useContext(UserOrganisationContext);

    const [job, setJob] = useState<AdvertisedJob>(props.job);
    const [overflowActive, setOverflowActive] = useState(false);
    const [showToolTip, setShowToolTip] = useState<boolean>(false)
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const getSalary = (job: AdvertisedJob) => {
            if (job.salaryLower !== null && job.salaryUpper !== null) {
                return "£"+job.salaryLower + " - £" + job.salaryUpper;
            }

            if (job.salaryLower == null && job.salaryUpper == null) {
                return job.salaryType
            }

        return null;
    }

    const textRef =useRef<HTMLSpanElement | null>(null);

    const checkOverflow = (textContainer: HTMLSpanElement | null): boolean => {
        if (textContainer)
            return (
                textContainer.offsetWidth < textContainer.getBoundingClientRect().width
            );
        return false;
    };

    const CompanyLogo: React.FC<Organisation> = (org: Organisation) => {
        const [src, setSrc] = useState<string>(org.imageUrl ? org.imageUrl : "./icons_company.svg");

        return (
            <Image src={src}
                   title={org.companyName}
                   onError={() => setSrc("/icons_company.svg")}
                   style={{
                       height: "90px",
                       width: "90px",
                       objectFit: "cover",
                       marginRight: "auto",
                       marginLeft: "auto",
                       marginBottom: 15,
                       outline: 'solid',
                       color: "#367bda"
                   }}
                   roundedCircle/>
        );
    };


    useEffect(() => {
        if (checkOverflow(textRef.current)) {
            setOverflowActive(true);
            setShowToolTip(true)
            return;
        }

        setOverflowActive(false);
    }, [overflowActive]);

    const popover = (
        <Popover id="popover-basic">
            <div className="p-3">
                {job.jobTitle}
            </div>
        </Popover>
    );

    const toggleModal = () => {
        setShowInfo(!showInfo)
    };

    return (
        <>
        <Card onClick={() => setShowInfo(true)} className="pb-3 job-card" style={{margin: "5px", marginBottom: "4em", cursor: "pointer"}} >
            <Card.Header style={{borderTopLeftRadius: "19px", borderTopRightRadius: "19px"}}>
                <OverlayTrigger trigger="hover" placement="top" overlay={popover}>
                    <span className="job-role pt-2">{job.jobTitle}</span>
                </OverlayTrigger>
            </Card.Header>
            <div className="text-center pt-3 mb-3">
                <div className="job-advert-container">
                    <div className="pb-3 text-center">
                            <CompanyLogo {...organisation} />
                    </div>
                    <div className="pb-3">
                        <span className="job-company-name ps-4 ">{organisation.companyName}</span>
                    </div>
                    <div className="job-details">
                        <div>
                        <FontAwesomeIcon icon={faCalendar} style={{alignItems: "left"}}/>
                            <span style={{textAlign: "left", paddingLeft: "10px"}}>{DateFormatter.formatMillis(job.postDate)}</span>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faMapMarked} style={{alignItems: "left"}}/>
                            <span style={{textAlign: "left", paddingLeft: "10px"}}>{job.location === null ? "Anywhere" : job.location.trimLeft().trimRight() }</span>
                        </div>
                    </div>
                </div>
            </div>
            <Card.Footer style={{backgroundColor: "white"}}>
                <div style={{textAlign: "center"}}>
                    {getSalary(job)}
                </div>
            </Card.Footer>
        </Card>
        <Modal size="lg" show={showInfo} onHide={() => setShowInfo(false)}>
            <ActiveJobInfo job={job}/>
        </Modal>
    </>
    );
};

export default ActiveJobs;
