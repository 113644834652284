import React, {useEffect, useState} from "react";
import NewsItem from "../../model/news/NewsItem";
import axios from "axios";
import {socialHostName} from "../../utils/Configuration";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faUnlink} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "react-bootstrap";

interface Props {
    articleId: string
}

const ArticleLink: React.FC<Props> = (props: Props) => {
    const [articleLoading, setArticleLoading] = useState<boolean>(false);
    const [articleError, setArticleError] = useState<boolean>(false);
    const [article, setArticle] = useState<NewsItem>();

    useEffect(() => {
        const fetchArticle = async () => {
            setArticleLoading(true);
            await axios.get(socialHostName + "news/" + props.articleId)
                .then(r => setArticle(r.data))
                .catch(() => setArticleError(true))
                .finally(() => setArticleLoading(false));
        };

        fetchArticle();
    }, []);

    return (
        <span>
            {articleError ? (
                <span style={{ cursor: "not-allowed", color: "#d32c2d" }}>
                    <FontAwesomeIcon icon={faUnlink} />
                </span>
            ) : (
                <a className="iqx-link" target="_blank" href={article?.link}>
                    {articleLoading ? (
                        <Spinner className="icon float-end" style={{ display: "block", height: "1rem", width: "1rem" }}
                                 animation="border"
                                 variant="primary" />
                    ) : (
                        <FontAwesomeIcon icon={faLink} size="1x"/>
                    )}
                </a>
            )}
        </span>
    );
};

export default ArticleLink;