import React, {useEffect, useState} from "react";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import {FilterNewPreset, newSelectedFilters} from "./ContractList";
import {contractsHostName} from "../../../utils/Configuration";
import axios from "axios";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import ContractFilters from "./ContractFilters";

const EditFilters: React.FC = () => {
    let history = useHistory();
    let location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/contracts/:filterId/edit',
        exact: false,
        strict: false
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [newFilters, setNewFilters] = useState<FilterNewPreset>({} as FilterNewPreset);
    const [selectedFilters, setSelectedFilters] = useState<newSelectedFilters>({} as newSelectedFilters);
    useEffect(() => {
        const fetchFilters = async () => {
            setLoading(true);
            await axios.get(contractsHostName + "mysql/getsavedparams?searchId=" + childMatchedPath?.params.filterId)
                .then(r => setSelectedFilters(r.data))
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchFilters();
    }, []);

    useEffect(() => {
        const fetchSearch = async () => {
            setLoading(true);
            await axios.get(contractsHostName + "mysql/getsavedmetadata?searchId=" + childMatchedPath?.params.filterId)
                .then(r => setNewFilters(r.data))
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchSearch();
    }, []);

    return (
        <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10 }}>
            {loading ? <IqLoadingIcon/> : (
                <div className="mb-5">
                    {newFilters && (<ContractFilters filters={selectedFilters} search={newFilters}/>)}
                </div>
            )}
        </div>
    );
};

export default EditFilters;