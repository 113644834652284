import React, {useContext, useEffect, useState} from 'react';
import './Campaigns.css';
import {Alert, Row, Table} from 'react-bootstrap';
import axios from "axios";
import {socialHostName} from "../../utils/Configuration";
import Campaign from '../../model/campaigns/Campaign';
import {useParams, useRouteMatch} from 'react-router-dom';
import CampaignContext from '../../context/campaign/CampaignContext';
import CampaignAnalytic from '../../model/campaigns/CampaignAnalytic';
import './CampaignView.css';
import IqLoadingIcon from '../common/IqLoadingIcon';
import CampaignLineSeriesChart from './CampaignLineSeriesChart';
import CampaignAnalyticGroupedByPivot from '../../model/campaigns/CampaignAnalyticGroupedByPivot';
import Pagination from '../../utils/PaginationHook';
import {hasAnyRole, Role} from '../../utils/Security';
import CampaignBarSeriesChart from './CampaignBarSeriesChart';


const CampaignView: React.FC = () => {

    let { campaignId } = useParams<any>();
    let { urn } = useParams<any>();
    let match = useRouteMatch();



    const [statistics, setStatistics] = useState<CampaignAnalytic>({} as CampaignAnalytic);
    const [dailyAnalytics, setDailyAnalytics] = useState<CampaignAnalytic[]>([] as CampaignAnalytic[])
    const [analyticsGroupedByCompany, setAnalyticsGroupedByCompany] = useState<CampaignAnalyticGroupedByPivot[]>([] as CampaignAnalyticGroupedByPivot[])
    const [analyticsGroupedByIndustry, setAnalyticsGroupedByIndustry] = useState<CampaignAnalyticGroupedByPivot[]>([] as CampaignAnalyticGroupedByPivot[])
    const [analyticsGroupedByCompanySize, setAnalyticsGroupedByCompanySize] = useState<CampaignAnalyticGroupedByPivot[]>([] as CampaignAnalyticGroupedByPivot[])

    const [loadingStatistics, setLoadingStatistics] = useState<boolean>(true);
    const [loadingDailyAnalytics, setLoadingDailyAnalytics] = useState<boolean>(true);
    const [loadingAnalyticsGroupedByCompany, setLoadingAnalyticsGroupedByCompany] = useState<boolean>(true);
    const [loadingAnalyticsGroupedByIndustry, setLoadingAnalyticsGroupedByIndustry] = useState<boolean>(true);
    const [loadingAnalyticsGroupedByCompanySize, setLoadingAnalyticsGroupedByCompanySize] = useState<boolean>(true);


    const [currentPageGroupedByCompany, setCurrentPageGroupedByCompany] = useState(1);
    const [currentPageGroupedByIndustry, setCurrentPageGroupedByIndustry] = useState(1);
    const [currentPageGroupedByCompanySize, setCurrentPageGroupedByCompanySize] = useState(1);

    const [error, setError] = useState<boolean>(false);
    const { campaigns, setCampaigns, isLoading, setIsLoading } = useContext(CampaignContext);
    const [campaign, setCampaign] = useState<Campaign | null | undefined>(null);

    const rootUrl = (() => {
        let url = [socialHostName, "linkedin/"];
        if (campaignId) {
            url.push([campaignId, "/"].join(""))
        }

        return url.join("");
    })()

    const fetchStatistics = async () => {
        setLoadingStatistics(true);
        await axios.get(rootUrl + urn + "/statistics", {
            params: {
                startDay: campaign?.startDay,
                startMonth: campaign?.startMonth,
                startYear: campaign?.startYear
            }
        })
            .then(r => {
                setStatistics(r.data as CampaignAnalytic);
            })
            .catch(error => console.error(error.message))
            .finally(() => setLoadingStatistics(false));
    };

    const fetchDailyAnalytics = async () => {
        setLoadingDailyAnalytics(true);
        await axios.get(rootUrl + urn + "/analytics/daily", {
            params: {
                startDay: campaign?.startDay,
                startMonth: campaign?.startMonth,
                startYear: campaign?.startYear
            }
        })
            .then(r => {
                setDailyAnalytics(r.data as CampaignAnalytic[]);
            })
            .catch(error => console.error(error.message))
            .finally(() => setLoadingDailyAnalytics(false));
    };

    const fetchAnalyticsGroupedByCompany = async () => {
        setLoadingAnalyticsGroupedByCompany(true);
        await axios.get(rootUrl + urn + "/analytics/company", {
            params: {
                startDay: campaign?.startDay,
                startMonth: campaign?.startMonth,
                startYear: campaign?.startYear
            }
        })
            .then(r => {
                setAnalyticsGroupedByCompany(r.data as CampaignAnalyticGroupedByPivot[]);
            })
            .catch(error => console.error(error.message))
            .finally(() => setLoadingAnalyticsGroupedByCompany(false));
    };

    const fetchAnalyticsGroupedByIndustry = async () => {
        setLoadingAnalyticsGroupedByIndustry(true);
        await axios.get(rootUrl + urn + "/analytics/industry", {
            params: {
                startDay: campaign?.startDay,
                startMonth: campaign?.startMonth,
                startYear: campaign?.startYear
            }
        })
            .then(r => {
                setAnalyticsGroupedByIndustry(r.data as CampaignAnalyticGroupedByPivot[]);
            })
            .catch(error => console.error(error.message))
            .finally(() => setLoadingAnalyticsGroupedByIndustry(false));
    };

    const fetchAnalyticsGroupedByCompanySize = async () => {
        setLoadingAnalyticsGroupedByCompanySize(true);
        await axios.get(rootUrl + urn + "/analytics/companySize", {
            params: {
                startDay: campaign?.startDay,
                startMonth: campaign?.startMonth,
                startYear: campaign?.startYear
            }
        })
            .then(r => {
                setAnalyticsGroupedByCompanySize(r.data as CampaignAnalyticGroupedByPivot[]);
            })
            .catch(error => console.error(error.message))
            .finally(() => setLoadingAnalyticsGroupedByCompanySize(false));
    };

    useEffect(() => {
        if (campaignId) {
            const fetchCampaign = async () => {
                setIsLoading(true);
                await axios.get(rootUrl + urn)
                    .then(r => {
                        setCampaign(r.data as Campaign);
                    })
                    .catch(error => console.error(error.message))
                    .finally(() => setIsLoading(false));
            };

            fetchCampaign();
        }
    }, []);

    useEffect(() => {
        if (!campaignId) {
            if (campaigns.length > 0) {
                setCampaign(campaigns.find((entry) => entry.campaign.id === parseInt(urn)))
                if (campaign) {
                    fetchStatistics();
                    fetchDailyAnalytics();
                    fetchAnalyticsGroupedByCompany();
                    fetchAnalyticsGroupedByIndustry();
                    fetchAnalyticsGroupedByCompanySize()
                }
            }
        } else {
            if (campaign) {
                fetchStatistics();
                fetchDailyAnalytics();
                fetchAnalyticsGroupedByCompany();
                fetchAnalyticsGroupedByIndustry();
                fetchAnalyticsGroupedByCompanySize()
            }
        }
    }, [campaigns, campaign]);

    interface OverallStatistic {
        name: string,
        value: any,
        col: number,
    }

    const OverallStatistic: React.FC<OverallStatistic> = (props: OverallStatistic) => {

        return (
            <div className={"col-12 col-sm-12 col-md-" + props.col + "col-lg-" + props.col + " col-xl-" + props.col}>
                <div className="statistic-card">
                    <div className="title">{props.name}</div>
                    <div className="value">{props.value}</div>
                </div>
            </div>
        )
    };

    interface ChartContainer {
        name: string,
        data: any,
    }

    const ChartContainer: React.FC<ChartContainer> = (props: ChartContainer) => {

        return (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                <div className="statistic-card" style={{ height: 350 }}>
                    <div className="title">
                        {props.name}
                    </div>
                    <CampaignLineSeriesChart data={props.data}
                        colour="#0072F0"
                        title={props.name}
                        yAxisFormat={(value: any) => `${value}`}
                        formatValue={(value: any) => `${value}`}
                    />
                </div>
            </div>
        )
    };

    const BarChartContainer: React.FC<ChartContainer> = (props: ChartContainer) => {

        return (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                <div className="statistic-card" style={{ height: 350 }}>
                    <div className="title">
                        {props.name}
                    </div>
                    <CampaignBarSeriesChart data={props.data}
                        colour="#0072F0"
                        title={props.name}
                        yAxisFormat={(value: any) => `${value}`}
                        formatValue={(value: any) => `${value}`}
                    />
                </div>
            </div>
        )
    };

    const hasCampaignsPermission = () => {
        return hasAnyRole([Role.CAMPAIGN_VIEWER]);
    };

    return (
        hasCampaignsPermission() ?
            isLoading ? <IqLoadingIcon /> :
                <>
                    {!campaignId &&
                        <Row className="pt-3">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <span id="campaign-title">Analytics of {campaign?.campaign.name}</span>
                            </div>
                        </Row>
                    }
                    <Row className="pt-3" style={{ marginTop: 15 }}>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <span className="campaign-sub-title">Overall Statistics</span>
                        </div>
                    </Row>
                    {loadingStatistics ? <IqLoadingIcon /> : <>
                        <Row className="pt-3" style={{ marginTop: 10 }}>
                            <OverallStatistic name="Impressions" value={statistics.analytic?.impressions} col={3} />
                            <OverallStatistic name="Clicks" value={statistics.analytic?.clicks} col={3} />
                            <OverallStatistic name="CTR" value={(statistics.ctr * 100).toFixed(2) + '%'} col={3} />
                            <OverallStatistic name="Reach" value={statistics.analytic?.approximateUniqueImpressions} col={3} />
                        </Row>
                        <Row className="pt-3" style={{ marginTop: 10 }}>
                            <OverallStatistic name="Leads" value={statistics.analytic?.oneClickLeads} col={2} />
                            <OverallStatistic name="Engagement Rate" value={(statistics.engagementRate * 100).toFixed(2) + '%'} col={2} />
                            <OverallStatistic name="Follows" value={statistics.analytic?.follows} col={2} />
                            <OverallStatistic name="Total Engagement" value={statistics.analytic?.totalEngagements} col={2} />
                            <OverallStatistic name="Sends" value={statistics.analytic?.sends} col={2} />
                            <OverallStatistic name="Opens" value={statistics.analytic?.opens} col={2} />
                        </Row>
                    </>}
                    <Row className="pt-3" style={{ marginTop: 15 }}>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <span className="campaign-sub-title">Daily Analytics</span>
                        </div>
                    </Row>
                    {loadingDailyAnalytics ? <IqLoadingIcon /> :
                        dailyAnalytics.length > 0 &&
                        <>
                            <Row className="pt-3" style={{ marginTop: 10 }}>
                                <ChartContainer data={dailyAnalytics.map(entry => {
                                    return {
                                        y: entry.analytic.impressions,
                                        x: new Date(
                                            entry.analytic.dateRange.start.year,
                                            entry.analytic.dateRange.start.month - 1,
                                            entry.analytic.dateRange.start.day)
                                    }
                                })} name={"Impressions"} />

                                <ChartContainer data={dailyAnalytics.map(entry => {
                                    return {
                                        y: entry.analytic.clicks,
                                        x: new Date(
                                            entry.analytic.dateRange.start.year,
                                            entry.analytic.dateRange.start.month - 1,
                                            entry.analytic.dateRange.start.day)
                                    }
                                })} name={"Clicks"} />
                            </Row>
                            <Row className="pt-3" style={{ marginTop: 10 }}>
                                <ChartContainer data={dailyAnalytics.map(entry => {
                                    return {
                                        y: Number((entry.ctr * 100).toFixed(2)),
                                        x: new Date(
                                            entry.analytic.dateRange.start.year,
                                            entry.analytic.dateRange.start.month - 1,
                                            entry.analytic.dateRange.start.day)
                                    }
                                })} name={"CTR (%)"} />
                                <ChartContainer data={dailyAnalytics.map(entry => {
                                    return {
                                        y: entry.analytic.approximateUniqueImpressions,
                                        x: new Date(
                                            entry.analytic.dateRange.start.year,
                                            entry.analytic.dateRange.start.month - 1,
                                            entry.analytic.dateRange.start.day)
                                    }
                                })} name={"Reach"} />

                            </Row>
                            <Row className="pt-3" style={{ marginTop: 10 }}>
                                <ChartContainer data={dailyAnalytics.map(entry => {
                                    return {
                                        y: Number((entry.engagementRate * 100).toFixed(2)),
                                        x: new Date(
                                            entry.analytic.dateRange.start.year,
                                            entry.analytic.dateRange.start.month - 1,
                                            entry.analytic.dateRange.start.day)
                                    }
                                })} name={"Engagement Rate (%)"} />
                                <BarChartContainer data={dailyAnalytics.map(entry => {
                                    return {
                                        y: entry.analytic.oneClickLeads,
                                        x: new Date(
                                            entry.analytic.dateRange.start.year,
                                            entry.analytic.dateRange.start.month - 1,
                                            entry.analytic.dateRange.start.day)?.getTime()
                                    }
                                })} name={"Leads"} />
                            </Row>
                            <Row className="pt-3" style={{ marginTop: 10 }}>
                                <ChartContainer data={dailyAnalytics.map(entry => {
                                    return {
                                        y: entry.analytic.follows,
                                        x: new Date(
                                            entry.analytic.dateRange.start.year,
                                            entry.analytic.dateRange.start.month - 1,
                                            entry.analytic.dateRange.start.day)
                                    }
                                })} name={"Follows"} />
                                <ChartContainer data={dailyAnalytics.map(entry => {
                                    return {
                                        y: entry.analytic.totalEngagements,
                                        x: new Date(
                                            entry.analytic.dateRange.start.year,
                                            entry.analytic.dateRange.start.month - 1,
                                            entry.analytic.dateRange.start.day)
                                    }
                                })} name={"Total Engagements"} />
                            </Row>
                            <Row className="pt-3" style={{ marginTop: 10 }}>
                                <ChartContainer data={dailyAnalytics.map(entry => {
                                    return {
                                        y: entry.analytic.sends,
                                        x: new Date(
                                            entry.analytic.dateRange.start.year,
                                            entry.analytic.dateRange.start.month - 1,
                                            entry.analytic.dateRange.start.day)
                                    }
                                })} name={"Sends"} />
                                <ChartContainer data={dailyAnalytics.map(entry => {
                                    return {
                                        y: entry.analytic.opens,
                                        x: new Date(
                                            entry.analytic.dateRange.start.year,
                                            entry.analytic.dateRange.start.month - 1,
                                            entry.analytic.dateRange.start.day)
                                    }
                                })} name={"Opens"} />
                            </Row>
                        </>
                    }
                    <Row className="pt-3" style={{ marginTop: 15 }}>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <span className="campaign-sub-title">Analytics Grouped by Company</span>
                        </div>
                    </Row>
                    <Row className="pt-3" style={{ marginTop: 10, marginLeft: 0, marginRight: 0 }}>
                        {loadingAnalyticsGroupedByCompany ? <IqLoadingIcon /> :
                            <>
                                {error ? (
                                    <Alert variant="danger">
                                        {"error"}
                                    </Alert>
                                ) : (
                                    <>
                                        <Table style={{ backgroundColor: "white", width: "100%", marginLeft: "auto", marginRight: "auto" }} striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Company Name</th>
                                                    <th>Impressions</th>
                                                    <th>Clicks</th>
                                                    <th>CTR</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {analyticsGroupedByCompany.slice((currentPageGroupedByCompany - 1) * 10, (currentPageGroupedByCompany) * 10).map((entry, index) => (
                                                    <tr key={index}>
                                                        <td className="pt-3">{entry.pivotValue}</td>
                                                        <td className="pt-3">{entry.analytic.impressions}</td>
                                                        <td className="pt-3">{entry.analytic.clicks}</td>
                                                        <td className="pt-3">{(entry.ctr * 100).toFixed(2) + '%'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <Pagination
                                                totalRecords={analyticsGroupedByCompany.length}
                                                pageLimit={10}
                                                pageRangeDisplayed={1}
                                                onChangePage={setCurrentPageGroupedByCompany}
                                            />
                                        </div>
                                    </>
                                )}

                            </>
                        }

                    </Row>
                    <Row className="pt-3" style={{ marginTop: 15 }}>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <span className="campaign-sub-title">Analytics Grouped by Industry</span>
                        </div>
                    </Row>
                    <Row className="pt-3" style={{ marginTop: 10, marginLeft: 0, marginRight: 0 }}>
                        {loadingAnalyticsGroupedByIndustry ? <IqLoadingIcon /> :
                            <>
                                {error ? (
                                    <Alert variant="danger">
                                        {"error"}
                                    </Alert>
                                ) : (
                                    <>
                                        <Table style={{ backgroundColor: "white", width: "100%", marginLeft: "auto", marginRight: "auto" }} striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Industry Name</th>
                                                    <th>Impressions</th>
                                                    <th>Clicks</th>
                                                    <th>CTR</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {analyticsGroupedByIndustry.slice((currentPageGroupedByIndustry - 1) * 10, (currentPageGroupedByIndustry) * 10).map((entry, index) => (
                                                    <tr key={index}>
                                                        <td className="pt-3">{entry.pivotValue}</td>
                                                        <td className="pt-3">{entry.analytic.impressions}</td>
                                                        <td className="pt-3">{entry.analytic.clicks}</td>
                                                        <td className="pt-3">{(entry.ctr * 100).toFixed(2) + '%'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <Pagination
                                                totalRecords={analyticsGroupedByIndustry.length}
                                                pageLimit={10}
                                                pageRangeDisplayed={1}
                                                onChangePage={setCurrentPageGroupedByIndustry}
                                            />
                                        </div>
                                    </>
                                )}

                            </>
                        }

                    </Row>
                    <Row className="pt-3" style={{ marginTop: 15 }}>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <span className="campaign-sub-title">Analytics Grouped by Company Size</span>
                        </div>
                    </Row>
                    <Row className="pt-3" style={{ marginTop: 10, marginLeft: 0, marginRight: 0 }}>
                        {loadingAnalyticsGroupedByCompanySize ? <IqLoadingIcon /> :
                            <>
                                {error ? (
                                    <Alert variant="danger">
                                        {"error"}
                                    </Alert>
                                ) : (
                                    <>
                                        <Table style={{ backgroundColor: "white", width: "100%", marginLeft: "auto", marginRight: "auto" }} striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Company Size</th>
                                                    <th>Impressions</th>
                                                    <th>Clicks</th>
                                                    <th>CTR</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {analyticsGroupedByCompanySize.slice((currentPageGroupedByCompanySize - 1) * 10, (currentPageGroupedByCompanySize) * 10).map((entry, index) => (
                                                    <tr key={index}>
                                                        <td className="pt-3">{entry.pivotValue}</td>
                                                        <td className="pt-3">{entry.analytic.impressions}</td>
                                                        <td className="pt-3">{entry.analytic.clicks}</td>
                                                        <td className="pt-3">{(entry.ctr * 100).toFixed(2) + '%'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <Pagination
                                                totalRecords={analyticsGroupedByCompanySize.length}
                                                pageLimit={10}
                                                pageRangeDisplayed={1}
                                                onChangePage={setCurrentPageGroupedByCompanySize}
                                            />
                                        </div>
                                    </>
                                )}

                            </>
                        }
                    </Row>
                </>
            :
            <Alert variant="danger">
                This feature is restricted to specific accounts as it is under development.
            </Alert>
    );
};

export default CampaignView;