import {faBan, faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Typeahead} from "react-bootstrap-typeahead";
import {organisationHostname} from '../../utils/Configuration';
import IqLoadingIcon from "../common/IqLoadingIcon";
import './OrganisationTechStack.css';

enum Sort {
    VENDOR = "vendor",
    PRODUCT = "product",
    CATEGORY = "category",
    INTENSITY = "intensity",
    DATE_FIRST_VERIFIED = "dateFirstVerified",
    DATE_LAST_VERIFIED = "dateLastVerified"
}

interface TechStackFilterOptions {
    preferredCategories: string[],
    preferredVendors: string[],
    categories: string[],
    vendors: string[]
}

interface Props {
    editFilters: boolean
    setEditFilters: React.Dispatch<React.SetStateAction<boolean>>
}
const OrganisationTechStackFilters: React.FC<Props> = (props: Props) => {

    const [loadingTechStackFilters, setLoadingTechStackFilters] = useState<boolean>(false);
    const [techStackFilterOptions, setTechStackFilterOptions] = useState<TechStackFilterOptions>({} as TechStackFilterOptions);


    const handleCategoriesChange = (categories: any) => {
        setTechStackFilterOptions({
            ...techStackFilterOptions,
            ["preferredCategories"]: categories
        })
    };
    const handleVendorsChange = (vendors: any) => {
        setTechStackFilterOptions({
            ...techStackFilterOptions,
            ["preferredVendors"]: vendors
        })
    };

    const onFiltersSave = () => {

        axios.post(organisationHostname + 'organisation/tech-stack-filter-options', {
            "preferredCategories": techStackFilterOptions.preferredCategories,
            "preferredVendors": techStackFilterOptions.preferredVendors
        }, {
            headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
            if (response.status === 200) {
                props.setEditFilters(false);
            }
        }).catch(error => console.log(error.message));
    };

    const fetchTechStackFilterOptions = async () => {
        await axios.get(organisationHostname + "organisation/tech-stack-filter-options")
            .then((response) => {
                setTechStackFilterOptions(response.data);
                setLoadingTechStackFilters(false);
            })
            .catch(error => console.log(error.message));

    };

    useEffect(() => {
        if (props.editFilters) {
            setLoadingTechStackFilters(true);
            fetchTechStackFilterOptions();
        }
    }, [props.editFilters]);

    return (
        <>
            {loadingTechStackFilters
                ?
                <Row className="filter-box"
                    style={{ marginLeft: 0, marginRight: 0, marginTop: 15, paddingTop: 15, paddingBottom: 15 }}>
                    <IqLoadingIcon />
                </Row>
                :
                <Row className="filter-box"
                    style={{ marginLeft: 0, marginRight: 0, marginTop: 15, paddingTop: 15, paddingBottom: 15 }}>

                    <Col sm="12" md="6" lg="6">
                        <div className="filter-card">
                            <h4>Vendors</h4>
                            <Typeahead
                                clearButton
                                selected={techStackFilterOptions.preferredVendors}
                                id="selection-authorities"
                                multiple
                                options={techStackFilterOptions.vendors}
                                onChange={handleVendorsChange}
                                placeholder="Choose vendors..."
                            />
                        </div>
                    </Col>
                    <Col sm="12" md="6" lg="6">
                        <div className="filter-card">
                            <h4>Categories</h4>
                            <Typeahead
                                clearButton
                                selected={techStackFilterOptions.preferredCategories}
                                id="selection-authorities"
                                multiple
                                options={techStackFilterOptions.categories}
                                onChange={handleCategoriesChange}
                                placeholder="Choose category... "
                            />
                        </div>
                    </Col>
                    <div style={{ marginLeft: "auto", marginRight: "auto", paddingTop: 15 }}>
                        <button onClick={() => {
                            props.setEditFilters(false)
                        }} type="button" className="filter-button"><span
                            className="text">Cancel</span><FontAwesomeIcon className="icon" icon={faBan}
                                color="#2F6FC3" size="1x" /></button>
                        <button onClick={onFiltersSave} type="button" className="filter-button"><span
                            className="text">Save filters</span><FontAwesomeIcon className="icon" icon={faSave}
                                color="#2F6FC3" size="1x" />
                        </button>
                    </div>

                </Row>
            }
        </>
    );
}

export default OrganisationTechStackFilters;