import React from "react";
import {Spinner} from "react-bootstrap";

const IqLoadingIcon: React.FC = () => {
    return (
        <Spinner style={{display: "block", marginLeft: "auto", marginRight: "auto", height: "2rem", width: "2rem"}}
                 animation="border"
                 variant="primary"/>
    );
};

export default IqLoadingIcon;
