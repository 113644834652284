import React, {useEffect, useState} from "react";
import axios from "axios";
import {frontEndHostName} from "../../utils/Configuration";
import translate from "../../i18n/translate";
import {Image, Spinner} from "react-bootstrap";
import IqLoadingIcon from "../common/IqLoadingIcon";
import './OnboardingThankyou.css'
import Organisation from "../../model/organisation/Organisation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport} from "@fortawesome/free-solid-svg-icons";
import {faSearchengin} from "@fortawesome/free-brands-svg-icons";

interface Props {
    continue: Function
    role: string | undefined
    roleFocus: string  | undefined
    businessFocus: string  | undefined
    account: string | undefined
}

const OnboardingThankYou: React.FC<Props> = (props: Props) => {



    const [error, setError] = useState<boolean>(false);
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [saving, setSaving] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchFollowedOrganisations = async () => {
            setLoading(true);
            await axios.get(frontEndHostName + "followed/minified")
                .then(r => {
                        setOrganisations(r.data)
                        setLoading(false)
                    })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchFollowedOrganisations();
    }, []);



    const CompanyLogo: React.FC<Organisation> = (org: Organisation) => {
        const [src, setSrc] = useState<string>(org.imageUrl ? org.imageUrl : "./icons_company.svg");

        return (
            <Image title={org.companyName}
                   onDragStart={e => e.stopPropagation()}
                   style={{
                       height: "50px",
                       width: "50px",
                       objectFit: "cover",
                       marginRight: 15,
                       marginBottom: 15,
                       border: "2px solid #5FD1F9"
                   }}
                   src={src}
                   onError={() => setSrc("/icons_company.svg")}
                   roundedCircle/>
        )
    };

    const capitaliseFirstWord = (phrase: string | undefined) => {
      return  phrase?.toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const displayUserSettingsRole = (role: string | undefined) => {
        if (role === 'BOTH'){
            return "Sales & Marketing"
        }
        return capitaliseFirstWord(role);
    }

    const displayUserSettingsBusinessTarget = (role: string | undefined) => {
        if (role === 'BOTH'){
            return "Channel & End Users"
        }
        return capitaliseFirstWord(role);
    }

    const displayUserSettingsRoleFocused = (role: string | undefined) => {
        if (role === 'BOTH'){
            return "Dedicated Accounts & New Business"
        }
        return capitaliseFirstWord(role);
    }

    const displayUserSettingsAccount = (role: string | undefined) => {
        if (role === 'BOTH'){
            return "Sector & Geography"
        }
        return capitaliseFirstWord(role);
    }

    return (
        <div>
                <div>
                    <div className="row mb-3">
                        <div className="col-xs-4">
                            <span className="welcome">Thank you for creating your account!</span>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='container-row'>
                            <div className='container-column'>
                                <a className='title'>User Settings Summary:</a>
                                <div style={{padding: "5px"}}>
                                <a style={{fontWeight: "bold"}}>Role: </a>
                                {displayUserSettingsRole(props.role)}
                                </div>
                                <div style={{padding: "5px"}}>
                                    <a style={{fontWeight: "bold"}}>Role Focused: </a>
                                    {displayUserSettingsRoleFocused(props.roleFocus)}
                                </div>
                                <div style={{padding: "5px"}}>
                                    <a style={{fontWeight: "bold"}}>Account Target: </a>
                                    {displayUserSettingsAccount(props.account)}
                                </div>
                                <div style={{padding: "5px"}}>
                                    <a style={{fontWeight: "bold"}}>Business Target: </a>
                                    {displayUserSettingsBusinessTarget(props.businessFocus)}
                                </div>
                            </div>

                            <div className='container-column'>
                                <a className='title'>Followed Companies:</a>
                                <div style={{margin: "auto"}}>
                                    {loading ?
                                        <IqLoadingIcon/>
                                        :
                                        <div style={{overflowY: "scroll", height: "180px"}}>
                                    {organisations.map((org: Organisation) => (
                                        <div key={org.id} className="mb-2" style={{paddingTop: "5px"}}>
                                        <a onMouseDown={ e => e.stopPropagation() }  href={`/organisation/${org.id}`}>
                                        <CompanyLogo {...org}/>
                                        <span className="iqx-link">
                                    {org.companyName}
                                        </span>
                                        </a>
                                        </div>
                                        ))}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='container-row'>
                            <div className='container-column'>
                              <a className='title'>  Create first Advance Query</a>
                                <div style={{margin: "auto"}}>
                                    <a href={"/advanced-search"}>
                              <FontAwesomeIcon icon={faSearchengin} color="#367CDB" size="5x"/>
                                    </a>
                                </div>
                            </div>
                            <div className='container-column'>
                               <a className='title'>Upload a CSV to create your first list</a>
                                <div className='container-column'>
                                    <div style={{margin: "auto"}}>
                                        <a href={"/upload"}>
                                            <FontAwesomeIcon icon={faFileExport} color="#367CDB" size="5x"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {saving ? (
                        <button className="iq-button iq-button-primary pull-right"
                                disabled={true} onClick={() => props.continue()}>
                            <span className="me-2">
                                {translate("onboarding.continue")}
                            </span>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </button>
                    ) : (
                        <button className="iq-button iq-button-primary pull-right"
                                onClick={() => props.continue()}>
                            <span>
                                {translate("onboarding.continue")}
                            </span>
                        </button>
                    )}
                </div>
        </div>
    );
};

export default OnboardingThankYou;