import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {frontEndHostName} from "../../../utils/Configuration";
import {Alert, Col, Form, Image, Row} from "react-bootstrap";
import Organisation from "../../../model/organisation/Organisation";
import DashboardItemContext from "../../../context/dashboard/DashboardItemContext";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import translate from "../../../i18n/translate";

const DashboardFollowed: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const {maxHeight, setMaxHeight, width, setWidth} = useContext(DashboardItemContext);

    const [showNames, setShowNames] = useState<boolean>(false);
    const toggleShowNames = () => {
        setShowNames(!showNames);
    };

    useEffect(() => {
        const fetchFollowedOrganisations = async () => {
            setLoading(true);

            await axios.get(frontEndHostName + "followed")
                .then(r => setOrganisations(r.data))
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchFollowedOrganisations();
    }, []);

    const getHeight = () => {
        if (width < 3) {
            return maxHeight - 130;
        }

        return maxHeight - 90;
    };

    const CompanyLogo: React.FC<Organisation> = (org: Organisation) => {
        const [src, setSrc] = useState<string>(org.imageUrl ? org.imageUrl : "./icons_company.svg");

        return (
            <Image title={org.companyName}
                   onDragStart={e => e.stopPropagation()}
                   style={{
                       height: "50px",
                       width: "50px",
                       objectFit: "cover",
                       marginRight: 15,
                       marginBottom: 15,
                       border: "2px solid #5FD1F9"
                   }}
                   src={src}
                   onError={() => setSrc("/icons_company.svg")}
                   roundedCircle/>
        )
    };

    const getClass = () => {
        let className = "followed-company-name pb-1";

        if (width < 4) {
            className += " sm";
        }

        return className;
    };

    return (
        <div>
            <Row className="pb-2">
                <Col>
                    <div className="organisation-card-label">
                        {translate("dashboard.followedorganisations.title")}
                    </div>
                    <span className="pull-right nonIqbladeDraggableWidget">
                        <Form.Check type="checkbox"
                                    label={translate("dashboard.followedorganisations.shownames")}
                                    checked={showNames}
                                    onChange={() => toggleShowNames()}/>
                    </span>
                </Col>
            </Row>
            {error ? (
                <Alert variant="danger">
                    {translate("errors.loading")}
                </Alert>
            ) : (
                <div>
                    {loading ? <IqLoadingIcon/> : (
                        <div style={{maxHeight: getHeight(), overflowY: "scroll"}}>
                            {showNames ? (
                                <div>
                                    {organisations.map((org: Organisation) => (
                                        <div key={org.id} className="mb-2 nonIqbladeDraggableWidget">
                                            <a onMouseDown={ e => e.stopPropagation() } className={getClass()} href={`/organisation/${org.id}`}>
                                                <CompanyLogo {...org}/>
                                                <span className="iqx-link">
                                                    {org.companyName}
                                                </span>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div>
                                    {organisations.map((org: Organisation) => (
                                        <a onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()} key={org.id} className="followed-company-name nonIqbladeDraggableWidget" style={{zIndex: 7}}
                                           href={`/organisation/${org.id}`}>
                                            <CompanyLogo {...org}/>
                                        </a>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default DashboardFollowed;
