import React, {useContext, useEffect, useState} from "react";
import UserOrganisationContext from "../../../context/organisation/OrganisationContext";
import axios from "axios";
import {Alert} from "react-bootstrap";
import "./OrganisationPeople.css";
import OfficerAppointment from "../../../model/organisation/officer/OfficerAppointment";
import ActiveOfficer from "./ActiveOfficer";
import {organisationHostname} from "../../../utils/Configuration";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import translate from "../../../i18n/translate";
import OrganisationOfficer from "../../../model/organisation/officer/OrganisationOfficer";

const OrganisationOfficers: React.FC = () => {

    const {organisation, setOrganisation} = useContext(UserOrganisationContext);

    const [appointments, setAppointments] = useState<OfficerAppointment[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
                await axios.get(organisationHostname + "organisation/" + organisation.id + "/officers")
                    .then(r => {
                        setAppointments(r.data)
                    })
                    .catch(() => setError(true))
                    .finally(() => setLoading(false));
        };

        fetchData();
    }, []);


    useEffect(() => {
        const updateOfficersWithoutProfileImg  = async (officer: OrganisationOfficer) => {
            await axios.put(organisationHostname + "officer/gather-info-from-twitter", officer)
                .then(r => console.log(r.data));
        }
        let officersWithoutProfileImg = appointments.filter(value => value.officer.twitter !== null && (value.officer.profileImageURL === null || value.officer.profileImageURL === ''));
        officersWithoutProfileImg.map(value => updateOfficersWithoutProfileImg(value.officer));

    },[appointments])


    return (
        <div style={{paddingTop: 10, padding: 15, marginTop: 10, width: "100%"}}>
            {loading ? <IqLoadingIcon/> : (
                <div style={{
                    paddingTop: 10,
                    backgroundColor: "white",
                    borderRadius: "0.45rem",
                    padding: 15,
                    marginTop: 10
                }}>
                    {error  ? (
                        <Alert variant="danger">
                            {translate("errors.loading")}
                        </Alert>

                    ) : (
                        <div>
                            <div style={{height: "34px", color: "#212121", fontSize: "28px", fontWeight: 100}}>
                                {appointments.length}&nbsp;Officers
                            </div>
                            <div className="officers-containter mt-2">
                                {
                                    appointments.map(appointment => <ActiveOfficer key={appointment.id}
                                                                                   appointment={appointment}/>)
                                }
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
};

export default OrganisationOfficers;
