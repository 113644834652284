import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';

interface ActionFieldButtonsI {
    onCancel: () => void,
    onApply: () => void
}
const ActionFieldButtons: React.FC<ActionFieldButtonsI> = (props: ActionFieldButtonsI) => {
    return (
        <div>
            <div onClick={() => props.onCancel()} className="remove">
                <FontAwesomeIcon icon={faTimes} color="white" size="1x" />
            </div>
            <div onClick={() => props.onApply()} className="apply">
                <i className="fa fa-check"></i>
            </div>
        </div>
    )
}

export default ActionFieldButtons;