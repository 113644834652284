import React, {useContext, useEffect, useState} from "react";
import {organisationHostname} from "../../utils/Configuration";
import axios from "axios";
import "./RenewalsDashboard.css";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {Alert, Table} from "react-bootstrap";
import {format} from "d3-format";
import RenewalsContext from "../../context/renewals/RenewalsContext";
import {useHistory} from "react-router-dom";
import {RenewalsFilters} from "../../model/renewals/RenewalsFilters";
import RenewalsByVendor from "./RenewalsByVendor";

interface TopEndUser {
    endUserCompany: string,
    value: number
}

interface TopByValue {
    vendorName: string,
    endUserCompany: string,
    value: number
}

interface UpcomingRenewal {
    itemCount: number
    value: number,
}

const RenewalsDashboard: React.FC = () => {

    let history = useHistory();
    const {setFilters, setRenewals, setPage, setHideExpired} = useContext(RenewalsContext);

    const [loadingEndUsers, setLoadingEndUsers] = useState<boolean>(false);
    const [topEndUsers, setTopEndUsers] = useState<TopEndUser[]>([]);

    const [loadingValue, setLoadingValue] = useState<boolean>(false);
    const [topByValue, setTopByValue] = useState<TopByValue[]>([]);

    const [loadingUpcoming, setLoadingUpcoming] = useState<boolean>(false);
    const [upcoming, setUpcoming] = useState<UpcomingRenewal[]>([]);

    const getUrl = (endpoint: string) => {
        return `${organisationHostname}renewals/dashboard/${endpoint}`
    }

    useEffect(() => {
        const fetchTopValue = async () => {
            setLoadingValue(true);
            await axios.get(getUrl("value"))
                .then(r => setTopByValue(r.data))
                .finally(() => setLoadingValue(false));
        }

        const fetchEndUsers = async () => {
            setLoadingEndUsers(true);
            await axios.get(getUrl("end-user"))
                .then(r => setTopEndUsers(r.data))
                .finally(() => setLoadingEndUsers(false));
        }

        const fetchUpcomingRenewals = async () => {
            setLoadingUpcoming(true);
            await axios.get(getUrl("upcoming"))
                .then(r => setUpcoming(r.data))
                .finally(() => setLoadingUpcoming(false));
        }

        fetchEndUsers();
        fetchTopValue();
        fetchUpcomingRenewals();
    }, []);

    const getUpcomingRenewalLabel = (index: number) => {
        if (index === 0) {
            return "0 - 30 days";
        }
        else if (index === 1) {
            return "31 - 60 days";
        }
        else if (index === 2) {
            return "61 - 90 days";
        }
    }

    const setDateFilters = (index: number) => {
        const startOffset = getStartOffset(index);
        const endOffset = 30 + (index * 30);

        let startDate = new Date();
        let endDate = new Date();
        startDate.setDate(startDate.getDate() + startOffset);
        endDate.setDate(startDate.getDate() + endOffset);

        let filters: RenewalsFilters = {
            endDateFrom: getDateString(startDate),
            endDateTo: getDateString(endDate)
        }

        setHideExpired(false);
        setFilters(filters);
        setRenewals([]);
        setPage(1);
        history.push(`/renewals?expiring=${index}`);
    }

    const getDateString = (date: Date) => {
        let string = date.toISOString();
        return string.substr(0, string.indexOf("T"));
    }

    const getStartOffset = (index: number) => {
        if (index === 1) {
            return 31;
        }
        else if (index === 2) {
            return 61;
        }

        return 0;
    }

    return (
        <div className="renewals-dashboard-container">
            <div className="renewals-dashboard-item">
                <RenewalsByVendor />
            </div>
            <div className="renewals-dashboard-item">
                <div className="organisation-card">
                    <div className="organisation-card-label mb-3">Top 10 Active End Users</div>
                    {loadingEndUsers ? <IqLoadingIcon /> : (
                        <div>
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>End User</th>
                                        <th className="text-end">Value (GBP)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {topEndUsers.map((endUser: TopEndUser, index: number) => (
                                        <tr key={index}>
                                            <td className="text-end">{index + 1}</td>
                                            <td>{endUser.endUserCompany.toUpperCase()}</td>
                                            <td className="text-end">{format(",.2f")(endUser.value)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </div>
            </div>
            <div className="renewals-dashboard-item">
                <div className="organisation-card">
                    <div className="organisation-card-label mb-3">Upcoming Renewals</div>
                    {loadingUpcoming ? <IqLoadingIcon /> : (
                        <div>
                            {!upcoming ?
                                <Alert variant="info">
                                    No upcoming renewals
                                </Alert> :
                                (
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th className="text-end">Count</th>
                                                <th className="text-end">Value (GBP)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {upcoming.map((data: UpcomingRenewal, index: number) => (
                                                <tr key={index}>
                                                    <td>{getUpcomingRenewalLabel(index)}</td>
                                                    <td className="text-end">
                                                        <a className="iqx-link" onClick={() => setDateFilters(index)}>{data.itemCount}</a>
                                                    </td>
                                                    <td className="text-end">{format(",.2f")(data.value)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )
                            }
                        </div>
                    )}
                </div>
            </div>
            <div className="renewals-dashboard-item">
                <div className="organisation-card">
                    <div className="organisation-card-label mb-3">Top 10 Renewals by Value</div>
                    {loadingValue ? <IqLoadingIcon /> : (
                        <div>
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>Vendor</th>
                                        <th>End User</th>
                                        <th className="text-end">Value (GBP)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {topByValue.map((item: TopByValue, index: number) => (
                                        <tr key={index}>
                                            <td className="text-end">{index + 1}</td>
                                            <td>{item.vendorName}</td>
                                            <td>{item.endUserCompany.toUpperCase()}</td>
                                            <td className="text-end">{format(",.2f")(item.value)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default RenewalsDashboard;