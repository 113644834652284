import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useContext, useEffect, useState} from 'react';
import {Utils as QbUtils} from 'react-awesome-query-builder-opti';
import AdvancedSearchContext from '../../../context/advanced-search/AdvancedSearchContext';
import ActionFieldButtons from './ActionFieldButtons';
import {Select} from "antd";
import IMultiSelectField from './IMultiSelectField';

const Option = Select.Option;



const MultiSelectField: React.FC<IMultiSelectField> = (props: IMultiSelectField) => {
    const { tree, setTree, filters, config } = useContext(AdvancedSearchContext);
    const [selected, setSelected] = useState(props.selected);
    const [filterId, setFilterId] = useState(QbUtils.uuid());

    const onChange = (val: string[]) => {
        setSelected(val);
    }

    const onCancel = () => {
        props.setFilterState("")
    }

    const onRemove = () => {
        let objectTree = QbUtils.getTree(tree);
        delete objectTree!.children1![filterId];
        setTree(QbUtils.checkTree(QbUtils.loadTree(objectTree), config));
        reset()
    }

    const reset = () => {
        props.setFilterState("")
        setSelected(props.selected)
    }

    const onApply = () => {
        let objectTree = QbUtils.getTree(tree);
        if (!objectTree.hasOwnProperty('children1')) {
            objectTree.children1 = {}
        }
        objectTree!.children1![filterId] = {
            "type": "rule",
            "properties": {
                "field": props.graphField,
                "operator": "select_any_in",
                "value": [
                    selected
                ],
                "valueSrc": [
                    "value"
                ],
                "valueType": [
                    "multiselect"
                ]
            }
        }
        setTree(QbUtils.checkTree(QbUtils.loadTree(objectTree), config));
        props.setFilterState("active")
    }

    useEffect(() => {
        let objectTree = QbUtils.getTree(tree);
        if (objectTree.hasOwnProperty('children1')) {
            if (objectTree!.children1!.hasOwnProperty(filterId)) {
                let properties = objectTree!.children1![filterId]!.properties;
                if ((properties as any).value[0] != null && (properties as any).field === props.graphField) {
                    setSelected((properties as any).value[0])
                    props.setFilterState("active");
                } else {
                    reset()
                }
            } else {
                reset()
            }
        }
    }, [tree])

    return (
        <div>
            {props.filterState === "editable" &&
                <div>
                    <Select
                        disabled={false}
                        mode={"multiple"}
                        style={{
                            minWidth: 200,
                            // width: 200,
                        }}
                        dropdownStyle={{
                            width: 250,
                        }}
                        // onDropdownVisibleChange={this.handleDropdownOpen}
                        key={"widget-multiselect"}
                        placeholder={"Input your choice"}
                        size={"small"}
                        value={selected}
                        onChange={onChange}
                        filterOption={true}
                    // maxTagCount={1}
                    >{props.options.map(o => {
                        return (<Option key={o} value={o}>{o}</Option>);
                    })}
                    </Select>
                    <ActionFieldButtons onCancel={onCancel} onApply={onApply} />
                </div>
            }
            {props.filterState === "active" &&
                <div>
                    <div>
                        {selected.join(", ").substring(0, 28) + "..."}
                    </div>

                    <div onClick={() => onRemove()} className="remove">
                        <FontAwesomeIcon icon={faTimes} color="white" size="1x" />
                    </div>
                </div>
            }
            {props.filterState === "" &&
                <div>
                    Any
                </div>
            }
        </div>
    );
}

export default MultiSelectField;