import React, {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {frontEndHostName, socialHostName} from "../../../utils/Configuration";
import DashboardItemContext from "../../../context/dashboard/DashboardItemContext";
import {Alert, Col, Row} from "react-bootstrap";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {Tweet} from "react-twitter-widgets";
import translate from "../../../i18n/translate";
import Select from "react-select";
import List from "../../../model/list/List";
import DashboardContext from "../../../context/dashboard/DashboardContext";
import DashboardKeyContext from "../../../context/dashboard/DashboardKeyContext";


interface TweetDTO {
    tweetId: string,
    userId: string,
    createdAt: string
}

interface SelectOption {
    value: string,
    label: string
}

const DashboardTwitter: React.FC = () => {

    const size: number = 5;
    const {maxHeight, setMaxHeight} = useContext(DashboardItemContext);
    const {layout} = useContext(DashboardContext);
    const {widgetId} = useContext(DashboardKeyContext)
    const getHeight = () => {
        return maxHeight - 120;
    };

    const [tweets, setTweets] = useState<TweetDTO[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingMore, setLoadingMore] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [dashboardId] = useState<number>(layout[0].dashboardId)

    const typeOptions: SelectOption[] = [
        {
            value: "all",
            label: translate("dashboard.twitter.all")
        },
        {
            value: "organisation",
            label: translate("dashboard.twitter.organisation")
        },
        {
            value: "officer",
            label: translate("dashboard.twitter.officer")
        }
    ];
    const sourceInitialOptions: SelectOption[] = [
        {
            value: "followed",
            label: translate("dashboard.twitter.followed")
        }
    ]

    const [sourceOptions, setSourceOptions] = useState<SelectOption[]>(sourceInitialOptions);
    const [selectedSource, setSelectedSource] = useState<SelectOption>(sourceInitialOptions[0]);
    const [selectedType, setSelectedType] = useState<SelectOption>(typeOptions[0]);
    const [isPreferenceRetrieved, setIsPreferenceRetrieved] = useState<boolean>(false);
    const [lists, setLists] = useState<List[]>([]);

    const prevSelection = useRef({selectedSource, selectedType}).current;

    const scroller = useRef<any>();

    useEffect(() => {
        const getWidgetPreferences = async () => {
            await axios.get(frontEndHostName + "api/dashboard/filter?widgetId=" + widgetId + "&dashboardId=" + dashboardId)
                .then(r => {
                    if (!r.data.selectedType ) {
                        setSelectedType(typeOptions[0])
                    }
                    if (!r.data.selectedSource){
                        setSelectedSource(sourceInitialOptions[0])
                    }
                    setSelectedSource(r.data.selectedSource)
                    setSelectedType(r.data.selectedType)
                })
                .catch(err => {
                    setSelectedType(typeOptions[0])
                    setSelectedSource(sourceInitialOptions[0])
                })
        }
        getWidgetPreferences()
        setIsPreferenceRetrieved(true)
    }, [])

    const updatePage = () => {
        setPage(prev => prev + 1);
        setLoadingMore(false);
    };

    const handleScroll = () => {
        if (!loading && !error) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            let currentScroll = scroller.current.scrollTop;

            if (currentScroll === maxScroll) {
                setLoadingMore(true);
                debounce(updatePage, 3000)();
            }
        }
    };

    const debounce = <F extends (...params: any[]) => void>(fn: F, delay: number) => {
        let timeoutId: any;

        const debounced = (...args: any) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => fn(...args), delay);
        };

        return debounced as (...args: Parameters<F>) => ReturnType<F>;
    };

    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        const fetchTweets = async () => {
            if (selectedSource !== prevSelection?.selectedSource || selectedType !== prevSelection?.selectedType || page === 0) {
                setLoading(true);
                await axios.get(socialHostName + "twitter/tweets?page=0" +
                    "&size=" + size + "&source=" + selectedSource?.value + "&type=" + selectedType?.value)
                    .then(r => {
                        setTweets(
                            r.data
                        );
                        setPage(0)
                    })
                    .catch(error => setError(true))
                    .finally(() => setLoading(false));
            } else {
                setLoadingMore(true);
                await axios.get(socialHostName + "twitter/tweets?page=" + page +
                    "&size=" + size + "&source=" + selectedSource.value + "&type=" + selectedType.value)
                    .then(r => {
                            setTweets((prevTweets: TweetDTO[]) => {
                                return [...prevTweets, ...r.data];
                            })
                        }
                    )
                    .catch(error => setError(true))
                    .finally(() => setLoadingMore(false));
            }
        };

        fetchTweets();
        return () => {
            prevSelection.selectedSource = selectedSource;
            prevSelection.selectedType = selectedType;
        };
    }, [page, selectedType, selectedSource]);

    useEffect(() => {
        const fetchLists = async () => {
            await axios.get(frontEndHostName + "user-lists?number=0")
                .then(r => {
                    setLists(r.data as List[]);
                })
                .catch(error => setError(true))
                .finally(() => {
                });
        };

        fetchLists();
    }, []);

    useEffect(() => {
        let listOptions: SelectOption[] = lists.map(l =>
            ({
                value: String(l.id),
                label: l.title
            })
        )
        setSourceOptions([...sourceOptions, ...listOptions])
    }, [lists]);


    useEffect(() => {
        const saveWidgetPreferences = () => {
            if (isPreferenceRetrieved) {
                let data = {selectedType, selectedSource}
                axios.post(frontEndHostName + "api/dashboard/save/filter?widgetId=" + widgetId + "&dashboardId=" + dashboardId, data)
            }
        }
        saveWidgetPreferences()
    }, [selectedType, selectedSource])

    return (
        <div>
            <Row className="pb-2">
                <Col>
                    <div className="organisation-card-label">
                        X
                    </div>
                </Col>
            </Row>
            <Row className="nonIqbladeDraggableWidget">
                <Col className="pt-2" xl={6} lg={6} md={3} sm={6} xs={6}>
                    <Select
                        options={typeOptions}
                        value={selectedType}
                        onChange={(e: any) => setSelectedType(e)}
                        placeholder="Select type..."
                        className="basic-multi-select"
                        classNamePrefix="select"/>
                </Col>
                <Col className="pt-2" xl={6} lg={6} md={3} sm={6} xs={6}>
                    <Select
                        options={sourceOptions}
                        value={selectedSource}
                        onChange={(e: any) => setSelectedSource(e)}
                        placeholder="Select source..."
                        className="basic-multi-select"
                        classNamePrefix="select"/>
                </Col>
            </Row>
            <div style={{marginTop: 15, maxHeight: getHeight(), overflowY: "scroll", overflowX: "hidden"}}
                 ref={scroller} onScroll={handleScroll}>
                {error ? (
                    <Alert variant="danger">
                        {translate("errors.loading")}
                    </Alert>
                ) : (
                    !loading ?
                        <div>
                            {tweets.length === 0 && <Alert variant="primary">
                                We couldn't find any recent tweets based on your selection criteria.
                            </Alert>}
                            {tweets.map((tweet: TweetDTO) => (
                                <div key={tweet.tweetId}>
                                    <Tweet tweetId={tweet.tweetId} options={{maxWidth: "98%!important"}}/>
                                </div>
                            ))}
                            <div>{loadingMore && <IqLoadingIcon/>}</div>
                        </div>
                        : <IqLoadingIcon/>
                )}
            </div>
        </div>
    );
};

export default DashboardTwitter;
