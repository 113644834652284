import React, {FormEvent, useState} from 'react';
import {Alert, Button, Form} from "react-bootstrap";
import translate from "../../i18n/translate";
import {useForm} from "react-hook-form";
import axios from "axios";
import {frontEndHostName} from "../../utils/Configuration";
import {faKey} from "@fortawesome/free-solid-svg-icons";
import Card from "./ProfileCard";
import {Variant} from "react-bootstrap/types";


const ChangePasswordForm = () => {

    const {register, watch, handleSubmit, errors, setValue} = useForm();
    const [passwordChangeResponse, setPasswordChangeResponse] = useState<[boolean | null, string | null]>([null, null]);

    type PasswordChange = "currentPassword" | "newPassword" | "repeatPassword";
    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        return handleSubmit(() => {
            let currentPassword = e.target[0].value;
            let newPassword = e.target[1].value;
            let repeatPassword = e.target[2].value;

            let dataFormatted = {
                currentPassword: currentPassword,
                newPassword: newPassword
            };

            axios.post(frontEndHostName + 'change-password', dataFormatted,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {
                if (response.status === 200) {
                    setPasswordChangeResponse([true, "Password changed successfully"]);
                }
            }).catch(error => {
                if (error.response.status === 400) {
                    setPasswordChangeResponse([false, (error.response.data.title || "Error")]);
                }
                console.log(error.message);
            });
        })();
    };

    return (
        <Card heading="Change Password" iconDefinition={faKey}>
            <Form onSubmit={onSubmit}>
                <Form.Group controlId="formCurrentPassword">
                    <Form.Label>{translate("profile.currentpassword")}</Form.Label>
                    <Form.Control name="currentPassword" type="password"
                                  placeholder={translate("profile.inputpassword")}
                                  ref={register({required: true})}
                                  isInvalid={errors.currentPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.currentPassword && errors.currentPassword.type === "required" && "Please fill in the current password."}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formNewPassword">
                    <Form.Label>{translate("profile.newpassword")}</Form.Label>
                    <Form.Control name="newPassword" type="password" placeholder={translate("profile.enternewpassword")}
                                  ref={register({required: true, minLength: 8})}
                                  isInvalid={errors.newPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.newPassword && errors.newPassword.type === "required" && "Please fill in the new password."}
                        {errors.newPassword && errors.newPassword.type === "minLength" && "Minimum length of password is 8."}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formRepeatPassword">
                    <Form.Label>{translate("profile.repeatpassword")}</Form.Label>
                    <Form.Control name="repeatPassword" type="password" placeholder={translate("profile.repeatnewpassword")}
                                  ref={register({
                                      required: true,
                                      maxLength: 50,
                                      validate: (value) => value === watch('newPassword')
                                  })}
                                  isInvalid={errors.repeatPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.repeatPassword && errors.repeatPassword.type === "required" && "Please repeat the new password."}
                        {errors.repeatPassword && errors.repeatPassword.type === "minLength" && "Minimum length of password is 8."}
                        {errors.repeatPassword && errors.repeatPassword.type === "validate" && "Passwords do not match."}
                    </Form.Control.Feedback>

                    <Alert className="mt-3" hidden={passwordChangeResponse == null} variant={((passwordChangeResponse[0] === false && "danger") ||
                        (passwordChangeResponse[0] === true && "success") || undefined) as Variant}>
                        {passwordChangeResponse}
                    </Alert>

                    <Button variant="primary" type="submit" style={{marginTop: 10, float: "right"}}>
                        {translate("profile.changepassword")}
                    </Button>

                </Form.Group>
            </Form>
        </Card>

    )
}

export default ChangePasswordForm;