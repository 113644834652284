import React from "react";
import List from "../../model/list/List";

const UserListContext = React.createContext({
    list: {} as List,
    setList: (list: List) => {},
    loading: false,
    setLoading: (isLoading: boolean) => {},
    showTransferModal: false,
    setShowTransferModal: (show: boolean) => {},
    showRequestContactsModal: false,
    setShowRequestContactsModal: (show: boolean) => {},
    showDataQualityModal: false,
    setShowDataQualityModal: (show: boolean) => {},
    showJobsModal: false,
    setShowJobsModal:  (show: boolean) => {},
});

export default UserListContext;
