import {faBuilding} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import ReactTimeAgo from "react-time-ago";
import {Payload} from "../../../model/notification/Payload";
import {DateText, Highlight, Icon, Item, ItemContent, NotificationItem, Text} from "../Notifications";

const OrganisationUpdatedNotification: React.FC<NotificationItem> = ({element}) => {
    return (
        <Item read={element.read}>
        <Icon>
            <FontAwesomeIcon color={"white"} icon={faBuilding} />
        </Icon>
        <ItemContent>
            <Text>
                {element?.targets?.map<React.ReactNode>((target: Payload, index: number) => (
                    <Highlight key={index}>
                        <a target={"_blank"} href={["/organisation", target.id].join("/")}>
                            {target.placeholder}
                        </a>
                    </Highlight>
                ))
                    .reduce((prev, curr) => [prev, ', ', curr])} {element?.targets?.length > 1 ? "have" : "has"} been recently updated.
            </Text>
            <DateText>
                <ReactTimeAgo date={new Date(element.modified)} locale="en-US" />
            </DateText>
        </ItemContent>
    </Item>
    )
}

export default OrganisationUpdatedNotification;