import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {contractsHostName} from "../../../utils/Configuration";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {Alert, Card, Col, Row} from "react-bootstrap";
import translate from "../../../i18n/translate";
import DashboardItemContext from "../../../context/dashboard/DashboardItemContext";
import DateFormatter from "../../../utils/formatters/DateFormatter";
import {faCalendar, faMoneyBill} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./DashboardContracts.css";
import ContractSearchDto from "../../../model/contracts/ContractSearchDto";

const DashboardContracts: React.FC = () => {
    let history = useHistory();
    const {maxHeight, setMaxHeight} = useContext(DashboardItemContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [contracts, setContracts] = useState<ContractSearchDto[]>([]);
    useEffect(() => {
        const fetchContracts = async () => {
            setLoading(true);

            // ${contractsHostName}mysql/follow/contracts/${contractId}/follow
            await axios.get(contractsHostName + "mysql/follow/contracts/followed")
                .then(r => setContracts(r.data))
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchContracts();
    }, []);

    const getHeight = () => {
        return maxHeight - 80;
    };

    const [unfollowing, setUnfollowing] = useState<string | null>(null);
    const unfollow = async (id: string) => {
        setUnfollowing(id);
        await axios.delete(contractsHostName + "mysql/follow/contracts/" + id + "/follow")
            .then(r => {
                let filtered = contracts.filter((c: ContractSearchDto) => c.releaseId !== id);
                setContracts(filtered);
            })
            .finally(() => setUnfollowing(id));
    };

    const formatValue = (value: number): string => {
        let formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0
        });

        return formatter.format(value);
    };

    return (
        <div>
            <Row className="pb-2">
                <Col>
                    <div className="organisation-card-label">
                        {translate("contracts.title")}
                    </div>
                </Col>
                <Col>
                    <a className="iq-link pull-right" onClick={() => history.push("/contracts")}>
                        {translate("dashboard.viewall")}
                    </a>
                </Col>
            </Row>
            {loading ? <IqLoadingIcon /> : (
                <div>
                    {error && (
                        <Alert variant="danger">
                            {translate("errors.loading")}
                        </Alert>
                    )}

                    <div className="nonIqbladeDraggableWidget" style={{maxHeight: getHeight(), overflowY: "scroll"}}>
                        {contracts.map((contract: ContractSearchDto) => (
                            <Card className="mb-2">
                                <Card.Body>
                                    <Row className="mb-4">
                                        <Col>
                                            <span className="dashboard-contract-title-label"
                                                  onClick={() => history.push("/contract/" + contract.releaseId)}>
                                                {contract.tenderTitle}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-1">
                                        <Col>
                                            <div className="dashboard-contract-details-container">
                                                <div className="dashboard-contract-detail">
                                                    <div className="dashboard-contract-detail"
                                                         title={translate("contracts.enddate")}>
                                                        <span>
                                                            <FontAwesomeIcon className="dashboard-contract-end purple icon" icon={faCalendar} size="1x" />
                                                        </span>
                                                        <span>{DateFormatter.formatDate(contract.tenderContractPeriodEndDate)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="dashboard-contract-details-container">
                                                <div className="dashboard-contract-detail ms-3">
                                                    <div className="dashboard-contract-detail"
                                                         title={translate("contracts.value")}>
                                                        <span>
                                                            <FontAwesomeIcon className="dashboard-contract-end pink icon" icon={faMoneyBill} size="1x" />
                                                        </span>
                                                        <span>{formatValue(contract.tenderValueAmount)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DashboardContracts;
