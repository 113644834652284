import React from "react";

const AdvancedSearchSelectedContext = React.createContext({
    selectedNuts: {} as string[],
    setSelectedNuts: (selected: string[]) => {},
    selectedISIC: {} as string[],
    setSelectedISIC: (selected: string[]) => {},
});

export default AdvancedSearchSelectedContext;
