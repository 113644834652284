import React, {useContext, useState} from "react";
import axios from "axios";
import {contractsHostName} from "../../../utils/Configuration";
import PublicSectorContext, {ResearchFundingFilters} from "../../../context/PublicSectorContext";
import translate from "../../../i18n/translate";
import AsyncSelect from "react-select/async";
import {SelectOption} from "../../news/GlobalNews";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {Col, Form, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faSave} from "@fortawesome/free-solid-svg-icons";
import {format} from "d3-format";

const FundingFilters: React.FC = () => {

    const {researchFundingFilters, setResearchFundingFilters} = useContext(PublicSectorContext);

    const [searchTerm, setSearchTerm] = useState<string>(researchFundingFilters.searchTerm);
    const [selectedOrganisations, setSelectedOrganisations] = useState<string[]>(researchFundingFilters.organisations || []);

    const [startDateTo, setStartDateTo] = useState<string>(researchFundingFilters.startDateTo);
    const [startDateFrom, setStartDateFrom] = useState<string>(researchFundingFilters.startDateFrom);
    const [endDateTo, setEndDateTo] = useState<string>(researchFundingFilters.endDateTo);
    const [endDateFrom, setEndDateFrom] = useState<string>(researchFundingFilters.endDateFrom);

    const valueOptions = [0, 25000, 50000, 100000, 250000, 500000, 1000000, 5000000, 10000000];
    const [minValue, setMinValue] = useState<number | undefined>(researchFundingFilters.minValue);
    const updateMinValue = (value: number) => {
        // @ts-ignore
        if (value === "No min") {
            setMinValue(undefined);
            return;
        }

        setMinValue(value);
    }
    const [maxValue, setMaxValue] = useState<number | undefined>(researchFundingFilters.maxValue);
    const updateMaxValue = (value: number) => {
        // @ts-ignore
        if (value === "No max") {
            setMaxValue(undefined);
            return;
        }

        setMaxValue(value);
    }

    const updateOrganisations = (e: SelectOption[]) => {
        if (e) {
            let selection = e.map(opt => opt.value);
            setSelectedOrganisations(selection);
        }
    };

    const fetchOrganisationOptions = async (q: string) => {
        if (q.length < 3) {
            return;
        }

        let params = {
            q: q
        };

        return new Promise<SelectOption[]>((resolve: any, reject: any) => {
            axios.get(`${contractsHostName}public-research-funding/organisation-search`, {params: params})
                .then(r => {
                    resolve(r.data.map((o: string) => {
                        return {
                            label: <div style={{display: "flex"}}>
                                <span>{o}</span>
                            </div>,
                            value: o
                        }
                    }));
                })
                .catch(reject);
        });
    }
    const searchOrganisations = AwesomeDebouncePromise(fetchOrganisationOptions, 300);

    const applyFilters = () => {
        let filters = {
            organisations: selectedOrganisations,
            searchTerm: searchTerm,
            startDateTo: startDateTo,
            startDateFrom: startDateFrom,
            endDateTo: endDateTo,
            endDateFrom: endDateFrom,
            minValue: minValue,
            maxValue: maxValue
        } as ResearchFundingFilters;

        setResearchFundingFilters(filters);
    }

    const clearFilters = () => {
        setResearchFundingFilters({} as ResearchFundingFilters);
    }

    const clearDisabled = () => {
        return selectedOrganisations?.length === 0 &&
            !searchTerm &&
            !minValue &&
            !maxValue &&
            !startDateFrom &&
            !startDateTo &&
            !endDateFrom &&
            !endDateTo;
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Filters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
                    <div>
                        <div className="organisation-card-label">Search Term</div>
                        <Form.Control name="key" type="text"
                                      placeholder={translate("advancedsearch.search")}
                                      value={searchTerm}
                                      onChange={(e: any) => setSearchTerm(e.target.value)}
                        />
                    </div>

                    <div>
                        <div className="organisation-card-label">
                            Organisations
                        </div>
                        <AsyncSelect
                                     placeholder={translate("advancedsearch.search")}
                                     defaultOptions={[]}
                                     isClearable
                                     isMulti
                                     defaultValue={selectedOrganisations?.map(o => ({label: o, value: o}))}
                                     onChange={(e: any) => updateOrganisations(e)}
                                     loadOptions={searchOrganisations}/>
                    </div>


                    <div className="mb-2">
                        <div className="organisation-card-label">
                            Start Date
                        </div>
                        <Row>
                            <Col className="col-5">
                                <input type="date" className="form-control" value={startDateFrom} onChange={(e: any) => setStartDateFrom(e.target.value)}/>
                            </Col>
                            <Col className="text-center pt-2 col-1">
                                <span>{translate("advancedsearch.to")}</span>
                            </Col>
                            <Col className="col-5">
                                <input type="date" className="form-control" value={startDateTo} onChange={(e: any) => setStartDateTo(e.target.value)}/>
                            </Col>
                        </Row>
                    </div>
                    <div className="mb-2">
                        <div className="organisation-card-label">
                            End Date
                        </div>
                        <Row>
                            <Col className="col-5">
                                <input type="date" className="form-control" value={endDateFrom} onChange={(e: any) => setEndDateFrom(e.target.value)}/>
                            </Col>
                            <Col className="text-center pt-2 col-1">
                                <span>{translate("advancedsearch.to")}</span>
                            </Col>
                            <Col className="col-5">
                                <input type="date" className="form-control" value={endDateTo} onChange={(e: any) => setEndDateTo(e.target.value)}/>
                            </Col>
                        </Row>
                    </div>

                    <div className="mb-2">
                        <div className="organisation-card-label">
                            Value
                        </div>
                        <Row>
                            <Col className="col-5">
                                <select className="form-control" value={minValue} onChange={(e: any) => updateMinValue(e.target.value)}>
                                    <option value={undefined}>{translate("contracts.nomin")}</option>
                                    {
                                        valueOptions
                                            .filter((option: number) => {
                                                if (!maxValue) {
                                                    return true;
                                                }

                                                return option != maxValue && option < maxValue;
                                            })
                                            .map((opt: number) => <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>)
                                    }
                                </select>
                            </Col>
                            <Col className="text-center pt-2 col-1">
                                <span>{translate("advancedsearch.to")}</span>
                            </Col>
                            <Col className="col-5">
                                <select className="form-control" value={maxValue} onChange={(e: any) => updateMaxValue(e.target.value)}>
                                    <option value={undefined}>{translate("contracts.nomax")}</option>
                                    {
                                        valueOptions
                                            .filter((option: number) => {
                                                if (!minValue) {
                                                    return true;
                                                }

                                                return option != minValue && option > minValue
                                            })
                                            .map((opt: number) => <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>)
                                    }
                                </select>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="iqx-button secondary md-size me-3"
                        disabled={clearDisabled()}
                        onClick={clearFilters}>
                    Clear Filters
                    <FontAwesomeIcon icon={faFilter} className="ms-2"/>
                </button>

                <button className="iqx-button primary md-size" onClick={applyFilters}>
                    Apply
                    <FontAwesomeIcon icon={faSave} className="me-1 ms-2" />
                </button>
            </Modal.Footer>
        </>
    );
};

export default FundingFilters;
