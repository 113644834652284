import React, {useEffect, useState} from 'react';
import {Row} from 'react-bootstrap';
import {socialHostName} from '../../utils/Configuration';
import axios from 'axios';
import './ProfileContainer.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useHistory, useLocation} from 'react-router-dom';
import IqLoadingIcon from '../common/IqLoadingIcon';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';

const LinkedInLanding: React.FC = () => {

    let history = useHistory();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    const [authCodeRegistered, setAuthCodeRegistered] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        registerAuthCode(query.get("code"));
    }, []);


    const registerAuthCode = (authCode: String | null) => {
        setLoading(true);
        if (authCode) {
            axios.post(socialHostName + 'linkedin/register-auth-code', authCode,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {
                    if (response.status === 200) {
                        setAuthCodeRegistered(true);
                    }
                }).catch(error => {
                    if (error.response.status === 400) {
                        setAuthCodeRegistered(false);
                    }
                    console.log(error.message);
                }).finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    };

    return (
        <Row className="mt-3">
            {loading ? <IqLoadingIcon /> :
                authCodeRegistered ?
                    <>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            backgroundColor: "white", borderRadius: "0.45rem", padding: 15, margin: 10, width: "100%"
                        }}>
                            <span><FontAwesomeIcon icon={faLinkedin} color="#0073B1" size="6x" /></span>
                            <span style={{
                                fontWeight: 500,
                                fontSize: 18,
                                marginTop: 15
                            }}>
                                Your account has been linked successfully with IQBlade.
                                </span>
                                <span style={{
                                fontWeight: 300,
                                fontSize: 18,
                                marginTop: 15
                            }}>
                                <a href="#" onClick={() => history.push('/profile')}>Go back to your profile.</a>
                                </span>
                        </div>
                    </> :
                    "Not registered"}
        </Row>
    );
};

export default LinkedInLanding;
