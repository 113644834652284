
export const titleCase = (input: string) => {
    let smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i;
    let alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/;
    let wordSeparators = /([ :–—-])/;

    return input.split(wordSeparators)
        .map((current, index, array) => {
            if (
                current.search(smallWords) > -1 &&
                index !== 0 &&
                index !== array.length - 1 &&
                array[index - 3] !== ':' &&
                array[index + 1] !== ':' &&
                (array[index + 1] !== '-' || (array[index - 1] === '-' && array[index + 1] === '-'))
            ) {
                return current.toLowerCase()
            }

            if (current.substr(1).search(/[A-Z]|\../) > -1) {
                return current
            }

            return current.replace(alphanumericPattern, (match)  => match.toUpperCase())
        })
        .join('');
};


export const formatPosition = (position: string) => {
    let officerTitles = /^[Cc].[Oo]$/i;
    if (position.length === 3 && position.search(officerTitles) > -1) {
        return position.toUpperCase();
    }

    return titleCase(position);
};