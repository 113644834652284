import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import ReactTimeAgo from "react-time-ago";
import {DateText, Icon, Item, ItemContent, NotificationItem, Text} from "../Notifications";

const TeamCreationNotification: React.FC<NotificationItem> = ({element}) => {
    return (
        <Item read={element.read}>
        <Icon>
            <FontAwesomeIcon color={"white"} icon={faUsers} />
        </Icon>
        <ItemContent>
            <Text>
                {[<>{element.source.placeholder} has created a team,</>,
                <a target={"_blank"} href={["/team/", element.targets[0].id].join("")}>{element.targets[0].placeholder},</a>,
                <>with members {element.middlewares.map(m => m.placeholder).join(", ")}.</>].reduce((prev: any, curr) =>
                    [...prev, <> </>, curr], [])}
            </Text>
            <DateText>
                <ReactTimeAgo date={new Date(element.modified)} locale="en-US" />
            </DateText>
        </ItemContent>
    </Item>
    )

}

export default TeamCreationNotification;