import React, {useContext, useEffect, useState} from "react";
import TeamContext from "../../../context/teams/TeamContext";
import {TeamUser} from "../Teams";
import {matchPath, useLocation, useRouteMatch} from "react-router-dom";
import {Alert, Button, Col, Form, InputGroup, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle, faPlus, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./EditTeam.css";
import {frontEndHostName} from "../../../utils/Configuration";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import translate from "../../../i18n/translate";
import UserAvatar from "../../common/UserAvatar";
import NewFreemiumUserModal from "../../admin/NewFreemiumUserModal";
import Pagination from "../../../utils/PaginationHook";
import {showGenericErrorDialog, showGenericSuccessDialog} from "../../common/CommonAlerts";

export interface OrganisationUser {
    id: number;
    uuid: string;
    login: string;
    imageUrl: string;
    firstName: string;
    lastName: string;
    email: string;
    activated: boolean;
    organisationId: string;
    authorities: string[];
    termsAgreed: boolean;
}

const EditTeam: React.FC = () => {
    const match = useRouteMatch();
    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/team/:teamId',
        exact: false,
        strict: false
    });

    const { team, setTeam } = useContext(TeamContext);
    const [teamName, setTeamName] = useState<string>(team.name);
    const [teamUsers, setTeamUsers] = useState<TeamUser[]>(team.users);
    const [organisationUsers, setOrganisationUsers] = useState<OrganisationUser[]>([]);

    const [loadingTeam, setLoadingTeam] = useState<boolean>(false);
    const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState(1);


    useEffect(() => {
        const fetchTeam = async () => {
            setLoadingTeam(true);
            await axios.get(frontEndHostName + "teams/" + childMatchedPath?.params.teamId)
                .then(r => {
                    setTeam(r.data);
                    setTeamName(r.data.name);
                    setTeamUsers(r.data.users);
                })
                .catch(() => setError(true))
                .finally(() => setLoadingTeam(false));
        };

        fetchTeam();
    }, []);


    const fetchOrganisationUsers = async () => {
        setLoadingUsers(true);

        let params = {
            page: currentPage - 1,
            size: 10
        };

        await axios.get(frontEndHostName + "users/organisation/", {
            params: params
        })
            .then(r => {
                let data = r.data;
                setOrganisationUsers(data.content);
                setTotalRecords(data.totalElements);
            })
            .catch(() => setError(true))
            .finally(() => setLoadingUsers(false));
    };

    useEffect(() => {
        fetchOrganisationUsers();
    }, [team, currentPage]);


    const handleNameChange = (event: any) => {
        setTeamName(event.target.value);
    };


    const addUser = async (u: OrganisationUser) => {
        let user = {
            teamId: childMatchedPath?.params.teamId,
            userId: u.id,
            login: u.login,
            imageUrl: u.imageUrl,
            firstName: u.firstName,
            lastName: u.lastName,
            manager: false
        } as TeamUser;

        await axios.put(frontEndHostName + "teams/add-user", user)
            .then(r => setTeamUsers(r.data.users))
            .catch(error => console.error(error.message));
    };

    const removeUser = async (id: number) => {
        await axios.delete(frontEndHostName + "teams/user/" + id + "/delete")
            .then(() => {
                let filtered = teamUsers.filter(user => user.id !== id);
                setTeamUsers(filtered);
            })
            .catch(() => showGenericErrorDialog());
    };

    const toggleAdmin = async (id: number) => {
        let current = teamUsers.find(u => u.id === id);

        await axios.get(frontEndHostName + "teams/user/" + id + "/toggle-manager")
            .then(r => {
                if (current) {
                    let users = teamUsers.filter(u => u.id !== id);

                    let isManager = current.manager;
                    let user = {
                        ...current,
                        manager: !isManager
                    };

                    let index = teamUsers.indexOf(user);
                    users.splice(index, 0, user);
                    setTeamUsers(users);
                }
            })
            .catch(() => showGenericErrorDialog());
    };

    const existsInTeam = (username: string) => {
        return teamUsers.find(user => user.login === username);
    };

    const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
    const saveChanges = async () => {
        let updated = {
            ...team,
            users: teamUsers
        };

        await axios.put(frontEndHostName + "teams/update", updated)
            .then(r => {
                setTeam(r.data);
                showGenericSuccessDialog();
            })
            .catch(() => showGenericErrorDialog())
    };

    const [showCreateUser, setShowCreateUser] = useState<boolean>(false);
    const hideCreateModal = () => {
        setShowCreateUser(false);
        fetchOrganisationUsers();
    };

    return (
        <div style={{ paddingTop: 35 }}>
            <NewFreemiumUserModal show={showCreateUser} onHide={hideCreateModal} />
            {loadingTeam ? <IqLoadingIcon /> : (
                <div>
                    {error ? (
                        <Alert variant="danger">
                            {translate("errors.loading")}
                        </Alert>
                    ) : (
                        <Row>
                            <div className="col-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
                                <div className="organisation-card">
                                    <div className="organisation-card-label">
                                        {translate("teams.manage.teamdetails")}
                                    </div>
                                    <div className="mt-2">
                                        <Row>
                                            <Col md={9}>
                                                <InputGroup className="mb-3">
                                                    {/*<InputGroup.Prepend>*/}
                                                        <InputGroup.Text>{translate("teams.manage.teamname")}</InputGroup.Text>
                                                    {/*</InputGroup.Prepend>*/}
                                                    <Form.Control type="text"
                                                        readOnly={true}
                                                        value={teamName}
                                                        onChange={(e) => handleNameChange(e)} />
                                                </InputGroup>
                                            </Col>
                                            <Col md={3}>
                                                <button className="iq-button iq-button-primary pull-right"
                                                    onClick={() => saveChanges()}>
                                                    {translate("teams.manage.savechanges")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="mt-2">
                                        <Table striped hover>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "65%" }}>{translate("teams.common.username")}</th>
                                                    <th style={{ width: "10%" }}>Admin</th>
                                                    <th style={{ width: "25%" }}>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {teamUsers?.map(user => (
                                                    <tr key={user.login}>
                                                        <td>
                                                            <UserAvatar user={user} height="40px" width="40px" marginRight={15} />
                                                            {user.firstName} {user.lastName}
                                                        </td>
                                                        <td>
                                                            {user.manager ? (
                                                                <Button variant="link"
                                                                    title={translate("teams.manage.demote")}
                                                                    onClick={() => toggleAdmin(user.id)}>
                                                                    <span className="fa fa-star fa-lg"></span>
                                                                </Button>
                                                            ) : (
                                                                <Button variant="link"
                                                                    title={translate("teams.manage.promote")}
                                                                    onClick={() => toggleAdmin(user.id)}>
                                                                    <span className="fa fa-star-o fa-lg"></span>
                                                                </Button>
                                                            )}
                                                        </td>
                                                        <td style={{ textAlign: "right" }}>
                                                            {translate("teams.manage.removemember")}
                                                            <Button className="remove-member" variant="link"
                                                                onClick={() => removeUser(user.id)}>
                                                                <FontAwesomeIcon icon={faMinusCircle} size="lg" />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                <div className="organisation-card">
                                    <div className="organisation-card-label">
                                        <span>
                                            {translate("teams.manage.addmembers")}
                                        </span>
                                        <span className="pull-right mt-n2">
                                            <button className="iqx-button sm-size primary" onClick={() => setShowCreateUser(true)}>
                                                <span className="me-2">Create User</span>
                                                <span><FontAwesomeIcon icon={faPlus} /></span>
                                            </button>
                                        </span>
                                    </div>
                                    <div className="mt-2">
                                        {loadingUsers ? <IqLoadingIcon /> : (
                                            <Table striped hover>
                                                <thead>
                                                    <tr>
                                                        <th>{translate("teams.common.username")}</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {organisationUsers.filter(u => !existsInTeam(u.login))
                                                        .map((user: OrganisationUser) => (
                                                            <tr key={user.login}>
                                                                <td>
                                                                    <UserAvatar user={user} height="40px" width="40px" marginRight={15} />
                                                                    {user.firstName} {user.lastName}
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {translate("teams.manage.addmember")}
                                                                    <Button className="add-member" variant="link"
                                                                        onClick={() => addUser(user)}>
                                                                        <FontAwesomeIcon icon={faPlusCircle} size="lg" />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                    <Pagination
                                                        totalRecords={totalRecords}
                                                        pageLimit={10}
                                                        initPage={currentPage}
                                                        pageRangeDisplayed={1}
                                                        onChangePage={setCurrentPage}
                                                    />
                                                </tbody>
                                            </Table>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Row>
                    )}
                </div>
            )}
        </div>
    );
};

export default EditTeam;
