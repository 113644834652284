import React, {useContext, useEffect, useState} from "react";
import {Alert, Row} from "react-bootstrap";
import OrganisationOfficers from "./OrganisationOfficers";
import OrganisationContacts from "./OrganisationContacts";
import OrganisationMyContacts from "./OrganisationMyContacts";
import axios from "axios";
import {socialHostName} from "../../../utils/Configuration";
import {hasUserRole, Role} from "../../../utils/Security";
import OrganisationPeopleContext from "../../../context/organisation/OrganisationPeopleContext";
import NylasContact from "../../../model/organisation/social/NylasContact";
import translate from "../../../i18n/translate";
import OrganisationJob from "./OrganisationJob";
import OrganisationContext from "../../../context/organisation/OrganisationContext";

enum TabKeys {
    OFFICERS = "officers",
    CONTACTS = "contacts",
    MYCONTACTS = "mycontacts",
    JOBS="jobs"
}

const OrganisationPeople: React.FC = () => {

    const {organisation} = useContext(OrganisationContext);
    const [contacts, setContacts] = useState<NylasContact[]>([]);
    const [key, setKey] = useState<string>(organisation.primaryType !== "PublicSector" ? TabKeys.OFFICERS : TabKeys.CONTACTS);
    const [nylasAccountRegistered, setNylasAccountRegistered] = useState<boolean>(false);
    const [freemiumUser, setFreemiumUser] = useState<boolean>(false);

    useEffect(() => {
        if (hasUserRole(Role.FREEMIUM)) {
            setFreemiumUser(true)
        }
    }, [])

    useEffect(() => {
        const fetchNylasStatus = () => {
            axios.get(socialHostName + "check-access")
                .then((response) => {
                    setNylasAccountRegistered(response.data)
                }).catch(error => console.log(error.message));
        }
        fetchNylasStatus();
    }, [])

    return (
        <OrganisationPeopleContext.Provider
            value={{contacts, setContacts, key, setKey}}>
            <div>
                <div style={{paddingTop: "10px"}}>
                    <Row className="" style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                        <div style={{marginLeft: "auto", marginRight: "auto"}} className="iq-button-group">
                            {organisation.primaryType !== "PublicSector" && (
                                <button disabled={freemiumUser} className={key === TabKeys.OFFICERS ? "selected-inverted" : ""}
                                        onClick={(e) => {
                                            setKey(TabKeys.OFFICERS)
                                        }}>Officers
                                </button>
                            )}
                            <button disabled={freemiumUser} className={key === TabKeys.CONTACTS ? "selected-inverted" : ""}
                                    onClick={(e) => {
                                        setKey(TabKeys.CONTACTS)
                                    }}>Contacts
                            </button>
                            {hasUserRole(Role.NYLAS_ACCESS) && (
                                <button disabled={freemiumUser} className={key === TabKeys.MYCONTACTS ? "selected-inverted" : ""}
                                        onClick={(e) => {
                                            setKey(TabKeys.MYCONTACTS)
                                        }}>My Contacts</button>)}
                            {hasUserRole(Role.USER) && (
                            <button disabled={freemiumUser} className={key === TabKeys.JOBS ? "selected-inverted" : ""}
                                    onClick={(e) => {
                                        setKey(TabKeys.JOBS)
                                    }}>Jobs
                            </button>)}
                        </div>
                    </Row>
                    <Row className="" style={{marginLeft: 0, marginRight: 0}}>
                        {freemiumUser && (
                            <div style={{width: "100%", borderRadius: "0.45rem", paddingRight: 15}}>
                            <Alert variant="warning">
                                {translate("organisation.people.upgradeAccountMessage")}
                            </Alert>
                            </div>
                        )}
                        <div style={{width: "100%"}} hidden={ key !== TabKeys.OFFICERS} >
                            <OrganisationOfficers />
                        </div>

                        <div style={{width: "100%"}} hidden={ key !== TabKeys.CONTACTS}>
                            <OrganisationContacts/>
                        </div>


                        <div  style={{width: "100%"}} hidden={ key !== TabKeys.MYCONTACTS}>
                            <OrganisationMyContacts nylasActive={nylasAccountRegistered}/>
                        </div>

                        <div  style={{width: "100%"}} hidden={ key !== TabKeys.JOBS}>
                            <OrganisationJob/>
                        </div>
                    </Row>
                </div>
            </div>
        </OrganisationPeopleContext.Provider>
    );
};

export default OrganisationPeople;
