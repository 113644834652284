import React, {useContext, useEffect, useState} from "react";
import PublicSectorContext, {FundingSort, ResearchFundingFilters} from "../../../context/PublicSectorContext";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {Form, Modal, Table} from "react-bootstrap";
import DateFormatter from "../../../utils/formatters/DateFormatter";
import Pagination from "../../../utils/PaginationHook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronRight,
    faFilter,
    faSort,
    faSortAmountDown,
    faSortAmountDownAlt
} from "@fortawesome/free-solid-svg-icons";
import {format} from "d3-format";
import {useHistory, useRouteMatch} from "react-router-dom";
import FundingFilters from "./FundingFilters";
import Switch from "antd/lib/switch";

const PublicResearchFunding: React.FC = () => {

    let history = useHistory();
    let match = useRouteMatch();

    const {
        funding, setFunding,
        loadingFunding, setLoadingFunding,
        fundingPage, setFundingPage,
        fundingPageSize, setFundingPageSize,
        fundingTotal, setFundingTotal,
        fundingSort, setFundingSort,
        fundingDesc, setFundingDesc,
        researchFundingFilters, setResearchFundingFilters
    } = useContext(PublicSectorContext);

    const [hideExpired, setHideExpired] = useState<boolean>(true);
    useEffect(() => {
        const toggleHideExpiredFunding = (switchStateValue: boolean) => {
            setLoadingFunding(true);
            let filters = {
                organisations: researchFundingFilters?.organisations || [],
                searchTerm: researchFundingFilters?.searchTerm || "",
                startDateFrom: researchFundingFilters?.startDateFrom || "",
                startDateTo: researchFundingFilters?.startDateTo || "",
                endDateFrom: researchFundingFilters?.endDateFrom || "",
                endDateTo: researchFundingFilters?.endDateTo || "",
                minValue: researchFundingFilters?.minValue || "",
                maxValue: researchFundingFilters?.maxValue || ""
            } as ResearchFundingFilters;

            filters.endDateFrom = "";
            if (switchStateValue) {
                filters.endDateFrom = new Date().toISOString().slice(0,10);
            }


            setResearchFundingFilters(filters);
            setHideExpired(switchStateValue);
            setLoadingFunding(false);

        }
        toggleHideExpiredFunding(hideExpired);
    }, [hideExpired]);


    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== fundingPageSize) {
            setFundingPageSize(size);
            setFundingPage(1);
        }
    };

    const getSortButton = (s: FundingSort) => {
        if (s === fundingSort) {
            return (
                <a className="sort-button pull-right"
                   onClick={() => {
                       setFundingDesc(!fundingDesc);
                   }}>
                    <FontAwesomeIcon icon={fundingDesc ? faSortAmountDown : faSortAmountDownAlt} />
                </a>
            );
        }

        return (
            <a className="sort-button pull-right"
               onClick={() => {
                   setFundingSort(s);
                   setFundingDesc(true);
               }}>
                <FontAwesomeIcon icon={faSort} />
            </a>
        );
    };
    const showClearFilters = () => {
        if (hideExpired) {
            if (!researchFundingFilters.searchTerm && !researchFundingFilters.organisations && !researchFundingFilters.startDateFrom && !researchFundingFilters.startDateTo && !researchFundingFilters.endDateTo) {
                return true;
            }
        }
        return false;
    }

    const [showFilters, setShowFilters] = useState<boolean>(false);
    useEffect(() => {
        setShowFilters(false);
    }, [researchFundingFilters]);

    return (
        <div style={{
            paddingTop: "10px",
            backgroundColor: "white",
            borderRadius: "0.45rem",
            padding: 15,
            marginTop: 10,
            paddingBottom: 25
        }}>
            <div className="row row-reverse mb-3 pull-right me-2">
                {(researchFundingFilters.searchTerm || researchFundingFilters.organisations?.length > 0) && (
                    showClearFilters()  ? null : (
                        <button className="iqx-button secondary md-size pull-right me-3"
                                onClick={() => {setResearchFundingFilters({} as ResearchFundingFilters); setHideExpired(false)}}>
                            Clear Filters
                            <FontAwesomeIcon icon={faFilter} className="ms-2"/>
                        </button>
                    )
                )}


                <button className="iqx-button primary md-size pull-right"
                        onClick={() => setShowFilters(true)}>
                    Filters
                    <FontAwesomeIcon icon={faFilter} className="ms-2"/>
                </button>
            </div>
            <div className="row row-reverse mb-3 pull-right me-2">
                <div className="col me-4 mt-2" style={{textAlign: "end"}}>
                    <span className="me-2">Hide expired</span>
                    <Switch checked={hideExpired} onChange={(switchStateValue: boolean) => setHideExpired(switchStateValue)}/>
                </div>
            </div>

            {loadingFunding ? <IqLoadingIcon/> : (
                <>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>Title {getSortButton(FundingSort.TITLE)}</th>
                                <th>Status {getSortButton(FundingSort.STATUS)}</th>
                                <th>Start Date {getSortButton(FundingSort.START_DATE)}</th>
                                <th>End Date {getSortButton(FundingSort.END_DATE)}</th>
                                <th className="text-end">Value {getSortButton(FundingSort.VALUE)}</th>
                                <th>Funder</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {funding.map(f => (
                                <tr key={f.projectId}>
                                    <td>{f.title}</td>
                                    <td>{f.status}</td>
                                    <td>{DateFormatter.formatDate(f.startDate)}</td>
                                    <td>{DateFormatter.formatDate(f.endDate)}</td>
                                    <td className="text-end">£{format(",.0f")(f.fundingValue)}</td>
                                    <td>{f.funderOrganisation.name}</td>
                                    <td className="text-end" style={{width: "11%"}}>
                                        <button className="iqx-button sm-size primary"
                                                onClick={() => history.push(`${match.url}/project/${f.projectId}`)}>
                                            <span className="me-2">View</span>
                                            <FontAwesomeIcon icon={faChevronRight}/>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="mt-4">
                        <div className="justify-content-md-end pagination mt-n2">
                            <span className="me-2">
                                <Form.Control as="select"
                                              style={{minWidth: "13%"}}
                                              defaultValue={fundingPageSize}
                                              onChange={(event) => changePageSize(event)}
                                              >
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                </Form.Control>
                            </span>
                            <span>
                                {!!fundingTotal && (
                                    <Pagination totalRecords={fundingTotal}
                                                pageLimit={fundingPageSize}
                                                pageRangeDisplayed={1}
                                                initPage={fundingPage}
                                                onChangePage={setFundingPage}
                                    />
                                )}
                            </span>
                        </div>
                    </div>
                </>
            )}
            <Modal show={showFilters}
                   onHide={() => setShowFilters(false)}
                   size="lg">
                <FundingFilters/>
            </Modal>
        </div>
    );
};

export default PublicResearchFunding;
