import React, {useContext, useEffect, useState} from "react";
import UserListContext from "../../../context/list/UserListContext";
import axios from "axios";
import {organisationHostname} from "../../../utils/Configuration";
import RequestContactsModal from "../../common/request-contacts/RequestContactsModal";
import {ContactRequest} from "../../../model/contacts/ContactRequest";
import {
    showGenericErrorDialog,
    showGenericSuccessDialog,
    showSuccessWithMessage,
    showWarning
} from "../../common/CommonAlerts";


const ListRequestContactsModal: React.FC = () => {
    const { list, showRequestContactsModal, setShowRequestContactsModal } = useContext(UserListContext);

    const [error, setError] = useState<boolean>(false);
    const [warning, setWarning] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleClose = () => {
        setShowRequestContactsModal(false);
    };


    const handleRequest = (payload: ContactRequest) => {
        setLoading(true);
        axios.post(organisationHostname + "organisation/update-cognism-contacts", {
            ...payload,
            listId: list.id,
        })
            .then(() => {
                showSuccessWithMessage("Success!", "Contacts successfully requested. Requests may take a while to process depending on the number of contacts requested, we will notify you when your request has been processed.");
                setShowRequestContactsModal(false);
            })
            .catch((error) => {
                if (error.response?.data?.errorKey === "consumptionexceeded") {
                    showWarning("Consumption exceeded!", "You don't have enough credits left to request contacts.");
                } else {
                    showGenericErrorDialog();
                }
            })
            .finally(() => setLoading(false));
    };

    const [jobTitleOptions, setJobTitleOptions] = useState<string[]>([]);
    useEffect(() => {
        const fetchJobTitleOptions = async () => {
            await axios.get(organisationHostname + "contacts/jobtitles")
                .then(r => setJobTitleOptions(r.data));
        }

        fetchJobTitleOptions();
    }, []);

    return (
        <RequestContactsModal showRequestContactsModal={showRequestContactsModal}
                              setShowRequestContactsModal={setShowRequestContactsModal}
                              handleClose={handleClose}
                              handleRequest={handleRequest}
                              // error={error}
                              // setError={setError}
                              // warning={warning}
                              // setWarning={setWarning}
                              // showSuccess={showSuccess}
                              // setShowSuccess={setShowSuccess}
                              loading={loading}
                              jobTitles={jobTitleOptions}/>
    );
};

export default ListRequestContactsModal;
