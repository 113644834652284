import React, {useEffect, useState} from "react";
import {Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faYoutube} from "@fortawesome/free-brands-svg-icons";
import UserListTwitter from "./UserListTwitter";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";
import UserListYouTube from "./UserListYouTube";
import {FaXTwitter} from "react-icons/fa6";

enum TabKeys {
    TWITTER = "twitter",
    YOUTUBE = "youtube"
}

const UserListSocial: React.FC = () => {

    let location = useLocation();
    const history = useHistory();

    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/lists/:listId',
        exact: false,
        strict: false
    });

    const parsed = queryString.parse(location.search);
    const k = parsed.key ? parsed.key as string : TabKeys.YOUTUBE;
    const [key, setKey] = useState<string>(k);

    useEffect(() => {
        if (key) {
            let link = "/lists/" + childMatchedPath?.params.listId + "/social?key=" + key;
            history.push(link);
        }
    }, [key]);

    return (
        <div>
            <Row className="" style={{ marginLeft: 0, marginRight: 0, marginTop: 15, display: "none" }}>
                <div style={{ marginLeft: "auto", marginRight: "auto" }} className="iq-button-group">
                    <button className={"ps-3 " + (key === TabKeys.TWITTER ? "selected-inverted" : "")}
                            onClick={(e) => { setKey(TabKeys.TWITTER) }}>
                        <FaXTwitter />
                    </button>
                    <button className={"pe-3 " + (key === TabKeys.YOUTUBE ? "selected-inverted" : "")}
                            onClick={(e) => { setKey(TabKeys.YOUTUBE) }}>
                        <FontAwesomeIcon icon={faYoutube} />
                    </button>
                </div>
            </Row>

            {key === TabKeys.TWITTER && <UserListTwitter />}
            {key === TabKeys.YOUTUBE && <UserListYouTube />}
        </div>
    );
};

export default UserListSocial;