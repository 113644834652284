import React, {useContext, useEffect, useState} from "react";
import {Radio} from "antd";

import './OnboardingQuestion.css';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import FilterBubble from "../advanced-search/FilterBubble";
import {QuickFilters} from "../advanced-search/FilterBubbleContainer";
import AdvancedSearchContext from "../../context/advanced-search/AdvancedSearchContext";
import {faFolder, faGlobe} from "@fortawesome/free-solid-svg-icons";
import TreeSelectField from "../advanced-search/fields/TreeSelectField";
import {getEndUserConfig, getNutsFilterValues} from "../advanced-search/AdvancedSearchConfig";
import I18nContext from "../../context/I18nContext";
import axios from "axios";
import {searchHostname} from "../../utils/Configuration";
import Filters from "../../model/advanced-search/Filters";
import {JsonGroup, Utils as QbUtils} from "react-awesome-query-builder-opti";

interface OnboardingQuestionProps {
    question: string,
    name: string,
    currentValue: string,
    radioValues: Array<RadioValue>,
    onChangeCallback: Function
    saveLocationOrISIC: Function
}

interface RadioValue {
    valueName: string,
    text: string,
    hint: string
}

const OnboardingQuestion: React.FC<OnboardingQuestionProps> = ({question, name, currentValue, radioValues, onChangeCallback, saveLocationOrISIC}) => {
    const [filters, setFilters] = useState({
        accreditations: [],
        competencies: [],
        vendors: [],
        products: [],
        companyTypes: [],
        companyLegalTypes: [],
        sicCodes: [],
        nutsCodes: [],
        headOffice: [],
        tags: [],
        isicCodes: [],
        statuses: []
    } as Filters);
    const { lang, setLang } = useContext(I18nContext);
    const [config, setConfig] = useState(getEndUserConfig(filters, lang));
    const queryValue: JsonGroup =  { "id": QbUtils.uuid(), "type": "group" };
    const [tree, setTree] = useState(QbUtils.checkTree(QbUtils.loadTree(queryValue), config));
    const [showIsicOrNuts, setShowIsicOrNuts] = useState("");
    const [filterSettings, setFilterSettings] = useState({} as any);
    const [loadFilter, setLoadFilter] = useState<boolean>(false)

    useEffect(() => {
        const fetchFilters = async () => {
            await axios.get(`${searchHostname}advanced-search/getFilters?timestamp=${new Date().getTime()}`)
                .then((response) => {
                    setFilters((response.data as Filters));
                }).catch(error => console.log(error.message));
        };
        fetchFilters();
    }, []);

    useEffect(() => {
        setConfig(getEndUserConfig(filters, lang));
    }, [filters]);



    useEffect(() => {
        setFilterSettings({
            [QuickFilters.NUTS]: {
                icon: faGlobe,
                title: "advancedsearch.location",
                widget: (filterState: any, setFilterState: any) => {
                    return <TreeSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selected={[]}
                        options={getNutsFilterValues(filters)}
                        filterId={QuickFilters.NUTS}
                        graphField={"nutsClassification.nuts*Code.keyword"}
                    />
                }
            },
            [QuickFilters.ISIC]: {
                icon: faFolder,
                title: "ISIC Codes",
                widget: (filterState: any, setFilterState: any) => {
                    return <TreeSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selected={[]}
                        options={filters.isicCodes?.map(isicCode => {
                            let jsonIsic = JSON.parse(isicCode);
                            return Object.assign({
                                value: jsonIsic.code,
                                title: jsonIsic.label[lang]
                            }, jsonIsic.parent === null ? null : {parent: jsonIsic.parent})
                        })}
                        filterId={QuickFilters.ISIC}
                        graphField={"isicClassification.isicLevel*Code.keyword"}
                    />
                }
            }
        })
    },[filters])

    useEffect(() => {
        let objectTree = QbUtils.getTree(tree);
        if (objectTree.hasOwnProperty('children1')) {
            for (let children1Key in objectTree.children1) {
                let properties = objectTree!.children1![children1Key]!.properties;
                saveLocationOrISIC(properties);
            }
        }
    }, [tree])


    return (
        <div>
            <div className="form-group">
                <div className="row">
                    <div className="col-xs-4">
                        <span className="question-header">{question}</span>
                    </div>
                </div>
                <div className="row mt-2">
                    <Radio.Group   onChange={(e: any) => { onChangeCallback(e.target.value)}} value={currentValue}>
                        {radioValues.map(v => (
                            <OverlayTrigger overlay={<Tooltip id="radio-tooltip">{v.hint}</Tooltip>} delay={{show: 50, hide: 100}} placement="right">
                                <div className="col-xs-4">
                                    <Radio className="no-select-text"  name={name} value={v.valueName} onChange={(e: any) => setShowIsicOrNuts(v.valueName)}>{v.text} </Radio>
                                </div>
                            </OverlayTrigger>
                        ))}
                    </Radio.Group>

                </div>
                {showIsicOrNuts.toLowerCase().includes("geography") &&
                    (<div style={{display: "flex"}}>
                        <AdvancedSearchContext.Provider value={{ tree, setTree, filters, config}}>
                            <FilterBubble key={["endUser", QuickFilters.NUTS].join("-")} filterId={QuickFilters.NUTS} filterSettings={filterSettings} />
                        </AdvancedSearchContext.Provider>
                    </div>)
                }
                {showIsicOrNuts.toLowerCase().includes("sector") &&
                    (<div style={{display: "flex"}}>
                        <AdvancedSearchContext.Provider value={{ tree, setTree, filters, config}}>
                            <FilterBubble key={["endUser", QuickFilters.ISIC].join("-")} filterId={QuickFilters.ISIC} filterSettings={filterSettings} />
                        </AdvancedSearchContext.Provider>
                    </div>)
                }
            </div>
        </div>
    )

}

export default OnboardingQuestion;