import React from 'react';
import {Card, Col, Image, Row} from "react-bootstrap";
import translate from "../../../../i18n/translate";
import {format} from "d3-format";
import {TwitterUser} from "../../../social/TwitterFeed";


interface TwitterProfileProps {
    twitterUser: TwitterUser
}

const TwitterProfile: React.FC<TwitterProfileProps> = ({twitterUser}) => {

    const getImageUrl = () => {
        if (twitterUser && twitterUser.logoUrl) {
            let formattedLogoUrl = twitterUser.logoUrl.substring(0, twitterUser.logoUrl.lastIndexOf(".")) + "_bigger" + twitterUser.logoUrl.substring(twitterUser.logoUrl.lastIndexOf("."));
            return formattedLogoUrl.replace("_normal", "");
        }
        return "/icons_profil.svg";
    };

    const getAccountAge = (date: string) => {

        var date1 = new Date(date);
        var date2 = new Date();
        var diff = new Date(date2.getTime() - date1.getTime());

        if ((diff.getUTCFullYear() - 1970 === 0) && (diff.getUTCMonth()-1 === 0)){
            return diff.getUTCDay() + "Days"
        } else if ((diff.getUTCFullYear() - 1970 !== 0)){
            return (diff.getUTCFullYear() - 1970) + "." + (diff.getUTCMonth()-1) + " Years"
        } else {
            return (diff.getUTCMonth()-1) + "Months";
        }

    }

    return(
        <Card className="mb-4">
            <Card.Body>
                <div className="text-center mb-2">
                    <Card.Title>
                        <Image className="profile-image mb-4"
                               src={getImageUrl()}
                               roundedCircle/>
                        <h4>{twitterUser.name}</h4>
                    </Card.Title>
                    <Card.Subtitle>
                        <div className="mb-3">
                            <h6 className="subtitle">@{twitterUser.screenName}</h6>
                        </div>
                        <div className="mb-3">
                            <a className="iq-button iq-button-gradient"
                               href={`https://www.twitter.com/${twitterUser.screenName}`}>
                                {translate("organisation.social.twitter.followon")}

                            </a>
                        </div>
                    </Card.Subtitle>
                    <Row>
                        <Col className="twitter-user-details detail-left">
                            <div>
                                <b>{format(',.3~s')(twitterUser.statusesCount).replace(/G/, "B")}</b>
                            </div>
                            <span className="subtitle">Tweets</span>
                        </Col>
                        <Col className="twitter-user-details detail-centre">
                            <div>
                                <b>{format(',.3~s')(twitterUser.followersCount).replace(/G/, "B")}</b>
                            </div>
                            <span className="subtitle">Followers</span>
                        </Col>
                        <Col className="twitter-user-details detail-right">
                            <div>
                                <b>{format(',.3~s')(twitterUser.friendsCount).replace(/G/, "B")}</b>
                            </div>
                            <span className="subtitle">Following</span>
                        </Col>
                    </Row>
                        <Row>
                            <Col className="twitter-user-details detail-left">
                                <div>
                                    <b>{getAccountAge(twitterUser.createdAt)}</b>
                                </div>
                                <span className="subtitle">Account Age</span>
                            </Col>
                        </Row>

                </div>
                <div className="p-2">
                    {twitterUser.description}
                </div>
            </Card.Body>
        </Card>
    )
}

export default TwitterProfile;