import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import './FilterBubble.css';
import translate from "../../i18n/translate";

interface Props {
    filterId: string,
    filterSettings: any,
    setEditing?: (filter: string) => void,
    clearEdit?: (filter: string) => void
}

const FilterBubble: React.FC<Props> = (props: Props) => {

    const [filterState, setFilterState] = useState("");

    useEffect(() => {
        if (props.setEditing && props.clearEdit) {
            if (filterState === "editable") {
                props.setEditing(props.filterId);
            }
            else {
                props.clearEdit(props.filterId);
            }
        }

    }, [filterState]);

    const onClick = () => {
        setFilterState("editable")
    };

    return (
        Object.keys(props.filterSettings).length
            ?
            <div onClick={() => { !filterState && onClick() }} className={"filter-bubble" + (filterState && " ") + filterState}>
                <div className="icon">
                    <FontAwesomeIcon icon={props.filterSettings[props.filterId].icon} color="white" size="1x" />
                </div>
                <div className="content">

                    <div className="title">
                        {translate(props.filterSettings[props.filterId].title)}
                    </div>
                    <div className="selection">
                        {props.filterSettings[props.filterId].widget(filterState, setFilterState)}
                    </div>
                </div>
            </div>
            :
            <div></div>
    );
}

export default FilterBubble;

