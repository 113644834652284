import React, {useEffect, useState} from 'react';
import {Alert, Card, Col, Row} from "react-bootstrap";
import translate from "../../../i18n/translate";
import TwitterFeed, {TwitterUser} from "../../social/TwitterFeed";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import TwitterTopData from "../../officer/social/TwitterTopData";
import {Aggregates} from "../../officer/social/OfficerSocial";
import axios from "axios";
import {socialHostName} from "../../../utils/Configuration";
import TwitterProfile from "./Twitter/TwitterProfile";
import TwitterStatistics from "./Twitter/TwitterStatistics";


interface TwitterPreviewProps {
    twitterUser: TwitterUser
}

const TwitterPreview: React.FC<TwitterPreviewProps> = ({twitterUser}) => {

    const [hashtags, setHashtags] = useState<Aggregates>();
    const [mentions, setMentions] = useState<Aggregates>();

    const [loadingMentions, setLoadingMentions] = useState<boolean>(false);
    const [loadingHashtags, setLoadingHashtags] = useState<boolean>(false);

    const [hashtagsError, setHashtagsError] = useState<boolean>(false);
    const [mentionsError, setMentionsError] = useState<boolean>(false);

    useEffect(() => {
        const fetchHashtags = async () => {
            setLoadingHashtags(true);
            await axios.get(socialHostName + "twitter/" + twitterUser?.screenName + "/hashtags")
                .then(r => {
                    setHashtags(r.data);
                    setHashtagsError(false);
                })
                .catch(() => setHashtagsError(true))
                .finally(() => setLoadingHashtags(false));
        };

        const fetchMentions = async () => {
            setLoadingMentions(true);
            await axios.get(socialHostName + "twitter/" + twitterUser?.screenName + "/mentions")
                .then(r => {
                    setMentions(r.data);
                    setHashtagsError(false);
                })
                .catch(() => setMentionsError(true))
                .finally(() => setLoadingMentions(false));
        };

        const fetchTwitterStats = async () => {
            await axios.post(socialHostName + "twitter/" + twitterUser?.screenName + "/stats")
                .then(r => console.log(r.data))
        };

        if (twitterUser != null) {
            fetchHashtags();
            fetchMentions();
            fetchTwitterStats();
        }
    }, [twitterUser]);

    return twitterUser && (
        <Row>
            <Col lg={3} sm={5} xs={12}>
                <TwitterProfile twitterUser={twitterUser}/>
            </Col>
            <Col lg={5} sm={7} xs={12}>
                <div className="mb-4" style={{maxHeight: 850, overflowY: "scroll"}}>
                    <TwitterFeed twitterUser={twitterUser}
                                 maxTweets="10"/>
                </div>
            </Col>
            <Col lg={4} xs={12}>
                <div className="mb-3">
                    <Card>
                        <Card.Body>
                            <div className="mb-2 organisation-card-label">
                                {translate("organisation.social.twitter.top5hashtags")}
                            </div>
                            {loadingHashtags && <IqLoadingIcon/>}
                            {hashtags && (
                                <TwitterTopData id="hashtag-tabs" prefix="#" aggregates={hashtags}/>
                            )}
                            {hashtagsError && (
                                <Alert variant="danger">
                                    {translate("errors.loading")}
                                </Alert>
                            )}
                        </Card.Body>
                    </Card>
                </div>
                <div className="mb-3">
                    <Card>
                        <Card.Body>
                            <div className="mb-2 organisation-card-label">
                                {translate("organisation.social.twitter.top5mentions")}
                            </div>
                            {loadingMentions && <IqLoadingIcon/>}
                            {mentions && (
                                <TwitterTopData id="mentions-tabs" prefix="@" aggregates={mentions}/>
                            )}
                            {mentionsError && (
                                <Alert variant="danger">
                                    {translate("errors.loading")}
                                </Alert>
                            )}
                        </Card.Body>
                    </Card>
                </div>
                <TwitterStatistics user={twitterUser}/>
            </Col>
        </Row>

    );

}

export default TwitterPreview;