import React from "react";
import CCSFramework from "../model/publicsector/CCSFramework";
import GCloudSupplier from "../model/publicsector/GCloudSupplier";
import Funding from "../model/publicsector/Funding";
import CCSFrameworkIdsAndName from "../model/publicsector/CCSFrameworkIdsAndName";

export enum CCSSort {
    NAME = "name",
    CODE = "code",
    START_DATE = "startDate",
    END_DATE = "endDate"
}

export enum GCloudSort {
    NAME = "supplierName",
    DOMAIN = "supplierDomain"
}

export enum FundingSort {
    TITLE = "title",
    STATUS = "status",
    START_DATE = "start_date",
    END_DATE = "end_date",
    VALUE = "funding_value"
}

export interface ResearchFundingFilters {
    organisations: string[],
    searchTerm: string,
    startDateTo: string,
    startDateFrom: string,
    endDateTo: string,
    endDateFrom: string,
    minValue: number,
    maxValue: number
}

export interface CCSFilters {
    searchTerm: string,
    frameworkIds: string[],
    frameworkIdsAndName: CCSFrameworkIdsAndName[],
    startDateTo: string,
    startDateFrom: string,
    endDateTo: string,
    endDateFrom: string
}

export interface GCloudFilters {
    searchTerm: string,
    frameworks: string[]
}

const PublicSectorContext = React.createContext({
    ccsFrameworks: [] as CCSFramework[],
    setCCSFrameworks: (f: CCSFramework[]) => {},
    loadingCCS: false,
    setLoadingCCS: (loading: boolean) => {},
    ccsPage: 1,
    setCCSPage: (page: number) => {},
    ccsPageSize: 10,
    setCCSPageSize: (page: number) => {},
    ccsTotal: 0,
    setCCSTotal: (total: number) => {},
    ccsSort: CCSSort.END_DATE,
    setCCSSort: (sort: CCSSort) => {},
    ccsDesc: true,
    setCCSDesc: (desc: boolean) => {},
    ccsFilters: {} as CCSFilters,
    setCCSFilters: (filters: CCSFilters) => {},
    frameworkIds: [] as string[],
    setFrameworkIds: (ids: string[]) => {},
    frameworkIdsAndName: [] as CCSFrameworkIdsAndName[],
    setFrameworkIdsAndName: (frameworkIdsAndName: CCSFrameworkIdsAndName[]) => {},

    gCloudSuppliers: [] as GCloudSupplier[],
    setGCloudSuppliers: (f: GCloudSupplier[]) => {},
    loadingGCloud: false,
    setLoadingGCloud: (loading: boolean) => {},
    gCloudPage: 1,
    setGCloudPage: (page: number) => {},
    gCloudPageSize: 10,
    setGCloudPageSize: (page: number) => {},
    gCloudTotal: 0,
    setGCloudTotal: (total: number) => {},
    gCloudSort: GCloudSort.NAME,
    setGCloudSort: (sort: GCloudSort) => {},
    gCloudDesc: false,
    setGCloudDesc: (desc: boolean) => {},
    gCloudFilters: {} as GCloudFilters,
    setGCloudFilters: (filters: GCloudFilters) => {},

    funding: [] as Funding[],
    setFunding: (f: Funding[]) => {},
    loadingFunding: false,
    setLoadingFunding: (loading: boolean) => {},
    fundingPage: 1,
    setFundingPage: (p: number) => {},
    fundingPageSize: 10,
    setFundingPageSize: (s: number) => {},
    fundingTotal: 0,
    setFundingTotal: (total: number) => {},
    fundingSort: FundingSort.END_DATE,
    setFundingSort: (s: FundingSort) => {},
    fundingDesc: false,
    setFundingDesc: (desc: boolean) => {},
    researchFundingFilters: {} as ResearchFundingFilters,
    setResearchFundingFilters: (filters: ResearchFundingFilters) => {}

});

export default PublicSectorContext;