import { useContext, useEffect, useRef } from "react";
import { Message, SubscribeHeaders } from "webstomp-client";
import StompContext from "../context/StompContext";

export function useSubscription(
    destinations: string | string[],
    onMessage: ((message: Message) => any) | undefined,
    headers: SubscribeHeaders
) {

    const stompContext = useContext(StompContext);
    if (stompContext === undefined) {
        throw new Error(
            'Could not find a StompSessionProvider.'
        );
    }
    const callbackRef = useRef<((message: Message) => any) | undefined>(onMessage);

    const _destinations = Array.isArray(destinations)
        ? destinations
        : [destinations];

    callbackRef.current = onMessage;

    useEffect(() => {
        const cleanUpFunctions: (() => void)[] = [];

        _destinations.forEach((_destination) =>
            cleanUpFunctions.push(
                stompContext.subscribe(
                    _destination,
                    (message) => {
                        callbackRef.current && callbackRef.current(message);
                    },
                    headers
                )
            )
        );

        return () => {
            cleanUpFunctions.forEach((_cleanUpFunction) => {
                _cleanUpFunction();
            });
        };
    }, [...Object.values(_destinations), ...Object.values(headers)]);
}

