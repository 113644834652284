import React from "react";
import ContractFilters from "./ContractFilters";
import {Col, Container, Row} from "react-bootstrap";
import translate from "../../../i18n/translate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";

const CreateFilters = () => {
    let history = useHistory();
    return (
        <Container fluid style={{ marginTop: 15, marginRight: "auto", paddingLeft: 15 }}>
            <Row className="mb-3">
                <Col className="pull-right">
                    <button className="iqx-button md-size pull-right" onClick={() => history.push("/public-sector/contracts")}>
                        <span className="me-2"><FontAwesomeIcon icon={faChevronLeft} /></span>
                        <span>{translate("contracts.back")}</span>
                    </button>
                </Col>
            </Row>

            <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10 }}>
                <div className="mb-5">
                    <ContractFilters/>
                </div>
            </div>
        </Container>
    );
};

export default CreateFilters;