import React from "react";
import {SelectOption} from "../../components/news/GlobalNews";

const NewsFiltersContext = React.createContext({
    showFiltersModal: false,
    setShowFiltersModal: (show: boolean) => {},
    sectorOptions: [] as SelectOption[],
    setSectorOptions: (options: SelectOption[]) => {},
    topicOptions: [] as SelectOption[],
    setTopicOptions: (options: SelectOption[]) => {},
    topics: [] as string[],
    setTopics: (filters: string[]) => {},
    sectors: [] as string[],
    setSectors: (filters: string[]) => {},
    terms: [] as string[],
    setTerms: (filters: string[]) => {}
});

export default NewsFiltersContext;