import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Alert, Form, Image, Row, Table} from 'react-bootstrap';
import axios from 'axios';
import {organisationHostname} from '../../../utils/Configuration';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faChevronCircleDown,
    faChevronCircleUp,
    faMinusCircle,
    faSort,
    faSortAmountDown,
    faSortAmountDownAlt
} from '@fortawesome/free-solid-svg-icons';
import Organisation from '../../../model/organisation/Organisation';
import Pagination from '../../../utils/PaginationHook';
import IqLoadingIcon from '../../common/IqLoadingIcon';


enum Sort {
    TURNOVER = "fs.turnover",
    ONE_YEAR_CAGR = "fs.numTurnoverChange",
    THREE_YEAR_CAGR = "fs.numThreeYearCagr",
    COMPANY_NAME = "organisation.companyName",
    ACCREDITATION = "organisationAccreditation.name",
    PARTNER_TYPE = "organisation.primaryBusinessType",
}

const OrganisationChannelPartnersList: React.FC = () => {

    interface Partner extends Organisation {
        accreditationName: string
    }

    let { organisationId } = useParams<{organisationId: string}>();
    const [loadingOrganisations, setLoadingOrganisations] = useState<boolean>(false);
    const [totalRecords, setTotalRecords] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, setSort] = useState<Sort>();
    const [desc, setDesc] = useState<boolean>();
    const [partners, setPartners] = useState<Partner[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);

    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== pageSize) {
            setPageSize(size);
        }
    };

    const CompanyLogo: React.FC<Partner> = (data: Partner) => {
        const [src, setSrc] = useState<string>(data.imageUrl ? data.imageUrl : "./icons_company.svg");

        return (
            <Image title={data.companyName}
                style={{
                    height: "30px",
                    width: "30px",
                    objectFit: "cover"
                }}
                src={src}
                onError={() => setSrc("/icons_company.svg")}
                roundedCircle />
        )
    };

    const formatTurnover = (org: Organisation): string => {

        let currType = org.countryCode == "UK" ? "GBP" : "EUR"

        let formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: currType,
            minimumFractionDigits: 0
        });

        if (org.financialSummary.length === 0 || !org.financialSummary[0].turnover) {
            return "-"
        }
        return formatter.format(org.financialSummary[0].turnover);
    };

    const getGrowthIcon = (growth: number) => {
        if (growth > 0) {
            return (<FontAwesomeIcon icon={faChevronCircleUp} style={{ color: "#66d32f", paddingBottom: 2 }} size="sm" />)
        } else if (growth < 0) {
            return (<FontAwesomeIcon icon={faChevronCircleDown} style={{ color: "red", paddingBottom: 2 }} size="sm" />);
        } else {
            return (<FontAwesomeIcon icon={faMinusCircle} style={{ color: "#ADADAD", paddingBottom: 2 }} size="sm" />);
        }
    };

    useEffect(() => {
        const fetchOrganisations = async () => {
            setLoadingOrganisations(true);
            let params: any = {
                page: currentPage - 1,
                size: pageSize
            };

            if (sort) {
                params["sort"] = sort + "," + (desc ? "DESC" : "ASC");
            }

            await axios.get(organisationHostname + "organisation/" + organisationId + "/channel-partners", { params })
                .then(r => {
                    const totalCount: number = r.data.totalElements;
                    setTotalRecords(totalCount);
                    setPartners(r.data.content)
                })
                .catch(() => { })
                .finally(() => setLoadingOrganisations(false));
        };

        fetchOrganisations();
    }, [currentPage, sort, desc, pageSize]);

    const getSortButton = (s: Sort) => {
        if (s === sort) {
            return (
                <a className="sort-button pull-right"
                    onClick={() => {
                        setDesc(!desc);
                    }}>
                    <FontAwesomeIcon icon={desc ? faSortAmountDown : faSortAmountDownAlt} />
                </a>
            );
        }

        return (
            <a className="sort-button pull-right"
                onClick={() => {
                    setSort(s);
                    setDesc(true);
                }}>
                <FontAwesomeIcon icon={faSort} />
            </a>
        );
    };

    const getOrganisationLink = (organisationId: string) => {
        return `/organisation/${organisationId}`;
    };

    return (
        <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10, width: "100%" }}>
            {!(partners.length === 0) && (
                <Table striped hover size="sm">
                    <thead>
                        <tr>
                            <th className="text-center">&nbsp;</th>
                            <th style={{ width: "35%" }}>
                                Company Name
                                {getSortButton(Sort.COMPANY_NAME)}
                            </th>
                            <th className="text-end">
                                Accreditation
                                {getSortButton(Sort.ACCREDITATION)}
                            </th>
                            <th className="text-end">
                                Type
                                {getSortButton(Sort.PARTNER_TYPE)}
                            </th>
                            <th className="text-end">
                                Revenue
                                {getSortButton(Sort.TURNOVER)}
                            </th>
                            <th className="text-end">
                                1 year CAGR
                                {getSortButton(Sort.ONE_YEAR_CAGR)}
                            </th>
                            <th className="text-end">
                                3 year CAGR
                                {getSortButton(Sort.THREE_YEAR_CAGR)}
                            </th>
                        </tr>
                    </thead>

                    {!loadingOrganisations && (
                        <tbody>
                            {
                                partners.map((org: Partner) => (
                                    <tr key={org.id + org.accreditationName}>
                                        <td className="text-center">
                                            <CompanyLogo {...org} />
                                        </td>
                                        <td>
                                            <a className="iqx-link" href={getOrganisationLink(org.id)}>
                                                {org.companyName}
                                            </a>
                                        </td>
                                        <td className="text-end">{org.accreditationName}</td>
                                        <td className="text-end">
                                            {org.primaryType}
                                        </td>
                                        <td className="text-end">
                                            {org.financialSummary[0]?.turnover ? (
                                                <span>{formatTurnover(org)}</span>
                                            ) : (
                                                <span>
                                                    -
                                                </span>
                                            )}</td>
                                        <td className="text-end">
                                            {org.financialSummary[0]?.turnoverChange ? (
                                                <span>{(Math.round(org.financialSummary[0]?.turnoverChange * 100) / 100).toFixed(2)} {getGrowthIcon(org.financialSummary[0]?.turnoverChange)}</span>
                                            ) : (
                                                <span>
                                                    -
                                                </span>
                                            )}
                                        </td>
                                        <td className="text-end">
                                            {org.financialSummary[0]?.threeYearCagr ? (
                                                <span>{
                                                (Math.round(org.financialSummary[0]?.threeYearCagr * 100) / 100).toFixed(2)
                                                } {getGrowthIcon(org.financialSummary[0]?.threeYearCagr)}</span>
                                            ) : (
                                                <span>
                                                    -
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    )}
                </Table>
            )}

            {loadingOrganisations ? <IqLoadingIcon /> :
                <Row className="justify-content-md-end pe-3">
                    <span className="me-2">
                        <Form.Control as="select"
                                      className=""
                                      defaultValue={pageSize}
                                      onChange={(event) => changePageSize(event)}
                                      >

                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                        </Form.Control>
                    </span>

                    <span>
                        <Pagination
                            totalRecords={totalRecords}
                            pageLimit={pageSize}
                            pageRangeDisplayed={1}
                            initPage={currentPage}
                            onChangePage={setCurrentPage}
                        />
                    </span>
                </Row>
            }

            {totalRecords === 0 && (
                <div className="justify-content-md-center">
                    <Alert variant="warning">
                        This company does not have channel partners.
                    </Alert>
                </div>)
            }

        </div>
    );
}

export default OrganisationChannelPartnersList;