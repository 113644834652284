import axios from "axios";
import React, {useState} from "react";
import {contractsHostName} from "../../../utils/Configuration";
import {Spinner} from "react-bootstrap";

function FollowContractButtonFake(props: { contractReleaseId: string; isAlreadyFollowedFlag: boolean; }) {
    const [following, setFollowing] = useState<boolean>(props.isAlreadyFollowedFlag);
    const [showLoading, setShowLoading] = useState<boolean>(false);

    const toggleFollow = async (contractId: string) => {
        let baseUrl = `${contractsHostName}mysql/follow/contracts/${contractId}/follow`;
        if (following) {
            setShowLoading(true);
            // Unfollow a Contract, note the DELETE request
            await axios.delete(baseUrl)
                .then(() => {
                    setFollowing(false);
                    setShowLoading(false);
                });
        } else {
            setShowLoading(true);
            // Follow a Contract, note the PUT request
            await axios.put(baseUrl)
                .then(() => {
                    setFollowing(true);
                    setShowLoading(false);
                });
        }
    };

    return (
        <div className="follow-button mb-1" onClick={() => toggleFollow(props.contractReleaseId)}>
                <span>
                    {showLoading ?
                        <Spinner style={{   display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            height: "1.5rem",
                            width: "1.5rem",}}
                                 animation="border"
                                 variant="primary"/>
                        :
                        following ?
                            <i className="fa fa-star fa-lg" style={{ color: "#2F6FC3" }} />
                            :
                            <i className="fa fa-star-o fa-lg" style={{ color: "#2F6FC3" }} />
                    }
                </span>
        </div>
    );
}

export default FollowContractButtonFake;