import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {socialHostName} from "../../utils/Configuration";
import {Alert, Col, Form, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faSort,
    faSortAmountDown,
    faSortAmountDownAlt,
    faSync,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import IqLoadingIcon from "../common/IqLoadingIcon";
import HubSpotDeal from "../../model/hubspot/HubSpotDeal";
import qs from "qs";
import DateFormatter from "../../utils/formatters/DateFormatter";
import {formatLocale, FormatLocaleDefinition} from "d3-format";
import I18nContext from "../../context/I18nContext";
import Pagination from "../../utils/PaginationHook";

enum Sort {
    NAME = "name",
    DEAL_STAGE = "dealStage",
    AMOUNT = "amount",
    CLOSED_DATE = "closedDate",
    CREATED_DATE = "createdDate",
    UPDATED_DATE= "updatedDate"
}

const HubSpotDeals: React.FC = () => {

    const [deals, setDeals] = useState<HubSpotDeal[]>([]);
    const [totalRecords, setTotalRecords] = useState<number>();

    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [sort, setSort] = useState<Sort>();
    const [desc, setDesc] = useState<boolean>();
    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== pageSize) {
            setPageSize(size);
        }
    };

    const [loading, setLoading] = useState<boolean>(false);
    const [syncInProgress, setSyncInProgress] = useState<boolean>(false);
    const syncDeals = () => {
        setLoading(true);
        axios.get(socialHostName + "hubspot/fetch-deals")
            .then(() => setSyncInProgress(true))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchDeals();
    }, [page, pageSize, sort, desc]);

    const fetchDeals = async () => {
        setLoading(true);
        let params: any = {
            page: page - 1,
            size: pageSize
        };

        if (sort) {
            params["sort"] = sort + "," + (desc ? "desc" : "asc");
        }

        await axios.get(socialHostName + "hubspot/deals", {
            params: params,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            }
        })
            .then(r => {
                setDeals(r.data.content);
                setTotalRecords(r.data.totalElements);
            })
            .finally(() => setLoading(false));
    };

    const getSortButton = (s: Sort) => {
        if (s === sort) {
            return (
                <a className="sort-button"
                   onClick={() => {
                       setDesc(!desc);
                   }}>
                    <FontAwesomeIcon icon={desc ? faSortAmountDown : faSortAmountDownAlt} />
                </a>
            );
        }

        return (
            <a className="sort-button"
               onClick={() => {
                   setSort(s);
                   setDesc(true);
               }}>
                <FontAwesomeIcon icon={faSort} />
            </a>
        );
    };

    const { lang } = useContext(I18nContext);
    let UK = formatLocale({
        currency: ["£ ", ""]
    } as FormatLocaleDefinition);

    let EU = formatLocale({
        currency: ["€", ""]
    } as FormatLocaleDefinition);

    const formatAmount = (amount: string) => {
        if (amount) {
            return lang === "en" ? UK.format('$,.3s')(+amount).replace(/G/, "B") : EU.format('$,.3s')(+amount).replace(/G/, "B");
        }

        return "-";
    };

    return (
        <div style={{
            paddingTop: "10px",
            backgroundColor: "white",
            borderRadius: "0.45rem",
            padding: 15,
            marginTop: 10
        }}>
            <Row>
                <Col className="pt-2" lg={4} md={5} sm={3} xs={3}>
                    <span style={{marginLeft: "auto"}}>
                        <button onClick={syncDeals} className="iqx-button md-size dark-blue">
                            <span style={{paddingRight: 10}}>Sync HubSpot Deals</span>
                            <FontAwesomeIcon icon={faSync} color="white" size="1x"/>
                        </button>
                    </span>
                </Col>
            </Row>
            <div className="mt-3">
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>
                                Name
                                {getSortButton(Sort.NAME)}
                            </th>
                            <th>
                                Deal Stage
                                {getSortButton(Sort.DEAL_STAGE)}
                            </th>
                            <th>
                                Amount
                                {getSortButton(Sort.AMOUNT)}
                            </th>
                            <th className="text-center">
                                Archived
                            </th>
                            <th>
                                Created Date
                                {getSortButton(Sort.CREATED_DATE)}
                            </th>
                            <th>
                                Updated Date
                                {getSortButton(Sort.UPDATED_DATE)}
                            </th>
                            <th>
                                Closed Date
                                {getSortButton(Sort.CLOSED_DATE)}
                            </th>
                        </tr>
                    </thead>
                    {!loading && (
                        <tbody>
                            {deals.map((deal: HubSpotDeal) => (
                                <tr key={deal.id}>
                                    <td>{deal.name}</td>
                                    <td>{deal.dealStage ? deal.dealStage : "-"}</td>
                                    <td>{formatAmount(deal.amount)}</td>
                                    <td className="text-center">{deal.archived ? <FontAwesomeIcon icon={faCheckCircle} color="#2bcb14"/> : <FontAwesomeIcon icon={faTimesCircle} color="#a82509" />}</td>
                                    <td>{DateFormatter.formatDate(deal.createdDate)}</td>
                                    <td>{DateFormatter.formatDate(deal.updatedDate)}</td>
                                    <td>{DateFormatter.formatDate(deal.closedDate)}</td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                </Table>

                {loading && <IqLoadingIcon />}

                {syncInProgress && (
                    <Alert variant="info" style={{ width: "100%", marginTop: 25 }}>
                        Deals sync in progress. Please check later.
                    </Alert>
                )}

                <div className="mt-4">
                    <div className="justify-content-md-end pagination mt-n2">
                        <span className="me-2">
                            <Form.Control as="select"
                                          style={{ minWidth: "13%" }}
                                          defaultValue={pageSize}
                                          onChange={(event) => changePageSize(event)}
                                          >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                            </Form.Control>
                        </span>
                        <span>
                            {totalRecords && (
                                <Pagination totalRecords={totalRecords}
                                            pageLimit={pageSize}
                                            pageRangeDisplayed={1}
                                            initPage={page}
                                            onChangePage={setPage}
                                />
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HubSpotDeals;