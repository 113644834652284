import React, {useContext, useState} from "react";
import PublicSectorContext, {GCloudFilters} from "../../../context/PublicSectorContext";
import translate from "../../../i18n/translate";
import {Form, Modal} from "react-bootstrap";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faSave} from "@fortawesome/free-solid-svg-icons";
import {SelectOption} from "../../news/GlobalNews";

const frameworkOptions: SelectOption[] = [
    {
        label: "G-Cloud 12",
        value: "G-Cloud 12"
    },
    {
        label: "G-Cloud 13",
        value: "G-Cloud 13"
    }
];

const GCloudFiltersModal: React.FC = () => {
    const {gCloudFilters, setGCloudFilters} = useContext(PublicSectorContext);

    const [searchTerm, setSearchTerm] = useState<string>(gCloudFilters.searchTerm);
    const [frameworks, setFrameworks] = useState<string[]>(gCloudFilters?.frameworks);
    const updateFrameworks = (e: SelectOption[]) => {
        if (e) {
            let selection = e.map(opt => opt.value);
            setFrameworks(selection);
        }
    };

    const applyFilters = () => {
        let f: GCloudFilters = {
            searchTerm: searchTerm,
            frameworks: frameworks
        };

        setGCloudFilters(f);
    };

    const clearFilters = () => {
        setGCloudFilters({} as GCloudFilters);
    };

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Filters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
                    <div>
                        <div className="organisation-card-label">Search Term</div>
                        <Form.Control name="key" type="text"
                                      placeholder={translate("advancedsearch.search")}
                                      value={searchTerm}
                                      onChange={(e: any) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div>
                        <div className="organisation-card-label">
                            Framework
                        </div>
                        <Select options={frameworkOptions as any[]}
                                isClearable
                                isMulti
                                defaultValue={frameworks?.map(o => ({label: o, value: o}))!}
                                onChange={(e: any) => updateFrameworks(e)}/>
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="iqx-button secondary md-size me-3"
                        disabled={frameworks?.length === 0 && !searchTerm}
                        onClick={clearFilters}>
                    Clear Filters
                    <FontAwesomeIcon icon={faFilter} className="ms-2"/>
                </button>

                <button className="iqx-button primary md-size" onClick={applyFilters}>
                    Apply
                    <FontAwesomeIcon icon={faSave} className="me-1 ms-2" />
                </button>
            </Modal.Footer>
        </>
    );
};

export default GCloudFiltersModal;