import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import './AdminUsersContainer.css';
import AdminUsersTable from './AdminUsersTable';

import axios, {AxiosRequestConfig} from 'axios';
import {frontEndHostName} from '../../utils/Configuration';
import FileDownload from "js-file-download";
import NewUserForm from "./NewUserForm";

const AdminUsersContainer: React.FC = () => {

    const [modalShow, setModalShow] = useState(false);


    const AdminNewUserModal: React.FC<any> = (props: any) => {
        const [isCreated, setIsCreated] = useState<boolean>(false);

       const handleSetCreated = (created: boolean) => {
         setIsCreated(created)
       }
      return (
            <Modal {...props}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create a new user
                    </Modal.Title>
                </Modal.Header>
                {isCreated ? (
                    <Modal.Body>
                        The account has been created successfully.
                    </Modal.Body>
                ) : (
                    <Modal.Body>
                    <NewUserForm isCreated={handleSetCreated} closeProp={modalShow}/>
                    </Modal.Body>
                )}
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        );
    };

    const exportCsv = () => {
        let requestConfig = {
            responseType: "blob"
        } as AxiosRequestConfig;

        axios.post(frontEndHostName + "users/export", requestConfig)
            .then(r => {
                let now = new Date().toLocaleDateString();
                let fileName = `user_data-${now}.csv`;
                FileDownload(r.data, fileName);
            });
    };

    return (
        <div className="admin-users-container">
            <AdminNewUserModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <span className="me-2">
                <Button variant="primary" onClick={() => setModalShow(true)}>Create a new user</Button>
            </span>
            <span className="me-2">
                <Button variant="outline-primary" onClick={() => exportCsv()}>
                    Export Active Users
                </Button>
            </span>
            <AdminUsersTable showRegisterModal={modalShow} />
        </div>
    );
};

export default AdminUsersContainer;
