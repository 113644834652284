import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import axios from "axios";
import {frontEndHostName, organisationHostname} from "../../utils/Configuration";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {getUserUuid} from "../../utils/Security";
import IqLoadingIcon from "../common/IqLoadingIcon";
import Organisation from "../../model/organisation/Organisation";

interface Props {
    show: boolean,
    onHide: () => void
}

const NewFreemiumUserModal: React.FC<any> = (props: any) => {
    const { register, errors, getValues, triggerValidation } = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    });

    const [loadingUser, setLoadingUser] = useState<boolean>(false);
    const [organisationId, setOrganisationId] = useState<string>();
    useEffect(() => {
        const loadUser = async () => {
            setLoadingUser(true);
            await axios.get(frontEndHostName + "users/" + getUserUuid())
                .then(r => {
                    updateOrganisation(r.data.organisationId);
                    setOrganisationId(r.data.organisationId);
                })
                .finally(() => setLoadingUser(false));
        };

        loadUser();
    }, []);

    const [loadingOrganisation, setLoadingOrganisation] = useState<boolean>(false);
    const [org, setOrg] = useState<Organisation>();
    useEffect(() => {
        const fetchOrganisation = async () => {
            setLoadingOrganisation(true);
            await axios.post(organisationHostname + "organisation/find-all-minified-by-id", {
                    ids: [organisationId]
                })
                .then(r => setOrg(r.data[0]))
                .finally(() => setLoadingOrganisation(false));
        };

        fetchOrganisation();
    }, [organisationId]);

    const [isCreated, setCreated] = useState(false);
    const [responseError, setResponseError] = useState("");

    const [password, setPassword] = useState<string>(Math.random().toString(36).slice(-8));
    const [organisation, setOrganisation] = useState<string>("");
    const [domains, setDomains] = useState<string[]>([]);
    const updateOrganisation = (value: string) => {
        setOrganisation(value);
        fetchDomains(value);
    };

    const fetchDomains = async (organisationId: string) => {
        await axios.get(organisationHostname + "organisation/" + organisationId + "/domains")
            .then(r => {
                setDomains(r.data);
            });
    };

    const saveUser = () => {
        let user = {
            login: getValues("login"),
            email: getValues("email"),
            organisationId: organisation,
            firstName: getValues("firstName"),
            lastName: getValues("lastName")
        };

        let postData = {
            user: user,
            password: password
        };

        axios.post(frontEndHostName + 'users/register-freemium', postData,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(() => setCreated(true))
            .catch(error => {
                let responseData = error.response.data;
                switch (responseData.errorKey) {
                    case "userexists": {
                        setResponseError("Username already exists.");
                        break;
                    }
                    case "emailexists": {
                        setResponseError("Email already exists.");
                        break;
                    }
                    default: {
                        setResponseError("Could not create the user account. Try again.");
                    }
                }
            });
    };

    const submit = () => {
        triggerValidation()
            .then(success => {
                if (success) {
                    saveUser();
                }
            });
    };

    return (
        <Modal show={props.show}
               size="lg"
               aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create a new user
                </Modal.Title>
            </Modal.Header>
            {isCreated ? (
                <Modal.Body>
                    The account has been created successfully.
                </Modal.Body>
            ) : (
                <Modal.Body>
                    {loadingUser ? <IqLoadingIcon /> : (
                        <>
                            <Form.Group controlId="formUserName">
                                <Form.Label>Username</Form.Label>
                                <Form.Control name="login"
                                              type="text"
                                              placeholder="Enter username"
                                              ref={register({ required: true, minLength: 1, maxLength: 50 })}
                                              isInvalid={errors.login} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.login && errors.login.type === "required" && "Please choose a username."}
                                    {errors.login && errors.login.type === "minLength" && "Minimum length of username is 1."}
                                    {errors.login && errors.login.type === "maxLength" && "Maximum length of username is 50."}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formFirstName">
                                <Form.Label>First name</Form.Label>
                                <Form.Control name="firstName" type="text" placeholder="Enter first name"
                                              ref={register({ required: true, maxLength: 50 })}
                                              isInvalid={errors.firstName} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.firstName && errors.firstName.type === "required" && "Please fill in the first name."}
                                    {errors.firstName && errors.firstName.type === "maxLength" && "Maximum length of first name is 50."}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formLastName">
                                <Form.Label>Last name</Form.Label>
                                <Form.Control name="lastName" type="text" placeholder="Enter last name"
                                              ref={register({ required: true, maxLength: 50 })}
                                              isInvalid={errors.lastName} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.lastName && errors.lastName.type === "required" && "Please fill in the last name."}
                                    {errors.lastName && errors.lastName.type === "maxLength" && "Maximum length of last name is 50."}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formOrganisationId">
                                <Form.Label>Organisation</Form.Label>

                                {loadingOrganisation ? <IqLoadingIcon /> : (
                                    <Form.Control name="organisation" type="text" value={org?.companyName} disabled/>
                                )}
                            </Form.Group>

                            <Form.Group controlId="formEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control name="email" type="email" placeholder="Enter email"
                                              ref={register({
                                                  required: true,
                                                  minLength: 5,
                                                  maxLength: 254,
                                                  validate: {
                                                      validDomain: (value: string) => domains.some((d: string) => value.endsWith(d))
                                                  }
                                              })}
                                              isInvalid={errors.email} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email && errors.email.type === "required" && "Please fill in the email address."}
                                    {errors.email && errors.email.type === "minLength" && "Minimum length of email is 5."}
                                    {errors.email && errors.email.type === "maxLength" && "Maximum length of email is 254."}
                                    {errors.email && errors.email.type === "validDomain" && `Email domain must match a company domain: ${domains.join(", ")}`}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formPassword">
                                <Form.Label>Autogenerated Password</Form.Label>
                                <Form.Control name="password" type="text" value={password} readOnly />
                                <Form.Text className="text-muted">
                                    Save this password before submit. You will thank me later.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Allow IQBlade to send promotional materials" />
                            </Form.Group>
                        </>
                    )}

                    {responseError !== "" &&
                    <Alert variant="danger">
                        {responseError}
                    </Alert>
                    }
                </Modal.Body>
            )}
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Close</Button>
                {!isCreated && (
                    <Button variant="success" type="button" onClick={() => submit()}>
                        Submit
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default NewFreemiumUserModal;