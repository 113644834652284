import React from 'react';
import {Container} from 'react-bootstrap';
import {Route, useHistory, useRouteMatch} from 'react-router-dom';
import './Campaigns.css';
import ManagedCampaignView from './ManagedCampaignView';

const ManagedCampaigns: React.FC = () => {

    let match = useRouteMatch();
    let history = useHistory();

    return (
        <Container fluid style={{ marginTop: 15 }}>
            <Route exact path={`${match.url}/:campaignId`}>
                <ManagedCampaignView />
            </Route>
        </Container>
    );
};

export default ManagedCampaigns;