import {faVolumeMute, faVolumeUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, {useContext, useEffect, useState} from 'react';
import {Spinner} from 'react-bootstrap';
import {useQuery} from 'react-query';
import styled from 'styled-components';
import {Message} from 'webstomp-client';
import {useSubscription} from '../../hooks/useSubscription';
import {EventType, Notification} from '../../model/notification/Notification';
import {notificationHostName} from '../../utils/Configuration';
import AdvancedSearchShareNotification from './items/AdvancedSearchShareNotification';
import ContactsUpdatedNotification from './items/ContactsUpdatedNotification';
import ListAdditionNotification from './items/ListAdditionNotification';
import ListShareNotification from './items/ListShareNotification';
import OrganisationUpdatedNotification from './items/OrganisationUpdatedNotification';
import ShareWithTeamNotification from './items/ShareWithTeamNotification';
import TeamCreationNotification from './items/TeamCreationNotification';
import TeamUserAdditionNotification from './items/TeamUserAdditionNotification';
import NotificationContext from './NotificationContext';
import OrganisationResearchRequestNotification from "./items/OrganisationResearchRequestNotification";
import EventsSinceLastLoginNotification from './items/EventsSinceLastLoginNotification';
import JobsUpdatedNotification from './items/JobsUpdatedNotification';
import SecFilingsUpdateNotification from './items/SecFilingsUpdateNotification';


const StyledNotifications = styled.div.attrs({
    className: 'notification-dropdown',
    id: 'notification-dropdown'
})`
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 0.4em;
  width: 320px;
  height: 45vh;
  background-color: white;
  color: black;
  z-index: 9999;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.35rem;
  gap: 0.4em;
  overflow-y: scroll;

  @media (min-width: 576px) {
    top: 3.8em;  
    right: 2.5em;
  }
  
  @media (max-width: 575px) {
    top: 5em;
    right: 2.5em;
  }
`;

const NotificationCloseable = styled.div`
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9998;
`;

export const Item = styled.div<{ read: boolean }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.9em;
    background-color: ${props => props.read ? "white" : "rgba(29, 155, 240, 0.1)"};
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.35rem;
    padding: 0.4em;
    gap: 0.8em;
    width: 100%;
  `;

export const Icon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 25px;
    width: 25px;
    background-color: #3378D1;
    border-radius: 50%;
    flex-shrink: 0;
`;

export const ItemContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Text = styled.div`
    text-align: left;
    font-weight: 300;
    font-family: Roboto;
`;

export const DateText = styled.div`
    text-align: left;
    font-weight: 300;
    font-size: 0.75em;
    font-family: Roboto;
`;

export const Highlight = styled.div`
    display: inline;
    font-weight: 500;

    &:hover {
        color: orange;
    }
`;

const Title = styled.div`
    display: flex;
    justify-content: flex-start;
    font-weight: 700;
    font-size: 1.2em;
`;

const Subtitle = styled.div`
    display: flex;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 1.0em;
`;

const Volume = styled.div`
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    cursor: pointer;
`

enum NotificationPeriod {
    OLDER,
    RECENT
}

interface Notifications {
    show: boolean,
    setShow: any
}

export interface NotificationItem {
    element: Notification
}

const notificationSound = new Audio('/notification.wav');

const Notifications: React.FC<Notifications> = (props: Notifications) => {

    const [currentPage, setCurrentPage] = useState<number>(0);
    const NOTIFICATIONS_PER_PAGE = 10;
    const NOTIFICATIONS_SORT = "modified,desc";
    const [recentStoreMap, setRecentStoreMap] = useState(new Map<string, Notification>());
    const { counter, setCounter } = useContext(NotificationContext);
    const [muted, setMuted] = useState<boolean>(localStorage.getItem("muted") === 'true');

    useEffect(() => {
        localStorage.setItem("muted", muted.toString());
    }, [muted])

    const updateRecentStoreMap = (k: any, v: any) => {
        setRecentStoreMap(new Map(recentStoreMap.set(k, v)));
        setCounter(counter + 1);
    };

    const subscription = useSubscription(
        "/user/topic/notifications",
        (message: Message) => {
            const n = JSON.parse(message.body) as Notification
            console.log(n)
            !muted && notificationSound.play();
            updateRecentStoreMap(n.id, n);
        },
        { ack: undefined })

    const query = useQuery(["notifications", currentPage], async () => {

        return await axios.get([notificationHostName, `notifications`].join(""), {
            params: {
                sort: NOTIFICATIONS_SORT,
                page: currentPage,
                size: NOTIFICATIONS_PER_PAGE
            }
        }).then(res => res.data)
    }, {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: Infinity,
    })

    const readAllNotifications = () => axios.put([notificationHostName, `notifications/readAll`].join("")).then(res => res.data)

    const countQuery = useQuery(["notificationsCount"], async () => {

        return await axios.get([notificationHostName, `notifications/count/unread`].join(""))
            .then(res => res.data)
    }, {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: Infinity,
    })

    useEffect(() => {
        if (props.show) {
            readAllNotifications()
            setCounter(0)
        }
    }, [props.show])

    useEffect(() => {
        setCounter(countQuery.data)
    }, [countQuery.data])

    useEffect(() => {
        document.title = ["IQBlade", ...(counter > 0 ? [['(', counter, ')'].join('')] : [])].join(" ")
    })

    const generateNotifications = (type: NotificationPeriod) => {

        const array = type === NotificationPeriod.OLDER ? query.data.content : Array.from(recentStoreMap.values()).sort((a, b) =>
            new Date(b.modified)?.getTime() - new Date(a.modified)?.getTime()
        )
        return (
            array?.map((element: Notification) => {
                switch (element?.eventType) {
                    case EventType.ORGANISATION_UPDATED:
                        return (<OrganisationUpdatedNotification element={element} />)
                    case EventType.CONTACTS_UPDATED:
                        return (<ContactsUpdatedNotification element={element} />)
                    case EventType.JOBS_UPDATED:
                        return (<JobsUpdatedNotification element={element} />)
                    case EventType.TEAM_CREATION:
                        return (<TeamCreationNotification element={element} />)
                    case EventType.TEAM_USER_ADDITION:
                        return (<TeamUserAdditionNotification element={element} />)
                    case EventType.ADVANCED_SEARCH_SHARE:
                        return (<AdvancedSearchShareNotification element={element} />)
                    case EventType.LIST_SHARE:
                        return (<ListShareNotification element={element} />)
                    case EventType.LIST_ADDITION:
                        return (<ListAdditionNotification element={element} />)
                    case EventType.SHARE_WITH_TEAM:
                        return (<ShareWithTeamNotification element={element} />)
                    case EventType.ORGANISATION_RESEARCH_REQUEST:
                        return (<OrganisationResearchRequestNotification element={element} />)
                    case EventType.EVENTS_SINCE_LAST_LOGIN:
                        return (<EventsSinceLastLoginNotification element={element} />)
                    case EventType.SEC_FILINGS_UPDATE:
                        return (<SecFilingsUpdateNotification element={element}/>)

                }
            })
        );
    }

    return (
        <>
            {props.show &&
                    <StyledNotifications onClick={(event: React.MouseEvent) => { }}>
                        <Volume>
                            <FontAwesomeIcon
                                icon={muted ? faVolumeMute : faVolumeUp}
                                onClick={() => setMuted(!muted)} />
                        </Volume>
                        < Title > Notifications</Title >
                        {recentStoreMap.size > 0 && <Subtitle>Recent</Subtitle>}
                        {generateNotifications(NotificationPeriod.RECENT)}
                        {recentStoreMap.size > 0 && <Subtitle>Older</Subtitle>}
                        {
                            query.isFetching && <Spinner style={{ display: "inline-block", marginLeft: "auto", marginRight: "auto", height: "2rem", width: "2rem" }}
                                                         animation="border"
                                                         variant="primary" />
                        }
                        {query.isFetched && generateNotifications(NotificationPeriod.OLDER)}
                    </StyledNotifications >
            }

            {props.show && <NotificationCloseable onClick={() => props.setShow(false)} />}
        </>

    );
};

export default Notifications;
