import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {Placement} from "react-bootstrap/types";


interface ButtonProps {
    onClick: Function;
    icon: IconProp;
    tooltip: string;
    tooltipPosition: Placement;
    disabled?: boolean;
}


const EnhancedButton: React.FC<ButtonProps> = ({onClick, icon, tooltip, tooltipPosition, disabled= false}) => {

    return (
        <OverlayTrigger overlay={<Tooltip id={"enhanced-button-tooltip"}>{tooltip}</Tooltip>} delay={{
            show: 0,
            hide: 250
        }} placement={tooltipPosition}>
            <button
                className="iq-button iq-button-primary me-2"
                disabled={disabled}
                onClick={() => onClick()}>
                <FontAwesomeIcon icon={icon} size="1x"/>
            </button>
        </OverlayTrigger>
    )
}

export default EnhancedButton;