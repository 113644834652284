import React, {useContext, useEffect, useState} from 'react';
import './MainContainer.css';
import LoginForm from '../auth/LoginForm';
import PageNotFound from './PageNotFound'
import Lists from '../lists/Lists';
import Dashboard from "../dashboard/Dashboard";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import AdminContainer from '../admin/AdminContainer';
import {getUserTermsAgreed, hasAnyRole, hasUserRole, Role} from '../../utils/Security';
import LoginContext from '../../context/LoginContext'
import OrganisationContainer from '../organisation/OrganisationContainer';
import UserListContainer from "../lists/UserListContainer";
import SearchContainer from '../search/SearchContainer';
import OfficerContainer from "../officer/OfficerContainer";
import Teams from "../teams/Teams";
import TeamContainer from "../teams/TeamContainer";
import AdvancedSearchContainer from '../advanced-search/AdvancedSearchContainer';
import Logout from './Logout';
import TermsAgreement from '../onboarding/TermsAgreement';
import ProfileContainer from '../profile/ProfileContainer';
import ForgotPasswordForm from '../auth/ForgotPasswordForm';
import ResetPasswordForm from '../auth/ResetPasswordForm';
// import OrganisationSummaryTemporary from '../organisation/OrganisationSummaryTemporary';
import GlobalNews from "../news/GlobalNews";
import PublicSectorContract from "../publicsector/contracts/PublicSectorContract";
import LinkedInLanding from '../profile/LinkedInLanding';
import Campaigns from '../campaigns/Campaigns';
import OnboardingPreferencesModal from "../onboarding/OnboardingPreferencesModal";
import MarketIntelligenceContainer from "../marketintelligence/MarketIntelligenceContainer";
import ContactContainer from "../social/ContactContainer";
import ImportedContacts from "../contacts/ImportedContacts";
import CampaignsManager from '../campaigns/CampaignsManager';
import ManagedCampaigns from '../campaigns/ManagedCampaigns';
import TechSales from "../techsales/TechSales";
import HubSpotLanding from "../profile/HubSpotLanding";
import HubSpotDashboard from "../hubspot/HubSpotDashboard";
import ProfileSettings from "../profile/ProfileSettings";
import ProfileIntegration from "../profile/ProfileIntegration";
import CSVUpload from "../lists/CSVUpload";
import ContractsContainer from "../publicsector/contracts/ContractsContainer";
import NotificationContainer from '../notifications/NotificationContainer';
import RenewalsContainer from "../renewals/RenewalsContainer";
import PublicSectorContainer from "../publicsector/PublicSectorContainer";
import UserJobHome from '../jobs/UserJobHome';
import PeopleContainer from '../contacts/PeopleContainer';

interface Props {
    logged: boolean,
    setLogged: React.Dispatch<React.SetStateAction<boolean>>
}

export const hasTeamsPermission = () => {
    return hasAnyRole([Role.ADMIN, Role.CLIENT_ADMIN, Role.ADVANCED_ACCOUNT_MANAGER, Role.BASIC_ACCOUNT_MANAGER]);
};

const MainContainer: React.FC<Props> = (props: Props) => {

    const { logged, setLogged } = useContext(LoginContext);
    const [termsAgreed, setTermsAgreed] = useState(getUserTermsAgreed);

    const history = useHistory();

    useEffect(() => {
        setTermsAgreed(getUserTermsAgreed);
    }, [termsAgreed, logged])

    let agreement = 0;
    return (
        <div className={logged === true ? "main-container logged-in-bg" : "main-container logged-out-bg"}>
            <div style={{ padding: 15 }} className="main-container-content">
                {logged === false
                    ?
                    <div style={{ height: "100%" }}>
                        <Route path="/">
                            <Switch>
                                /**
                                Routes that are exception to the redirect system when unauthenticated
                                */
                                {/*<Route path="/organisation/temporary">*/}
                                {/*    <OrganisationSummaryTemporary />*/}
                                {/*</Route>*/}
                                <Route path="/forgot-password">
                                    <ForgotPasswordForm />
                                </Route>
                                <Route path="/reset-password">
                                    <ResetPasswordForm />
                                </Route>
                                /**
                                    Fallback to /login
                                 */
                                <Redirect
                                    to={{
                                        pathname: "/login"
                                    }}
                                />
                            </Switch>
                        </Route>
                        <Route path="/login">
                            <LoginForm setLogged={props.setLogged}></LoginForm>
                        </Route>
                    </div>
                    :
                    <Switch>
                        {termsAgreed == false &&
                            <Route path="/">
                                <TermsAgreement setTermsAgreed={setTermsAgreed} />
                            </Route>
                        }
                        <Route exact strict path="/">
                            <Redirect
                                to={{
                                    pathname: "/dashboard"
                                }}
                            />
                        </Route>
                        <Route path="/onboarding">
                            <OnboardingPreferencesModal shown={true} setShown={() => true} exitable={false} onCompleteCallback={() => history.push("/")}/>
                        </Route>
                        <Route path="/dashboard" component={Dashboard} />
                        <Route exact strict path="/profile" component={ProfileContainer} />
                        <Route exact strict path="/profile/settings" component={ProfileSettings} />
                        <Route exact strict path="/profile/integration" component={ProfileIntegration} />
                        <Route path="/profile/linkedin-landing" component={LinkedInLanding} />
                        <Route path="/profile/hubspot-landing" component={HubSpotLanding} />
                        {hasUserRole(Role.ADMIN) &&
                            <Route path="/admin" component={AdminContainer} />
                        }
                        <Route exact strict path="/lists" component={Lists} />
                        <Route exact strict path="/upload" component={CSVUpload} />
                        <Route path="/lists/" component={UserListContainer} />
                        <Route path="/organisation">
                            <OrganisationContainer />
                        </Route>
                        <Route path="/officer/" component={OfficerContainer} />
                        <Route path="/contact/" component={ContactContainer} />
                        <Route path="/notification/" component={NotificationContainer}/>
                        <Route path="/advanced-search">
                            <AdvancedSearchContainer />
                        </Route>
                        <Route path="/search">
                            <SearchContainer />
                        </Route>
                        {hasTeamsPermission() && <Route path="/teams" component={Teams} />}
                        {hasTeamsPermission() && <Route path="/team/" component={TeamContainer} />}

                        <Route path="/news" component={GlobalNews} />
                        <Route path="/marketintelligence" component={MarketIntelligenceContainer} />
                        <Route path="/public-sector" component={PublicSectorContainer} />
                        {/*{hasUserRole(Role.GEO_UK) && <Route exact strict path="/contracts" component={ContractList}/>}*/}
                        {/*{hasUserRole(Role.GEO_UK) && <Route exact strict path="/contracts/filter/create" component={CreateFilters}/>}*/}
                        {hasUserRole(Role.GEO_UK) && <Route path="/contracts/" component={ContractsContainer}/>}
                        {hasUserRole(Role.GEO_UK) && <Route path="/contract/" component={PublicSectorContract}/>}
                        <Route path="/people" component={PeopleContainer}/>
                        {hasUserRole(Role.TD_SALES) && <Route path="/tech-sales" component={TechSales} />}
                        {hasUserRole(Role.TD_RENEWALS) && <Route path="/renewals" component={RenewalsContainer}/> }
                        {hasUserRole(Role.CAMPAIGN_VIEWER) && <Route path="/campaigns" component={Campaigns} />}
                        {/* {(hasUserRole(Role.CAMPAIGN_VIEWER) || hasUserRole(Role.CAMPAIGN_RECIPIENT)) && <Route path="/campaigns" component={Campaigns} />} */}
                        {(hasUserRole(Role.CAMPAIGN_CREATOR) || hasUserRole(Role.CAMPAIGN_SUPERADMIN)) && <Route path="/campaigns-manager" component={CampaignsManager} />}
                        {hasUserRole(Role.CAMPAIGN_VIEWER) && <Route path="/managed-campaigns" component={ManagedCampaigns} />}
                        {hasUserRole(Role.HUBSPOT) && <Route path="/hubspot" component={HubSpotDashboard} /> }
                        <Route path="/logout" component={Logout} />
                        <Route component={PageNotFound} />
                    </Switch>
                }
            </div>
        </div>
    );
}

export default MainContainer;
