import React, {useContext, useEffect, useRef, useState} from "react";
import "./Dashboard.css";
import {Col, Container, Row} from "react-bootstrap";
import {Responsive, WidthProvider} from "react-grid-layout";
import DashboardItem from "./item/DashboardItem";
import AddModal from "./AddModal";
import Item from "./item/Item";
import ItemType from "./item/ItemType";
import DashboardContext from "../../context/dashboard/DashboardContext";
import {frontEndHostName} from "../../utils/Configuration";
import axios from "axios";
import IqLoadingIcon from "../common/IqLoadingIcon";
import translate from "../../i18n/translate";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export const MARGINS: [number, number] = [10, 30];
export const ROW_HEIGHT = 70;

const Dashboard: React.FC = () => {

    const [breakpoint, setBreakpoint] = useState();
    const [cols, setCols] = useState({
        lg: 12,
        md: 10,
        sm: 6,
        xs: 4,
        xxs: 2
    });

    const onBreakpointChange = (newBreakpoint: any, newCols: any) => {
        setBreakpoint(newBreakpoint);
        setCols(newCols);
    };

    let items = useRef<Item[]>([]);
    const setItems = (k: Item[]) => {
        items.current = k;
    };

    let dashboardId = useRef<number>();
    const setDashboardId = (id: number) => {
        dashboardId.current = id;
    };

    let userId = useRef<number>();
    const setUserId = (id: number) => {
        userId.current = id;
    };

    let updated = useRef<boolean>(false);
    const setUpdated = (isUpdated: boolean) => {
        updated.current = isUpdated;
    };


    const saveLayout = () => {
        let data = {
            id: dashboardId.current,
            user: userId.current,
            items: items.current
        };

        axios.post(frontEndHostName + "dashboard/save", data);
    };

    const onLayoutChange = (newLayout: Item[]) => {
        setLayout(newLayout);
        let newItems =
            newLayout.map((item: any) => {
                let split = item.i.split(".")
                return {
                    dashboardId: dashboardId.current,
                    i: item.i,
                    x: item.x,
                    y: item.y,
                    w: item.w,
                    h: item.h,
                    k: split[0]
                } as Item;
            });
        setItems(newItems);
        setUpdated(true);
    };

    const [loading, setLoading] = useState<boolean>(false);
    const [layout, setLayout] = useState<Item[]>([]);
    const [cachedUserDashboard, setCachedUserDashboard] = useState<any>();

    const beforeUnload = () => {
        let isUpdated = updated.current;
        if (isUpdated) {
            saveLayout();
        }
    };

    useEffect(() => {
        const fetchLayout = async () => {
            setLoading(true);
            await axios.get(frontEndHostName + "dashboard")
                .then(r => {
                    let userDashboard = r.data;
                    setCachedUserDashboard(userDashboard);
                    setDashboardId(userDashboard.id);
                    setUserId(userDashboard.user);
                    setLayout(userDashboard.items);
                })
                .catch(error => console.error(error))
                .finally(() => {
                    setLoading(false);
                    setUpdated(false);
                });
        };

        fetchLayout();

        window.addEventListener("resize", fetchLayout);
        window.addEventListener("beforeunload", beforeUnload);
        return () => {
            if (updated.current) {
                saveLayout();
            }
            window.removeEventListener("beforeunload", beforeUnload);
            window.removeEventListener("resize", fetchLayout)
        }

    }, []);

    const addItem = (type: ItemType) => {
        let item = {
            dashboardId: dashboardId.current,
            i: type.key + "." + (layout.length + 1).toString(),
            x: (layout.length * 3) % 12,
            y: Infinity, // puts it at the bottom
            w: 3,
            h: 3,
            k: type.key
        } as Item;

        setLayout([
            ...layout,
            item
        ]);
    };

    const removeItem = (key: string) => {
        let filteredItems = layout.filter((i: Item) => i.i !== key);
        onLayoutChange(filteredItems);
    };

    const widgetExists = (type: ItemType) => {
        let existingItem =
            layout.find(item => {
                let split = item.i.split(".")
                if (split[0] === "0" || split[0] === "5") {
                    return false;
                }
                return split[0] === type.key
            });

        return existingItem !== undefined;
    };

    const [showModal, setShowModalState] = useState<boolean>(false);
    const setShowModal = (show: boolean) => setShowModalState(show);

    const {newType, setNewType} = useContext(DashboardContext);

    useEffect(() => {

        if (layout.length === 0) {
            addItem(ItemType.WELCOME)
        }

        if (layout.length > 1) {
            removeItem("12.1")
        }
    }, [layout.length])

    return (
        <DashboardContext.Provider value={{showModal, setShowModal, newType, setNewType, layout, setLayout}}>
            <Container fluid style={{paddingLeft: 0, paddingRight: 0, overflowX: "hidden", marginBottom: 15}}>
                {loading ? <IqLoadingIcon/> : (
                    <div onTouchStart={e => e.stopPropagation()}>
                        <Row style={{paddingBottom: 5, marginBottom: 10}}>
                            <Col xs={12}>
                                <button className="iq-button iq-button-primary pull-right"
                                        title="Add item to your dashboard"
                                        onClick={() => setShowModal(true)}>
                                    {translate("dashboard.additem")}
                                    <i className="ps-2 fa fa-plus-circle fa-lg"/>
                                </button>
                            </Col>
                        </Row>
                        {layout.length > 0 ? (
                            <ResponsiveReactGridLayout
                                style={{minHeight: "80vh"}}
                                margin={MARGINS}
                                onLayoutChange={onLayoutChange}
                                rowHeight={ROW_HEIGHT}
                                compactType={"vertical"}
                                onBreakpointChange={onBreakpointChange}
                                draggableCancel={".nonIqbladeDraggableWidget"}
                            >

                                {layout.filter((el: Item) => el.i !== "null").map((el: Item) => {
                                    return (
                                        <div key={el.i} data-grid={el}>
                                            <DashboardItem element={el} remove={removeItem}/>
                                        </div>
                                    );
                                })}
                            </ResponsiveReactGridLayout>
                        ) : (
                            <div>
                                <h1>Welcome to your dashboard!</h1>
                                <p>Start by adding some widgets</p>
                                <button className="iq-button iq-button-primary"
                                        title="Add item to your dashboard"
                                        onClick={() => setShowModal(true)}>
                                    <span className="ps-2 pe-2">Start here!</span>
                                </button>
                            </div>
                        )}

                        <AddModal addItem={(type: ItemType) => addItem(type)} widgetExists={widgetExists}/>
                    </div>
                )}
            </Container>
        </DashboardContext.Provider>
    );
};

export default Dashboard;
