import React, {useEffect, useRef, useState} from "react";
import HubSpotContact from "../../model/hubspot/HubSpotContact";
import {Alert, Col, Form, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faSort,
    faSortAmountDown,
    faSortAmountDownAlt,
    faSync,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {socialHostName} from "../../utils/Configuration";
import axios from "axios";
import IqLoadingIcon from "../common/IqLoadingIcon";
import DateFormatter from "../../utils/formatters/DateFormatter";
import Pagination from "../../utils/PaginationHook";
import Select from "react-select";
import {SelectOption} from "../news/GlobalNews";
import qs from "qs";
import translate from "../../i18n/translate";
import AwesomeDebouncePromise from "awesome-debounce-promise";

enum Sort {
    FIRST_NAME = "firstName",
    LAST_NAME = "lastName",
    EMAIL = "email",
    EMAIL_DOMAIN = "emailDomain",
    CREATED = "createdAt",
    UPDATED = "updatedAt"
}

const HubSpotContacts: React.FC = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [contacts, setContacts] = useState<HubSpotContact[]>([]);
    const [totalRecords, setTotalRecords] = useState<number>();

    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [sort, setSort] = useState<Sort>();
    const [desc, setDesc] = useState<boolean>();
    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== pageSize) {
            setPageSize(size);
        }
    };

    const [domainFilterOptions, setDomainFilterOptions] = useState<SelectOption[]>([]);
    const [loadingDomainFilters, setLoadingDomainFilters] = useState<boolean>(false);
    const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
    const updateSelectedDomains = (values: SelectOption[]) => {
        setSelectedDomains(
            values?.map((item: SelectOption) => item.value)
        );
        setPage(1);
    };

    const searchQueryRef = useRef<string>();
    const updateSearchQuery = (value: string) => {
        searchQueryRef.current = value;
        if (page === 1) {
            fetchContacts();
        } else {
            setPage(1);
        }
    };

    const debounced = AwesomeDebouncePromise(updateSearchQuery, 300);
    const handleSearchQueryChange = async (e: any) => {
        e.persist();
        await debounced(e.target.value);
    };

    useEffect(() => {
        const fetchDomainFilters = async () => {
            setLoadingDomainFilters(true);
            await axios.get(socialHostName + "hubspot/filters/domains")
                .then(r => {
                    let options: SelectOption[] =
                        r.data.map((item: string) => {
                            return {
                                value: item,
                                label: item
                            }
                        });

                    setDomainFilterOptions(options);
                })
                .finally(() => setLoadingDomainFilters(false));
        };

        fetchDomainFilters();
    }, []);

    useEffect(() => {
        fetchContacts();
    }, [page, pageSize, sort, desc, selectedDomains]);

    const fetchContacts = async () => {
        setLoading(true);
        let params: any = {
            page: page - 1,
            size: pageSize
        };

        if (sort) {
            params["sort"] = sort + "," + (desc ? "desc" : "asc");
        }

        if (selectedDomains?.length > 0) {
            params["domains"] = selectedDomains;
        }

        if (searchQueryRef.current) {
            params["term"] = searchQueryRef.current
        }

        await axios.get(socialHostName + "hubspot/contacts", {
                params: params,
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                }
            })
            .then(r => {
                setContacts(r.data.content);
                setTotalRecords(r.data.totalElements);
            })
            .finally(() => setLoading(false));
    };

    const [syncInProgress, setSyncInProgress] = useState<boolean>(false);
    const syncContacts = () => {
        setLoading(true);
        axios.get(socialHostName + "hubspot/fetch-contacts")
            .then(() => { setSyncInProgress(true) })
            .finally(() => setLoading(false));
    };

    const getSortButton = (s: Sort) => {
        if (s === sort) {
            return (
                <a className="sort-button"
                   onClick={() => {
                       setDesc(!desc);
                   }}>
                    <FontAwesomeIcon icon={desc ? faSortAmountDown : faSortAmountDownAlt} />
                </a>
            );
        }

        return (
            <a className="sort-button"
               onClick={() => {
                   setSort(s);
                   setDesc(true);
               }}>
                <FontAwesomeIcon icon={faSort} />
            </a>
        );
    };

    return (
        <div style={{
            paddingTop: "10px",
            backgroundColor: "white",
            borderRadius: "0.45rem",
            padding: 15,
            marginTop: 10
        }}>
            <Row>
                <Col className="pt-2" lg={4} md={5} sm={3} xs={3}>
                    <span style={{marginLeft: "auto"}}>
                        <button onClick={syncContacts} className="iqx-button md-size dark-blue">
                            <span style={{paddingRight: 10}}>Sync HubSpot Contacts</span>
                            <FontAwesomeIcon icon={faSync} color="white" size="1x"/>
                        </button>
                    </span>
                </Col>
                <Col className="pt-2" lg={{ span: 3, offset: 3 }} md={{ span: 3, offset: 2 }} sm={{ span: 4 }} xs={{ span: 5 }}>
                    <Select isMulti
                            isDisabled={loadingDomainFilters}
                            options={domainFilterOptions}
                            onChange={(e: any) => updateSelectedDomains(e)}
                            placeholder="Select email domains..."
                            className="basic-multi-select"
                            classNamePrefix="select" />
                </Col>
                <Col className="pt-2" xl={2} lg={2} md={2} sm={5} xs={4}>
                    <input className="form-control float-end" type="text" placeholder={translate("advancedsearch.search")} onKeyUp={handleSearchQueryChange} />
                </Col>
            </Row>
            <div className="mt-3">
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>
                                First Name
                                {getSortButton(Sort.FIRST_NAME)}
                            </th>
                            <th>
                                Last Name
                                {getSortButton(Sort.LAST_NAME)}
                            </th>
                            <th>
                                Email
                                {getSortButton(Sort.EMAIL)}
                            </th>
                            <th>
                                Email Domain
                                {getSortButton(Sort.EMAIL_DOMAIN)}
                            </th>
                            <th className="text-center">
                                Archived
                            </th>
                            <th>
                                Created At
                                {getSortButton(Sort.CREATED)}
                            </th>
                            <th>
                                Updated At
                                {getSortButton(Sort.UPDATED)}
                            </th>
                        </tr>
                    </thead>
                    {!loading && (
                        <tbody>
                            {contacts.map((contact: HubSpotContact) => (
                                <tr key={contact.id}>
                                    <td>{contact.firstName}</td>
                                    <td>{contact.lastName}</td>
                                    <td>{contact.email}</td>
                                    <td>{contact.emailDomain}</td>
                                    <td className="text-center">{contact.archived ? <FontAwesomeIcon icon={faCheckCircle} color="#2bcb14"/> : <FontAwesomeIcon icon={faTimesCircle} color="#a82509" />}</td>
                                    <td>{DateFormatter.formatDate(contact.createdAt)}</td>
                                    <td>{DateFormatter.formatDate(contact.updatedAt)}</td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                </Table>

                {loading && <IqLoadingIcon />}

                {syncInProgress && (
                    <Alert variant="info" style={{ width: "100%", marginTop: 25 }}>
                        Contacts sync in progress. Please check later.
                    </Alert>
                )}

                <div className="mt-4">
                    <div className="justify-content-md-end pagination mt-n2">
                        <span className="me-2">
                            <Form.Control as="select"
                                          style={{ minWidth: "13%" }}
                                          defaultValue={pageSize}
                                          onChange={(event) => changePageSize(event)}
                                          >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                            </Form.Control>
                        </span>
                        <span>
                            {totalRecords && (
                                <Pagination totalRecords={totalRecords}
                                            pageLimit={pageSize}
                                            pageRangeDisplayed={1}
                                            initPage={page}
                                            onChangePage={setPage}
                                />
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HubSpotContacts;