import React, {useContext, useEffect, useState} from "react";
import OfficerContext from "../../context/officer/OfficerContext";
import axios from "axios";
import {Alert, Card, Col, Form, Row, Table} from "react-bootstrap";
import {matchPath, useLocation} from "react-router-dom";
import DateFormatter from "../../utils/formatters/DateFormatter";
import OfficerAppointment from "../../model/organisation/officer/OfficerAppointment";
import {organisationHostname} from "../../utils/Configuration";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {getOccupationOrRole} from "./Officer";

const OfficerAppointments: React.FC = () => {
    const {appointments, setAppointments} = useContext(OfficerContext);
    const [currentAppointments, setCurrentAppointments] = useState<OfficerAppointment[]>([]);
    const [previousAppointments, setPreviousAppointments] = useState<OfficerAppointment[]>([]);

    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/officer/:officerId',
        exact: false,
        strict: false
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    useEffect(() => {
        const fetchAppointments = async () => {
            setLoading(true);

            await axios.get(organisationHostname + "officer/" + childMatchedPath?.params.officerId + "/appointments")
                .then(r => successHandler(r.data))
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        if (appointments.length === 0 && !loading) {
            fetchAppointments();
        }
        else {
            successHandler(appointments);
        }
    }, []);

    const successHandler = (allAppointments: OfficerAppointment[]) => {
        setAppointments(allAppointments);

        setPreviousAppointments(
            allAppointments.filter(appointment => appointment.resignationDate)
        );

        setCurrentAppointments(
            allAppointments.filter(appointment => !appointment.resignationDate)
        );
    };

    const [hideInactive, setHideInactive] = useState<boolean>(true);
    const toggleHideInactive = () => {
        setHideInactive(!hideInactive);
    };

    return (
        <div>
            {loading ? (
                <IqLoadingIcon/>
            ) : (
                <div>
                    {error ? (
                        <Alert variant="danger" style={{ width: "100%", marginTop: 25 }}>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", alignContent: "center" }}>
                                <FontAwesomeIcon icon={faExclamationCircle} color="#721C24" size="2x" />
                                <span style={{ marginLeft: 15 }}>Unable to load appointments. Please contact support.</span>
                            </div>
                        </Alert>
                    ) : (
                        <div style={{paddingTop: 10}}>
                            <Row>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>
                                                <div className="organisation-card-label">
                                                    Current Appointments
                                                </div>
                                            </Card.Title>
                                            <Form.Group>
                                                <Form.Check type="checkbox"
                                                            label="Hide Inactive Companies"
                                                            checked={hideInactive}
                                                            onChange={() => toggleHideInactive()}/>
                                            </Form.Group>
                                            <Table striped bordered hover size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>Appointed At</th>
                                                        <th>Role</th>
                                                        <th>Appointed On</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentAppointments
                                                        .filter(appointment => hideInactive ? appointment.organisation.companyStatus === "Active" : true)
                                                        .map(appt => (
                                                                <tr key={appt.id}>
                                                                    <td>
                                                                        <a href={`/organisation/${appt.organisation.id}`}>
                                                                            {appt.organisation.companyName}
                                                                        </a>
                                                                    </td>
                                                                    <td>{getOccupationOrRole(appt)}</td>
                                                                    <td>{DateFormatter.formatDate(appt.appointmentDate)}</td>
                                                                    <td>{appt.organisation.companyStatus}</td>
                                                                </tr>
                                                            )
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {previousAppointments.length > 0 && (
                                    <Col>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>
                                                    <div className="organisation-card-label">
                                                        Previous Appointments
                                                    </div>
                                                </Card.Title>
                                                <Table striped bordered hover size="sm">
                                                    <thead>
                                                        <tr>
                                                            <th>Appointed At</th>
                                                            <th>Role</th>
                                                            <th>Appointed On</th>
                                                            <th>Resigned On</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {previousAppointments
                                                            .map(appt => (
                                                                    <tr key={appt.id}>
                                                                        <td>
                                                                            <a href={`/organisation/${appt.organisation.id}`}>
                                                                                {appt.organisation.companyName}
                                                                            </a>
                                                                        </td>
                                                                        <td>{getOccupationOrRole(appt)}</td>
                                                                        <td>{DateFormatter.formatDate(appt.appointmentDate)}</td>
                                                                        <td>{DateFormatter.formatDate(appt.resignationDate)}</td>
                                                                    </tr>
                                                                )
                                                            )
                                                        }
                                                        </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default OfficerAppointments;
