import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import AdvancedSearch from '../../model/advanced-search/AdvancedSearch';
import './AdvancedSearchView.css';
import axios from 'axios';
import {Alert, Col, Container, OverlayTrigger, Popover, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faCalendar,
    faClock,
    faEdit,
    faExclamationCircle,
    faFilter,
    faSearch,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import {Builder, Query, Utils as QbUtils} from 'react-awesome-query-builder-opti';
import Pagination from '../../utils/PaginationHook';
import {searchHostname} from '../../utils/Configuration';
import IqLoadingIcon from '../common/IqLoadingIcon';
import AddToListButton from "../organisation/common/AddToListButton";
import Select from 'react-select';
import translate from "../../i18n/translate";
import DateFormatter from "../../utils/formatters/DateFormatter";
import SearchCompany from '../../model/advanced-search/SearchCompany';
import AdvancedSearchViewCompany from './AdvancedSearchViewCompany';
import {hasUserRole, Role} from "../../utils/Security";
import {getChannelConfig, getEndUserConfig} from "./AdvancedSearchConfig";
import I18nContext from "../../context/I18nContext";

interface Props {}

interface SelectOption {
    value: string,
    label: string
}

export const stripHtml = (bio: string) => {
    if(bio){
        return bio.replace(/(<([^>]+)>)/gi, "").trim();
    } else {
        return "";}
}

const AdvancedSearchView: React.FC<Props> = (props: Props) => {


    const {advancedSearchId} = useParams<any>();
    let history = useHistory();

    const [advancedSearch, setAdvancedSearch] = useState<AdvancedSearch>({} as AdvancedSearch);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [loadingCompanies, setLoadingCompanies] = useState(true);
    const [expired, setExpired] = useState(false);
    const [error, setError] = useState(false);

    const pageLimitOptions: SelectOption[] = [10, 25, 50, 100].map(i => (
        {
            value: String(i),
            label: translate("advancedsearch.perpage", {count: String(i)})
        })
    );

    const sortByOptions: SelectOption[] = [
        {
            value: "relevance",
            label: translate("advancedsearch.sort.relevance")
        },
        {
            value: "nameAToZ",
            label: translate("advancedsearch.sort.nameasc")
        },
        {
            value: "nameZToA",
            label: translate("advancedsearch.sort.namedesc")
        },
        {
            value: "revenueLowToHigh",
            label: translate("advancedsearch.sort.revenueasc")
        },
        {
            value: "revenueHighToLow",
            label: translate("advancedsearch.sort.revenuedesc")
        },
        {
            value: "investmentRaisedLowToHigh",
            label: translate("advancedsearch.sort.investmentasc")
        },
        {
            value: "investmentRaisedHighToLow",
            label: translate("advancedsearch.sort.investmentdesc")
        },
        {
            value: "employeesLowToHigh",
            label: translate("advancedsearch.sort.employeesasc")
        },
        {
            value: "employeesHighToLow",
            label: translate("advancedsearch.sort.employeesdesc")
        }
    ];

    const [companies, setCompanies] = useState<SearchCompany[]>([] as SearchCompany[])
    const [noOfCompanies, setNoOfCompanies] = useState<number>(0);
    const [selectedPageLimit, setSelectedPageLimit] = useState<SelectOption>(pageLimitOptions[0]);
    const [selectedSortBy, setSelectedSortBy] = useState<SelectOption>(sortByOptions[0]);
    const [disablePagination, setDisplayPagination] = useState<boolean>(false);

    const [allCompanies, setAllCompanies] = useState<string[]>([] as string[])

    useEffect(() => {
        if (hasUserRole(Role.FREEMIUM)) {
            setDisplayPagination(true)
        }
    }, [])

    useEffect(() => {
        setLoading(true);
        const fetchAdvancedSearch = async () => {
            const result = await axios.get(searchHostname + "advanced-search/" + advancedSearchId)
                .then((response) => {
                    setAdvancedSearch(response.data);
                    setLoading(false);
                }).catch(error => {
                    setError(true);
                    setLoading(false);
                    console.error(error.message)
                });
        };
        fetchAdvancedSearch();
    }, []);

    useEffect(() => {
        setLoadingCompanies(true)
        const offset: number = (currentPage - 1) * +selectedPageLimit.value;
        const fetchSearchResult = async () => {
            const result = await axios.get(searchHostname + "advanced-search/view/" + advancedSearchId +
                "?offset=" + offset +
                "&size=" + selectedPageLimit.value +
                "&sortBy=" + selectedSortBy.value
            )
                .then((response) => setCompanies(response.data))
                .catch(error => {
                    if (error.response.status === 400) {
                        if (error.response.data.errorKey === "searchexpired") {
                            setExpired(true);
                        }
                    }
                    console.error(error.message)
                })
                .finally(() => setLoadingCompanies(false));
        };
        fetchSearchResult();
    }, [currentPage, selectedPageLimit, selectedSortBy]);

    useEffect(() => {
        if (advancedSearch.searchGraph != null) {
            const fetchCount = async () => {
                const result = await axios.post(searchHostname + "advanced-search/" + advancedSearch.type + "/count/", advancedSearch.searchGraph,
                    {
                        headers: {'Content-Type': 'text/plain'}
                    })
                    .then((response) => {
                        setNoOfCompanies(response.data);
                    })
                    .catch(error => console.error(error.message));
            };
            fetchCount();
        }
    }, [advancedSearch]);

    useEffect(() => {
        setLoadingCompanies(true)
        const offset: number = (currentPage - 1) * +selectedPageLimit.value;

        const fetchAllCompanies = async () => {
            const result = await axios.get(searchHostname + "advanced-search/view/ids-only/" + advancedSearchId +
                "?offset=" + offset +
                "&size=" + 1000 +
                "&sortBy=" + selectedSortBy.value
            )
                .then((response) => {
                    setAllCompanies(response.data);
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        if (error.response.data.errorKey === "searchexpired") {
                            setExpired(true);
                        }
                    }
                    console.error(error.message)
                })
                .finally(() => setLoadingCompanies(false));
        };

        fetchAllCompanies();
    }, [currentPage, selectedPageLimit, selectedSortBy]);

    const popover = (
        <Popover id="popover-basic">
            <h3>{translate("lists.freemiumPopoverTitle")}</h3>
            <div>
                {translate("lists.freemiumPopoverMessage")}
            </div>
        </Popover>
    );

    const PageSizeOptions = () => {
        return (
            <Col className="pt-2 me-2" xl={2} lg={3} md={6} sm={6} xs={12}>
                <Select
                    options={pageLimitOptions}
                    value={selectedPageLimit}
                    onChange={(e: any) => setSelectedPageLimit(e)}
                    placeholder="Select page limit..."
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isDisabled={disablePagination}
                />
            </Col>
        )
    }

    const SortOptions = () => {
        return (
            <Col className="pt-2 me-2" xl={2} lg={3} md={6} sm={6} xs={12}>
                <Select
                    options={sortByOptions}
                    value={selectedSortBy}
                    onChange={(e: any) => setSelectedSortBy(e)}
                    placeholder="Sort by..."
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isDisabled={disablePagination}/>
            </Col>
        )
    }

    const PageSelector: React.FC = () => {
        return (
            <Pagination
                initPage={currentPage}
                totalRecords={noOfCompanies}
                pageLimit={+selectedPageLimit.value}
                pageRangeDisplayed={1}
                onChangePage={setCurrentPage}
                displayPagination={disablePagination}
            />
        );
    };

    const [showFilters, setShowFilters] = useState<boolean>(false);
    const toggleFilters = () => {
        setShowFilters(!showFilters);
    }

    const {lang} = useContext(I18nContext);
    const renderBuilder: React.FC<any> = (props) => (
        <div className="query-builder-container" style={{ padding: '10px' }}>
            <div className="query-builder qb-lite">
                <Builder {...props} />
            </div>
        </div>
    );

    const getFilterConfig = () => {
        if (advancedSearch.type === "channel") {
            return {
                ...getChannelConfig([])
            };
        }

        return {
            ...getEndUserConfig([], lang)
        };
    }

    return (
        <div>
            <Container id="advanced-search-companies-view-container" fluid style={{paddingLeft: 0, paddingRight: 0}}>
                {!loading
                    ?
                    error
                        ?
                        <Alert variant="danger" style={{width: "100%", marginTop: 25}}>
                            <div style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                alignContent: "center"
                            }}>
                                <FontAwesomeIcon icon={faExclamationCircle} color="#721C24" size="2x"/>
                                <span style={{marginLeft: 15}}>Unable to load advanced search.</span>
                            </div>
                        </Alert>
                        :
                        <>
                            <Row className="" style={{marginLeft: 0, marginRight: 0, paddingTop: 15}}>
                                <Col xs={12} style={{paddingLeft: 0, paddingRight: 0}}>
                                    <Row className="" style={{marginLeft: 0, marginRight: 0, paddingTop: 0}}>
                                        <div style={{position: "relative"}} id="container-header">
                                            <div className="content">
                                                <div style={{
                                                    width: "auto",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    flexWrap: "wrap"
                                                }}>
                                                    <div><FontAwesomeIcon style={{marginRight: 10}} icon={faSearch}
                                                                          color="white" size="2x"/><span style={{
                                                        fontWeight: 500,
                                                        fontSize: 32
                                                    }}> {advancedSearch.name}</span></div>
                                                    <div style={{display: "flex", flexWrap: "wrap", marginTop: 10}}>
                                                        <span style={{marginRight: 15}}
                                                              className="advanced-search-date">
                                                            <FontAwesomeIcon style={{marginLeft: 5, marginRight: 5}}
                                                                             className="icon" icon={faCalendar}
                                                                             size="1x"/>
                                                            {translate("advancedsearch.createdon", {date: DateFormatter.formatDate(advancedSearch.createdOn.toString())})}
                                                        </span>
                                                        <span className="advanced-search-date">
                                                            <FontAwesomeIcon style={{marginLeft: 5, marginRight: 5}}
                                                                             className="icon" icon={faCalendar}
                                                                             size="1x"/>
                                                            {translate("advancedsearch.expiringon", {date: DateFormatter.formatDate(advancedSearch.expiringOn.toString())})}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div id="advanced-search-counter-wrapper">
                                                    <div id="advanced-search-counter">{noOfCompanies}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                            {!expired
                                ?
                                <>
                                    {/* Buttons Row Container */}
                                    <div className="advanced-search-buttons-container">

                                        {/* Page Size and Sorting */}
                                        {hasUserRole(Role.FREEMIUM) ? (
                                            <OverlayTrigger placement="top" overlay={popover}>
                                                <PageSizeOptions/>
                                            </OverlayTrigger>) : (
                                            <PageSizeOptions/>)}

                                        {hasUserRole(Role.FREEMIUM) ? (
                                            <OverlayTrigger placement="top" overlay={popover}>
                                                <SortOptions/>
                                            </OverlayTrigger>
                                        ) : (<SortOptions/>)}

                                        {/* Add To List Buttons */}
                                        <div className="advanced-search-buttons-add-to-list">
                                            <AddToListButton
                                                companyIds={companies.map(company => company.companyId)}
                                                variant="white"
                                                buttonText="Add Page To List"
                                                advancedSearchId={advancedSearchId}
                                                advancedSearchName={advancedSearch.name}/>

                                            <AddToListButton
                                                companyIds={allCompanies}
                                                variant="white"
                                                advancedSearchId={advancedSearchId}
                                                advancedSearchName={advancedSearch.name}/>


                                            <button className="iq-action-button m-1 white"
                                                    onClick={toggleFilters}>
                                                <div className="organisation-button-text">
                                                    <span className="text">
                                                        {showFilters ? "Hide" : "Show"} Filters
                                                    </span>
                                                    <FontAwesomeIcon className="icon ms-1" style={{marginTop: "2px"}}
                                                                     icon={faFilter} color="#2F6FC3" size="1x"/>
                                                </div>
                                            </button>
                                        </div>


                                        {/* Spacer between elements */}
                                        <span className="advanced-search-buttons-spacer"/>

                                        {/* Page Selector Top */}
                                        {hasUserRole(Role.FREEMIUM) ? (
                                            <OverlayTrigger placement="top" overlay={popover}>
                                                <div className="advanced-search-buttons-page-selector">
                                                    <PageSelector/>
                                                </div>
                                            </OverlayTrigger>) : (
                                            <div className="advanced-search-buttons-page-selector">
                                                <PageSelector/>
                                            </div>
                                        )}

                                    </div>

                                    {showFilters && (
                                        <div className="organisation-card mb-5">
                                            <Query
                                                {...getFilterConfig()}
                                                value={QbUtils.loadTree(JSON.parse(advancedSearch.searchGraph))}
                                                renderBuilder={renderBuilder}
                                            />

                                            <div className="mt-n3" style={{display: "flex", justifyContent: "flex-end", flexDirection: "row", width: "96%"}}>
                                                <div className="col-1 me-4">
                                                    <button className="iq-action-button grey m-1"
                                                            onClick={() => setShowFilters(false)}>
                                                        <div className="organisation-button-text">
                                                            <span className="text">
                                                                Hide
                                                            </span>
                                                            <FontAwesomeIcon className="icon ms-1" style={{marginTop: "2px"}}
                                                                             icon={faTimes} color="#2F6FC3" size="1x"/>
                                                        </div>
                                                    </button>
                                                </div>

                                                <div className="col-1 me-3">
                                                    <button className="iq-action-button grey m-1"
                                                            onClick={() => {
                                                                history.push(
                                                                    `/advanced-search/${advancedSearch.id}/edit`,
                                                                    {
                                                                        searchId: advancedSearch.id,
                                                                        searchGraph: advancedSearch.searchGraph,
                                                                        searchType: advancedSearch.type,
                                                                        searchName: advancedSearch.name
                                                                    }
                                                                );
                                                            }}>
                                                        <div className="organisation-button-text">
                                                            <span className="text">
                                                                Edit
                                                            </span>
                                                            <FontAwesomeIcon className="icon ms-1" style={{marginTop: "2px"}}
                                                                             icon={faEdit} color="#2F6FC3" size="1x"/>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* List of Companies */}
                                    {loadingCompanies
                                        ?
                                        Array.from(Array(+selectedPageLimit.value), (_, x) => x).map((i) =>
                                            < Row key={i}
                                                  className="advanced-search-company-container animated-background"
                                                  style={{marginLeft: 0, marginRight: 0, paddingTop: 15}}>
                                            </Row>
                                        )

                                        :
                                        <>
                                            {
                                                companies
                                                    .map(company => (
                                                        <AdvancedSearchViewCompany key={company.companyId}
                                                                                   companyId={company.companyId}
                                                                                   companyName={company.companyName}
                                                                                   companyNumber={company.companyNumber}
                                                                                   introduction={stripHtml(company.introduction)}
                                                                                   financialSummary={{
                                                                                       turnover: company.financialSummary?.turnover,
                                                                                       numberOfEmployees: company.financialSummary?.numberOfEmployees
                                                                                   }}
                                                                                   imageUrl={company.imageUrl}
                                                                                   country={company.country}
                                                        />
                                                    ))
                                            }
                                        </>
                                    }

                                    {/* Bottom Page Number Selector */}
                                    {hasUserRole(Role.FREEMIUM) ? (
                                        <OverlayTrigger placement="top" overlay={popover}>
                                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                                <PageSelector/>
                                            </div>
                                        </OverlayTrigger>) : (
                                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                                            <PageSelector/>
                                        </div>
                                    )}

                                </>
                                :
                                <Alert variant="primary" style={{width: "100%", marginTop: 25}}>
                                    <div className="search-expired">
                                        <FontAwesomeIcon icon={faClock} color="#367CDB" size="2x"/>
                                        <span
                                            style={{marginLeft: 15}}>It seems like this search has expired on {DateFormatter.format(advancedSearch.expiringOn)}. <span
                                            onClick={() => history.push("/advanced-search")}
                                            style={{color: "#42A6EE", textDecoration: "underline", cursor: "pointer"}}>Go back</span> and rerun it to see the results.</span>
                                    </div>
                                </Alert>
                            }
                        </>
                    :
                    <IqLoadingIcon/>
                }
            </Container>
        </div>
    );
}

export default AdvancedSearchView;
