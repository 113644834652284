import React, {useState} from "react";
import {Hint, HorizontalGridLines, LineMarkSeries, XAxis, XYPlot, YAxis} from "react-vis";
import {format} from "d3-format";
import {AutoSizer} from "react-virtualized";

interface Props {
    data: any[],
    colour: string,
    title: string,
    formatValue: (value: string) => {},
    yAxisFormat: (value: any) => string
}

const ContractValuePerYear: React.FC<Props> = ({data, colour, title, formatValue, yAxisFormat}) => {
    const [hover, setHover] = useState<any>();

    const xMin = data.reduce((min: number, p: { x: number; }) => p.x < min ? p.x : min, data[0].x);
    const xMax = data.reduce((max: number, p: { x: number; }) => p.x > max ? p.x : max, data[0].x);

    const yMin = data.reduce((min: number, p: { y: number; }) => p.y < min ? p.y : min, data[0].y);
    const yMax = data.reduce((max: number, p: { y: number; }) => p.y > max ? p.y : max, data[0].y);
    return (
        <AutoSizer>
            {({ height, width }) => (
                <XYPlot width={width}
                        margin={{left: 50}}
                        height={300}
                        xDomain={[xMin, xMax]}
                        yDomain={[yMin, yMax]}>

                    <HorizontalGridLines />
                    <LineMarkSeries
                        getNull={(d) => d.y != null}  //only draw if different than null
                        color={colour}
                        data={data}
                        onValueMouseOver={(v: any) => {
                            setHover(v);
                        }}
                        onValueMouseOut={(v: any) => {
                            setHover(null);
                        }} />

                    <XAxis tickFormat={val => Math.round(val) === val ? val : ""} title="Year Awarded" />
                    <YAxis tickFormat={tick => `£${format('.2s')(tick).replace(/G/, "B")}`} title={title} />

                    {hover && (
                        <Hint value={hover}>
                            <div className="chart-hint">
                                <div className="x">{hover.x}</div>
                                <div className="y">{(formatValue(hover.y) as String)?.replace(/G/, "B")}</div>
                            </div>
                        </Hint>
                    )}
                </XYPlot>
            )}
        </AutoSizer>
    );
};

export default ContractValuePerYear;