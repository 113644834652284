import React from "react";
import {DateText, Highlight, Icon, Item, ItemContent, NotificationItem, Text} from "../Notifications";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding} from "@fortawesome/free-solid-svg-icons";
import {Payload} from "../../../model/notification/Payload";
import translate from "../../../i18n/translate";
import ReactTimeAgo from "react-time-ago";

const OrganisationResearchRequestNotification: React.FC<NotificationItem> = ({ element }) => {

    return (
        <Item read={element.read}>
            <Icon>
                <FontAwesomeIcon color="white" icon={faBuilding} />
            </Icon>
            <ItemContent>
                {element.middlewares.map((middleware: Payload) => (
                    <div key={middleware.id}>
                        <Text>
                            <span className="me-2">
                                <Highlight>
                                    <a target={"_blank"} href={["/organisation", middleware.id].join("/")}>
                                        {middleware.placeholder}
                                    </a>
                                </Highlight>

                            </span>
                            <span>{translate("notifications.research")}</span>
                        </Text>
                    </div>
                ))}
                <DateText>
                    <ReactTimeAgo date={new Date(element.modified)} locale="en-US" />
                </DateText>
            </ItemContent>
        </Item>
    );
};

export default OrganisationResearchRequestNotification;