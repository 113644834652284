import React from "react";

const DashboardItemContext = React.createContext({
    maxHeight: 0,
    setMaxHeight: (height: number) => {},
    width: 0,
    setWidth: (width: number) => {}
});

export default DashboardItemContext;
