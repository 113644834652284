import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import './AdvancedSearchView.css';
import {Image, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAddressCard, faChartLine, faUsers} from '@fortawesome/free-solid-svg-icons';
import FollowButton from "../organisation/common/FollowButton";
import AddToListButton from "../organisation/common/AddToListButton";
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {formatLocale, FormatLocaleDefinition} from 'd3-format';
import translate from "../../i18n/translate";
import LazyLoad from 'react-lazyload';
import SearchCompany from '../../model/advanced-search/SearchCompany';

const AdvancedSearchViewCompany: React.FC<SearchCompany> = (props: SearchCompany) => {


    let history = useHistory();

    interface CompanyProperty {
        icon: IconProp,
        tooltip: string,
        value: string | number,
    }

    const CompanyProperty: React.FC<CompanyProperty> = (org: CompanyProperty) => {
        const renderTooltip = (props: any) => (
            <Tooltip {...props}>
                {org.tooltip}
            </Tooltip>
        );
        return (
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            >
                <div className="property">
                    <span className="icon">
                        <FontAwesomeIcon icon={org.icon} color="black" size="1x" />
                    </span>
                    <span className="text">
                        {org.value}
                    </span>
                </div>
            </OverlayTrigger>
        )
    };

    const CompanyLogo: React.FC<SearchCompany> = (company: SearchCompany) => {
        const [src, setSrc] = useState<string>(company.imageUrl ? company.imageUrl : "./icons_company.svg");

        return (
            <Image title={company.companyName}
                style={{
                    height: "30px",
                    width: "30px",
                    objectFit: "cover"
                }}
                src={src}
                onError={() => setSrc("/icons_company.svg")}
                roundedCircle />
        )
    };

    let UK = formatLocale({
        currency: ["£ ", ""]
    } as FormatLocaleDefinition)

    let EU = formatLocale({
        currency: ["€", ""]
    } as FormatLocaleDefinition)

    const stripHtml = (bio: string) => {
        if (bio) {
            return bio.replace(/(<([^>]+)>)/gi, "").trim();
        }
    };
    return (
        <div className="advanced-search-company-container" style={{ marginLeft: 0, marginRight: 0, paddingTop: 15 }}>
            <div className="left">
                <div className="company-name iqx-link" style={{ cursor: "pointer" }}
                    onClick={() => history.push("/organisation/" + props.companyId)}>
                    <CompanyLogo {...props} />
                    <span style={{ marginLeft: 10 }}>{props.companyName}</span>
                </div>
                <div className="company-introduction">{stripHtml(props.introduction?.substring(0, 180))}{props.introduction?.length > 180 && "..."}</div>
                <div className="search-properties">
                    {props.companyNumber != null &&
                        <CompanyProperty icon={faAddressCard} value={props.companyNumber} tooltip={translate("lists.companynumber")} />}
                    {props.financialSummary?.numberOfEmployees != null &&
                        <CompanyProperty icon={faUsers} value={props.financialSummary?.numberOfEmployees} tooltip={translate("lists.employees")} />}
                    {props.financialSummary?.turnover != null &&
                        <CompanyProperty icon={faChartLine} value={props.country === "UK" ?
                            UK.format('$,.3s')(+props.financialSummary?.turnover).replace(/G/, "B")
                            : EU.format('$,.3s')(+props.financialSummary?.turnover).replace(/G/, "B")} tooltip={translate("organisation.summary.headlinenumbers.revenues")} />}
                </div>
            </div>
            <div className="right">
                <LazyLoad scrollContainer={".main-container-content"} once>
                    <FollowButton variant="grey" companyId={props.companyId} />
                </LazyLoad>

                <AddToListButton variant="grey" companyIds={[props.companyId]} />
            </div>
        </div>
    );

}

export default AdvancedSearchViewCompany;
