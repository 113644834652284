import React, {useEffect, useState} from "react";
import axios from "axios";
import {organisationHostname} from "../../utils/Configuration";
import translate from "../../i18n/translate";
import DateFormatter from "../../utils/formatters/DateFormatter";
import {Col, Form, Row, Spinner, Table} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import IqLoadingIcon from "../common/IqLoadingIcon";
import Pagination from "../../utils/PaginationHook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faNewspaper, faSort, faSortAmountDown, faSortAmountDownAlt} from "@fortawesome/free-solid-svg-icons";
import {CompanyLogo} from "./MarketIntelligenceContainer";
import {GazetteDetail} from "../../model/organisation/GazetteDetail";

enum Sort {
    PUBLISHED = "published",
    NOTICE_TYPE = "noticeType",
    CATEGORY = "category",
    ORGANISATION = "organisation"
}

const Gazette: React.FC = () => {
    let history = useHistory();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [sort, setSort] = useState<string>(Sort.PUBLISHED);
    const [desc, setDesc] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [gazetteDetails, setGazetteDetails] = useState<GazetteDetail[]>([]);
    const [totalRecords, setTotalRecords] = useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let page = currentPage - 1;
            let url = `${organisationHostname}gazette?page=${page}&size=${pageSize}&sort=${sort + "," + (desc ? "DESC" : "ASC")}`;

            await axios.get(url)
                .then(r => {
                    let data = r.data;
                    console.log(r.data)
                    setGazetteDetails(data.content);
                    setTotalRecords(data.totalElements);
                })
                .finally(() => setLoading(false));
        };

        fetchData();
    }, [currentPage, pageSize, sort, desc]);

    const getSortButton = (s: Sort) => {
        if (s === sort) {
            return (
                <a className="sort-button pull-right"
                   onClick={() => {
                       setDesc(!desc);
                   }}>
                    <FontAwesomeIcon icon={desc ? faSortAmountDown : faSortAmountDownAlt}/>
                </a>
            );
        }

        return (
            <a className="sort-button pull-right"
               onClick={() => {
                   setSort(s);
                   setDesc(true);
               }}>
                <FontAwesomeIcon icon={faSort}/>
            </a>
        );
    };

    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== pageSize) {
            setPageSize(size);
        }
    };


    return (
        <div style={{paddingTop: 35}}>
            <div className="organisation-card">
                <div className="organisation-card-label">
                    {translate("marketintelligence.risk.title")}
                </div>
                <div className="mt-4">
                    <Table striped hover>
                        <thead>
                        <tr>
                            <th>
                                {translate("marketintelligence.risk.published")}
                                {getSortButton(Sort.PUBLISHED)}
                            </th>
                            <th>
                                {translate("marketintelligence.risk.noticetype")}
                                {getSortButton(Sort.NOTICE_TYPE)}
                            </th>
                            <th className="text-end">
                                {translate("marketintelligence.risk.category")}
                                {getSortButton(Sort.CATEGORY)}
                            </th>
                            <th style={{ width: "30%" }}>
                                {translate("marketintelligence.risk.organisations")}
                                {getSortButton(Sort.ORGANISATION)}
                            </th>
                            <th className="text-end">
                                    <span>
                                        <FontAwesomeIcon icon={faNewspaper} size="1x"/>
                                    </span>
                            </th>
                        </tr>
                        </thead>
                        {!loading && (
                            <tbody>
                            {gazetteDetails.map((details: GazetteDetail) => (
                                <tr key={details.gazetteId}>
                                    <td>{DateFormatter.formatDate(details.published.toString())}</td>
                                    <td>
                                            <span className="text-uppercase">
                                                {details.noticeType ? details.noticeType : "-"}
                                            </span>
                                    </td>
                                    <td className="text-end">
                                        {(details.category)}
                                    </td>
                                    <td>
                                            <span className="pe-3">
                                                <CompanyLogo {...details.organisation} />
                                            </span>
                                        <span className="iqx-link pt-2" onClick={() => history.push("/organisation/" + details.organisation.id)}>
                                                {details.organisation.companyName}
                                            </span>
                                    </td>
                                    <td className="text-right">
                                        <a className="iqx-link" target="_blank" href={details?.noticeLink}>
                                            {loading ? (
                                                <Spinner className="icon float-end" style={{ display: "block", height: "1rem", width: "1rem" }}
                                                         animation="border"
                                                         variant="primary" />
                                            ) : (
                                                <FontAwesomeIcon icon={faLink} size="1x"/>
                                            )}
                                        </a>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        )}
                    </Table>

                    {loading && (
                        <div className="row text-center">
                            <IqLoadingIcon />
                        </div>
                    )}

                    <Row className="justify-content-md-end">
                        <Col md={1}>
                            <Form.Control as="select"
                                          className="pull-right"
                                          defaultValue={pageSize}
                                          onChange={(event) => changePageSize(event)}
                                          >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                            </Form.Control>
                        </Col>
                        <Col md={2}>
                            <div className="pull-right">
                                {totalRecords > 0 && (
                                    <Pagination totalRecords={totalRecords}
                                                pageLimit={pageSize}
                                                pageRangeDisplayed={1}
                                                initPage={currentPage}
                                                onChangePage={setCurrentPage}
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default Gazette;