import React, {useEffect, useState} from 'react';
import {Col, Container, Image, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {useHistory, useLocation} from "react-router-dom";
import './SearchContainer.css';
import axios from 'axios';
import {searchHostname} from '../../utils/Configuration';
import IqLoadingIcon from '../common/IqLoadingIcon';
import IqFlag from '../common/CountryFlag';
import {faAddressCard, faChartLine, faExternalLinkAlt, faUsers} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {formatLocale, FormatLocaleDefinition} from 'd3-format';
import SearchCompany from '../../model/advanced-search/SearchCompany';
import translate from "../../i18n/translate";

interface IIndexable<T = any> { [key: string]: T }

const compareAsc = (a: SearchCompany, b: SearchCompany, property: string) => {

    const aFinancialSummary = (a.financialSummary as IIndexable);
    const bFinancialSummary = (b.financialSummary as IIndexable);

    if (!bFinancialSummary || bFinancialSummary[property] === null) {
        return 1;
    }

    if (!aFinancialSummary || aFinancialSummary[property] === null) {
        return -1;
    }


    if (aFinancialSummary[property] > bFinancialSummary[property]) {
        return 1;
    }
    else if (aFinancialSummary[property] === bFinancialSummary[property]) {
        return 0;
    }

    return -1;
};

const compareDesc = (a: SearchCompany, b: SearchCompany, property: string) => {

    const aFinancialSummary = (a.financialSummary as IIndexable);
    const bFinancialSummary = (b.financialSummary as IIndexable);

    if (!aFinancialSummary || aFinancialSummary[property] === null) {
        return 1;
    }

    if (!bFinancialSummary || bFinancialSummary[property] === null) {
        return -1;
    }

    if (aFinancialSummary[property] < bFinancialSummary[property]) {
        return 1;
    }
    else if (aFinancialSummary[property] === bFinancialSummary[property]) {
        return 0;
    }

    return -1;
};

const SearchContainer: React.FC = () => {

    const stripHtml = (bio: string) => {
        if (bio) {
            return bio.replace(/(<([^>]+)>)/gi, "").trim();
        }
    };

    const sortOptions = [
        {
            value: "NAME_ASC",
            label: "advancedsearch.sort.nameasc",
            sortExpression: (a: SearchCompany, b: SearchCompany) => a.companyName.localeCompare(b.companyName)
        },
        {
            value: "NAME_DESC",
            label: "advancedsearch.sort.namedesc",
            sortExpression: (a: SearchCompany, b: SearchCompany) => b.companyName.localeCompare(a.companyName)
        },
        {
            value: "TURNOVER_ASC",
            label: "advancedsearch.sort.revenueasc",
            sortExpression: (a: SearchCompany, b: SearchCompany) => compareAsc(a, b, "turnover")
        },
        {
            value: "TURNOVER_DESC",
            label: "advancedsearch.sort.revenuedesc",
            sortExpression: (a: SearchCompany, b: SearchCompany) => compareDesc(a, b, "turnover")
        },
        {
            value: "EMPLOYEES_ASC",
            label: "advancedsearch.sort.employeesasc",
            sortExpression: (a: SearchCompany, b: SearchCompany) => compareAsc(a, b, "numberOfEmployees")
        },
        {
            value: "EMPLOYEES_DESC",
            label: "advancedsearch.sort.employeesdesc",
            sortExpression: (a: SearchCompany, b: SearchCompany) => compareDesc(a, b, "numberOfEmployees")
        }
    ];

    let history = useHistory();

    const [sort, setSort] = useState<string>();
    const [companies, setCompanies] = useState<SearchCompany[]>([] as SearchCompany[])
    const [loading, setLoading] = useState<Boolean>(false);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    useEffect(() => {
        setLoading(true);
        const fetchSearchResult = async () => {
            await axios.post(searchHostname + "quick-search",
                {
                    searchQuery: query.get("q"),
                    filters: query.get("filters")?.split(",")
                })
                .then((response) => {
                    setCompanies(response.data);
                    setLoading(false);
                }).catch(error => console.log(error.message));
        };

        const timeOutId = setTimeout(() => fetchSearchResult(), 1000);
        return () => clearTimeout(timeOutId);
    }, [query.get("q"), query.get("filters")]);

    interface CompanyLogoProps {
        companyName: string,
        imageUrl: string
    }
    const CompanyLogo: React.FC<CompanyLogoProps> = (org: CompanyLogoProps) => {
        const [src, setSrc] = useState<string>(org.imageUrl ? org.imageUrl : "/icons_company.svg");

        return (
            <Image title={org.companyName}
                style={{
                    height: "60px",
                    width: "60px",
                    objectFit: "cover",
                    marginRight: 15,
                    marginBottom: 15,
                    border: "2px solid #5FD1F9"
                }}
                src={src}
                onError={() => setSrc("/icons_company.svg")}
                roundedCircle />
        )
    };

    let UK = formatLocale({
        currency: ["£ ", ""]
    } as FormatLocaleDefinition)

    let EU = formatLocale({
        currency: ["€", ""]
    } as FormatLocaleDefinition)

    interface CompanyProperty {
        icon: IconProp,
        tooltip: string,
        value: string | number,
    }

    const CompanyProperty: React.FC<CompanyProperty> = (org: CompanyProperty) => {
        const renderTooltip = (props: any) => (
            <Tooltip {...props}>
                {org.tooltip}
            </Tooltip>
        );
        return (
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            >
                <div className="property">
                    <span className="icon">
                        <FontAwesomeIcon icon={org.icon} color="black" size="1x" />
                    </span>
                    <span className="text">
                        {org.value}
                    </span>
                </div>
            </OverlayTrigger>
        )
    };

    const loadCompany = async (id: string) => {
        trackCompanyClick(id)
        history.push("/organisation/" + id);
    };

    const trackCompanyClick = async (id: string) => {
        let postData = {
            organisationId: id
        };

        axios.post(searchHostname + "search-history/save", postData);
    }

    useEffect(() => {
        const sortResults = () => {
            let option = sortOptions.find(s => s.value === sort);

            if (option) {
                let sorted = [...companies].sort(option.sortExpression);
                setCompanies(sorted);
            }
        };

        sortResults();
    }, [sort]);

    return (
        <div>
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Row className="mb-3">
                    <Col xl={{ span: 3, offset: 9 }} lg={{ span: 3, offset: 9 }} md={{ span: 3, offset: 9 }} sm={{ span: 6, offset: 6 }} xs={{ span: 6, offset: 6 }}>
                        <select className="form-control" onChange={(e: any) => setSort(e.target.value)}>
                            <option disabled={true} selected={true}>{translate("contacts.sort.sortby")}</option>
                            {sortOptions.map(option => <option value={option.value}>{translate(option.label)}</option>)}
                        </select>
                    </Col>
                </Row>
                <Row className="" style={{ marginLeft: 0, marginRight: 0, paddingTop: 15 }}>
                    {loading
                        ?
                        <IqLoadingIcon />
                        :
                        <div style={{ width: "100%" }}>
                            {companies.length === 0 &&
                                <div className="search-not-found">
                                    <div className="alert alert-light" role="alert">
                                        We couldn't find any organisation matching your search query.
                                    </div>
                                </div>
                            }
                            {
                                companies
                                    .map(company => (
                                        <div className="search-item">
                                            <span className="search-company-logo">
                                                <CompanyLogo
                                                    imageUrl={company.imageUrl}
                                                    companyName={company.companyName}
                                                />
                                            </span>
                                            <span className="search-content">
                                                <div style={{ cursor: "pointer" }}
                                                    className="search-company-name">
                                                    <span onClick={() => loadCompany(company.companyId)}>{company.companyName}</span> <a target="_blank" onClick={() => trackCompanyClick(company.companyId)} href={["/organisation/", company.companyId].join("")}>
                                                        <FontAwesomeIcon icon={faExternalLinkAlt} color="#009FE3" size="sm" />
                                                    </a>
                                                </div>

                                                <div className="search-properties">
                                                    {company.companyNumber != null &&
                                                        <CompanyProperty icon={faAddressCard} value={company.companyNumber} tooltip={"Company Number"} />}
                                                    {company.financialSummary?.numberOfEmployees != null &&
                                                        <CompanyProperty icon={faUsers} value={company.financialSummary?.numberOfEmployees} tooltip={"Employees"} />}
                                                    {company.financialSummary?.turnover != null &&
                                                        <CompanyProperty icon={faChartLine} value={company.country === "UK" ?
                                                            UK.format('$,.3s')(+company.financialSummary?.turnover).replace(/G/, "B")
                                                            : EU.format('$,.3s')(+company.financialSummary?.turnover).replace(/G/, "B")} tooltip={"Revenues"} />}
                                                </div>

                                                <div className="search-company-intro">
                                                    {stripHtml(company.introduction)}
                                                </div>
                                            </span>
                                            <span className="search-company-country"><IqFlag country={company.country} width={75} height={75} /></span>
                                        </div>
                                    ))
                            }
                        </div>
                    }

                </Row>
            </Container>
        </div>
    );
}

export default SearchContainer;
