import {faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {Modal} from 'react-bootstrap';
import IqSmallLoadingIcon from '../common/IqSmallLoadingIcon';
import { StateHandler } from '../../model/organisation/editor/StateHandler';

interface Props {
    
    displayModal: boolean, //
    handleClose: () => void //
    
    socialScraperData: StateHandler, //
    updateProfileData: StateHandler, //
    updateFilingHistoryData: StateHandler, //
    updateOfficerAppointmentData: StateHandler, //
    updateFinancialStatementData: StateHandler, //
    directorScraperData: StateHandler, //

    caseStudiesGatherData: StateHandler, //
    caseStudiesProcessData: StateHandler, //

    whitePaperGatherData: StateHandler, //
    whitepaperProcessData: StateHandler, //

}
const NewDisplayFetchCompanyDetailsModal: React.FC<Props> = (props: Props) => {

    const updateMessage = (stateData: StateHandler) => {
        if (stateData.loading) {
            return (
                <div style={{ alignItems: "center", padding: "5px" }}>
                    <IqSmallLoadingIcon />
                </div>)
        } else {
            if (stateData.success) {
                return <FontAwesomeIcon icon={faCheckCircle} color="green" size="lg" />
            }
            if (stateData.error.state) {
                return (
                    <>
                        <FontAwesomeIcon icon={faTimesCircle} color="red" size="lg" />
                        {stateData.error.message}
                    </>
                )
            }
        }
    }

    return (
        <Modal show={props.displayModal} onHide={props.handleClose} dialogClassName="modal-dialog modal-full-height modal-right modal-notify modal-success">
            <Modal.Header closeButton>
                <Modal.Title>Update Company</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className="fetch-container">
                    <div>
                        <span className="update-company-title">Auto Gather</span>
                        {updateMessage(props.socialScraperData)}
                    </div>
                    <div>
                        <span className="update-company-title">Fetch Company Profile</span>
                        {updateMessage(props.updateProfileData)}
                    </div>
                    <div>
                        <span className="update-company-title">Update Filing History</span>
                        {updateMessage(props.updateFilingHistoryData)}
                    </div>
                    <div>
                        <span className="update-company-title">Update Officers Appointment</span>
                        {updateMessage(props.updateOfficerAppointmentData)}
                    </div>
                    <div>
                        <span className="update-company-title">Director Profiles</span>
                        {updateMessage(props.directorScraperData)}
                    </div>
                    <div>
                        <span className="update-company-title">Update Financial Statements</span>
                        {updateMessage(props.updateFinancialStatementData)}
                    </div>
                    <div>
                        <span className="update-company-title">Gather Case Studies</span>
                        {updateMessage(props.caseStudiesGatherData)}
                    </div>

                    <div>
                        <span className="update-company-title">Process Case Studies</span>
                        {updateMessage(props.caseStudiesProcessData)}
                    </div>

                    <div>
                        <span className="update-company-title">Gather White Paper</span>
                        {updateMessage(props.whitePaperGatherData)}
                    </div>

                    <div>
                        <span className="update-company-title">Process White Paper</span>
                        {updateMessage(props.whitepaperProcessData)}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default NewDisplayFetchCompanyDetailsModal;