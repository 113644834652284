import React, {useEffect, useState} from "react";
import axios from "axios";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {Alert, Row} from "react-bootstrap";
import "./ServiceStatusContainer.css";
import ServiceStatus from "./ServiceStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {gatewayRoutes} from "../../../utils/Configuration";

export interface RouteVM {
    path: string;
    serviceId: string;
    serviceInstances: ServiceInstance[];
}

export interface InstanceInfo {
    instanceId: string;
    app: string;
    appGroupName?: any;
    ipAddr: string;
    homePageUrl: string;
    statusPageUrl: string;
    healthCheckUrl: string;
    secureHealthCheckUrl?: any;
    vipAddress: string;
    secureVipAddress: string;
    hostName: string;
    status: string;
    lastUpdatedTimestamp: number;
    lastDirtyTimestamp: number;
}

export interface ServiceInstance {
    scheme: string;
    host: string;
    port: number;
    secure: boolean;
    serviceId: string;
    uri: string;
    instanceId: string;
    instanceInfo: InstanceInfo;
}

export interface Service {
    name: string,
    serviceName: string;
    expectedInstances: number
}

const services: Service[] = [
    {
        name: "Frontend Service",
        serviceName: "frontend-service",
        expectedInstances: 2
    },
    {
        name: "Notification Service",
        serviceName: "notification-service",
        expectedInstances: 1
    },
    {
        name: "Organisation Service",
        serviceName: "organisation-service",
        expectedInstances: 3
    },
    {
        name: "Search Service",
        serviceName: "search-service",
        expectedInstances: 3
    },
    {
        name: "Social Service",
        serviceName: "social-service",
        expectedInstances: 1
    }
];

const ServiceStatusContainer: React.FC = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [routes, setRoutes] = useState<RouteVM[]>([]);
    const [error, setError] = useState<boolean>(false);
    const fetchRoutes = async () => {
        setLoading(true);
        setError(false);
        await axios.get(gatewayRoutes)
            .then(r => setRoutes(r.data))
            .catch(e => setError(true))
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (routes.length === 0) {
            fetchRoutes();
        }
    }, []);

    return (
        <div className="p-3">
            <Row className="pull-right mb-2">
                <button className="iqx-button primary md-size" onClick={fetchRoutes}>
                    <span className="me-2">Refresh</span>
                    <FontAwesomeIcon icon={faSyncAlt} />
                </button>
            </Row>
            {loading ? <IqLoadingIcon /> :
                <div className="p-3">
                    {error ? (
                        <Alert variant="danger">
                            Unable to fetch routes from gateway
                        </Alert>
                    ) : (
                        <div id="status-container">
                            {services.map(service => {
                                const route = routes.find(r => r.serviceId === service.serviceName);
                                const serviceInstances = route?.serviceInstances || [];
                                return (
                                    <ServiceStatus service={service} serviceInstances={serviceInstances} />
                                )
                            })}
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

export default ServiceStatusContainer;