import React, {useEffect, useState} from 'react';
import './Campaigns.css';
import {Alert, Button, Container, Form, Modal, Row, Spinner} from 'react-bootstrap';
import axios from "axios";
import {frontEndHostName, socialHostName} from "../../utils/Configuration";
import IqLoadingIcon from "../common/IqLoadingIcon";
import translate from "../../i18n/translate";
import {Route, Switch, useHistory, useRouteMatch} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBullhorn, faCalendar, faChevronRight, faPlus, faPowerOff} from '@fortawesome/free-solid-svg-icons';
import ManagedCampaignView from './ManagedCampaignView';
import ManagedCampaign from '../../model/campaigns/ManagedCampaign';
import List from '../../model/list/List';
import Select from 'react-select';
import {Status} from '../../model/campaigns/Status';
import DateFormatter from "../../utils/formatters/DateFormatter";

const Campaigns: React.FC = () => {

    let match = useRouteMatch();
    let history = useHistory();

    const [campaigns, setCampaigns] = useState<ManagedCampaign[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [showListDialog, setShowListDialog] = useState<{ show: boolean, campaignId: number | null }>({ show: false, campaignId: null });


    const fetchCampaigns = async () => {
        setIsLoading(true);
        await axios.get(socialHostName + "managedcampaigns")
            .then(r => {
                setCampaigns(r.data as ManagedCampaign[]);
            })
            .catch(error => console.error(error.message))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (!showListDialog.show) {
            fetchCampaigns();
        }
    }, [showListDialog.show]);


    interface ListOption {
        value: string,
        label: string
    }
    const AddListDialog: React.FC = () => {

        const [userLists, setUserLists] = useState<List[]>([]);
        const [listOptions, setListOptions] = useState<ListOption[]>([]);
        const [loading, setLoading] = useState<boolean>(false);
        const [inProgress, setInProgress] = useState<boolean>(false);
        const [selectedList, setSelectedList] = useState<ListOption | null>(null);

        const handleClose = (e: any) => {
            setShowListDialog({ show: false, campaignId: null });
        }

        const onSubmit = (e: any) => {
            if (selectedList && showListDialog.campaignId) {
                setInProgress(true)
                axios.post(socialHostName + 'managedcampaign/' + showListDialog.campaignId + '/add-list/' + selectedList?.value).then((response) => {
                    if (response.status === 200) {
                        setShowListDialog({ show: false, campaignId: null });
                    }
                }).catch(error => console.log(error.message))
                    .finally(() => setInProgress(false));
            }
        }

        useEffect(() => {
            if (showListDialog) {
                setLoading(true);
                const fetchLists = async () => {
                    await axios.get(frontEndHostName + "user-lists")
                        .then(r => {
                            setUserLists(r.data as List[]);

                            setListOptions(r.data.map((list: List) => {
                                return {
                                    value: `${list.id}`,
                                    label: translate("organisation.addtolist.listlabel", { title: list.title, count: list.organisations.length })
                                }
                            }));
                        })
                        .catch(error => console.error(error.message))
                        .finally(() => {
                            setLoading(false);
                        });

                };

                fetchLists();
            }
        }, [showListDialog]);

        return (<Modal show={showListDialog.show} onHide={() => setShowListDialog({ show: false, campaignId: null })} dialogClassName="modal-dialog modal-full-height modal-right modal-notify modal-success">
            <Modal.Header closeButton>
                <Modal.Title>Attach list to campaign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    {(loading) ? <IqLoadingIcon /> : (
                        <Form.Group style={{ marginTop: 15 }}>
                            <Form.Label>Choose a list to add to the campaign</Form.Label>
                            <Select options={listOptions}
                                onChange={(e: any) => setSelectedList(e)}
                                placeholder={translate("organisation.addtolist.selectplaceholder")}
                                className="basic-multi-select"
                                classNamePrefix="select" />
                        </Form.Group>
                    )}
                </Form.Group>
                <hr />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                {inProgress ? (
                    <Button variant="success" disabled>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        &nbsp;Adding...
                    </Button>
                ) : (
                    <Button variant="success"
                        onClick={onSubmit}>
                        Add
                    </Button>
                )}
            </Modal.Footer>
        </Modal>);
    }

    return (
        <Container fluid style={{ marginTop: 15 }}>

            <Switch>
                <Route exact path={`${match.url}/`}>
                    <AddListDialog />
                    {isLoading ? <IqLoadingIcon /> : (
                        error ?
                            <Alert className="mt-2" variant="danger">
                                {translate("errors.loading")}
                            </Alert>
                            :
                            <>
                                <div className="iq-headline">
                                    <span className="text">
                                        {translate("campaigns.title")}
                                    </span>
                                </div>

                                <Row className="" style={{ marginLeft: 0, marginRight: 0 }}>
                                    <div id="campaign-list-container">
                                        {
                                            campaigns
                                                .map(entry => (
                                                    <div className="campaign-card">
                                                        <div>
                                                            <span className="campaign-magnifier-box">
                                                                <FontAwesomeIcon icon={faBullhorn} color="white" size="1x" />
                                                            </span>
                                                            <span className="name">
                                                                {entry.campaignName}
                                                            </span>
                                                        </div>
                                                        <div className="campaign-content-container">
                                                            <div className="campaign-details-container">
                                                                <div className="campaign-detail">
                                                                    <div className="campaign-detail-container">
                                                                        <span><FontAwesomeIcon style={{ marginLeft: 5, marginRight: 10 }} className="icon" icon={faCalendar} color="black" size="1x" /></span>
                                                                        <span>Started on {DateFormatter.format(entry.createdDate)}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="campaign-detail">
                                                                    <div className="campaign-detail-container">
                                                                        <span><FontAwesomeIcon style={{ marginLeft: 5, marginRight: 10 }} className="icon" icon={faPowerOff} color="black" size="1x" /></span>
                                                                        <span>{entry.status}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="campaign-actions">
                                                            {entry.status === Status.AWAITING_LIST &&
                                                                <div className="campaign-action">
                                                                    <button className="iqx-button sm-size mb-2 pull-right"
                                                                        // disabled={hasExpired(search)}
                                                                        onClick={() => setShowListDialog({ show: true, campaignId: entry.id })}>
                                                                        <span style={{ paddingRight: 10 }}>
                                                                            Add List
                                                                        </span>
                                                                        <FontAwesomeIcon icon={faPlus} size="lg" />
                                                                    </button>
                                                                </div>
                                                            }
                                                            <div className="campaign-action">
                                                                <button className="iqx-button sm-size primary mb-2 pull-right"
                                                                    // disabled={hasExpired(search)}
                                                                    onClick={() => history.push("/campaigns/" + entry.id)}>
                                                                    <span style={{ paddingRight: 10 }}>
                                                                        View Campaign
                                                                    </span>
                                                                    <FontAwesomeIcon icon={faChevronRight} size="lg" />
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))
                                        }
                                    </div>
                                </Row>
                            </>
                    )}
                </Route>
                <Route path={`${match.url}/:campaignId`}>
                    <ManagedCampaignView />
                </Route>
            </Switch>
        </Container>
    );
};

export default Campaigns;