import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {Route, Switch, useHistory, useRouteMatch} from 'react-router-dom';
import ManagedCampaign from '../../model/campaigns/ManagedCampaign';
import ManagedCampaignEditor from './ManagedCampaignEditor';
import {socialHostName} from '../../utils/Configuration';
import './CampaignsManager.css';
import axios from 'axios';
import IqSmallLoadingIcon from '../common/IqSmallLoadingIcon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import LinkedinCampaigns from './LinkedinCampaigns';
import translate from "../../i18n/translate";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {showGenericErrorDialog, showGenericSuccessDialog} from "../common/CommonAlerts";
import DateFormatter from "../../utils/formatters/DateFormatter";

const CampaignsManager: React.FC = () => {

    let match = useRouteMatch();
    let history = useHistory();

    const [managedCampaigns, setManagedCampaigns] = useState<ManagedCampaign[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    interface Editor {
        show: boolean,
        campaign: ManagedCampaign | null
    }
    const [editor, setEditor] = useState<Editor>({ show: false, campaign: null })

    const fetchCampaigns = async () => {
        setIsLoading(true);
        await axios.get(socialHostName + "campaigns-manager")
            .then(r => {
                setManagedCampaigns(r.data as ManagedCampaign[]);
            })
            .catch(error => console.error(error.message))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        fetchCampaigns();
    }, []);

    const onDelete = async (campaignId: number) => {
        await axios.delete(socialHostName + "managedcampaign/" + campaignId)
            .then(r => {
                fetchCampaigns();
                showGenericSuccessDialog();
            })
            .catch(error => showGenericErrorDialog())
            .finally(() => { });
    }

    const showDeleteConfirmDialog = (id: number) => {
        const DeleteConfirmDialog = withReactContent(Swal);
        DeleteConfirmDialog.fire({
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete campaign",
                title: "Do you want to delete this campaign?",
                focusCancel: true
            })
            .then(result => {
                if (result.isConfirmed) {
                    onDelete(id);
                }
            });
    }

    return (
        <Container fluid style={{ marginTop: 15 }}>
            <Switch>
                <Route exact path={`${match.url}/`}>
                    <div className="iq-headline">
                        <span className="text">
                            {translate("campaigns.manager")}
                        </span>
                    </div>
                    {editor.show ?
                        <ManagedCampaignEditor mc={editor.campaign} setEditor={setEditor} />
                        :
                        isLoading ?
                            <IqSmallLoadingIcon />
                            :
                            <div id="campaigns-manager-container">
                                <div className="card create">
                                    <div style={{ height: "100%" }} className="actions">
                                        <button className="button"
                                            onClick={() => { setEditor({ show: true, campaign: null }) }}>
                                            <span style={{ paddingRight: 10 }}>
                                                New Managed Campaign
                                            </span>
                                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                                        </button>
                                        <button className="button"
                                            onClick={() => { history.push("/campaigns-manager/linkedin") }}>
                                            <span style={{ paddingRight: 10 }}>
                                                View my Linkedin campaigns
                                            </span>
                                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                                        </button>
                                    </div>
                                </div>
                                {managedCampaigns.map(mc =>
                                (<div className="card">
                                    <div className="status">
                                        {mc.status ? mc.status : "No Status"}
                                    </div>
                                    <div className="name">
                                        {mc.campaignName}
                                    </div>
                                    <div className="date">
                                        {DateFormatter.format(mc.createdDate)}
                                    </div>
                                    <div className="details">
                                        <div>{mc.creator.firstName + " " + mc.creator.lastName} (manager)</div>
                                        {mc.distributor ? <div>{mc.distributor.companyName} (distributor) ({mc.distributorUsers.length} users)</div> :
                                            <div>No distributor has been specified.</div>}
                                        {mc.partner ? <div>{mc.partner.companyName} (partner) ({mc.partnerUsers.length} users)</div> :
                                            <div>No partner has been specified.</div>}
                                        {mc.vendor ? <div>{mc.vendor.companyName} (vendor) ({mc.vendorUsers.length} users)</div> :
                                            <div>No vendor has been specified.</div>}
                                    </div>
                                    <div className="actions">
                                        <button className="iqx-button sm-size pull-right"
                                            onClick={() => { showDeleteConfirmDialog(mc.id) }}>
                                            <span style={{ paddingRight: 10 }}>
                                                Delete
                                            </span>
                                            <FontAwesomeIcon icon={faTrash} size="1x" />
                                        </button>
                                        <button className="iqx-button sm-size pull-right"
                                            onClick={() => { setEditor({ show: true, campaign: mc }) }}>
                                            <span style={{ paddingRight: 10 }}>
                                                Edit
                                            </span>
                                            <FontAwesomeIcon icon={faEdit} size="1x" />
                                        </button>
                                        <button className="iqx-button sm-size primary mb-2 pull-right"
                                            onClick={() => history.push("/campaigns/" + mc.id)}>
                                            <span style={{ paddingRight: 10 }}>
                                                View
                                            </span>
                                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                                        </button>
                                    </div>
                                </div>)
                                )}
                            </div>
                    }
                </Route>
                <Route path={`${match.url}/linkedin`}>
                    <LinkedinCampaigns />
                </Route>
            </Switch>
        </Container>
    );
};

export default CampaignsManager;