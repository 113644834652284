import React, {memo, useState} from "react";
import NewsItem, {Company} from "../../model/news/NewsItem";
import {Card, Col, Image, Row} from "react-bootstrap";
import "./News.css";
import DateFormatter from "../../utils/formatters/DateFormatter";
import {EmailIcon, EmailShareButton, LinkedinIcon, LinkedinShareButton, TwitterShareButton, XIcon} from "react-share";
import translate from "../../i18n/translate";

interface Props {
    item: NewsItem
}

const NewsCard: React.FC<Props> = (props: Props) => {

    const [expanded, setExpanded] = useState<boolean>(false);

    const truncate = (str: string, length: number) => {
        if (str) {
            let ellipsis = '...';
            return str.length > length ? str.slice(0, length) + ellipsis : str;
        }
    };

    const CompanyLogo: React.FC<Company> = (company: Company) => {
        const { logo } = company;
        const [src, setSrc] = useState<string>(logo ? logo : "./icons_company.svg");

        return (
            <Image title={company.name}
                className="news-company-logo"
                style={{
                    height: "50px",
                    width: "50px",
                    objectFit: "cover",
                    marginRight: 15,
                    border: "2px solid #5FD1F9"
                }}
                src={src}
                onError={() => setSrc("/icons_company.svg")}
                roundedCircle />
        )
    };

    const ViewCompanies = memo((memoProps: {item: NewsItem}) => (<>
        {
            memoProps.item.companies
                .map((company: Company) => (
                    <a href={`/organisation/${company.id}`}>
                        <CompanyLogo {...company} />
                    </a>
                )
                ).slice(0, 5)
        }

        {!expanded ?
            memoProps.item.companies.length > 5 && (
                <div onClick={() => setExpanded(true)}>
                    <div className="news-company-logo">
                        <span className="news-company-logo-helper">
                        </span>
                        <p className="news-company-plus">+{memoProps.item.companies.length - 5}</p>
                    </div>
                </div>
            )
            :
            memoProps.item.companies
                .map((company: Company) => (
                    <a href={`/organisation/${company.id}`}>
                        <CompanyLogo {...company} />
                    </a>
                )
                ).slice(5, memoProps.item.companies.length)
        }
    </>));

    return (
        <Card>
            <Card.Body className="mb-2">
                <Card.Title>
                    <a className="news-title" href={props.item.link} target="_blank">{props.item.title}</a>
                </Card.Title>
                <Card.Text className="mt-1">
                    {props.item.image && (
                        <Image src={props.item.image}
                               style={{
                                   maxHeight: "25vh",
                                   width: "100%",
                                   objectFit: "cover",
                                   marginRight: 15,
                                   marginBottom: 15
                               }}
                        />
                    )}
                    {truncate(props.item.text, 250)}
                </Card.Text>
                <Card.Subtitle>
                    <span className="float-end">
                        <small style={{ fontWeight: 500 }} className="text-muted">{props.item.source}</small>
                    </span>
                </Card.Subtitle>
            </Card.Body>
                {props.item.companies.length > 0 && (
                    <div className="mt-2 ps-3 pe-3">
                        <span className="news-items" title="Companies Mentioned">
                            <ViewCompanies item={props.item}/>
                        </span>
                    </div>
                )}
                <div className="ps-3 pe-3 mb-2">
                    <span className="float-end" style={{fontSize: 12, color: "#929292"}}>
                        {props.item.id}
                    </span>
                </div>
            <Card.Footer>
                <Row>
                    <Col className="pt-1">
                        <span className="float-left">
                            <small style={{fontWeight: 500}} className="text-muted">{DateFormatter.formatDate(props.item?.date?.toString())}</small>
                        </span>
                    </Col>
                    <Col>
                        <span className="float-end">
                            <small className="text-muted me-2">{translate("organisation.temporarylink.share")}:</small>
                            <span title="LinkedIn" className="me-2">
                                <LinkedinShareButton title={props.item.title}
                                                     url={props.item.link}>
                                    <LinkedinIcon size={32} round={true}/>
                                </LinkedinShareButton>
                            </span>
                            <span title="Twitter" className="me-2">
                                <TwitterShareButton title={props.item.title}
                                                    url={props.item.link}>
                                    <XIcon size={32} round={true}/>
                                </TwitterShareButton>
                            </span>
                            <span title="Email">
                                <EmailShareButton subject={props.item.title}
                                                  url={props.item.link}>
                                    <EmailIcon size={32} round={true}/>
                                </EmailShareButton>
                            </span>
                        </span>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    );
};

export default NewsCard;
