import React, {useContext, useEffect, useState} from "react";
import DashboardItemContext from "../../../context/dashboard/DashboardItemContext";
import {Alert, Image} from "react-bootstrap";
import IqProgressBar from "../../common/IqProgressBar";
import {ConsumptionData} from "../../teams/dashboard/TeamDashboardTable";
import axios from "axios";
import {frontEndHostName, organisationHostname, searchHostname} from "../../../utils/Configuration";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {getUserFirstName, getUserImageURL} from "../../../utils/Security";
import {ROW_HEIGHT} from "../Dashboard";
import './DashboardUserProfile.css'
import translate from "../../../i18n/translate";

interface ListConsumptionData {
    listsConsumed: ConsumptionData,
    totalCompanies: ConsumptionData
}

const DashboardUserProfile: React.FC = () => {

    const { maxHeight, setMaxHeight, width, setWidth } = useContext(DashboardItemContext);

    const rowHeight = maxHeight / ROW_HEIGHT;
    const getHeight = () => {
        if (width < 4 && rowHeight > 4) {
            return maxHeight - 180;
        }

        return maxHeight - 110;
    };

    const [loadingSearchCredits, setLoadingSearchCredits] = useState<boolean>(false);
    const [loadingRequestContactsCredits, setLoadingRequestContactsCredits] = useState<boolean>(false);
    const [searchCreditUsage, setSearchCreditUsage] = useState<ConsumptionData>();
    const [requestContactsCredits, setRequestContactsCredits] = useState<ConsumptionData>();
    const [listConsumption, setListConsumption] = useState<ConsumptionData>();
    const [totalCompaniesInLists, setTotalCompaniesInLists] = useState<ConsumptionData>();
    const [loadingLists, setLoadingLists] = useState<boolean>(false);

    const [error, setError] = useState<boolean>(false);
    useEffect(() => {
        const fetchSearchCredits = async () => {
            setLoadingSearchCredits(true);
            await axios.get(searchHostname + "search-credits")
                .then(r => setSearchCreditUsage(r.data))
                .catch(error => console.error(error.message))
                .finally(() => setLoadingSearchCredits(false));
        };

        const fetchRequestContactsCredits = async () => {
            setLoadingRequestContactsCredits(true);
            await axios.get(organisationHostname + "organisation/request-contacts-credits")
                .then(r => setRequestContactsCredits(r.data))
                .catch(error => console.error(error.message))
                .finally(() => setLoadingRequestContactsCredits(false));
        };
        const fetchListStats = async () => {
            setLoadingLists(true);
            await axios.get(frontEndHostName + "list-consumption")
                .then(r => {
                    let listData: ListConsumptionData = r.data;
                    setListConsumption(listData.listsConsumed);
                    setTotalCompaniesInLists(listData.totalCompanies)
                })
                .catch(error => setError(true))
                .finally(() => setLoadingLists(false));
        };

        fetchSearchCredits();
        fetchRequestContactsCredits();
        fetchListStats();
    }, []);

    const [loadingFollowedOfficers, setLoadingFollowedOfficers] = useState<boolean>(false);
    const [followedOfficerConsumption, setFollowedOfficerConsumption] = useState<ConsumptionData>();
    useEffect(() => {
        const fetchFollowedOfficers = async () => {
            setLoadingFollowedOfficers(true);
            await axios.get(frontEndHostName + "officer/follow-consumption")
                .then(r => setFollowedOfficerConsumption(r.data))
                .finally(() => setLoadingFollowedOfficers(false))
        };

        fetchFollowedOfficers();
    }, []);

    const [loadingFollowedOrganisations, setLoadingFollowedOrganisations] = useState<boolean>(false);
    const [followedOrganisationConsumption, setFollowedOrganisationConsumption] = useState<ConsumptionData>();
    useEffect(() => {
        const fetchFollowedOrganisations = async () => {
            setLoadingFollowedOrganisations(true);
            await axios.get(frontEndHostName + "organisation/follow-consumption")
                .then(r => setFollowedOrganisationConsumption(r.data))
                .finally(() => setLoadingFollowedOrganisations(false))
        };

        fetchFollowedOrganisations();
    }, []);

    const isLoading = () => {
        return loadingSearchCredits || loadingLists || loadingRequestContactsCredits || loadingFollowedOfficers || loadingFollowedOrganisations;
    };

    const getPercentage = (consumed: number, capacity: number) => {
        let percentage = (consumed / capacity) * 100;
        return Math.round(percentage * 10) / 10;
    };

    const getImageURL = () => {
        let imageUrl = getUserImageURL();
        if (imageUrl) {
            return imageUrl;
        } else {
            return null;
        }
    };

    const getDaysUntilRefresh = () => {
        let today = new Date();

        let nextMonth = new Date(today.getTime());
        nextMonth.setMonth(today.getMonth() + 1);
        nextMonth.setDate(0);

        let days = nextMonth.getDate() > today.getDate() ? nextMonth.getDate() - today.getDate() : 0;
        return days;
    };


    const showProfileImage = () => {
        return (rowHeight > 1 && width > 3) || (rowHeight > 4);
    };

    const DEFAULT_LOGO: string = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXCRwFAjZrKBBbmGqCMaD13M51N_tmqreLpQ&usqp=CAU";

    const UserLogo: React.FC = () => {
        const [src, setSrc] = useState<string>(getImageURL() ? getImageURL()! : DEFAULT_LOGO);

        return (
            <Image className="profile-avatar"
                   src={src}
                   onError={() => setSrc(DEFAULT_LOGO)}
                   roundedCircle/>
        )
    };

    return (
        <div className="pt-2">
            <div className="profile-header-container">
                <span className="profile-welcome ps-2">
                    {translate("dashboard.profile.welcome", { name: getUserFirstName()})}
                </span>
                {showProfileImage() && (
                    <UserLogo/>
                )}
            </div>
            {error ? (
                <Alert variant="danger">
                    {translate("errors.loading")}
                </Alert>
            ) : (
                    <div>
                        {rowHeight > 2 && (
                            <div>
                                {isLoading() ? <IqLoadingIcon /> : (
                                    <div style={{ height: getHeight(),
                                    maxHeight: getHeight(), 
                                    overflowY: "scroll", 
                                    overflowX: "hidden", 
                                    paddingLeft: 5, 
                                    paddingRight: 5,
                                    display: "flex",
                                    flexDirection: "column" }}>
                                        {listConsumption && (
                                            <div className="mb-2">
                                                {translate("dashboard.profile.listscreated", { count: listConsumption.consumed})} <span className="pull-right strong">{listConsumption.capacity}</span>
                                                <IqProgressBar now={getPercentage(listConsumption.consumed, listConsumption.capacity)} />
                                            </div>
                                        )}
                                        {totalCompaniesInLists && (
                                            <div className="mb-2">
                                                {translate("dashboard.profile.listcompanies", {count: totalCompaniesInLists.consumed})} <span className="pull-right strong">{totalCompaniesInLists.capacity}</span>
                                                <IqProgressBar now={getPercentage(totalCompaniesInLists.consumed, totalCompaniesInLists.capacity)} />
                                            </div>
                                        )}

                                        {followedOrganisationConsumption && (
                                            <div className="mb-2">
                                                {translate("dashboard.profile.followedorganisations", {count: followedOrganisationConsumption.consumed})} <span className="pull-right strong">{followedOrganisationConsumption.capacity}</span>
                                                <IqProgressBar now={getPercentage(followedOrganisationConsumption.consumed, followedOrganisationConsumption.capacity)} />
                                            </div>
                                        )}

                                        {followedOfficerConsumption && (
                                            <div className="mb-2">
                                                {translate("dashboard.profile.followedofficers", {count: followedOfficerConsumption.consumed})} <span className="pull-right strong">{followedOfficerConsumption.capacity}</span>
                                                <IqProgressBar now={getPercentage(followedOfficerConsumption.consumed, followedOfficerConsumption.capacity)} />
                                            </div>
                                        )}

                                        {searchCreditUsage && (
                                            <div className="mb-2">
                                                {translate("dashboard.profile.searchcredits", {count: searchCreditUsage.consumed})} <span className="pull-right strong">{searchCreditUsage.capacity}</span>
                                                <IqProgressBar now={getPercentage(searchCreditUsage.consumed, searchCreditUsage.capacity)} />
                                                <span className="profile-credit-reset pull-right mt-1">
                                                    {translate("dashboard.profile.creditrefresh", {days: getDaysUntilRefresh()})}
                                                </span>
                                            </div>
                                        )}
                                        {requestContactsCredits && (
                                            <div className="mb-2">
                                                {translate("dashboard.profile.contactrequests", {count: requestContactsCredits.consumed})} <span className="pull-right strong">{requestContactsCredits.capacity}</span>
                                                <IqProgressBar now={getPercentage(requestContactsCredits.consumed, requestContactsCredits.capacity)} />
                                                <span className="profile-credit-reset pull-right mt-1">
                                                    {translate("dashboard.profile.creditrefresh", {days: getDaysUntilRefresh()})}
                                                </span>
                                            </div>
                                        )}
                                        
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
        </div>
    )
};

export default DashboardUserProfile;
