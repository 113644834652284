import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Image} from "react-bootstrap";
import {
    faBriefcase,
    faChevronLeft,
    faEnvelope,
    faFolder,
    faMobile,
    faPhone,
    faPhoneSquareAlt
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Organisation from "../../model/organisation/Organisation";
import NylasContact from "../../model/organisation/social/NylasContact";

interface Props {
    organisation: Organisation,
    emailId: string
    contactName: string
    contact: NylasContact
}

const ContactHeader: React.FC<Props> = (props: Props) => {
    const history = useHistory();
    const [formattedAddress, setFormattedAddress] = useState("");


    useEffect(() => {
        let formattedAddress = [];
        if (props.organisation.addressLine1 != null && props.organisation.addressLine1.trim() != "") formattedAddress.push(props.organisation.addressLine1)
        if (props.organisation.addressLine2 != null && props.organisation.addressLine2.trim() != "") formattedAddress.push(props.organisation.addressLine2)
        if (props.organisation.postTown != null && props.organisation.postTown.trim() != "") formattedAddress.push(props.organisation.postTown)
        if (props.organisation.postCode != null && props.organisation.postCode.trim() != "") formattedAddress.push(props.organisation.postCode)

        setFormattedAddress(formattedAddress.join(", "));

    }, [props.organisation]);

    const getImageUrl = () => {
        if (!props.contact.picture_url) {
            return "/icons_profil.svg";
        }

        return props.contact.picture_url;
    };

    const getJobTitle = () => {
        if (props.contact.jobTitle && props.contact.jobTitle.length > 0) {
            return props.contact.jobTitle[0];
        }
        return '-'
    }

    const getPhoneNumber = () => {
        if (props.contact.phoneNumbers && props.contact.phoneNumbers.length > 0) {
            return props.contact.phoneNumbers.map((value, index) => {
                if (value.type === "MOBILE") {
                    return <div style={{fontSize: 16}}>
                        <FontAwesomeIcon style={{marginRight: 10}}
                                         icon={faMobile}/>
                        {value.number}
                    </div>
                } else {
                    return <div style={{fontSize: 16}}>
                        <FontAwesomeIcon style={{marginRight: 10}}
                                         icon={faPhoneSquareAlt}/>
                        {value.number}
                    </div>
                }
            })
        }
        return <div style={{fontSize: 16}}>
            <FontAwesomeIcon style={{marginRight: 10}}
                             icon={faPhoneSquareAlt}/>
            -
        </div>
    }

    const CompanyLogo: React.FC<Organisation> = (org: Organisation) => {
        const [src, setSrc] = useState<string>(org.imageUrl ? org.imageUrl : "./icons_company.svg");

        return (
            <Image src={src}
                   title={org.companyName}
                   onError={() => setSrc("/icons_company.svg")}/>
        );
    };


    return (
        <div>
            <button style={{background: "white"}} className="iqx-button iconic md-size pe-2 pe-3"
                    onClick={() => history.push('/organisation/' + props.organisation.id + '/people')}>
                <span className="pe-2"><FontAwesomeIcon icon={faChevronLeft}/></span>
                <span>Back</span>
            </button>
            <div style={{marginLeft: 0, marginRight: 0, paddingTop: 15}}>
                <div style={{paddingLeft: 0, paddingRight: 0}}>
                    <div id="mycontact-container-header">
                                                <span id="company-summary-container">
                                                    <Image style={{height: "75px", width: "75px", objectFit: "cover"}}
                                                           src={getImageUrl()}
                                                           roundedCircle/>
                                                    <span id="company-summary">
                                                        <div style={{
                                                            fontSize: 24,
                                                            fontWeight: "bold"
                                                        }}>{props.contactName}</div>
                                                         <div style={{fontSize: 16}}>
                                                               <FontAwesomeIcon style={{marginRight: 10}}
                                                                                icon={faBriefcase}/>
                                                             {getJobTitle()}
                                                         </div>
                                                         <div style={{fontSize: 16}}>
                                                             <FontAwesomeIcon style={{marginRight: 10}}
                                                                              icon={faEnvelope}/>
                                                             {props.emailId}
                                                         </div>
                                                        {getPhoneNumber()}
                                                    </span>
                                                </span>
                        <div style={{marginLeft: "auto"}}>
                                <span className="company-logo">
                                    <span className="company-logo-helper">
                                    </span>
                                    <CompanyLogo {...props.organisation} />
                                </span>
                            <span id="company-summary" style={{textAlign: "right"}}>
                                    Status: {props.organisation.companyStatus}
                                <div style={{
                                    fontSize: 24,
                                    fontWeight: "bold",
                                    maxWidth: "45rem",
                                    textAlign: "right"
                                }}>{props.organisation.companyName}</div>
                                    <div className="mb-1" style={{fontSize: 11, textAlign: "right"}}>
                                        {
                                            formattedAddress
                                        }
                                    </div>
                                {props.organisation.phoneNumber && (
                                    <div style={{fontSize: 11}}>
                                        <FontAwesomeIcon style={{marginRight: 10}}
                                                         icon={faPhone}/>{props.organisation.phoneNumber}
                                    </div>
                                )}
                                <div style={{fontSize: 11}}>
                                        <FontAwesomeIcon style={{marginRight: 10}}
                                                         icon={faFolder}/>{props.organisation.primaryType}
                                    </div>
                                </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ContactHeader;
