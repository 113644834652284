import React, {useEffect, useState} from "react";
import {faShareAltSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {OrganisationUser} from "../../teams/edit/EditTeam";
import axios from "axios";
import {frontEndHostName, organisationHostname} from "../../../utils/Configuration";
import {Col, Dropdown, Form, Row, Table} from "react-bootstrap";
import IqLoadingIcon from "../IqLoadingIcon";
import Pagination from "../../../utils/PaginationHook";
import UserAvatar from "../UserAvatar";

interface Props {
    filters: any
}

const ShareContacts: React.FC<Props> = (props: Props) => {

    const [userIds, setUserIds] = useState<number[]>([]);
    const [organisationUsers, setOrganisationUsers] = useState<OrganisationUser[]>([]);
    const [userPage, setUserPage] = useState<OrganisationUser[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState(1);

    const fetchOrganisationUsers = async () => {
        setLoading(true);

        await axios.get(frontEndHostName + "users/organisation/active")
            .then(r => {
                let data = r.data;
                setOrganisationUsers(data);
                setTotalRecords(data.length);
            })
            .catch((error) => console.error(error.message))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchOrganisationUsers();
    }, []);

    useEffect(() => {
        if (organisationUsers && organisationUsers.length > 0) {
            let start = (currentPage - 1) * 10;
            let users = organisationUsers.slice(start, start + 10);

            setUserPage(users);
        }
    }, [organisationUsers, currentPage]);

    const handleCheck = (userId: number) => {
        let selected = userIds.find(id => userId === id);
        if (selected) {
            let newSelection = userIds.filter(u => u === userId);
            setUserIds(newSelection);
        }
        else {
            setUserIds([
                ...userIds,
                userId
            ]);
        }
    }

    const selectAll = () => {
        let all = organisationUsers.map(user => user.id);
        setUserIds(all);
    }

    const shareFilter = async () => {
        let postData = {
            userIds: userIds,
            filters: props.filters
        }

        await axios.post(organisationHostname + "organisation/request-contacts-filters/share", postData)
            .then(() => setUserIds([]));
    }

    return (
        <Dropdown as="span">
            <Dropdown.Toggle id="export" className="export-toggle" variant="link" as="span" size="sm">
                <FontAwesomeIcon title="Share" icon={faShareAltSquare} style={{ marginLeft: 2, marginRight: 8, cursor: "pointer" }} color="lightgreen"></FontAwesomeIcon>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{minWidth: "350px"}} className="mt-1 p-3" align="end">
                {loading ? <IqLoadingIcon /> : (
                    <div>
                        <Row className="text-end mb-2">
                            <Col>
                                <button className="iqx-button secondary sm-size" onClick={() => setUserIds([])} disabled={userIds.length === 0}>Deselect All</button>
                                <button className="iqx-button primary sm-size" onClick={selectAll}>Select All</button>
                            </Col>
                        </Row>
                        <Table striped hover>
                            <tbody>
                                {userPage.map(user => (
                                    <tr onClick={() => handleCheck(user.id)} style={{cursor: "pointer"}}>
                                        <td>
                                            <Form.Check type="checkbox"
                                                        checked={userIds.indexOf(user.id) !== -1}
                                                        onChange={() => handleCheck(user.id)}/>
                                        </td>
                                        <td>
                                            <UserAvatar user={user} width="20px" height="20px" />
                                        </td>
                                        <td>
                                            {user.firstName} {user.lastName}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <Pagination
                            totalRecords={totalRecords}
                            pageLimit={10}
                            initPage={currentPage}
                            pageRangeDisplayed={1}
                            onChangePage={setCurrentPage}
                        />
                    </div>
                )}
                <div className="pull-right text-end mt-1">
                    <button className="iqx-button primary sm-size" onClick={shareFilter}>Share</button>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default ShareContacts;