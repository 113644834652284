import React, {useContext, useEffect, useState} from "react";
import UserOrganisationContext from "../../../context/organisation/OrganisationContext";
import {Alert} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {organisationHostname} from "../../../utils/Configuration";
import './OrganisationContacts.css'
import axios from 'axios';
import OrganisationContactEntry from "./OrganisationContactEntry";
import {OrganisationContact} from "../../../model/organisation/OrganisationContact";
import OrganisationRequestContactsModal from "./OrganisationRequestContactsModal";
import ContactFiltersModal, {ContactFilters} from "../../contacts/ContactFiltersModal";
import ContactSortSelect from "../../contacts/ContactSortSelect";
import translate from "../../../i18n/translate";
import Pagination from "../../../utils/PaginationHook";

const OrganisationContacts: React.FC = () => {
    const { organisation } = useContext(UserOrganisationContext);
    const [contacts, setContacts] = useState<OrganisationContact[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [sort, setSort] = useState<string>();
    const [currentPage, setCurrentPage] = useState(1);
    const contactsPerPageLimit: number = 15;
    const [totalRecords, setTotalRecords] = useState(1);

    const [filters, setFilters] = useState<ContactFilters>();
    useEffect(() => {
        const fetchContacts = async () => {
            setLoading(true);

            let params: any = {
                sort: sort,
                page: currentPage - 1,
                size: contactsPerPageLimit
            };

            let postData = {
                jobTitles: filters?.jobTitles,
                roles: filters?.roles,
                seniority: filters?.seniority,
                countries: filters?.countries,
                userId: filters?.userId,
                from: filters?.from,
                to: filters?.to
            };

            await axios.post(organisationHostname + "organisation/" + organisation.id + "/contacts", postData, { params: params })
                .then(r => {
                    setContacts(r.data.content);
                    setTotalRecords(r.data.totalElements);
                })
                .finally(() => setLoading(false));
        };

        fetchContacts();
    }, [sort, filters, organisation.id, currentPage]);

    const setSortBy = (e: any) => {
        setSort(e.target.value);
    };

    return (
        <div style={{ paddingTop: 10, padding: 15, marginTop: 10, width: "100%" }}>
            <div className="request-contacts-actions mb-3">
                <div className="request-contacts me-3">
                    <OrganisationRequestContactsModal />
                </div>
                <div className="sort-filter">
                    <div className="me-3">
                        <ContactFiltersModal setFilters={setFilters} />
                    </div>
                    <div className="me-3">
                        <ContactSortSelect setSortBy={setSortBy} />
                    </div>
                </div>
            </div>
            {loading ? <IqLoadingIcon /> : (
                <div>
                    {contacts?.length > 0 ? (
                        <div className="contacts-container">
                            {contacts.map(contact => <OrganisationContactEntry contact={contact} showOrganisation={false} />)}
                        </div>
                    ) : (
                        <Alert variant="warning" style={{ width: "100%", marginTop: 25 }}>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", alignContent: "center" }}>
                                <FontAwesomeIcon icon={faExclamationCircle} color="#367CDB" size="2x" />
                                <span style={{ marginLeft: 15 }}>{translate("contacts.nocontacts.organisation")}</span>
                            </div>
                        </Alert>
                    )}
                    <div style={{ marginTop: 10 }} className="pull-right">
                        <Pagination
                            totalRecords={totalRecords}
                            pageLimit={contactsPerPageLimit}
                            initPage={currentPage}
                            pageRangeDisplayed={1}
                            onChangePage={setCurrentPage}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrganisationContacts;
