import React, { useContext, useEffect } from "react";
import { useState } from "react";
import {Col, Container, Row } from "react-bootstrap";
import { Route, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import OrganisationContext from "../../../context/organisation/OrganisationContext";
import PublicSectorContext from "../../../context/PublicSectorContext";
import translate from "../../../i18n/translate";


export enum SectorType{
    CONTRACT = "contract",
    FRAMEWORK = "framework",
    FUNDING = "funding"
}

const OrganisationPublicSectorView: React.FC = () => {

    const {
        ccsFrameworks,
        gCloudTotal,
        fundingTotal,
    } = useContext(PublicSectorContext);

    const {organisation} = useContext(OrganisationContext);

    let match = useRouteMatch();
    let history = useHistory();
    const location = useLocation();

    const [sectorSelected, setSectorSelected] = useState<SectorType>(SectorType.CONTRACT);
    
    return (
            <Container fluid style={{marginTop: 15, marginRight: "auto", paddingLeft: 15}}>
                <Row className="mb-3">
                    <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                        <div className="iq-headline">
                        <span className="text">
                            {translate("contracts.nav")}
                        </span>
                        </div>
                    </Col>
                </Row>
                <Row className="" style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                    <div style={{marginLeft: "auto", marginRight: "auto"}} className="iq-button-group">
                        <button className={window.location.pathname.includes("contracts") ? "selected-inverted" : ""}
                                onClick={() => {history.push(`${match.url}/contracts`)  }}>
                            Contracts
                        </button>
                        {ccsFrameworks.length !== 0 && (
                            <button className={window.location.pathname.includes("frameworks") ? "selected-inverted" : ""}
                                    onClick={() => { history.push(`${match.url}/frameworks`) }}>
                                CCS Frameworks
                            </button>
                        )}
                        {(gCloudTotal !== 0  && organisation.primaryType != "PublicSector") && (
                            <button className={window.location.pathname.includes("g-cloud") ? "selected-inverted" : ""}
                                    onClick={() => { history.push(`${match.url}/g-cloud`) }}>
                                G-Cloud Supplier
                            </button>
                        )}
                        {fundingTotal !== 0 && (
                            <button className={window.location.pathname.includes("funding") ? "selected-inverted" : ""}
                                    onClick={() => { history.push(`${match.url}/funding`) }}>
                                R&D Funding
                            </button>
                        )}
                    </div>
                </Row>
            </Container>


        );
}

export default OrganisationPublicSectorView;