import React from "react";
import Organisation from "../../model/organisation/Organisation";
import CaseStudy from "../../model/organisation/CaseStudy";
import YoutubeVideo from "../../model/organisation/social/YoutubeVideo";
import WhitePaper from "../../model/organisation/WhitePaper";
import InvestorRelations from "../../model/organisation/InvestorRelations";
import { StateHandler } from "../../model/organisation/editor/StateHandler";

const intialStateHandler = {loading: false, success:false, error: {state: false, message: ""}}

const UserOrganisationContext = React.createContext({
    organisation: {} as Organisation,
    setOrganisation: (list: Organisation) => {},
    caseStudiesResponse: [] as CaseStudy[],
    setCaseStudiesResponse: (data: CaseStudy[]) => {},
    caseStudySearchTerm: "",
    setCaseStudySearchTerm: (term: string) => {},
    caseStudiesState: intialStateHandler,
    setCaseStudiesState: (data: StateHandler) => {},
    youtubeCaseStudies: [] as YoutubeVideo[],
    setYoutubeCaseStudies: (data: YoutubeVideo[]) => {},
    whitePaperResponse: [] as WhitePaper[],
    setWhitePaperResponse: (data: WhitePaper[]) => {},
    whitePaperSearchTerm: "",
    setWhitePaperSearchTerm: (term: string) => {},
    whitePaperState: intialStateHandler,
    setWhitePaperState: (data: StateHandler) => {},
    investorRelationsState: intialStateHandler,
    setInvestorRelationsState: (data: StateHandler) => {},
    investorRelationsResponse: [] as InvestorRelations[],
    setInvestorRelationsResponse: (data: InvestorRelations[]) => {},
    loading: false,
    setLoading: (isLoading: boolean) => {},
    fetchCompany: () => {}
});

export default UserOrganisationContext;
