import React, {useContext, useEffect, useState} from "react";
import {Alert, Button, Card, Col, Image, Row} from "react-bootstrap";
import './OrganisationMyContacts.css'
import IqLoadingIcon from "../../common/IqLoadingIcon";
import translate from "../../../i18n/translate";
import NylasContact from "../../../model/organisation/social/NylasContact";
import {socialHostName} from "../../../utils/Configuration";
import axios from "axios";
import Pagination from "../../../utils/PaginationHook";
import Select from "react-select";
import UserOrganisationContext from "../../../context/organisation/OrganisationContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";
import OrganisationPeopleContext from "../../../context/organisation/OrganisationPeopleContext";


interface NylasActiveProps {
    nylasActive: boolean;
}

interface SelectOption {
    value: string,
    label: string
}

const OrganisationMyContacts: React.FC<NylasActiveProps> = (props: NylasActiveProps) => {

    const history = useHistory();
    const {organisation} = useContext(UserOrganisationContext);
    const {contacts, setContacts} = useContext(OrganisationPeopleContext);
    const [noOfContacts, setNoOfContacts] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);

    const pageLimitOptions: SelectOption[] = [25, 50, 75, 100].map(i =>
        ({
            value: String(i),
            label: String(i) + " per page"
        })
    )
    const [selectedPageLimit, setSelectedPageLimit] = useState<SelectOption>(pageLimitOptions[0]);

    const sourceOptions: SelectOption[] = [{value: "inbox", label: "Inbox"}, {
        value: "address_book",
        label: "Contacts"
    }].map(source =>
        ({
            value: String(source.value),
            label: String(source.label)
        })
    )

    const sortByOptions: SelectOption[] = [
        {
            value: "nameAToZ",
            label: "Name: A-Z"
        },
        {
            value: "nameZToA",
            label: "Name: Z-A"
        },
        {
            value:"inboundAsc",
            label: "Inbound Emails: Asc"
        },
        {
            value:"inboundDesc",
            label: "Inbound Emails: Desc"
        },
        {
            value:"outboundAsc",
            label: "Outbound Emails: Asc"
        },
        {
            value:"outboundDesc",
            label: "Outbound Emails: Desc"
        }
    ];

    const [sort, setSort] = useState<SelectOption>(sortByOptions[2])
    const [source, setSource] = useState<SelectOption>(sourceOptions[0]);

    const sortContacts = (sort: SelectOption, contactsToSort: NylasContact[]) => {
        switch (sort.value) {
            case "nameAToZ":
                return contactsToSort.sort(function (a, b) {
                    if (a.givenName < b.givenName) {
                        return -1;
                    }
                    if (a.givenName > b.givenName) {
                        return 1;
                    }
                    return 0;
                })
            case "nameZToA":
                return contactsToSort.sort(function (a, b) {
                    if (a.givenName > b.givenName) {
                        return -1;
                    }
                    if (a.givenName < b.givenName) {
                        return 1;
                    }
                    return 0;
                })
            case "inboundAsc":
                return contactsToSort.sort(function (a, b) {
                    if (a.inboundCount > b.inboundCount) {
                        return -1;
                    }
                    if (a.inboundCount < b.inboundCount) {
                        return 1;
                    }
                    return 0;
                });
            case "inboundDesc":
                return contactsToSort.sort(function (a, b) {
                    if (a.inboundCount < b.inboundCount) {
                        return -1;
                    }
                    if (a.inboundCount > b.inboundCount) {
                        return 1;
                    }
                    return 0;
                })
            case "outboundAsc":
                return contactsToSort.sort(function (a, b) {
                    if (a.outboundCount > b.outboundCount) {
                        return -1;
                    }
                    if (a.outboundCount < b.outboundCount) {
                        return 1;
                    }
                    return 0;
                })
            case "outboundDesc":
                return contactsToSort.sort(function (a, b) {
                    if (a.outboundCount < b.outboundCount) {
                        return -1;
                    }
                    if (a.outboundCount > b.outboundCount) {
                        return 1;
                    }
                    return 0;
                })

        }
    }


    const ProfileImage = (contact: NylasContact) => {
        const [src, setSrc] = useState<string>(contact.picture_url ? contact.picture_url : "/icons_profil.svg");
        return (
            <Image src={src}
                   onError={() => setSrc("/icons_profil.svg")}
                   style={{
                       height: "70px",
                       width: "70px",
                       objectFit: "cover",
                       marginRight: "auto",
                       marginLeft: "auto",
                       marginBottom: 15,
                       border: "2px solid #5FD1F9"
                   }}
                   roundedCircle/>
        );
    }

    interface ContactProps {
        contact: NylasContact
    }

    function capitalise(text: string) {
        return text
            .toLowerCase()
            .split(' ')
            .map(function (word) {
                return word[0].toUpperCase() + word.substr(1);
            })
            .join(' ');
    }

    const ContactCards: React.FC<ContactProps> = (contactProps: ContactProps) => {
        let name: string;
        if (contactProps.contact.givenName === null) {
            name = contactProps.contact.emails[0].email;
        } else {
            name = contactProps.contact.givenName + " " + contactProps.contact.surname
        }
        return (
            <Card className="my-contact-card pb-3" style={{margin: "5px", marginBottom: "4em"}}>
                <div className="text-center pt-3">
                    <div className="pt-1">
                        <a className="contact-title"
                           onClick={() => history.push(`/contact/${contactProps.contact.id}?organisation=${organisation.id}`, {
                               contactEmailId: contactProps.contact.emails[0].email,
                               contactName: name,
                               contactId: contactProps.contact.id,
                               contacts: contacts,
                               organisation: organisation
                           })}>
                            {name}

                        </a>
                    </div>
                    <hr/>
                    <ProfileImage {...contactProps.contact}/>
                    <div className="pb-3">
                        {contactProps.contact.emails.map((email, index) => {
                            return (
                                <span style={{textAlign: "center"}} key={index}
                                      className="contact-email">{email.email}</span>
                            );
                        })}

                        <span className="contact-source">Source: {capitalise(contactProps.contact.source)}</span>
                        <span className="contact-inbound">Inbound Emails: {contactProps.contact.inboundCount}</span>
                        <span className="contact-outbound">Outbound Emails: {contactProps.contact.outboundCount}</span>
                    </div>
                </div>
            </Card>
        );
    }

    const connectToNylas = (e: any) => {
        e.preventDefault();
        axios.get(socialHostName + "nylas")
            .then((response) => {
                window.location.href = (response.data)
            }).catch(error => setError(true));
    }

    useEffect(() => {
        const fetchMyContactsSize = async () => {
            await axios.get(socialHostName + "contacts/size?organisationUrl=" + organisation.companyUrl + "&source=" + source.value)
                .then((response) => {
                    setNoOfContacts(response.data)
                }).catch(error => setError(true));
        }
        fetchMyContactsSize();


    }, [source])

    useEffect(() => {
        setLoading(true)
        const fetchMyContacts = async () => {
            await axios.get(socialHostName + "contacts?organisationUrl=" + organisation.companyUrl + "&source="
                + source.value + "&pageSize=" + selectedPageLimit.value + "&currentPage=" + currentPage + "&sort=" + sort.value)
                .then((response) => {
                    let difference = response.data.filter((x: NylasContact) => !contacts.includes(x));
                    let nylasContacts = sortContacts(sort,difference);
                    if (nylasContacts){
                        setContacts(nylasContacts);
                    }
                }).catch(error => setError(true))
                .finally(() => {
                    setLoading(false);
                });
        }
        fetchMyContacts();
    }, [organisation.companyUrl, selectedPageLimit.value, currentPage, source.value])


    return (
        <div style={{paddingTop: "5px", overflowX: "hidden", width: "100%"}}>
            {loading ? <div><IqLoadingIcon/>
                <div style={{
                    paddingTop: 10,
                    backgroundColor: "white",
                    borderRadius: "0.45rem",
                    padding: 15,
                    marginTop: 10,
                    textAlign: "center"
                }}>
                    Contact discovery is syncing, this might take upto 60 seconds or more.
                </div>
            </div> : (
                noOfContacts > 0 ?
                    <div style={{
                        paddingTop: 10,
                        backgroundColor: "white",
                        borderRadius: "0.45rem",
                        padding: 15,
                        marginTop: 10
                    }}>
                        <Row>
                            <Col className="pt-2" xl={2} lg={3} md={6} sm={6} xs={6}>
                                <Select
                                    options={pageLimitOptions}
                                    value={selectedPageLimit}
                                    onChange={(e: any) => {
                                        setSelectedPageLimit(e);
                                        setCurrentPage(1)
                                    }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"/>
                            </Col>
                            <Col className="pt-2" xl={2} lg={3} md={6} sm={6} xs={6}>
                                <Select
                                    options={sortByOptions}
                                    value={sort}
                                    onChange={(e: any) => {
                                        setSort(e);
                                        sortContacts(e, contacts)
                                    }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"/>
                            </Col>
                            <Col className="pt-2" xl={2} lg={3} md={6} sm={6} xs={6}>
                                <Select
                                    options={sourceOptions}
                                    value={source}
                                    onChange={(e: any) => {
                                        setSource(e);
                                    }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"/>
                            </Col>

                            <Col style={{marginLeft: 0, marginRight: 0, paddingTop: 5}}>
                                <div style={{float: "right"}}>
                                    <Pagination
                                        totalRecords={noOfContacts}
                                        pageLimit={+selectedPageLimit.value}
                                        pageRangeDisplayed={1}
                                        initPage={currentPage}
                                        onChangePage={setCurrentPage}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div>
                            {error ? (
                                <Alert variant="danger">
                                    {translate("errors.loading")}
                                </Alert>
                            ) : (
                                <div>
                                    <div style={{
                                        height: "34px",
                                        color: "#212121",
                                        fontSize: "28px",
                                        fontWeight: 100
                                    }}>
                                        {noOfContacts}&nbsp;Contacts
                                    </div>

                                    <Row className="justify-content-md-center mt-2">
                                        {
                                            contacts.map((contact, index) => <ContactCards key={index}
                                                                                           contact={contact}/>)
                                        }

                                    </Row>
                                </div>
                            )}
                        </div>
                    </div>
                    :
                    <div>

                        <Alert variant="warning" style={{width: "100%", marginTop: 25}}>
                            <div style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                alignContent: "center"
                            }}>

                                <FontAwesomeIcon icon={faExclamationCircle} color="#367CDB" size="2x"/>
                                {props.nylasActive ? (
                                    <span style={{marginLeft: 15, paddingRight: 20}}>Unfortunately, There are no email contacts for this organisation.</span>
                                ) : (
                                    <>
                                        <span style={{marginLeft: 15, paddingRight: 20}}>Unfortunately, there is no registration for your email</span>
                                        <Button onClick={connectToNylas}>Connect to your Email</Button>
                                    </>
                                )}
                            </div>
                        </Alert>
                    </div>
            )}
        </div>
    );
}

export default OrganisationMyContacts;