import React, {useEffect, useState} from "react";
import {Col, Image, Row} from "react-bootstrap";
import NylasContact from "../../model/organisation/social/NylasContact";
import {useHistory} from "react-router-dom";
import Organisation from "../../model/organisation/Organisation";
import ContactType from "./ContactType";
import './ContactNavigation.css'

interface Props {
    contactsList: NylasContact[],
    contact: string,
    organisation: Organisation
}

const ContactNavigation: React.FC<Props> = (props: Props) => {

        const history = useHistory();
        const [previousOrg, setPreviousOrg] = useState<NylasContact>();
        const [nextOrg, setNextOrg] = useState<NylasContact>();
        const [contacts, setContacts] = useState<NylasContact[]>([]);


        useEffect(() => {
            setContacts(props.contactsList);
        }, [props.contactsList])

        useEffect(() => {
            let number = contacts.findIndex(value => value.id === props.contact);
            if (number > 0) {
                let prev = number - 1;
                setPreviousOrg(contacts[prev]);
            }


            if (number < contacts.length) {
                let next = number + 1;
                setNextOrg(contacts[next]);
            }

        }, [contacts, props.contact]);

        useEffect(() => {


        }, [previousOrg, nextOrg])


        const ProfileLogo: React.FC<NylasContact> = (org: NylasContact) => {
            const [src, setSrc] = useState<string>(org.picture_url ? org.picture_url : "/icons_profil.svg");

            return (
                <Image style={{height: "40px", width: "40px", objectFit: "cover"}}
                       title={org.givenName}
                       src={src}
                       onError={() => setSrc("/icons_profil.svg")}
                       roundedCircle/>
            );
        };

        const getName = (nylasContact: NylasContact) => {

            if (nylasContact.givenName === null) {
                return nylasContact.emails[0].email;
            } else {
                return nylasContact.givenName + " " + nylasContact.surname
            }
        }


        return (
            <div>
                <Row className="pt-3" style={{marginLeft: 0, marginRight: 0}}>
                    <Col>
                        <div className="contact-navigation-container">
                            {previousOrg ? (
                                    <a className="contact-navigation-link"
                                       onClick={() => history.push(`/contact/${previousOrg.id}?organisation=${props.organisation.id}`, {
                                           contactEmailId: previousOrg.emails[0].email,
                                           contactName: getName(previousOrg),
                                           contactId: previousOrg.id,
                                           contacts: contacts,
                                           organisation: props.organisation
                                       })}>

                                        <div className="arrow-left">
                                            <ProfileLogo {...previousOrg} />
                                            <span style={{color:"white"}} className="text">
                                                {getName(previousOrg)}
                                    </span>
                                        </div>
                                    </a>
                                )
                                :
                                <div/>
                            }
                            {nextOrg ? (
                                    <a className="contact-navigation-link"
                                       onClick={() => history.push(`/contact/${nextOrg.id}?organisation=${props.organisation.id}`, {
                                           contactEmailId: nextOrg.emails[0].email,
                                           contactName: getName(nextOrg),
                                           contactId: nextOrg.id,
                                           contacts: contacts,
                                           organisation: props.organisation
                                       })}>
                                        <div className="arrow-right">
                                    <span style={{color:"white"}} className="text">
                                        {getName(nextOrg)}
                                    </span>
                                            <ProfileLogo {...nextOrg} />
                                        </div>
                                    </a>
                                )
                                :
                                <div/>
                            }
                        </div>
                    </Col>
                </Row>
                <div style={{paddingTop: -50}}>
                    <ContactType/>
                </div>
            </div>
        )
    }
;

export default ContactNavigation;
