import {createIntl, createIntlCache} from "react-intl";
import messages_en from "./messages/messages_en";
import messages_de from "./messages/messages_de";
import messages_fr from "./messages/messages_fr";
import messages_ro from "./messages/messages_ro";
import {getUserLangKey} from "../utils/Security";
import Cookies from "universal-cookie";
import {formatLocale, FormatLocaleDefinition} from "d3-format";

export const messages: any = {
    en: messages_en,
    de: messages_de,
    fr: messages_fr,
    ro: messages_ro
};

export const flattenMessages = ((nestedMessages: any, prefix = '') => {
    if (nestedMessages === null) {
        return {}
    }
    return Object.keys(nestedMessages).reduce((messages, key) => {
        const value       = nestedMessages[key]
        const prefixedKey = prefix ? `${prefix}.${key}` : key

        if (typeof value === 'string') {
            Object.assign(messages, { [prefixedKey]: value })
        } else {
            Object.assign(messages, flattenMessages(value, prefixedKey))
        }

        return messages
    }, {})
});

const cache = createIntlCache();

const cookies = new Cookies();
let locale = cookies.get("locale");

export const userLanguage = locale ? locale : getUserLangKey();
let intl = createIntl(
    {
        locale: userLanguage,
        messages: flattenMessages(messages[userLanguage])
    },
    cache
);


export const changeLanguage = (lang: string) => {
    const newIntl = createIntl(
        {
            locale: lang,
            messages: flattenMessages(messages[lang])
        },
        cache
    );

    intl = newIntl;
    cookies.set("locale", lang);
};

const translate = (id: any, values?: {}) => {
    return intl.formatMessage({id}, values);
};

export const getLocaleFormat = () => {
    if (userLanguage === "de" || userLanguage === "fr") {
        return formatLocale({
            currency:  ["€", ""]
        } as FormatLocaleDefinition);
    }
    else {
        return formatLocale({
            currency:  ["£", ""]
        } as FormatLocaleDefinition);
    }
};

export default translate;
