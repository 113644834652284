import React, {useContext, useEffect, useState} from "react";
import PublicSectorContext, {CCSFilters, CCSSort} from "../../../context/PublicSectorContext";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {Alert, Form, Modal, Table} from "react-bootstrap";
import DateFormatter from "../../../utils/formatters/DateFormatter";
import Pagination from "../../../utils/PaginationHook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faSort, faSortAmountDown, faSortAmountDownAlt} from "@fortawesome/free-solid-svg-icons";
import {useHistory, useRouteMatch} from "react-router-dom";
import CCSFrameworkFilters from "./CCSFrameworkFilters";
import {Switch} from "antd";
import translate from "../../../i18n/translate";

const CCSFrameworks: React.FC = () => {

    let history = useHistory();
    let match = useRouteMatch();

    const {
        ccsFrameworks,
        loadingCCS,
        setLoadingCCS,
        ccsPage,
        setCCSPage,
        ccsPageSize,
        setCCSPageSize,
        ccsTotal,
        ccsSort,
        setCCSSort,
        ccsDesc,
        setCCSDesc,
        ccsFilters,
        setCCSFilters
    } = useContext(PublicSectorContext);

    const [hideExpired, setHideExpired] = useState<boolean>(true);
    useEffect(() => {
        const toggleHideExpiredFrameworks = (switchStateValue: boolean) => {
            setLoadingCCS(true);
            let filters = {
                frameworkIds: ccsFilters?.frameworkIds || [],
                searchTerm: ccsFilters?.searchTerm || "",
                startDateFrom: ccsFilters?.startDateFrom || "",
                startDateTo: ccsFilters?.startDateTo || "",
                endDateFrom: ccsFilters?.endDateFrom || "",
                endDateTo: ccsFilters?.endDateTo || ""
            } as CCSFilters;

            filters.endDateFrom = "";
            if (switchStateValue) {
                filters.endDateFrom = new Date().toISOString().slice(0, 10);
            }

            setCCSFilters(filters);
            setHideExpired(switchStateValue);
            setLoadingCCS(false);
        }
        toggleHideExpiredFrameworks(hideExpired);
    }, [hideExpired]);

    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== ccsPageSize) {
            setCCSPageSize(size);
            setCCSPage(1);
        }
    };

    const getSortButton = (s: CCSSort) => {
        if (s === ccsSort) {
            return (
                <a className="sort-button pull-right"
                   onClick={() => {
                       setCCSDesc(!ccsDesc);
                   }}>
                    <FontAwesomeIcon icon={ccsDesc ? faSortAmountDown : faSortAmountDownAlt}/>
                </a>
            );
        }

        return (
            <a className="sort-button pull-right"
               onClick={() => {
                   setCCSSort(s);
                   setCCSDesc(true);
               }}>
                <FontAwesomeIcon icon={faSort}/>
            </a>
        );
    };

    const [showFilters, setShowFilters] = useState<boolean>(false);
    useEffect(() => {
        setShowFilters(false);
    }, [ccsFilters]);

    const showClearFilters = () => {
        if (hideExpired) {
            if (!ccsFilters.searchTerm && !ccsFilters.frameworkIds && !ccsFilters.startDateFrom && !ccsFilters.startDateTo && !ccsFilters.endDateTo) {
                return true;
            }
        }
        return false;
    }

    return (
        <div>
            <div className="row row-reverse mb-3 pull-right me-2">
                {(ccsFilters.searchTerm || ccsFilters.frameworkIds?.length > 0 ||
                    ccsFilters?.startDateTo || ccsFilters?.startDateFrom ||
                    ccsFilters?.endDateTo
                ) && (
                    showClearFilters() ? null : (
                        <button className="iqx-button secondary md-size pull-right me-3"
                                onClick={() => {
                                    setCCSFilters({} as CCSFilters);
                                    setHideExpired(false)
                                }}>
                            Clear Filters
                            <FontAwesomeIcon icon={faFilter} className="ms-2"/>
                        </button>
                    )
                )}

                <button className="iqx-button primary md-size pull-right"
                        onClick={() => setShowFilters(true)}>
                    Filters
                    <FontAwesomeIcon icon={faFilter} className="ms-2"/>
                </button>
            </div>
            <div className="row row-reverse mb-3 pull-right me-2">
                <div className="col me-4 mt-2" style={{textAlign: "end"}}>
                    <span className="me-2">Hide expired</span>
                    <Switch checked={hideExpired}
                            onChange={(switchStateValue: boolean) => setHideExpired(switchStateValue)}/>
                </div>
            </div>
            {loadingCCS ? <IqLoadingIcon/> : (
                <div>
                    {ccsFrameworks.length > 0 ? (
                        <>
                            <Table striped hover>
                                <thead>
                                <tr>
                                    <th>Name {getSortButton(CCSSort.NAME)}</th>
                                    <th>Description</th>
                                    <th>Framework ID{getSortButton(CCSSort.CODE)}</th>
                                    <th>Start Date{getSortButton(CCSSort.START_DATE)}</th>
                                    <th>End Date{getSortButton(CCSSort.END_DATE)}</th>
                                    <th className="text-end">Lots</th>
                                </tr>
                                </thead>
                                <tbody>
                                {ccsFrameworks.map(ccs => (
                                    <tr key={ccs.id}>
                                        <td><span className="iqx-link"
                                                  onClick={() => history.push(`${match.url}/ccs/${ccs.id}`)}>{ccs.name}</span>
                                        </td>
                                        <td>{ccs.shortDescription}</td>
                                        <td>{ccs.code}</td>
                                        <td>{DateFormatter.formatDate(ccs.startDate)}</td>
                                        <td>{DateFormatter.formatDate(ccs.endDate)}</td>
                                        <td className="text-end">{ccs.lots.length}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            <div className="mt-4">
                                <div className="justify-content-md-end pagination mt-n2">
                                    <span className="me-2">
                                        <Form.Control as="select"
                                                      style={{minWidth: "13%"}}
                                                      defaultValue={ccsPageSize}
                                                      onChange={(event) => changePageSize(event)}
                                                      >
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                        </Form.Control>
                                    </span>
                                    <span>
                                        {!!ccsTotal && (
                                            <Pagination totalRecords={ccsTotal}
                                                        pageLimit={ccsPageSize}
                                                        pageRangeDisplayed={1}
                                                        initPage={ccsPage}
                                                        onChangePage={setCCSPage}/>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="justify-content-md-center mt-3">
                            <Alert variant="info">
                                {translate("frameworks.notfound")}
                            </Alert>
                        </div>
                    )}

                </div>
            )}
            <Modal show={showFilters}
                   onHide={() => setShowFilters(false)}
                   size="lg">
                <CCSFrameworkFilters/>
            </Modal>
        </div>
    );
}

export default CCSFrameworks;