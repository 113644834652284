import React, {useEffect, useState} from "react";
import axios from "axios";
import {contractsHostName} from "../../../utils/Configuration";
import {Alert, Row} from "react-bootstrap";
import translate from "../../../i18n/translate";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import ContractSearchLink from "./ContractSearchLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";

// New Interfaces
export interface newSelectedFilters {
    followed?: boolean,
    searchTerm?: string | null,
    status?: string[]
    cpvCode?: string[],
    buyerName?: string[],
    supplierName?: string[],
    minValue?: number | null,
    maxValue?: number | null,
    minStartDate?: any | null,
    maxStartDate?: any | null,
    minEndDate?: any | null,
    maxEndDate?: any | null,
    minPublishedDate?: any | null,
    maxPublishedDate?: any | null,
    sme?: boolean | null,
    vcse?: boolean | null
}

export interface FilterNewPreset {
    saveSearchId: number,
    nameOfSearch: string,
    numberOfResults: number,
}

const ContractList: React.FC = () => {
    let history = useHistory();

    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [newFilters, setNewFilters] = useState<FilterNewPreset[]>([]);

    useEffect(() => {
        const fetchFilters = async () => {
            setLoading(true);
            await axios.get(contractsHostName + "mysql/getallsavedsearch")
                .then(r => setNewFilters(r.data))
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchFilters();
    }, []);

    const [deleting, setDeleting] = useState<boolean>(false);
    const deleteFilter = async (id: number) => {
        setDeleting(true);
        await axios.delete(contractsHostName + "mysql/getsavedsearch?searchId=" + id)
            .then(() => setNewFilters(newFilters.filter((p: FilterNewPreset) => p.saveSearchId !== id)))
            .finally(() => setDeleting(false));
    };

    return (
        <div style={{ marginTop: 15 }}>
            <Row className="mb-3">
                <span style={{ marginLeft: "auto" }}>
                    <button className="iqx-button primary md-size pull-right" onClick={() => history.push("/public-sector/contracts/filter/create")}>
                        <span className="me-2">{translate("contracts.create")}</span>
                        <span><FontAwesomeIcon icon={faPlus} /></span>
                    </button>
                </span>
            </Row>

            {loading ? <IqLoadingIcon /> : (
                error ? (
                    <Alert className="mt-2" variant="danger">
                        {translate("errors.loading")}
                    </Alert>
                ) : (
                    <div id="contracts-list-container">
                        {
                            newFilters.map(filter => (
                                <Row style={{ marginLeft: 0, marginRight: 0, marginBottom: 20 }} key={filter.saveSearchId}>
                                    <div className="list-link-container">
                                        <ContractSearchLink filter={filter} deleteFilter={deleteFilter}/>
                                    </div>
                                </Row>
                            ))
                        }
                    </div>
                )
            )}
        </div>
    );
};

export default ContractList;