import {faAddressBook} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import ReactTimeAgo from "react-time-ago";
import {DateText, Icon, Item, ItemContent, NotificationItem, Text} from "../Notifications";

const ContactsUpdatedNotification: React.FC<NotificationItem> = ({ element }) => {
    return (
        <Item read={element.read}>
            <Icon>
                <FontAwesomeIcon color={"white"} icon={faAddressBook} />
            </Icon>
            <ItemContent>
                <Text>
                    {+element.targets[0].placeholder === 0 ?
                        "We could not find any contacts for your recent request, tweaking your filters can yield better results."
                        :
                        <>
                            {[<>We found</>,
                            <a target={"_blank"} href="/contacts">{+element.targets[0].placeholder} contacts</a>,
                            <>as a result of a recent contact request.</>]
                                .reduce((prev: any, curr) =>
                                    [...prev, <> </>, curr], [])}
                        </>}
                </Text>
                <DateText>
                    <ReactTimeAgo date={new Date(element.modified)} locale="en-US" />
                </DateText>
            </ItemContent>
        </Item>
    )

}

export default ContactsUpdatedNotification;