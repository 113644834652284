import React from 'react';

export enum Flag {
    DE = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 3'%3E %3Cdesc%3EFlag of Germany%3C/desc%3E %3Crect id='black_stripe' width='5' height='3' y='0' x='0' fill='%23000'/%3E %3Crect id='red_stripe' width='5' height='2' y='1' x='0' fill='%23D00'/%3E %3Crect id='gold_stripe' width='5' height='1' y='2' x='0' fill='%23FFCE00'/%3E %3C/svg%3E",
    UK = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 30'%3E %3CclipPath id='t'%3E %3Cpath d='M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z'/%3E %3C/clipPath%3E %3Cpath d='M0,0 v30 h60 v-30 z' fill='%2300247d'/%3E %3Cpath d='M0,0 L60,30 M60,0 L0,30' stroke='%23fff' stroke-width='6'/%3E %3Cpath d='M0,0 L60,30 M60,0 L0,30' clip-path='url(%23t)' stroke='%23cf142b' stroke-width='4'/%3E %3Cpath d='M30,0 v30 M0,15 h60' stroke='%23fff' stroke-width='10'/%3E %3Cpath d='M30,0 v30 M0,15 h60' stroke='%23cf142b' stroke-width='6'/%3E %3C/svg%3E",
    FR = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3 2'%3E %3Crect width='3' height='2' fill='%23ED2939'/%3E %3Crect width='2' height='2' fill='%23fff'/%3E %3Crect width='1' height='2' fill='%23002395'/%3E %3C/svg%3E",
    RO = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='flag-icons-ro' viewBox='0 0 640 480'%3E%3Cg fill-rule='evenodd' stroke-width='1pt'%3E%3Cpath fill='%2300319c' d='M0 0h213.3v480H0z'/%3E%3Cpath fill='%23ffde00' d='M213.3 0h213.4v480H213.3z'/%3E%3Cpath fill='%23de2110' d='M426.7 0H640v480H426.7z'/%3E%3C/g%3E%3C/svg%3E%0A",
    US = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='flag-icons-us' viewBox='0 0 640 480'%3E%3Cpath fill='%23bd3d44' d='M0 0h640v480H0'/%3E%3Cpath stroke='%23fff' stroke-width='37' d='M0 55.3h640M0 129h640M0 203h640M0 277h640M0 351h640M0 425h640'/%3E%3Cpath fill='%23192f5d' d='M0 0h364.8v258.5H0'/%3E%3Cmarker id='us-a' markerHeight='30' markerWidth='30'%3E%3Cpath fill='%23fff' d='m14 0 9 27L0 10h28L5 27z'/%3E%3C/marker%3E%3Cpath fill='none' marker-mid='url(%23us-a)' d='m0 0 16 11h61 61 61 61 60L47 37h61 61 60 61L16 63h61 61 61 61 60L47 89h61 61 60 61L16 115h61 61 61 61 60L47 141h61 61 60 61L16 166h61 61 61 61 60L47 192h61 61 60 61L16 218h61 61 61 61 60z'/%3E%3C/svg%3E%0A",
    NO = "data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20203.55%20141.6%22%3E%3Crect%20fill%3D%22%23EF2B2D%22%20width%3D%22203.55%22%20height%3D%22141.4%22%20x%3D%220%22%20y%3D%220%22%20%2F%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M92.35%200v141.6H54.67V0z%22%2F%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M0%2051.96h203.55v37.68H0z%22%2F%3E%3Cpath%20fill%3D%22%23002868%22%20d%3D%22M82.93%200v141.6H64.09V0z%22%2F%3E%3Cpath%20fill%3D%22%23002868%22%20d%3D%22M0%2061.38h203.55v18.84H0z%22%2F%3E%3C%2Fsvg%3E"
}

interface IFlag {
    width: number,
    height: number
}

const UKFlag: React.FC<IFlag> = (props) => {
    return (
        <img style={{width: props.width, height: props.height}} src={Flag.UK}/>
    );
}

const DEFlag: React.FC<IFlag> = (props) => {
    return (
        <img style={{width: props.width, height: props.height}} src={Flag.DE}/>
    );
}

const FRFlag: React.FC<IFlag> = (props) => {
    return (
        <img style={{width: props.width, height: props.height}} src={Flag.FR}/>
    );
}

const ROFlag: React.FC<IFlag> = (props) => {
    return (
        <img style={{width: props.width, height: props.height}} src={Flag.RO}/>
    );
}

const USFlag: React.FC<IFlag> = (props) => {
    return (
        <img style={{width: props.width, height: props.height}} src={Flag.US}/>
    );
}

const NOFlag: React.FC<IFlag> = (props) => {
    return (
        <img style={{width: props.width, height: props.height}} src={Flag.NO}/>
    );
};

interface IIqFlag {
    country: string
    width: number,
    height: number
}
const IqFlag: React.FC<IIqFlag> = (props) => {
    return (
        <div>
            {(props.country == "UK" || props.country == "GB") && <UKFlag width={props.width} height={props.height}/>}
            {props.country == "DE" && <DEFlag width={props.width} height={props.height}/>}
            {props.country == "FR" && <FRFlag width={props.width} height={props.height}/>}
            {props.country == "RO" && <ROFlag width={props.width} height={props.height}/>}
            {props.country == "US" && <USFlag width={props.width} height={props.height}/>}
            {props.country == "NO" && <NOFlag width={props.width} height={props.height}/>}
        </div>
    );
}

export default IqFlag;
