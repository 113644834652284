import React from "react";
import {Flag} from "./CountryFlag";

export default class Locale {

    public static readonly ENGLISH = new Locale("en", "English", Flag.UK);
    public static readonly GERMAN = new Locale("de", "Deutsch", Flag.DE);
    public static readonly FRENCH = new Locale("fr", "Français", Flag.FR);
    public static readonly ROMANIAN = new Locale("ro", "Romanian", Flag.RO);

    public static readonly LOCALES: Locale[] = [
        Locale.ENGLISH,
        Locale.GERMAN,
        Locale.FRENCH,
        Locale.ROMANIAN
    ];

    private constructor(
        public readonly key: string,
        public readonly display: string,
        public readonly flag: Flag
    ) {}
}
