import React from "react";
import YoutubeVideo from "../../../model/organisation/social/YoutubeVideo";
import {Card, Col, Row} from "react-bootstrap";
import DateFormatter from "../../../utils/formatters/DateFormatter";
import translate from "../../../i18n/translate";
import {
    EmailIcon,
    EmailShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";

const YoutubeCaseStudyCard: React.FC<{ caseStudy: YoutubeVideo }> = ({caseStudy}) => {
    const sourceUrl = `https://www.youtube.com/watch/${caseStudy.id}`;

    return (
        <Card>
            <Card.Body className="mb-2">
                <Card.Title>
                    <a className="news-title" href={sourceUrl} target="_blank">{caseStudy.title}</a>
                </Card.Title>
                <Card.Text className="mt-1">
                    <div className="text-center mb-3"
                         style={{
                             maxHeight: "30vh",
                             width: "100%",
                             objectFit: "cover",
                             objectPosition: "50% 5%",
                             marginRight: 15,
                             marginBottom: 15
                         }}>
                        <div className="embed-responsive-16by9">
                            <iframe className="embed-responsive-item"
                                    src={`https://www.youtube.com/embed/${caseStudy.id}`}/>
                        </div>
                    </div>
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col className="pt-1">
                        {!!caseStudy.createdAt && (
                            <span>
                                <small style={{fontWeight: 500}}
                                       className="text-muted">Created: {DateFormatter.formatMillis(caseStudy.createdAt)}</small>
                            </span>
                        )}
                    </Col>
                    <Col>
                        <span className="float-end">
                            <small className="text-muted me-2">{translate("organisation.temporarylink.share")}:</small>
                            <span title="LinkedIn" className="me-2">
                                <LinkedinShareButton title={caseStudy.title}
                                                     url={sourceUrl}>
                                    <LinkedinIcon size={32} round={true}/>
                                </LinkedinShareButton>
                            </span>
                            <span title="Twitter" className="me-2">
                                <TwitterShareButton title={caseStudy.title}
                                                    url={sourceUrl}>
                                    <TwitterIcon size={32} round={true}/>
                                </TwitterShareButton>
                            </span>
                            <span title="Email">
                                <EmailShareButton title={caseStudy.title}
                                                  url={sourceUrl}>
                                    <EmailIcon size={32} round={true}/>
                                </EmailShareButton>
                            </span>
                        </span>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    );
};

export default YoutubeCaseStudyCard;