import React, {useContext, useEffect, useState} from "react";
import UserListContext from "../../../context/list/UserListContext";
import axios from "axios";
import {socialHostName} from "../../../utils/Configuration";
import ScrollPaginationContext from "../../../context/ScrollPaginationContext";
import {Alert} from "react-bootstrap";
import translate from "../../../i18n/translate";
import {Tweet} from "react-twitter-widgets";
import IqLoadingIcon from "../../common/IqLoadingIcon";

interface TweetDTO {
    tweetId: string,
    userId: string,
    createdAt: string
}

const UserListTwitter: React.FC = () => {
    const {list, setList} = useContext(UserListContext);
    const {page, setPage} = useContext(ScrollPaginationContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const [tweets, setTweets] = useState<TweetDTO[]>([]);

    useEffect(() => {
        const fetchTweets = async () => {
            setLoading(true);
            await axios.get(socialHostName + "twitter/tweets?page=" + page +
                "&size=10&source=" + list?.id + "&type=all")
                .then(r => {

                    setTweets((prevTweets: TweetDTO[]) => {
                        return [...prevTweets, ...r.data];
                    });
                })
                .catch(error => setError(true))
                .finally(() => setLoading(false));
        };


        if (list) {
            fetchTweets();
        }

        return () => {
            setPage(0);
        }
    }, [list, page]);

    return (
        <div style={{maxHeight: "100%", width: "100%", overflowY: "scroll", overflowX: "hidden", marginLeft: 0, marginRight: 0, marginTop: 15 }}>
            {error ? (
                <Alert variant="danger">
                    {translate("errors.loading")}
                </Alert>
            ) : (
                <div style={{width: 550, margin: "auto"}}>
                    {!loading && tweets.length === 0 && (
                        <Alert variant="primary">
                            We couldn't find any recent tweets for this list.
                        </Alert>
                    )}

                    {tweets.map((tweet: TweetDTO) => (
                        <div key={tweet.tweetId}>
                            <Tweet tweetId={tweet.tweetId} options={{maxWidth: "98%!important"}}/>
                        </div>
                    ))}
                    <div>{loading && <IqLoadingIcon/>}</div>
                </div>
            )}
        </div>
    );
};

export default UserListTwitter;