import React, {useContext, useEffect, useState} from "react";
import {Alert, Button, Col, ListGroup, ListGroupItem, Modal, Row, Spinner} from "react-bootstrap";
import NewsFiltersContext from "../../context/news/NewsFiltersContext";
import Select from "react-select";
import {socialHostName} from "../../utils/Configuration";
import axios from "axios";
import IqLoadingIcon from "../common/IqLoadingIcon";
import translate from "../../i18n/translate";
import {faFilter, faPencilAlt, faPlus, faSave, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SelectOption} from "./GlobalNews";
import Creatable from "react-select/creatable";

interface NewsPreferences {
    id: number | null,
    title: string,
    sectors: string[],
    topics: string[],
    terms: string[]
}

const NewsFiltersModal: React.FC = () => {
    const {showFiltersModal, setShowFiltersModal, topicOptions, sectorOptions, topics, setTopics, sectors, setSectors, terms, setTerms} = useContext(NewsFiltersContext);

    const [preferences, setPreferences] = useState<NewsPreferences[]>([]);
    const [loadingPreferences, setLoadingPreferences] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const [showEditor, setShowEditor] = useState<boolean>(false);
    useEffect(() => {
        const fetchPreferences = async () => {
            setLoadingPreferences(true);
            await axios.get(socialHostName + "preferences")
                .then(r => setPreferences(r.data))
                .catch(() => setError(true))
                .finally(() => setLoadingPreferences(false));
        };

        if (showFiltersModal && !showEditor) {
            fetchPreferences();
        }
    }, [showFiltersModal, showEditor]);

    const [hasAddPermission, setAddPermission] = useState<boolean>(false);
    useEffect(() => {
        const fetchAddPermission = async () => {
            await axios.get(socialHostName + "preferences/has-capacity")
                .then(r => setAddPermission(r.data));
        };

        if (showFiltersModal) {
            fetchAddPermission();
        }
    }, [showFiltersModal, preferences]);

    const [deleting, setDeleting] = useState<boolean>(false);
    const [deleteError, setDeleteError] = useState<boolean>(false);
    const deletePreferences = async (id: number) => {
        setDeleting(true);
        await axios.delete(socialHostName + "preferences/" + id + "/delete")
            .then(() => {
                let filtered = preferences.filter((item: NewsPreferences) => item.id !== id)
                setPreferences(filtered);
            })
            .catch(() => setDeleteError(true))
            .finally(() => setDeleting(false));
    };

    const applyFilters = (id: number) => {
        let s = preferences.find((item: NewsPreferences) => item.id === id);
        setTopics(s!.topics);
        setSectors(s!.sectors);
        setTerms(s!.terms);
        setShowFiltersModal(false);
    };

    const [selected, setSelected] = useState<NewsPreferences>();
    const openEditor = (id: number) => {
        let s = preferences.find((item: NewsPreferences) => item.id === id);
        setSelected(s);
        setShowEditor(true);
    };

    const setTitle = (title: string) => {
        let updated = {
            ...selected,
            title: title
        } as NewsPreferences;

        setSelected(updated);
    };

    const updateTopics = (values: SelectOption[]) => {
        let t = values.map((item: SelectOption) => item.value);
        let updated = {
            ...selected,
            topics: t
        } as NewsPreferences;

        setSelected(updated);
    };

    const updateSectors = (values: SelectOption[]) => {
        let s = values.map((item: SelectOption) => item.value);
        let updated = {
            ...selected,
            sectors: s
        } as NewsPreferences;

        setSelected(updated);
    };

    const updateSearchTerms = (values: SelectOption[]) => {
        let s = values.map((item: SelectOption) => item.value);
        let updated = {
            ...selected,
            terms: s
        } as NewsPreferences;

        setSelected(updated);
    };

    const createNew = () => {
        let created = {
            id: null,
            title: "",
            sectors: [],
            topics: [],
            terms: []
        } as NewsPreferences;

        setSelected(created);
        setShowEditor(true);
    };

    const [saveError, setSaveError] = useState<string>();
    const [showSaveError, setShowSaveError] = useState<boolean>(false);
    const showErrorAlert = (error: string) => {
        setSaveError(error);
        setShowSaveError(true);
        setTimeout(() => {
            setShowSaveError(false);
        }, 2000);
    };

    const [saving, setSaving] = useState<boolean>(false);
    const save = () => {
        if (!selected?.title) {
            showErrorAlert("Name cannot be blank");
        }
        else {
            setSaving(true);
            axios.post(socialHostName + "preferences/save", selected)
                .then(r => {
                    setPreferences([
                        ...preferences,
                        r.data
                    ]);
                    setShowEditor(false);
                })
                .catch(() => showErrorAlert(translate("errors.generic")))
                .finally(() => setSaving(false));
        }
    };

    return (
        <Modal size="lg" show={showFiltersModal} onHide={() => {setShowEditor(false); setShowFiltersModal(false)}}>
            <Modal.Header closeButton>
                Configure Filters
            </Modal.Header>
            <Modal.Body className="mb-3">
                {loadingPreferences ? <IqLoadingIcon /> : (
                    <div>
                        {showEditor ? (
                            <div>
                                <Alert variant="danger" show={showSaveError}>
                                    {saveError}
                                </Alert>
                                <div className="row">
                                    <div className="col">
                                        <label className="ps-1 mb-n1">Name</label>
                                        <input type="text"
                                               className="form-control"
                                               value={selected!.title}
                                               placeholder="Filter Name..."
                                               onChange={(event: any) => setTitle(event.target.value)}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <label className="ps-1 mb-n1">Sectors</label>
                                        <Select isMulti
                                                isDisabled={sectorOptions.length === 0}
                                                options={sectorOptions}
                                                defaultValue={selected!.sectors.map((item: string) => ({value: item, label: item} as SelectOption))}
                                                onChange={(e: any) => updateSectors(e)}
                                                placeholder="Select sectors..."
                                                className="basic-multi-select"
                                                classNamePrefix="select" />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <label className="ps-1 mb-n1">Topics</label>
                                        <Select isMulti
                                                isDisabled={topicOptions.length === 0}
                                                options={topicOptions}
                                                defaultValue={selected!.topics.map((item: string) => ({value: item, label: item} as SelectOption))}
                                                onChange={(e: any) => updateTopics(e)}
                                                placeholder="Select topics..."
                                                className="basic-multi-select"
                                                classNamePrefix="select" />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <label className="ps-1 mb-n1">Search Terms</label>
                                        <Creatable isMulti
                                                   onChange={(e: any) => updateSearchTerms(e)}
                                                   defaultValue={selected!.terms.map((item: string) => ({value: item, label: item} as SelectOption))}
                                                   placeholder="Enter Search Terms..."
                                                   options={[]} />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="float-end">
                                        <button className="iqx-button sm-size iconic me-2"
                                                onClick={() => setShowEditor(false)}>
                                            <span>Cancel</span>
                                        </button>
                                        <button className="iqx-button sm-size primary pull-right"
                                                onClick={() => save()}>
                                            {saving ? (
                                                <span>
                                                    <span className="me-2">Saving...</span>
                                                    <Spinner as="span"
                                                             animation="border"
                                                             size="sm"
                                                             role="status"
                                                             aria-hidden="true"/>
                                                </span>
                                            ) : (
                                                <span>
                                                    <span className="me-2">Save</span>
                                                    <FontAwesomeIcon icon={faSave} />
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="row mb-2">
                                    <div className="col">
                                        <button className="iqx-button primary sm-size pull-right"
                                                disabled={!hasAddPermission}
                                                onClick={() => createNew()}>
                                            <span className="me-2">Create New</span>
                                            <FontAwesomeIcon icon={faPlus} />
                                        </button>
                                    </div>
                                </div>
                                <ListGroup>
                                    {preferences.map((item: NewsPreferences) => (
                                        <ListGroupItem>
                                            <Row>
                                                <Col className="pt-1">
                                                    {item.title}
                                                </Col>
                                                <Col xl={3} className="text-end">
                                                    <span className="me-2">
                                                        <Button variant="outline-success"
                                                                disabled={deleting}
                                                                size="sm"
                                                                title="Apply"
                                                                onClick={() => applyFilters(item.id!)}>
                                                            <FontAwesomeIcon icon={faFilter} />
                                                        </Button>
                                                    </span>
                                                    <span className="me-2">
                                                        <Button variant="outline-secondary"
                                                                disabled={deleting}
                                                                size="sm"
                                                                title="Edit"
                                                                onClick={() => openEditor(item.id!)}>
                                                            <FontAwesomeIcon icon={faPencilAlt} />
                                                        </Button>
                                                    </span>
                                                    <span className="me-2">
                                                        <Button variant="danger"
                                                                disabled={deleting}
                                                                size="sm"
                                                                title="Delete"
                                                                onClick={() => deletePreferences(item.id!)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </Button>
                                                    </span>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </div>
                        )}
                    </div>
                )}
                {(!loadingPreferences && error) && (
                    <Alert variant="danger">
                        {translate("errors.loading")}
                    </Alert>
                )}
            </Modal.Body>
        </Modal>
    )
};

export default NewsFiltersModal;