import React, {useContext, useEffect, useState} from "react";
import OfficerContext from "../../../context/officer/OfficerContext";
import {TwitterUser} from "../../social/TwitterFeed";
import {socialHostName} from "../../../utils/Configuration";
import axios from "axios";
import "./OfficerSocial.css";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import TwitterPreview from "../../organisation/social/TwitterPreview";

export interface TopData {
    count: number;
    text: string;
}

export interface Aggregates {
    oneMonth: TopData[];
    threeMonth: TopData[];
    sixMonth: TopData[]
}

const OfficerSocial: React.FC = () => {
    const { officer, setOfficer } = useContext(OfficerContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [twitterUser, setTwitterUser] = useState<TwitterUser>();
    const [hashtags, setHashtags] = useState<Aggregates>();
    const [mentions, setMentions] = useState<Aggregates>();


    useEffect(() => {

        const fetchUser = async () => {
            await axios.get(socialHostName + "twitter/" + officer.twitter)
                .then(r => setTwitterUser(r.data))
                .catch(error => console.error(error.message));
        };

        const fetchHashtags = async () => {
            await axios.get(socialHostName + "twitter/" + officer.twitter + "/hashtags")
                .then(r => setHashtags(r.data))
                .catch(error => console.error(error.message));
        };

        const fetchMentions = async () => {
            await axios.get(socialHostName + "twitter/" + officer.twitter + "/mentions")
                .then(r => setMentions(r.data))
                .catch(error => console.error(error.message));
        };

        setLoading(true);

        fetchUser();
        fetchHashtags();
        fetchMentions();

        setLoading(false);
    }, []);

    const getImageUrl = () => {
        if (twitterUser && twitterUser.logoUrl) {
            return twitterUser.logoUrl?.replace("_normal", "").replace("_bigger", "");
        }

        return "/icons_profil.svg";
    };

    return (
        <div style={{ paddingTop: 35 }}>
            {loading ? <IqLoadingIcon /> : (
                <div>
                    {twitterUser && <TwitterPreview twitterUser={twitterUser}/>}
                </div>
            )}

        </div>
    );
};

export default OfficerSocial;
