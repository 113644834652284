import {faList} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import ReactTimeAgo from "react-time-ago";
import {Payload} from "../../../model/notification/Payload";
import {DateText, Highlight, Icon, Item, ItemContent, NotificationItem, Text} from "../Notifications";

const ListAdditionNotification: React.FC<NotificationItem> = ({ element }) => {
    const [othersCount, setOthersCount] = useState<number>(2);
    return (
        <Item read={element.read}>
            <Icon>
                <FontAwesomeIcon color={"white"} icon={faList} />
            </Icon>
            <ItemContent>
                <Text>
                    {element?.targets?.slice(0, othersCount).map<React.ReactNode>((target: Payload, index: number) => (
                        <Highlight key={index}>
                            <a target={"_blank"} href={["/organisation", target.id].join("/")}>
                                {target.placeholder}
                            </a>
                        </Highlight>
                    ))
                        .reduce((prev, curr) => [prev, ', ', curr])}
                    <> </>
                    <span style={{fontWeight: 500, color: "#007bff", cursor: "pointer"}} onClick={() => setOthersCount(othersCount * 2)}>
                    {element?.targets?.length - othersCount > 0 && ["and", element?.targets?.length - othersCount, "others"].join(" ")}
                    </span>
                    <> </>
                    {element?.targets?.length > 1 ? "have" : "has"} been added to the list
                    <> </>
                    <a target={"_blank"} href={["/lists/", element.middlewares[0].id].join("")}>
                        {element.middlewares[0].placeholder}
                    </a>.
                </Text>
                <DateText>
                    <ReactTimeAgo date={new Date(element.modified)} locale="en-US" />
                </DateText>
            </ItemContent>
        </Item>
    )

}

export default ListAdditionNotification;