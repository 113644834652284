import React, {useContext, useEffect, useState} from "react";
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import Investments from "./Investments";
import SubmenuContext from "../../context/SubmenuContext";
import translate from "../../i18n/translate";
import {faCompressAlt, faFileSignature, faLevelUpAlt, faTags} from "@fortawesome/free-solid-svg-icons";
import {Col, Container, Image, Row} from "react-bootstrap";
import Appointments from "./Appointments";
import Organisation from "../../model/organisation/Organisation";
import Gazette from "./Gazette";
import SicCodeAnalytics from "./SicCodeAnalytics";

export const CompanyLogo: React.FC<Organisation> = (data: Organisation) => {
    const [src, setSrc] = useState<string>(data.imageUrl ? data.imageUrl : data.logoUrl ? data.logoUrl : "./icons_company.svg");

    return (
        <Image title={data.companyName}
               style={{
                   height: "30px",
                   width: "30px",
                   objectFit: "cover"
               }}
               src={src}
               onError={() => setSrc("/icons_company.svg")}
               roundedCircle />
    );
};

const MarketIntelligenceContainer: React.FC = () => {
    let history = useHistory();
    const match = useRouteMatch();
    const { submenuItems, setSubmenuItems, submenuTitle, setSubmenuTitle } = useContext(SubmenuContext);

    useEffect(() => {
        setSubmenuTitle(translate("marketintelligence.title"));
        let submenu = [
            {
                name: "marketintelligence.investments",
                url: "/marketintelligence/investments",
                icon: faLevelUpAlt
            },
            {
                name: "marketintelligence.appointments.title",
                url: "/marketintelligence/appointments",
                icon: faFileSignature
            },
            {
                name: "marketintelligence.risk.title",
                url: "/marketintelligence/risk",
                icon: faCompressAlt
            },
            {
                name: "marketintelligence.sic.title",
                url: "/marketintelligence/industry-analysis",
                icon: faTags
            }
        ];

        setSubmenuItems(submenu);
        return () => {
            setSubmenuTitle("");
            setSubmenuItems([]);
        }
    }, []);

    return (
        <Container fluid style={{ marginTop: 15 }}>
            <div className="iq-headline">
                <span className="text">
                     {translate("marketintelligence.title")}
                </span>
            </div>
            <Row className="mt-2 text-center" style={{ marginLeft: 0, marginRight: 0, marginBottom: 15 }}>
                <Col>
                <div style={{ marginLeft: "auto", marginRight: "auto" }} className="iq-button-group">
                    <button className={window.location.pathname.includes("investments") ? "selected-inverted" : ""}
                            onClick={(e) => { history.push("/marketintelligence/investments") }}>
                        {translate("marketintelligence.investments")}
                    </button>
                    <button className={window.location.pathname.includes("appointments") ? "selected-inverted" : ""}
                            onClick={(e) => { history.push("/marketintelligence/appointments") }}>
                        {translate("marketintelligence.appointments.title")}
                    </button>
                    <button className={window.location.pathname.includes("risk") ? "selected-inverted" : ""}
                            onClick={(e) => { history.push("/marketintelligence/risk") }}>
                        {translate("marketintelligence.risk.title")}
                    </button>
                    <button className={window.location.pathname.includes("industry-analysis") ? "selected-inverted" : ""}
                            onClick={(e) => { history.push("/marketintelligence/industry-analysis") }}>
                        {translate("marketintelligence.sic.title")}
                    </button>
                </div>
                </Col>
            </Row>
            <Switch>
                <Route exact path={`${match.url}/investments`} component={Investments}/>
                <Route exact path={`${match.url}/appointments`} component={Appointments}/>
                <Route exact path={`${match.url}/risk`} component={Gazette}/>
                <Route exact path={`${match.url}/industry-analysis`} component={SicCodeAnalytics}/>
            </Switch>
        </Container>
    )
};

export default MarketIntelligenceContainer;