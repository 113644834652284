import React, {useEffect, useState} from "react";
import axios from "axios";
import {organisationHostname} from "../../utils/Configuration";
import TechDataSalesAggregate from "../../model/techsales/TechDataSalesAggregate";
import {Col, Row, Table} from "react-bootstrap";
import {AutoSizer} from "react-virtualized";
import {DiscreteColorLegend, Hint, RadialChart} from "react-vis";
import {COLOURS} from "./TechSales";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {formatLocale, FormatLocaleDefinition} from "d3-format";

const SalesByEndUser: React.FC = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTop10, setLoadingTop10] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [techSalesByYear, setTechSalesByYear] = useState<any>({});
    const [yearlyTop10, setYearlyTop10] = useState<any>({});

    useEffect(() => {
        const fetchSalesByEndUser = async () => {
            setLoading(true);

            await axios.get(organisationHostname + "tech-sales/end-user")
                .then(r => setTechSalesByYear(r.data))
                .catch(e => setError(true))
                .finally(() => setLoading(false))
        };

        fetchSalesByEndUser();
    }, []);

    useEffect(() => {
        const fetchTop10ByYear = async () => {
            setLoadingTop10(true);

            await axios.get(organisationHostname + "tech-sales/end-user/top-10")
                .then(r => setYearlyTop10(r.data))
                .catch(e => setError(true))
                .finally(() => setLoadingTop10(false))
        };

        fetchTop10ByYear();
    }, []);

    const [hoverKey, setHoverKey] = useState<any>();
    const [hover, setHover] = useState<any>();

    let UK = formatLocale({
        currency: ["£ ", ""]
    } as FormatLocaleDefinition);

    const formatValue = (key: string, label: string) => {
        let value = techSalesByYear[key]?.find((item: TechDataSalesAggregate) => item.label === label)?.totalRevenueLocal;

        if (value) {
            return UK.format('$,.3s')(value).replace(/G/, "B")
        }
    };

    return (
        <div style={{ paddingTop: "10px", padding: 15, marginTop: 10, minHeight: 400 }}>
            <Row className="mb-3">
                {loading && <IqLoadingIcon />}
                {Object.keys(techSalesByYear).map(key => (
                    <Col>
                        <div className="organisation-card" style={{ minHeight: 400 }}>
                            <div className="organisation-card-label">
                                {key}
                            </div>
                            <AutoSizer>
                                {({height, width}) => (
                                    <div style={{ display: "flex", flexDirection: "row", height: height, width: width, justifyContent: "center", alignItems: "center" }}>
                                        <RadialChart
                                            angleDomain={[0, 100]}
                                            data={techSalesByYear[key]?.map((item: TechDataSalesAggregate, index: number) => {
                                                return {
                                                    angle: item.totalRevenueLocal,
                                                    label: item.label,
                                                    color: COLOURS[index]
                                                }
                                            })}
                                            onValueMouseOver={(v: any) => {
                                                setHover(v);
                                                setHoverKey(key);
                                            }}
                                            onValueMouseOut={(v: any) => {
                                                setHover(null);
                                                setHoverKey(null);
                                            }}
                                            width={width * 0.7}
                                            height={300}
                                            colorType="literal"
                                        >
                                            {(hover && hoverKey === key) && (
                                                <Hint value={hover}>
                                                    <div style={{
                                                        color: 'white',
                                                        backgroundColor: 'black',
                                                        opacity: 0.9,
                                                        alignItems: 'center',
                                                        padding: '5px',
                                                        borderRadius: '5px',
                                                        border: '2px solid',
                                                        fontWeight: 500
                                                    }}>
                                                        <div>{hover?.label}</div>
                                                        <div>
                                                            {formatValue(key, hover.label)}
                                                        </div>
                                                    </div>
                                                </Hint>
                                            )}
                                        </RadialChart>
                                        <DiscreteColorLegend
                                            height={300}
                                            orientation="vertical"
                                            width={width * 0.3}
                                            items={techSalesByYear[key]?.map((item: any, index: number) => {
                                                return {
                                                    title: item.label,
                                                    color: COLOURS[index],
                                                    strokeWidth: 25
                                                }
                                            })}
                                        />
                                    </div>
                                )}
                            </AutoSizer>
                        </div>
                    </Col>
                ))}
            </Row>

            <Row>
                {loadingTop10 && <IqLoadingIcon />}
                {Object.keys(yearlyTop10).map(key => (
                    <Col>
                        <div className="organisation-card">
                            <div className="organisation-card-label">
                                {key}
                            </div>

                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>Organisation</th>
                                        <th style={{width: "20%"}}>Sales</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {yearlyTop10[key]?.map((item: any, index: number) => (
                                        <tr>
                                            <td>
                                                {index + 1}.
                                            </td>
                                            <td>
                                                {item.label}
                                            </td>
                                            <td style={{width: "20%"}}>
                                                {UK.format('$,.3s')(item.totalRevenueLocal).replace(/G/, "B")}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default SalesByEndUser;