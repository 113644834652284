import React, {Dispatch, useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { StateHandler } from '../../model/organisation/editor/StateHandler';

interface Props {
    updateHandler: (e: any, updateButton: boolean, url: string, setStateData: Dispatch<StateHandler>, stateData: StateHandler) => void;
    url: string
    setStateData: Dispatch<StateHandler>;
    stateData: StateHandler;
    buttonText: string
}


const EditorButton: React.FC<Props> = (props: Props) => {

    return (
        <div>
            <Button style={{marginLeft: "15px", marginTop: 15}}
                    variant="warning"
                    type="submit"
                    onClick={(e: any) => {
                        props.updateHandler(e, false,
                            props.url,
                            props.setStateData,
                            props.stateData)
                    }}>
                {props.buttonText}
            </Button>
        </div>
    )
}
export default EditorButton;