import React, {useState} from "react";
import {AutoSizer} from "react-virtualized";
import {Hint, HorizontalGridLines, VerticalBarSeries, XAxis, XYPlot, YAxis} from "react-vis";

interface Props {
    data: any[],
    colour: string,
    title: string,
    formatValue: (value: string) => string,
    yAxisFormat: (value: any) => string
}

const CampaignBarSeriesChart: React.FC<Props> = (props: Props) => {

    const [hover, setHover] = useState<{ x: Date, y: number } | null>();

    const xMin = props.data[0].x;
    const xMax = props.data[props.data.length - 1].x;
    const yMax = props.data.reduce((max: number, p: { y: number; }) => p.y > max ? p.y : max, props.data[0].y);

    return (
        <AutoSizer>
            {({ height, width }) => (
                <XYPlot width={width}
                    xType="time"
                    height={300}
                    xDomain={[xMin, xMax]}
                    yDomain={[0, yMax + 0.5]}
                    stackBy="y"
                >

                    <HorizontalGridLines />
                    <VerticalBarSeries
                        barWidth={0.5}
                        color={props.colour}
                        data={props.data}
                        onValueMouseOver={(v: any) => {
                            setHover(v);
                        }}
                        onValueMouseOut={(v: any) => {
                            setHover(null);
                        }} />

                    <XAxis tickTotal={3} tickFormat={val => [val.getUTCDate(), val.getUTCMonth(), val.getFullYear()].join('/')} title="Date" />
                    <YAxis tickFormat={tick => tick} title={props.title} />

                    {hover && (
                        <Hint value={hover}>
                            <div className="chart-hint">
                                <div className="x">{new Date(hover.x).toDateString()}</div>
                                <div className="y">{props.formatValue(String(hover.y))}</div>
                            </div>
                        </Hint>
                    )}
                </XYPlot>
            )}
        </AutoSizer>
    );
};

export default CampaignBarSeriesChart;
