import React, {useContext, useEffect, useState} from "react";
import {matchPath, Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {newSelectedFilters} from "./ContractList";
import axios from "axios";
import {contractsHostName} from "../../../utils/Configuration";
import SubmenuContext from "../../../context/SubmenuContext";
import {faChevronLeft, faFileContract, faTools} from "@fortawesome/free-solid-svg-icons";
import ContractsSearch from "./ContractsSearch";
import EditFilters from "./EditFilters";
import {Col, Container, Row} from "react-bootstrap";
import translate from "../../../i18n/translate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ContractsContainer: React.FC = () => {
    let history = useHistory();
    const match = useRouteMatch();
    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/contracts/:filterId',
        exact: false,
        strict: false
    });

    const {setSubmenuTitle, setSubmenuItems} = useContext(SubmenuContext);

    const [newFilters, setNewFilters] = useState<newSelectedFilters>();
    useEffect(() => {
        const fetchFilters = async () => {
            await axios.get(contractsHostName + "mysql/getsavedparams?searchId=" + childMatchedPath?.params.filterId)
                .then(r => {
                    setNewFilters(r.data);
                    setSubmenuTitle(r.data.title);
                    let submenuItems = [
                        {
                            name: "View Contracts",
                            url: "/contracts/" + childMatchedPath?.params.filterId,
                            icon: faFileContract
                        },
                        {
                            name: "Edit Filters",
                            url: "/contracts/" + childMatchedPath?.params.filterId + "/edit",
                            icon: faTools
                        }
                    ];

                    setSubmenuItems(submenuItems);
                });
        };

        fetchFilters();
        return () => {
            setSubmenuTitle("");
            setSubmenuItems([]);
        };
    }, []);

    return (
        <Container fluid style={{ marginTop: 15, marginRight: "auto", paddingLeft: 15 }}>
            <Row className="mb-3">
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                    <div className="iq-headline">
                        <span className="text">
                            {translate("contracts.title")}
                        </span>
                    </div>
                </Col>
                <Col className="pull-right">
                    <button className="iqx-button md-size pull-right" onClick={() => history.push("/public-sector/contracts")}>
                        <span className="me-2"><FontAwesomeIcon icon={faChevronLeft} /></span>
                        <span>{translate("contracts.back")}</span>
                    </button>
                </Col>
            </Row>

            <Switch>
                <Route exact path={`${match.url}/:filterId`}  component={ContractsSearch} />
                <Route path={`${match.url}/:filterId/edit`} component={EditFilters} />
            </Switch>
        </Container>
    );
};

export default ContractsContainer;

