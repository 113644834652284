import React, {useContext, useEffect, useState} from "react";
import UserListContext from "../../../context/list/UserListContext";
import {Alert, Button, Form, Modal, Spinner} from "react-bootstrap";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {frontEndHostName} from "../../../utils/Configuration";
import {OrganisationUser} from "../../teams/edit/EditTeam";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import Select from "react-select";

interface SelectOption {
    value: string,
    label: string
}

const ListTransferModal: React.FC = () => {
    const { list, setList, showTransferModal, setShowTransferModal } = useContext(UserListContext);
    const history = useHistory();
    const [usernames, setUsernames] = useState<string[]>([]);
    const [deleteList, setDeleteList] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<SelectOption[]>([]);

    const handleClose = () => {
        setUsernames([]);
        setShowTransferModal(false);
    };

    const handleCheck = (event: any) => {
        setDeleteList(event.target.checked);
    };

    const [inProgress, setInProgress] = useState<boolean>(false);
    const transferList = async () => {
        setInProgress(true);
        await axios.post(frontEndHostName + "transfer-user-lists/" + list.id + "?remove=" + deleteList, usernames)
            .then(() => {
                if (deleteList) {
                    history.push("/lists");
                }
                else {
                    handleClose()
                }
            })
            .catch(error => {
                let responseData = error.response.data;
                if (responseData.errorKey === "maxlists") {
                    setHasListCapacity(false);
                }
                else if (responseData.errorKey === "maxcapacity") {
                    setHasOrganisationCapacity(false);
                }
                else {
                    setError(true);
                }
            })
            .finally(() => setInProgress(false));
    };

    const [error, setError] = useState<boolean>(false);
    const [hasListCapacity, setHasListCapacity] = useState<boolean>(true);
    const [hasOrganisationCapacity, setHasOrganisationCapacity] = useState<boolean>(true);

    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            await axios.get(frontEndHostName + "users")
                .then(r => {
                    setSearchResults(
                        r.data.map((item: OrganisationUser) => {
                            return {
                                value: `${item.login}`,
                                label: `${item.firstName} ${item.lastName}`
                            } as SelectOption;
                        })
                    )
                })
                .catch((error) => {
                    setError(true)
                })
                .finally(() => setLoading(false));
        };

        if (showTransferModal) {
            fetchUsers();
        }
    }, [showTransferModal]);

    const handleInputChange = (value: SelectOption[]) => {
        setUsernames(value.map((item: SelectOption) => item.value));
    };

    return (
        <Modal show={showTransferModal} onHide={handleClose} dialogClassName="modal-dialog modal-full-height modal-right modal-notify modal-success">
            <Modal.Header closeButton>
                <Modal.Title>Transfer list to another user</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    {loading ? <IqLoadingIcon /> : (
                        <Select isMulti
                            options={searchResults}
                            onChange={(e: any) => handleInputChange(e)}
                            className="basic-multi-select"
                            classNamePrefix="select" />
                    )}
                </Form.Group>

                <hr />
                <Form.Check label="Delete from my lists"
                    type="checkbox"
                    onChange={(event: any) => handleCheck(event)}
                />
                <hr />
                {!hasListCapacity && (
                    <Alert variant="danger">
                        One or more users do not have enough capacity for storing lists.
                    </Alert>
                )}
                {!hasOrganisationCapacity && (
                    <Alert variant="danger">
                        One or more users do not have enough capacity to store organisations in lists.
                    </Alert>
                )}
                {error && (
                    <Alert variant="danger">
                        An error has occured.
                    </Alert>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                {inProgress ? (
                    <Button variant="success" disabled>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        &nbsp;Transferring...
                    </Button>
                ) : (
                    <Button variant="success"
                        disabled={usernames.length === 0}
                        onClick={() => {
                            transferList();
                        }}>
                        Transfer
                    </Button>
                )}

            </Modal.Footer>
        </Modal>
    );
};

export default ListTransferModal;
