import React, {useContext} from 'react';
import {Table} from 'react-bootstrap';
import OrganisationSocialContext from '../../../context/organisation/OrganisationSocialContext';

const DigitalMaturityScoreBreakdown: React.FC = () => {

    const { digitalMaturityScore } = useContext(OrganisationSocialContext);

    return (
        <div style={{ width: "100%" }} className="organisation-card">
            <div className="iq-tx-11 iq-tx-uppercase iq-tx-spacing-1 iq-tx-semibold iq-tx-color-02">
                Digital Maturity Score Breakdown
                                    </div>

            <Table style={{ width: "100%" }} bordered striped hover>
                <tr>
                    <td rowSpan={6}></td>
                    <th style={{ backgroundColor: "#E9ECEF" }} colSpan={3} scope="colgroup">Social Audience</th>
                    <th style={{ backgroundColor: "#E9ECEF" }} colSpan={4} scope="colgroup">Content & Engagement</th>
                    <th style={{ backgroundColor: "#E9ECEF" }} colSpan={1} scope="colgroup">Web</th>
                    <th style={{ backgroundColor: "#E9ECEF" }} colSpan={2} scope="colgroup">Size</th>
                    <th style={{ backgroundColor: "#E9ECEF" }} colSpan={1} scope="colgroup">Referentiality</th>
                    <th style={{ backgroundColor: "#E9ECEF" }} colSpan={2} scope="colgroup">MarTech Stack</th>
                </tr>
                <tr>
                    <th colSpan={1} scope="colgroup">LinkedIn</th>
                    <th colSpan={1} scope="colgroup">Twitter</th>
                    <th colSpan={1} scope="colgroup">YouTube</th>
                    <th colSpan={2} scope="colgroup">Twitter</th>
                    <th colSpan={2} scope="colgroup">YouTube</th>
                    <th colSpan={1} scope="colgroup">Alexa Rank</th>
                    <th colSpan={1} scope="colgroup">Revenue</th>
                    <th colSpan={1} scope="colgroup">Employees</th>
                    <th colSpan={1} scope="colgroup">Case Studies</th>
                    <th colSpan={1} scope="colgroup">CRM</th>
                    <th colSpan={1} scope="colgroup">Marketing Automation</th>
                </tr>
                <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Tweets</th>
                    <th scope="col">Likes</th>
                    <th scope="col">Videos</th>
                    <th scope="col">Views</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
                <tr>
                    <td>0</td>
                    <td>{digitalMaturityScore.twitterLikes + digitalMaturityScore.twitterTweets}</td>
                    <td>{digitalMaturityScore.youtubeVideos + digitalMaturityScore.youtubeViews}</td>
                    <td>{digitalMaturityScore.twitterTweets}</td>
                    <td>{digitalMaturityScore.twitterLikes}</td>
                    <td>{digitalMaturityScore.youtubeVideos}</td>
                    <td>{digitalMaturityScore.youtubeViews}</td>
                    <td>{digitalMaturityScore.alexaRank}</td>
                    <td>{digitalMaturityScore.revenue}</td>
                    <td>{digitalMaturityScore.employees}</td>
                    <td>{digitalMaturityScore.caseStudies}</td>
                    <td>{digitalMaturityScore.crm}</td>
                    <td>{digitalMaturityScore.marketingAutomation}</td>
                </tr>
                <tr>
                    <td style={{textAlign: "center"}} colSpan={13}>Total: {digitalMaturityScore.totalScore} / 100</td>
                </tr>
            </Table>
        </div>
    );
}

export default DigitalMaturityScoreBreakdown;
