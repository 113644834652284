import React, {useState} from "react";
import Supplier from "../../../model/contracts/Supplier";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleDown, faChevronCircleUp} from "@fortawesome/free-solid-svg-icons";

const ContractSuppliers: React.FC<{suppliers: Supplier[]}> = ({suppliers}) => {

    const [expanded, setExpanded] = useState<boolean>(false);
    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const format = (supplier: Supplier) => {
        if (!supplier || !supplier.name) {
            return "";
        }

        return (
            <div>
                {supplier.id ? (
                    <a className="iqx-link me-2" href={"/organisation/" + supplier.id}>
                        {supplier.name.toUpperCase()}
                    </a>
                ) : (
                    <span className="me-2">
                        {supplier.name.toUpperCase()}
                    </span>
                )}
            </div>
        );
    }

    return (
        <div>
            {suppliers.length < 2 ? suppliers.map(supplier => format(supplier)) : (
                <div className="flex flex-col" style={{width: "100%"}}>
                    <div style={{width: "100%"}}>
                        <span className="me-2">{suppliers.length} suppliers</span>

                        <span className="iqx-link pull-right" onClick={toggleExpanded}>
                            {expanded ? <FontAwesomeIcon icon={faChevronCircleUp}/> : <FontAwesomeIcon icon={faChevronCircleDown}/>}
                        </span>
                    </div>
                    <div className="mt-2" style={{display: expanded ? "block" : "none"}}>
                        {suppliers.map(supplier => format(supplier))}
                    </div>
                </div>
            )}
        </div>
    )
};

export default ContractSuppliers;