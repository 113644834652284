import React, {useEffect, useState} from "react";
import Organisation from "../../model/organisation/Organisation";
import {getUserFirstName} from "../../utils/Security";
import {Image, OverlayTrigger, Popover, Table} from "react-bootstrap";
import translate from "../../i18n/translate";
import FollowButton from "../organisation/common/FollowButton";
import axios from "axios";
import {organisationHostname, searchHostname} from "../../utils/Configuration";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {SelectOption} from "../news/GlobalNews";
import AsyncSelect from "react-select/async";
import SearchCompany from "../../model/advanced-search/SearchCompany";
import IqFlag from "../common/CountryFlag";

interface OnboardingChannelPartnersProps {
    partners: Organisation[],
    continueCallback: Function,
    showWelcome: boolean
}

const OnboardingChannelPartners: React.FC<OnboardingChannelPartnersProps> = ({partners, continueCallback, showWelcome}) => {


    const [selected, setSelected] = useState([]) ;

    const [addVendors, setAddVendors] = useState(false) ;

    const CompanyLogo: React.FC<Organisation> = (company: Organisation) => {
        const [src, setSrc] = useState<string>(company.imageUrl ? company.imageUrl : "./icons_company.svg");

        return (
            <Image title={company.companyName}
                   style={{
                       height: "45px",
                       width: "45px",
                       objectFit: "cover"
                   }}
                   src={src}
                   onError={() => setSrc("/icons_company.svg")}
                   roundedCircle/>
        )
    };

    const [loadingChannelPartners, setLoadingChannelPartners] = useState<boolean>(false);
    const [channelPartners, setChannelPartners] = useState<Organisation[]>([]);
    useEffect(() => {
        const getChannelPartners = async () => {
            setLoadingChannelPartners(true);

            await axios.get(organisationHostname + "organisation/vendors")
                .then(r => setChannelPartners(r.data))
                .finally(() => setLoadingChannelPartners(false));
        };

        getChannelPartners();
    }, []);

    const loadOrganisations = (q: string) => {
        let data = {
            searchQuery: q,
            filters: ["Active"]

        };

        return new Promise<SelectOption[]>((resolve: any, reject: any) => {
            axios.post(searchHostname + "quick-search", data)
                .then((r) => {
                    resolve(
                        r.data.map((o: SearchCompany) => {
                            return {
                                label: <div style={{display: "flex"}}>
                                    <span>{o.companyName}</span>
                                    <span className={"option-companyId"}>{o.companyNumber}</span>
                                    <span className={"option-country-flag"}><IqFlag country={o.country} width={20} height={20} /></span>
                                    <FollowButton companyId={o.companyId} variant="grey"/>
                                </div>,
                                value: o.companyId
                            }
                        })
                    );
                })
                .catch(reject);
        });
    };

    const popover = (
        <Popover id="popover-basic">
            <h3>Add vendors to your organisation</h3>
            <div>
                Please add vendors to your organisation, to follow those companies.
            </div>
        </Popover>
    );

    return (
        <div>
            {showWelcome &&
                <div className="mb-4">
                    <span className="welcome">{translate("onboarding.welcome", {name: getUserFirstName()})}</span>
                </div>
            }

            {channelPartners && channelPartners.length > 0 &&
                <div className="mb-3">
                    {translate("onboarding.vendors")}
                </div>
            }



            <Table className="mb-2" hover>
                <tbody>
                {loadingChannelPartners && <IqLoadingIcon/>}
                {channelPartners.map((partner: Organisation) => (
                    <tr key={partner.id}>
                        <td>
                            <CompanyLogo {...partner} />
                        </td>
                        <td className="pt-4">
                            {partner.companyName}
                        </td>
                        <td className="pt-3">
                            <FollowButton companyId={partner.id} variant="grey"/>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <OverlayTrigger  placement="top" overlay={popover}>
            <button className="iq-button iq-button-primary" onClick={() => setAddVendors(!addVendors)}>
                Add Vendors
            </button>
            </OverlayTrigger>

            <div style={{paddingTop: "10px"}}>
            {addVendors &&
                <AsyncSelect
                             isMulti
                             placeholder={translate("onboarding.typeandselect")}
                             defaultOptions={[]}
                             loadOptions={loadOrganisations}/>
            }
            </div>


            <div style={{paddingTop: "30px"}}>
            <button  className="iq-button iq-button-primary pull-right"
                    onClick={() => continueCallback()}>
                {translate("onboarding.continue")}
            </button>
            </div>
        </div>
    );
};

export default OnboardingChannelPartners;