import axios from "axios";
import {formatLocale, FormatLocaleDefinition} from "d3-format";
import React, {useContext, useEffect, useState} from "react";
import {Alert, Card, Col, Image, Row} from "react-bootstrap";
import {matchPath, useLocation} from "react-router-dom";
import UserListContext from "../../../context/list/UserListContext";
import translate from "../../../i18n/translate";
import PieChartData from "../../../model/list/PieChartData";
import {frontEndHostName} from "../../../utils/Configuration";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import DashboardBarChart from "./common/DashboardBarChart";
import DashboardPieChart from "./common/DashboardPieChart";
import {COLOURS} from "../../techsales/TechSales";

interface Top5Data {
    organisationId: number,
    companyName: string,
    countryCode: string,
    imageUrl: string,
    value: number
}

interface FinancialData {
    profit: [],
    employees: [],
    revenue: [],
    top5ByRevenue: Top5Data[],
    top5ByGrowth: Top5Data[],
    top5ByDecline: Top5Data[],
    distributionByRevenue: PieChartData[]
}

const ListFinancials: React.FC = () => {
    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/lists/:listId',
        exact: false,
        strict: false
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [data, setData] = useState<FinancialData>();
    const { list, setList } = useContext(UserListContext);

    let UK = formatLocale({
        currency: ["£ ", ""]
    } as FormatLocaleDefinition)

    let EU = formatLocale({
        currency: ["€", ""]
    } as FormatLocaleDefinition)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await axios.get(frontEndHostName + "user-lists/" + childMatchedPath?.params.listId + "/dashboard/financials")
                .then(r => setData(r.data))
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchData();
    }, []);

    const CompanyLogo: React.FC<Top5Data> = (data: Top5Data) => {
        const [src, setSrc] = useState<string>(data.imageUrl ? data.imageUrl : "./icons_company.svg");

        return (
            <Image title={data.companyName}
                style={{
                    height: "30px",
                    width: "30px",
                    objectFit: "cover"
                }}
                src={src}
                onError={() => setSrc("/icons_company.svg")}
                roundedCircle />
        )
    };

    const formatYAxisLabel = (value: number) => {
        if (value < 1000) {
            return value.toLocaleString();
        }
        else if (value < 1000000) {
            return `${value / 1000}K`;
        }
        else if (value < 1000000000) {
            return `${value / 1000000}M`;
        }
        else {
            return `${value / 1000000000}B`;
        }
    };

    return (
        <div className="mb-3 mt-3">
            {error ? (
                <Alert variant="danger">
                    {translate("errors.loading")}
                </Alert>
            ) : (
                <div>
                    {loading ? <IqLoadingIcon /> : (
                        <div>
                            {data && (
                                <Row>
                                    {data.profit.length !== 0 && (
                                        <Col md={4}>
                                            <Card style={{ height: 400, borderRadius: "0.35rem" }}>
                                                <Card.Body>
                                                    <Card.Title>
                                                        <h4>{translate("lists.averagegrossprofit")}</h4>
                                                    </Card.Title>
                                                    <DashboardBarChart data={data.profit}
                                                        colour={COLOURS[0]}
                                                        title="Profit %"
                                                        yAxisFormat={(value: any) => `${value}`}
                                                        formatValue={(value: string) => {
                                                            return value + "%";
                                                        }} />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )}

                                    {data.revenue.length !== 0 && (
                                        <Col md={4}>
                                            <Card style={{ height: 400, borderRadius: "0.35rem" }}>
                                                <Card.Body>
                                                    <Card.Title>
                                                        <h4>{translate("lists.averagerevenue")}</h4>
                                                    </Card.Title>
                                                    <DashboardBarChart data={data.revenue}
                                                        colour={COLOURS[5]}
                                                        title="Revenue £M"
                                                        yAxisFormat={(value: any) => formatYAxisLabel(Number(value))}
                                                        formatValue={(value: string) => {
                                                            let formattedNumber = Number(value).toLocaleString();
                                                            return "£" + formattedNumber;
                                                        }} />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )}

                                    {data.employees.length !== 0 && (
                                        <Col md={4}>
                                            <Card style={{ height: 400, borderRadius: "0.35rem" }}>
                                                <Card.Body>
                                                    <Card.Title>
                                                        <h4>{translate("lists.averageemployees")}</h4>
                                                    </Card.Title>
                                                    <DashboardBarChart data={data.employees}
                                                        colour={COLOURS[3]}
                                                        title="Employees"
                                                        yAxisFormat={(value: any) => formatYAxisLabel(Number(value))}
                                                        formatValue={(value: string) => {
                                                            return Number(value).toLocaleString();
                                                        }} />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                            )}

                        </div>
                    )}


                    <Row className="mt-3">
                        {data?.top5ByRevenue && data.top5ByRevenue?.length > 0 && (
                            <Col md={6}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            <h4>{translate("lists.top5revenue")}</h4>
                                        </Card.Title>
                                        {data?.top5ByRevenue
                                            .sort((a: Top5Data, b: Top5Data) => b.value - a.value)
                                            .map((item: Top5Data) => (
                                                <Row style={{ paddingTop: "1%" }} key={item.organisationId}>
                                                    <Col xs={1}>
                                                        <CompanyLogo {...item} />
                                                    </Col>
                                                    <Col xs={7}>
                                                        <a href={"/organisation/" + item.organisationId}>{item.companyName}</a>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <div className="pull-right">
                                                            {item.countryCode === "UK" ?
                                                                UK.format("$,.3~s")(item.value).replace(/G/, "B") : EU.format("$,.3~s")(item.value).replace(/G/, "B")}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                            )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}

                        {(data?.top5ByGrowth && data.top5ByGrowth?.length > 0) && (
                            <Col md={6}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            <h4>{translate("lists.top5growth")}</h4>
                                        </Card.Title>
                                        {data?.top5ByGrowth
                                            .sort((a: Top5Data, b: Top5Data) => b.value - a.value)
                                            .map((item: Top5Data) => (
                                                <Row style={{ paddingTop: "1%" }} key={item.organisationId}>
                                                    <Col xs={1}>
                                                        <CompanyLogo {...item} />
                                                    </Col>
                                                    <Col xs={7}>
                                                        <a href={`/organisation/${item.organisationId}`}>{item.companyName}</a>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <div className="pull-right">
                                                            {item.value}%
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ))
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                    </Row>

                    <Row className="mt-3">
                        {data?.top5ByDecline && data.top5ByDecline?.length > 0 && (
                            <Col md={7}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            <h4>{translate("lists.top5decline")}</h4>
                                        </Card.Title>
                                        {data?.top5ByDecline
                                            .sort((a: Top5Data, b: Top5Data) => a.value - b.value)
                                            .map((item: Top5Data) => (
                                                <Row style={{ paddingTop: "1%" }} key={item.organisationId}>
                                                    <Col xs={1}>
                                                        <CompanyLogo {...item} />
                                                    </Col>
                                                    <Col xs={7}>
                                                        <a href={`/organisation/${item.organisationId}`}>{item.companyName}</a>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <div className="pull-right">
                                                            {item.value}%
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ))
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}

                        {data?.distributionByRevenue && data.distributionByRevenue.length > 0 && (
                            <Col md={5}>
                                <Card style={{ height: 400 }}>
                                    <Card.Body>
                                        <Card.Title>
                                            <h4>{translate("lists.distributionbyrevenue")}</h4>
                                        </Card.Title>
                                        {data ? (
                                            <DashboardPieChart title="Distribution By Revenue"
                                                chartId="distributionByRevenueChart"
                                                data={data?.distributionByRevenue}
                                                height={320} />
                                        ) : (
                                            <IqLoadingIcon />
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </div>
            )}
        </div>
    );
};

export default ListFinancials;
