import organisation_fr from "./organisation_fr";
import notifications_fr from "./notifications_fr";

export default {
    lang: "Français",
    signout: "Se déconnecter",
    signingout: "Déconnecter",
    months: {
        jan: "janvier",
        feb: "février",
        mar: "mars",
        apr: "avril",
        may: "mai",
        jun: "juin",
        jul: "juillet",
        aug: "aout",
        sep: "septembre",
        oct: "octobre",
        nov: "novembre",
        dec: "décembre"
    },
    locations: {
        UK: "United Kingdom",
        FR: "France",
        DE: "Germany",
        US: "United States"
    },
    onboarding: {
        welcome: "Bienvenue {name}!",
        vendors: "Nous avons trouvé les fournisseurs suivants pour votre organisation. Voulez-vous les suivre?",
        continue: "Continuer"
    },
    nav: {
        filters: "Filters",
        recentsearches: "Recherches récentes"
    },
    errors: {
        loading: "Erreur de chargement. Veuillez contacter le support technique.",
        generic: "Une erreur s'est produite, veuillez réessayer."
    },
    login: {
        signin: "Connexion",
        subtitle: "Saisissez vos identifiants ci-dessous pour poursuivre",
        email: "Email",
        password: "Mot de passe",
        forgotpassword: "Mot de passe oublié?",
        rememberme: "Se souvenir de moi",
        recoverpassword: "Récupérer votre mot de passe",
        enteremail: "Saisissez votre adresse e-mail ci-dessous pour récupérer votre mot de passe",
        emailsent: "L'e-mail de récupération a été envoyé",
        backtologin: "Retourner à l'écran de connexion",
        error: {
            incorrectpassword: "Votre mot de passe est incorrect",
            maxfailedattempts: "You have exceeded your maximum failed login attempts and have been locked out. Please contact support.",
            notactivatedpart1: "User is not activated, please contact our Customer Success Team ",
            notactivatedpart2: " at IQBlade to activate",
            concurrentsession: "User {user} is already logged in elsewhere.",
            endsession: "Login here instead?",
            confirmendsession: "Login here",
            other: "Nous rencontrons des problèmes avec l'authentification. Réessayez."
        }
    },
    dashboard: {
        additem: "Ajouter un élément",
        addnewitem: "Ajouter un nouvel élément à votre tableau de bord",
        close: "Fermer",
        add: "Ajouter",
        viewall: "Tout afficher",
        view: "Afficher",
        profile: {
            title: "Mon profil",
            welcome: "Nous sommes heureux de vous revoir, {name}!",
            listscreated: "{count} listes créées",
            listcompanies: "{count} sociétés répertoriées dans les listes",
            searchcredits: "{count} crédits de recherche utilisés",
            followedorganisations: "{count} organisations suivies",
            followedofficers: "{count} dirigeants suivies",
            contactrequests: "{count} Demande de contact crédits consommés",
            creditrefresh: "Actualisation dans {days} jours"
        },
        followedorganisations: {
            title: "Entreprises suivies",
            shownames: "Afficher le nom des sociétés"
        },
        twitter: {
            title: "X",
            filteredTitle: "X filtré selon les termes",
            followed: "Suivi",
            all: "Tous",
            organisation: "Organisation",
            officer: "Dirigeant",
            popoverTitle: "Charger les termes",
            popoverMessage: "Les centres d'intérêt enregistrés seront chargés"
        },
        globalnews: {
            title: "Actualités internationales"
        },
        followednews: {
            title: "Mes actualités"
        },
        filinghistory: {
            title: "Historique de dépôt"
        },
        calendar: {
            title: "Mon calendrier des évènements"
        },
        videos: {
            title: "Dernières vidéos YouTube",
            filteredTitle: "Dernières vidéos Youtube filtrées par termes",
        },
        followedorganisationsyearend: {
            title: "Entreprises suivies en fin d’année fiscal",
            limit: "{count} entreprises"
        },
        welcome: {
            title: "Bienvenue sur votre tableau de bord"
        }
    },
    news: {
        title: "Actualités"
    },
    lists: {
        title: "Listes",
        createdby: "Créée par {username}",
        createdon: "Créée le {date}",
        companies: "{count} sociétés",
        jobs: "Jobs",
        requestjobs: "Request Jobs",
        yours: "Votre liste",
        shared: "Partagées",
        viewlist: "Afficher la liste",
        deletelist: "Supprimer liste",
        companynumber: "SIREN",
        companyname: "Nom de la société",
        established: "Fondée en",
        employees: "Salariés",
        turnover: "Chiffre d'affaires",
        growth: "Croissance",
        decline: "Baisse",
        companytype: "Type de société",
        companylegaltype: "Company Legal Type",
        deleteselected: "Supprimer la sélection",
        selectall: "Tout sélectionner",
        deselectall: "Tout désélectionner",
        overview: "Vue d'ensemble",
        dashboard: "Tableau de bord",
        contacts: "Contacts",
        listactivity: "Activité de la liste",
        averagegrossprofit: "Marge brute moyenne",
        averagerevenue: "Recettes moyennes",
        averageemployees: "Effectif moyen",
        distributionbyrevenue: "Répartition par recettes",
        financial: "Éléments financiers",
        classification: "Classification",
        listcreated: "Liste créée",
        createlist: "Créer une Liste",
        transferlist: "Transférer liste",
        exportselected: "Exporter la sélection",
        exportlinkedin: "Exporter pour LinkedIn",
        exportPopoverTitle: "Autorisation requise pour un rôle supérieur",
        exportPopoverMessage: "Veuillez souscrire à un rôle de découverte pour accéder à cette fonctionnalité.",
        freemiumPopoverTitle: "Upgrade auf ein kostenpflichtiges Konto",
        freemiumPopoverMessage:"Bitte aktualisieren Sie Ihr Konto auf ein kostenpflichtiges, um die vollständige Liste zu sehen.",
        exportfailed: "Échec de l'exportation de la liste",
        rowsselected: "{count} lignes sélectionnées",
        deleting: "Suppression en cours...",
        requestcontacts: "Demande de contacts",
        exportcontacts: "Exporter des contacts",
        top5revenue: "Top 5 des recettes",
        top5growth: "Top 5 – Croissance sur 3ans",
        top5decline: "Top 5 – Baisse sur 3ans",
        top5growth1year: "Top 5 - Croissance sur 1ans",
        top5decline1year: "Top 5 - Baisse sur 1ans",
        deleteconfirm: "Souhaitez-vous vraiment supprimer {title}?",
        deleteconfirmtitle: "Êtes-vous sûr·e?",
        confirmdelete: "Oui!",
        deletesuccess: "Suppression réussie!",
        deletefailed: "Échec de la suppression de la liste",
        deletecompaniesfailed: "Impossible de supprimer des entreprises",
        deletecompaniesfailedcampaignlsit: "Vous ne pouvez pas supprimer les entreprises de cette liste car elles sont utiliséee dans une campagne.",
        deletefailedcampaignlist: "Vous ne pouvez pas supprimer cette liste car elle est utilisée dans une campagne.",
        companiesadded: "Sociétés ajoutées par {firstname} {lastname}",
        companiesremoved: "Sociétés supprimées par {firstname} {lastname}",
        comment: "Commentaire",
        next: "Suivant dans la liste",
        previous: "Précèdent dans la liste"
    },
    teams: {
        title: "Équipes",
        allteams: "Toutes les équipes",
        createteam: "Créer une équipe",
        main: {
            createdby: "Créée par {username}",
            createdon: "Créée le {date}",
            members: "{count} membres",
            viewteam: "Afficher l'équipe",
            manage: "Gérer",
            deleteteam: "Supprimer une équipe",
            backtoteams: "Retourner à l'écran des équipes",
        },
        common: {
            username: "Nom d'utilisateur",
            total: "Total",
            searches: "Recherches",
        },
        overview: {
            title: "Vue d'ensemble",
            lists: "Listes",
            recentactivity: "Activité récente",
            addedcompanies: "a ajouté des sociétés à",
            removedcompanies: "a supprimé des sociétés de",
            commentedon: "a ajouté un commentaire sur"
        },
        logins: {
            title: "Connexions",
            loginactivity: "Activité des connexions",
            totallogins: "Nombre total de connexions",
            lastloggedin: "Dernière connexion",
            time: "Durée",
            usercreatedon: "Utilisateur créé le",
        },
        lists: {
            user: "Utilisateur",
            listevents: "Événements de la liste",
            listname: "Nom de la liste",
            eventtype: "Type d'événement",
            date: "Date",
            sourced: "Sourced from",
            description: "Description",
            organisations: "Entreprises",
            totallists: "Nombre total de listes",
            totalcompaniesinlists: "Nombre total de sociétés répertoriées dans les listes",
            totalfollowedcompanies: "Nombre total de sociétés suivies",
            companiesadded: "Sociétés ajoutées",
            companiesremoved: "Sociétés supprimées",
            comment: "Commentaire"
        },
        searches: {
            advancedsearchesshared: "Recherches avancées partagées",
            lastrun: "Dernière recherche",
            search: "Recherche",
            searchcreditusage: "Utilisation des crédits de recherche",
        },
        manage: {
            teamdetails: "Informations sur l'équipe",
            teamname: "Nom de l'équipe",
            usersinteam: "Utilisateurs dans l'équipe",
            savechanges: "Enregistrer les modifications",
            addmembers: "Ajouter des membres",
            addmember: "Ajouter un membre",
            removemember: "Supprimer un membre",
            promote: "Promouvoir en tant qu'administrateur",
            demote: "Supprimer en tant qu'administrateur"
        }
    },
    advancedsearch: {
        title: "Recherche avancée",
        name: "Donnez un nom à votre recherche",
        confirmtitle: "vous êtes sûr?",
        confirm: "La création d’une nouvelle recherche consommera des crédits. Êtes-vous sûr de vouloir continuer?",
        unappliedtitle: "You have unsaved changes!",
        unappliedfilters: "You have some unsaved filters in your configuration. Are you sure you want to continue?",
        goback: "Go back",
        search: "Recherche",
        enduser: "Utilisateur final",
        channel: "Secteur",
        quickfilters: "Filtres prédéfinis",
        searchtype: "Type de recherche",
        remainingcredits: "Crédits restants",
        companiesfound: "Entreprises trouvées",
        employees: "Employés",
        established: "Fondée en",
        hostingproviders: "Hébergeur",
        locations: "Lieu",
        country: "Pays",
        reset: "Réinitialiser",
        morefilters: "Filtres personnalisés",
        createdon: "Créé le {date}",
        expiringon: "Expire le {date}",
        expiredon: "Expiré le {date}",
        to: "à",
        new: "Nouvelle Recherche",
        reuse: "Réutiliser",
        rerun: "Relancer",
        delete: "Supprimer",
        view: "Afficher la recherche",
        expired: "Cette recherche a expiré le {date}. Veuillez relancer la recherche pour afficher les résultats.",
        rerunconfirm: "Lorsque vous répétez une recherche, le crédit sera utilisé et la date d’expiration sera prolongée.",
        createsuccess: "Votre recherche a été créée",
        perpage: "{count} per page",
        sort: {
            relevance: "Pertinence",
            nameasc: "Nom de l'entreprise A-Z",
            namedesc: "Nom de l'entreprise Z-A",
            revenueasc: "Revenu - Croissant",
            revenuedesc: "Revenu Décroissant",
            investmentasc: "Investissement recueilli- Croissant",
            investmentdesc: "Investissement recueilli- Décroissant",
            employeesasc: "Employés Croissant",
            employeesdesc: "Employés Décroissant"
        },
        vendors: "Fournisseurs",
        products: "Produits",
        location: "Localisation (NUTS)",
        tags: "Tags",
        accreditations: "Accréditations",
        competencies: "Compétences",
        investmentraised: "Investissements réalisés"
    },
    organisation: organisation_fr,
    contracts: {
        nav: "Secteur public",
        title: "Contrats du secteur public",
        notfound: "Aucun contrat trouvé",
        back: "Retourner",
        contracttitle: "Titre",
        organisation: "Organisation",
        startdate: "Date de début",
        enddate: "Date de fin",
        publisheddate: "Date de publication",
        awarded: "Attribué",
        value: "Valeur",
        suppliername: "Nom du fournisseur",
        description: "Description",
        awardedsupplier: "Awarded Supplier",
        estimatedvalue: "Valeur totale estimée",
        follow: "Suivre",
        followed: "Suivi",
        showfollowed: "Afficher uniquement les contrats suivis",
        status: "Statut",
        tender: "Appel d'offres",
        contractawarded: "Contrat attribué",
        unsuccessful: "échoué",
        buyers: "Acheteurs",
        suppliers: "Fournisseurs",
        nomin: "Pas de minimum",
        nomax: "Pas de maximum",
        cpvcodes: "Codes CPV",
        save: "Enregistrer",
        clearfilters: "Effacer les filtres",
        filtername: "Filter Name",
        contractsfound: "Contracts found",
        create: "Create Filter",
        searchterm: "Search Term"
    },
    campaigns: {
        nav: "Campagnes",
        manager: "Manager de campagnes",
        title: "Campagnes"
    },
    marketintelligence: {
        title: "Intelligence économique",
        abbr: "M&A",
        investments: "Investissements",
        appointments: {
            title: "Nominations",
            name: "Nom",
            position: "Fonction"
        },
        sic: {
            title: "Industry Analysis",
            header: "Active Companies by Industry",
            category: "Category",
            description: "Description",
            date: "Date",
            count: "Count",
            prev: "Previous Month",
            variance: "Variance",
            pctchange: "% Change"
        },
    },
    contacts: {
        title: "Contacts",
        employment: "Emploi",
        education: "Education",
        discoveredon: "Découvert le",
        lastmodified: "Modifié le",
        noemployment: "Le contact n'a pas d'historique d'emploi.",
        noeducation: "Le contact n'a pas d'historique de formation",
        sort: {
            sortby: "Trier par...",
            datediscoveredasc: "Date de découverte - Croissant",
            datediscovereddesc: "Date de découverte - Décroissant",
            lastnameasc: "Nom de famille - Croissant",
            lastnamedesc: "Nom de famille - Décroissant",
            firstnameasc: "Prénom - Croissant",
            firstnamedesc: "Prénom - Décroissant",
            jobtitleasc: "Titre du poste - Croissant",
            jobtitledesc: "Titre du poste - Décroissant"
        },
        filter: {
            filters: "Filters",
            jobtitles: "Fonctions",
            jtplaceholder: "Saisissez vos fonctions",
            seniority: "Ancienneté",
            seniorityplaceholder: "Entrez l'ancienneté souhaitée",
            roles: "Rôles",
            roleplaceholder: "Entrez les rôles souhaités",
            country: "Pays",
            countryplaceholder: "Entrez les pays souhaités",
            alllists: "Toutes les listes",
            apply: "Appliquer des filtres"
        },
        nocontacts: {
            imported: "Malheureusement, nous n'avons pas de données de contact pour les utilisateurs de votre organisation.",
            organisation: "Malheureusement, nous n'avons pas de données de contact pour cette organisation."
        }
    },
    techsales: {
        nav: "Nos ventes TD",
        month: "Ventes dans le temps",
        calendarmonth: "Mois du calendrier",
        manufacturer: "Ventes par fabricant",
        category: "Ventes par catégorie",
        enduser: "Sales By End User"
    },
    renewals: {
        nav: "Renewals"
    },
    profile: {
        changepassword: "Modifier le mot de passe",
        currentpassword: "Mot de passe actuel",
        inputpassword: "Entrez votre mot de passe actuel",
        newpassword: "Nouveau mot de passe",
        enternewpassword: "Entrez le nouveau mot de passe",
        repeatpassword: "Répéter le mot de passe",
        repeatnewpassword: "Répétez le nouveau mot de passe",
        avatartwitter: "Télécharger l'avatar de mon profil X",
        avatardevice: "Télécharger l'avatar depuis mon appareil",
        chooseavatar: "Choisir l'avatar",
        social: {
            twitter: "X (seulement l'identifiant/le tag)"
        }
    },
    notifications: notifications_fr
}
