import {faBuilding} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import ReactTimeAgo from "react-time-ago";
import translate from "../../../i18n/translate";
import {Payload} from "../../../model/notification/Payload";
import {DateText, Highlight, Icon, Item, ItemContent, NotificationItem} from "../Notifications";

const ShareWithTeamNotification: React.FC<NotificationItem> = ({ element }) => {
    return (
        <Item read={element.read}>
            <Icon>
                <FontAwesomeIcon color="white" icon={faBuilding} />
            </Icon>
            <ItemContent>
                {element.middlewares.map((middleware: Payload) => (
                    <Highlight key={middleware.id}>
                        <span className="me-2">
                            {translate("notifications.share.organisation", {
                                name: element.source.placeholder
                            })}
                        </span>
                        <a target={"_blank"} href={["/organisation", middleware.id].join("/")}>
                            {middleware.placeholder}
                        </a>
                    </Highlight>
                ))}
                <DateText>
                    <ReactTimeAgo date={new Date(element.modified)} locale="en-US" />
                </DateText>
            </ItemContent>
        </Item>
    )

}

export default ShareWithTeamNotification;