import React, {useContext, useEffect, useState} from "react";
import {organisationHostname} from "../../utils/Configuration";
import axios from "axios";
import qs from "qs";
import "./RenewalsContainer.css";
import {Renewal} from "../../model/renewals/Renewal";
import {RenewalsFilters} from "../../model/renewals/RenewalsFilters";
import RenewalsContext, {RenewalsSort} from "../../context/renewals/RenewalsContext";
import RenewalsList from "./RenewalsList";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import RenewalView from "./RenewalView";
import RenewalsDashboard from "./RenewalsDashboard";
import SubmenuContext from "../../context/SubmenuContext";
import {faChartPie, faRedo} from "@fortawesome/free-solid-svg-icons";
import translate from "../../i18n/translate";
import {Container} from "react-bootstrap";

const RenewalsContainer: React.FC = () => {

    let match = useRouteMatch();
    const { submenuTitle, setSubmenuTitle, submenuItems, setSubmenuItems } = useContext(SubmenuContext);
    useEffect(() => {
        setSubmenuItems([
            {
                name: "All Renewals",
                url: "/renewals",
                icon: faRedo
            },
            {
                name: "Dashboard",
                url: "/renewals/dashboard",
                icon: faChartPie
            }
        ]);

        setSubmenuTitle(translate("renewals.nav"));

        return () => {
            setSubmenuTitle("");
            setSubmenuItems([]);
        };
    }, []);

    const [filters, setFilters] = useState<RenewalsFilters | null>(null);
    const [showFilters, setShowFilters] = useState<boolean>(false);

    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [renewals, setRenewals] = useState<Renewal[]>([]);

    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [sort, setSort] = useState<RenewalsSort>(RenewalsSort.AGREEMENT_END_DATE);
    const [desc, setDesc] = useState<boolean>(false);
    const [hideExpired, setHideExpired] = useState<boolean>(true);

    const getPostData = () => {
        if (filters) {
            return filters;
        }
    }

    const [loadingVendors, setLoadingVendors] = useState<boolean>(false);
    const [vendors, setVendors] = useState<string[]>([]);
    useEffect(() => {
        const fetchVendors = async () => {
            setLoadingVendors(true);
            await axios.get(organisationHostname + "renewals/vendors")
                .then(r => setVendors(r.data))
                .finally(() => setLoadingVendors(false));
        }

        fetchVendors();
    }, []);

    const [loadingEndUsers, setLoadingEndUsers] = useState<boolean>(false);
    const [endUsers, setEndUsers] = useState<string[]>([]);
    useEffect(() => {
        const fetchEndUsers = async () => {
            setLoadingEndUsers(true);
            await axios.get(organisationHostname + "renewals/end-users")
                .then(r => setEndUsers(r.data))
                .finally(() => setLoadingEndUsers(false));
        }

        fetchEndUsers();
    }, []);

    const fetchRenewals = async () => {
        setLoading(true);
        let params: any = {
            hideExpired: hideExpired,
            page: page - 1,
            size: pageSize,
            sort: sort + "," + (desc ? "DESC" : "ASC")
        };

        let postData = getPostData();
        await axios.post(organisationHostname + "renewals", postData, {
                params: params,
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                }
            })
            .then(r => {
                setRenewals(r.data.content);
                setTotalRecords(r.data.totalElements);
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchRenewals();
    }, [page, pageSize, sort, desc, filters, hideExpired]);

    return (
        <SubmenuContext.Provider value={{ submenuTitle, setSubmenuTitle, submenuItems, setSubmenuItems }}>
            <RenewalsContext.Provider value={{
                renewals, setRenewals,
                filters, setFilters,
                hideExpired, setHideExpired,
                page, setPage,
                pageSize, setPageSize,
                sort, setSort,
                desc, setDesc,
                showFilters, setShowFilters,
                loading, setLoading,
                vendors, setVendors,
                endUsers, setEndUsers,
                loadingVendors, setLoadingVendors,
                loadingEndUsers, setLoadingEndUsers,
                totalRecords, setTotalRecords
            }}>
                <Container fluid style={{ marginTop: 15, marginRight: "auto", paddingLeft: 15 }}>
                    <div className="iq-headline">
                        <span className="text">
                            Renewals
                        </span>
                    </div>
                    <Switch>
                        <Route exact path={`${match.url}`} component={RenewalsList} />
                        <Route exact path={`${match.url}/dashboard`} component={RenewalsDashboard} />
                        <Route path={`${match.url}/:renewalId`} component={RenewalView} />
                    </Switch>
                </Container>
            </RenewalsContext.Provider>
        </SubmenuContext.Provider>
    );
};

export default RenewalsContainer;