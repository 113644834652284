import axios from 'axios';
import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import {useRouteMatch} from "react-router-dom";
import Organisation from '../../model/organisation/Organisation';
import {organisationHostname} from '../../utils/Configuration';
import './AdminDataAnalytics.css';
import AdminDataAnalyticsCard from './AdminDataAnalyticsCard';
import {showGenericErrorDialog, showGenericSuccessDialog} from "../common/CommonAlerts";


const AdminDataAnalytics: React.FC = () => {

    let match = useRouteMatch();

    enum Switch {
        LISTS = "lists",
        FOLLOWED = "followed"
    }

    interface OrganisationCard {
        organisations: Organisation[],
        switch: Switch,
        totalPages: number,
        loading: boolean
    }

    const defaultValues = {
        organisations: [],
        switch: Switch.LISTS,
        totalPages: 0,
        loading: false
    };

    const [organisationsWithoutHgData, setOrganisationsWithoutHgData] = useState<OrganisationCard>(defaultValues)
    const [organisationsWithoutFinancials, setOrganisationsWithoutFinancials] = useState<OrganisationCard>(defaultValues)
    const [organisationsWithOldFilings, setOrganisationsWithOldFilings] = useState<OrganisationCard>(defaultValues)
    const [organisationsWithoutUrl, setOrganisationsWithoutUrl] = useState<OrganisationCard>(defaultValues)
    const [organisationsWithoutTwitter, setOrganisationsWithoutTwitter] = useState<OrganisationCard>(defaultValues)

    const [isServiceRunning, setIsServiceRunning] = useState<boolean>(false);

    const retrieveOrgsWithoutHgData = async (page: number = 1) => {
        setOrganisationsWithoutHgData({
            ...organisationsWithoutHgData,
            loading: true
        });
        const url = [organisationHostname, 'organisation/data-analytics/', organisationsWithoutHgData.switch, '/', 'without-hgdata', '?page=', page - 1].join("")
        const result = await axios.get(url)
            .then((response) => {
                setOrganisationsWithoutHgData({
                    organisations: response.data.content,
                    switch: organisationsWithoutHgData.switch,
                    totalPages: response.data.totalPages,
                    loading: false
                });
            }).catch(error => console.log(error.message));
    };

    const retrieveOrgsWithoutFinancials = async (page: number = 1) => {
        setOrganisationsWithoutFinancials({
            ...organisationsWithoutFinancials,
            loading: true
        });
        const url = [organisationHostname, 'organisation/data-analytics/', organisationsWithoutFinancials.switch, '/', 'without-financials', '?page=', page - 1].join("")
        const result = await axios.get(url)
            .then((response) => {
                setOrganisationsWithoutFinancials({
                    organisations: response.data.content,
                    switch: organisationsWithoutFinancials.switch,
                    totalPages: response.data.totalPages,
                    loading: false
                });
            }).catch(error => console.log(error.message));
    };

    const retrieveOrgsWithOldFilings = async (page: number = 1) => {
        setOrganisationsWithOldFilings({
            ...organisationsWithOldFilings,
            loading: true
        });
        const url = [organisationHostname, 'organisation/data-analytics/', organisationsWithOldFilings.switch, '/', 'with-old-filings', '?page=', page - 1].join("")
        const result = await axios.get(url)
            .then((response) => {
                setOrganisationsWithOldFilings({
                    organisations: response.data.content,
                    switch: organisationsWithOldFilings.switch,
                    totalPages: response.data.totalPages,
                    loading: false
                });
            }).catch(error => console.log(error.message));
    };

    const retrieveOrgsWithoutUrl = async (page: number = 1) => {
        setOrganisationsWithoutUrl({
            ...organisationsWithoutUrl,
            loading: true
        });
        const url = [organisationHostname, 'organisation/data-analytics/', organisationsWithoutUrl.switch, '/', 'without-url', '?page=', page - 1].join("")
        const result = await axios.get(url)
            .then((response) => {
                setOrganisationsWithoutUrl({
                    organisations: response.data.content,
                    switch: organisationsWithoutUrl.switch,
                    totalPages: response.data.totalPages,
                    loading: false
                });
            }).catch(error => console.log(error.message));
    };

    const retrieveOrgsWithoutTwitter = async (page: number = 1) => {
        setOrganisationsWithoutTwitter({
            ...organisationsWithoutTwitter,
            loading: true
        });
        const url = [organisationHostname, 'organisation/data-analytics/', organisationsWithoutTwitter.switch, '/', 'without-twitter', '?page=', page - 1].join("")
        const result = await axios.get(url)
            .then((response) => {
                setOrganisationsWithoutTwitter({
                    organisations: response.data.content,
                    switch: organisationsWithoutTwitter.switch,
                    totalPages: response.data.totalPages,
                    loading: false
                });
            }).catch(error => console.log(error.message));
    };

    const updateFinancialStatements = (e: any, organisationId: string) => {
        e.preventDefault();
        setIsServiceRunning(true);
        axios.get(organisationHostname + 'organisation/' + organisationId + '/update-financial-statements')
            .then((response) => {
                showGenericSuccessDialog();
            })
            .catch((reason) => {
                showGenericErrorDialog();
            })
            .finally(() => setIsServiceRunning(false));
    }


    const updateWithClearbit = (e: any, organisationId: string) => {
        e.preventDefault();
        setIsServiceRunning(true);
        axios.post(organisationHostname + 'organisation/' + organisationId + '/edit/clearbit')
            .then((response) => {
                showGenericSuccessDialog();
            })
            .catch(() => {
                showGenericErrorDialog();
            })
            .finally(() => setIsServiceRunning(false));
    }

    const updateFilingHistory = (e: any, organisationId: string) => {
        e.preventDefault();
        setIsServiceRunning(true);
        axios.get(organisationHostname + 'organisation/update-filing-history?organisationIds=' + organisationId)
            .then((response) => {
                showGenericSuccessDialog();
            })
            .catch(() => {
                showGenericErrorDialog();
            })
            .finally(() => setIsServiceRunning(false));
    }

    return (
        <div id="admin-analytics-container">
            <div className="row">
                <AdminDataAnalyticsCard totalPages={organisationsWithoutHgData.totalPages}
                    title={"Organisations in lists that do no have tech stack"}
                    values={organisationsWithoutHgData}
                    setter={setOrganisationsWithoutHgData}
                    retriever={retrieveOrgsWithoutHgData}
                    isServiceRunning={isServiceRunning}
                    setIsServiceRunning={setIsServiceRunning}
                />
                <AdminDataAnalyticsCard
                    title={"Organisations in lists that do no have financials"}
                    totalPages={organisationsWithoutFinancials.totalPages}
                    values={organisationsWithoutFinancials}
                    setter={setOrganisationsWithoutFinancials}
                    retriever={retrieveOrgsWithoutFinancials}
                    isServiceRunning={isServiceRunning}
                    setIsServiceRunning={setIsServiceRunning}
                    updateButton={(organisationId: string) => organisationId?.substring(0, 2) === "UK" && <Button style={{ marginLeft: "15px" }}
                        variant="warning"
                        type="submit"
                        disabled={isServiceRunning}
                        onClick={(e: any) => updateFinancialStatements(e, organisationId)}>
                        Fetch CS
                    </Button>} />
            </div>
            <div className="row">
                <AdminDataAnalyticsCard totalPages={organisationsWithOldFilings.totalPages} values={organisationsWithOldFilings}
                    title={"Organisations in lists that have filings older than 6 months"}
                    setter={setOrganisationsWithOldFilings}
                    retriever={retrieveOrgsWithOldFilings}
                    isServiceRunning={isServiceRunning}
                    setIsServiceRunning={setIsServiceRunning}
                    updateButton={(organisationId: string) => organisationId?.substring(0, 2) === "UK" && <Button style={{ marginLeft: "15px" }}
                        variant="warning"
                        type="submit"
                        disabled={isServiceRunning}
                        onClick={(e: any) => updateFilingHistory(e, organisationId)}>
                        Fetch CH
                    </Button>} />
                <AdminDataAnalyticsCard totalPages={organisationsWithoutUrl.totalPages}
                    values={organisationsWithoutUrl}
                    title={"Organisations in lists without URL and Domain Aliases"}
                    setter={setOrganisationsWithoutUrl}
                    retriever={retrieveOrgsWithoutUrl}
                    isServiceRunning={isServiceRunning}
                    setIsServiceRunning={setIsServiceRunning} />
            </div>
            <div className="row">
                <AdminDataAnalyticsCard totalPages={organisationsWithoutTwitter.totalPages} values={organisationsWithoutTwitter}
                    title={"Organisations in lists without Twitter handle"}
                    setter={setOrganisationsWithoutTwitter}
                    retriever={retrieveOrgsWithoutTwitter}
                    isServiceRunning={isServiceRunning}
                    setIsServiceRunning={setIsServiceRunning}
                    updateButton={(organisationId: string) => <Button style={{ marginLeft: "15px" }}
                        variant="warning"
                        type="submit"
                        disabled={isServiceRunning}
                        onClick={(e: any) => updateWithClearbit(e, organisationId)}>
                        Fetch CB (must have URL)
                    </Button>} />
            </div>
        </div>
    );
}

export default AdminDataAnalytics;
