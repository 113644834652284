import React from "react";
import {useHistory} from "react-router-dom";
import translate from "../../../i18n/translate";
import Accreditation from "../../../model/organisation/Accreditation";
import "./OrganisationAccreditations.css";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {faChevronRight, faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
    accreditations: Accreditation[]
}

const OrganisationAccreditations: React.FC<Props> = (props: Props) => {
    let history = useHistory();

    const getSearchGraph = (accreditation: string) => {
        let query = {
            id: "b8bbba89-cdef-4012-b456-7178020bbee5",
            type: "group",
            path:[
                "b8bbba89-cdef-4012-b456-7178020bbee5"
            ],
            children1:{
                "8a98b9aa-89ab-4cde-b012-3178020c021d": {
                    type:"rule",
                    id:"8a98b9aa-89ab-4cde-b012-3178020c021d",
                    properties:{
                        field: "accreditations.accreditation.concatenatedName.keyword",
                        operator: "select_any_in",
                        value: [
                            accreditation
                        ],
                        valueSrc:[
                            "value"
                        ],
                        valueType: [
                            "multiselect"
                        ]
                    },
                    path:[
                        "b8bbba89-cdef-4012-b456-7178020bbee5",
                        "8a98b9aa-89ab-4cde-b012-3178020c021d"
                    ]
                }
            }
        };

        return JSON.stringify(query);
    };

    return (
        <div className="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7" >
            <div className="organisation-card">
                <div className="organisation-card-label">
                    {translate("organisation.summary.techpartners")}
                </div>
                <div className="organisation-card-text">
                    {props.accreditations?.map((accreditation: Accreditation) => (
                        <OverlayTrigger trigger="click"
                                        placement="bottom"
                                        rootClose={true}
                                        overlay={
                                            <Popover id="accreditation-action">
                                                <div className="organisation-card-label">
                                                    {accreditation.accreditation?.concatenatedName}
                                                </div>
                                                <div className="mt-4 mb-4">
                                                    <a className="iq-button iq-button-primary"
                                                       onClick={() => history.push("/advanced-search/create", { searchGraph: getSearchGraph(accreditation.accreditation.concatenatedName), searchType: "channel" })}>
                                                        <span className="me-2">
                                                            {translate("advancedsearch.title")}
                                                        </span>
                                                        <FontAwesomeIcon icon={faSearch} />
                                                    </a>
                                                </div>
                                                <div className="mt-2 mb-2">
                                                    <a className="iq-button iq-button-secondary"
                                                       href={"/organisation/" + accreditation.accreditation.organisationId + "/channel-partners?view=list"}>
                                                        <span className="me-2">
                                                            {translate("organisation.channelpartners.title")}
                                                        </span>
                                                        <FontAwesomeIcon icon={faChevronRight}/>
                                                    </a>
                                                </div>
                                            </Popover>
                                        }
                                        delay={{ show: 250, hide: 400 }}>
                            <div className="badge badge-primary badge-link">
                                {accreditation?.accreditation?.concatenatedName}
                            </div>
                        </OverlayTrigger>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OrganisationAccreditations;