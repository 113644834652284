import React, {useRef, useState} from "react";
import {Alert, Spinner, Table} from "react-bootstrap";
import translate from "../../i18n/translate";
import IqLoadingIcon from "../common/IqLoadingIcon";
import DateFormatter from "../../utils/formatters/DateFormatter";
import {useHistory} from "react-router-dom";
import {GazetteDetail} from "../../model/organisation/GazetteDetail";
import {faLink, faNewspaper, faSort, faSortAmountDown, faSortAmountDownAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props{
    gazetteDetails: GazetteDetail[]
}

enum Sort {
    PUBLISHED = "published",
    NOTICE_TYPE = "noticeType",
    CATEGORY = "category",
    ORGANISATION = "organisation"
}
const OrganisationGazetteDetails: React.FC<Props> = (props:Props) => {

    let history = useHistory();


    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState<string>(Sort.PUBLISHED);
    const [desc, setDesc] = useState<boolean>(true);
    const scroller = useRef<any>();


    const handleScroll = () => {
        if (!loading && !error) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            let currentScroll = scroller.current.scrollTop;

            if (currentScroll === maxScroll) {
                setPage(prev => prev + 1);
            }
        }
    };

    const getSortButton = (s: Sort) => {
        if (s === sort) {
            return (
                <a className="sort-button pull-right"
                   onClick={() => {
                       setDesc(!desc);
                   }}>
                    <FontAwesomeIcon icon={desc ? faSortAmountDown : faSortAmountDownAlt}/>
                </a>
            );
        }

        return (
            <a className="sort-button pull-right"
               onClick={() => {
                   setSort(s);
                   setDesc(true);
               }}>
                <FontAwesomeIcon icon={faSort}/>
            </a>
        );
    };


    return (
        <div>
            {error ? (
                <Alert variant="danger">
                    {translate("errors.loading")}
                </Alert>
            ) : (
                <div className="filing-history-widget-container" style={{overflowY: "scroll" }} ref={scroller} onScroll={handleScroll}>
                    <Table>
                        <thead>
                        <tr>
                            <th>
                                {translate("marketintelligence.risk.published")}
                                {getSortButton(Sort.PUBLISHED)}
                            </th>
                            <th>
                                {translate("marketintelligence.risk.noticetype")}
                                {getSortButton(Sort.NOTICE_TYPE)}
                            </th>
                            <th>
                                {translate("marketintelligence.risk.category")}
                                {getSortButton(Sort.CATEGORY)}
                            </th>
                            <th className="text-end">
                                    <span>
                                        <FontAwesomeIcon icon={faNewspaper} size="1x"/>
                                    </span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.gazetteDetails.map((gazetteDetail: GazetteDetail) => (
                            <tr key={gazetteDetail.gazetteId}>
                                <td>
                                    <span>{DateFormatter.formatDate(gazetteDetail.published.toString())}</span>
                                </td>
                                <td>
                                    <span>{gazetteDetail.noticeType}</span>
                                </td>
                                <td>
                                    <span>{gazetteDetail.category}</span>
                                </td>
                                <td className="text-end">
                                    <a className="iqx-link" target="_blank" href={gazetteDetail?.noticeLink}>
                                        {loading ? (
                                            <Spinner className="icon float-end" style={{ display: "block", height: "1rem", width: "1rem" }}
                                                     animation="border"
                                                     variant="primary" />
                                        ) : (
                                            <FontAwesomeIcon icon={faLink} size="1x"/>
                                        )}
                                    </a>
                                </td>
                                {/*<td>*/}
                                {/*    <span className="description">{getAmount(mergerDetail)}</span>*/}
                                {/*</td>*/}
                                {/*<td>&nbsp;</td>*/}
                                {/*<td className="text-end">*/}
                                {/*    <ArticleLink articleId={mergerDetail.articleUuid}/>*/}
                                {/*</td>*/}
                            </tr>
                        ))}

                        {/*{props.appointmentDetails.map((appointment: AppointmentDetails) => (*/}
                        {/*    <tr key={appointment.id}>*/}
                        {/*        <td>*/}
                        {/*            {DateFormatter.formatDate(appointment.date)}*/}
                        {/*        </td>*/}
                        {/*        <td>*/}
                        {/*      <span className="pe-3">*/}
                        {/*        <CompanyLogo {...appointment.organisation} />*/}
                        {/*      </span>*/}
                        {/*            <span className="iqx-link pt-2" onClick={() => history.push("/organisation/" + appointment.organisation.id)}>*/}
                        {/*          {appointment.organisation.companyName.toUpperCase()}*/}
                        {/*      </span>*/}
                        {/*        </td>*/}
                        {/*        <td>*/}
                        {/*      <span className="pt-2">*/}
                        {/*       {titleCase(appointment.name)}*/}
                        {/*       </span>*/}
                        {/*        </td>*/}
                        {/*        <td>*/}
                        {/*            Appointed as {formatPosition(appointment.position)}*/}
                        {/*        </td>*/}

                        {/*        <td className="text-end">*/}
                        {/*            <ArticleLink articleId={appointment.articleUuid}/>*/}
                        {/*        </td>*/}
                        {/*    </tr>*/}
                        {/*))}*/}



                        </tbody>
                    </Table>
                    <div>{loading && <IqLoadingIcon />}</div>
                </div>
            )}
        </div>
    )
}

export default OrganisationGazetteDetails;