import React, {useState} from "react";
import PieChartData from "../../../../model/list/PieChartData";
import {AutoSizer} from "react-virtualized";
import {DiscreteColorLegend, Hint, RadialChart} from "react-vis";
import './DashboardPieChart.css';
import {COLOURS} from "../../../techsales/TechSales";

interface Props {
    data: PieChartData[],
    title: string,
    chartId: string,
    height: number,
    formatValue?: (value: number | string) => {}
}

const DashboardPieChart: React.FC<Props> = (props: Props) => {
    const [hover, setHover] = useState<any>();

    return (

        <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10, height: props.height }}>
            <AutoSizer>
                {({ height, width }) => (
                    <div style={{ display: "flex", flexDirection: "row", height: height, width: width, justifyContent: "center", alignItems: "center" }}>
                        <RadialChart
                            angleDomain={[0, 100]}
                            data={props.data.map((item: PieChartData, index: number) => {
                                const formattedValue = props.formatValue ? props.formatValue(item.value) : item.value;
                                return {
                                    label: formattedValue + " (" + item.label + ")",
                                    angle: item.value,
                                    color: COLOURS[index]
                                }
                            })}
                            onValueMouseOver={(v: any) => {
                                setHover(v);
                            }}
                            onValueMouseOut={(v: any) => {
                                setHover(null);
                            }}
                            width={width * 70 / 100}
                            height={height}
                            colorType="literal">
                            {hover && (
                                <Hint value={hover}>
                                    <div style={{
                                        display: 'flex',
                                        color: 'white',
                                        backgroundColor: 'black',
                                        opacity: 0.9,
                                        alignItems: 'center',
                                        padding: '5px',
                                        borderRadius: '5px',
                                        border: '2px solid',
                                        fontWeight: 500
                                    }}>
                                        <div>{hover?.label}</div>
                                    </div>
                                </Hint>
                            )}
                        </RadialChart>

                        <DiscreteColorLegend
                            height={height}
                            orientation={"vertical"}
                            width={width * 30 / 100}
                            items={props.data.filter((item) => item.label !== null).map((item, index) => { return { title: item.label, color: COLOURS[index], strokeWidth: 25 } })} />
                    </div>

                )}
            </AutoSizer>
        </div>
    );
};

export default DashboardPieChart;
