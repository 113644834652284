import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import './OrganisationSummary.css';
import { Container, Row, Table } from 'react-bootstrap';
import OrganisationFinancialSummary from '../../model/organisation/OrganisationFinancialSummary';
import axios from 'axios';
import { organisationHostname } from '../../utils/Configuration';
import FinancialStat from './common/FinancialStat';
import queryString from 'query-string';
import UserOrganisationContext from '../../context/organisation/OrganisationContext';
import { formatLocale, FormatLocaleDefinition } from 'd3-format';
import translate from "../../i18n/translate";

const OrganisationFinancials: React.FC = () => {

    let { organisationId } = useParams<{ organisationId: string }>();
    let location = useLocation();
    const history = useHistory();

    const parsed = queryString.parse(location.search);

    const [financialView, setFinancialView] = useState(parsed.view);
    const [financialSummaryValues, setOrgFinancialValues] = useState<OrganisationFinancialSummary>({} as OrganisationFinancialSummary);
    const { organisation, setOrganisation } = useContext(UserOrganisationContext);


    const getIncomeStatementValues = () => {
        return [
            {
                name: "organisation.financials.weeks",
                values: getIncomeStatementValuesPerYear("weeks", "none")
            },
            {
                name: "organisation.financials.currency",
                values: getIncomeStatementValuesPerYear("currency", "none")
            },
            {
                name: "organisation.financials.consolidatedaccounts",
                values: getIncomeStatementValuesPerYear("consolidatedAccounts", "none")
            },
            {
                name: "organisation.financials.turnover",
                values: getIncomeStatementValuesPerYear("turnover", "currency")
            },
            {
                name: "organisation.financials.costofsales",
                values: getIncomeStatementValuesPerYear("costOfSales", "currency")
            },
            {
                name: "organisation.financials.grossprofit",
                values: getIncomeStatementValuesPerYear("grossProfit", "currency")
            },
            {
                name: "organisation.financials.wages",
                values: getIncomeStatementValuesPerYear("wagesAndSalaries", "currency")
            },
            {
                name: "organisation.financials.emoluments",
                values: getIncomeStatementValuesPerYear("directorEmoluments", "currency")
            },
            {
                name: "organisation.financials.operatingprofit",
                values: getIncomeStatementValuesPerYear("operatingProfit", "currency")
            },
            {
                name: "organisation.financials.depreciation",
                values: getIncomeStatementValuesPerYear("depreciation", "currency")
            },
            {
                name: "organisation.financials.auditfees",
                values: getIncomeStatementValuesPerYear("auditFees", "currency")
            },
            {
                name: "organisation.financials.interestpayments",
                values: getIncomeStatementValuesPerYear("interestPayments", "currency")
            },
            {
                name: "organisation.financials.pretax",
                values: getIncomeStatementValuesPerYear("pretaxProfit", "currency")
            },
            {
                name: "organisation.financials.tax",
                values: getIncomeStatementValuesPerYear("taxation", "currency")
            },
            {
                name: "organisation.financials.pat",
                values: getIncomeStatementValuesPerYear("profitAfterTax", "currency")
            },
            {
                name: "organisation.financials.retainedprofit",
                values: getIncomeStatementValuesPerYear("retainedProfit", "currency")
            },
            {
                name: "organisation.financials.export",
                values: getIncomeStatementValuesPerYear("export", "currency")
            }
        ]
    };

    const getBalanceSheetValues = () => {
        return [
            {
                name: "organisation.financials.weeks",
                values: getIncomeStatementValuesPerYear("weeks", "none")
            },
            {
                name: "organisation.financials.currency",
                values: getIncomeStatementValuesPerYear("currency", "none")
            },
            {
                name: "organisation.financials.consolidatedaccounts",
                values: getIncomeStatementValuesPerYear("consolidatedAccounts", "none")
            },
            {
                name: "organisation.financials.cash",
                values: getIncomeStatementValuesPerYear("cash", "currency")
            },
            {
                name: "organisation.financials.assets.assets",
                values: getIncomeStatementValuesPerYear("totalAssets", "currency")
            },
            {
                name: "organisation.financials.liabilities.liabilities",
                values: getIncomeStatementValuesPerYear("totalLiabilities", "currency")
            },
            {
                name: "organisation.financials.assets.intangible",
                values: getIncomeStatementValuesPerYear("intangibleAssets", "currency")
            },
            {
                name: "organisation.financials.assets.misc",
                values: getIncomeStatementValuesPerYear("miscellaneousCurrentAssets", "currency")
            },
            {
                name: "organisation.financials.liabilities.misc",
                values: getIncomeStatementValuesPerYear("miscellaneousCurrentLiabilities", "currency")
            },
            {
                name: "organisation.financials.debtors.other",
                values: getIncomeStatementValuesPerYear("otherDebtors", "currency")
            },
            {
                name: "organisation.financials.longtermfinance.other",
                values: getIncomeStatementValuesPerYear("otherLongTermFinance", "currency")
            },
            {
                name: "organisation.financials.shorttermfinance",
                values: getIncomeStatementValuesPerYear("otherShortTermFinance", "currency")
            },
            {
                name: "organisation.financials.assets.tangible",
                values: getIncomeStatementValuesPerYear("tangibleAssets", "currency")
            },
            {
                name: "organisation.financials.assets.current",
                values: getIncomeStatementValuesPerYear("totalCurrentAssets", "currency")
            },
            {
                name: "organisation.financials.liabilities.current",
                values: getIncomeStatementValuesPerYear("totalCurrentLiabilities", "currency")
            },
            {
                name: "organisation.financials.assets.fixed",
                values: getIncomeStatementValuesPerYear("totalFixedAssets", "currency")
            },
            {
                name: "organisation.financials.longtermfinance.total",
                values: getIncomeStatementValuesPerYear("totalLongTermFinance", "currency")
            },
            {
                name: "organisation.financials.creditors",
                values: getIncomeStatementValuesPerYear("tradeCreditors", "currency")
            },
            {
                name: "organisation.financials.debtors.trade",
                values: getIncomeStatementValuesPerYear("tradeDebtors", "currency")
            }
        ]
    };

    const getRatiosValues = () => {
        return [
            {
                name: "organisation.financials.pretaxmargin",
                values: getIncomeStatementValuesPerYear("preTaxProfitMargin", "none")
            },
            {
                name: "organisation.financials.currentratio",
                values: getIncomeStatementValuesPerYear("currentRatio", "none")
            },
            {
                name: "organisation.financials.salesnetworkingcapital",
                values: getIncomeStatementValuesPerYear("salesNetworkingCapital", "none")
            },
            {
                name: "organisation.financials.gearing",
                values: getIncomeStatementValuesPerYear("gearing", "none")
            },
            {
                name: "organisation.financials.equity",
                values: getIncomeStatementValuesPerYear("equityInPercent", "none")
            },
            {
                name: "organisation.financials.creditordays",
                values: getIncomeStatementValuesPerYear("creditorDays", "none")
            },
            {
                name: "organisation.financials.liquidity",
                values: getIncomeStatementValuesPerYear("liquidityAcidTest", "none")
            },
            {
                name: "organisation.financials.roce.full",
                values: getIncomeStatementValuesPerYear("returnOnCapitalEmployed", "none")
            },
            {
                name: "organisation.financials.rotae",
                values: getIncomeStatementValuesPerYear("returnOnTotalAssetsEmployed", "none")
            },
            {
                name: "organisation.financials.currentdebt",
                values: getIncomeStatementValuesPerYear("currentDebtRatio", "none")
            },
            {
                name: "organisation.financials.totaldebt",
                values: getIncomeStatementValuesPerYear("totalDebtRatio", "none")
            },
            {
                name: "organisation.financials.stockturnover",
                values: getIncomeStatementValuesPerYear("stockTurnoverRatio", "none")
            },
            {
                name: "organisation.financials.ronae",
                values: getIncomeStatementValuesPerYear("returnOnNetAssetsEmployed", "none")
            }
        ]
    };

    let UK = formatLocale({
        currency: ["£", ""]
    } as FormatLocaleDefinition)

    let EU = formatLocale({
        currency: ["€", ""]
    } as FormatLocaleDefinition)

    let US = formatLocale({
        currency: ["$", ""]
    } as FormatLocaleDefinition)

    const getIncomeStatementValuesPerYear = (member: string, symbol: string) => {
        let symbols: any = {
            "none": function (oyv: Record<string, any>) {
                return <td>{oyv[member] === null ? "-" : oyv[member]}</td>;
            },
            "currency": function (oyv: Record<string, any>) {
                if (organisation.countryCode === "UK") {
                    return <td>{(Number.isNaN(Number(oyv[member])) || oyv[member] === null) ? "-" : UK.format('$,.3s')(+oyv[member]).replace(/G/, "B")}</td>
                } else if (organisation.countryCode === "US") {
                    return <td>{(Number.isNaN(Number(oyv[member])) || oyv[member] === null) ? "-" : US.format('$,.3s')(+oyv[member]).replace(/G/, "B")}</td>
                } else {
                    return <td>{(Number.isNaN(Number(oyv[member])) || oyv[member] === null) ? "-" : EU.format('$,.3s')(+oyv[member]).replace(/G/, "B")}</td>
                }
            }
        };

        return organisation.detailedFinancials
            .sort((a, b) => (a.financialYear > b.financialYear) ? 1 : ((b.financialYear > a.financialYear) ? -1 : 0))
            .slice(-10)
            .map((oyv: Record<string, any>) => (
                symbols[symbol](oyv)
            ))
    };

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        setFinancialView(parsed.view);
    }, [location]);

    useEffect(() => {


        const fetchCompany = async () => {
            await axios.get(organisationHostname + "organisation/" + organisationId + "/financial-summary")
                .then((response) => setOrgFinancialValues(response.data))
                .catch(error => console.log(error.message));
        };

        fetchCompany();
    }, []);

    const redirectToFinancialView = (view: string) => {
        var link: string = "/organisation/" + organisationId + "/financials";
        if (view !== "") {
            link += "?view=" + view;
        }
        history.push(link);
    };

    const showSummary = () => {
        let summary = organisation.financialSummary[0];
        return Object.values(summary).some(x => x !== null && x !== '');
    };

    const getTwoDecimalPoint = (numberToFormat: number) => {
        return (Math.round(numberToFormat * 100) / 100).toFixed(2);
    };

    const getCAGRLabel = () => {
        const years = organisation?.detailedFinancials?.length || 0;
        return years > 3 ? "organisation.financials.cagr" : "organisation.financials.turnoverchange";
    }

    return (
        <div>
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0, marginTop: 15 }}>
                <Row className="" style={{ marginLeft: 0, marginRight: 0 }}>
                    <div style={{ marginLeft: "auto", marginRight: "auto" }} className="iq-button-group">
                        {showSummary() && (
                            <button className={financialView === undefined ? "selected-inverted" : ""}
                                onClick={(e) => { redirectToFinancialView("") }}>
                                {translate("organisation.summary.title")}
                            </button>
                        )}
                        <button className={(financialView === "incomeStatement" || (!showSummary() && financialView === undefined)) ? "selected-inverted" : ""}
                            onClick={(e) => { redirectToFinancialView("incomeStatement") }}>
                            {translate("organisation.financials.incomestatement")}
                        </button>
                        <button className={financialView === "balanceSheet" ? "selected-inverted" : ""}
                            onClick={(e) => { redirectToFinancialView("balanceSheet") }}>
                            {translate("organisation.financials.balancesheet")}
                        </button>
                        <button className={financialView === "ratios" ? "selected-inverted" : ""}
                            onClick={(e) => { redirectToFinancialView("ratios") }}>
                            {translate("organisation.financials.ratios")}
                        </button>
                    </div>
                </Row>
                {financialView === undefined && showSummary() &&
                    <div>
                        <Row className="" style={{ marginLeft: 0, marginRight: 0, paddingTop: 25 }}>
                            <FinancialStat name="organisation.financials.turnover"
                                value={organisation.financialSummary[0].turnover?.toString()}
                                diff={organisation.financialSummary[0].turnoverChange?.toString()}
                                symbol="currency" />
                            <FinancialStat name={getCAGRLabel()}
                                value={organisation.financialSummary[0].threeYearCagr?.toString()}
                                diff=""
                                symbol="%" />
                            <FinancialStat name="organisation.financials.grossprofit"
                                value={organisation.financialSummary[0].grossProfit?.toString()}
                                diff={organisation.financialSummary[0].grossProfitChange?.toString()}
                                symbol="currency" />
                            <FinancialStat name="organisation.financials.operatingprofit"
                                value={organisation.financialSummary[0].operatingProfit?.toString()}
                                diff={organisation.financialSummary[0].operatingProfitChange?.toString()}
                                symbol="currency" />
                        </Row>
                        <Row className="" style={{ marginLeft: 0, marginRight: 0, marginTop: 25 }}>
                            <FinancialStat name="organisation.financials.cash"
                                value={organisation.financialSummary[0].cash?.toString()}
                                diff={organisation.financialSummary[0].cashChange?.toString()}
                                symbol="currency" />
                            <FinancialStat name="organisation.financials.networth"
                                value={organisation.financialSummary[0].netWorth?.toString()}
                                diff={organisation.financialSummary[0].netWorthChange?.toString()}
                                symbol="currency" />
                            <FinancialStat name="organisation.financials.assets.assets"
                                value={organisation.financialSummary[0].totalAssets?.toString()}
                                diff={organisation.financialSummary[0].totalAssetsChange?.toString()}
                                symbol="currency" />
                            <FinancialStat name="organisation.financials.liabilities.liabilities"
                                value={organisation.financialSummary[0].totalLiabilities?.toString()}
                                diff={organisation.financialSummary[0].totalLiabilitiesChange?.toString()}
                                symbol="currency" />
                        </Row>
                        <Row className="" style={{ marginLeft: 0, marginRight: 0, marginTop: 25 }}>
                            <FinancialStat name="organisation.financials.liquidity"
                                value={organisation.financialSummary[0].liquidityAcidTest?.toString()}
                                diff={organisation.financialSummary[0].liquidityAcidTestChange?.toString()}
                                symbol="" />
                            <FinancialStat name="organisation.financials.gearing"
                                value={organisation.financialSummary[0].gearing?.toString()}
                                diff={organisation.financialSummary[0].gearingChange?.toString()}
                                symbol="" />
                            <FinancialStat name="organisation.financials.roce.short"
                                value={getTwoDecimalPoint(organisation.financialSummary[0].returnOnCapitalEmployed)}
                                diff={organisation.financialSummary[0].returnOnCapitalEmployedChange?.toString()}
                                symbol="" />
                            <FinancialStat name="organisation.financials.pat"
                                value={organisation.financialSummary[0].profitAfterTaxPct?.toString()}
                                diff={organisation.financialSummary[0].profitAfterTaxChange?.toString()}
                                symbol="currency" />
                        </Row>
                    </div>
                }
                {(financialView === "incomeStatement" || (!showSummary() && financialView === undefined)) &&
                    <div>
                        <Row className="" style={{ marginLeft: 0, marginRight: 0, paddingTop: 15 }}>
                            <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10, width: "100%" }}>
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>{translate("organisation.financials.profitandloss")}</th>
                                            {organisation.detailedFinancials
                                                .sort((a, b) => (a.financialYear > b.financialYear) ? 1 : ((b.financialYear > a.financialYear) ? -1 : 0))
                                                .slice(-10)
                                                .map(oyv => (
                                                    <th>{oyv.financialYear}</th>
                                                ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getIncomeStatementValues().map(isv => (
                                            <tr key={isv.name}>
                                                {translate(isv.name)}
                                                {isv.values}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>

                        </Row>
                    </div>
                }
                {financialView === "balanceSheet" &&
                    <div>
                        <Row className="" style={{ marginLeft: 0, marginRight: 0, paddingTop: 15 }}>
                            <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10, width: "100%" }}>
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>{translate("organisation.financials.balancesheet")}</th>
                                            {organisation.detailedFinancials
                                                .sort((a, b) => (a.financialYear > b.financialYear) ? 1 : ((b.financialYear > a.financialYear) ? -1 : 0))
                                                .slice(-10)
                                                .map(oyv => (
                                                    <th>{oyv.financialYear}</th>
                                                ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getBalanceSheetValues().map(isv => (
                                            <tr key={isv.name}>
                                                {translate(isv.name)}
                                                {isv.values}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>

                        </Row>
                    </div>
                }
                {financialView === "ratios" &&
                    <div>
                        <Row className="" style={{ marginLeft: 0, marginRight: 0, paddingTop: 15 }}>
                            <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10, width: "100%" }}>
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>{translate("organisation.financials.ratios")}</th>
                                            {organisation.detailedFinancials
                                                .sort((a, b) => (a.financialYear > b.financialYear) ? 1 : ((b.financialYear > a.financialYear) ? -1 : 0))
                                                .slice(-10)
                                                .map(oyv => (
                                                    <th>{oyv.financialYear}</th>
                                                ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getRatiosValues().map(isv => (
                                            <tr key={isv.name}>
                                                {translate(isv.name)}
                                                {isv.values}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>

                        </Row>
                    </div>
                }
            </Container>
        </div >
    );
}

export default OrganisationFinancials;
