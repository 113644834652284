import React, {useEffect, useState} from "react";
import {Alert, Row} from "react-bootstrap";
import TeamDashboardTable, {ConsumptionData} from "./TeamDashboardTable";
import axios from "axios";
import {matchPath, useLocation} from "react-router-dom";
import {frontEndHostName} from "../../../utils/Configuration";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import translate from "../../../i18n/translate";
import ListEventsTable from "./ListEventsTable";

const TeamListActivity: React.FC = () => {
    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/team/:teamId',
        exact: false,
        strict: false
    });

    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [listCapacities, setListCapacities] = useState<ConsumptionData[]>([]);
    const [totalCompanies, setTotalCompanies] = useState<ConsumptionData[]>([]);
    const [followedCompanies, setFollowedCompanies] = useState<ConsumptionData[]>([]);

    useEffect(() => {
        const fetchListActivity = async () => {
            setLoading(true);

            await axios.get(frontEndHostName + "teams/" + childMatchedPath?.params.teamId + "/dashboard/lists")
                .then(r => {
                    let data = r.data;
                    setListCapacities(data.listCapacities);
                    setTotalCompanies(data.totalCompanies);
                    setFollowedCompanies(data.followedCompanies);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchListActivity();
    }, []);

    return (
        <div style={{paddingTop: 15}} className="mb-3">
            {error ? (
                <Alert variant="danger">
                    {translate("errors.loading")}
                </Alert>
            ) : (
                <Row>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-4">
                        <ListEventsTable teamId={childMatchedPath?.params.teamId}/>
                    </div>

                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 pt-4">
                        <div className="organisation-card">
                            <div className="organisation-card-label">
                                {translate("teams.lists.totallists")}
                            </div>
                            {loading ? <IqLoadingIcon /> : (
                                <div className="organisation-card-text">
                                    <TeamDashboardTable data={listCapacities} />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6  pt-4">
                        <div className="organisation-card">
                            <div className="organisation-card-label">
                                {translate("teams.lists.totalcompaniesinlists")}
                            </div>
                            {loading ? <IqLoadingIcon /> : (
                                <div className="organisation-card-text">
                                    <TeamDashboardTable data={totalCompanies} />
                                </div>
                            )}
                        </div>
                    </div>

                    {followedCompanies.length > 0 && (
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 pt-4">
                            <div className="organisation-card">
                                <div className="organisation-card-label">
                                    {translate("teams.lists.totalfollowedcompanies")}
                                </div>

                                {loading ? <IqLoadingIcon /> : (
                                    <div className="organisation-card-text">
                                        <TeamDashboardTable data={followedCompanies} />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Row>
            )}
        </div>
    );
};

export default TeamListActivity;
