import React, {useContext, useEffect, useState} from "react";
import Item from "./Item";
import ItemType from "./ItemType";
import DashboardItemContext from "../../../context/dashboard/DashboardItemContext";
import DashboardContext from "../../../context/dashboard/DashboardContext";
import {MARGINS, ROW_HEIGHT} from "../Dashboard";
import './DashboardItem.css'
import DashboardKeyContext from "../../../context/dashboard/DashboardKeyContext";

interface Props {
    element: Item,
    remove: (key: string) => void
}

const DashboardItem: React.FC<Props> = (props: Props) => {
    const [element, setElement] = useState<Item>(props.element);
    const { layout, setLayout } = useContext(DashboardContext);

    let itemType = ItemType.ALL_TYPES[element.k];

    const computeHeightWithMargin = (item: Item) => {
            
        // We multiply ROW_HEIGHT with grid units of the element and add the margins for each element-1
        // at the end we substract an arbitrary offset 25
        return ROW_HEIGHT * item.h + Math.max(0, item.h-1) * MARGINS[1] - 25;
    }

    const [maxHeight, setItemHeight] = useState<number>(computeHeightWithMargin(element));
    const setMaxHeight = (height: number) => {
        setItemHeight(height);
    };

    const [widgetId, setWidgetKey] = useState<string>(element.i);
    const setWidgetId = (id: string) => {
        setWidgetKey(id);
    };

    const [width, setItemWidth] = useState<number>(element.w);
    const setWidth = (width: number) => {
        setItemWidth(width);
    };

    useEffect(() => {
        let i = layout.find((item: Item) =>
            {
                let key = item.i.split(".");
                 return key[0] === itemType.key;

            }
        );
        if (i) {
            setMaxHeight(computeHeightWithMargin(i));
            setWidth(i.w);
        }
    }, [layout]);

    return (
        <div className="dashboard-item-container">
            <div className="dashboard-item-icon fa-lg">
                {itemType.icon}
            </div>
            <div>
                <button className="remove btn btn-link btn-sm nonIqbladeDraggableWidget" onClick={() => props.remove(element.i)}>
                    <span className="dashboard-item-remove"><i className="fa fa-times fa-sm" /></span>
                </button>
            </div>
            <DashboardItemContext.Provider value={{ maxHeight, setMaxHeight, width, setWidth}}>
                <DashboardKeyContext.Provider value={{widgetId, setWidgetId}}>
                <itemType.element  />
                </DashboardKeyContext.Provider>
            </DashboardItemContext.Provider>
        </div>
    );
};

export default DashboardItem;
