export default {
    jobs: {
        requestJobs: "Request Jobs"
    },
    featuredon: {
        title: "Basé sur",
        nolists: "Cette organisation n'apparaît dans aucune liste",
        remove: "Supprimer",
        deleteconfirm: "Êtes-vous sûr de vouloir supprimer cette organisation de la {list}?",
        error: "Impossible de supprimer de la liste"
    },
    addtolist: {
        title: "Ajouter à la liste",
        multipletitle: "Ajouter un batch à la liste",
        subtitle: "Ajouter une société à la liste",
        multiplesubtitle: "Ajouter des sociétés à la liste",
        chooselist: "Choisir ou créer une nouvelle liste",
        listplaceholder: "Choisissez ou tapez pour créer une nouvelle liste...",
        selectplaceholder: "Sélectionnez une liste...",
        listlabel: "{title} ({count} d'entreprises)",
        addsuccess: "Ajouté à la liste avec succès!",
        duplicatename: "Une liste portant ce nom existe déjà!",
        frozen: {
            campaign: "Vous ne pouvez pas ajouter d'entreprises car cette liste est utilisée dans une campagne."
        },
        maxcapacity: "Vous n'avez pas la capacité d'ajouter plus d'entreprises dans les listes.",
        maxlists: "Vous n'avez pas la capacité de créer plus de listes"
    },
    follow: "Suivre",
    followed: "Suivi",
    followcapacity: "Vous n'avez pas la possibilité de suivre davantage d'organisations.",
    updatecompanyinfo: {
        title: "Mise à jour des informations sur l'entreprise",
        text: "Une mise à jour des informations sur l'entreprise sera effectuée dans un délai d'un jour ouvrable.",
        popover: "Demande de mise à jour des informations sur l’entreprise"
    },
    temporarylink: {
        button: "Partager un lien temporaire",
        title: "Générer un lien temporaire",
        email: "Saisir l'adresse électronique",
        share: "Partager"
    },
    summary: {
        title: "Résumé",
        introduction: "Introduction",
        socials: "Médias sociaux",
        tags: "Tags",
        competencies: "Compétences",
        techpartners: "Partenariats technologiques",
        companydetails: "Détails sur l'entreprise",
        funding: {
            title: "Financement",
            date: "Date",
            round: "Tour de table",
            value: "Valeur",
            leadinvestor: "Investisseur principal",
            acquired: "Acquired"
        },
        companyinfo: {
            title: "Informations sur la société",
            website: "Site web",
            address: "Adresse des bureaux",
            phone: "Numéro de téléphone",
            financialyearend: "Fin de l'année fiscale",
            legalform: "Forme juridique",
            investment: "Investissement collecté",
            digitalmaturity: "Score de maturité digitale",
            fiscalyearend: "Fin d'année fiscale",
            ultimateparentcompany: "L'ultime compagnie familiale",
            immediateparentcompany: "Société mère immédiate",
            subsidiarycompany: "Filiale"
        },
        linkedinsearch: {
            poweredby: "Powered By",
            seniorcontacts: "Trouver des contacts seniors",
            sales: "Trouver des contacts dans le domaine des ventes et du marketing",
            infrastructure: "Trouver des contacts dans le domaine de l'infrastructure technologique",
            security: "Trouver des contacts dans le domaine des données d'application, du DevOps et de la sécurité"
        },
        classification: {
            sector: "Secteur",
            industrygroup: "Groupe d'industrie",
            industry: "Industrie",
            subindustry: "Sous-industrie",
            isic: "Codes CITI",
            sic: "Codes SIC",
            ussic: "Codes US SIC",
            wz: "Codes WZ",
            naf: "Codes NAF",
            expand: "Agrandir la vue",
            isichierarchy: "Hiérarchie CITI"
        },
        headlinenumbers: {
            title: "Chiffres clés",
            revenues: "Revenu",
            employees: "Employés dans cette organisation"
        },
        filinghistory: {
            name: "Historique des entrées",
            title: "Titre",
            date: "Date d'entrée"
        },
        map: {
            title: "Emplacements des Bureaux",
            locations: "Afficher tous les sites"
        }
    },
    financials: {
        title: "Finances",
        notavailable: "Non disponible",
        turnover: "Chiffre d'affaires",
        turnoverchange: "TCAC sur 1 an",
        investmentraised: "Investissements levés",
        cagr: "TCAC sur 3 ans",
        grossprofit: "Bénéfice brut",
        operatingprofit: "Bénéfice d'exploitation",
        weeks: "Semaines",
        currency: "Devise",
        consolidatedaccounts: "Comptes consolidés",
        costofsales: "Coût des ventes",
        wages: "Salaires et traitements",
        emoluments: "Salaires des directeurs",
        depreciation: "Amortissement",
        auditfees: "Frais d'audit",
        interestpayments: "Intérêts payés",
        pretax: "Bénéfice avant impôts",
        tax: "Impôt",
        pat: "Bénéfice après impôt",
        retainedprofit: "Bénéfice non distribué",
        export: "Exportations",
        cash: "Liquidités",
        networth: "Valeur nette",
        liquidity: "Ratio de trésorerie",
        gearing: "Endettement",
        roce: {
            short: "RCI",
            full: "Rendement du Capital Investi %"
        },
        incomestatement: "Compte de résultat",
        balancesheet: "Bilan",
        ratios: "Ratios",
        profitandloss: "Bénéfice et perte",
        debtors: {
            trade: "Débiteurs commerciaux",
            other: "Autres débiteurs"
        },
        longtermfinance: {
            total: "Total des financements à long terme",
            other: "Autres financements à long terme"
        },
        shorttermfinance: "Autres financements à court terme",
        assets: {
            assets: "Actifs",
            tangible: "Immobilisations corporelles",
            intangible: "Immobilisations incorporelles",
            current: "Total de l'actif circulant",
            misc: "Actifs courants divers",
            fixed: "Total de l'actif immobilisé"
        },
        liabilities: {
            liabilities: "Passif",
            current: "Total du passif à court terme",
            misc: "Passifs courants divers"
        },
        creditors: "Fournisseurs",
        pretaxmargin: "Marge bénéficiaire avant impôt",
        currentratio: "Ratio de liquidité générale",
        salesnetworkingcapital: "Fond de Roulement Net",
        equity: "Fonds propres (%)",
        creditordays: "Jours de crédit",
        rotae: "Rendement de l'actif total employé (%)",
        currentdebt: "Ratio d'endettement actuel",
        totaldebt: "Ratio d'endettement total",
        stockturnover: "Ratio de rotation des stocks (%)",
        ronae: "Rendement de l'actif net employé (%)"
    },
    people: {
        title: "Personnel",
        upgradeAccountMessage: "Bitte aktualisieren Sie Ihr Konto auf ein kostenpflichtiges, um Kontakte zu sehen."
    },
    techstack: {
        title: "Capital technologique",
        datefirstverified: "Date de première vérification",
        datelastverified: "Date de la dernière vérification",
        vendor: "Fournisseur",
        product: "Produit",
        category: "Catégorie",
        intensity: "Intensité"
    },
    news: {
        title: "News"
    },
    social: {
        title: "Social",
        twitter: {
            nouser: "Les données relatives aux utilisateurs de X ne sont pas disponibles pour cette organisation",
            followon: "Suivre sur X",
            top5mentions: "Top 5 des mentions",
            top5hashtags: "Top 5 des hashtags"
        },
        youtube: {
            nouser: "Les données des utilisateurs de YouTube ne sont pas disponibles pour cette organisation"
        }
    },
    shareholders: {
        title: "Actionnaires"
    },
    channelpartners: {
        title: "Partenaires de distribution"
    },
    edit: {
        title: "Editer l'organisation"
    },
    contracts: {
        title: "Contrats"
    },
    spend: {
        title: "Dépenses"
    },
    previousnames: {
        title: "Noms précédents",
        name: "Nom",
        effectivefrom: "En vigueur à partir de",
        ceased: "Interrompu"
    },
    casestudies: {
        title: "Case Studies"
    },
    whitepaper: {
        title: "Whitepaper"
    },
}