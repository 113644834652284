import React, {useEffect, useState} from "react";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {contractsHostName, searchHostname} from "../../../utils/Configuration";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {Alert, Col, Row, Spinner} from "react-bootstrap";
import translate from "../../../i18n/translate";
import DateFormatter from "../../../utils/formatters/DateFormatter";
import {
    faBuilding,
    faCalendar,
    faCalendarCheck,
    faCalendarTimes,
    faChevronLeft,
    faMoneyBill,
    faStar
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ContractWrapper from "../../../model/contracts/PSContracts/ContractWrapper";
import ContractParties from "../../../model/contracts/PSContracts/parties/ContractParties";

interface CompanyMatchResponse {
    id: string;
    companyName: string;
    imageUrl: string;
    companyStatus: string;
}

enum OrganisationRole {
    BUYER = "buyer",
    SUPPLIER = "supplier"
}

const PublicSectorContract: React.FC = () => {
    let history = useHistory();
    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/contract/:id',
        exact: false,
        strict: false
    });

    const [foundContract, setFoundContract] = useState<ContractWrapper>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    useEffect(() => {
        const fetchContractUrl = contractsHostName + "elastic/" + childMatchedPath?.params.id;
        const fetchContract = async () => {
            axios.get(fetchContractUrl)
                .then((res) => {
                    setFoundContract(res.data);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchContract();
    }, []);

    const [following, setFollowing] = useState<boolean>(false);
    const [loadingFollowing, setLoadingFollowing] = useState<boolean>(false);
    useEffect(() => {
        const fetchFollowing = async () => {
            setLoadingFollowing(true);
            await axios.get(contractsHostName + "mysql/follow/contracts/" + childMatchedPath?.params.id + "/following")
                .then(r => setFollowing(r.data))
                .finally(() => setLoadingFollowing(false));
        };

        fetchFollowing();
    }, [following]);

    const [hasFollowCapacity, setFollowCapacity] = useState<boolean>(false);
    useEffect(() => {
        if (!following) {
            axios.get(contractsHostName + "mysql/follow/contracts/has-follow-capacity")
                .then(r => setFollowCapacity(r.data));
        }
    }, [following]);

    const baseUrl = contractsHostName + "mysql/follow/contracts/" + childMatchedPath?.params.id + "/follow";
    const followContract = async () => {
        setLoadingFollowing(true);
        await axios.put(baseUrl)
            .then(r => setFollowing(r.data))
            .finally(() => setLoadingFollowing(false));
    };

    const unfollowContract = async () => {
        setLoadingFollowing(true);
        await axios.delete(baseUrl)
            .then(() => setFollowing(false))
            .finally(() => setLoadingFollowing(false));
    };

    const formatValue = (value: number): string => {
        let formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0
        });

        return formatter.format(value);
    };

    let details = [
        {
            title: "contracts.awarded",
            value: foundContract?.content?.tender?.datePublished ? DateFormatter.formatDate(foundContract.content.tender.datePublished) : undefined,
            icon: faCalendar
        },
        {
            title: "contracts.startdate",
            value: foundContract?.content?.tender?.contractPeriod?.startDate ? DateFormatter.formatDate(foundContract.content.tender.contractPeriod.startDate) : undefined,
            icon: faCalendarCheck
        },
        {
            title: "contracts.enddate",
            value: foundContract?.content?.tender?.contractPeriod?.endDate ? DateFormatter.formatDate(foundContract.content.tender.contractPeriod.endDate) : undefined,
            icon: faCalendarTimes
        },
        {
            title: "contracts.estimatedvalue",
            value: (foundContract?.content?.tender?.value?.amount) ?
                (formatValue( foundContract.content.tender.value.amount)) : (foundContract?.content?.awards) ? (
                    (foundContract?.content?.awards[0]?.value?.amount) ? (formatValue(foundContract.content.awards[0].value.amount)) : undefined
                ) : undefined,
            icon: faMoneyBill
        },
        {
            title: "contracts.organisation",
            value: foundContract?.content.buyer.name,
            icon: faBuilding
        }
    ];

    const getOrganisations = (role: string) => {
        return foundContract?.content?.parties.filter(p => p.roles.some(r => r === role)) || []
    }

    const getDocuments = () => {
        if (!foundContract || !foundContract.content) {
            return [];
        }

        let tender = foundContract.content.tender;
        if (tender.documents) {
            return tender.documents;
        }

        let award = foundContract.content.awards[0];
        if (award) {
            return award.documents
        }

        return [];
    }

    const [buyerOrg, setBuyerOrg] = useState<CompanyMatchResponse>();
    useEffect(() => {
        const fetchBuyerOrgByName = async () => {
            const legalName = getOrganisations(OrganisationRole.BUYER)[0].identifier.legalName.toUpperCase();
            await axios.post(searchHostname + "organisation/matchexact", {
                    id: "",
                    name: legalName,
                    url: "",
                    address: {
                        postCode: ""
                    },
                    country: "uk"
                })
                .then(r => setBuyerOrg(r.data[0]))
                .catch(error => {
                    console.error(error.message);
                });
        };

        if (foundContract && !foundContract.content.buyer.iqbladeId) {
            fetchBuyerOrgByName();
        }
    }, [foundContract]);

    useEffect(() => {
        const updateBuyer = async () => {
            const requestBody = {
                id: foundContract?.content.id,
                organisationId: buyerOrg!.id
            }

            await axios.post(contractsHostName + "elastic/update-buyer", requestBody);
        }

        if (buyerOrg) {
            updateBuyer()
        }
    }, [buyerOrg]);

    const formatCoHNumberToOrgId = (supplierCoHCode: string) => {
        if (/(GB-COH[^UK])/g.test(supplierCoHCode)) {
            supplierCoHCode = supplierCoHCode.replace(/(GB-COH[^UK])/g, "UK");

            if (supplierCoHCode.length < 10) {
                let leadingZeroAccumulator: string = "";
                for(let zeroesNeeded: number = 10 ; zeroesNeeded > supplierCoHCode.length; zeroesNeeded--) {
                    leadingZeroAccumulator = leadingZeroAccumulator + "0";
                }
                supplierCoHCode = supplierCoHCode.replace("UK", "UK" + leadingZeroAccumulator);
            }
        }
        return supplierCoHCode;
    }

    // Show loading screen while waiting for results
    if (loading) {
        return (
            <div>
                <Row className="mb-3">
                    <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                        <div className="iq-headline">
                        <span className="text">
                            {translate("contracts.title")}
                        </span>
                        </div>
                    </Col>
                </Row>
                <div className="organisation-card">
                    <IqLoadingIcon />
                </div>
            </div>
        )
    }

    const getBuyerLink = (buyer: ContractParties) => {
        if (foundContract?.content.buyer.iqbladeId) {
            return (
                <span>
                    <b>Legal Name:  </b>
                    <a href={location.hash + "/organisation/" + foundContract?.content.buyer.iqbladeId}>
                        {buyer?.identifier?.legalName.toUpperCase()}
                    </a>
                </span>
            );
        }

        if (buyerOrg) {
            return (
                <span>
                    <b>Legal Name:  </b>
                    <a href={location.hash + "/organisation/" + buyerOrg.id}>
                        {buyer?.identifier?.legalName.toUpperCase()}
                    </a>
                </span>
            )
        }

        return (
            <span>
                <b>Legal Name:  </b>
                {buyer?.identifier?.legalName.toUpperCase()}
            </span>
        );
    }

    return (
        <div>
            <Row className="mb-3">
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                    <div className="iq-headline">
                        <span className="text">
                            {translate("contracts.title")}
                        </span>
                    </div>
                </Col>
            </Row>

            <div className="organisation-card">
                <Row>
                    <Col>
                        <p>CONTRACT RELEASE ID: {foundContract?.releaseId}</p>
                        <button className="iqx-button iconic md-size pe-2 pe-3"
                                onClick={() => history.goBack()}>
                            <span className="pe-2"><FontAwesomeIcon icon={faChevronLeft}/></span>
                            <span>Back</span>
                        </button>
                    </Col>
                    <Col>
                        {!following ? (
                            <button type="button"
                                    className="iq-action-button pull-right"
                                    onClick={() => followContract()}
                                    disabled={!hasFollowCapacity}>
                                <span className="text ms-2">{translate("contracts.follow")}</span>
                                {loadingFollowing ? (
                                    <Spinner className="icon" style={{ display: "block", height: "1rem", width: "1rem" }}
                                             animation="border"
                                             variant="primary" />
                                ) : (
                                    <i className="fa fa-star-o fa-1x icon" style={{ color: "#2F6FC3" }} />
                                )}
                            </button>
                        ) : (
                            <button type="button"
                                    className="iq-action-button pull-right"
                                    onClick={() => unfollowContract()}>
                                <span className="text ms-2">{translate("contracts.followed")}</span>
                                {loadingFollowing ? (
                                    <Spinner className="icon" style={{ display: "block", height: "1rem", width: "1rem" }}
                                             animation="border"
                                             variant="primary" />
                                ) : (
                                    <FontAwesomeIcon className="icon" icon={faStar} color="#2F6FC3" size="1x" />
                                )}
                            </button>
                        )}
                    </Col>
                </Row>

                {/* Top Row Details */}
                <Row className="mt-2">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="contract-title-label text-center">
                            {foundContract?.content.tender.title}
                        </div>
                    </Col>
                </Row>

                <Row className="mt-4">
                    {details.map(detail => (
                        <Col>
                            <div>
                                <span>
                                    <span className="pe-2">
                                        <FontAwesomeIcon icon={detail.icon}/>
                                    </span>
                                    <span className="title">
                                        {translate(detail.title)}:
                                    </span>
                                </span>
                            </div>
                            <div>
                                <span className="text-muted" style={{fontSize: 18}}>{detail.value}</span>
                            </div>
                        </Col>
                    ))}
                </Row>


                {/* Contract Status */}
                <div className="public-sector-contract-row mt-4">
                    <div className="public-sector-contract-column">
                        <span className="font-weight-bold">
                            Tender Status:  {foundContract?.content?.tender?.status.toUpperCase()}
                            <p>
                                {foundContract?.content?.tender?.status.toUpperCase() === "PLANNING" ? <span style={{color: "red"}}>Tender Planning </span> : <span>Tender Planning </span>} -{"> "}
                                {foundContract?.content?.tender?.status.toUpperCase() === "PLANNED" ? <span style={{color: "#FFBF00"}}>Tender Planned </span> : <span>Tender Planned </span>} -{"> "}
                                {foundContract?.content?.tender?.status.toUpperCase() === "ACTIVE" ? <span style={{color: "green"}}>Tender Active </span> : <span>Tender Active </span>} -{"> "}
                                {foundContract?.content?.tender?.status.toUpperCase() === "COMPLETE" ? <span style={{color: "green"}}>Tender Complete </span> : <span>Tender Complete </span>} -{"> "}
                                {foundContract?.content?.tender?.status.toUpperCase() === "CANCELLED" ? <span style={{color: "red"}}>Tender Cancelled </span> : <span>Tender Cancelled </span>}
                            </p>
                        </span>
                        <span className="font-weight-bold">
                            {foundContract?.content?.awards ? (
                                <p> Award Status:
                                    {foundContract?.content?.awards[0]?.status.toUpperCase() === "TENDER" ? <span style={{color: "#FFBF00"}}> Tender </span> : null}
                                    {foundContract?.content?.awards[0]?.status.toUpperCase() === "OPEN" ? <span style={{color: "#FFBF00"}}> Contract Award </span> : null}
                                    {foundContract?.content?.awards[0]?.status.toUpperCase() === "ACTIVE" ? <span style={{color: "green"}}> Active Contract </span> : null}
                                    {foundContract?.content?.awards[0]?.status.toUpperCase() === "EXPIRED" ? <span style={{color: "green"}}> Expired contract </span> : null}
                                </p>
                            ) : <p>Award Status: <span style={{color: "red"}}> Not Awarded </span></p>}
                        </span>
                    </div>
                </div>
            </div>

            <div className="organisation-card mt-3">
                <div className="organisation-card-label">{translate("contracts.description")}</div>
                {foundContract?.content.tender.description ?
                    <p style={{fontSize: 18}}>{foundContract.content.tender.description.split(". ").map(paragraph => <p> {paragraph} </p>)}</p>
                    :
                    <p style={{fontSize: 18}}>{foundContract?.content.tender.description}</p>
                }
            </div>

            <div className="organisation-card mt-3">
                <div className="organisation-card-label">
                    Contract Type
                </div>
                <ul className="unbulleted-list">
                    <li>
                        <span><b>Code Schema: </b>{foundContract?.content.tender.classification.scheme}</span>
                    </li>
                    <li>
                        <span><b>Code: </b>{foundContract?.content.tender.classification.id}</span>
                    </li>
                    <li>
                        <span><b>Description: </b>{foundContract?.content.tender.classification.description}</span>
                    </li>
                </ul>
            </div>

            <div className="organisation-card mt-3">
                <div className="organisation-card-label">
                    Contract Procurement Process
                </div>
                <ul className="unbulleted-list">
                    <li>
                        <span><b>Procurement Method: </b>{foundContract?.content?.tender?.procurementMethod}</span>
                    </li>
                    <li>
                        <span><b>Procurement Category: </b>{foundContract?.content?.tender?.mainProcurementCategory}</span>
                    </li>
                    <li>
                        <span><b>Procurement Method Procedure Type: </b>{foundContract?.content?.tender?.procurementMethodDetails}</span>
                    </li>
                </ul>
            </div>

            <div className="organisation-card mt-3">
                <div className="organisation-card-label">
                    Contract Suitability
                </div>
                <ul className="unbulleted-list">
                    <li>
                    <span><b>Small & Medium Enterprises: </b>{foundContract?.content?.tender?.suitability?.sme ? (
                        <b><span style={{color: "green"}}>SUITABLE</span></b>
                    ) : (
                        <b><span style={{color: "darkred"}}>NOT SUITABLE</span></b>
                    )}</span>
                    </li>
                    <li>
                    <span><b>Voluntary, Community & Social Enterprises: </b>{foundContract?.content?.tender?.suitability?.vcse ? (
                        <b><span style={{color: "green"}}>SUITABLE</span></b>
                    ) : (
                        <b><span style={{color: "darkred"}}>NOT SUITABLE</span></b>
                    )}</span>
                    </li>
                </ul>
            </div>

            <div className="organisation-card mt-3">
                <div className="organisation-card-label">
                    {translate("contracts.awardedbuyer")}
                </div>
                {getOrganisations(OrganisationRole.BUYER)?.map((buyer) => (
                    <ul className="unbulleted-list">
                        {buyer.name ? (
                            <li>
                                <span><b>Name:  </b>{buyer.name}</span>
                            </li>
                        ) : null}
                        {buyer?.identifier?.legalName ? (
                            <li>
                                {getBuyerLink(buyer)}
                            </li>
                        ) : null}
                        {buyer.id ? (
                            <li>
                                <span><b>ID:   </b>{buyer.id}</span>
                            </li>
                        ) : null}

                        {/* Contact Address */}
                        {buyer?.address ? (
                            <li>
                                <span><b>Address:  </b></span>
                            </li>
                        ) : null}
                        {buyer?.address ? (
                            <ul className="unbulleted-list">
                                {buyer?.address?.streetAddress ? (
                                    <li>
                                        <span><b>Street:  </b>{buyer?.address?.streetAddress}</span>
                                    </li>
                                ) : null}
                                {buyer?.address?.locality ? (
                                    <li>
                                        <span><b>Area:  </b>{buyer?.address?.locality}</span>
                                    </li>
                                ) : null}
                                {buyer?.address?.postalCode ? (
                                    <li>
                                        <span><b>Post Code:  </b>{buyer?.address?.postalCode}</span>
                                    </li>
                                ) : null}
                                {buyer?.address?.countryName ? (
                                    <li>
                                        <span><b>Country:  </b>{buyer?.address?.countryName}</span>
                                    </li>
                                ) : null}
                            </ul>
                        ) : null}

                        {/* Contact Details */}
                        {buyer?.contactPoint ? (
                            <li>
                                <span><b>Contact Details:  </b></span>
                            </li>
                        ) : null}
                        {buyer?.contactPoint ? (
                            <ul className="unbulleted-list">
                                {buyer?.contactPoint?.name ? (
                                    <li>
                                        <span><b>Contact Name:  </b>{buyer?.contactPoint?.name}</span>
                                    </li>
                                ) : null}
                                {buyer?.contactPoint?.email ? (
                                    <li>
                                        <span><b>Contact Email:  </b><a href={"mailto:" + buyer?.contactPoint?.email}>{buyer?.contactPoint?.email}</a></span>
                                    </li>
                                ) : null}
                                {buyer?.contactPoint?.telephone ? (
                                    <li>
                                        <span><b>Contact Telephone:  </b><a href={"tel:" + buyer?.contactPoint?.telephone}>{buyer?.contactPoint?.telephone}</a></span>
                                    </li>
                                ) : null}
                                {buyer?.details?.url ? (
                                    <li>
                                        <span><b>Contact URL:  </b><a href={buyer?.details?.url}>{buyer?.details?.url}</a></span>
                                    </li>
                                ) : null}
                            </ul>
                        ) : null}

                        {/* Contact Misc */}
                        {buyer?.details?.scale ? (
                            <li>
                                <span><b>Organisation Scale:  </b>{buyer?.details?.scale}</span>
                            </li>
                        ) : null}
                        {buyer?.details?.vcse ? (
                            <li>
                            <span><b>Voluntary, Community & Social Enterprise:  </b>{buyer?.details?.vcse ? (
                                <b><span style={{color: "green"}}>YES</span></b>
                            ) : (
                                <b><span style={{color: "darkred"}}>NO</span></b>
                            )}</span>
                            </li>
                        ) : null}
                        {buyer?.identifier?.id ? (
                            <li>
                                <span><b>Identification:  </b>{buyer?.identifier?.id}</span>
                            </li>
                        ) : null}
                        {buyer?.identifier?.scheme ? (
                            <li>
                                <span><b>Scheme:  </b>{buyer?.identifier?.scheme}</span>
                            </li>
                        ) : null}
                    </ul>
                ))}
            </div>

            <div className="organisation-card mt-3">
                <div className="organisation-card-label">
                    {translate("contracts.awardedsupplier")}
                </div>
                {getOrganisations(OrganisationRole.SUPPLIER)?.map((supplier) => (
                    <ul className="unbulleted-list">
                        {supplier.name ? (
                            <li>
                                {/(GB-COH[^UK])/g.test(supplier.id) ?
                                    <span><b>Name: </b><a href={location.hash + "/organisation/" + formatCoHNumberToOrgId(supplier.id)}>
                                        {supplier.name}</a></span>
                                    :
                                    <span><b>Name: </b>{supplier.name}</span>}
                            </li>
                        ) : null}
                        {supplier?.identifier?.legalName ? (
                            <li>
                                <span><b>Legal Name: </b>{supplier?.identifier?.legalName.toUpperCase()}</span>
                            </li>
                        ) : null}
                        {supplier.id ? (
                            <li>
                                <span><b>ID:  </b>{supplier.id}</span>
                            </li>
                        ) : null}

                        {/* Contact Address */}
                        {supplier?.address ? (
                            <li>
                                <span><b>Address: </b></span>
                            </li>
                        ) : null}
                        {supplier?.address ? (
                            <ul className="unbulleted-list">
                                {supplier?.address?.streetAddress ? (
                                    <li>
                                        <span><b>Street: </b>{supplier?.address?.streetAddress}</span>
                                    </li>
                                ) : null}
                                {supplier?.address?.locality ? (
                                    <li>
                                        <span><b>Area: </b>{supplier?.address?.locality}</span>
                                    </li>
                                ) : null}
                                {supplier?.address?.postalCode ? (
                                    <li>
                                        <span><b>Post Code: </b>{supplier?.address?.postalCode}</span>
                                    </li>
                                ) : null}
                                {supplier?.address?.countryName ? (
                                    <li>
                                        <span><b>Country: </b>{supplier?.address?.countryName}</span>
                                    </li>
                                ) : null}
                            </ul>
                        ) : null}

                        {/* Contact Details */}
                        {supplier?.contactPoint ? (
                            <li>
                                <span><b>Contact Details: </b></span>
                            </li>
                        ) : null}
                        {supplier?.contactPoint ? (
                            <ul className="unbulleted-list">
                                {supplier?.contactPoint?.name ? (
                                    <li>
                                        <span><b>Contact Name: </b>{supplier?.contactPoint?.name}</span>
                                    </li>
                                ) : null}
                                {supplier?.contactPoint?.email ? (
                                    <li>
                                        <span><b>Contact Email: </b><a href={"mailto:" + supplier?.contactPoint?.email}>{supplier?.contactPoint?.email}</a></span>
                                    </li>
                                ) : null}
                                {supplier?.contactPoint?.telephone ? (
                                    <li>
                                        <span><b>Contact Telephone: </b><a href={"tel:" + supplier?.contactPoint?.telephone}>{supplier?.contactPoint?.telephone}</a></span>
                                    </li>
                                ) : null}
                                {supplier?.details?.url ? (
                                    <li>
                                        <span><b>Contact URL: </b><a href={supplier?.details?.url}>{supplier?.details?.url}</a></span>
                                    </li>
                                ) : null}
                            </ul>
                        ) : null}

                        {/* Contact Misc */}
                        {supplier?.details?.scale ? (
                            <li>
                                <span><b>Organisation Scale: </b>{supplier?.details?.scale}</span>
                            </li>
                        ) : null}
                        {supplier?.details?.vcse ? (
                            <li>
                             <span><b>Voluntary, Community & Social Enterprise:  </b>{supplier?.details?.vcse ? (
                                 <b> <span style={{color: "green"}}>YES</span> </b>
                             ) : (
                                 <b> <span style={{color: "darkred"}}>NO</span> </b>
                             )}</span>
                            </li>
                        ) : null}
                        {supplier?.identifier?.id ? (
                            <li>
                                <span><b>Identification: </b>{supplier?.identifier?.id}</span>
                            </li>
                        ) : null}
                        {supplier?.identifier?.scheme ? (
                            <li>
                                <span><b>Scheme: </b>{supplier?.identifier?.scheme}</span>
                            </li>
                        ) : null}
                    </ul>
                ))}
            </div>

            {getDocuments().length > 0 ? (
                <div className="organisation-card mt-3">
                    <div className="organisation-card-label">
                        Documents
                    </div>
                    {getDocuments().map((doc) => (
                            <ul className="unbulleted-list">
                                {doc.id ? (
                                    <li>
                                        <span><b>ID: </b>{doc.id}</span>
                                    </li>
                                ) : null}
                                {doc.language ? (
                                    <li>
                                        <span><b>Language:  </b>{doc.language}</span>
                                    </li>
                                ) : null}
                                {doc.description ? (
                                    <li>
                                        <span><b>Description:  </b>{doc.description}</span>
                                    </li>
                                ) : null}
                                {doc?.url ? (
                                    <li>
                                        <span><b>URL: </b><a href={doc.url}>{doc.url}</a></span>
                                    </li>
                                ) : null}
                                {doc.format ? (
                                    <li>
                                        <span><b>Format:  </b>{doc.format}</span>
                                    </li>
                                ) : null}
                                {doc.datePublished ? (
                                    <li>
                                        <span><b>Date Published:  </b>{DateFormatter.formatDate(doc.datePublished)}</span>
                                    </li>
                                ) : null}
                                {doc.dateModified ? (
                                    <li>
                                        <span><b>Date Modified:  </b>{DateFormatter.formatDate(doc.dateModified)}</span>
                                    </li>
                                ) : null}
                            </ul>
                        )
                    )}
                </div>
            ) : null}

            {error && (
                <div className="justify-content-md-center">
                    <Alert variant="danger">
                        {translate("errors.loading")}
                    </Alert>
                </div>
            )}

        </div>
    );
};

export default PublicSectorContract;