import React, {useEffect, useState} from "react";
import {Container, Row} from "react-bootstrap";
import queryString from "query-string";
import {useHistory, useLocation} from "react-router-dom";
import HubSpotContacts from "./HubSpotContacts";
import HubSpotDeals from "./HubSpotDeals";
import "./HubSpotDashboard.css";
import HubSpotCompanies from "./HubSpotCompanies";

enum TabKeys {
    CONTACTS = "contacts",
    COMPANIES = "companies",
    DEALS = "deals"
}

const HubSpotDashboard: React.FC = () => {

    let location = useLocation();
    const history = useHistory();
    const parsed = queryString.parse(location.search);

    const [view, setView] = useState(parsed.view);
    useEffect(() => {
        const parsed = queryString.parse(location.search);
        setView(parsed.view);
    }, [location]);

    const updateView = (view: string) => {
        let link: string = "/hubspot";
        if (view !== "") {
            link += "?view=" + view;
        }
        history.push(link);
    };

    return (
        <Container fluid style={{marginTop: 15}}>
            <div className="iq-headline">
                <span className="text">
                    HubSpot
                </span>
            </div>

            <Row className="" style={{ marginLeft: 0, marginRight: 0, marginTop: 15 }}>
                <div style={{ marginLeft: "auto", marginRight: "auto" }} className="iq-button-group">
                    <button className={"ps-3 " + (!view || view === TabKeys.CONTACTS ? "selected-inverted" : "")}
                            onClick={(e) => { updateView(TabKeys.CONTACTS) }}>
                        Contacts
                    </button>
                    <button className={"pe-3 " + (view === TabKeys.COMPANIES ? "selected-inverted" : "")}
                            onClick={(e) => { updateView(TabKeys.COMPANIES) }}>
                        Companies
                    </button>
                    <button className={"pe-3 " + (view === TabKeys.DEALS ? "selected-inverted" : "")}
                            onClick={(e) => { updateView(TabKeys.DEALS) }}>
                        Deals
                    </button>
                </div>
            </Row>

            {(!view || view === TabKeys.CONTACTS) && (
                <HubSpotContacts />
            )}

            {view === TabKeys.COMPANIES && (
                <HubSpotCompanies />
            )}

            {view === TabKeys.DEALS && (
                <HubSpotDeals />
            )}
        </Container>
    );
};

export default HubSpotDashboard;