import axios from "axios";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Alert, Col, Row} from "react-bootstrap";
import Select from "react-select";
import DashboardItemContext from "../../../context/dashboard/DashboardItemContext";
import translate from "../../../i18n/translate";
import List from "../../../model/list/List";
import YoutubeVideo from "../../../model/organisation/social/YoutubeVideo";
import {frontEndHostName, socialHostName} from "../../../utils/Configuration";
import IqLoadingIcon from "../../common/IqLoadingIcon";


interface SelectOption {
    value: string,
    label: string
}

const DashboardYoutube: React.FC = () => {

    const size: number = 5;
    const { maxHeight, setMaxHeight } = useContext(DashboardItemContext);
    const getHeight = () => {
        return maxHeight - 120;
    };

    const [videos, setVideos] = useState<YoutubeVideo[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingMore, setLoadingMore] = useState<boolean>(false);
    const [loadingLists, setLoadingLists] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const sourceInitialOptions: SelectOption[] = [
        {
            value: "followed",
            label: "Followed"
        }
    ]
    const [sourceOptions, setSourceOptions] = useState<SelectOption[]>(sourceInitialOptions);
    const [selectedSource, setSelectedSource] = useState<SelectOption>(sourceInitialOptions[0]);
    const [lists, setLists] = useState<List[]>([]);

    const prevSelection = useRef({ selectedSource }).current;

    const scroller = useRef<any>();

    const updatePage = () => {
        setPage(prev => prev + 1);
        setLoadingMore(false);
    };

    const handleScroll = () => {
        if (!loading && !error) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            let currentScroll = scroller.current.scrollTop;

            if (currentScroll === maxScroll) {
                setLoadingMore(true);
                debounce(updatePage, 3000)();
            }
        }
    };

    const debounce = <F extends (...params: any[]) => void>(fn: F, delay: number) => {
        let timeoutId: any;

        const debounced = (...args: any) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => fn(...args), delay);
        };

        return debounced as (...args: Parameters<F>) => ReturnType<F>;
    };

    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        const fetchTweets = async () => {
            if (selectedSource != prevSelection?.selectedSource || page === 0) {
                setLoading(true);
                await axios.get(socialHostName + "youtube/latest-videos?page=0" +
                    "&size=" + size + "&source=" + selectedSource.value)
                    .then(r => {
                        setVideos(
                            r.data.content
                        );
                        setPage(0)
                    })
                    .catch(error => setError(true))
                    .finally(() => setLoading(false));
            } else {
                setLoadingMore(true);
                await axios.get(socialHostName + "youtube/latest-videos?page=" + page +
                    "&size=" + size + "&source=" + selectedSource.value)
                    .then(r => {
                        setVideos((prevVideos: YoutubeVideo[]) => {
                            return [...prevVideos, ...r.data.content];
                        })
                    }
                    )
                    .catch(error => setError(true))
                    .finally(() => setLoadingMore(false));
            }
        };

        fetchTweets();
        return () => {
            prevSelection.selectedSource = selectedSource;
        };
    }, [page, selectedSource]);

    useEffect(() => {
        const fetchLists = async () => {
            await axios.get(frontEndHostName + "user-lists?number=0")
                .then(r => {
                    setLists(r.data as List[]);
                })
                .catch(error => setError(true))
                .finally(() => { });
        };

        fetchLists();
    }, []);

    useEffect(() => {
        let listOptions: SelectOption[] = lists.map(l =>
        ({
            value: String(l.id),
            label: l.title
        })
        )
        setSourceOptions([...sourceOptions, ...listOptions])
    }, [lists]);

    return (
        <div>
            <Row className="pb-2">
                <Col>
                    <div className="organisation-card-label">
                        {translate('dashboard.videos.title')}
                    </div>
                </Col>
            </Row>
            <Row className="nonIqbladeDraggableWidget">
                <Col className="pt-2" xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Select
                        options={sourceOptions}
                        value={selectedSource}
                        onChange={(e: any) => setSelectedSource(e)}
                        placeholder="Select source..."
                        className="basic-multi-select"
                        classNamePrefix="select" />
                </Col>
            </Row>
            <div style={{ marginTop: 15, maxHeight: getHeight(), overflowY: "scroll", overflowX: "hidden" }} ref={scroller} onScroll={handleScroll}>
                {error ? (
                    <Alert variant="danger">
                        {translate("errors.loading")}
                    </Alert>
                ) : (
                    !loading ?
                        <div>
                            {videos.length === 0 && <Alert variant="primary">
                                We couldn't find any recent videos based on your selection criteria.
                            </Alert>}
                            {videos.map((video: YoutubeVideo) => (
                                <div className="embed-responsive-16by9 nonIqbladeDraggableWidget">
                                    <iframe style={{width: "100%", height: 400}} className="embed-responsive-item" src={`https://www.youtube.com/embed/${video.id}`} />
                                </div>
                            ))}
                            <div>{loadingMore && <IqLoadingIcon />}</div>
                        </div>
                        : <IqLoadingIcon />
                )}
            </div>
        </div>
    );
};

export default DashboardYoutube;
