import React, {useEffect, useState} from 'react';
import {faChartBar} from "@fortawesome/free-solid-svg-icons";
import IqLoadingIcon from "../common/IqLoadingIcon";
import translate from "../../i18n/translate";
import IqProgressBar from "../common/IqProgressBar";
import Card from "./ProfileCard";
import {ConsumptionData} from "../teams/dashboard/TeamDashboardTable";
import axios from "axios";
import {frontEndHostName, searchHostname} from "../../utils/Configuration";


const CurrentAllowance = () => {

    interface ListConsumptionData {
        listsConsumed: ConsumptionData,
        totalCompanies: ConsumptionData
    }


    const [loading, setLoading] = useState<boolean>(false);
    const [listConsumption, setListConsumption] = useState<ConsumptionData>();
    const [totalCompaniesInLists, setTotalCompaniesInLists] = useState<ConsumptionData>();
    const [searchCreditUsage, setSearchCreditUsage] = useState<ConsumptionData>();

    useEffect(() => {
        setLoading(true)
        const fetchSearchCredits = () => {
            axios.get(searchHostname + "search-credits")
                .then(r => setSearchCreditUsage(r.data))
                .catch(error => console.error(error.message));
        };

        const fetchListStats = () => {
            axios.get(frontEndHostName + "list-consumption")
                .then(r => {
                    let listData: ListConsumptionData = r.data;
                    setListConsumption(listData.listsConsumed);
                    setTotalCompaniesInLists(listData.totalCompanies)
                    setLoading(false)
                })
                .catch(error => console.error(error.message));
        };

        fetchSearchCredits();
        fetchListStats();

    }, []);

    const getPercentage = (consumed: number, capacity: number) => {
        let percentage = (consumed / capacity) * 100;
        return Math.round(percentage * 10) / 10;
    };

    const getDaysUntilRefresh = () => {
        let today = new Date();

        let nextMonth = new Date(today.getTime());
        nextMonth.setMonth(today.getMonth() + 1);
        nextMonth.setDate(0);

        let days = nextMonth.getDate() > today.getDate() ? nextMonth.getDate() - today.getDate() : 0;
        return days;
    };

    return (
        <Card heading={"Current Allowance"} iconDefinition={faChartBar}>
            {loading ? <IqLoadingIcon/> : (
                <div>
                    {listConsumption && (
                        <div className="mb-2">
                            {translate("dashboard.profile.listscreated", {count: listConsumption.consumed})}
                            <span className="pull-right strong">{listConsumption.capacity}</span>
                            <IqProgressBar now={getPercentage(listConsumption.consumed, listConsumption.capacity)}/>
                        </div>
                    )}
                    {totalCompaniesInLists && (
                        <div className="mb-2">
                            {translate("dashboard.profile.listcompanies", {count: totalCompaniesInLists.consumed})}
                            <span className="pull-right strong">{totalCompaniesInLists.capacity}</span>
                            <IqProgressBar now={getPercentage(totalCompaniesInLists.consumed, totalCompaniesInLists.capacity)}/>
                        </div>
                    )}
                    {searchCreditUsage && (
                        <div className="mb-2">
                            {translate("dashboard.profile.searchcredits", {count: searchCreditUsage.consumed})}
                            <span className="pull-right strong">{searchCreditUsage.capacity}</span>
                            <IqProgressBar now={getPercentage(searchCreditUsage.consumed, searchCreditUsage.capacity)}/>
                            <span className="profile-credit-reset pull-right mt-1">
                                            {translate("dashboard.profile.creditrefresh", {days: getDaysUntilRefresh()})}
                                        </span>
                        </div>
                    )}
                </div>
            )}
        </Card>
    );
}

export default CurrentAllowance;