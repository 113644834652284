import React, {useContext, useEffect, useState} from "react";
import SubmenuContext from "../../context/SubmenuContext";
import {Link, matchPath, useLocation} from "react-router-dom";
import {Col, Image, Nav, NavItem, Row, Spinner} from "react-bootstrap";
import OfficerContext from "../../context/officer/OfficerContext";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getOfficerName} from "../../model/organisation/officer/OrganisationOfficer";
import {frontEndHostName} from "../../utils/Configuration";
import axios from "axios";
import {showGenericErrorDialog, showGenericSuccessDialog} from "../common/CommonAlerts";

const OfficerHeader: React.FC = () => {
    const location = useLocation();
    const {officer, setOfficer, appointments, setAppointments} = useContext(OfficerContext);
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/officer/:officerId',
        exact: false,
        strict: false
    });

    const {submenuItems, setSubmenuItems} = useContext(SubmenuContext);
    const isActive = (url: string) => {
        return url === location.pathname;
    };

    const getImageUrl = () => {
        if (!officer.profileImageURL) {
            return "/icons_profil.svg";
        }

        return officer.profileImageURL;
    };

    const [isFollowing, setFollowing] = useState<boolean>(false);
    const [loadingFollowing, setLoadingFollowing] = useState<boolean>(false);
    useEffect(() => {
        setLoadingFollowing(true);
        axios.get(frontEndHostName + "following/officer/" + childMatchedPath?.params.officerId)
            .then(r => setFollowing(r.data))
            .finally(() => setLoadingFollowing(false));
    }, []);

    const [hasFollowCapacity, setFollowCapacity] = useState<boolean>(false);
    useEffect(() => {
        if (!isFollowing) {
            axios.get(frontEndHostName + "officer/has-follow-capacity")
                .then(r => setFollowCapacity(r.data));
        }
    }, [isFollowing]);

    const followOfficer = async () => {
        setLoadingFollowing(true);
        await axios.put(frontEndHostName + "follow-officer/" + childMatchedPath?.params.officerId)
            .then(() => setFollowing(true))
            .catch(() => showGenericSuccessDialog())
            .finally(() => setLoadingFollowing(false));
    };

    const unfollowOfficer = async () => {
        setLoadingFollowing(true);
        await axios.delete(frontEndHostName + "unfollow-officer/" + childMatchedPath?.params.officerId)
            .then(() => setFollowing(false))
            .catch(() => showGenericErrorDialog())
            .finally(() => setLoadingFollowing(false));
    };

    return (
        <div>
            <Row className="" style={{ marginLeft: 0, marginRight: 0, paddingTop: 15 }}>
                <Col xs={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Row className="" style={{ marginLeft: 0, marginRight: 0, paddingTop: 0 }}>
                        <div id="container-header">
                            <span id="company-summary-container">
                                <Image style={{height: "75px", width: "75px", objectFit: "cover"}}
                                       src={getImageUrl()}
                                       roundedCircle/>
                                <span id="company-summary">
                                    <div style={{ fontSize: 24, fontWeight: "bold" }}>{getOfficerName(officer)}</div>
                                </span>
                            </span>
                            <span id="company-actions">
                                {!isFollowing ? (
                                    <button type="button"
                                            className="iq-action-button white"
                                            onClick={() => followOfficer()}
                                            disabled={!hasFollowCapacity}>
                                        <span className="text">Follow</span>
                                        {loadingFollowing ? (
                                            <Spinner className="icon" style={{ display: "block", height: "1rem", width: "1rem" }}
                                                     animation="border"
                                                     variant="primary" />
                                        ) : (
                                            <i className="fa fa-star-o fa-1x icon" style={{ color: "#2F6FC3" }} />
                                        )}
                                    </button>
                                ) : (
                                    <button type="button"
                                            className="iq-action-button white"
                                            onClick={() => unfollowOfficer()}>
                                        <span className="text">Followed</span>
                                        {loadingFollowing ? (
                                            <Spinner className="icon" style={{ display: "block", height: "1rem", width: "1rem" }}
                                                     animation="border"
                                                     variant="primary" />
                                        ) : (
                                            <FontAwesomeIcon className="icon" icon={faStar} color="#2F6FC3" size="1x" />
                                        )}
                                    </button>
                                )}
                            </span>
                            <div id="container-header-navigation">
                                <Nav>
                                    {submenuItems.map((item: any, index: any) => {
                                        return (
                                            <NavItem key={index}>
                                                <Link to={item.url} className="nav-item">
                                                    <span >{item.name}</span>
                                                    {isActive(item.url) && (
                                                        <div className="arrow-wrapper">
                                                            <div className="arrow-up"></div>
                                                        </div>
                                                    )}
                                                </Link>
                                            </NavItem>
                                        )
                                    })}
                                </Nav>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Row>
        </div>
    )
};

export default OfficerHeader;
