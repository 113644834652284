import React, {useContext, useEffect, useRef, useState} from "react";
import NewsItem from "../../model/news/NewsItem";
import axios from "axios";
import {socialHostName} from "../../utils/Configuration";
import {Alert, Col, Container, Row} from "react-bootstrap";
import IqLoadingIcon from "../common/IqLoadingIcon";
import NewsFeed from "./NewsFeed";
import "./News.css";
import Select from "react-select";
import translate from "../../i18n/translate";
import I18nContext from "../../context/I18nContext";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NewsFiltersContext from "../../context/news/NewsFiltersContext";
import NewsFiltersModal from "./NewsFiltersModal";
import Creatable from "react-select/creatable";
import {hasUserRole, Role} from "../../utils/Security";
import qs from "qs";

export interface SelectOption {
    value: string,
    label: string
}

const GlobalNews: React.FC = () => {
    const {lang, setLang} = useContext(I18nContext);

    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [news, setNews] = useState<NewsItem[]>([]);

    const scroller = useRef<any>();
    const [page, setPage] = useState<number>(0);

    const [sectors, setSectors] = useState<string[]>([]);
    const setSectorFilters = (value: SelectOption[]) => {
        setSectors(value?.map((item: SelectOption) => item.value));
        setPage(0);
    };

    const [topics, setTopics] = useState<string[]>([]);
    const setTopicFilters = (value: SelectOption[]) => {
        setTopics(value?.map((item: SelectOption) => item.value));
        setPage(0);
    };

    const [language, setLanguage] = useState<string[]>([lang]);
    const setLanguageFilters = (value: SelectOption[]) => {
        setLanguage(value?.map((item: SelectOption) => item.value));
        setPage(0);
    };

    const [terms, setTerms] = useState<string[]>([]);
    const setTermFilters = (value: SelectOption[]) => {
        setTerms(value?.map((item: SelectOption) => item.value));
        setPage(0);
    };

    useEffect(() => {
        const fetchNews = async () => {
            setLoading(true);

            let params = {
                page: page,
                size: 18,
                sectors: sectors,
                topics: topics,
                terms: terms,
                lang: language
            };

            await axios.get(socialHostName + "news", {
                    params: params,
                    paramsSerializer: (params) => {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    }
                })
                .then(r => {
                    if (page === 0) {
                        setNews([...r.data]);
                    }
                    else {
                        setNews((previousNews: NewsItem[]) => {
                            return [...previousNews, ...r.data];
                        });
                    }
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchNews();
    }, [page, sectors, topics, language, terms]);

    useEffect(() => {
        if (loading && page !== 0) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            scroller.current.scrollTop = maxScroll;
        }
    }, [loading]);

    const handleScroll = () => {
        if (!loading && !error) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            let currentScroll = scroller.current.scrollTop;

            if (currentScroll === maxScroll) {
                setPage(prev => prev + 1);
            }
        }
    };

    const [sectorOptions, setSectorOptions] = useState<SelectOption[]>([]);
    const [topicOptions, setTopicOptions] = useState<SelectOption[]>([]);

    const getLanguageOptions = () => {
        let options: SelectOption[] = [];

        if (hasUserRole(Role.GEO_UK)) {
            options.push({
                value: "en",
                label: "English"
            } as SelectOption);
        }

        if (hasUserRole(Role.GEO_DE)) {
            options.push({
                value: "de",
                label: "Deutsch"
            } as SelectOption);
        }

        if (hasUserRole(Role.GEO_FR)) {
            options.push({
                value: "fr",
                label: "Français"
            } as SelectOption);
        }

        return options;
    };

    useEffect(() => {
        const fetchTopics = async () => {
            await axios.get(socialHostName + "topics")
                .then(r => setTopicOptions(r.data));
        };

        fetchTopics();
    }, []);

    useEffect(() => {
        const fetchSectors = async () => {
            await axios.get(socialHostName + "sectors")
                .then(r => setSectorOptions(r.data));
        };

        fetchSectors();
    }, []);

    const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);

    const getDefaultLanguage = () => {
        let options = getLanguageOptions();

        let userLanguage = options.find(o => o.value === lang);
        if (userLanguage) {
            return userLanguage;
        }

        if (hasUserRole(Role.GEO_UK)) {
            return options.find(o => o.value === "en");
        }

        if (hasUserRole(Role.GEO_DE)) {
            return options.find(o => o.value === "de");
        }

        if (hasUserRole(Role.GEO_FR)) {
            return options.find(o => o.value === "fr");
        }
    };

    return (
        <NewsFiltersContext.Provider
            value={{
                showFiltersModal,
                setShowFiltersModal,
                topicOptions,
                setTopicOptions,
                sectorOptions,
                setSectorOptions,
                topics,
                setTopics,
                sectors,
                setSectors,
                terms,
                setTerms
            }}>
            <Container fluid style={{maxHeight: "100%", overflowY: "scroll", overflowX: "hidden", marginTop: 15}} ref={scroller} onScroll={handleScroll}>
                <Row className="mb-3">
                    <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                        <div className="iq-headline">
                            <span className="text">{translate("news.title")}</span>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className="pt-2" xl={2} lg={2} md={2} sm={6} xs={6}>
                        <Select isMulti
                                isDisabled={false}
                                options={getLanguageOptions()}
                                defaultValue={getDefaultLanguage()}
                                onChange={(e: any) => setLanguageFilters(e)}
                                placeholder="Select languages..."
                                className="basic-multi-select"
                                classNamePrefix="select" />
                    </Col>
                    <Col className="pt-2" xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Creatable isMulti
                                   onChange={(e: any) => setTermFilters(e)}
                                   value={terms?.map(s => ({value: s, label: s} as SelectOption))}
                                   placeholder="Enter Search Terms..."
                                   options={[]} />
                    </Col>
                    <Col className="pt-2" xl={2} lg={2} md={2} sm={6} xs={6}>
                        <Select isMulti
                                isDisabled={sectorOptions.length === 0}
                                options={sectorOptions}
                                value={sectors?.map(s => ({label: s, value: s} as SelectOption))}
                                onChange={(e: any) => setSectorFilters(e)}
                                placeholder="Select sectors..."
                                className="basic-multi-select"
                                classNamePrefix="select" />
                    </Col>
                    <Col className="pt-2" xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Select isMulti
                                isDisabled={topicOptions.length === 0}
                                options={topicOptions}
                                value={topics?.map(s => ({label: s, value: s} as SelectOption))}
                                onChange={(e: any) => setTopicFilters(e)}
                                placeholder="Select topics..."
                                className="basic-multi-select"
                                classNamePrefix="select" />

                    </Col>
                    <Col className="pt-2">
                        <button className="iqx-button primary md-size float-end"
                                onClick={() => setShowFiltersModal(true)}
                                title="Configure Filters">
                            <span className="pe-2">Configure Filters</span>
                            <FontAwesomeIcon icon={faCog}/>
                        </button>
                    </Col>
                </Row>

                {error ? (
                    <Alert variant="danger">
                        {translate("errors.loading")}
                    </Alert>
                ) : (
                    <div>
                        <NewsFeed news={news}/>
                        <div>{loading && <IqLoadingIcon />}</div>
                    </div>
                )}

                <NewsFiltersModal />
            </Container>
        </NewsFiltersContext.Provider>
    )
};

export default GlobalNews;
