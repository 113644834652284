import React from "react";
import {Col, Container, Row } from "react-bootstrap";
import { useRouteMatch, useHistory, Route, Redirect } from "react-router-dom";
import translate from "../../i18n/translate";
import { hasUserRole, Role } from "../../utils/Security";
import UserJobHome from "../jobs/UserJobHome";
import ImportedContacts from "./ImportedContacts";


const PeopleContainer: React.FC = () => {

    let history = useHistory();
    let match = useRouteMatch();





    return (
        <Container fluid style={{ marginTop: 15, marginRight: "auto", paddingLeft: 15 }}>
            <Redirect to={`${match.url}/contacts`} />
            <Row className="mb-3">
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                    <div className="iq-headline">
                            <span className="text">
                                People
                            </span>
                    </div>
                </Col>
            </Row>
            <Row className="" style={{ marginLeft: 0, marginRight: 0, marginBottom: 15 }}>
                <div style={{ marginLeft: "auto", marginRight: "auto" }} className="iq-button-group">
                    <button className={window.location.pathname.includes("contacts") ? "selected-inverted" : ""}
                            onClick={() => { history.push("/people/contacts") }}>
                        Contacts
                    </button>
                    <button className={window.location.pathname.includes("jobs") ? "selected-inverted" : ""}
                            onClick={() => { history.push(`/people/jobs`) }}>
                        Jobs
                    </button>
                </div>
            </Row>
            {hasUserRole(Role.GEO_UK) &&
                <Route exact strict path={`${match.url}/contacts`}>
                    <div style={{
                        paddingTop: "10px",
                        backgroundColor: "white",
                        borderRadius: "0.45rem",
                        padding: 15,
                        marginTop: 10
                    }}>
                        <ImportedContacts/>
                    </div>
                </Route>}
            {hasUserRole(Role.GEO_UK) &&
                <Route exact strict path={`${match.url}/jobs`}>
                    <div style={{
                        paddingTop: "10px",
                        backgroundColor: "white",
                        borderRadius: "0.45rem",
                        padding: 15,
                        marginTop: 10
                    }}>
                        <UserJobHome/>
                    </div>
                </Route>}
        </Container>
    )
};

export default PeopleContainer;