import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {Container, Nav, NavItem, Row} from 'react-bootstrap';
import {UserResponse} from '../../model/users/UserResponse';
import {frontEndHostName} from '../../utils/Configuration';
import IqLoadingIcon from '../common/IqLoadingIcon';
import UserAvatar from '../common/UserAvatar';
import './ProfileContainer.css';
import {Link, useHistory, useLocation} from 'react-router-dom';
import OnboardingPreferencesModal from "../onboarding/OnboardingPreferencesModal";


const ProfileContainer: React.FC = () => {

    const [userSettings, setUserSettings] = useState(false);
    const [user, setUser] = useState<UserResponse>({} as UserResponse);
    const [loading, setLoading] = useState<boolean>(true);
    const [subMenuItems, setSubMenuItem] = useState([
        {name: "User Profile", url: '/profile/settings'},
        {name: "User Integrations", url: "/profile/integration"}
    ])
    const location = useLocation();
    const history = useHistory();

    const isActive = (url: string) => {
        return url === location.pathname;
    };

    const fetchUser = async () => {
        const result = await axios.get(frontEndHostName + 'users/account')
            .then((response) => {
                setUser(response.data);
                setLoading(false)
            }).catch(error => console.log(error.message));
    };

    const userSettingsCompletion = () => {
        setUserSettings(false);
    }

    useEffect(() => {
        fetchUser();
    }, []);

    return (

        <div>
            {!loading ?
                <Container fluid style={{paddingLeft: 0, paddingRight: 0}}>
                    <Row style={{marginLeft: 0, marginRight: 0, paddingTop: 15}}>
                        <div id="container-header">
                            <div id="profile-header">
                                <div id="user-data">
                                    <div id="profile-name">{user.firstName} {user.lastName}</div>
                                    <div id="profile-mail">{user.email}</div>
                                </div>
                                <div><UserAvatar width={"100px"} height={"100px"} user={user}/></div>
                            </div>
                            <div id="profile-container-navigation">
                                <Nav>
                                    {subMenuItems.map((item: any, index: any) => {
                                        return (
                                            <NavItem key={index}>
                                                <Link to={{pathname: item.url, state: {loadingState: loading}}}
                                                      className="profile-nav-item">
                                                    <span>{item.name}</span>
                                                    {isActive(item.url) && <div className="profile-arrow-wrapper">
                                                        <div className="profile-arrow-up"/>
                                                    </div>}

                                                </Link>
                                            </NavItem>
                                        )
                                    })}
                                </Nav>
                            </div>
                        </div>
                    </Row>
                    <OnboardingPreferencesModal shown={userSettings} setShown={setUserSettings} exitable={true} onCompleteCallback={userSettingsCompletion}/>
                </Container>
                : (
                    <IqLoadingIcon/>
                )
            }
        </div>
    );
};

export default ProfileContainer;
