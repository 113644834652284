import React, {useContext} from 'react';
import UserOrganisationContext from '../../context/organisation/OrganisationContext';
import translate from "../../i18n/translate";
import OrganisationSocialContext from "../../context/organisation/OrganisationSocialContext";

const COMPANY_URL = "https://www.linkedin.com/company/";

const OrganisationSummaryPeople: React.FC = () => {

    const { organisation } = useContext(UserOrganisationContext);
    const { socialHandles } = useContext(OrganisationSocialContext);

    const getHandle = () => {
        if (socialHandles.linkedin) {
            let url = socialHandles.linkedin;

            if (url.startsWith(COMPANY_URL)) {
                let handle = trimTrailingSlash(url.replaceAll(COMPANY_URL, ""));

                let index = handle.lastIndexOf("/");
                return index > 0 ? handle.substr(0, index) : handle;
            }
        }

        return organisation.companyName;
    };

    const trimTrailingSlash = (handle: string) => {
        if (handle.endsWith("/")) {
            handle = handle.substr(0, handle.length - 1);
        }

        return handle;
    };

    return (
        <ul>
            <li>
                <a target="_blank" href={"https://www.linkedin.com/search/results/people/?company=" + getHandle() + "&title=MD%20OR%20CEO%20OR%20CIO%20OR%20CTO%20OR%20CFO%20OR%20director&origin=FACETED_SEARCH&page=1"}>
                    {translate("organisation.summary.linkedinsearch.seniorcontacts")}
                </a>
            </li>
            <li>
                <a target="_blank" href={"https://www.linkedin.com/search/results/people/?company=" + getHandle() + "&title=Sales%20OR%20(business%20AND%20development)%20OR%20(New%20AND%20Business)%20OR%20Marketing&origin=FACETED_SEARCH"}>
                    {translate("organisation.summary.linkedinsearch.sales")}
                </a>
            </li>
            <li>
                <a target="_blank" href={"https://www.linkedin.com/search/results/people/?company=" + getHandle() + "&title=(Network%20OR%20Storage%20OR%20Datacentre%20OR%20IT)%20AND%20(manager%20OR%20director)&origin=FACETED_SEARCH&page=1"}>
                    {translate("organisation.summary.linkedinsearch.infrastructure")}
                </a>
            </li>
            <li>
                <a target="_blank" href={"https://www.linkedin.com/search/results/people/?company=" + getHandle() + "&title=Security%20OR%20Application%20OR%20Database%20OR%20Data%20OR%20DevOps%20OR%20CISO&origin=FACETED_SEARCH&page=1"}>
                    {translate("organisation.summary.linkedinsearch.security")}
                </a>
            </li>
        </ul>
    );
};

export default OrganisationSummaryPeople;
