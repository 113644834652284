import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import Select from "antd/lib/select";
import axios from "axios";
import {frontEndHostName} from "../../utils/Configuration";
import Card from "./ProfileCard";
import {faThumbsUp} from "@fortawesome/free-solid-svg-icons";

const ChangeInterests: React.FC = () => {

    const [interests, setInterests] = useState<string[]>([]);

    const handleInterestsChange = (val: string[]) => {
        setInterests(val);
    };

    const [saving, setSaving] = useState<boolean>(false);


    useEffect(() => {

        const fetchInterests = async () => {
            await axios.get(frontEndHostName + "interests")
                .then(r => setInterests(r.data))

        }
        fetchInterests();


    },[])


    const save = async () => {
        setSaving(true);
        await axios.post(frontEndHostName + "interests/save", interests)
            .finally(() => {
                setSaving(false);
            });
    };

    return (
       <Card heading={"Interests"} iconDefinition={faThumbsUp}>
           <Form.Group>
               <Form.Text className="mb-3">
                   Modify your interests
               </Form.Text>
               <Select
                   disabled={false}
                   mode={'tags'}
                   style={{
                       minWidth: 200,
                       width: "100%"
                   }}
                   dropdownStyle={{
                       width: 250,
                   }}
                   key="widget-multiselect"
                   placeholder="Input interests"
                   size="large"
                   value={interests}
                   tokenSeparators={[',']}
                   onChange={(val: string[]) => { handleInterestsChange(val) }}
                   filterOption={true}
               />
           </Form.Group>

           <Button variant="primary" type="submit" style={{marginTop: "auto", float: "right"}} onClick={save}>
               Save
           </Button>
        </Card>
    )
}


export default ChangeInterests;