import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {Team} from "./Teams";
import DateFormatter from "../../utils/formatters/DateFormatter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faChevronRight, faHashtag, faTools, faTrashAlt, faUsers} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {frontEndHostName} from "../../utils/Configuration";
import translate from "../../i18n/translate";
import {showGenericErrorDialog, showSuccessWithMessage} from "../common/CommonAlerts";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

interface Props {
    team: Team;
    deleteTeam: () => void
}

const TeamLink: React.FC<Props> = (props: Props) => {
    const [team, setTeam] = useState<Team>(props.team);
    let history = useHistory();

    const showDeleteConfirm = () => {
        const ConfirmDeleteDialog = withReactContent(Swal);
        ConfirmDeleteDialog.fire({
                title: "Are you sure you want to delete this team?",
                text: "Deleting a team cannot be undone",
                icon: "question",
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "Cancel",
                showCancelButton: true
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    await axios.delete(frontEndHostName + "teams/" + team.id + "/delete")
                        .then(r => {
                            showSuccessWithMessage("Success!", "Team has been deleted successfully!");
                            props.deleteTeam();
                        })
                }
            })
            .catch(error => showGenericErrorDialog())
    }

    return (
        <>
            <div className="team-card">
                <div>
                    <span className="team-icon-box">
                        <FontAwesomeIcon icon={faUsers} color="white" size="1x" />
                    </span>
                    <span className="name">
                        {team.name}
                    </span>
                </div>
                <div className="team-content-container">
                    <div className="team-details-container">
                        <div className="team-detail">
                            <div className="team-detail-container">
                                <span><FontAwesomeIcon style={{ marginLeft: 5, marginRight: 5 }} className="icon" icon={faCalendar} color="#9120C3" size="1x" /></span>
                                <span>{translate("teams.main.createdon", {date: DateFormatter.formatDate(team.createdDate.toString())})}</span>
                            </div>
                        </div>
                        <div className="team-detail">
                            <div className="team-detail-container">
                                <span><FontAwesomeIcon style={{ marginLeft: 5, marginRight: 5 }} className="icon" icon={faHashtag} color="#f84899" size="1x" /></span>
                                <span>{translate("teams.main.members", {count: team.users.length})}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="team-actions">
                    <div className="team-action">
                        <button className="iqx-button sm-size pull-right" onClick={() => showDeleteConfirm()}>
                            <span style={{ paddingRight: 10 }}>{translate("teams.main.deleteteam")}</span>
                            <FontAwesomeIcon icon={faTrashAlt} size="1x" />
                        </button>
                    </div>
                    <div className="team-action">
                        <button className="iqx-button sm-size pull-right" onClick={() => history.push(`/team/${team.id}/edit`)}>
                            <span style={{ paddingRight: 10 }}>{translate("teams.main.manage")}</span>
                            <FontAwesomeIcon icon={faTools} size="1x" />
                        </button>
                    </div>
                    <div className="team-action">
                        <button className="iqx-button sm-size primary mb-2 pull-right" onClick={() => history.push(`/team/${team.id}`)}>
                            <span style={{ paddingRight: 10 }}>{translate("teams.main.viewteam")}</span>
                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
};

export default TeamLink;
