import React from "react";
import { ImmutableTree } from 'react-awesome-query-builder-opti';
import Filters from "../../model/advanced-search/Filters";

const AdvancedSearchContext = React.createContext({
    tree: {} as ImmutableTree,
    setTree: (tree: ImmutableTree) => {},
    filters: {} as Filters,
    config: {} as any
});

export default AdvancedSearchContext;
