import {faFacebook, faInstagram, faLinkedin, faVimeo, faYoutube} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useContext, useState} from 'react';
import UserOrganisationContext from '../../../context/organisation/OrganisationContext';
import OrganisationSocialContext from '../../../context/organisation/OrganisationSocialContext';
import IqLoadingIcon from '../../common/IqLoadingIcon';
import {ReactComponent as XingLogo} from './assets/xing-logo.svg';
import {FaXTwitter} from "react-icons/fa6"

const OrganisationSocialHandles: React.FC = () => {

    const { organisation } = useContext(UserOrganisationContext);
    const { socialHandles, loadingSocials } = useContext(OrganisationSocialContext);

    const [error, setError] = useState<boolean>(false);
    const [socialParent, setSocialParent] = useState<HTMLDivElement | null>();

    return (
        <>
            {loadingSocials ?
                <IqLoadingIcon />
                :
                <div className="organisation-social-handle-y-axis">
                    <div className="organisation-social-handles" ref={(element) => setSocialParent(element)}>
                        {socialHandles?.twitter && <a style={{ flexShrink: 0, maxWidth: 42, maxHeight:42 }} href={"https://twitter.com/" + socialHandles?.twitter} target="_blank"><FaXTwitter color="#2F6FC3" size="3x" /></a>}
                        {socialHandles?.linkedin && <a style={{ flexShrink: 0 }} href={socialHandles?.linkedin} target="_blank"><FontAwesomeIcon icon={faLinkedin} color="#2F6FC3" size="3x" /></a>}
                        {socialHandles?.facebook && <a style={{ flexShrink: 0 }} href={socialHandles?.facebook} target="_blank"><FontAwesomeIcon icon={faFacebook} color="#2F6FC3" size="3x" /></a>}
                        {socialHandles?.instagram && <a style={{ flexShrink: 0 }} href={socialHandles?.instagram} target="_blank"><FontAwesomeIcon icon={faInstagram} color="#2F6FC3" size="3x" /></a>}
                        {socialHandles?.vimeo && <a style={{ flexShrink: 0 }} href={socialHandles?.vimeo} target="_blank"><FontAwesomeIcon icon={faVimeo} color="#2F6FC3" size="3x" /></a>}
                        {socialHandles?.youtube && <a style={{ flexShrink: 0 }} href={socialHandles?.youtube} target="_blank"><FontAwesomeIcon icon={faYoutube} color="#2F6FC3" size="3x" /></a>}
                        {socialHandles?.xing && <a style={{ flexShrink: 0 }} href={socialHandles?.xing} target="_blank"><XingLogo style={{ fill: "#2F6FC3", height: 48, width: 48 }} /></a>}
                    </div>
                </div>
            }
        </>
    );
};

export default OrganisationSocialHandles;
