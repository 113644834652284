import React, {useEffect, useState} from "react";
import {FundingOrganisation} from "../../../model/publicsector/Funding";
import {organisationHostname} from "../../../utils/Configuration";
import axios from "axios";
import {Image} from "react-bootstrap";
import IqSmallLoadingIcon from "../../common/IqSmallLoadingIcon";

interface Props {
    organisation: FundingOrganisation,
    title: string
}

const ResearchOrganisation: React.FC<Props> = (props: Props) => {
    const {organisation, title} = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [organisationLogo, setOrganisationLogo] = useState<string>();
    useEffect(() => {
        const fetchOrganisation = async () => {
            setLoading(true);
            await axios.post(organisationHostname + "organisation/find-all-minified-by-id", {
                ids: [organisation.iqbladeOrganisationId]
            })
                .then(r => setOrganisationLogo(r.data[0].imageUrl))
                .catch(e => console.error(e))
                .finally(() => setLoading(false));
        }

        if (organisation.iqbladeOrganisationId) {
            fetchOrganisation();
        }
    }, []);

    interface CompanyLogo {
        imageUrl: string | undefined,
        companyName: string | undefined
    }

    const CompanyLogo: React.FC<CompanyLogo> = (props: CompanyLogo) => {
        const [src, setSrc] = useState<string>(props.imageUrl ? props.imageUrl : "./icons_company.svg");

        return (
            <Image title={props.companyName}
                   style={{
                       height: "50px",
                       width: "50px",
                       objectFit: "cover",
                       border: "2px solid #5FD1F9"
                   }}
                   src={src}
                   onError={() => setSrc("/icons_company.svg")}
                   roundedCircle />
        )
    };

    return (
        <div className="info-entry">
            <div className="organisation-card-label">
                {title}
            </div>
            <div className="info-entry">
                {loading ? <IqSmallLoadingIcon /> : (
                    <div className="ccs-title mb-3">
                        <span className="me-3"><CompanyLogo imageUrl={organisationLogo} companyName={organisation.name} /></span>
                        {organisation.iqbladeOrganisationId ? (
                            <a href={`/organisation/${organisation.iqbladeOrganisationId}`} className="iqx-link">{organisation.name}</a>
                        ) : (
                            <span style={{cursor: "not-allowed"}}>{organisation.name}</span>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ResearchOrganisation;