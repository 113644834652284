import React, {useEffect, useState} from "react";
import "./ContractSearchLink.css";
import {FilterNewPreset} from "./ContractList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faFileContract, faFilter, faPencilAlt, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {contractsHostName} from "../../../utils/Configuration";
import {useHistory} from "react-router-dom";

interface Props {
    filter: FilterNewPreset;
    deleteFilter: (id: number) => void;
}

const ContractSearchLink: React.FC<Props> = (props: Props) => {

    let history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [count, setCount] = useState<number>();
    useEffect(() => {

        // Check the count by getting the Search ID and perform a Get Search Count on the Search, then store that count and compare to the one from the DB, then show the new contract for a search
        const getCount = async () => {
            setLoading(true);
            await axios.get(contractsHostName + "mysql/getsavednewcount?searchId=" + props.filter.saveSearchId)
                .then(r => setCount(r.data))
                .finally(() => setLoading(false));
        };

        getCount();
    }, [count]);

    return (
        <div className="contract-card">
            <div>
                <span className="contract-icon-box">
                    <FontAwesomeIcon icon={faFilter} color="white" size="1x" />
                </span>
                <span className="name">
                    {props.filter.nameOfSearch}
                </span>
            </div>
            <div className="contract-content-container">
                <div className="contract-details-container">
                    <div className="contract-detail">
                        <div className="contract-detail-container">
                            <span><FontAwesomeIcon style={{ marginLeft: 5, marginRight: 5 }} className="icon" icon={faFileContract} color="#9120C3" size="1x" /></span>
                            <span className={loading ? "pt-1" : ""}>
                                {loading ? (
                                    <span className="spinner-border text-primary pt-1" style={{display: "block", marginLeft: "auto", marginRight: "auto", height: "1rem", width: "1rem"}} ></span>
                                ) : (
                                    count ? (
                                        (count -  props.filter.numberOfResults != 0) ? (
                                            count - props.filter.numberOfResults > 0 ? (
                                                <span>{props.filter.numberOfResults} contracts + {count - props.filter.numberOfResults} new contracts found</span>
                                            ) : (
                                            <span>{props.filter.numberOfResults} contracts - {count - props.filter.numberOfResults} new contracts found</span>
                                            )
                                        ) : (
                                            <span>{props.filter.numberOfResults} contracts - No new contracts found</span>
                                        )
                                    ) : (
                                        <span>{props.filter.numberOfResults} contracts</span>
                                    )
                                )}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="contract-actions">
                    <div className="contract-action">
                        <button className="iqx-button sm-size pull-right" onClick={() => props.deleteFilter(props.filter.saveSearchId)}>
                            <span style={{ paddingRight: 10 }}>
                                Delete
                            </span>
                            <FontAwesomeIcon icon={faTrashAlt} size="1x" />
                        </button>
                    </div>

                    <div className="contract-action">
                        <button className="iqx-button sm-size pull-right" onClick={() => history.push("/contracts/" + props.filter.saveSearchId + "/edit")}>
                            <span style={{ paddingRight: 10 }}>
                                Edit Filters
                            </span>
                            <FontAwesomeIcon icon={faPencilAlt} size="1x" />
                        </button>
                    </div>

                    <div className="contract-action">
                        <button className="iqx-button sm-size primary mb-2 pull-right" onClick={() => history.push("/contracts/" + props.filter.saveSearchId)}>
                            <span style={{ paddingRight: 10 }}>
                                View Contracts
                            </span>
                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContractSearchLink;