import React, {useContext} from "react";
import TeamContext from "../../context/teams/TeamContext";
import {Col, Nav, NavItem, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {Link, useHistory, useLocation} from "react-router-dom";
import SubmenuContext from "../../context/SubmenuContext";
import translate from "../../i18n/translate";

const TeamHeader: React.FC = () => {
    const location = useLocation();
    const {team, setTeam} = useContext(TeamContext);
    let history = useHistory();
    const {submenuItems, setSubmenuItems} = useContext(SubmenuContext);

    const isActive = (url: string) => {
        return url === location.pathname;
    };

    return (
        <div>
            <Row className="" style={{ marginLeft: 0, marginRight: 0, paddingTop: 15 }}>
                <Col xs={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Row className="" style={{ marginLeft: 0, marginRight: 0, paddingTop: 0 }}>
                        <div id="container-header">

                            <div style={{marginBottom: 5}}>
                                <a className="team-header-link" onClick={() => history.push("/teams")}>
                                    <FontAwesomeIcon icon={faChevronLeft} style={{marginRight: 10}}/>
                                    &nbsp;{translate("teams.main.backtoteams")}
                                </a>
                            </div>
                            <span id="company-summary-container" style={{marginBottom: 20}}>
                                <span id="company-summary">
                                    <div style={{ fontSize: 24, fontWeight: "bold" }}>{team.name}</div>
                                </span>
                            </span>
                            <div id="container-header-navigation">
                                <Nav>
                                    {submenuItems.map((item: any, index: any) => {
                                        return (
                                            <NavItem key={index}>
                                                <Link to={item.url} className="nav-item">
                                                    <span>{translate(item.name)}</span>
                                                    {isActive(item.url) && (
                                                        <div className="arrow-wrapper">
                                                            <div className="arrow-up"></div>
                                                        </div>
                                                    )}
                                                </Link>
                                            </NavItem>
                                        )
                                    })}
                                </Nav>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default TeamHeader;
