import React, {useContext, useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {Alert} from 'react-bootstrap';
import axios from 'axios';
import {socialHostName} from '../../../utils/Configuration';
import IqLoadingIcon from '../../common/IqLoadingIcon';
import NewsItem from '../../../model/news/NewsItem';
import translate from '../../../i18n/translate';
import NewsFeed from '../../news/NewsFeed';
import ScrollPaginationContext from '../../../context/ScrollPaginationContext';

const OrganisationChannelPartnersNews: React.FC = () => {

    let { organisationId } = useParams<{organisationId: string}>();
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [news, setNews] = useState<NewsItem[]>([]);

    const scroller = useRef<any>();
    const {page, setPage} = useContext(ScrollPaginationContext);

    useEffect(() => {
        const fetchNews = async () => {
            setLoading(true);

            let query = "?page=" + page + "&size=18";

            await axios.get(socialHostName + "news/channel-partners/" + organisationId + query)
                .then(r => {
                    if (page === 0) {
                        setNews([...r.data]);
                    }
                    else {
                        setNews((previousNews: NewsItem[]) => {
                            return [...previousNews, ...r.data];
                        });
                    }
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchNews();
    }, [page]);

    return (
        <div style={{maxHeight: "100%", width: "100%", overflowY: "scroll", overflowX: "hidden"}}>
            {error ? (
                <Alert variant="danger">
                    {translate("errors.loading")}
                </Alert>
            ) : (
                <>
                    <NewsFeed news={news}/>
                    {loading && <IqLoadingIcon />}
                </>
            )}
        </div>
    );
}

export default OrganisationChannelPartnersNews;
