import React, {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {frontEndHostName, socialHostName} from "../../../utils/Configuration";
import DashboardItemContext from "../../../context/dashboard/DashboardItemContext";
import {Alert, Col, OverlayTrigger, Popover, Row} from "react-bootstrap";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {Tweet} from "react-twitter-widgets";
import translate from "../../../i18n/translate";
import DashboardContext from "../../../context/dashboard/DashboardContext";
import DashboardKeyContext from "../../../context/dashboard/DashboardKeyContext";
import {Select, Switch} from "antd";


interface TweetDTO {
    tweetId: string,
    userId: string,
    createdAt: string
}

interface SelectOption {
    value: string,
    label: string
}

const DashboardFilteredTwitter: React.FC = () => {

    const size: number = 5;
    const { maxHeight, setMaxHeight } = useContext(DashboardItemContext);
    const { layout } = useContext(DashboardContext);
    const { widgetId } = useContext(DashboardKeyContext)
    const getHeight = () => {
        return maxHeight - 120;
    };

    const [tweets, setTweets] = useState<TweetDTO[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingMore, setLoadingMore] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [dashboardId] = useState<number>(layout[0].dashboardId)

    const [selectedTerms, setSelectedTerms] = useState<string[]>([]);
    const [isPreferenceRetrieved, setIsPreferenceRetrieved] = useState<boolean>(false);
    const [loadFilter, setLoadFilter] = useState<boolean>(false);

    const prevSelection = useRef({ selectedTerms }).current;

    const scroller = useRef<any>();

    useEffect(() => {
        const getWidgetPreferences = async () => {
            await axios.get(frontEndHostName + "api/dashboard/filter?widgetId=" + widgetId + "&dashboardId=" + dashboardId)
                .then(r => {
                    setSelectedTerms(r.data)
                })
                .catch(err => {
                    console.log(err)
                })
        }
        getWidgetPreferences()
        setIsPreferenceRetrieved(true)
    }, [])

    const updatePage = () => {
        setPage(prev => prev + 1);
        setLoadingMore(false);
    };

    const handleScroll = () => {
        if (!loading && !error) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            let currentScroll = scroller.current.scrollTop;

            if (currentScroll === maxScroll) {
                setLoadingMore(true);
                debounce(updatePage, 3000)();
            }
        }
    };

    const debounce = <F extends (...params: any[]) => void>(fn: F, delay: number) => {
        let timeoutId: any;

        const debounced = (...args: any) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => fn(...args), delay);
        };

        return debounced as (...args: Parameters<F>) => ReturnType<F>;
    };

    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        const fetchTweets = async () => {
            if (selectedTerms !== prevSelection?.selectedTerms || page === 0) {
                setLoading(true);
                await axios.post(socialHostName + "twitter/filteredTweets?page=0" +
                    "&size=" + size, selectedTerms)
                    .then(r => {
                        setTweets(
                            r.data
                        );
                        setPage(0)
                    })
                    .catch(error => setError(true))
                    .finally(() => setLoading(false));
            } else {
                setLoadingMore(true);
                await axios.post(socialHostName + "twitter/filteredTweets?page=" + page +
                    "&size=" + size, selectedTerms)
                    .then(r => {
                        setTweets((prevTweets: TweetDTO[]) => {
                            return [...prevTweets, ...r.data];
                        })
                    }
                    )
                    .catch(error => setError(true))
                    .finally(() => setLoadingMore(false));
            }
        };

        fetchTweets();
        return () => {
            prevSelection.selectedTerms = selectedTerms;
        };
    }, [page, selectedTerms]);

    useEffect(() => {
        const saveWidgetPreferences = () => {
            if (isPreferenceRetrieved) {
                let data = selectedTerms
                axios.post(frontEndHostName + "api/dashboard/save/filter?widgetId=" + widgetId + "&dashboardId=" + dashboardId, data)
            }
        }
        saveWidgetPreferences()
    }, [selectedTerms, loadFilter])


    useEffect(() => {
        const fetchInterests = async () => {
            await axios.get(frontEndHostName + "interests")
                .then(r => setSelectedTerms(r.data))
        }
        if (loadFilter) {
            fetchInterests();
        }

    },[loadFilter])
    
    const onChange = (checked: boolean) => {
      if (checked){
          setLoadFilter(true)
      } else {
          setLoadFilter(false)
          setSelectedTerms([]);
      }
    }

    const popover = (
        <Popover id="popover-basic">
            <h3>{translate("dashboard.twitter.popoverTitle")}</h3>
            <div>
                {translate("dashboard.twitter.popoverMessage")}
            </div>
        </Popover>
    );


    return (
        <div>
            <Row style={{justifyContent: "right"}}>
                <div>
                  <span style={{textTransform: "uppercase", color: "#929292", paddingRight: 2}} >Load terms</span>
                    <OverlayTrigger overlay={popover}>
                <Switch size={"small"} onChange={(checked: boolean) => onChange(checked)}/>
                    </OverlayTrigger>
                </div>
            </Row>
            <Row className="pb-0.5">
                <Col>
                    <div className="organisation-card-label">
                        {translate("dashboard.twitter.filteredTitle")}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="pt-1" xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Select
                        disabled={false}
                        mode={"tags"}
                        style={{
                            width: "100%",
                            // width: 200,
                        }}
                        dropdownStyle={{
                            width: 250,
                        }}
                        // onDropdownVisibleChange={this.handleDropdownOpen}
                        key={"widget-multiselect"}
                        placeholder={"Input your Twitter filters"}
                        size={"large"}
                        value={selectedTerms}
                        onChange={(val: string[]) => { setSelectedTerms(val) }}
                        filterOption={true}
                    // maxTagCount={1}
                    >
                    </Select>
                </Col>
            </Row>
            <div style={{ marginTop: 15, maxHeight: getHeight(), overflowY: "scroll", overflowX: "hidden" }}
                ref={scroller} onScroll={handleScroll}>
                {error ? (
                    <Alert variant="danger">
                        {translate("errors.loading")}
                    </Alert>
                ) : (
                    !loading ?
                        <div>
                            {tweets.length === 0 && <Alert variant="primary">
                                We couldn't find any recent tweets based on the provided terms.
                            </Alert>}
                            {tweets.map((tweet: TweetDTO) => (
                                <div key={tweet.tweetId}>
                                    <Tweet tweetId={tweet.tweetId} options={{ maxWidth: "98%!important" }} />
                                </div>
                            ))}
                            <div>{loadingMore && <IqLoadingIcon />}</div>
                        </div>
                        : <IqLoadingIcon />
                )}
            </div>
        </div>
    );
};

export default DashboardFilteredTwitter;
