import React from 'react';
import './IqCopyright.css';
import translate from "../../i18n/translate";

const IqCopyright: React.FC = () => {

    const currentTime = new Date();
    const IQ_BLADE_BUILD = process.env.REACT_APP_BUILD || "dev";


    const romanize = (num: number) => {
        if (!+num)
            return false;
        let digits = String(+num).split(""),
            key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
                "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
                "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
            roman = "",
            i = 3;
        while (i--) {
            let digit = digits.pop()
            if (digit) roman = (key[+digit + (i * 10)] || "") + roman;
        }
        return Array(+digits.join("") + 1).join("M") + roman;
    };

    return (
        <div id="iqblade-copyright">
            <div id="iqblade-copyright-wrapper-vertically">
                <div className="iqblade-copyright-wrapper-horizontally">
                    <div>© {romanize(currentTime.getFullYear())} · IQBlade Limited</div>
                </div>
                <div className="iqblade-copyright-wrapper-horizontally">
                    <div>{translate("lang")}</div>
                </div>
                <div className="iqblade-copyright-wrapper-horizontally">
                    <div>{IQ_BLADE_BUILD}</div>
                </div>
                <div className="iqblade-copyright-wrapper-horizontally">
                    <div><a className="iqx-link" target= "_blank" href="https://www.iqblade.com/privacy-policy/">Privacy Policy</a></div>
                </div>
            </div>
        </div>
    );
}

export default IqCopyright;
