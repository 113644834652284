import React from "react";
import NylasContact from "../../model/organisation/social/NylasContact";

const OrganisationPeopleContext = React.createContext({

    contacts: {} as NylasContact[],
    setContacts: (contacts: NylasContact[]) => {},
    key: '',
    setKey: (key: string) => {},

});

export default OrganisationPeopleContext;