import {faCheck, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Select} from "antd";
import axios from "axios";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import AdvancedSearch from "../../model/advanced-search/AdvancedSearch";
import {frontEndHostName, searchHostname} from "../../utils/Configuration";
import IqSmallLoadingIcon from "../common/IqSmallLoadingIcon";
import {Team} from "../teams/Teams";

interface AdvancedSearchShareProps {
    search: AdvancedSearch,
    setSearch: any;
    setBeingShared: any;
}

const ShareContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: #EFEFEF;
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    font-family: Roboto;
    gap: 0.4rem;
    border-radius: 0.7rem;
  `;

const ExistingContainer = styled.div`
    display: flex;
    gap: 0.4rem;
`;

const ActionContainer = styled.div`
    display:flex;
    gap: 0.4rem;
    align-items: center;
    flex-wrap: wrap;
`;

const TeamBubble = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 0.4rem;
    background-color: #EFEFEF;
    font-family: Roboto;
    font-weight: 500;
    font-size: 0.9rem;
    border: 0.05rem solid;
    border-radius: 0.7rem;
    padding: 0.25rem;
    background-color: white;
  `;
const AdvancedSearchShare: React.FC<AdvancedSearchShareProps> = (props: AdvancedSearchShareProps) => {

    const [teamEntries, setTeamEntries] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingAdd, setLoadingAdd] = useState<boolean>(false);

    const [teams, setTeams] = useState<Team[]>([]);


    const fetchTeams = async () => {
        setLoading(true)
        await axios.get(frontEndHostName + "teams")
            .then(r => setTeams(r.data))
            .catch(() => { })
            .finally(() => { setLoading(false) });
    };

    const addTeamEntries = async () => {
        setLoadingAdd(true);
        await axios.post(searchHostname + "advanced-search/share/", {
            searchId: props.search.id,
            teamIds: teamEntries
        })
            .then(r => { props.setSearch(r.data); setTeamEntries([]) })
            .catch(() => { })
            .finally(() => { setLoadingAdd(false) });
    };

    const removeTeamEntry = async (teamId: number) => {
        await axios.delete(searchHostname + "advanced-search/share/", {
            params: {
                searchId: props.search.id,
                teamId: teamId
            }
        })
            .then(r => props.setSearch({
                ...props.search,
                shares: props.search.shares.filter(share => share.id !== teamId)
            }))
            .catch(() => { })
            .finally(() => { });
    };

    useEffect(() => {
        fetchTeams();
    }, [])

    return (
        <ShareContainer>
            <ExistingContainer>
                {props.search.shares.map(team => (
                    <TeamBubble>
                        <span>{team.name}</span>
                        <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon={faTrash}
                            size={"sm"}
                            onClick={() => removeTeamEntry(team.id)} />
                    </TeamBubble>
                ))}
            </ExistingContainer>
            {loading ? <IqSmallLoadingIcon /> :
                <ActionContainer>
                    <Select
                        disabled={false}
                        mode={"tags"}
                        style={{
                            minWidth: 200,
                        }}
                        dropdownStyle={{
                            width: 250,
                        }}
                        key={"widget-multiselect"}
                        placeholder={"Input desired teams"}
                        size={"middle"}
                        value={teamEntries}
                        onChange={(val: string[]) => { setTeamEntries(val) }}
                        filterOption={true}
                    >
                        {teams.map(o => {
                            return (<Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>);
                        })}
                    </Select>
                    {loadingAdd
                        ?
                        <IqSmallLoadingIcon />
                        :
                        <>
                            {teamEntries.length > 0 && <button className="iqx-button sm-size primary mb-2 pull-right" onClick={() => { addTeamEntries() }}>
                                <span style={{ paddingRight: 10 }}>
                                    {"Add"}
                                </span>
                                <FontAwesomeIcon icon={faCheck} size="sm" />
                            </button>}
                            <button className="iqx-button sm-size primary mb-2 pull-right" onClick={() => { props.setBeingShared(false) }}>
                                <span style={{ paddingRight: 10 }}>
                                    {"Done"}
                                </span>
                                <FontAwesomeIcon icon={faCheck} size="sm" />
                            </button>
                        </>
                    }
                </ActionContainer>
            }
        </ShareContainer>
    );
};

export default AdvancedSearchShare;