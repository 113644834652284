import {Select} from "antd";
import axios from "axios";
import {formatLocale, FormatLocaleDefinition} from 'd3-format';
import React, {useEffect, useState} from "react";
import {Container, Row, Spinner, Table} from "react-bootstrap";
import {matchPath, useHistory, useLocation, useParams} from "react-router-dom";
import {AutoSizer} from "react-virtualized";
import {
    DiscreteColorLegend,
    Hint,
    HorizontalGridLines,
    LineMarkSeries,
    LineSeriesPoint,
    VerticalBarSeries,
    VerticalBarSeriesPoint,
    VerticalGridLines,
    XAxis,
    XYPlot,
    YAxis
} from "react-vis";
import {organisationHostname} from "../../../utils/Configuration";
import './PublicSectorSpend.css';
import PublicSectorSpendInfiniteSelect from "./PublicSectorSpendInfiniteSelect";
import {getMonthName} from "../../../utils/formatters/DateFormatter";
import queryString from "query-string";
import PublicSectorSpendContext from "../../../context/organisation/PublicSectorSpendContext";

const Option = Select.Option;

export enum FilterType {
    EXPENSE_TYPE = "EXPENSE_TYPE",
    EXPENSE_AREA = "EXPENSE_AREA",
    SUPPLIER = "SUPPLIER",
    POST_DATE = "POST_DATE"
}

const PublicSectorSpend: React.FC = () => {

    let { organisationId } = useParams<{organisationId: string}>();

    const [hover, setHover] = useState<any>();

    interface SupplierOverTime {
        postedDate: Date,
        spendSum: number
    }

    interface SupplierByArea {
        postedDate: Date,
        spendSum: number,
        expenseArea: string
    }

    interface Top10Supplier {
        supplier: string,
        supplierId: string,
        spendSum: number
    }

    enum SpendView {
        SUPPLIERS_OVER_TIME = "time",
        SUPPLIERS_BY_AREA = "area",
        TOP_10_SUPPLIERS = "top10"
    }

    let location = useLocation();
    const history = useHistory();
    const parsed = queryString.parse(location.search);
    const [spendView, setSpendView] = useState(parsed.view);
    useEffect(() => {
        const parsed = queryString.parse(location.search);
        setSpendView(parsed.view);
    }, [location]);

    const updateView = (view: string) => {
        const childMatchedPath: any = matchPath(location.pathname, {
            path: '/organisation/:organisationId',
            exact: false,
            strict: false
        });

        let link: string = "/organisation/" + childMatchedPath?.params.organisationId + "/spend";
        if (view !== "") {
            link += "?view=" + view;
        }

        history.push(link);
    };

    const [supplierOverTime, setSupplierOverTime] = useState<SupplierOverTime[]>([]);
    const [supplierOverTimeData, setSupplierOverTimeData] = useState<LineSeriesPoint[]>([]);

    const [supplierByArea, setSupplierByArea] = useState<SupplierByArea[]>([]);
    const [supplierByAreaData, setSupplierByAreaData] = useState<VerticalBarSeriesPoint[]>([]);

    const [top10Suppliers, setTop10Suppliers] = useState<Top10Supplier[]>([]);


    const [expenseAreaOptions, setExpenseAreaOptions] = useState<any>({
        content: [{ expenseArea: "All" }]
    });
    const [selectedExpenseArea, setSelectedExpenseArea] = useState<any>("All");

    const [expenseTypeOptions, setExpenseTypeOptions] = useState<any>({
        content: [{ expenseType: "All" }]
    });
    const [selectedExpenseType, setSelectedExpenseType] = useState<any>("All");

    const [supplierOptions, setSupplierOptions] = useState<any>({
        content: [{ supplier: "All" }]
    });
    const [selectedSupplier, setSelectedSupplier] = useState<any>("All");

    const [yearOptions, setYearOptions] = useState<any>({
        content: [{ year: "All" }]
    });
    const [selectedYear, setSelectedYear] = useState<any>("All");

    let UK = formatLocale({
        currency: ["£", ""]
    } as FormatLocaleDefinition);


    const [chartLoading, setChartLoading] = useState<boolean>(false);


    const fetchSupplierOverTime = async (expenseType: string, expenseArea: string) => {
        // setIsLoading(true);
        setChartLoading(true);
        await axios.post(organisationHostname + "organisation/" + organisationId + "/spend/supplier-over-time", {
            expenseType: expenseType === "All" ? "%" : expenseType,
            expenseArea: expenseArea === "All" ? "%" : expenseArea

        })
            .then(r => {
                setSupplierOverTime(r.data);
            })
            .catch(error => console.error(error.message))
            .finally(() => setChartLoading(false));
    };

    const fetchSupplierByArea = async (supplier: string, year: string) => {
        // setIsLoading(true);
        setChartLoading(true);
        await axios.post(organisationHostname + "organisation/" + organisationId + "/spend/supplier-by-area", {
            supplier: supplier === "All" ? "%" : supplier,
            year: year === "All" ? "%" : ['%', year, '%'].join("")

        })
            .then(r => {
                setSupplierByArea(r.data);
            })
            .catch(error => console.error(error.message))
            .finally(() => setChartLoading(false));
    };

    const fetchTop10Suppliers = async (expenseArea: string, year: string) => {
        // setIsLoading(true);       
        await axios.post(organisationHostname + "organisation/" + organisationId + "/spend/top-ten-suppliers", {
            expenseArea: expenseArea === "All" ? "%" : expenseArea,
            year: year === "All" ? "%" : ['%', year, '%'].join("")

        })
            .then(r => {
                setTop10Suppliers(r.data);
            })
            .catch(error => console.error(error.message))
    };

    useEffect(() => {
        fetchSupplierOverTime(selectedExpenseType, selectedExpenseArea);
    }, []);

    useEffect(() => {
        fetchSupplierOverTime(selectedExpenseType, selectedExpenseArea);
    }, [selectedExpenseArea, selectedExpenseType])

    useEffect(() => {
        fetchSupplierByArea(selectedSupplier, selectedYear)
    }, [selectedSupplier, selectedYear])

    useEffect(() => {
        fetchTop10Suppliers(selectedExpenseArea, selectedYear);
    }, [selectedExpenseArea, selectedYear])

    const getDate = (val: any) => {
        if (val instanceof Date) {
            return val;
        }

        return new Date(val);
    }

    useEffect(() => {
        setSupplierOverTimeData(supplierOverTime.map(val =>
        ({
            x: getDate(val.postedDate).getMonth(),
            y: val.spendSum,
            year: getDate(val.postedDate).getFullYear()
        } as LineSeriesPoint)
        ))
    }, [supplierOverTime])

    useEffect(() => {
        setSupplierByAreaData(supplierByArea.map(val =>
        ({
            x: getDate(val.postedDate).getMonth(),
            y: val.spendSum,
            expenseArea: val.expenseArea
        } as LineSeriesPoint)
        ))
    }, [supplierByArea])

    return (
        <PublicSectorSpendContext.Provider
            value={{
                expenseAreaOptions,
                setExpenseAreaOptions,
                expenseTypeOptions,
                setExpenseTypeOptions,
                supplierOptions,
                setSupplierOptions,
                yearOptions,
                setYearOptions,
            }}>
            <Container fluid style={{ marginTop: 15, marginRight: "auto", paddingLeft: 0 }}>
                <Row className="" style={{ marginLeft: 0, marginRight: 0 }}>
                    <div style={{ marginLeft: "auto", marginRight: "auto" }} className="iq-button-group">
                        <button className={!spendView || spendView === SpendView.SUPPLIERS_OVER_TIME ? "selected-inverted" : ""}
                            onClick={(e) => { updateView(SpendView.SUPPLIERS_OVER_TIME) }}>
                            Suppliers over time
                        </button>
                        <button className={spendView === SpendView.SUPPLIERS_BY_AREA ? "selected-inverted" : ""}
                            onClick={(e) => { updateView(SpendView.SUPPLIERS_BY_AREA) }}>
                            Suppliers by area
                        </button>
                        <button className={spendView === SpendView.TOP_10_SUPPLIERS ? "selected-inverted" : ""}
                            onClick={(e) => { updateView(SpendView.TOP_10_SUPPLIERS) }}>
                            Top 10 suppliers
                        </button>
                    </div>
                </Row>
                {(!spendView || spendView === SpendView.SUPPLIERS_OVER_TIME) &&
                    <>
                        <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 35, marginTop: 10 }}>
                            <form>
                                <div className="ps-spend-filter-form">
                                    <div className="filter">
                                        <span className="title">Expense Area</span>
                                        <PublicSectorSpendInfiniteSelect
                                            filterType={FilterType.EXPENSE_AREA}
                                            selected={selectedExpenseArea}
                                            setSelected={setSelectedExpenseArea}
                                            placeholder={"Select desired expense area"}
                                            selectKey={"expense-area-multiselect"}
                                            optionField={"expenseArea"}
                                        />
                                    </div>
                                    <div className="filter">
                                        <span className="title">Supplier</span>
                                        <PublicSectorSpendInfiniteSelect
                                            filterType={FilterType.SUPPLIER}
                                            selected={selectedSupplier}
                                            setSelected={setSelectedSupplier}
                                            placeholder="Select desired supplier"
                                            selectKey="supplier-multiselect"
                                            optionField={"supplier"}
                                        />
                                    </div>
                                </div>
                            </form>


                            {chartLoading && <Spinner style={{ display: "block", marginLeft: "auto", marginRight: "auto", marginTop: "15px", height: "1.5rem", width: "1.5rem" }}
                                animation="border"
                                variant="primary" />}
                        </div>

                        <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 35, marginTop: 10, height: "650px" }}>
                            <DiscreteColorLegend
                                orientation={"horizontal"}
                                items={Array.from(new Set(supplierOverTimeData.map(val => val.year))).sort((a: number, b: number) => a - b).map((item, index) => { return { title: item, strokeWidth: 25 } })} />

                            <AutoSizer>
                                {({ height, width }) => (
                                    <XYPlot width={width}
                                            margin={{left: 50, right: 30}}
                                            height={height - 50}
                                            xType={"linear"}>
                                        <HorizontalGridLines />
                                        <VerticalGridLines />
                                        <XAxis tickFormat={val => {
                                            if (Math.round(val) === val) {
                                                return getMonthName(val);
                                            }

                                            return ""
                                        }} title="Date" />
                                        <YAxis title="Spend" tickFormat={val => UK.format('$,.3s')(val).replace(/G/, "B")} />
                                        {Array.from(new Set(supplierOverTimeData.map(val => val.year))).map(year =>
                                        (<LineMarkSeries onValueMouseOver={(v: any) => {
                                            setHover(v);
                                        }}
                                            onValueMouseOut={(v: any) => {
                                                setHover(null);
                                            }}
                                            data={supplierOverTimeData.filter(val => val.year === year).sort((val1, val2) => val1.x - val2.x)}
                                        // data={[{ x: 1, y: 10 }, { x: 2, y: 4 }, { x: 4, y: 2 }, { x: 5, y: 15 }]}

                                        />)
                                        )
                                        }
                                        {hover && (
                                            <Hint value={hover}>
                                                <div className="chart-hint">
                                                    <div className="x">{getMonthName(hover.x)}</div>
                                                    <div className="y">{UK.format('$,.3s')(hover.y).replace(/G/, "B")}</div>
                                                </div>
                                            </Hint>
                                        )}
                                    </XYPlot>

                                )}
                            </AutoSizer>
                        </div>
                    </>
                }
                {spendView === SpendView.SUPPLIERS_BY_AREA &&
                    <>
                        <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 35, marginTop: 10 }}>
                            <form>
                                <div className="ps-spend-filter-form">
                                    <div className="filter">
                                        <span className="title">Supplier</span>
                                        <PublicSectorSpendInfiniteSelect
                                            filterType={FilterType.SUPPLIER}
                                            selected={selectedSupplier}
                                            setSelected={setSelectedSupplier}
                                            placeholder="Select desired supplier"
                                            selectKey="supplier-multiselect"
                                            optionField={"supplier"}
                                        />
                                    </div>
                                    <div className="filter">
                                        <span className="title">Year</span>
                                        <PublicSectorSpendInfiniteSelect
                                            filterType={FilterType.POST_DATE}
                                            selected={selectedYear}
                                            setSelected={setSelectedYear}
                                            placeholder={"Select desired year"}
                                            selectKey="year-multiselect"
                                            optionField={"year"}
                                        />
                                    </div>
                                </div>
                            </form>


                            {chartLoading && <Spinner style={{ display: "block", marginLeft: "auto", marginRight: "auto", marginTop: "15px", height: "1.5rem", width: "1.5rem" }}
                                animation="border"
                                variant="primary" />}
                        </div>

                        <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 35, marginTop: 10, height: "650px" }}>
                            <DiscreteColorLegend
                                orientation={"horizontal"}
                                items={Array.from(new Set(supplierByAreaData.map(val => val.expenseArea))).map((item, index) => { return { title: item, strokeWidth: 25 } })} />

                            <AutoSizer>
                                {({ height, width }) => (
                                    <XYPlot margin={{left: 50, right: 30}} width={width} height={height - 50} xType={"ordinal"} stackBy="y">
                                        <VerticalGridLines />
                                        <HorizontalGridLines />
                                        <XAxis tickFormat={val => { return getMonthName(val) }} title="Date" />
                                        <YAxis title="Spend" tickFormat={val => UK.format('$,.3s')(val).replace(/G/, "B")} />
                                        {Array.from(new Set(supplierByAreaData.map(val => val.expenseArea))).map(expenseArea =>
                                            <VerticalBarSeries barWidth={0.3} onValueMouseOver={(v: any) => {
                                                setHover(v);
                                            }}
                                                onValueMouseOut={(v: any) => {
                                                    setHover(null);
                                                }}
                                                data={supplierByAreaData.filter(val => val.expenseArea === expenseArea).sort((val1, val2) => (val1.x as number) - (val2.x as number))}
                                            />
                                        )}
                                        {hover && (
                                            <Hint value={hover}>
                                                <div className="chart-hint">
                                                    <div className="x">{getMonthName(hover.x)}</div>
                                                    <div className="y">{UK.format('$,.3s')(hover.y).replace(/G/, "B")}</div>
                                                    <div className="y">{hover.expenseArea}</div>
                                                </div>
                                            </Hint>
                                        )}
                                    </XYPlot>

                                )}
                            </AutoSizer>
                        </div>
                    </>
                }
                {spendView === SpendView.TOP_10_SUPPLIERS &&
                    <>
                        <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 35, marginTop: 10 }}>
                            <form>
                                <div className="ps-spend-filter-form">
                                    <div className="filter">
                                        <span className="title">Year</span>
                                        <PublicSectorSpendInfiniteSelect
                                            filterType={FilterType.POST_DATE}
                                            selected={selectedYear}
                                            setSelected={setSelectedYear}
                                            placeholder={"Select desired year"}
                                            selectKey={"year-multiselect"}
                                            optionField={"year"}
                                        />
                                    </div>
                                    <div className="filter">
                                        <span className="title">Expense Area</span>
                                        <PublicSectorSpendInfiniteSelect
                                            filterType={FilterType.EXPENSE_AREA}
                                            selected={selectedExpenseArea}
                                            setSelected={setSelectedExpenseArea}
                                            placeholder={"Select desired expense area"}
                                            selectKey={"expense-area-multiselect"}
                                            optionField={"expenseArea"}
                                        />
                                    </div>
                                </div>
                            </form>


                            {chartLoading && <Spinner style={{ display: "block", marginLeft: "auto", marginRight: "auto", marginTop: "15px", height: "1.5rem", width: "1.5rem" }}
                                animation="border"
                                variant="primary" />}

                            <Table style={{ backgroundColor: "white", width: "100%", marginTop: 15, marginLeft: "auto", marginRight: "auto" }} striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Supplier</th>
                                        <th>Spend</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {top10Suppliers.map((entry, index) => (
                                        <tr key={index}>
                                            <td className="pt-3">
                                                {entry.supplierId ? (
                                                    <a className="iqx-link" onClick={() => history.push(`/organisation/${entry.supplierId}`)}>
                                                        {entry.supplier}
                                                    </a>
                                                ) : (
                                                    <>{entry.supplier}</>
                                                )}
                                            </td>
                                            <td className="pt-3">{UK.format('$,.3s')(entry.spendSum).replace(/G/, "B")}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </>
                }
            </Container>
        </PublicSectorSpendContext.Provider>
    );
};

export default PublicSectorSpend;
