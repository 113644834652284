import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Card} from "react-bootstrap";
import axios from "axios";
import {socialHostName} from "../../../../utils/Configuration";
import {TwitterUser} from "../../../social/TwitterFeed";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

export interface TwitterUserStats {
    id: string;
    tweets: number;
    likes: number;
    retweets: number;
    tweetRate: number;
    likesPerTweet: number;
    timesRetweeted: number;
    retweetsPerTweet: number;
    following: number;
    followers: number;
    timesAccessed: number;
    accountCreationDate: Date;
}

interface Metric {
    metric: string;
    value: number;
}

interface TwitterStatisticsProps {
    user: TwitterUser
}

const generateStatsChart = (chartId: string,
                            data: Array<Metric>) => {
    if (data.map(d => d.value).every(v => v === undefined || v === null)) return;

    let chart = am4core.create(chartId, am4charts.XYChart);

    chart.data = data;
    chart.paddingRight = 20;

    let catAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    catAxis.dataFields.category = "metric";
    catAxis.renderer.grid.template.location = 0;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (valueAxis !== undefined && valueAxis.tooltip !== undefined) {
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;
    }

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.name = "Test";
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "metric";
    series.tooltipText = "{valueY.value}";
    chart.cursor = new am4charts.XYCursor();

    return () => {
        chart.dispose();
    };
}

const TwitterStatistics: React.FC<TwitterStatisticsProps> = ({user}) => {

    const [stats, setStats] = useState<TwitterUserStats>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        const fetchUserStats = async () => {
            setLoading(true);
            await axios.get(`${socialHostName}twitter/${user.twitterId}/stats`)
                .then(r => {
                    setStats(r.data);
                    setError(false);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };
        if (user) fetchUserStats();
    }, [user]);

    useLayoutEffect(() => {
        if (stats) {
            const countsData: Array<Metric> = [
                {metric: "Tweets", value: stats.tweets},
                {metric: "Retweets", value: stats.retweets},
                {metric: "Likes", value: stats.likes}
            ];
            generateStatsChart("countsChart", countsData)
            const ratesData: Array<Metric> = [
                {metric: "Likes/Tweet", value: stats.likesPerTweet},
                {metric: "Retweets/Tweet", value: stats.retweetsPerTweet}
            ];
            generateStatsChart("ratesChart", ratesData);
        }
    }, [stats]);

    let chartStyle = {width: "100%", height: 200};
    return (
        <div className="mb-3">
            <Card>
                <Card.Body>
                    <div className="mb-2 organisation-card-label">
                        X Statistics
                    </div>
                    <div id="countsChart" style={chartStyle}/>
                    <div id="ratesChart" style={chartStyle}/>
                </Card.Body>
            </Card>
        </div>
    )
}

export default TwitterStatistics;