import React, {useContext, useState} from "react";
import PublicSectorContext, {CCSFilters} from "../../../context/PublicSectorContext";
import {Col, Form, Modal, Row} from "react-bootstrap";
import translate from "../../../i18n/translate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faSave} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import {SelectOption} from "../../news/GlobalNews";
import CCSFrameworkIdsAndName from "../../../model/publicsector/CCSFrameworkIdsAndName";

const CCSFrameworkFilters: React.FC = () => {
    const {ccsFilters, setCCSFilters, frameworkIds, frameworkIdsAndName} = useContext(PublicSectorContext);
    const [searchTerm, setSearchTerm] = useState<string>(ccsFilters?.searchTerm);
    const [selectedFrameworkIds, setSelectedFrameworkIds] = useState<CCSFrameworkIdsAndName[]>(ccsFilters?.frameworkIdsAndName || []);
    const updateFrameworkIds = (e: SelectOption[]) => {
        if (e) {
            let selection: CCSFrameworkIdsAndName[] = e.map(opt => ({
                id: opt.value,
                name: opt.label
            }));

            setSelectedFrameworkIds(selection);
        }
    };

    const [startDateTo, setStartDateTo] = useState<string>(ccsFilters?.startDateTo);
    const [startDateFrom, setStartDateFrom] = useState<string>(ccsFilters?.startDateFrom);
    const [endDateTo, setEndDateTo] = useState<string>(ccsFilters?.endDateTo);
    const [endDateFrom, setEndDateFrom] = useState<string>(ccsFilters?.endDateFrom);

    const applyFilters = () => {
        let filters = {
            frameworkIds: selectedFrameworkIds.map(framework => framework.id),
            searchTerm: searchTerm,
            startDateFrom: startDateFrom,
            startDateTo: startDateTo,
            endDateFrom: endDateFrom,
            endDateTo: endDateTo
        } as CCSFilters;

        setCCSFilters(filters);
    }

    const clearFilters = () => {
        setCCSFilters({} as CCSFilters);
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Filters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
                    <div>
                        <div className="organisation-card-label">
                            Framework ID
                        </div>
                        <Select
                                options={frameworkIdsAndName.map(id => ({value: id.id, label: (id.id + " | " + id.name)}))}
                                isClearable
                                isMulti
                                defaultValue={selectedFrameworkIds?.map(o => ({label: o.id, value: o.name}))}
                                onChange={(e: any) => updateFrameworkIds(e)}/>
                    </div>

                    <div>
                        <div className="organisation-card-label">Search Term</div>
                        <Form.Control name="key" type="text"
                                      placeholder={translate("advancedsearch.search")}
                                      value={searchTerm}
                                      onChange={(e: any) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="mb-2">
                        <div className="organisation-card-label">
                            Start Date
                        </div>
                        <Row>
                            <Col className="col-5">
                                <input type="date" className="form-control" value={startDateFrom} onChange={(e: any) => setStartDateFrom(e.target.value)}/>
                            </Col>
                            <Col className="text-center pt-2 col-1">
                                <span>{translate("advancedsearch.to")}</span>
                            </Col>
                            <Col className="col-5">
                                <input type="date" className="form-control" value={startDateTo} onChange={(e: any) => setStartDateTo(e.target.value)}/>
                            </Col>
                        </Row>
                    </div>
                    <div className="mb-2">
                        <div className="organisation-card-label">
                            End Date
                        </div>
                        <Row>
                            <Col className="col-5">
                                <input type="date" className="form-control" value={endDateFrom} onChange={(e: any) => setEndDateFrom(e.target.value)}/>
                            </Col>
                            <Col className="text-center pt-2 col-1">
                                <span>{translate("advancedsearch.to")}</span>
                            </Col>
                            <Col className="col-5">
                                <input type="date" className="form-control" value={endDateTo} onChange={(e: any) => setEndDateTo(e.target.value)}/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="iqx-button secondary md-size me-3"
                        disabled={selectedFrameworkIds?.length === 0 && !searchTerm && (
                            !ccsFilters?.startDateTo &&
                            !ccsFilters?.startDateFrom &&
                            !ccsFilters?.endDateTo &&
                            !ccsFilters?.endDateFrom
                        )}
                        onClick={clearFilters}>
                    Clear Filters
                    <FontAwesomeIcon icon={faFilter} className="ms-2"/>
                </button>

                <button className="iqx-button primary md-size" onClick={applyFilters}>
                    Apply
                    <FontAwesomeIcon icon={faSave} className="me-1 ms-2" />
                </button>
            </Modal.Footer>
        </>
    );
};

export default CCSFrameworkFilters;