import React from "react";
import DashboardLists from "./DashboardLists";
import DashboardFollowed from "./DashboardFollowed";
import DashboardTwitter from "./DashboardTwitter";
import DashboardGlobalNews from "./DashboardGlobalNews";
import DashboardFollowedNews from "./DashboardFollowedNews";
import DashboardUserProfile from "./DashboardUserProfile";
import DashboardFollowedFilingHistory from "./DashboardFollowedFilingHistory";
import DashboardContracts from "./DashboardContracts";
import DashboardYoutube from "./DashboardYoutube";
import DashboardFollowedYearEnd from "./DashboardFollowedYearEnd";
import DashboardFilteredTwitter from "./DashboardFilteredTwitter";
import DashboardFilteredYoutube from "./DashboardFilteredYoutube";
import DashboardWelcome from "./DashboardWelcome";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faYoutube} from "@fortawesome/free-brands-svg-icons";
import {
    faCalendar,
    faGavel,
    faHistory,
    faList,
    faNewspaper,
    faRss,
    faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import {FaXTwitter} from "react-icons/fa6"


export default class ItemType {
    public static readonly TWITTER = new ItemType("0", "dashboard.twitter.title", <FaXTwitter/>, DashboardTwitter, true);
    public static readonly NEWS = new ItemType("1", "dashboard.globalnews.title", <FontAwesomeIcon icon={faNewspaper} />, DashboardGlobalNews);
    public static readonly FOLLOWED_COMPANIES = new ItemType("2", "dashboard.followedorganisations.title", <FontAwesomeIcon icon={faRss} />, DashboardFollowed);
    public static readonly LISTS = new ItemType("3", "lists.title", <FontAwesomeIcon icon={faList} />, DashboardLists);
    public static readonly NEWS_FOLLOWED = new ItemType("4", "dashboard.followednews.title", <FontAwesomeIcon icon={faNewspaper} />, DashboardFollowedNews);
    public static readonly USER_PROFILE = new ItemType("5", "dashboard.profile.title", <FontAwesomeIcon icon={faUserCircle}/>, DashboardUserProfile);
    public static readonly FILING_HISTORY = new ItemType("6", "dashboard.filinghistory.title",  <FontAwesomeIcon icon={faHistory}/>, DashboardFollowedFilingHistory);
    public static readonly CONTRACTS = new ItemType("7", "contracts.title",  <FontAwesomeIcon icon={faGavel}/>, DashboardContracts);
    public static readonly YOUTUBE = new ItemType("8", "dashboard.videos.title",  <FontAwesomeIcon icon={faYoutube}/>, DashboardYoutube);
    public static readonly FOLLOWED_COMPANIES_YEAR_END = new ItemType("9", "dashboard.followedorganisationsyearend.title",  <FontAwesomeIcon icon={faCalendar}/>, DashboardFollowedYearEnd);
    public static readonly TWITTER_FILTERED = new ItemType("10", "dashboard.twitter.filteredTitle", <FaXTwitter/>, DashboardFilteredTwitter, true);
    public static readonly YOUTUBE_FILTERED = new ItemType("11", "dashboard.videos.filteredTitle", <FontAwesomeIcon icon={faYoutube}/>, DashboardFilteredYoutube);
    public static readonly WELCOME = new ItemType("12", "dashboard.welcome.title", "", DashboardWelcome, true);

    public static readonly ALL_TYPES: Record<string, ItemType> = {
        // "0": ItemType.TWITTER,
        "1": ItemType.NEWS,
        "4": ItemType.NEWS_FOLLOWED,
        "2": ItemType.FOLLOWED_COMPANIES,
        "3": ItemType.LISTS,
        "5": ItemType.USER_PROFILE,
        "6": ItemType.FILING_HISTORY,
        "7": ItemType.CONTRACTS,
        "8": ItemType.YOUTUBE,
        "9": ItemType.FOLLOWED_COMPANIES_YEAR_END,
        // "10": ItemType.TWITTER_FILTERED,
        "11": ItemType.YOUTUBE_FILTERED,
        "12": ItemType.WELCOME
    };

    private constructor(
        public readonly key: string,
        public readonly i18nKey: string,
        public readonly icon: React.ReactNode,
        public readonly element: React.FC,
        public readonly hidden?: boolean
    ) { }


}
