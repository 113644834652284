import React, {ChangeEvent} from "react";
import translate from "../../i18n/translate";
import {Form} from "react-bootstrap";

interface Props {
    setSortBy: (e: ChangeEvent<HTMLSelectElement>) => void
}

const ContactSortSelect: React.FC<Props> = (props: Props) => {
    return (
        <Form.Select className="form-control" onChange={props.setSortBy}>
            <option disabled={true} selected={true}>{translate("contacts.sort.sortby")}</option>
            <option value="last_modified_date,ASC">{translate("contacts.sort.datediscoveredasc")}</option>
            <option value="last_modified_date,DESC">{translate("contacts.sort.datediscovereddesc")}</option>
            <option value="last_name,ASC">{translate("contacts.sort.lastnameasc")}</option>
            <option value="last_name,DESC">{translate("contacts.sort.lastnamedesc")}</option>
            <option value="first_name,ASC">{translate("contacts.sort.firstnameasc")}</option>
            <option value="first_name,DESC">{translate("contacts.sort.firstnamedesc")}</option>
            <option value="job_title,ASC">{translate("contacts.sort.jobtitleasc")}</option>
            <option value="job_title,DESC">{translate("contacts.sort.jobtitledesc")}</option>
        </Form.Select>
    );
};

export default ContactSortSelect;
