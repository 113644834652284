import React, {useContext, useEffect, useState} from "react";
import OrganisationContext from "../../../context/organisation/OrganisationContext";
import CaseStudyCard from "./CaseStudyCard";
import Masonry from "react-masonry-css";
import CaseStudy from "../../../model/organisation/CaseStudy";
import "./OrganisationCaseStudies.css";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {faYoutube} from "@fortawesome/free-brands-svg-icons";
import YoutubeVideo from "../../../model/organisation/social/YoutubeVideo";
import YoutubeCaseStudyCard from "./YoutubeCaseStudyCard";
import translate from "../../../i18n/translate";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import IqLoadingIcon from "../../common/IqLoadingIcon";

enum Tabs {
    PDF = "pdf",
    YOUTUBE = "youtube"
}

const OrganisationCaseStudies: React.FC = () => {
    const {
        caseStudiesResponse,
        setCaseStudiesResponse,
        youtubeCaseStudies,
        setYoutubeCaseStudies,
        caseStudySearchTerm,
        setCaseStudySearchTerm,
        caseStudiesState,
        setCaseStudiesState,

    } = useContext(OrganisationContext);

    const [tab, setTab] = useState<string>();

    useEffect(() => {
        setTab(caseStudiesResponse.length > 0 ? Tabs.PDF : Tabs.YOUTUBE);
    }, []);

    const debounced = AwesomeDebouncePromise(setCaseStudySearchTerm, 300);
    const handleSearchQueryChange = async (e: any) => {
        e.persist();
        await debounced(e.target.value);
    };

    return (
        <div style={{
            paddingTop: 10,
            backgroundColor: "white",
            borderRadius: "0.45rem",
            padding: 15,
            marginTop: 10
        }}>
            {(caseStudiesResponse.length > 0 && youtubeCaseStudies.length > 0) && (
                <Row className="" style={{ marginLeft: 0, marginRight: 0, marginTop: 15, marginBottom: 15 }}>
                    <div style={{ marginLeft: "auto", marginRight: "auto" }} className="iq-button-group">
                        <button className={tab === Tabs.PDF ? "selected-inverted" : ""}
                                onClick={(e) => { setTab(Tabs.PDF) }}>
                            <FontAwesomeIcon icon={faFilePdf} />
                        </button>
                        <button className={tab === Tabs.YOUTUBE ? "selected-inverted" : ""}
                                onClick={(e) => { setTab(Tabs.YOUTUBE) }}>
                            <FontAwesomeIcon icon={faYoutube} />
                        </button>
                    </div>
                </Row>
            )}

            {tab === Tabs.PDF ? (
                <>
                    <Row className="mb-3">
                        <Col lg={{ span: 3, offset: 9 }} md={{ span: 3, offset: 9 }} sm={{ span: 4, offset: 8 }} xs={{ span: 6, offset: 6 }}>
                            <input className="form-control float-end"
                                   type="text"
                                   placeholder={translate("advancedsearch.search")}
                                   onKeyUp={handleSearchQueryChange}
                            />
                        </Col>
                    </Row>

                    {caseStudiesState.loading ? <IqLoadingIcon /> : (
                        <Masonry breakpointCols={3}
                                 className="cs-grid"
                                 columnClassName="cs-grid-column">
                            {caseStudiesResponse.map((caseStudy: CaseStudy, index: number) => (
                                <div key={`key${index}`} className="mb-3">
                                    <CaseStudyCard caseStudy={caseStudy} />
                                </div>
                            ))}
                        </Masonry>
                    )}
                </>
            ) : (
                <Masonry breakpointCols={3}
                         className="cs-grid"
                         columnClassName="cs-grid-column">
                    {youtubeCaseStudies.map((caseStudy: YoutubeVideo, index: number) => (
                        <div key={`key${index}`} className="mb-3">
                            <YoutubeCaseStudyCard caseStudy={caseStudy} />
                        </div>
                    ))}
                </Masonry>
            )}
        </div>
    );
};

export default OrganisationCaseStudies;