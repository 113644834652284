import React, {useEffect, useState} from "react";
import {contractsHostName} from "../../../utils/Configuration";
import axios from "axios";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import Funding from "../../../model/publicsector/Funding";
import {Alert, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import translate from "../../../i18n/translate";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import "./PublicResearchFundingView.css";
import DateFormatter from "../../../utils/formatters/DateFormatter";
import ResearchOrganisation from "./ResearchOrganisation";
import {format} from "d3-format";

const PublicResearchFundingView: React.FC = () => {
    let history = useHistory();
    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/public-sector/funding/project/:projectId',
        exact: false,
        strict: false
    });
    const organisationPath: any = matchPath(location.pathname, {
        path: '/organisation/:organisationId/public-sector-organisation/funding/project/:projectId',
        exact: false,
        strict: false
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [funding, setFunding] = useState<Funding>();
    useEffect(() => {
        const fetchFunding = async (projectId: number) => {
            setLoading(true);
            setError(false);
            await axios.get(`${contractsHostName}public-research-funding/${projectId}`)
                .then(r => setFunding(r.data))
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }



        const projectId = childMatchedPath?.params.projectId;
        const organisationFrameworkId = organisationPath?.params.projectId;
        if (projectId) {
            fetchFunding(projectId);
        } else if (organisationFrameworkId) {
            fetchFunding(organisationFrameworkId)
        }
    }, []);

    const getFundingParticipants = () => {

        let participants =
            funding?.organisations
                .filter(o => o.role === "PARTICIPANT" || o.role === "COLLABORATOR")
                .sort((o, z) => o.role.localeCompare(z.role));

        return (
            <>
                {participants?.map(participant => (
                    <div className="project-info">
                        <ResearchOrganisation organisation={participant} title={participant.role} />
                    </div>
                ))}
            </>
        )
    }

    return (
        <div style={{
            paddingTop: "10px",
            backgroundColor: "white",
            borderRadius: "0.45rem",
            padding: 25,
            marginTop: 10
        }}>
            <Row className="mb-4">
                <Col className="pt-2">
                    <div className="organisation-card-label">Public Research Funding</div>
                </Col>
                <Col className="pull-right">
                    <button className="iqx-button md-size pull-right" onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                        <span className="me-1">Back</span>
                    </button>
                </Col>
            </Row>

            {error && <Alert variant="danger">{translate("errors.loading")}</Alert>}
            {loading ? <IqLoadingIcon /> : (
                <>
                    <div className="organisation-card">
                        <div className="title">{funding?.title}</div>
                        <div className="project-info">
                            <div className="info-entry w-33">
                                <div className="organisation-card-label">Project ID</div>
                                <div>{funding?.projectId}</div>
                            </div>
                            <div className="info-entry w-33">
                                <div className="organisation-card-label">Project Reference</div>
                                <div>{funding?.projectReference}</div>
                            </div>
                            <div className="info-entry w-33">
                                <div className="organisation-card-label">Status</div>
                                <div>{funding?.status}</div>
                            </div>
                        </div>

                        {funding && (
                            <div className="project-info mt-3">
                                <div className="info-entry w-33">
                                    <div className="organisation-card-label">Start Date</div>
                                    <div>{DateFormatter.formatDate(funding.startDate)}</div>
                                </div>
                                <div className="info-entry w-33">
                                    <div className="organisation-card-label">End Date</div>
                                    <div>{DateFormatter.formatDate(funding.endDate)}</div>
                                </div>
                                <div className="info-entry w-33">
                                    <div className="organisation-card-label">Value</div>
                                    <div>£{format(",.0f")(funding?.fundingValue)}</div>
                                </div>
                            </div>
                        )}

                        <div className="project-info mt-4">
                            {(funding && funding.leadResearchOrganisation) && <ResearchOrganisation organisation={funding!.leadResearchOrganisation!} title="Lead Research Organisation" />}
                        </div>
                        <div className="project-info">
                            {(funding && funding.funderOrganisation) && <ResearchOrganisation organisation={funding!.funderOrganisation!} title="Funder" />}
                        </div>
                        {getFundingParticipants()}
                    </div>

                    {funding?.abstract &&
                        <div className="organisation-card mt-4">
                            <div className="organisation-card-label">
                                Abstract
                            </div>
                            <div className="mt-2">{funding?.abstract}</div>

                            {(funding.researchTopics && funding.researchTopics.length > 0) && (
                                <>
                                    <div className="organisation-card-label mt-4">
                                        Research Topics
                                    </div>
                                    <div className="project-info">
                                        {funding.researchTopics.map(topic => <span className="badge badge-primary badge-tag">{topic}</span>)}
                                    </div>
                                </>
                            )}

                            {(funding.researchSubjects && funding.researchSubjects.length > 0) && (
                                <>
                                    <div className="organisation-card-label mt-4">
                                        Research Subjects
                                    </div>
                                    <div className="project-info">
                                        {funding.researchSubjects.map(topic => <span className="badge badge-primary badge-tag">{topic}</span>)}
                                    </div>
                                </>
                            )}
                        </div>
                    }
                </>
            )}
        </div>
    );
};

export default PublicResearchFundingView;