import React, {useEffect, useState} from "react";
import {Image} from "react-bootstrap";
import {UserResponse} from "../../model/users/UserResponse";
import DefaultAvatar from "../../utils/formatters/DefaultAvatar";
import {OrganisationUser} from "../teams/edit/EditTeam";
import {TeamUser} from "../teams/Teams";

interface UserAvatar {
    user: TeamUser | OrganisationUser | UserResponse
    width: string,
    height: string,
    marginRight?: number
}
const UserAvatar: React.FC<UserAvatar> = (props: UserAvatar) => {
    const [src, setSrc] = useState<string>(props.user.imageUrl ? props.user.imageUrl : DefaultAvatar.AVATAR_URL);

    useEffect(() => {
        setSrc(props.user.imageUrl ? props.user.imageUrl : DefaultAvatar.AVATAR_URL);
    }, [props.user])
    return (
        <Image style={{ height: props.width, width: props.height, objectFit: "cover", marginRight: props.marginRight }}
            title={props.user.login}
            src={src}
            onError={() => setSrc(DefaultAvatar.AVATAR_URL)}
            roundedCircle />
    )
};

export default UserAvatar;