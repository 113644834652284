import React, {useContext, useEffect, useState} from "react";
import DomainAlias from "../../../model/organisation/DomainAlias";
import axios from "axios";
import {organisationHostname} from "../../../utils/Configuration";
import UserOrganisationContext from "../../../context/organisation/OrganisationContext";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {Col, Form, Row} from "react-bootstrap";
import {faSave, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Ticker {
    id: {
        organisationId: string
        tickers: string
    }
}
const TickerEditor: React.FC = () => {

    const {organisation, setOrganisation} = useContext(UserOrganisationContext);

    const [tickers, setTickers] = useState<Ticker[]>([]);
    const [loadingTickers, setLoadingTickers] = useState<boolean>(false);

    useEffect(() => {
        const fetchTickers = async () => {
            setLoadingTickers(true);
            await axios.get(organisationHostname + "organisation/" + organisation.id + "/tickers")
                .then(r => setTickers(r.data))
                .finally(() => setLoadingTickers(false));
        };

        fetchTickers();
    }, []);

    const [newTicker, setNewTicker] = useState<string>();
    const updateNewTicker = (event: any) => {
        setNewTicker(event.target.value);
    };

    const saveNewTicker = () => {
        axios.put(organisationHostname + "organisation/" + organisation.id + "/tickers", {ticker: newTicker})
            .then(r => {
                let data = [
                    ...tickers,
                    r.data
                ];

                setTickers(data);
            })
            .finally(() => setNewTicker(""));
    };

    const deleteTicker = (ticker: string) => {
        axios.delete(organisationHostname + "organisation/" + organisation.id + "/tickers/" + ticker)
            .then(() => {
                let filtered = tickers.filter(t => t.id.tickers !== ticker);
                setTickers(filtered);
            });
    };

    return (
        <div className="organisation-card">
            <div className="iq-tx-11 iq-tx-uppercase iq-tx-spacing-1 iq-tx-semibold iq-tx-color-02">
                Tickers (Stock Exchange)
            </div>
            <div>
                {loadingTickers ? <IqLoadingIcon /> : (
                    <div>
                        {tickers?.map((ticker: Ticker) => (
                            <Row key={ticker.id.tickers} className="mt-2">
                                <Col>
                                    <Form.Control readOnly={true} type="text" value={ticker.id.tickers} />
                                </Col>
                                <Col className="pt-1">
                                    <button className="iqx-button sm-size primary" onClick={() => deleteTicker(ticker.id.tickers)}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </button>
                                </Col>
                            </Row>
                        ))}

                        <Row className="mt-2">
                            <Col>
                                <Form.Control type="text" value={newTicker} onChange={updateNewTicker} />
                            </Col>
                            <Col className="pt-1">
                                <button className="iqx-button sm-size primary" onClick={() => saveNewTicker()} disabled={!newTicker}>
                                    <FontAwesomeIcon icon={faSave} />
                                </button>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TickerEditor;