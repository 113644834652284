import React, {useEffect} from "react";

export interface TwitterUser {
    id: number;
    twitterId: number;
    screenName: string;
    name: string;
    description: string;
    isProtected: boolean;
    isDirector: boolean;
    isVerified: boolean;
    bannerUrl: string;
    logoUrl: string;
    url: string;
    mostRecentTweetId: number;
    statusesCount: number;
    favouritesCount: number;
    followersCount: number;
    friendsCount: number;
    location: string;
    listedCount: number;
    lang: string;
    timeZone: string;
    utcOffset: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}

interface Props {
    twitterUser: TwitterUser,
    maxTweets: string
}

const TwitterFeed: React.FC<Props> = (props: Props) => {

    useEffect(() => {
        const anchor = document.createElement("a");
        anchor.setAttribute("class", "twitter-timeline");
        anchor.setAttribute("data-theme", "light");
        anchor.setAttribute("data-tweet-limit", props.maxTweets);
        anchor.setAttribute("data-chrome", "");
        anchor.setAttribute("href", "https://twitter.com/" + props.twitterUser.screenName);
        document.getElementsByClassName("twitter-embed")[0].appendChild(anchor);

        const script = document.createElement("script");
        script.setAttribute("src", "https://platform.twitter.com/widgets.js");
        document.getElementsByClassName("twitter-embed")[0].appendChild(script);
    }, []);

    return (
        <div className="twitter-embed">
        </div>
    )
};

export default TwitterFeed;
