import React, {useContext} from "react";
import OfficerAppointments from "./OfficerAppointments";
import OfficerContext from "../../context/officer/OfficerContext";
import {Button, Col, Row} from "react-bootstrap";
import {faLinkedin, faMedium, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OfficerInterest from "../../model/organisation/officer/OfficerInterest";
import "./Officer.css";
import OfficerAppointment from "../../model/organisation/officer/OfficerAppointment";

export const getOccupationOrRole = (appointment: OfficerAppointment) => {
    if (!appointment) {
        return "-";
    }

    if (appointment.occupation) {
        return appointment.occupation;
    }

    return appointment.role;
}

const Officer: React.FC = () => {
    const {officer, setOfficer, appointments, setAppointments} = useContext(OfficerContext);

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const getInterests = () => {
        if (!officer.interests) {
            return [];
        }

        return officer.interests
                    .sort((a: OfficerInterest, b: OfficerInterest) => {
                        return b.score - a.score;
                    })
                    .slice(0, 10);
    };

    const showContactDetails = () => {
        return hasSocials() || getLatestAppointment()?.email || officer.phoneNumber;
    };

    const hasSocials = () => {
        return officer.twitter || officer.medium || officer.linkedIn;
    };

    const getLatestAppointment = () => {
        return appointments?.sort(
            (a: OfficerAppointment, b: OfficerAppointment) => {
                let aMillis = new Date(a.appointmentDate).getTime();
                let bMillis = new Date(b.appointmentDate).getTime();
                return bMillis - aMillis;
            })[0];
    };

    const stripHtml = (bio: string) => {
        if(bio) {
            return bio.replace(/(<([^>]+)>)/gi, "").trim();
        }
    };

    const getDates = (birthDate: string) => {
        if (birthDate === null){
            return "";
        }
        let date = new Date(birthDate);
        if (monthNames[date.getMonth()] === undefined){
            return birthDate;
        }
        return monthNames[date.getMonth()] + "-"+ date.getFullYear();
    }

    const getIntroWidth = () => {
        let hasInterests = officer.interests?.length !== 0;
        if (hasInterests && showContactDetails()) {
            return "col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4";
        }

        if (!hasInterests && !showContactDetails()) {
            return "col-6 col-sm-6 col-md-9 col-lg-9 col-xl-9";
        }

        return "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6";
    };

    return (
        <div style={{paddingTop: 35}}>
            <Row className="mb-2">
                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                    <div className="organisation-card">
                        <div className="organisation-card-label">
                            About
                        </div>
                        <div className="organisation-card-text">
                            <div><b>Title:</b> {getOccupationOrRole(getLatestAppointment())}</div>
                            <div><b>DOB:</b> {getDates(officer.dateOfBirth)}</div>
                            <div><b>Nationality:</b> {officer.nationality}</div>
                            <div><b>Country of Residence:</b> {officer.countryOfResidence}</div>
                        </div>
                    </div>
                </div>
                {showContactDetails() && (
                    <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                        <div className="organisation-card">
                            <div className="organisation-card-label">
                                Contact Details
                            </div>
                            <div className="organisation-card-text">
                                {getLatestAppointment()?.email && (
                                    <div>
                                        <b>Email:</b>&nbsp;
                                        {getLatestAppointment()?.email}
                                    </div>
                                )}
                                {officer.phoneNumber && (
                                    <div>
                                        <b>Phone:</b>&nbsp;
                                        {officer.phoneNumber}
                                    </div>
                                )}
                                {hasSocials() && (
                                    <Row>
                                        {officer.twitter && (
                                            <Col>
                                                <Button className="social-link" variant="link" size="lg" href={`https://www.twitter.com/${officer.twitter}`}>
                                                    <FontAwesomeIcon icon={faTwitter} size="2x" />
                                                </Button>
                                            </Col>
                                        )}
                                        {officer.linkedIn && (
                                            <Col>
                                                <Button className="social-link" variant="link" size="lg" href={officer.linkedIn}>
                                                    <FontAwesomeIcon icon={faLinkedin} size="2x" />
                                                </Button>
                                            </Col>
                                        )}
                                        {officer.medium && (
                                            <Col>
                                                <Button className="social-link" variant="link" size="lg" href={officer.medium}>
                                                    <FontAwesomeIcon icon={faMedium} size="2x" />
                                                </Button>
                                            </Col>
                                        )}
                                    </Row>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {officer.interests?.length > 0 && (
                    <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                        <div className="organisation-card">
                            <div className="organisation-card-label">
                                Interests
                            </div>
                            <div className="organisation-card-text">
                                <Row className="justify-content-md-center">
                                    {getInterests().map(interest => (
                                            <div className="officer-interest">
                                                {interest.name}
                                            </div>
                                        )
                                    )}
                                </Row>
                            </div>
                        </div>
                    </div>
                )}
                {officer.biography && (
                    <div className={getIntroWidth()}>
                        <div className="organisation-card">
                            <div className="organisation-card-label">
                                Introduction
                            </div>
                            <div className="organisation-card-text">
                                {stripHtml(officer.biography)}
                            </div>
                        </div>
                    </div>
                )}
            </Row>
            <OfficerAppointments />

           {/* FR-978 hide twitter: https://iqblade.atlassian.net/jira/software/projects/FR/issues/FR-798 */}
           {/*<CommonFollowers/>*/}
        </div>
    )
};

export default Officer;
