

class DefaultAvatar {
    static AVATAR_URL = "https://iqblade-fe-assets.s3.eu-west-1.amazonaws.com/user_avatar.png";

    static displayDefaultAvatar(imageUrl: string) {
        if (imageUrl === null){
            return this.AVATAR_URL;
        }
        return imageUrl;
    }
}

export default DefaultAvatar;