import {faEdit, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import {Alert, Row, Table} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import TechStack from "../../model/organisation/TechStack";
import {organisationHostname} from '../../utils/Configuration';
import Pagination from '../../utils/PaginationHook';
import IqLoadingIcon from "../common/IqLoadingIcon";
import {getSort, getSortButton} from './OrganisationTechStack';
import './OrganisationTechStack.css';
import OrganisationTechStackFilters from "./OrganisationTechStackFilters";
import HoverToolTip from "../../utils/HoverToolTip";

interface TechStackFilterOptions {
    preferredCategories: string[],
    preferredVendors: string[],
    categories: string[],
    vendors: string[]
}

export enum Sort {
    VENDOR = "vendor",
    PRODUCT = "product",
    CATEGORY = "category",
    INTENSITY = "intensity",
    DATE_FIRST_VERIFIED = "dateFirstVerified",
    DATE_LAST_VERIFIED = "dateLastVerified"
}


const OrganisationTechStackFiltered: React.FC = () => {
    let { organisationId } = useParams<{organisationId: string}>();
    const searchQueryRef = useRef<string>();

    const [techStack, setTechStack] = useState<TechStack[]>([] as TechStack[]);
    const [loadingTechStackData, setLoadingTechStackData] = useState<boolean>(false);

    const [totalRecords, setTotalRecords] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const techPerPageLimit: number = 10;
    const [sort, setSort] = useState<string>(Sort.DATE_LAST_VERIFIED);
    const [desc, setDesc] = useState<boolean>(true);
    const [editFilters, setEditFilters] = useState<boolean>(false);

    const [error, setError] = useState<boolean>(false);


    const fetchTechStack = async () => {
        const springCurrentPage: number = currentPage - 1;

        setLoadingTechStackData(true);
        let requestParams = {
            params: {
                page: springCurrentPage,
                size: techPerPageLimit,
                sort: getSort(sort, desc),
                q: searchQueryRef.current
            }
        };

        let url = `${organisationHostname}organisation/${organisationId}/technology-filtered-stack`;
        await axios.get(url, requestParams)
            .then((response) => {
                const totalCount: number = Number(response.headers['x-total-count']);
                setTotalRecords(totalCount);
                setTechStack(response.data);
            })
            .catch(() => setError(true))
            .finally(() => setLoadingTechStackData(false));
    };

    useEffect(() => {
        fetchTechStack();
    }, [currentPage, sort, desc, editFilters]);


    return (
        <>
            {!editFilters ?
                <>
                    <div className="flex flex-row">
                        <div className="ms-auto">
                            <button style={{marginTop: 15}}
                                    onClick={() => {
                                        setEditFilters(true)
                                    }}
                                    type="button"
                                    className="filter-button"><span className="text">
                                Change filters</span><FontAwesomeIcon className="icon" icon={faEdit} color="#2F6FC3"
                                                                      size="1x"/>
                            </button>
                        </div>
                    </div>
                    {!loadingTechStackData
                        ?
                        totalRecords !== 0
                            ?
                            <>
                                <Row className="" style={{marginLeft: 0, marginRight: 0, paddingTop: 15}}>
                                    <div style={{
                                        paddingTop: "10px",
                                        backgroundColor: "white",
                                        borderRadius: "0.45rem",
                                        padding: 15,
                                        marginTop: 10,
                                        width: "100%"
                                    }}>
                                        <Table striped hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Vendor
                                                        {getSortButton(Sort.VENDOR, setDesc, desc, setSort, sort)}
                                                    </th>
                                                    <th>
                                                        Product
                                                        {getSortButton(Sort.PRODUCT, setDesc, desc, setSort, sort)}
                                                    </th>
                                                    <th>
                                                        Category
                                                        {getSortButton(Sort.CATEGORY, setDesc, desc, setSort, sort)}
                                                    </th>
                                                        <th>
                                                            Date First Verified
                                                            <HoverToolTip id={"date-first-hover"} tooltip={"This is the date we first identified this technology being used at this organisation"} tooltipPosition={"top"}>
                                                                <FontAwesomeIcon className="ms-2" icon={faInfoCircle} size="1x" color="#206fc3"/>
                                                            </HoverToolTip>
                                                            {getSortButton(Sort.DATE_FIRST_VERIFIED, setDesc, desc, setSort, sort)}
                                                        </th>
                                                        <th>
                                                            Date Last Verified
                                                            <HoverToolTip id={"date-last-hover"} tooltip={"This is the date we most recently saw this technology being used at this organisation"} tooltipPosition={"top"}>
                                                                <FontAwesomeIcon className="ms-2" icon={faInfoCircle} size="1x" color="#206fc3"/>
                                                            </HoverToolTip>
                                                            {getSortButton(Sort.DATE_LAST_VERIFIED, setDesc, desc, setSort, sort)}
                                                        </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {techStack.map(ts => (
                                                    <tr key={ts.id}>
                                                        <td>{ts.vendor}</td>
                                                        <td>{ts.product}</td>
                                                        <td>{ts.category}</td>
                                                        <td>{new Date(ts.dateFirstVerified).toDateString()}</td>
                                                        <td>{new Date(ts.dateLastVerified).toDateString()}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Row>
                                <Row className="" style={{ marginLeft: 0, marginRight: 0, marginTop: 15, float: "right" }}>
                                    <Pagination
                                        totalRecords={totalRecords}
                                        pageLimit={techPerPageLimit}
                                        pageRangeDisplayed={1}
                                        initPage={currentPage}
                                        onChangePage={setCurrentPage}
                                    />
                                </Row>
                            </>
                            :
                            <Alert style={{ marginTop: 25 }} variant="warning">
                                We don't have technology stack data for this organisation based on your current filter
                                preferences.
                            </Alert>
                        :
                        <IqLoadingIcon />
                    }
                </>
                :
                <OrganisationTechStackFilters editFilters={editFilters} setEditFilters={setEditFilters} />
            }

        </>
    );
}

export default OrganisationTechStackFiltered;