import React from "react";
import {Table} from "react-bootstrap";
import {TeamUser} from "../Teams";
import IqProgressBar from "../../common/IqProgressBar";
import translate from "../../../i18n/translate";
import UserAvatar from "../../common/UserAvatar";

interface Props {
    data: ConsumptionData[]
}

export interface ConsumptionData {
    user: TeamUser;
    consumed: number;
    capacity: number
}

const TeamDashboardTable: React.FC<Props> = (props: Props) => {
    const getPercentage = (item: ConsumptionData) => {
        let pct = (item.consumed / item.capacity) * 100;
        return Math.round(pct);
    };

    return (
        <Table striped hover>
            <thead>
                <th style={{ width: "25%" }}>{translate("teams.common.username")}</th>
                <th style={{ width: "62%" }}>&nbsp;</th>
                <th className="text-center" style={{ width: "13%" }}>{translate("teams.common.total")}</th>
            </thead>
            <tbody>
                {props.data.map((item: ConsumptionData) => (
                    <tr>
                        <td>
                            <UserAvatar user={item.user} height="40px" width="40px" marginRight={15} />
                            {item.user.firstName} {item.user.lastName}
                        </td>
                        <td>
                            <IqProgressBar now={getPercentage(item)} />
                        </td>
                        <td className="text-center">{item.consumed} / {item.capacity}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
};

export default TeamDashboardTable;
