import {faSort, faSortAmountDown, faSortAmountDownAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import React, {useContext, useEffect, useState} from 'react';
import {Container, Row} from 'react-bootstrap';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import UserOrganisationContext from "../../context/organisation/OrganisationContext";
import './OrganisationTechStack.css';
import OrganisationTechStackFiltered, {Sort as FilteredSort} from './OrganisationTechStackFiltered';
import OrganisationTechStackUnfiltered, {Sort as UnfilteredSort} from './OrganisationTechStackUnfiltered';


export const getSortButton = (
    s: FilteredSort | UnfilteredSort,
    setDesc: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    desc: boolean | undefined,
    setSort: React.Dispatch<React.SetStateAction<string | undefined>>,
    sort: string | undefined
) => {
    if (s === sort) {
        return (
            <a className="sort-button"
                onClick={() => {
                    setDesc(!desc);
                }}>
                <FontAwesomeIcon icon={desc ? faSortAmountDown : faSortAmountDownAlt} />
            </a>
        );
    }

    return (
        <a className="sort-button"
            onClick={() => {
                setSort(s);
                setDesc(true);
            }}>
            <FontAwesomeIcon icon={faSort} />
        </a>
    );
};

export const getSort = (sort: string | undefined, desc: boolean | undefined) => {
    if (sort) {
        return sort + "," + (desc ? "DESC" : "ASC");
    }
};

const OrganisationTechStack: React.FC = () => {
    const { organisation } = useContext(UserOrganisationContext);

    let { organisationId } = useParams<{organisationId: string}>();
    const history = useHistory();
    let location = useLocation();
    const parsed = queryString.parse(location.search);
    const [techStackView, setTechStackView] = useState(parsed.view);

    const redirectToTechStackView = (view: string) => {
        var link: string = "/organisation/" + organisationId + "/techstack";
        if (view !== "") {
            link += "?view=" + view;
        }
        history.push(link);
    };

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        setTechStackView(parsed.view);
    }, [location]);

    return (
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0, marginTop: 15, overflowX: "hidden" }}>
            <Row className="" style={{ marginLeft: 0, marginRight: 0, marginBottom: 15 }}>
                <div style={{ marginLeft: "auto", marginRight: "auto" }} className="iq-button-group">
                    <button className={techStackView === undefined ? "selected-inverted" : ""}
                        onClick={(e) => { redirectToTechStackView(""); }}>Whole Technology Stack</button>
                    <button className={techStackView === "filtered" ? "selected-inverted" : ""}
                        onClick={(e) => { redirectToTechStackView("filtered") }}>Filtered Technology Stack</button>
                </div>
            </Row>
            {techStackView === undefined &&
                <OrganisationTechStackUnfiltered />
            }
            {techStackView === "filtered" &&
                <OrganisationTechStackFiltered />
            }
        </Container>
    );
}

export default OrganisationTechStack;