import {faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faNewspaper, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {Alert, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {Tweet} from "react-twitter-widgets";
import styled from "styled-components";
import NewsItem from "../../model/news/NewsItem";
import {frontEndHostName, socialHostName} from "../../utils/Configuration";
import IqLoadingIcon from "../common/IqLoadingIcon";
import NewsCard from "../news/NewsCard";


const SocialEventsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: wrap;
    background-color:white;
    padding: 2rem;
    margin-bottom: 1rem;
    font-family: Roboto;
    gap: 0.8rem;
    border-radius: 0.7rem;
  `;

const Title = styled.div`
   font-size: 1.5rem;
   font-weight: 500;
`;

const SocialEventsSplit = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 0.4rem;
    padding: 2rem;
`

const News = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.4rem;
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 0;
    flex-shrink: 1;
`

const Twitter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 0;
    flex-shrink: 1;
`

interface NewsPreferences {
    id: number | null,
    userId: number,
    title: string,
    sectors: string[],
    topics: string[],
    terms: string[]
    default: boolean
}

interface TweetDTO {
    tweetId: string,
    userId: string,
    createdAt: string
}

const SocialEventsNotification: React.FC = () => {

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    const [loadingNews, setLoadingNews] = useState<boolean>(false);
    const [loadingTwitter, setLoadingTwitter] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [news, setNews] = useState<NewsItem[]>([]);
    const [newsError, setNewsError] = useState<boolean>();
    const [twitterError, setTwitterError] = useState<boolean>();
    const [tweets, setTweets] = useState<TweetDTO[]>([]);
    const [interests, setInterests] = useState<string[]>([]);

    const renderTooltip = (props: any) => (
        <Tooltip {...props}>
            {interests.length > 0 ? interests.join(",") : "Add interests in your user settings."}
        </Tooltip>
    );

    useEffect(() => {
        const fetchInterests = async () => {
            await axios.get(frontEndHostName + "interests")
                .then(r => setInterests(r.data))

        }
        fetchInterests();

    }, [])

    useEffect(() => {
        const fetchNews = async () => {
            setLoadingNews(true);

            await axios.post([socialHostName, "news/followed", '?page=', page, '&size=10&since=', query.get('since')].join(''),
                [...interests])
                .then(r => {
                    if (page === 0) {
                        setNews([...r.data]);
                    } else {
                        setNews((previousNews: NewsItem[]) => {
                            return [...previousNews, ...r.data];
                        });
                    }
                })
                .catch(() => setNewsError(true))
                .finally(() => setLoadingNews(false));
        };

        fetchNews();
    }, [interests]);

    useEffect(() => {
        const fetchTweets = async () => {
            setLoadingTwitter(true);
            await axios.post([socialHostName, "twitter/filteredTweets", '?page=', page, '&size=10&since=', query.get('since')].join(''),
                [...interests])
                .then(r => {
                    setTweets(
                        r.data
                    );
                    setPage(0)
                })
                .catch(error => setTwitterError(true))
                .finally(() => setLoadingTwitter(false));

        };

        fetchTweets();
    }, [interests]);

    return (
        <SocialEventsContainer>
            <Title>Based on your follows and interests <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            ><FontAwesomeIcon icon={faQuestionCircle} color={"367BDA"} size="xs"/></OverlayTrigger>, this is what you have missed since your last visit...</Title>
            <SocialEventsSplit>
                <News>
                    <FontAwesomeIcon style={{ alignSelf: "center" }} icon={faNewspaper} color="#367BDA" size="3x" />
                    {loadingNews ? <IqLoadingIcon /> :
                        news.length > 0 ? news.map((item: NewsItem, index: number) => (
                            <div key={index} className="pb-2">
                                <NewsCard item={item} />
                            </div>
                        )) : <Alert variant="success">
                            You're up-to-date!
                        </Alert>}
                </News>
                <Twitter>
                    <FontAwesomeIcon style={{ alignSelf: "center" }} icon={faTwitter} color="#367BDA" size="3x" />
                    {loadingTwitter ? <IqLoadingIcon /> :
                        tweets.length > 0 ? tweets.map((tweet: TweetDTO) => (
                            <Tweet key={tweet.tweetId} tweetId={tweet.tweetId} />
                        )) : <Alert variant="success">
                            You're up-to-date!
                        </Alert>}
                </Twitter>
            </SocialEventsSplit>
        </SocialEventsContainer>
    )
};

export default SocialEventsNotification;
