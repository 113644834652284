import React, { useState } from "react";
import {Modal} from "react-bootstrap";
import "./ActiveJobInfo.css";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FileDownload from "js-file-download";
import AdvertisedJob from "../../../model/organisation/AdvertisedJob";

interface Props {
    job: AdvertisedJob
}


const ActiveJobInfo: React.FC<Props> = (props: Props) => {
    const [job, setJob] = useState<AdvertisedJob>(props.job);

    const ifRemote = () => {
        if (job.location == "Anywhere") {
            return "Remote Work | Anywhere";
        }
        return job.location
    }

    return (
        <>
            <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            (job.companyWebsite === null)
                                ? <span>{job.jobTitle}</span>
                                : <a href={"http://" + job.companyWebsite} target="_blank" rel="noreferrer noopener">{job.jobTitle}</a>
                        }
                        <p style={{lineHeight: 1}}/>
                        <span className="sub-title">{job.companyName}</span>
                        <br />
                        <span className="details-title">{ifRemote()}</span>
                        <br />
                        <span className="details-title">{job.contractType}</span>
                    </Modal.Title>
                <br />
            </Modal.Header>
            <Modal.Body className="p-4">
                <div className="mt-3 preserve-space">
                    {job.body}
                </div>
            </Modal.Body>
        </>
    );
};

export default ActiveJobInfo;