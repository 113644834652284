import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons";
import './ContactEvents.css'
import axios from "axios";
import {socialHostName} from "../../utils/Configuration";
import {useHistory, useLocation} from "react-router-dom";
import NylasEventStats from "../../model/organisation/social/NylasEventStats";
import Organisation from "../../model/organisation/Organisation";
import NylasContact from "../../model/organisation/social/NylasContact";
import {Image, OverlayTrigger, Popover} from "react-bootstrap";


interface CommonContacts {
    name: string,
    noOfMeeting: number
}

const ContactEvents: React.FC = () => {

        const history = useHistory()
        const myContacts = useLocation().state;

        const [loading, setLoading] = useState<boolean>(false);
        const [eventStats, setEventStats] = useState<NylasEventStats>({} as NylasEventStats);
        const [commonContacts, setCommonContacts] = useState<CommonContacts[]>([]);
        const [organisation, setOrganisation] = useState({} as Organisation);
        const [contacts, setContacts] = useState<NylasContact[]>([]);
        const [contact, setContact] = useState<NylasContact>({} as NylasContact);

        useEffect(() => {

            setLoading(true)
            let locationStateData: any;
            if (myContacts) {
                locationStateData = myContacts;
                setOrganisation(locationStateData.organisation)
                setContacts(locationStateData.contacts)
                let currentContact = locationStateData.contacts.find((value: { id: any; }) => value.id === locationStateData.contactId);
                if (currentContact) {
                    setContact(currentContact);
                }
            }
            const fetchMessageStats = async () => {
                setLoading(true);
                await axios.post(socialHostName + "eventstats", locationStateData.contactEmailId)
                    .then(r => setEventStats(r.data));
            }
            fetchMessageStats();

            const fetchCommonContact = async () => {
                await axios.get(socialHostName + "commoncontacts?organisationalUrl=" + locationStateData.organisation.companyUrl)
                    .then(r => setCommonContacts(r.data));
            }
            fetchCommonContact();
        }, [myContacts])

        const EventsStats = () => {
            return (
                <div id="nylas-stats-card-container" style={{paddingTop: 15}}>
                    <div className="nylas-stats-card">
                        <div className="nylas-stats-label">
                            Message Stats:
                        </div>
                        <div>
                            <OverlayTrigger placement="top"
                                            delay={{show: 250, hide: 400}}
                                            overlay={
                                                <Popover id={"meeting-contact-popover"}>
                                                    Last meeting with {displayEmailIfNameNull(contact)} as the organiser
                                                </Popover>
                                            }>
                                <b>Last Meeting/Call as Organiser:</b>
                            </OverlayTrigger>
                            <span style={{float: "right"}}> {eventStats.lastMeetingOrganiser} </span>
                        </div>
                        <div>
                            <OverlayTrigger placement="top"
                                            delay={{show: 250, hide: 400}}
                                            overlay={
                                                <Popover id={"meeting-contact-popover"}>
                                                    Next meeting with {displayEmailIfNameNull(contact)} as the organiser
                                                </Popover>
                                            }>
                            <b>Next Meeting/Call as Organiser:</b>
                            </OverlayTrigger>
                            <span style={{float: "right"}}> {eventStats.nextMeetingOrganiser}</span>
                        </div>
                        <div>
                            <OverlayTrigger placement="top"
                                            delay={{show: 250, hide: 400}}
                                            overlay={
                                                <Popover id={"meeting-contact-popover"}>
                                                    Last meeting with {displayEmailIfNameNull(contact)} as the participant
                                                </Popover>
                                            }>
                            <b>Last Meeting/Call as Participant:</b>
                            </OverlayTrigger>
                            <span style={{float: "right"}}> {eventStats.lastMeetingParticipant} </span>
                        </div>
                        <div>
                            <OverlayTrigger placement="top"
                                            delay={{show: 250, hide: 400}}
                                            overlay={
                                                <Popover id={"meeting-contact-popover"}>
                                                    Next meeting with {displayEmailIfNameNull(contact)} as the participant
                                                </Popover>
                                            }>
                            <b>Next Meeting/Call as Participant:</b>
                            </OverlayTrigger>
                            <span style={{float: "right"}}> {eventStats.nextMeetingParticipant}</span>
                        </div>
                    </div>

                    <div className="nylas-stats-card">
                        <table>
                            <thead>
                            <tr>
                                <th></th>
                                <th>Last 30 Days</th>
                                <th>Last 60 Days</th>
                                <th>Last 90 Days</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Meeting/Calls With Contact:</td>
                                <td>{eventStats.meetingLast30Days}</td>
                                <td>{eventStats.meetingLast60Days}</td>
                                <td>{eventStats.meetingLast90Days}</td>
                            </tr>
                            <tr>
                                <td>Average per week</td>
                                <td>{eventStats.averagePerWeek30Days}</td>
                                <td>{eventStats.averagePerWeek60Days}</td>
                                <td>{eventStats.averagePerWeek90Days}</td>
                            </tr>
                            <tr>
                                <td>Trend from previous Period</td>
                                <td> {eventStats.trend30Days ?
                                    <FontAwesomeIcon style={{marginLeft: 5}} icon={faArrowUp} color="green"
                                                     size="1x"/>
                                    : <FontAwesomeIcon style={{marginLeft: 5}} icon={faArrowDown} color="red"
                                                       size="1x"/>}</td>
                                <td> {eventStats.trend60Days ?
                                    <FontAwesomeIcon style={{marginLeft: 5}} icon={faArrowUp} color="green"
                                                     size="1x"/>
                                    : <FontAwesomeIcon style={{marginLeft: 5}} icon={faArrowDown} color="red"
                                                       size="1x"/>}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }

        const percentageOfMeeting = (emailsPerContact: number, totalMessages: number) => {
            return parseFloat(String(emailsPerContact / totalMessages * 100)).toFixed(2);
        }

        const displayEmailIfNameNull = (nameContact: NylasContact) => {
            if (nameContact.givenName === null) {
                return nameContact.emails[0].email;
            } else {
                return nameContact.givenName + " " + nameContact.surname
            }
        }

        const redirectToContact = (contactName: string) => {
            let nextContact = contacts.find((value => displayEmailIfNameNull(value).includes(contactName)));
            if (nextContact) {
                return history.push(`/contact/${nextContact.id}?organisation=${organisation.id}`, {
                    contactEmailId: nextContact.emails[0].email,
                    contactName: displayEmailIfNameNull(nextContact),
                    contactId: nextContact.id,
                    contacts: contacts,
                    organisation: organisation
                });
            }
        }

        const ProfileImage = (contact: NylasContact) => {
            const [src, setSrc] = useState<string>(contact.picture_url ? contact.picture_url : "/icons_profil.svg");
            return (
                <Image src={src}
                       onError={() => setSrc("/icons_profil.svg")}
                       style={{
                           height: "70px",
                           width: "70px",
                           objectFit: "cover",
                           marginTop: 10,
                           marginBottom: 15,
                           border: "2px solid #5FD1F9"
                       }}
                       roundedCircle/>
            );
        }


        const CommonContactsInMeeting = () => {
            let commonMeetingContacts = commonContacts.sort((a, b) => {
                return b.noOfMeeting - a.noOfMeeting
            }).slice(0, 4).filter(value => {return value.name !== displayEmailIfNameNull(contact) });
            let totalMeeting = commonContacts.reduce((accum, item) => accum + item.noOfMeeting, 0);

            return (
                <div id="common-contact-card-container" style={{paddingTop: 15}}>
                    <div className="nylas-common-outer-container">
                        <div className="nylas-stats-label">
                            Common contacts in Meetings/Calls
                        </div>
                        <div className="nylas-common-contacts-container">
                            {commonMeetingContacts.map((value: { name: string; noOfMeeting: number; }, index) => {
                                    return (
                                        <div key={index} className="nylas-common-profile">
                                            <div className="nylas-common-profile-image">
                                                <ProfileImage {...contact}/>
                                            </div>
                                            <div className="nylas-common-stats">
                                                <a onClick={() => {
                                                    redirectToContact(value.name)
                                                }}>
                                                    <span> {value.name} </span>
                                                </a>
                                                <span>{percentageOfMeeting(value.noOfMeeting,
                                                    totalMeeting) + "%"
                                                }</span>
                                            </div>
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    </div>
                </div>
            );
        }


        return (
            <div>
                <EventsStats/>
                <CommonContactsInMeeting/>
            </div>
        )
    }
;

export default ContactEvents;
