import React, {useContext, useEffect, useState} from "react";
import DomainAlias from "../../../model/organisation/DomainAlias";
import axios from "axios";
import {organisationHostname} from "../../../utils/Configuration";
import UserOrganisationContext from "../../../context/organisation/OrganisationContext";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {Col, Form, Row} from "react-bootstrap";
import {faSave, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DomainAliasEditor: React.FC = () => {

    const {organisation, setOrganisation} = useContext(UserOrganisationContext);

    const [loadingDomains, setLoadingDomains] = useState<boolean>(false);
    const [domainAliases, setDomainAliases] = useState<DomainAlias[]>([]);
    useEffect(() => {
        const fetchDomains = async () => {
            setLoadingDomains(true);
            await axios.get(organisationHostname + "organisation/" + organisation.id + "/domain-aliases")
                .then(r => setDomainAliases(r.data))
                .finally(() => setLoadingDomains(false));
        };

        fetchDomains();
    }, []);

    const [newAlias, setNewAlias] = useState<string>();
    const updateNewAlias = (event: any) => {
        setNewAlias(event.target.value);
    };

    const saveNewAlias = () => {
        let dto = {
            url: newAlias,
            organisationId: organisation.id,
            validated: false
        };

        axios.put(organisationHostname + "organisation/" + organisation.id + "/domain-aliases", dto)
            .then(r => {
                let data = [
                    ...domainAliases,
                    r.data
                ];

                setDomainAliases(data);
            })
            .finally(() => setNewAlias(""));
    };

    const deleteAlias = (id: number) => {
        axios.delete(organisationHostname + "organisation/" + organisation.id + "/domain-aliases/" + id)
            .then(() => {
                let filtered = domainAliases.filter(a => a.id !== id);
                setDomainAliases(filtered);
            });
    };

    return (
        <div className="organisation-card">
            <div className="iq-tx-11 iq-tx-uppercase iq-tx-spacing-1 iq-tx-semibold iq-tx-color-02">
                Domain Aliases
            </div>
            <div>
                {loadingDomains ? <IqLoadingIcon /> : (
                    <div>
                        {domainAliases.map((domainAlias: DomainAlias) => (
                            <Row key={domainAlias.id} className="mt-2">
                                <Col>
                                    <Form.Control readOnly={true} type="text" value={domainAlias.url} />
                                </Col>
                                <Col className="pt-1">
                                    <button className="iqx-button sm-size primary" onClick={() => deleteAlias(domainAlias.id)}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </button>
                                </Col>
                            </Row>
                        ))}

                        <Row className="mt-2">
                            <Col>
                                <Form.Control type="text" value={newAlias} onChange={updateNewAlias} />
                            </Col>
                            <Col className="pt-1">
                                <button className="iqx-button sm-size primary" onClick={() => saveNewAlias()} disabled={!newAlias}>
                                    <FontAwesomeIcon icon={faSave} />
                                </button>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DomainAliasEditor;