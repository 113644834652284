import React from 'react';
import {Col} from "react-bootstrap";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import PageNotFound from '../common/PageNotFound';
import AdminAuthoritiesContainer from './AdminAuthoritiesContainer';
import './AdminContainer.css';
import AdminDataAnalytics from './AdminDataAnalytics';
import AdminGdprContainer from './AdminGdprContainer';
import AdminUsersContainer from './AdminUsersContainer';
import UserEdit from "./UserEdit";
import ServiceStatusContainer from "./status/ServiceStatusContainer";

const AdminSubContainer: React.FC = () => {
    let match = useRouteMatch();

    return (
        <Col xs={12} className="mt-3">
            <Switch>
                <Route strict exact path={`${match.url}/users`} component={AdminUsersContainer} />
                <Route strict exact path={`${match.url}/analytics`} component={AdminDataAnalytics}/>
                <Route exact path={`${match.url}/users/:userId`} component={UserEdit} />
                <Route path={`${match.url}/authorities`} component={AdminAuthoritiesContainer} />
                <Route path={`${match.url}/gdpr`} component={AdminGdprContainer} />
                <Route path={`${match.url}/service-status`} component={ServiceStatusContainer} />
                <Route component={PageNotFound} />
            </Switch>
        </Col>
    )
};

export default AdminSubContainer;
