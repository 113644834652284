import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import translate from "../../i18n/translate";

export const showErrorWithMessage = (title: string, text: string) => {
    const ErrorDialog = withReactContent(Swal);
    return ErrorDialog.fire({
        title: title,
        text: text,
        icon: "error"
    });
}

export const showGenericErrorDialog = () => {
    const ErrorDialog = withReactContent(Swal);
    ErrorDialog.fire({
        title: translate("errors.generic"),
        icon: "error"
    });
}

export const showSuccessWithMessage = (title: string, text: string) => {
    const SuccessDialog = withReactContent(Swal);
    return SuccessDialog.fire({
            title: title,
            text: text,
            icon: "success"
        })
}

export const showGenericSuccessDialog = () => {
    const SuccessDialog = withReactContent(Swal);
    return SuccessDialog.fire({
        title: "Save successful!",
        icon: "success"
    });
}

export const showWarning = (title: string, text: string) => {
    const WarningDialog = withReactContent(Swal);
    return WarningDialog.fire({
        icon: "warning",
        title: title,
        text: text
    });
}
