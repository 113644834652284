import React, {useContext, useEffect} from "react";
import {Form, Modal, Table} from "react-bootstrap";
import IqLoadingIcon from "../common/IqLoadingIcon";
import translate from "../../i18n/translate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronRight,
    faFilter,
    faSort,
    faSortAmountDown,
    faSortAmountDownAlt
} from "@fortawesome/free-solid-svg-icons";
import {Renewal} from "../../model/renewals/Renewal";
import {format} from "d3-format";
import DateFormatter from "../../utils/formatters/DateFormatter";
import Pagination from "../../utils/PaginationHook";
import RenewalsFiltersModal from "./RenewalsFiltersModal";
import RenewalsContext, {RenewalsSort} from "../../context/renewals/RenewalsContext";
import {RenewalsFilters} from "../../model/renewals/RenewalsFilters";
import {useHistory, useLocation} from "react-router-dom";
import {Switch} from "antd";
import queryString from "query-string";

const RenewalsList: React.FC = () => {

    const {
        renewals, setRenewals,
        filters, setFilters,
        hideExpired, setHideExpired,
        page, setPage,
        pageSize, setPageSize,
        sort, setSort,
        desc, setDesc,
        showFilters, setShowFilters,
        loading, setLoading,
        loadingVendors, setLoadingVendors,
        loadingEndUsers, setLoadingEndUsers,
        totalRecords, setTotalRecords,
        vendors, setVendors,
        endUsers, setEndUsers
    } = useContext(RenewalsContext);

    let history = useHistory();
    const location = useLocation();
    let {expiring} = queryString.parse(location.search);

    const getCurrency = (currency: string) => {
        if (currency === "USD") {
            return "$";
        }

        if (currency === "GBP") {
            return "£";
        }

        if (currency === "EUR") {
            return "€";
        }
    }

    const getSortButton = (s: RenewalsSort) => {
        if (s === sort) {
            return (
                <a className="sort-button pull-right"
                   onClick={() => {
                       setDesc(!desc);
                   }}>
                    <FontAwesomeIcon icon={desc ? faSortAmountDown : faSortAmountDownAlt} />
                </a>
            );
        }

        return (
            <a className="sort-button pull-right"
               onClick={() => {
                   setSort(s);
                   setDesc(true);
               }}>
                <FontAwesomeIcon icon={faSort} />
            </a>
        );
    };

    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== pageSize) {
            setPageSize(size);
        }
    };

    const updateFilters = (filters: RenewalsFilters | null) => {
        setFilters(filters);
        if (filters?.endDateFrom) {
            setHideExpired(false);
        }
        else if (!filters) {
            setHideExpired(true);
        }
        setShowFilters(false);
        setRenewals([]);
        setPage(1);
    }

    useEffect(() => {
        return () => {
            if (!!expiring) {
                updateFilters(null);
            }
        }
    }, [])

    return (
        <div style={{
            paddingTop: "10px",
            backgroundColor: "white",
            borderRadius: "0.45rem",
            padding: 15,
            marginTop: 10
        }}>
            {loading ? <IqLoadingIcon/> : (
                <div>
                    <div className="mt-4 mb-3">
                        <div className="justify-content-md-end pagination mt-n2">
                            <div className="me-4">
                                <span className="me-2">Hide expired</span>
                                <Switch checked={hideExpired} onChange={() => setHideExpired(!hideExpired)}/>
                            </div>
                            <button className="iqx-button primary sm-size"
                                    disabled={loadingEndUsers || loadingVendors}
                                    onClick={() => setShowFilters(true)}>
                                <span className="pe-2">{translate("contacts.filter.filters")}</span>
                                <span><FontAwesomeIcon icon={faFilter} color="#FFFFFF" /></span>
                            </button>
                        </div>
                    </div>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>
                                    Agreement ID
                                </th>
                                <th>
                                    Vendor Name
                                    {getSortButton(RenewalsSort.VENDOR)}
                                </th>
                                <th>
                                    End User
                                    {getSortButton(RenewalsSort.END_USER)}
                                </th>
                                <th>
                                    Product Program
                                    {getSortButton(RenewalsSort.PRODUCT_PROGRAM)}
                                </th>
                                <th className="text-end">
                                    Renewal Value
                                    {getSortButton(RenewalsSort.RENEWAL_VALUE)}
                                </th>
                                <th>
                                    Agreement Status
                                    {getSortButton(RenewalsSort.AGREEMENT_STATUS)}
                                </th>
                                <th>
                                    Agreement End Date
                                    {getSortButton(RenewalsSort.AGREEMENT_END_DATE)}
                                </th>
                                <th style={{width: "8%"}}>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renewals.map((renewal: Renewal) => (
                                <tr>
                                    <td>
                                        {renewal.agreementNumber}
                                    </td>
                                    <td>{renewal.vendor}</td>
                                    <td>{renewal.endUser.toUpperCase()}</td>
                                    <td>{renewal.productProgram}</td>
                                    <td className="text-end">
                                        {getCurrency(renewal.productSellCurrency)}
                                        {format(",.2f")(renewal.productSellPrice)}
                                    </td>
                                    <td>{renewal.agreementStatus}</td>
                                    <td>{DateFormatter.formatDate(renewal.agreementEndDate)}</td>
                                    <td className="text-end">
                                        <button className="iqx-button primary sm-size"
                                                onClick={() => history.push(`/renewals/${renewal.id}`)}>
                                            <span className="me-2">View</span>
                                            <span>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <div className="mt-4">
                        <div className="justify-content-md-end pagination mt-n2">
                            <span className="me-2">
                                <Form.Control as="select"
                                              style={{ minWidth: "13%" }}
                                              defaultValue={pageSize}
                                              onChange={(event) => changePageSize(event)}
                                              >
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                </Form.Control>
                            </span>
                            <span>
                                {!!totalRecords && (
                                    <Pagination totalRecords={totalRecords}
                                                pageLimit={pageSize}
                                                pageRangeDisplayed={1}
                                                initPage={page}
                                                onChangePage={setPage}
                                    />
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {(!loadingEndUsers && !loadingVendors) && (
                <Modal dialogClassName="renewals-filters-modal"
                       show={showFilters}
                       onHide={() => setShowFilters(false)}>
                    <RenewalsFiltersModal setFilters={updateFilters}
                                          initialFilters={filters}
                                          vendorOptions={vendors}
                                          endUserOptions={endUsers}/>
                </Modal>
            )}
        </div>
    );
}

export default RenewalsList;