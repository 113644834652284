import React from "react";
import ItemType from "../../components/dashboard/item/ItemType";
import Item from "../../components/dashboard/item/Item";

const DashboardContext = React.createContext({
    showModal: false,
    setShowModal: (show: boolean) => {},
    newType: null,
    setNewType: (type: ItemType | null) => {},
    layout: [] as Item[],
    setLayout: (layout: Item[]) => {}
});

export default DashboardContext;
