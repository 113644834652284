import React, {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {socialHostName} from "../../../utils/Configuration";
import {Alert, Col, Row} from "react-bootstrap";
import NewsItem from "../../../model/news/NewsItem";
import DashboardItemContext from "../../../context/dashboard/DashboardItemContext";
import NewsCard from "../../news/NewsCard";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {useHistory} from "react-router-dom";
import translate from "../../../i18n/translate";
import I18nContext from "../../../context/I18nContext";

const DashboardGlobalNews: React.FC = () => {
    const {lang, setLang} = useContext(I18nContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [news, setNews] = useState<NewsItem[]>([]);

    const {maxHeight, setMaxHeight} = useContext(DashboardItemContext);
    let history = useHistory();

    const scroller = useRef<any>();
    const [page, setPage] = useState<number>(0);

    const handleScroll = () => {
        if (!loading && !error) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            let currentScroll = scroller.current.scrollTop;

            if (currentScroll === maxScroll) {
                setPage(prev => prev + 1);
            }
        }
    };

    useEffect(() => {
        const fetchNews = async () => {
            setLoading(true);

            let query = "?page=" + page + "&size=10";
            if (lang) {
                query += "&lang=" + lang;
            }

            await axios.get(socialHostName + "news" + query)
                .then(r => {
                    if (page === 0) {
                        setNews([...r.data]);
                    }
                    else {
                        setNews((previousNews: NewsItem[]) => {
                            return [...previousNews, ...r.data];
                        });
                    }
                })
                .catch(error => setError(true))
                .finally(() => setLoading(false));
        };

        fetchNews();
    }, [page, lang]);


    useEffect(() => {
        if (loading  && page !== 0) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            scroller.current.scrollTop = maxScroll;
        }
    }, [loading]);

    const getHeight = () => {
        return maxHeight - 70;
    };

    return (
        <div>
            <Row className="pb-2">
                <Col>
                    <div className="organisation-card-label ">
                        {translate("dashboard.globalnews.title")}
                    </div>
                </Col>
                <Col>
                    <a className="iq-link pull-right nonIqbladeDraggableWidget" onClick={() => history.push("/news")}>
                        {translate("dashboard.viewall")}
                    </a>
                </Col>
            </Row>
            {error ? (
                <Alert variant="danger">
                    {translate("errors.loading")}
                </Alert>
            ) : (
                <div className="nonIqbladeDraggableWidget" style={{maxHeight: getHeight(), overflowY: "scroll"}} ref={scroller} onScroll={handleScroll}>

                    {news.map((item: NewsItem) => (
                        <div key={item.id} className="pb-2">
                            <NewsCard item={item}/>
                        </div>
                    ))}
                    <div>{loading && <IqLoadingIcon />}</div>
                </div>
            )}
        </div>
    );
};

export default DashboardGlobalNews;
