import React, {useEffect, useState} from "react";
import axios from "axios";
import {socialHostName} from "../../utils/Configuration";
import UserSocialHandles from "./UserSocialHandles";
import {getUserUuid, hasAnyRole, hasUserRole, Role} from "../../utils/Security";
import ProfileContainer from "./ProfileContainer";
import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHubspot, faLinkedin, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope, faHourglassEnd} from "@fortawesome/free-solid-svg-icons";
import translate from "../../i18n/translate";
import IqSmallLoadingIcon from "../common/IqSmallLoadingIcon";
import "./ProfileIntegration.css"
import LinkedinAccount from "../../model/campaigns/LinkedinAccount";
import HubSpotAccount from "../../model/hubspot/HubSpotAccount";
import {showGenericErrorDialog, showGenericSuccessDialog} from "../common/CommonAlerts";


const ProfileIntegration: React.FC = () => {

    const [linkedInAccessToken, setLinkedInAccessToken] = useState<LinkedinAccount>();
    const [linkedInAuthRequestUrl, setLinkedInAuthRequestUrl] = useState<string>("");
    const [hubSpotConnectUrl, setHubSpotConnectUrl] = useState<string>();
    const [hubSpotAccessToken, setHubSpotAccessToken] = useState<HubSpotAccount>();
    const [nylasAuthUrl, setNylasAuthUrl] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);
    const [socialHandles, setSocialHandles] = useState<UserSocialHandles>({} as UserSocialHandles);
    const [editedSocialHandles, setEditSocialHandles] = useState<UserSocialHandles>({} as UserSocialHandles);
    const [nylasAccountRegistered, setNylasAccountRegistered] = useState<boolean>(false);

    useEffect(() => {
        const fetchNylasStatus = () => {
            axios.get(socialHostName + "check-access")
                .then((response) => {
                    setNylasAccountRegistered(response.data)
                }).catch(error => console.log(error.message));
        }
        fetchNylasStatus();
        if (!nylasAccountRegistered){
            const connectToNylas = () => {
                axios.get(socialHostName + "nylas")
                    .then((response) => {
                        setNylasAuthUrl(response.data)
                    }).catch(error => showGenericErrorDialog());
            }
            connectToNylas();
        }


    }, [])




    useEffect(() => {
        if (getUserUuid() != null) {
            const fetchSocialHandles = async () => {
                await axios.get(socialHostName + "user/social-handles")
                    .then((response) => setSocialHandles(response.data))
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                    })
            };

            fetchSocialHandles();
        }

    }, [])


    const handleSocialHandlesChange = (event: any) => {
        if (editedSocialHandles === undefined) {
            setEditSocialHandles({
                ...editedSocialHandles as UserSocialHandles,
                [event.target.name]: event.target.value,
            });
        } else {
            setEditSocialHandles({...editedSocialHandles, [event.target.name]: event.target.value});
        }
    };

    const updateSocialHandles = (e: any) => {
        e.preventDefault();
        setLoading(true);
        axios.post(socialHostName + 'user/edit/social-handles', editedSocialHandles)
            .then((response) => {
                setSocialHandles(response.data)
                showGenericSuccessDialog()
            })
            .catch(() => {
                showGenericErrorDialog();
            })
            .finally(() => setLoading(false));
    }

    const revokeNylasAccount = () => {
        setLoading(true);
        axios.delete(socialHostName + '/nylas/revoke')
            .then(() => {
                showGenericSuccessDialog()
            })
            .catch(() => {
               showGenericErrorDialog();
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        setLoading(true)
        const fetchLinkedInAuthCode = () => {
            axios.get(socialHostName + "linkedin/access-token")
                .then(r => {
                    setLinkedInAccessToken(r.data);
                })
                .catch(error => console.error(error.message))
                .finally(() => setLoading(false));
        };


        const fetchLinkedInAuthRequestUrl = () => {
            axios.get(socialHostName + "linkedin/auth-request-url")
                .then(r => {
                    setLinkedInAuthRequestUrl(r.data as string);
                })
                .catch(error => console.error(error.message));
        };

        const fetchHubSpotConnectUrl = () => {
            axios.get(socialHostName + "hubspot/connect")
                .then(r => setHubSpotConnectUrl(r.data))
                .catch(error => setHubSpotAccessToken({token: "", expiresIn: ""}));
        };

        const fetchHubSpotAccessToken = () => {
            axios.get(socialHostName + "hubspot/access-token")
                .then(r => { console.log(r.data); setHubSpotAccessToken(r.data) });
        };


        fetchLinkedInAuthRequestUrl();
        fetchHubSpotConnectUrl();
        fetchHubSpotAccessToken();
        fetchLinkedInAuthCode();
    }, [])

    useEffect(() => {
        setEditSocialHandles(socialHandles);
    }, [socialHandles])

    const hasCampaignsPermission = () => {
        return hasAnyRole([Role.ADMIN, Role.CLIENT_ADMIN]);
    };

    const displayStatusCircle = (token: any, tokenExpiresOn: any) => {
        let currentDate = new Date();
        let tokenExpiryDate = new Date(tokenExpiresOn);
        if (token && (tokenExpiryDate > currentDate)){
            return (
                <span className="integration-status-green"/>
            )
        } else if (token && (tokenExpiryDate < currentDate)){
            return (
                <span className="integration-status-orange"/>
            )
        } else
        return (
            <span className="integration-status-red"/>
        )
    }


    const displayExpiresOnText = (token: any, tokenExpiresOn: any) => {

        let currentDate = new Date();
        let tokenExpiryDate = new Date(tokenExpiresOn);

        if (token && (tokenExpiryDate > currentDate)) {
            return (
                <div className="token-expiry">
                    <FontAwesomeIcon icon={faHourglassEnd} color="green" size="1x" />
                    <span style={{marginLeft: 5, color: "green"}}>Token expires on {new Date(tokenExpiresOn).toDateString()}</span>
                </div>
            )
        } else if (token && (tokenExpiryDate < currentDate)){
            return (
                <div className="token-expiry">
                    <FontAwesomeIcon icon={faHourglassEnd} color="#ff6b00" size="1x" />
                    <span style={{marginLeft: 5, color:"#ff6b00"}}>Token expired on {new Date(tokenExpiresOn).toDateString()}</span>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const attachOrRefreshTokenText = (token: any, tokenExpiresOn: any, authUrl: string) => {

        let currentDate = new Date();
        let tokenExpiryDate = new Date(tokenExpiresOn);

        if (token && (tokenExpiryDate > currentDate)){
            return (
                <></>
            )
        } else if (token  && (tokenExpiryDate < currentDate)){
            return (
                <a href={authUrl} target="_blank"
                   style={{marginTop: 10, float: "right", fontWeight: 500}}>
                    Refresh Account
                </a>
            )
        } else {
            return (
                <a href={authUrl} target="_blank"
                   style={{marginTop: 10, float: "right", fontWeight: 500}}>
                    Attach Account
                </a>
            )
        }
    }

    const attachOrRemoveToken = (nylasAccess: boolean, url: string) => {
        if (nylasAccess){
            return(
            <a onClick={revokeNylasAccount}
               style={{marginTop: 10, float: "right", fontWeight: 500}}>
                Revoke Access
            </a>
            )
        } else {
            return (
                <a href={nylasAuthUrl} target="_blank"
                   style={{marginTop: 10, float: "right", fontWeight: 500}}>
                    Connect MailBox
                </a>
            )
        }
    }




    return (
        <div>
            <ProfileContainer/>
            {!loading ?
                <div className="profile-integration-container">
                        {hasCampaignsPermission() &&
                            <div className="item">
                                <div className="profile-integration-card">
                                    <div className="title">
                                        <FontAwesomeIcon icon={faLinkedin} color="black" size="1x"/>
                                        <span>Linkedin Connect</span>
                                    </div>
                                    <Form id="linkedin-form">
                                        <Form.Group controlId="formUserName">
                                            <InputGroup>
                                                {/*<InputGroup.Prepend>*/}
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faLinkedin} color="#3177c8" size="1x"/>
                                                        <span style={{marginLeft: 5, fontSize: 14}}>Access Token</span>
                                                    </InputGroup.Text>
                                                {/*</InputGroup.Prepend>*/}
                                                <Form.Control name="mail" disabled type="text" className="login-input"
                                                              value={linkedInAccessToken?.accessToken ? linkedInAccessToken.accessToken : "There is no access token attached to IQBlade."}
                                                              placeholder="None"/>
                                            </InputGroup>
                                            {attachOrRefreshTokenText(linkedInAccessToken?.accessToken, linkedInAccessToken?.tokenExpiresOn,linkedInAuthRequestUrl)}
                                        </Form.Group>
                                    </Form>
                                    {linkedInAccessToken?.accessToken &&
                                    displayExpiresOnText(linkedInAccessToken.accessToken, linkedInAccessToken.tokenExpiresOn)
                                    }
                                    {displayStatusCircle(linkedInAccessToken?.accessToken, linkedInAccessToken?.tokenExpiresOn)}
                                </div>

                            </div>
                        }
                        {hasUserRole(Role.HUBSPOT) && (
                        <div className="item">
                                <div className="profile-integration-card">
                                    <div className="title">
                                        <FontAwesomeIcon icon={faHubspot} color="black" size="1x"/>
                                        <span>HubSpot Connect</span>
                                    </div>
                                    <Form.Group>
                                        <InputGroup>
                                            {/*<InputGroup.Prepend>*/}
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faHubspot} color="#3177c8" size="1x"/>
                                                    <span style={{marginLeft: 5, fontSize: 14}}>Access Token</span>
                                                </InputGroup.Text>
                                            {/*</InputGroup.Prepend>*/}
                                            <Form.Control name="mail" disabled type="text" className="login-input"
                                                          value={hubSpotAccessToken?.token ? hubSpotAccessToken.token : "There is no access token attached to IQBlade."}
                                                          placeholder="None"/>
                                        </InputGroup>
                                        {attachOrRefreshTokenText(hubSpotAccessToken?.token, hubSpotAccessToken?.expiresIn, hubSpotConnectUrl!)}
                                    </Form.Group>
                                    {displayStatusCircle(hubSpotAccessToken?.token, hubSpotAccessToken?.expiresIn)}
                                </div>
                        </div>
                        )}
                        <div className="item">
                            <div className="profile-integration-card">
                                <div className="title">
                                    <FontAwesomeIcon icon={faTwitter} color="black" size="1x"/>
                                    <span>Twitter</span>
                                </div>
                                <Form style={{marginTop: 15}}>
                                    <Form.Group controlId="formTwitter">
                                        <Form.Label>{translate("profile.social.twitter")}</Form.Label>
                                        <Form.Control name="twitter" type="text"
                                                      onChange={handleSocialHandlesChange}
                                                      value={editedSocialHandles?.twitter}/>
                                    </Form.Group>
                                    {loading ? <IqSmallLoadingIcon/>
                                        :
                                        <Button style={{marginLeft: "auto", marginTop: 15, float: "right"}}
                                                variant="primary"
                                                type="submit"
                                                onClick={(e: any) => updateSocialHandles(e)}>
                                            Save
                                        </Button>
                                    }
                                </Form>
                            </div>
                        </div>
                    {hasUserRole(Role.NYLAS_ACCESS) && (
                    <div className="item">
                            <div className="profile-integration-card">
                                <div className="title">
                                    <FontAwesomeIcon icon={faEnvelope} color="black" size="1x"/>
                                    <span>MailBox</span>
                                </div>
                                <Form id="linkedin-form">
                                    <Form.Group controlId="formUserName">
                                        <InputGroup>
                                            {/*<InputGroup.Prepend>*/}
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faEnvelope} color="#3177c8" size="1x"/>
                                                    <span style={{marginLeft: 5, fontSize: 14}}>Access Token</span>
                                                </InputGroup.Text>
                                            {/*</InputGroup.Prepend>*/}
                                            <Form.Control name="mail" disabled type="text" className="login-input"
                                                          value={nylasAccountRegistered ? "Access Token attached to IQBlade": "There is no access token attached to IQBlade."}
                                                          placeholder="None"/>
                                        </InputGroup>
                                        {attachOrRemoveToken(nylasAccountRegistered, nylasAuthUrl)}
                                    </Form.Group>
                                </Form>
                                {nylasAccountRegistered ? (
                                    <span className="integration-status-green"/>
                                ): (
                                    <span className="integration-status-red"/>
                                )}
                            </div>
                    </div>
                    )}
                </div>
                : <></>
            }
        </div>
    );

}

export default ProfileIntegration;