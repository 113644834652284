import React, {useEffect, useState} from 'react';
import {Alert, Button, Form, Modal, Table} from 'react-bootstrap';
import axios from 'axios';
import {organisationHostname} from '../../utils/Configuration';
import GdprExclusion from '../../model/admin/GdprExclusion';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {useForm} from 'react-hook-form';
import Pagination from '../../utils/PaginationHook';

interface Props {
}

const AdminGdprContainer: React.FC<Props> = (props: Props) => {

    const [exclusions, setExclusions] = useState<GdprExclusion[]>([] as GdprExclusion[]);
    const [renderSwitch, setRenderSwitch] = useState<boolean>(false);
    const [totalRecords, setTotalRecords] = useState(1);
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const MAX_EXCLUSIONS_PER_PAGE = 10;

    useEffect(() => {
        if (modalShow == false) {
            const fetchExclusions = async () => {
                const springCurrentPage: number = currentPage - 1;
                const result = await axios.get(organisationHostname + 'gdpr/exclusions' + '?page=' + springCurrentPage + '&size=' + MAX_EXCLUSIONS_PER_PAGE)
                    .then((response) => {
                        setExclusions(response.data.content);
                        setTotalRecords(response.data.totalElements);
                    }).catch(error => console.log(error.message));
            };
            fetchExclusions();
        }
    }, [renderSwitch, modalShow, currentPage]);


    const onDelete = (id: number) => {

        axios.delete(organisationHostname + 'gdpr/exclusion/' + id).then((response) => {
            if (response.status === 200) {
                setRenderSwitch(!renderSwitch);
            }
        }).catch(error => console.log(error.message));

    };

    const AdminNewExclusionModal: React.FC<any> = (props: any) => {

        const { register, errors, getValues, triggerValidation } = useForm({
            mode: "onChange",
            reValidateMode: "onChange"
        });

        const [isCreated, setCreated] = useState(false);
        const [responseError, setResponseError] = useState("");

        const saveExclusion = () => {
            let exclusion = {
                emailAddress: getValues("email"),
                firstName: getValues("firstName"),
                lastName: getValues("lastName"),
                phone: getValues("phone"),
                requestedSource: getValues("requestedSource")
            };

            axios.post(organisationHostname + 'gdpr/exclusion', exclusion,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(() => setCreated(true))
                .catch(error => {
                    setResponseError("An error occured while trying to add the GDPR exclusion.");
                });
        };

        const submit = () => {
            triggerValidation()
                .then(success => {
                    if (success) {
                        saveExclusion();
                    }
                });
        };

        return (
            <Modal {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add a new exclusion
                    </Modal.Title>
                </Modal.Header>
                {isCreated ? (
                    <Modal.Body>
                        The exclusion has been added successfully.
                    </Modal.Body>
                ) : (
                    <Modal.Body>
                        <Form.Group controlId="formFirstName">
                            <Form.Label>First name</Form.Label>
                            <Form.Control name="firstName" type="text" placeholder="Enter first name"
                                ref={register({ required: true, maxLength: 50 })}
                                isInvalid={errors.firstName} />
                            <Form.Control.Feedback type="invalid">
                                {errors.firstName && errors.firstName.type === "required" && "Please fill in the first name."}
                                {errors.firstName && errors.firstName.type === "maxLength" && "Maximum length of first name is 50."}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formLastName">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control name="lastName" type="text" placeholder="Enter last name"
                                ref={register({ required: true, maxLength: 50 })}
                                isInvalid={errors.lastName} />
                            <Form.Control.Feedback type="invalid">
                                {errors.lastName && errors.lastName.type === "required" && "Please fill in the last name."}
                                {errors.lastName && errors.lastName.type === "maxLength" && "Maximum length of last name is 50."}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control name="email" type="email" placeholder="Enter email"
                                ref={register({ required: true, minLength: 5, maxLength: 254 })}
                                isInvalid={errors.email} />
                            <Form.Control.Feedback type="invalid">
                                {errors.email && errors.email.type === "required" && "Please fill in the email address."}
                                {errors.email && errors.email.type === "minLength" && "Minimum length of email is 5."}
                                {errors.email && errors.email.type === "maxLength" && "Maximum length of email is 254."}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formPhone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control name="phone" type="text" placeholder="Enter phone"
                                ref={register({ required: false, minLength: 5, maxLength: 254 })}
                                isInvalid={errors.phone} />
                            <Form.Control.Feedback type="invalid">
                                {errors.phone && errors.phone.type === "minLength" && "Minimum length of phone is 5."}
                                {errors.phone && errors.phone.type === "maxLength" && "Maximum length of phone is 254."}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formRequestedSource">
                            <Form.Label>Requested Source</Form.Label>
                            <Form.Control name="requestedSource" as="select" placeholder="Select requested source"
                                ref={register({ required: true })}
                                isInvalid={errors.requestedSource}>
                                <option>email</option>
                                <option>phone</option>
                                <option>other</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.email && errors.email.type === "required" && "Please select the requested source."}
                            </Form.Control.Feedback>
                        </Form.Group>
                        {responseError !== "" &&
                            <Alert variant="danger">
                                {responseError}
                            </Alert>
                        }
                    </Modal.Body>
                )}
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>Close</Button>
                    {!isCreated && (
                        <Button variant="success" type="button" onClick={() => submit()}>
                            Submit
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <div>
            <AdminNewExclusionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <Button style={{ marginBottom: 10 }} variant="primary" onClick={() => setModalShow(true)}>Add a new exclusion</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Mail</th>
                        <th>Phone</th>
                        <th>Requested Source</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        exclusions
                            .map(exclusion => (
                                <tr key={exclusion.id}>
                                    <td>{exclusion.firstName}</td>
                                    <td>{exclusion.lastName}</td>
                                    <td>{exclusion.emailAddress}</td>
                                    <td>{exclusion.phone.toString()}</td>
                                    <td>{exclusion.requestedSource}</td>
                                    <td>
                                        <Button variant="link" onClick={() => { onDelete(exclusion.id) }}>
                                            <FontAwesomeIcon icon={faTrashAlt} color="red" size="1x" />
                                        </Button>
                                    </td>
                                </tr>
                            ))
                    }
                </tbody>
            </Table>
            <Pagination
                totalRecords={totalRecords}
                pageLimit={MAX_EXCLUSIONS_PER_PAGE}
                pageRangeDisplayed={1}
                onChangePage={setCurrentPage}
            />
        </div>
    );
}

export default AdminGdprContainer;