import OfficerInterest from "./OfficerInterest";

export default interface OrganisationOfficer {
    id: number;
    title: string;
    forname: string;
    surname: string;
    otherForenames: string;
    countryOfResidence: string;
    nationality: string;
    dateOfBirth: string;
    companiesHouseId: string;
    biography: string;
    twitter: string;
    linkedIn: string;
    crunchbase: string;
    medium: string;
    profileImageName: string;
    profileImageURL: string;
    phoneNumber: string;
    interests: OfficerInterest[];
}

export const getOfficerName = (officer: OrganisationOfficer) => {
    let fullName = "";
    if (officer.title) {
        fullName += officer.title + ". ";
    }

    let surname = officer.surname?.substring(0, 1).toUpperCase() + officer.surname?.substring(1).toLocaleLowerCase();
    fullName += [officer.forname, surname].filter(Boolean).join(' ')
    return fullName;
};
