import React, {useContext, useEffect, useState} from 'react';
import {Utils as QbUtils} from 'react-awesome-query-builder-opti';
import AdvancedSearchContext from '../../../context/advanced-search/AdvancedSearchContext';
import {Switch} from "antd";
import AdvancedSearchSelectedContext from "../../../context/advanced-search/AdvancedSearchSelectedContext";
import AdvancedSearchLoadFilterContext from "../../../context/advanced-search/AdvancedSearchLoadFilterContext";


interface Props {

}

const ConditionalSwitch: React.FC = () => {
    let { tree, setTree, filters, config } = useContext(AdvancedSearchContext);
    let { setLoadFilter } = useContext(AdvancedSearchLoadFilterContext);
    const { setSelectedNuts, setSelectedISIC} = useContext(AdvancedSearchSelectedContext);
    const [operator, setOperator] = useState(true);
    const [applyFilter, setApplyFilter] = useState(false);
    const [show, setShow] = useState(false);

    const onChange = (checked: boolean) => {
        let objectTree = QbUtils.getTree(tree);
        if (objectTree.hasOwnProperty('properties')) {
            objectTree!.properties = {
                "conjunction": checked ? "AND" : "OR"
            };
            setTree(QbUtils.checkTree(QbUtils.loadTree(objectTree), config));
        }
    };







    useEffect(() => {
        let objectTree = QbUtils.getTree(tree);
        if (!objectTree.hasOwnProperty('properties')) {
            objectTree!.properties = {
                "conjunction": "AND"
            }
            setTree(QbUtils.checkTree(QbUtils.loadTree(objectTree), config));
        }
    }, [tree])

    useEffect(() => {
        let objectTree = QbUtils.getTree(tree);
        if (objectTree.hasOwnProperty('properties')) {
            if (objectTree!.properties!.hasOwnProperty("conjunction")) {
                let conjunction = objectTree!.properties!.conjunction;
                if ((conjunction as any) != null) {
                    setOperator((conjunction as any) === "AND" ? true : false)
                    setShow(true);
                } else {
                    setShow(false)
                }
            } else {
                setShow(false)
            }
        } else {
            setShow(false)
        }

    }, [tree])

    return (
        show ? <Switch style={{ marginBottom: 10 }} checkedChildren="AND" unCheckedChildren="OR" checked={operator} onChange={(checked: boolean) => onChange(checked)} /> : <></>
    )

}

export default ConditionalSwitch;