import React, {useContext} from "react";
import {Col, Nav, NavItem, Row} from "react-bootstrap";
import DateFormatter from "../../../utils/formatters/DateFormatter";
import UserListContext from "../../../context/list/UserListContext";
import {Link, useLocation} from "react-router-dom";
import SubmenuContext from "../../../context/SubmenuContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalculator, faCalendar, faUser} from "@fortawesome/free-solid-svg-icons";
import translate from "../../../i18n/translate";

const UserListSummary: React.FC = () => {
    const { list, setList } = useContext(UserListContext);
    const location = useLocation();

    const { submenuItems, setSubmenuItems } = useContext(SubmenuContext);

    const isActive = (url: string) => {
        return url === location.pathname;
    };

    return (
        <div className="user-list-header-container">
            <Row className="" style={{ marginLeft: 0, marginRight: 0, paddingTop: 15 }}>
                <Col xs={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Row className="" style={{ marginLeft: 0, marginRight: 0, paddingTop: 0 }}>
                        <div id="container-header">
                            <span id="company-summary-container">
                                <span id="company-summary">
                                    <div style={{ marginLeft: 10, fontSize: 28, fontWeight: "bold" }}>List {list.id}: {list.title}</div>
                                    <div className="list-properties-container">
                                        <div className="list-property">
                                            <div className="list-property-container">
                                                <span><FontAwesomeIcon style={{ marginLeft: 5, marginRight: 5 }} className="icon" icon={faUser} color="white" size="1x" /></span>
                                                <span>{translate("lists.createdby", {username: list.userDisplayName})}</span>
                                            </div>
                                        </div>
                                        <div className="list-property">
                                        <div className="list-property-container">
                                            <span><FontAwesomeIcon style={{ marginLeft: 5, marginRight: 5 }} className="icon" icon={faCalendar} color="white" size="1x" /></span>
                                            <span>{translate("lists.createdon", {date: DateFormatter.formatDate(list.createdOn)})}</span>
                                        </div>
                                    </div>
                                    <div className="list-property">
                                        <div className="list-property-container">
                                            <span><FontAwesomeIcon style={{ marginLeft: 5, marginRight: 5 }} className="icon" icon={faCalculator} color="white" size="1x" /></span>
                                            <span>{translate("lists.companies", { count: list.organisations?.length })}</span>
                                        </div>
                                    </div>
                                    </div>
                                </span>
                            </span>
                            <div style={{marginTop: 15}}/>
                            <div id="container-header-navigation">
                                <Nav>
                                    {submenuItems.map((item: any, index: any) => {
                                        return (
                                            <NavItem key={index}>
                                                <Link to={item.url} className="nav-item">
                                                    <span >{translate(item.name)}</span>
                                                    {isActive(item.url) && <div className="arrow-wrapper">
                                                        <div className="arrow-up"></div>
                                                    </div>}

                                                </Link>
                                            </NavItem>
                                        )
                                    })}
                                </Nav>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Row>
        </div>
    )
};

export default UserListSummary;
