import {Switch} from "antd";
import axios from "axios";
import React, {KeyboardEventHandler, useContext, useEffect, useRef, useState} from "react";
import {Alert, Col, OverlayTrigger, Popover, Row} from "react-bootstrap";
import DashboardContext from "../../../context/dashboard/DashboardContext";
import DashboardItemContext from "../../../context/dashboard/DashboardItemContext";
import DashboardKeyContext from "../../../context/dashboard/DashboardKeyContext";
import translate from "../../../i18n/translate";
import YoutubeVideo from "../../../model/organisation/social/YoutubeVideo";
import {frontEndHostName, socialHostName} from "../../../utils/Configuration";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import Creatable from "react-select/creatable";


interface SelectOption {
    value: string,
    label: string
}

const DashboardFilteredYoutube: React.FC = () => {

    const size: number = 5;
    const { maxHeight, setMaxHeight } = useContext(DashboardItemContext);
    const { layout } = useContext(DashboardContext);
    const { widgetId } = useContext(DashboardKeyContext)
    const getHeight = () => {
        return maxHeight - 120;
    };

    const [videos, setVideos] = useState<YoutubeVideo[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingMore, setLoadingMore] = useState<boolean>(false);
    const [loadingLists, setLoadingLists] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [isPreferenceRetrieved, setIsPreferenceRetrieved] = useState<boolean>(false);
    const [dashboardId] = useState<number>(layout[0].dashboardId)
    const [loadFilter, setLoadFilter] = useState<boolean>(false);

    const [selectedTerms, setSelectedTerms] = useState<string[]>([]);

    const prevSelection = useRef({ selectedTerms }).current;

    const scroller = useRef<any>();

    useEffect(() => {
        const getWidgetPreferences = async () => {
            await axios.get(frontEndHostName + "api/dashboard/filter?widgetId=" + widgetId + "&dashboardId=" + dashboardId)
                .then(r => {
                    setSelectedTerms(r.data)
                })
                .catch(err => {
                    console.log(err)
                })
        }
        getWidgetPreferences()
        setIsPreferenceRetrieved(true)
    }, [])

    const updatePage = () => {
        setPage(prev => prev + 1);
        setLoadingMore(false);
    };

    useEffect(() => {
        const fetchInterests = async () => {
            await axios.get(frontEndHostName + "interests")
                .then(r => setSelectedTerms(r.data))
        }
        if (loadFilter) {
            fetchInterests();
        }

    },[loadFilter])

    const handleScroll = () => {
        if (!loading && !error) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            let currentScroll = scroller.current.scrollTop;

            if (currentScroll === maxScroll) {
                setLoadingMore(true);
                debounce(updatePage, 3000)();
            }
        }
    };

    const debounce = <F extends (...params: any[]) => void>(fn: F, delay: number) => {
        let timeoutId: any;

        const debounced = (...args: any) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => fn(...args), delay);
        };

        return debounced as (...args: Parameters<F>) => ReturnType<F>;
    };

    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        const fetchTweets = async () => {
            if (selectedTerms !== prevSelection?.selectedTerms || page === 0) {
                setLoading(true);
                await axios.post(socialHostName + "youtube/latest-videos-by-terms?page=0" +
                    "&size=" + size, selectedTerms)
                    .then(r => {
                        setVideos(
                            r.data.content
                        );
                        setPage(0)
                    })
                    .catch(error => setError(true))
                    .finally(() => setLoading(false));
            } else {
                setLoadingMore(true);
                await axios.post(socialHostName + "youtube/latest-videos-by-terms?page=" + page +
                    "&size=" + size, selectedTerms)
                    .then(r => {
                        setVideos((prevVideos: YoutubeVideo[]) => {
                            return [...prevVideos, ...r.data.content];
                        })
                    }
                    )
                    .catch(error => setError(true))
                    .finally(() => setLoadingMore(false));
            }
        };

        fetchTweets();
        return () => {
            prevSelection.selectedTerms = selectedTerms;
        };
    }, [page, selectedTerms]);


    useEffect(() => {
        const saveWidgetPreferences = () => {
            if (isPreferenceRetrieved) {
                let data = selectedTerms
                axios.post(frontEndHostName + "api/dashboard/save/filter?widgetId=" + widgetId + "&dashboardId=" + dashboardId, data)
            }
        }
        saveWidgetPreferences()
    }, [selectedTerms, loadFilter])

    const onChange = (checked: boolean) => {
        if (checked){
            setLoadFilter(true)
        } else {
            setLoadFilter(false)
            setSelectedTerms([]);
        }
    }

    const popover = (
        <Popover id="popover-basic">
            <h3>{translate("dashboard.twitter.popoverTitle")}</h3>
            <div>
                {translate("dashboard.twitter.popoverMessage")}
            </div>
        </Popover>
    );


    return (
        <div>
            <Row style={{justifyContent: "right"}}>
                <div>
                    <span style={{textTransform: "uppercase", color: "#929292", paddingRight: 2}} >Load terms</span>
                    <OverlayTrigger overlay={popover}>
                    <Switch size={"small"} onChange={(checked: boolean) => onChange(checked)}/>
                    </OverlayTrigger>
                </div>
            </Row>
            <Row className="pb-2">
                <Col>
                    <div className="organisation-card-label">
                        {translate('dashboard.videos.filteredTitle')}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="pt-2" xl={12} lg={12} md={12} sm={12} xs={12}>

                    <Creatable isMulti
                               onChange={(e: any) => {
                                   let payload = e.map(v => v.value);
                                   setSelectedTerms(payload)}
                    }
                               value={selectedTerms?.map(s => ({value: s, label: s} as SelectOption))}
                               placeholder="Enter Search Terms..."
                               options={[]}

                               styles={{
                                   control: (baseStyles, state) => ({
                                       ...baseStyles,
                                       color: "white",
                                   }),
                                   multiValue: (basestyles) => {
                                       const color = "#206fc3";
                                       return {
                                           ...basestyles,
                                           backgroundColor: color,
                                           borderRadius: "8%",
                                           paddingLeft: "8px",
                                           paddingRight: "3px",
                                           paddingTop: "3px",
                                           paddingBottom: "3px"
                                       };
                                   },
                                   multiValueLabel: (basestyles) => ({
                                       basestyles,
                                   }),
                                   multiValueRemove: (basestyles) => ({
                                       basestyles,
                                       paddingRight: "4px"
                                   }),
                               }}


                    />
                </Col>
            </Row>
            <div style={{ marginTop: 15, maxHeight: getHeight(), overflowY: "scroll", overflowX: "hidden" }} ref={scroller} onScroll={handleScroll}>
                {error ? (
                    <Alert variant="danger">
                        {translate("errors.loading")}
                    </Alert>
                ) : (
                    !loading ?
                        <div className="dashboard-youtube-video-list nonIqbladeDraggableWidget">
                            {videos.length === 0 && <Alert variant="primary">
                                We couldn't find any recent videos based on your selection criteria.
                            </Alert>}
                            {videos.map((video: YoutubeVideo, index: number) => (
                                <div key={index} className="embed-responsive-16by9">
                                    <iframe style={{ width: "100%", height: 400 }} className="embed-responsive-item" src={`https://www.youtube.com/embed/${video.id}`} />
                                </div>
                            ))}
                            <div>{loadingMore && <IqLoadingIcon />}</div>
                        </div>
                        : <IqLoadingIcon />
                )}
            </div>
        </div>
    );
};

export default DashboardFilteredYoutube;
