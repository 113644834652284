import React, {useContext, useEffect, useState} from "react";
import UserOrganisationContext from "../../../context/organisation/OrganisationContext";
import {Col, Row } from "react-bootstrap";
import translate from "../../../i18n/translate";
import Masonry from "react-masonry-css";
import CaseStudyCard from "../casestudy/CaseStudyCard";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import InvestorRelations from "../../../model/organisation/InvestorRelations";
import InvestorRelationsCard from "./InvestorRelationsCard";

const OrganisationInvestorRelations: React.FC = () => {

    const {
        investorRelationsResponse,
        investorRelationsState,
    } = useContext(UserOrganisationContext);

    // const debounced = AwesomeDebouncePromise(setCaseStudySearchTerm, 300);
    // const handleSearchQueryChange = async (e: any) => {
    //     e.persist();
    //     await debounced(e.target.value);
    // };

    return (
        <>
            {/*<Row className="mb-3">*/}
            {/*    <Col lg={{ span: 3, offset: 9 }} md={{ span: 3, offset: 9 }} sm={{ span: 4, offset: 8 }} xs={{ span: 6, offset: 6 }}>*/}
            {/*        <input className="form-control float-end"*/}
            {/*               type="text"*/}
            {/*               placeholder={translate("advancedsearch.search")}*/}
            {/*               onKeyUp={handleSearchQueryChange}*/}
            {/*        />*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            {investorRelationsState.loading ? <IqLoadingIcon /> : (
                <Masonry breakpointCols={3}
                         className="cs-grid"
                         columnClassName="cs-grid-column">
                    {investorRelationsResponse.map((investorRelation: InvestorRelations, index: number) => (
                        <div key={`key${index}`} className="mb-3">
                            <InvestorRelationsCard investorRelation={investorRelation} />
                        </div>
                    ))}
                </Masonry>
            )}
        </>
    )


}


export default OrganisationInvestorRelations;