import React, {useContext} from "react";
import DashboardContext from "../../../context/dashboard/DashboardContext";


const DashboardWelcome = () => {


    const { showModal, setShowModal } = useContext(DashboardContext);



    return (
        <div>
        <h5>Welcome to your dashboard!</h5>
        <p>Start by adding some widgets</p>
        <p>Widgets can be resized and rearranged</p>
        <p>Try dragging me around!</p>
        <button className="iq-button iq-button-primary"
                style={{alignContent: "center"}}
                title="Add item to your dashboard"
                onClick={() => setShowModal(true)}>
            <span className="ps-2 pe-2">Start here!</span>
        </button>
    </div>
    )
}


export default DashboardWelcome;