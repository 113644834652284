import React, {useContext, useState} from "react";
import UserOrganisationContext from "../../context/organisation/OrganisationContext";
import OrganisationGroupStructure from "../../model/organisation/OrganisationGroupStructure";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe, faLevelDownAlt, faLevelUpAlt} from "@fortawesome/free-solid-svg-icons";
import translate from "../../i18n/translate";
import {Button, Modal} from "react-bootstrap";
import './OrganisationCompanyStructure.css'


const OrganisationCompanyStructure: React.FC = () => {

    const {organisation} = useContext(UserOrganisationContext);
    const [modalShow, setModalShow] = useState<boolean>(false);

    const getParentCompany = () => {
        let parentCompanyData: OrganisationGroupStructure[] = organisation.organisationGroupStructure;

        if (parentCompanyData) {
            return parentCompanyData.some(value => value.ultimateParentCompany)
        }
    }

    const getImmediateCompany = () => {
        let parentCompanyData: OrganisationGroupStructure[] = organisation.organisationGroupStructure;

        if (parentCompanyData) {
            return parentCompanyData.some(value => !value.ultimateParentCompany && !value.subsidiaryCompanies)
        }
    }

    const getSubsidiaryCompany = () => {
        let subsidiaryCompany: OrganisationGroupStructure[] = organisation.organisationGroupStructure;

        if (subsidiaryCompany) {
            return subsidiaryCompany.some(value => !value.ultimateParentCompany && value.subsidiaryCompanies)
        }
    }

    const GetParentCompany = () => {
        let parentCompanyData: any = organisation.organisationGroupStructure?.find(value => value.ultimateParentCompany)

        return (
            <div className="item">
                <div className="logo">
                      {<FontAwesomeIcon icon={faGlobe} color="white" size="lg"/>}
                </div>
                    <div className="text">
                      {translate("organisation.summary.companyinfo.ultimateparentcompany")}
                    </div>
                <div >
                    {parentCompanyData.companyNumber !== null ? (
                        <a href={`/organisation/${parentCompanyData.companyNumber}`}>{parentCompanyData.companyName}</a>
                    ):(
                        <a>{parentCompanyData.companyName}</a>
                    )}
                </div>
            </div>

        )
    }

    const GetImmediateCompany = () => {
        let immediateCompanyData: any = organisation.organisationGroupStructure?.find(value => !value.ultimateParentCompany && !value.subsidiaryCompanies);

        return (
            <div className="item">
                <div className="logo">
                  {<FontAwesomeIcon icon={faLevelUpAlt} color="white" size="lg"/>}
                </div>
                <div className="text">
                    {translate("organisation.summary.companyinfo.immediateparentcompany")}
                </div>
                <div>
                    {immediateCompanyData.companyNumber !== null ? (
                        <a href={`/organisation/${immediateCompanyData.companyNumber}`}>{immediateCompanyData.companyName}</a>
                    ):(
                        <a>{immediateCompanyData.companyName}</a>
                    )}

                </div>
            </div>
                )}

    const GetSubsidiaryCompany = () => {
        let subsidiaryCompany: number = organisation.organisationGroupStructure?.filter(value => !value.ultimateParentCompany && value.subsidiaryCompanies).length;

        return (
            <div className="item">
                <div className="logo">
                    {<FontAwesomeIcon icon={faLevelDownAlt} color="white" size="lg"/>}
                </div>
                <div className="text">
                    {translate("organisation.summary.companyinfo.subsidiarycompany")}
                </div>
                <div>
                    <a style= {{color: "#007bff"}} onClick={handleShow}>{subsidiaryCompany}  companies</a>
                </div>
            </div>
        )
    }

    const handleClose = () => {
        setModalShow(false);
    }
    const handleShow = () => {
        setModalShow(true);
    }

    const SubsidiaryModal = () => {

        return(
            <Modal show={modalShow} onHide={handleClose} scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter centered">
            <Modal.Header closeButton>
                <Modal.Title className="">
                    <div className="text">
                        {translate("organisation.summary.companyinfo.subsidiarycompany")}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {organisation.organisationGroupStructure?.
                filter(value => !value.ultimateParentCompany && value.subsidiaryCompanies)
                    .map(value => {
                        return(
                            <div style={{textAlign: "center"}}>
                                {value.companyNumber !== null ? (
                                    <a href={`/organisation/${value.companyNumber}`}>{value.companyName}</a>
                                ):(
                                    <a>{value.companyName}</a>
                                )}
                            </div>
                        )
                    })
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
        )

    }



return (
        <div className="company-structure-container">
            {getParentCompany() &&
                <GetParentCompany/>
            }
            {getImmediateCompany() &&
                <GetImmediateCompany/>
            }

            {getSubsidiaryCompany() &&
                <>
                <GetSubsidiaryCompany/>
                <SubsidiaryModal/>
                </>
            }

        </div>
    );
}

export default OrganisationCompanyStructure;