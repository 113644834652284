import {faCommentDots, faPlusCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export default class EventType {

    public static readonly REMOVED_COMPANY = new EventType("lists.companiesremoved", <FontAwesomeIcon icon={faTrashAlt} color="#CC0000" size="1x"/> );
    public static readonly NEW_COMPANY = new EventType("lists.companiesadded", <FontAwesomeIcon icon={faPlusCircle} color="#45c343" size="1x"/> );
    public static readonly COMMENT = new EventType("lists.comment", <FontAwesomeIcon icon={faCommentDots} color="#5C5C5C" size="1x"/> );

    public static readonly ALL_TYPES: Record<string, EventType> = {
        "REMOVED_COMPANY": EventType.REMOVED_COMPANY,
        "NEW_COMPANY": EventType.NEW_COMPANY,
        "COMMENT": EventType.COMMENT
    };

    private constructor(
        public readonly displayName: string,
        public readonly icon: JSX.Element
    ) {}
}
