import {faUserCircle, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {Button, Form, Modal, Row} from "react-bootstrap";
import CheckboxTree from "react-checkbox-tree";
import {useForm} from "react-hook-form";
import translate from "../../../i18n/translate";
import {frontEndHostName, organisationHostname} from "../../../utils/Configuration";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {Team, TeamUser} from "../../teams/Teams";
import {getUserUuid} from "../../../utils/Security";
import {showGenericErrorDialog, showSuccessWithMessage} from "../../common/CommonAlerts";

interface ShareLinkModal {
    show: boolean,
    setShow: React.Dispatch<React.SetStateAction<boolean>>,
    childMatchedPath: any
}
const ShareLinkModal: React.FC<ShareLinkModal> = (props: ShareLinkModal) => {

    
    enum ShareMode {
        TEAM,
        TEMPORARY
    }

    const { register, handleSubmit, errors, setValue } = useForm<TemporaryLinkRequest>();

    const handleClose = () => props.setShow(false);
    interface TemporaryLinkRequest{
        mail: string
        companyId: string
        userId: string
    }
    const onSubmit = (data: TemporaryLinkRequest) => {
        data.companyId = props.childMatchedPath?.params.organisationId
        data.userId = getUserUuid()!
        axios.post(organisationHostname + 'organisation/send-temporary-link', data,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((response) => {
                if (response.status === 200) {
                    handleClose();
                    showSuccessWithMessage("Success!", "We have successfully sent an access token to the specified e-mail address.");
                }
            }).catch(error => {console.log(error.message); showGenericErrorDialog();});
    };

    const [shareMode, setShareMode] = useState<ShareMode>(ShareMode.TEMPORARY);

    const [checked, setChecked] = useState<any[]>([]);
    const updateChecked = (input: any[]) => {
        setChecked([
            ...input
        ]);
    };

    const [expanded, setExpanded] = useState<any[]>([]);
    const updateExpanded = (input: any[]) => {
        setExpanded([
            ...input
        ]);
    };

    const [teams, setTeams] = useState<Team[]>([]);
    const [loadingTeams, setLoadingTeams] = useState<boolean>(false);
    useEffect(() => {
        const fetchTeams = async () => {
            setLoadingTeams(true);
            await axios.get(frontEndHostName + "teams/user")
                .then(r => setTeams(r.data))
                .finally(() => setLoadingTeams(false));
        };

        fetchTeams();
    }, []);

    const getNodes = () => {
        return teams.map((t: Team) => {
            return {
                value: t.id,
                label: t.name,
                children:
                    t.users.map((u: TeamUser) => {
                        return {
                            /* All node objects must have a unique value.
                             This value is serialized into the checked and expanded arrays and is also used 
                             for performance optimizations. We append team ID at the end of UUID. 
                             Remove it before send.
                             */
                            value: [u.userUuid, t.id].join(),
                            label: u.firstName + " " + u.lastName
                        }
                    })
            }
        });
    };

    const shareProfileWithTeam = async () => {
        let postData = {
            organisationId: props.childMatchedPath?.params.organisationId,
            uuids: checked.map(uuid => uuid.substring(0,36))
        };

        await axios.post(organisationHostname + "organisation/share", postData)
            .then(() => showSuccessWithMessage("Success!", "We have successfully shared this profile with the specified team members."))
    };

    return (
        <div>
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{translate("organisation.temporarylink.share")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row className="mb-3" style={{ marginLeft: 0, marginRight: 0 }}>
                            <div style={{ marginLeft: "auto", marginRight: "auto" }} className="iq-button-group">
                                <button className={shareMode === ShareMode.TEMPORARY ? "selected-inverted" : ""}
                                        onClick={(e) => { setShareMode(ShareMode.TEMPORARY) }}>
                                    Share Temporary Link
                                </button>

                                <button className={shareMode === ShareMode.TEAM ? "selected-inverted" : ""}
                                        onClick={(e) => setShareMode(ShareMode.TEAM)}>
                                    Share With Team
                                </button>
                            </div>
                        </Row>

                        {shareMode === ShareMode.TEMPORARY ? (
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group controlId="formMail">
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control name="mail" type="text" placeholder={translate("organisation.temporarylink.email")}
                                                  ref={register({ required: true })}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    {translate("organisation.temporarylink.share")}
                                </Button>
                            </Form>
                        ) : (
                            <div>
                                {loadingTeams ? <IqLoadingIcon /> : (
                                    <div>
                                        <div className="mb-3">
                                            <CheckboxTree
                                                nodes={getNodes() as any}
                                                checked={checked}
                                                expanded={expanded}
                                                onCheck={updateChecked}
                                                onExpand={updateExpanded}
                                                icons={{
                                                    parentOpen: <FontAwesomeIcon icon={faUsers} />,
                                                    parentClose: <FontAwesomeIcon icon={faUsers} />,
                                                    leaf: <FontAwesomeIcon icon={faUserCircle} />
                                                }}
                                            />
                                        </div>

                                        <Button variant="primary" type="button" onClick={shareProfileWithTeam} disabled={!checked || checked.length === 0}>
                                            {translate("organisation.temporarylink.share")}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {translate("dashboard.close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ShareLinkModal;