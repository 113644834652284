import React from "react";
import DigitalMaturityScore from "../../model/organisation/DigitalMaturityScore";
import SocialHandles from "../../model/organisation/social/SocialHandles";

const OrganisationSocialContext = React.createContext({
    socialHandles: {} as SocialHandles,
    setSocialHandles: (socialHandles: SocialHandles) => {},
    loadingSocials: false,
    setLoadingSocials: (isLoading: boolean) => {},
    digitalMaturityScore: {} as DigitalMaturityScore
});

export default OrganisationSocialContext;
