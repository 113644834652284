import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {socialHostName} from "../../utils/Configuration";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHubspot} from "@fortawesome/free-brands-svg-icons";
import {Row} from "react-bootstrap";
import {showGenericErrorDialog} from "../common/CommonAlerts";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const HubSpotLanding: React.FC = () => {
    let history = useHistory();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    useEffect(() => {
        registerAuthCode(query.get("code"));
    }, []);

    const [loading, setLoading] = useState<boolean>(false);
    const registerAuthCode = (authCode: string | null) => {
        setLoading(true);
        axios.post(socialHostName + 'hubspot/register-auth-code', authCode, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                showSuccessDialog();
            })
            .catch(error => showGenericErrorDialog())
            .finally(() => {
                setLoading(false);
            });
    };

    const SuccessDialogBody = () => {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                flexDirection: "column",
                backgroundColor: "white", borderRadius: "0.45rem", padding: 15, margin: 10, width: "100%"
            }}>
                <span><FontAwesomeIcon icon={faHubspot} color="#0073B1" size="6x"/></span>
                <span style={{
                    fontWeight: 500,
                    fontSize: 18,
                    marginTop: 15
                }}>
                        Your account has been linked successfully with IQBlade.
                    </span>
            </div>
        );
    }

    const showSuccessDialog = () => {
        const SuccessDialog = withReactContent(Swal);
        SuccessDialog.fire({
            title: "Success!",
            html: <SuccessDialogBody/>,
        }).then(accept);
    }

    const accept = () => {
        history.push("/profile");
    };

    return (
        <Row className="mt-3">
            {loading && <IqLoadingIcon/>}
        </Row>
    );
};

export default HubSpotLanding;