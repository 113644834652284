import {Select} from "antd";
import axios from "axios";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {organisationHostname} from "../../../utils/Configuration";
import './PublicSectorSpend.css';
import {FilterType} from "./PublicSectorSpend";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import PublicSectorSpendContext from "../../../context/organisation/PublicSectorSpendContext";

const Option = Select.Option;

interface Props {
    filterType: FilterType
    selected: any,
    setSelected: any,
    placeholder: string,
    selectKey: string,
    optionField: string
}

const PublicSectorSpendInfiniteSelect: React.FC<Props> = (props: Props) => {

    const {
        expenseAreaOptions,
        setExpenseAreaOptions,
        expenseTypeOptions,
        setExpenseTypeOptions,
        supplierOptions,
        setSupplierOptions,
        yearOptions,
        setYearOptions
    } = useContext(PublicSectorSpendContext);

    let { organisationId } = useParams<{organisationId: string}>();

    const [scrollLoading, setScrollLoading] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const PAGE_SIZE = 20;

    const setOptions = (options: any) => {
        if (props.filterType === FilterType.EXPENSE_AREA) {
            setExpenseAreaOptions(options);
        }
        else if (props.filterType === FilterType.EXPENSE_TYPE) {
            setExpenseTypeOptions(options);
        }
        else if (props.filterType === FilterType.SUPPLIER) {
            setSupplierOptions(options);
        }
        else if (props.filterType === FilterType.POST_DATE) {
            setYearOptions(options);
        }
    };

    const getOptions = () => {
        if (props.filterType === FilterType.EXPENSE_AREA) {
            return expenseAreaOptions;
        }
        else if (props.filterType === FilterType.EXPENSE_TYPE) {
            return expenseTypeOptions;
        }
        else if (props.filterType === FilterType.SUPPLIER) {
            return supplierOptions;
        }
        else if (props.filterType === FilterType.POST_DATE) {
            return yearOptions;
        }
    };

    const [q, setQ] = useState<string>();
    const fetchFilters = async () => {
        setScrollLoading(true);
        await axios.post(organisationHostname + "organisation/" + organisationId + "/spend/filter-options?page=" + pageNumber + "&size=" + PAGE_SIZE, {
                filterType: props.filterType,
                filter: q
            })
            .then(r => {
                setOptions({
                    ...r.data,
                    content: [...getOptions()?.content, ...r.data.content]
                });
            })
            .catch(error => console.error(error.message))
            .finally(() => { setScrollLoading(false) })
    };

    useEffect(() => {
        fetchFilters();
    }, [pageNumber]);


    const onScroll = (event: any) => {
        const target = event.target;
        if (!scrollLoading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (getOptions()?.pageable?.pageNumber <= getOptions()?.totalPages) {
                setPageNumber(pageNumber + 1);
            }
        }
    };

    const debounced = AwesomeDebouncePromise((s: string) => {
        setQ(s);
        if (pageNumber !== 0) {
            setPageNumber(0);
        }
        else {
            fetchFilters();
        }
    }, 300);

    const search = async (e: string) => {
        await debounced(e);
    };

    return (
        <Select
            id={props.selectKey}
            disabled={false}
            showSearch={true}
            style={{
                width: "100%"
            }}
            dropdownStyle={{}}
            key={props.selectKey}
            placeholder={props.placeholder}
            size={"large"}
            value={props.selected}
            onChange={(val: string[]) => {
                props.setSelected(val)
            }}
            filterOption={true}
            loading={scrollLoading}
            onPopupScroll={onScroll}
            onSearch={search}
        >
            {getOptions()?.content?.filter((item: any) => item !== null)
                .map((o: any) => o[props.optionField])
                .filter((x: any, i: number, a: any[]) => a.indexOf(x) == i)
                .map((opt: any) => {
                    return (
                        <Option key={opt} value={opt}>{opt}</Option>
                    );
                })
            }
        </Select>

    );
};

export default PublicSectorSpendInfiniteSelect;
