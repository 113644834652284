import {faAddressBook, faBriefcase} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import ReactTimeAgo from "react-time-ago";
import {DateText, Icon, Item, ItemContent, NotificationItem, Text} from "../Notifications";

const JobsUpdatedNotification: React.FC<NotificationItem> = ({ element }) => {
    return (
        <Item read={element.read}>
            <Icon>
                <FontAwesomeIcon color={"white"} icon={faBriefcase} />
            </Icon>
            <ItemContent>
                <Text>
                    {+element.targets[0].placeholder === 0 ?
                        "We could not find any Jobs for your recent request, tweaking your term can yield better results."
                        :
                        <>
                            {[<>We found</>,
                                <a target={"_blank"} href="/people/jobs">{+element.targets[0].placeholder} Jobs</a>,
                                <>as a result of a recent job request.</>]
                                .reduce((prev: any, curr) =>
                                    [...prev, <> </>, curr], [])}
                        </>}
                </Text>
                <DateText>
                    <ReactTimeAgo date={new Date(element.modified)} locale="en-US" />
                </DateText>
            </ItemContent>
        </Item>
    )

}

export default JobsUpdatedNotification;