import React, {useEffect, useState} from "react";
import {Button, Col, Form as BSForm, Modal, Row} from "react-bootstrap";
import {Form, Select} from "antd";
import {countriesOptions, rolesOptions, seniorityOptions} from "../common/request-contacts/RequestContactsOptions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faTimes} from "@fortawesome/free-solid-svg-icons";
import translate from "../../i18n/translate";
import {OrganisationUser} from "../teams/edit/EditTeam";
import axios from "axios";
import {frontEndHostName} from "../../utils/Configuration";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {getUserUuid} from "../../utils/Security";

const Option = Select.Option;

export interface ContactFilters {
    jobTitles: string[],
    roles: string[],
    seniority: string[],
    countries: string[],
    userId: string | null,
    from: string,
    to: string,
}

interface Props {
    setFilters: (filters: ContactFilters) => void,
}

const ContactFiltersModal: React.FC<Props> = (props: Props) => {

    const [showModal, setShowModal] = useState<boolean>(false);

    const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
    const [organisationUsers, setOrganisationUsers] = useState<OrganisationUser[]>([]);

    useEffect(() => {
        const fetchOrganisationUsers = async () => {
            setLoadingUsers(true);

            await axios.get(frontEndHostName + "users/organisation/active")
                .then(r => {
                    let data = r.data;
                    setOrganisationUsers(data);
                })
                .finally(() => setLoadingUsers(false));
        };

        fetchOrganisationUsers();
    }, []);

    const [selectedJobTitles, setSelectedJobTitles] = useState<string[]>([]);
    const [selectedSeniority, setSelectedSeniority] = useState<string[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<string | null | undefined>(getUserUuid());
    const updateSelectedUser = (e: any) => {
        let val = e.target.value;
        if (!val) {
            setSelectedUserId(null);
        }

        setSelectedUserId(val);
    }

    const [dateFrom, setDateFrom] = useState<string>();
    const [dateTo, setDateTo] = useState<string>();

    const handleClose = () => {
        setShowModal(false);
    };

    const applyFilters = () => {
        let filters = {
            jobTitles: selectedJobTitles,
            roles: selectedRoles,
            seniority: selectedSeniority,
            countries: selectedCountries,
            userId: selectedUserId,
            from: dateFrom,
            to: dateTo
        } as ContactFilters;

        props.setFilters(filters);
        setShowModal(false);
    };


    const clearFilters = () => {
        setSelectedJobTitles([]);
        setSelectedRoles([]);
        setSelectedSeniority([]);
        setSelectedCountries([]);
        setDateFrom(undefined);
        setDateTo(undefined);
        let filters = {
            jobTitles: [],
            roles: [],
            seniority: [],
            countries: [],
            userId: selectedUserId,
            from: "",
            to: ""
        } as ContactFilters;

        props.setFilters(filters);
    }

    const filtersPresent = () => {
        return selectedJobTitles.length > 0 || selectedRoles.length > 0 || selectedSeniority.length > 0 || selectedCountries.length > 0
            || !!dateFrom || !!dateTo;
    }

    return (
        <>
            <button type="button" className="iq-action-button white pull-right" onClick={() => setShowModal(true)}>
                <span className="pe-2">{translate("contacts.filter.filters")}</span>
                <span>
                    <FontAwesomeIcon icon={faFilter} />
                </span>
            </button>

            <button type="button" className="iq-action-button white pull-right me-2" onClick={clearFilters} disabled={!filtersPresent()}>
                <span className="pe-2">{translate("contracts.clearfilters")}</span>
                <span>
                    <FontAwesomeIcon icon={faTimes} />
                </span>
            </button>

            <Modal show={showModal} onHide={handleClose} dialogClassName="modal-dialog modal-full-height modal-right modal-notify modal-success">
                <Modal.Header closeButton>
                    <Modal.Title>Filters</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form layout="vertical">
                        <Form.Item label="Discovered By">
                            {loadingUsers ? <IqLoadingIcon /> : (
                                <BSForm.Select className="form-control" value={selectedUserId as string} onChange={updateSelectedUser}>
                                    <option value="">Any</option>
                                    {organisationUsers.map(user => (
                                        <option value={user.uuid}>{user.firstName} {user.lastName}</option>
                                    ))}
                                </BSForm.Select>
                            )}
                        </Form.Item>

                        <Form.Item label={translate("contacts.discoveredon")}>
                            <Row>
                                <Col className="col-5">
                                    <input type="date" className="form-control" value={dateFrom} onChange={(e: any) => setDateFrom(e.target.value)}/>
                                </Col>
                                <Col className="text-center pt-2 col-2">
                                    <span>{translate("advancedsearch.to")}</span>
                                </Col>
                                <Col className="col-5">
                                    <input type="date" className="form-control" value={dateTo} onChange={(e: any) => setDateTo(e.target.value)}/>
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item label={translate("contacts.filter.jobtitles")}>
                            <Select
                                disabled={false}
                                mode={"tags"}
                                style={{
                                    minWidth: 200,
                                }}
                                dropdownStyle={{
                                    width: 250,
                                }}
                                key={"widget-multiselect"}
                                placeholder={translate("contacts.filter.jtplaceholder")}
                                size={"large"}
                                value={selectedJobTitles}
                                onChange={(val: string[]) => { setSelectedJobTitles(val) }}
                                filterOption={true}
                            >
                            </Select>
                        </Form.Item>
                        <Form.Item label={translate("contacts.filter.seniority")}>
                            <Select
                                disabled={false}
                                mode={"multiple"}
                                style={{
                                    minWidth: 200,
                                }}
                                dropdownStyle={{
                                    width: 250,
                                }}
                                key={"widget-multiselect"}
                                placeholder={translate("contacts.filter.seniorityplaceholder")}
                                size={"large"}
                                value={selectedSeniority}
                                onChange={(val: string[]) => { setSelectedSeniority(val) }}
                                filterOption={true}
                            >
                                {seniorityOptions.map(o => {
                                    return (<Option key={o} value={o}>{o}</Option>);
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item label={translate("contacts.filter.roles")}>
                            <Select
                                disabled={false}
                                mode={"multiple"}
                                style={{
                                    minWidth: 200,
                                }}
                                dropdownStyle={{
                                    width: 250,
                                }}
                                key={"widget-multiselect"}
                                placeholder={translate("contacts.filter.roleplaceholder")}
                                size={"large"}
                                value={selectedRoles}
                                onChange={(val: string[]) => { setSelectedRoles(val) }}
                                filterOption={true}
                            >
                                {rolesOptions.map(o => {
                                    return (<Option key={o} value={o}>{o}</Option>);
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item label={translate("contacts.filter.country")}>
                            <Select
                                disabled={false}
                                mode={"multiple"}
                                style={{
                                    minWidth: 200,
                                }}
                                dropdownStyle={{
                                    width: 250,
                                }}
                                key={"widget-multiselect"}
                                placeholder={translate("contacts.filter.countryplaceholder")}
                                size={"large"}
                                value={selectedCountries}
                                onChange={(val: string[]) => { setSelectedCountries(val) }}
                                filterOption={true}
                            >
                                {countriesOptions.map(o => {
                                    return (<Option key={o} value={o}>{o}</Option>);
                                })}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {translate("dashboard.close")}
                    </Button>
                    <Button variant="success" onClick={applyFilters}>
                        {translate("contacts.filter.apply")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default ContactFiltersModal;