import React from "react";
import {Renewal} from "../../model/renewals/Renewal";
import {RenewalsFilters} from "../../model/renewals/RenewalsFilters";

export enum RenewalsSort {
    VENDOR = "vendor",
    AGREEMENT_END_DATE = "agreementEndDate",
    END_USER = "endUser",
    PRODUCT_PROGRAM = "productProgram",
    AGREEMENT_STATUS = "agreementStatus",
    RENEWAL_VALUE = "productSellPrice"
}

const RenewalsContext = React.createContext({
    renewals: [] as Renewal[],
    setRenewals: (renewals: Renewal[]) => {},
    filters: {} as RenewalsFilters | null,
    setFilters: (filters: RenewalsFilters | null) => {},
    hideExpired: false,
    setHideExpired: (hide: boolean) => {},
    page: 0,
    setPage: (page: number) => {},
    pageSize: 20,
    setPageSize: (pageSize: number) => {},
    sort: RenewalsSort.AGREEMENT_END_DATE,
    setSort: (r: RenewalsSort) => {},
    desc: true,
    setDesc: (desc: boolean) => {},
    showFilters: false,
    setShowFilters: (show: boolean) => {},
    loading: false,
    setLoading: (loading: boolean) => {},
    vendors: [] as string[],
    setVendors: (vendors: string[]) => {},
    endUsers: [] as string[],
    setEndUsers: (endUsers: string[]) => {},
    loadingVendors: false,
    setLoadingVendors: (loading: boolean) => {},
    loadingEndUsers: false,
    setLoadingEndUsers: (loading: boolean) => {},
    totalRecords: 0,
    setTotalRecords: (records: number) => {}
});

export default RenewalsContext;