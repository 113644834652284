import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {Builder, ImmutableTree, JsonGroup, Query, Utils as QbUtils} from 'react-awesome-query-builder-opti';
import "antd/dist/antd.css";
import './AdvancedSearchCreation.css';
import 'react-awesome-query-builder-opti/lib/css/styles.css';
import {Button, Col, Container, FormControl, OverlayTrigger, Popover, Row} from 'react-bootstrap';
import axios from 'axios';
import {useHistory, useLocation} from 'react-router-dom';
import {searchHostname} from '../../utils/Configuration';
import {ConsumptionData} from '../teams/dashboard/TeamDashboardTable';
import IqSmallLoadingIcon from '../common/IqSmallLoadingIcon';
import FilterBubbleContainer, {QuickFilters} from './FilterBubbleContainer';
import AdvancedSearchContext from '../../context/advanced-search/AdvancedSearchContext';
import Filters from '../../model/advanced-search/Filters';
import {getChannelConfig, getEndUserConfig} from './AdvancedSearchConfig';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowCircleDown} from '@fortawesome/free-solid-svg-icons';
import ConditionalSwitch from './fields/ConditionalSwitch';
import translate from "../../i18n/translate";
import {format} from "d3-format";
import I18nContext from '../../context/I18nContext';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {showErrorWithMessage, showGenericErrorDialog} from "../common/CommonAlerts";

const AdvancedSearchCreation: React.FC = () => {

    const [filters, setFilters] = useState({
        accreditations: [],
        competencies: [],
        vendors: [],
        products: [],
        companyTypes: [],
        companyLegalTypes: [],
        sicCodes: [],
        nutsCodes: [],
        headOffice: [],
        tags: [],
        isicCodes: [],
        statuses: []
    } as Filters);

    const [filtersLoading, setFiltersLoading] = useState(true);

    interface GenericPayload {
        show: boolean,
        message: string,
        success: boolean,
        searchId: number
    }

    let history = useHistory();
    const transferredGraph: any = useLocation().state;

    const defaultTreeGraph: any = () => {
        const uuid1 = QbUtils.uuid();

        return (
            {
                "id": uuid1,
                "type": "group",
                "properties": {
                    "conjunction": "AND"
                },
                "children1": {
                    [QuickFilters.Status]: {
                        "type": "rule",
                        "properties": {
                            "field": "companyStatus.keyword",
                            "operator": "select_any_in",
                            "value": [
                                [
                                    "Active"
                                ]
                            ],
                            "valueSrc": [
                                "value"
                            ],
                            "valueType": [
                                "multiselect"
                            ]
                        },
                        "id":   QuickFilters.Status,
                        "path": [
                            uuid1,
                            QuickFilters.Status
                        ]
                    }
                },
                "path": [
                    uuid1
                ]
            }

        )
    }

    const queryValue: JsonGroup = transferredGraph != null ? JSON.parse(transferredGraph.searchGraph) : defaultTreeGraph();
    const {lang, setLang} = useContext(I18nContext);

    const [searchType, setSearchType] = useState<string>(transferredGraph != null ? transferredGraph.searchType : "endUser");
    const [graphHidden, setGraphHidden] = useState<boolean>(transferredGraph == null);
    const [counter, setCounter] = useState(0);
    const [searchName, setSearchName] = useState(transferredGraph?.searchName != null ? transferredGraph.searchName : "");
    const [config, setConfig] = useState(getEndUserConfig(filters, lang));
    const [tree, setTree] = useState(QbUtils.checkTree(QbUtils.loadTree(queryValue), config));
    const [searchCreditUsage, setSearchCreditUsage] = useState<ConsumptionData>();
    const [loadingSearchCredits, setLoadingSearchCredits] = useState<boolean>(false);
    const [loadingCounter, setLoadingCounter] = useState<boolean>(false);

    const [unsavedFilters, setUnsavedFilters] = useState<string[]>([]);
    const setEditing = (filter: string) => {
        setUnsavedFilters([
            ...unsavedFilters,
            filter
        ]);
    }

    const trimTitleAndSet = (searchTitle: string) => {
        if (searchTitle !== null) {
            if (searchTitle.trimLeft() !== null) {
                setSearchName(searchTitle.trimLeft());
            }
        }
    }

    const clearEdit = (filter: string) => {
        let newFilters = unsavedFilters.filter(s => s !== filter);
        setUnsavedFilters(newFilters);
    }


    useEffect(() => {
        const fetchCount = async () => {
            setLoadingCounter(true);
            const result = await axios.post(searchHostname + 'advanced-search/' + searchType + '/count', JSON.stringify(tree), {
                headers: { 'Content-Type': 'text/plain' }
            })
                .then((response) => {
                    setCounter(response.data);
                    setLoadingCounter(false);
                }).catch(error => console.log(error.message));
        };

        fetchCount();
    }, [tree]);

    useEffect(() => {
        const fetchFilters = async () => {
            const result = await axios.get(`${searchHostname}advanced-search/getFilters?timestamp=${new Date().getTime()}`)
                .then((response) => {
                    setFilters((response.data as Filters));
                }).catch(error => console.log(error.message));
        };

        const fetchSearchCredits = async () => {
            setLoadingSearchCredits(true);
            axios.get(searchHostname + "search-credits")
                .then(r => setSearchCreditUsage(r.data))
                .catch(error => console.error(error.message))
                .finally(() => setLoadingSearchCredits(false));
        };

        fetchFilters();
        fetchSearchCredits();
    }, []);

    useEffect(() => {
        let objectTree = QbUtils.getTree(tree);
        if (!objectTree.hasOwnProperty('children1')) {
            setGraphHidden(true);
        } else {
            setGraphHidden(false)
        }
    },[tree])


    const [delayRender, setRenderDelay] = useState<boolean>(false);

    useEffect(() => {
        setRenderDelay(true);
        if (searchType === "channel") {
            setConfig(getChannelConfig(filters));
        }
        else if (searchType === "endUser") {
            setConfig(getEndUserConfig(filters, lang));
        }
    }, [filters, searchType]);

    useEffect(() => {
        if (searchType === "channel") {
            setTree(QbUtils.checkTree(QbUtils.loadTree(queryValue), getChannelConfig(filters)));
        }
        else if (searchType === "endUser") {
            setTree(QbUtils.checkTree(QbUtils.loadTree(queryValue), getEndUserConfig(filters, lang)));
        }
        setRenderDelay(false);
    }, [config]);

    const onSubmit = () => {

        let searchId = transferredGraph?.searchId || null;

        axios.post(searchHostname + 'advanced-search', {
            "searchGraph": JSON.stringify(tree),
            "name": searchName,
            "type": searchType,
            "id": searchId
        }, {
            headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
            const SuccessDialog = withReactContent(Swal);
            SuccessDialog.fire({
                    icon: "success",
                    title: "Success!",
                    text: translate("advancedsearch.createsuccess")
                })
                .then(() => history.push("/advanced-search/" + response.data.id))
        }).catch(error => {
            if (error.response?.data?.errorKey === "nocredit") {
                showErrorWithMessage("No search credits left.", null);
            } else {
                showGenericErrorDialog();
            }
        });

    };

    const renderBuilder: React.FC<any> = (props) => (
        <div className="query-builder-container" style={{ padding: '10px' }}>
            <div className="query-builder qb-lite">
                <Builder {...props} />
            </div>
        </div>
    );


    const renderResult: React.FC<any> = ({ tree: immutableTree, config }) => (
        <div className="query-builder-result">
        </div>
    );

    const onChange = (immutableTree: ImmutableTree, config: any) => {
        // Tip: for better performance you can apply `throttle` - see `examples/demo`
        setTree(immutableTree);
        // setConfig(config);
    };

    const showSubmitDialog = () => {
        const SubmitDialog = withReactContent(Swal);
        SubmitDialog.fire({
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: translate("advancedsearch.search"),
                title: translate("advancedsearch.confirmtitle"),
                focusCancel: true,
                text: translate("advancedsearch.confirm")
            })
            .then(result => {
                if (result.isConfirmed) {
                    onSubmit();
                }
            });
    }

    const showUnsavedConfirmDialog = () => {
        const SubmitDialog = withReactContent(Swal);
        SubmitDialog.fire({
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: translate("advancedsearch.goback"),
                confirmButtonText: translate("advancedsearch.search"),
                title: translate("advancedsearch.unappliedtitle"),
                focusCancel: true,
                text: translate("advancedsearch.unappliedfilters")
            })
            .then(result => {
                if (result.isConfirmed) {
                    onSubmit();
                }
            });
    }

    const runSearch = (e: React.MouseEvent<Element, MouseEvent>) => {
        if (unsavedFilters && unsavedFilters.length > 0) {
            showUnsavedConfirmDialog();
        }
        else {
            showSubmitDialog();
        }
    }

    return (
        <div>
            <Container className="advanced-search-creation-container" fluid style={{ marginTop: 15 }}>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="10">
                        <Row className="" style={{ marginLeft: 0, marginRight: 0, marginTop: "15px" }}>
                            <Col xs="12" sm="12" md="5" lg="5" xl="6">
                                <div className="advanced-search-creation-widget">
                                    <span className="title">
                                        {translate("advancedsearch.name")}
                                    </span>

                                    <FormControl style={ searchName ? {marginTop: "15px"} : { marginTop: "15px", boxShadow:  "0px 0px 3px 2px #BB3241" }} value={searchName} onChange={(e: ChangeEvent<HTMLInputElement>) => { trimTitleAndSet(e.target.value) }} type="text" placeholder="Name" />
                                    {searchName ? null : (<p style={{ color: "#C5515E", paddingTop: "0.33rem"}}>Please provide a valid search name.</p>)}
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="5" lg="5" xl="4">
                                <div className="advanced-search-creation-widget">
                                    <span className="title">
                                        {translate("advancedsearch.searchtype")}
                                    </span>
                                    <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "15px" }} className="iq-button-group">
                                        <button className={searchType === "endUser" ? "selected-inverted" : ""}
                                            onClick={(e) => { setSearchType("endUser") }}>
                                            {translate("advancedsearch.enduser")}
                                        </button>
                                        <button className={searchType === "channel" ? "selected-inverted" : ""}
                                            onClick={(e) => { setSearchType("channel") }}>
                                            {translate("advancedsearch.channel")}
                                        </button>
                                    </div>
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="2" lg="2" xl="2">
                                <div className="advanced-search-creation-widget">
                                    <span className="title">
                                        {translate("advancedsearch.remainingcredits")}
                                    </span>
                                    <div style={{ textAlign: "center", fontSize: "38px" }}>
                                        {loadingSearchCredits
                                            ?
                                            <IqSmallLoadingIcon />
                                            :
                                            searchCreditUsage != null ?
                                                searchCreditUsage.capacity - searchCreditUsage.consumed
                                                :
                                                "N/A"
                                        }
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        {transferredGraph == null &&
                            <Row className="" style={{ marginLeft: 0, marginRight: 0, marginTop: "15px" }}>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12">

                                    <div className="advanced-search-creation-filter-cloud">
                                        <div style={{ marginBottom: 10 }}>
                                            <span className="title">{translate("advancedsearch.quickfilters")}</span>
                                        </div>
                                        <AdvancedSearchContext.Provider value={{ tree, setTree, filters, config }}>
                                            <ConditionalSwitch />
                                            <FilterBubbleContainer searchType={searchType} setEditing={setEditing} clearEdit={clearEdit} />
                                        </AdvancedSearchContext.Provider>
                                    </div>
                                </Col>

                            </Row>
                        }
                        <Row className="" style={{ marginLeft: 0, marginRight: 0, marginTop: "15px" }}>
                            <Col style={{ minHeight: "100%" }} xs="12" sm="12" md="12" lg="12">
                                    <div
                                        className={"advanced-search-creation-expand-graph " + (!graphHidden && "hidden")}>
                                        <FontAwesomeIcon style={{cursor: "pointer"}}
                                                         onClick={() => setGraphHidden(false)} icon={faArrowCircleDown}
                                                         color="#3B97E8" size="3x"/>
                                    </div>

                                <div className={"advanced-search-creation-graph " + (graphHidden  && "hidden")}>
                                    <span className="title">
                                        {translate("advancedsearch.morefilters")}
                                    </span>
                                    {!delayRender &&
                                        <Query
                                            {...config}
                                            value={tree}
                                            onChange={onChange}
                                            renderBuilder={renderBuilder}
                                        />
                                    }
                                </div>
                                {renderResult(tree, config)}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2">
                        <div className="advanced-search-creation-results">
                            <span className="title">{translate("advancedsearch.companiesfound")}</span>
                            <div className="advanced-search-counter">
                                <span>
                                    {loadingCounter
                                        ?
                                        <IqSmallLoadingIcon />
                                        :
                                        counter != null ?
                                            counter == 10000 ?
                                                "> " + counter
                                                :
                                                format(',')(counter)
                                            :
                                            "N/A"
                                    }
                                </span>
                            </div>
                            <Button style={{ width: "100%" }} onClick={() => { setCounter(0); setTree(QbUtils.loadTree(queryValue)); }} variant="outline-secondary">
                                {translate("advancedsearch.reset")}
                            </Button>

                            <OverlayTrigger
                                trigger="hover"
                                placement="bottom"
                                rootClose={true}
                                overlay={
                                <Popover id="featured-in-lists">
                                    { searchName.trim().length == 0 ? "If no valid search name is entered, it is not possible to perform a search." : null}
                                </Popover>
                            }>

                                <Button
                                    disabled={searchName.trim().length == 0}
                                    onClick={runSearch}
                                    style={{ marginTop: "15px", width: "100%" }}
                                    variant="primary"
                                    as="button"
                                    className="iqx-button"
                                    title={ searchName.trim().length == 0 ? "If no valid search name is entered, it is not possible to perform a search." : null}
                                >
                                    {translate("advancedsearch.search")}
                                </Button>

                            </OverlayTrigger>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default AdvancedSearchCreation;
