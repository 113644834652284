import React, {useEffect, useState} from "react";
import axios from "axios";
import {socialHostName} from "../../utils/Configuration";
import qs from "qs";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faSort,
    faSortAmountDown,
    faSortAmountDownAlt,
    faSync,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {Alert, Col, Form, Row, Table} from "react-bootstrap";
import HubSpotCompany from "../../model/hubspot/HubSpotCompany";
import DateFormatter from "../../utils/formatters/DateFormatter";
import Pagination from "../../utils/PaginationHook";

enum Sort {
    NAME = "company.name",
    DOMAIN = "company.domain",
    CREATED_DATE = "company.createdAt",
    UPDATED_DATE = "company.updatedAt"
}

const HubSpotCompanies: React.FC = () => {

    const [companies, setCompanies] = useState<HubSpotCompany[]>([]);
    const [totalRecords, setTotalRecords] = useState<number>();

    const [sort, setSort] = useState<Sort>();
    const [desc, setDesc] = useState<boolean>(false);

    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== pageSize) {
            setPageSize(size);
        }
    };

    const [loading, setLoading] = useState<boolean>(false);
    const [syncInProgress, setSyncInProgress] = useState<boolean>(false);
    const syncCompanies = () => {
        setLoading(true);
        axios.get(socialHostName + "hubspot/fetch-companies")
            .then(() => setSyncInProgress(true))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchCompanies();
    }, [page, pageSize, sort, desc]);

    const fetchCompanies = async () => {
        setLoading(true);
        let params: any = {
            page: page - 1,
            size: pageSize
        };

        if (sort) {
            params["sort"] = sort + "," + (desc ? "desc" : "asc");
        }

        await axios.get(socialHostName + "hubspot/companies", {
            params: params,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            }
        })
            .then(r => {
                setCompanies(r.data.content);
                setTotalRecords(r.data.totalElements);
            })
            .finally(() => setLoading(false));
    };

    const getSortButton = (s: Sort) => {
        if (s === sort) {
            return (
                <a className="sort-button"
                   onClick={() => {
                       setDesc(!desc);
                   }}>
                    <FontAwesomeIcon icon={desc ? faSortAmountDown : faSortAmountDownAlt} />
                </a>
            );
        }

        return (
            <a className="sort-button"
               onClick={() => {
                   setSort(s);
                   setDesc(true);
               }}>
                <FontAwesomeIcon icon={faSort} />
            </a>
        );
    };

    const formatPhoneNumber = (phoneNumber: string) => {
        if (!phoneNumber) {
            return "-";
        }

        return phoneNumber;
    };

    return (
        <div style={{
            paddingTop: "10px",
            backgroundColor: "white",
            borderRadius: "0.45rem",
            padding: 15,
            marginTop: 10
        }}>
            <Row>
                <Col className="pt-2" lg={4} md={5} sm={3} xs={3}>
                    <span style={{marginLeft: "auto"}}>
                        <button onClick={syncCompanies} className="iqx-button md-size dark-blue">
                            <span style={{paddingRight: 10}}>Sync HubSpot Companies</span>
                            <FontAwesomeIcon icon={faSync} color="white" size="1x"/>
                        </button>
                    </span>
                </Col>
            </Row>

            <div className="mt-3">
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>
                                Name
                                {getSortButton(Sort.NAME)}
                            </th>
                            <th>
                                Domain
                                {getSortButton(Sort.DOMAIN)}
                            </th>
                            <th className="text-center">
                                Archived
                            </th>
                            <th className="text-end">
                                Phone Number
                            </th>
                            <th>
                                Created Date
                                {getSortButton(Sort.CREATED_DATE)}
                            </th>
                            <th>
                                Updated Date
                                {getSortButton(Sort.UPDATED_DATE)}
                            </th>
                        </tr>
                    </thead>
                    {!loading && (
                        <tbody>
                            {companies.map((company: HubSpotCompany) => (
                                <tr key={company.id}>
                                    <td>{company.name}</td>
                                    <td>{company.domain}</td>
                                    <td className="text-center">{company.archived ? <FontAwesomeIcon icon={faCheckCircle} color="#2bcb14"/> : <FontAwesomeIcon icon={faTimesCircle} color="#a82509" />}</td>
                                    <td className="text-end">{formatPhoneNumber(company.phoneNumber)}</td>
                                    <td>{DateFormatter.formatDate(company.createdAt)}</td>
                                    <td>{DateFormatter.formatDate(company.updatedAt)}</td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                </Table>

                {loading && <IqLoadingIcon />}


                {syncInProgress && (
                    <Alert variant="info" style={{ width: "100%", marginTop: 25 }}>
                        Companies sync in progress. Please check later.
                    </Alert>
                )}

                <div className="mt-4">
                    <div className="justify-content-md-end pagination mt-n2">
                        <span className="me-2">
                            <Form.Control as="select"
                                          style={{ minWidth: "13%" }}
                                          defaultValue={pageSize}
                                          onChange={(event) => changePageSize(event)}
                                          >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                            </Form.Control>
                        </span>
                        <span>
                            {totalRecords && (
                                <Pagination totalRecords={totalRecords}
                                            pageLimit={pageSize}
                                            pageRangeDisplayed={1}
                                            initPage={page}
                                            onChangePage={setPage}
                                />
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HubSpotCompanies;