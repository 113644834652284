import React from "react";

const PublicSectorSpendContext = React.createContext({
    expenseAreaOptions: [] as any,
    setExpenseAreaOptions: (options: any) => {},
    expenseTypeOptions: [] as any,
    setExpenseTypeOptions: (options: any) => {},
    supplierOptions: [] as any,
    setSupplierOptions:(options: any) => {},
    yearOptions: [] as any,
    setYearOptions: (options: any) => {}
});

export default PublicSectorSpendContext;
