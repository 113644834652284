import {
    faBoxOpen,
    faCalendarCheck,
    faCalendarTimes,
    faHashtag,
    faHome,
    faHourglassEnd,
    faLevelUpAlt,
    faLink,
    faPhone,
    faStarHalfAlt
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useContext} from 'react';
import UserOrganisationContext from '../../context/organisation/OrganisationContext';
import './OrganisationSummaryDetails.css';
import translate from "../../i18n/translate";
import OrganisationSocialContext from '../../context/organisation/OrganisationSocialContext';
import OrganisationFinancialSummary from "../../model/organisation/OrganisationFinancialSummary";


interface Props {
    address: string
}
const OrganisationSummaryDetails: React.FC<Props> = (props: Props) => {

    const { organisation } = useContext(UserOrganisationContext);
    const { digitalMaturityScore } = useContext(OrganisationSocialContext);

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    const getUrl = (website: string) => {
        if (website?.startsWith("http")) {
            return website;
        }

        if (website?.startsWith("www.")) {
            return `https://${website}`;
        }

        return `https://www.${website}`;
    };

    const longNumberToAbbreviatedString = (num: number) => {
        if (num < 1e3) return num;
        if (num >= 1e3 && num < 1e6) return +(num / 1e3).toFixed(1) + "K";
        if (num >= 1e6 && num < 1e9) return +(num / 1e6).toFixed(1) + "M";
        if (num >= 1e9 && num < 1e12) return +(num / 1e9).toFixed(1) + "B";
        if (num >= 1e12) return +(num / 1e12).toFixed(1) + "T";
    }

    const getFiscalYearEnd = (financialSummary: OrganisationFinancialSummary) => {
        let date: string = financialSummary?.dateOfAccounts;
        if (date === null) { return date;}
        let dateSplit = date.split("/");
        if (parseInt(dateSplit[1]) > 12) {
            return dateSplit[1] + "/" + (dateSplit[0]?.length < 2 ? ["0", dateSplit[0]].join("") : dateSplit[0])
        }
        return date.substring(0, date.lastIndexOf('/'))
    }


    const bits = [
        {
            name: "organisation.summary.companyinfo.website",
            icon: <FontAwesomeIcon icon={faLink} color="white" size="lg" />,
            exists: organisation.website != null,
            value: <a rel="external" href={getUrl(organisation.website)} target={"_blank"}>{organisation.website}</a>
        },
        {
            name: "organisation.summary.companyinfo.address",
            icon: <FontAwesomeIcon icon={faHome} color="white" size="lg" />,
            exists: props.address != null && props.address !== "",
            value: props.address
        },
        {
            name: "organisation.summary.companyinfo.phone",
            icon: <FontAwesomeIcon icon={faPhone} color="white" size="lg" />,
            exists: organisation.phoneNumber != null,
            value: organisation.phoneNumber
        },
        {
            name: "lists.companynumber",
            icon: <FontAwesomeIcon icon={faHashtag} color="white" size="lg" />,
            exists: organisation.primaryType !== "PublicSector" && (organisation.companyNumber != null && organisation.companyNumber !== ""),
            value: organisation.companyNumber
        },
        {
            name: "lists.established",
            icon: <FontAwesomeIcon icon={faCalendarTimes} color="white" size="lg" />,
            exists: organisation.yearEstablished != null,
            value: new Date(organisation.yearEstablished).getFullYear()
        },
        {
            name: "organisation.summary.companyinfo.financialyearend",
            icon: <FontAwesomeIcon icon={faCalendarCheck} color="white" size="lg" />,
            exists: organisation.accountsRefDay && organisation.accountsRefMonth,
            value: organisation.accountsRefDay + " " + monthNames[organisation.accountsRefMonth - 1]
        },
        {
            name: "organisation.summary.companyinfo.legalform",
            icon: <FontAwesomeIcon icon={faBoxOpen} color="white" size="lg" />,
            exists: organisation.companyCategory != null,
            value: organisation.companyCategory
        },
        {
            name: "organisation.summary.companyinfo.investment",
            icon: <FontAwesomeIcon icon={faLevelUpAlt} color="white" size="lg" />,
            exists: organisation.financialSummary?.length > 0 && organisation.financialSummary[0].investmentRaised,
            value: organisation.financialSummary?.length > 0 ? '$' + longNumberToAbbreviatedString(organisation.financialSummary[0].investmentRaised?.valueOf()) : 0
        },
        {
            name: "organisation.summary.companyinfo.fiscalyearend",
            icon: <FontAwesomeIcon icon={faHourglassEnd} color="white" size="lg" />,
            exists: organisation.financialSummary?.length > 0 && organisation.financialSummary[0].dateOfAccounts,
            value: organisation.financialSummary?.length > 0 ? getFiscalYearEnd(organisation.financialSummary[0]) : 0
        }
    ];

    if (organisation.primaryType !== "EndUser") {
        bits.push(
            {
                name: "organisation.summary.companyinfo.digitalmaturity",
                icon: <FontAwesomeIcon icon={faStarHalfAlt} color="white" size="lg" />,
                exists: digitalMaturityScore?.totalScore,
                value: [digitalMaturityScore?.totalScore, "100"].join('/')
            }
        )
    }

    return (
        <div className="organisation-details-container">
            {bits.filter(b => b.exists)
                .map(bit => (
                    <span className="organisation-details-markup" key={bit.name}>
                        <span className="left">
                            <span className="logo">
                                {bit.icon}
                            </span>
                            <span className="text">
                                {translate(bit.name)}
                            </span>
                        </span>
                        <span className="right">
                            {bit.value}
                        </span>
                    </span>
                )
                )}
        </div>
    );
};

export default OrganisationSummaryDetails;
