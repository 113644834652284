import axios from "axios";
import React, {useContext, useEffect, useState} from "react";
import {Redirect, Route, useRouteMatch} from "react-router-dom";
import UserOrganisationContext from "../../../context/organisation/OrganisationContext";
import PublicSectorContext, {
    CCSFilters,
    CCSSort,
    FundingSort,
    GCloudFilters,
    GCloudSort,
    ResearchFundingFilters
} from "../../../context/PublicSectorContext";
import CCSFramework from "../../../model/publicsector/CCSFramework";
import CCSFrameworkIdsAndName from "../../../model/publicsector/CCSFrameworkIdsAndName";
import Funding from "../../../model/publicsector/Funding";
import GCloudSupplier from "../../../model/publicsector/GCloudSupplier";
import {contractsHostName} from "../../../utils/Configuration";
import {hasUserRole, Role} from "../../../utils/Security";
import CCSFrameworks from "../../publicsector/framework/CCSFrameworks";
import CCSFrameworkView from "../../publicsector/framework/CCSFrameworkView";
import GCloudView from "../../publicsector/framework/GCloudView";
import PublicResearchFunding from "../../publicsector/funding/PublicResearchFunding";
import PublicResearchFundingView from "../../publicsector/funding/PublicResearchFundingView";
import OrganisationPublicSectorView from "./OrganisationPublicSectorView";
import OrganisationContractsContainer from "./OrganisationContractsContainer";
import IqLoadingIcon from "../../common/IqLoadingIcon";

interface Props {
    gCloudProductTotal: number
}

const OrganisationPublicSectorCotainer: React.FC<Props> = (props: Props) => {

    const {
        organisation
    } = useContext(UserOrganisationContext);

    let match = useRouteMatch();

    const [ccsFrameworks, setCCSFrameworks] = useState<CCSFramework[]>([]);
    const [loadingCCS, setLoadingCCS] = useState<boolean>(false);

    const [gCloudSuppliers, setGCloudSuppliers] = useState<GCloudSupplier[]>([]);
    const [loadingGCloud, setLoadingGCloud] = useState<boolean>(false);
    const [gCloudPage, setGCloudPage] = useState<number>(1);
    const [gCloudPageSize, setGCloudPageSize] = useState<number>(10);
    const [gCloudTotal, setGCloudTotal] = useState<number>(props.gCloudProductTotal);

    const [gCloudSort, setGCloudSort] = useState<GCloudSort>(GCloudSort.NAME);
    const [gCloudDesc, setGCloudDesc] = useState<boolean>(false);
    const [gCloudFilters, setGCloudFilters] = useState<GCloudFilters>({} as GCloudFilters);

    const [ccsPage, setCCSPage] = useState<number>(1);
    const [ccsPageSize, setCCSPageSize] = useState<number>(10);
    const [ccsTotal, setCCSTotal] = useState<number>(0);

    const [ccsSort, setCCSSort] = useState<CCSSort>(CCSSort.END_DATE);
    const [ccsDesc, setCCSDesc] = useState<boolean>(false);

    const [frameworkIds, setFrameworkIds] = useState<string[]>([]);
    const [ccsFilters, setCCSFilters] = useState<CCSFilters>({} as CCSFilters);
    const [frameworkIdsAndName, setFrameworkIdsAndName] = useState<CCSFrameworkIdsAndName[]>([])

    const [funding, setFunding] = useState<Funding[]>([]);
    const [loadingFunding, setLoadingFunding] = useState<boolean>(false);
    const [fundingPage, setFundingPage] = useState<number>(1);
    const [fundingPageSize, setFundingPageSize] = useState<number>(10);
    const [fundingTotal, setFundingTotal] = useState<number>(0);

    const [fundingSort, setFundingSort] = useState<FundingSort>(FundingSort.END_DATE);
    const [fundingDesc, setFundingDesc] = useState<boolean>(true);

    const [researchFundingFilters, setResearchFundingFilters] = useState<ResearchFundingFilters>({} as ResearchFundingFilters);


    useEffect(() => {
        const fetchCCSFrameworks = async () => {
            setLoadingCCS(true);
            let params: any = {
                page: ccsPage - 1,
                size: ccsPageSize,
                sort: ccsSort + "," + (ccsDesc ? "DESC" : "ASC")
            }

            await axios.post(contractsHostName + "ccs-framework/organisation/" + organisation.id, ccsFilters, {params: params})
                .then(r => {
                    setCCSFrameworks(r.data.content);
                    setCCSTotal(r.data.totalElements);
                })
                .finally(() => setLoadingCCS(false));
        }

        fetchCCSFrameworks();
    }, [ccsPage, ccsPageSize, ccsSort, ccsDesc, ccsFilters]);

    useEffect(() => {
        const fetchFunding = async () => {
            setLoadingFunding(true);
            let params: any = {
                page: fundingPage - 1,
                size: fundingPageSize,
                sort: fundingSort + "," + (fundingDesc ? "DESC" : "ASC")
            }

            await axios.post(contractsHostName + "public-research-funding/organisation/" + organisation.id, researchFundingFilters, {params: params})
                .then(r => {
                    setFunding(r.data.content);
                    setFundingTotal(r.data.totalElements);
                })
                .finally(() => setLoadingFunding(false));
        }
        fetchFunding();
    }, [fundingPage, fundingPageSize, fundingSort, fundingDesc, researchFundingFilters]);

    useEffect(() => {
        const fetchFrameworkIds = async () => {
            await axios.get(contractsHostName + "ccs-framework/framework-ids")
                .then((r) => setFrameworkIds(r.data));
        }

        fetchFrameworkIds();
    }, []);

    useEffect(() => {
        const fetchFrameworkIdsAndName = async () => {
            await axios.get(contractsHostName + "ccs-framework/name")
                .then((r) => setFrameworkIdsAndName(r.data));
        }

        fetchFrameworkIdsAndName();
    }, []);

    return (
        <PublicSectorContext.Provider value={{
            ccsFrameworks, setCCSFrameworks,
            loadingCCS, setLoadingCCS,
            ccsPage, setCCSPage,
            ccsPageSize, setCCSPageSize,
            ccsTotal, setCCSTotal,
            ccsSort, setCCSSort,
            ccsDesc, setCCSDesc,
            ccsFilters, setCCSFilters,
            frameworkIds, setFrameworkIds,
            frameworkIdsAndName, setFrameworkIdsAndName,
            gCloudSuppliers, setGCloudSuppliers,
            loadingGCloud, setLoadingGCloud,
            gCloudPage, setGCloudPage,
            gCloudPageSize, setGCloudPageSize,
            gCloudTotal, setGCloudTotal,
            gCloudSort, setGCloudSort,
            gCloudDesc, setGCloudDesc,
            gCloudFilters, setGCloudFilters,
            funding, setFunding,
            loadingFunding, setLoadingFunding,
            fundingPage, setFundingPage,
            fundingPageSize, setFundingPageSize,
            fundingTotal, setFundingTotal,
            fundingSort, setFundingSort,
            fundingDesc, setFundingDesc,
            researchFundingFilters, setResearchFundingFilters
        }}>
            <OrganisationPublicSectorView></OrganisationPublicSectorView>
            {hasUserRole(Role.GEO_UK) &&
                <Route exact strict path={`${match.url}/frameworks`}>
                    <div style={{
                        paddingTop: "10px",
                        backgroundColor: "white",
                        borderRadius: "0.45rem",
                        padding: 15,
                        marginTop: 10
                    }}>
                        <CCSFrameworks/>
                    </div>

                    </Route>}
                {hasUserRole(Role.GEO_UK) &&
                    <Route exact strict path={`${match.url}/funding`} component={PublicResearchFunding}/>}

                {hasUserRole(Role.GEO_UK) &&
                    <Route exact path={`${match.url}/frameworks/ccs/:frameworkId`} component={CCSFrameworkView}/>}
                {hasUserRole(Role.GEO_UK) &&
                    <Route exact path={`${match.url}/g-cloud`} component={GCloudView}/>}
                {hasUserRole(Role.GEO_UK) &&
                    <Route exact path={`${match.url}/funding/project/:projectId`} component={PublicResearchFundingView}/>}
                {hasUserRole(Role.GEO_UK) &&
                    <Route exact strict path={`${match.url}/contracts`} component={OrganisationContractsContainer}/>}
                {hasUserRole(Role.GEO_UK) &&
                    <Route exact path={`${match.url}`}>
                        <Redirect to={`${match.url}/contracts`}></Redirect>
                    </Route>
                }

        </PublicSectorContext.Provider>
    );
}

export default OrganisationPublicSectorCotainer;