import axios from 'axios';
import React, {useContext, useEffect, useState} from "react";
import { Dropdown, Row } from 'react-bootstrap';
import { matchPath, useLocation } from 'react-router-dom';
import UserListContext from '../../../context/list/UserListContext';
import translate from '../../../i18n/translate';
import { JobRequest } from '../../../model/organisation/JobRequest';
import { socialHostName } from '../../../utils/Configuration';
import { hasAnyRole, hasUserRole, Role } from '../../../utils/Security';
import UserJobHome from '../../jobs/UserJobHome';


const UserListJobs: React.FC = () => {

    const { showJobsModal, setShowJobsModal } = useContext(UserListContext);

    let location = useLocation()
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/lists/:listId',
        exact: false,
        strict: false
    });

    return (
        <div style={{ paddingTop: 10, padding: 15, marginTop: 10, width: "100%" }}>
            <Row className="justify-content-md-end mb-4">
                {hasUserRole(Role.INTERNAL) && (
                    <div className="col">
                        <button className="iqx-button primary md-size me-2"
                                style={{ marginLeft: 5 }}
                                onClick={() => setShowJobsModal(true)}>
                            {translate("lists.requestjobs")}
                        </button>
                    </div>
                )}
            </Row>
            <UserJobHome />
        </div>
    )
}

export default UserListJobs;