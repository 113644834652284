import React from 'react';
import {Col, Container, Nav, Row} from "react-bootstrap";
import {NavLink, useRouteMatch} from "react-router-dom";
import './AdminContainer.css';
import AdminSubContainer from './AdminSubContainer';

const AdminContainer: React.FC = () => {

    let match = useRouteMatch();

    return (
        <div>
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Row className="" style={{ marginLeft: 0, marginRight: 0 }}>
                    <Col xs={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Nav variant="tabs" defaultActiveKey="/home">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to={`${match.url}/users`}>Users</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={NavLink} to={`${match.url}/analytics`}>Analytics</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={NavLink} to={`${match.url}/authorities`}>Authorities</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={NavLink} to={`${match.url}/gdpr`}>GDPR</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={NavLink} to={`${match.url}/service-status`}>Service Status</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: "#FFFFFF" }}>
                    <AdminSubContainer />
                </Row>
            </Container>
        </div>
    );
}

export default AdminContainer;
