import {
    faCalendar,
    faChevronRight,
    faPlay,
    faRecycle,
    faSearch,
    faShare,
    faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import translate from "../../i18n/translate";
import AdvancedSearch from "../../model/advanced-search/AdvancedSearch";
import DateFormatter from "../../utils/formatters/DateFormatter";
import {getUserUuid} from "../../utils/Security";
import AdvancedSearchShare from "./AdvancedSearchShare";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";

interface AdvancedSearchItemProps {
    search: AdvancedSearch,
    setShowRerun: any;
    setShowDelete: any;
}
const AdvancedSearchItem: React.FC<AdvancedSearchItemProps> = (props: AdvancedSearchItemProps) => {

    const [search, setSearch] = useState<AdvancedSearch>(props.search);
    const [isBeingShared, setBeingShared] = useState<boolean>(false);
    let history = useHistory();

    const hasExpired = (search: AdvancedSearch) => {
        return new Date(search.expiringOn).getTime() < new Date().getTime();
    };

    const getExpiryMessage = (search: AdvancedSearch) => {
        let messageKey = hasExpired(search) ? "advancedsearch.expiredon" : "advancedsearch.expiringon";
        return translate(messageKey, { date: DateFormatter.formatDate(search.expiringOn.toString()) })
    };

    return (
        <div className={"adv-search-card" + (hasExpired(search) ? " expired-card" : "")}>
            <div className={hasExpired(search) ? "expired" : ""}>
                <span className="adv-search-magnifier-box">
                    <FontAwesomeIcon icon={faSearch} color="white" size="1x" />
                </span>
                <a className="name" href={(("/advanced-search/" + search.id))}>
                    {search.name}
                </a>
            </div>
            <div className={"adv-search-content-container" + hasExpired(search) ? " expired" : ""}>
                <div className="adv-search-details-container">
                    <div className="adv-search-detail">
                        <div className="adv-search-detail-container">
                            <span><FontAwesomeIcon style={{ marginLeft: 5, marginRight: 5 }} className="icon" icon={faCalendar} color="#9120C3" size="1x" /></span>
                            <span>{translate("advancedsearch.createdon", { date: DateFormatter.formatDate(search.createdOn.toString()) })}</span>
                        </div>
                    </div>
                    <div className="adv-search-detail">
                        <div className="adv-search-detail-container">
                            <span><FontAwesomeIcon style={{ marginLeft: 5, marginRight: 5 }} className="icon" icon={faCalendar} color="#f84899" size="1x" /></span>
                            <span>
                                {getExpiryMessage(search)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="adv-search-actions">
                {!hasExpired(search) &&
                    <div className="adv-search-action">
                        <button className="iqx-button sm-size pull-right" disabled={false}
                                onClick={() => { history.push(`/advanced-search/${search.id}/edit`, { searchId: search.id, searchGraph: search.searchGraph, searchType: search.type , searchName: search.name}) }}>
                            <span style={{ paddingRight: 10 }}>
                                {translate("advancedsearch.edit")}
                            </span>
                            <FontAwesomeIcon icon={faEdit} size="1x" />
                        </button>
                    </div>
                }
                <div className="adv-search-action">
                    <button className="iqx-button sm-size pull-right"
                        onClick={() => { history.push("/advanced-search/create", { searchGraph: search.searchGraph, searchType: search.type }) }}>
                        <span style={{ paddingRight: 10 }}>
                            {translate("advancedsearch.reuse")}
                        </span>
                        <FontAwesomeIcon icon={faRecycle} size="1x" />
                    </button>
                </div>
                {search.userUuid === getUserUuid() &&
                    <>
                        {hasExpired(search) &&
                            <div className="adv-search-action">
                                <button className="iqx-button sm-size pull-right"
                                        onClick={() => { props.setShowRerun({ show: true, searchId: search.id }) }}
                                        title="Rerun an expired search to be able to view it again"
                                >
                                    <span style={{ paddingRight: 10 }}>
                                        {translate("advancedsearch.rerun")}
                                    </span>
                                    <FontAwesomeIcon icon={faPlay} size="1x" />
                                </button>
                            </div>
                        }
                        <div className="adv-search-action">
                            <button className="iqx-button sm-size pull-right"
                                onClick={() => { props.setShowDelete({ show: true, searchId: search.id }) }}>
                                <span style={{ paddingRight: 10 }}>
                                    {translate("advancedsearch.delete")}
                                </span>
                                <FontAwesomeIcon icon={faTrashAlt} size="1x" />
                            </button>
                        </div>
                        <div className="adv-search-action">
                            <button className="iqx-button sm-size pull-right" onClick={() => setBeingShared(true)}>
                                <span style={{ paddingRight: 10 }}>
                                    {"Share"}
                                </span>
                                <FontAwesomeIcon icon={faShare} size="1x" />
                            </button>
                        </div>
                    </>}
                <div className="adv-search-action">
                    <button className="iqx-button sm-size primary mb-2 pull-right"
                        title={
                            hasExpired(search) ? translate("advancedsearch.expired", { date: DateFormatter.formatDate(search.expiringOn.toString()) })
                                : translate("advancedsearch.view")
                        }
                        disabled={hasExpired(search)}
                        onClick={() => history.push("/advanced-search/" + search.id)}>
                        <span style={{ paddingRight: 10 }}>
                            {translate("advancedsearch.view")}
                        </span>
                        <FontAwesomeIcon icon={faChevronRight} size="lg" />
                    </button>
                </div>

            </div>
            {isBeingShared && <AdvancedSearchShare search={search} setSearch={setSearch} setBeingShared={setBeingShared} />}
            {!isBeingShared && search?.shares?.length > 0 && <div className={"list-shared-with"  + (hasExpired(search) ? " expired" : "")}>Shared by <span className={"team"}>{search.userDisplayName}</span> with {search.shares
                .map<React.ReactNode>(s => <span className={"team"}>{s.name}</span>)}</div>}
        </div>
    );
};

export default AdvancedSearchItem;