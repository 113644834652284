import React, {useEffect, useState} from "react";
import IqLoadingIcon from "./IqLoadingIcon";
import {Image, ListGroup, ListGroupItem} from "react-bootstrap";
import axios from "axios";
import {searchHostname} from "../../utils/Configuration";
import {useHistory} from "react-router-dom";
import translate from "../../i18n/translate";
import {ListOrganisation} from "../../model/organisation/ListOrganisation";

const SearchHistory: React.FC = () => {

    let history = useHistory();

    const [loadingSearchHistory, setLoadingSearchHistory] = useState<boolean>(false);
    const [searchHistory, setSearchHistory] = useState<ListOrganisation[]>([]);
    useEffect(() => {
        const fetchSearchHistory = async () => {
            setLoadingSearchHistory(true);
            await axios.get(searchHostname + "search-history")
                .then(r => setSearchHistory(r.data))
                .finally(() => setLoadingSearchHistory(false))
        };

        fetchSearchHistory();
    }, []);

    const CompanyLogo: React.FC<ListOrganisation> = (org: ListOrganisation) => {
        const [src, setSrc] = useState<string>(org.imageUrl ? org.imageUrl : "./icons_company.svg");

        return (
            <Image style={{ height: "40px", width: "40px", objectFit: "cover" }}
                   title={org.companyName}
                   src={src}
                   onError={() => setSrc("/icons_company.svg")}
                   roundedCircle />
        );
    };

    return (
        <div style={{minWidth: "1000px"}}
             className="p-3">
            <div className="mb-3">
                <span className="organisation-card-label">
                    {translate("nav.recentsearches")}
                </span>
            </div>
            {loadingSearchHistory ? <IqLoadingIcon /> : (
                <ListGroup>
                    {searchHistory.map((org: ListOrganisation) => (
                        <ListGroupItem>
                            <a className="iqx-link" onClick={() => history.push("/organisation/" + org.id)}>
                                <span className="me-3">
                                    <CompanyLogo {...org}/>
                                </span>
                                <span>
                                    {org.companyName}
                                </span>
                            </a>
                        </ListGroupItem>
                    ))}
                </ListGroup>
            )}
        </div>
    );
};

export default SearchHistory;