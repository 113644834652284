import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Col, Image, Row, Table} from 'react-bootstrap';
import axios from 'axios';
import {organisationHostname} from '../../../utils/Configuration';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown, faChevronCircleUp, faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import Organisation from '../../../model/organisation/Organisation';
import IqLoadingIcon from '../../common/IqLoadingIcon';
import OrganisationAccreditationDashboard from '../../../model/organisation/OrganisationAccreditationDashboard';
import DashboardPieChart from '../../lists/dashboard/common/DashboardPieChart';
import translate from "../../../i18n/translate";
import OrganisationFinancialSummary from "../../../model/organisation/OrganisationFinancialSummary";
import {getMonthName} from "../../../utils/formatters/DateFormatter";

const OrganisationChannelPartnersDashboard: React.FC = () => {

    let { organisationId } = useParams<{organisationId: string}>();
    const [loadingDashboard, setLoadingDashboard] = useState<boolean>(false);
    const [organisationAccreditationDashboard, setOrganisationAccreditationDashboard] = useState<OrganisationAccreditationDashboard>({} as OrganisationAccreditationDashboard);



    const CompanyLogo: React.FC<Organisation> = (data: Organisation) => {
        const [src, setSrc] = useState<string>(data.imageUrl ? data.imageUrl : "./icons_company.svg");

        return (
            <Image title={data.companyName}
                style={{
                    height: "30px",
                    width: "30px",
                    objectFit: "cover"
                }}
                src={src}
                onError={() => setSrc("/icons_company.svg")}
                roundedCircle />
        )
    };


    const formatTurnover = (org: Organisation): string => {
        let formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0
        });

        if (org.financialSummary.length === 0 || !org.financialSummary[0].turnover) {
            return "-"
        }

        return formatter.format(org.financialSummary[0].turnover);
    };

    const getGrowthIcon = (growth: number) => {
        if (growth > 0) {
            return (
                <FontAwesomeIcon icon={faChevronCircleUp} style={{ color: "#66d32f", paddingBottom: 2 }} size="sm" />
            )
        }
        else if (growth < 0) {
            return (
                <FontAwesomeIcon icon={faChevronCircleDown} style={{ color: "red", paddingBottom: 2 }} size="sm" />
            );
        }
        else {
            return (
                <FontAwesomeIcon icon={faMinusCircle} style={{ color: "#ADADAD", paddingBottom: 2 }} size="sm" />
            );
        }
    };

    useEffect(() => {
        const fetchDashboard = async () => {
            setLoadingDashboard(true);
            await axios.get(organisationHostname + "organisation/" + organisationId + "/channel-partners-dashboard")
                .then(r => {
                    setOrganisationAccreditationDashboard(r.data)

                })
                .catch(() => { })
                .finally(() => setLoadingDashboard(false));
        };

        fetchDashboard();

    }, []);

    const getOrganisationLink = (organisationId: string) => {
        let link = `/organisation/${organisationId}`;
        return link;
    };

    const getTwoDecimalPoints = (numberToFormat: number) => {
        return (Math.round(numberToFormat * 100) / 100).toFixed(2);
    };

    const getFormattedFiscalYearEnd = (financialSummary: OrganisationFinancialSummary) => {
        let dateOfAccounts = new Date(financialSummary.dateOfAccounts);

        return `${translate(getMonthName(dateOfAccounts.getMonth()))} ${dateOfAccounts.getFullYear()}`;
    };

    return (
        <>
            {loadingDashboard ?
                <Row className="" style={{ marginLeft: 15, marginRight: 15, paddingTop: 15 }}>
                    <IqLoadingIcon />
                </Row>
                :
                <>
                    <Row className="" style={{ marginLeft: 15, marginRight: 15, paddingTop: 15 }}>
                        {!(organisationAccreditationDashboard.topOrganisationsGrowth?.length === 0) && (
                            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                                <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10, width: "100%" }}>
                                    <div className="organisation-card-label">
                                        {translate("lists.top5growth1year")}
                                    </div>
                                    <Table striped hover size="sm">
                                        <thead>
                                            <tr>
                                                <th className="text-center">&nbsp;</th>
                                                <th style={{ width: "35%" }}>
                                                    {translate("lists.companyname")}
                                                </th>
                                                <th className="text-end">
                                                    {translate("lists.growth")}
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                organisationAccreditationDashboard.topOrganisationsGrowth?.map((org: Organisation) => (
                                                    <tr key={org.id}>
                                                        <td className="text-center">
                                                            <CompanyLogo {...org} />
                                                        </td>
                                                        <td>
                                                            <a className="iqx-link" href={getOrganisationLink(org.id)}>
                                                                {org.companyName}
                                                            </a>
                                                        </td>
                                                        <td className="text-end">
                                                            {org.financialSummary[0]?.turnoverChange ? (
                                                                <span>{getTwoDecimalPoints(org.financialSummary[0]?.turnoverChange)} {getGrowthIcon(org.financialSummary[0]?.turnoverChange)}</span>
                                                            ) : (
                                                                <span>
                                                                    -
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        )}
                        {!(organisationAccreditationDashboard.bottomOrganisationsGrowth?.length === 0) && (
                            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                                <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10, width: "100%" }}>
                                    <div className="organisation-card-label">
                                        {translate("lists.top5decline1year")}
                                    </div>
                                    <Table striped hover size="sm">
                                        <thead>
                                            <tr>
                                                <th className="text-center">&nbsp;</th>
                                                <th style={{ width: "35%" }}>
                                                    {translate("lists.companyname")}
                                                </th>
                                                <th className="text-end">
                                                    Decline
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                organisationAccreditationDashboard.bottomOrganisationsGrowth?.map((org: Organisation) => (
                                                    <tr key={org.id}>
                                                        <td className="text-center">
                                                            <CompanyLogo {...org} />
                                                        </td>
                                                        <td>
                                                            <a className="iqx-link" href={getOrganisationLink(org.id)}>
                                                                {org.companyName}
                                                            </a>
                                                        </td>
                                                        <td className="text-end">
                                                            {org.financialSummary[0]?.turnoverChange ? (
                                                                <span>{getTwoDecimalPoints(org.financialSummary[0]?.turnoverChange)} {getGrowthIcon(org.financialSummary[0]?.turnoverChange)}</span>
                                                            ) : (
                                                                <span>
                                                                    -
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>

                                </div>
                            </Col>
                        )}
                        {!(organisationAccreditationDashboard.topOrganisationsRevenue?.length === 0) && (

                            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                                <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10, width: "100%" }}>
                                    <div className="organisation-card-label">
                                        {translate("lists.top5revenue")}
                                    </div>
                                    <Table striped hover size="sm">
                                        <thead>
                                            <tr>
                                                <th className="text-center">&nbsp;</th>
                                                <th style={{ width: "35%" }}>
                                                    {translate("lists.companyname")}
                                                </th>
                                                <th className="text-end">
                                                    {translate("organisation.financials.turnover")}
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                organisationAccreditationDashboard.topOrganisationsRevenue?.map((org: Organisation) => (
                                                    <tr key={org.id}>
                                                        <td className="text-center">
                                                            <CompanyLogo {...org} />
                                                        </td>
                                                        <td>
                                                            <a className="iqx-link" href={getOrganisationLink(org.id)}>
                                                                {org.companyName}
                                                            </a>
                                                        </td>
                                                        <td className="text-end">
                                                            {org.financialSummary[0]?.turnover ? (
                                                                <span>{formatTurnover(org)}</span>
                                                            ) : (
                                                                <span>
                                                                    -
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        )}
                    </Row>
                    <Row className="" style={{ marginLeft: 15, marginRight: 15, paddingTop: 15 }}>
                        {!(organisationAccreditationDashboard.upcomingOrganisationsYearEnd?.length === 0) && (
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10, width: "100%" }}>
                                    <div className="organisation-card-label">
                                        {translate("organisation.summary.companyinfo.fiscalyearend")}
                                    </div>
                                    <Table striped hover size="sm">
                                        <thead>
                                            <tr>
                                                <th className="text-center">&nbsp;</th>
                                                <th style={{ width: "35%" }}>
                                                    {translate("lists.companyname")}
                                                </th>
                                                <th className="text-end">
                                                    {translate("organisation.summary.companyinfo.fiscalyearend")}
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                organisationAccreditationDashboard.upcomingOrganisationsYearEnd?.map((org: Organisation) => (
                                                    <tr key={org.id}>
                                                        <td className="text-center">
                                                            <CompanyLogo {...org} />
                                                        </td>
                                                        <td>
                                                            <a className="iqx-link" href={getOrganisationLink(org.id)}>
                                                                {org.companyName}
                                                            </a>
                                                        </td>
                                                        <td className="text-end">
                                                            {org.financialSummary[0]?.dateOfAccounts ? (
                                                                <span>
                                                                    {getFormattedFiscalYearEnd(org.financialSummary[0])}
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    -
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        )}
                        {!(organisationAccreditationDashboard.distributionByRevenue?.length === 0) && (

                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10, width: "100%" }}>
                                    <div className="organisation-card-label">
                                        {translate("lists.distributionbyrevenue")}
                                    </div>
                                    {organisationAccreditationDashboard.distributionByRevenue && (
                                        <DashboardPieChart title="Sic Code"
                                            chartId="sicCodeChart"
                                            data={organisationAccreditationDashboard.distributionByRevenue}
                                            height={320} />
                                    )}
                                </div>
                            </Col>
                        )}
                    </Row>
                </>
            }
        </>
    );
}

export default OrganisationChannelPartnersDashboard;
