import React, {useContext, useEffect, useRef, useState} from "react";
import {Alert, Button, Modal, Table} from "react-bootstrap";
import Pagination from "../../utils/PaginationHook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faEdit, faTimesCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import LoginContext from '../../context/LoginContext'
import axios from "axios";
import {frontEndHostName} from "../../utils/Configuration";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {useHistory} from "react-router-dom";
import translate from "../../i18n/translate";
import AwesomeDebouncePromise from "awesome-debounce-promise";


interface IAdminUsersTable {
    showRegisterModal: boolean
}
const AdminUsersTable: React.FC<IAdminUsersTable> = (props: IAdminUsersTable) => {

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const { logged, setLogged } = useContext(LoginContext);

    const [selectedUser, setSelectedUser] = useState<UsersResponse>();

    const history = useHistory();

    const [totalRecords, setTotalRecords] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const usersPerPageLimit: number = 10;

    const searchQuery = useRef<string>();

    interface UsersResponse {
        id: number,
        uuid: string;
        login: string,
        firstName: string,
        lastName: string,
        email: string,
        activated: boolean

    }
    const [users, setUsers] = useState<UsersResponse[]>([] as UsersResponse[]);

    const [error, setError] = useState<boolean>(false);

    const updateSearchQuery = (value: string) => {
        searchQuery.current = value;
        if (currentPage === 1) {
            fetchUsers();
        }
        else {
            setCurrentPage(1);
        }
    };

    const debounced = AwesomeDebouncePromise(updateSearchQuery, 300);
    const handleSearchQueryChange = async (e: any) => {
        e.persist();
        await debounced(e.target.value);
    };

    const fetchUsers = async () => {
        setIsLoading(true);
        let params: any = {
            page: currentPage - 1,
            size: usersPerPageLimit
        };
        let payloadData = {
            q: searchQuery.current
        };
        await axios.post(frontEndHostName + 'allUsers', payloadData, {
            params: params
        })
            .then((response) => {
                if (response.status === 401) {
                    localStorage.removeItem("login");
                    setLogged(false);
                } else {
                    setTotalRecords(response.data.totalElements);
                    setUsers(response.data.content);
                }
            })
            .catch(() => setError(true))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (!props.showRegisterModal && !showEditModal && !showDeleteModal) {
            fetchUsers();
        }
    }, [props.showRegisterModal, currentPage, showEditModal, showDeleteModal]);

    const DeleteUserModal: React.FC<any> = (props: any) => {

        const handleClose = () => props.setShow(false);

        const deleteUser = () => {
            if (props.user) {
                axios.delete(frontEndHostName + 'users/' + props.user.uuid + "/delete")
                    .then((response) => {
                        handleClose();
                    })
                    .catch(error => console.log(error.message));
            }
        };

        return (
            <div>
                <Modal show={props.show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure you want to delete {props.user?.login}?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Deleting a user cannot be undone.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={deleteUser}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    };

    return (
        <div className="mt-3">
            <DeleteUserModal show={showDeleteModal} setShow={setShowDeleteModal} user={selectedUser} />
            <div style={{ marginBottom: 10, display: "flex", justifyContent: "flex-end" }}>
                <input className="form-control float-end" type="text" placeholder={translate("advancedsearch.search")} onKeyUp={handleSearchQueryChange} />
            </div>
            {isLoading ? <IqLoadingIcon /> :
                <div>
                    {error ? (
                        <Alert variant="danger">
                            {translate("errors.loading")}
                        </Alert>
                    ) : (
                        <div>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Username</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th style={{ width: 15 }}>Activated</th>
                                        <th style={{ width: 20 }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(user => (
                                        <tr key={user.uuid}>
                                            <td className="pt-3">{user.id}</td>
                                            <td className="pt-3">{user.login}</td>
                                            <td className="pt-3">{user.firstName}</td>
                                            <td className="pt-3">{user.lastName}</td>
                                            <td className="pt-3">{user.email}</td>
                                            <td className="pt-3" style={{ textAlign: "center" }}>
                                                {user.activated
                                                    ?
                                                    <FontAwesomeIcon icon={faCheckCircle} color="green" size="1x" />
                                                    :
                                                    <FontAwesomeIcon icon={faTimesCircle} color="orange" size="1x" />
                                                }
                                            </td>
                                            <td className="pt-2" style={{ textAlign: "center", display: "flex", justifyContent: "space-between" }}>
                                                <Button variant="link" onClick={() => { history.push(`/admin/users/${user.uuid}`) }}>
                                                    <FontAwesomeIcon icon={faEdit} color="black" size="1x" />
                                                </Button>
                                                <Button variant="link" onClick={() => { setSelectedUser(user); setShowDeleteModal(true) }}>
                                                    <FontAwesomeIcon icon={faTrashAlt} color="red" size="1x" />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <div className="pull-right">
                                <Pagination
                                    totalRecords={totalRecords}
                                    pageLimit={usersPerPageLimit}
                                    initPage={currentPage}
                                    pageRangeDisplayed={1}
                                    onChangePage={setCurrentPage}
                                />
                            </div>
                        </div>
                    )}
                </div>
            }

        </div>
    )
};

export default AdminUsersTable;
