import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import "./OnboardingPreferences.css";
import OnboardingPreferences from "./OnboardingPreferences";
import OnboardingPreferencesControl from "./OnboardingPreferencesControl";
import {faChevronLeft, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";
import EnhancedButton from "../../utils/EnhancedButton";

const valueUnspecified = "UNSPECIFIED";

enum Role {
    SALES = "Sales",
    MARKETING = "Marketing",
    BOTH = "Sales & Marketing"
}

enum RoleFocus {
    CHANNEL = "Channel Only",
    END_USERS = "End Users Only",
    BOTH = "Channel & End Users"
}

enum Account {
    DEDICATED_ACCOUNTS = "Dedicated Accounts Only",
    NEW_BUSINESS = "New Business Only",
    BOTH = "Dedicated Accounts & New Business"
}

enum BusinessTarget {
    SECTOR = "Sector",
    GEOGRAPHY = "Geography",
    BOTH = "Sector & Geography"
}

interface OnboardingPreferencesProps {
    onCompleteCallback: Function,
    exitable: boolean,
    shown: boolean,
    setShown: Function
}

const OnboardingPreferencesModal: React.FC<OnboardingPreferencesProps> = ({
                                                                              onCompleteCallback,
                                                                              exitable,
                                                                              shown,
                                                                              setShown
                                                                          }) => {
    const history = useHistory();

    const [page, setPage] = useState<number>(0);
    const [nextButtonEnabled, setNextButtonEnabled] = useState<boolean>(false);

    const handleClose = () => setShown(false);
    const nextPage = () => {
        setPage(p => p + 1);

    };
    const previousPage = () => {
        setPage(p => p - 1);

    };

    return (
        <div>
            <Modal show={shown}
                   animation={true}
                   size="lg"
                   backdrop="static"
                   keyboard={exitable}
                   onHide={handleClose}
            >
                <Modal.Header closeButton={exitable}>
                    {page === 0 ? "Onboarding" : "User Settings"}
                    <div className="justify-content-end">
                        <EnhancedButton onClick={() => history.push("/")} icon={faTimes} tooltip={"Exit Onboarding"} tooltipPosition="left"/>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <OnboardingPreferences page={page} continueCallback={nextPage} onCompleteCallback={onCompleteCallback} setEnableContinueCallback={setNextButtonEnabled}/>
                </Modal.Body>
                <Modal.Footer>
                    {page > 2 ?
                        <div style={{display: 'flex', marginRight: "auto"}}>
                            <div>
                                {page > 0 &&
                                    <EnhancedButton onClick={() => setPage(page-1)} icon={faChevronLeft} tooltip="Previous Page" tooltipPosition="left"/>
                                }
                            </div>
                        </div>
                        :
                        <OnboardingPreferencesControl page={page} lastPage={2} nextButtonEnabled={nextButtonEnabled}
                                                      nextPageCallback={nextPage} prevPageCallback={previousPage}/>

                    }
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default OnboardingPreferencesModal;