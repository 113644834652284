import React, {ChangeEvent, useEffect, useState} from "react";
import ContactFiltersModal, {ContactFilters} from "./ContactFiltersModal";
import {Alert, Button, Col, Dropdown, Modal, Row} from "react-bootstrap";
import ContactSortSelect from "./ContactSortSelect";
import axios from "axios";
import {organisationHostname} from "../../utils/Configuration";
import {OrganisationContact} from "../../model/organisation/OrganisationContact";
import IqLoadingIcon from "../common/IqLoadingIcon";
import OrganisationContactEntry from "../organisation/people/OrganisationContactEntry";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import translate from "../../i18n/translate";
import ContactListFilter from "./ContactListFilter";
import Pagination from "../../utils/PaginationHook";
import FileDownload from "js-file-download";
import {getUserUuid} from "../../utils/Security";

const ImportedContacts: React.FC = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [contacts, setContacts] = useState<OrganisationContact[]>([]);

    const [filters, setFilters] = useState<ContactFilters>({
        userId: getUserUuid()
    } as ContactFilters);
    const [list, setList] = useState<string>();
    const [sort, setSort] = useState<string>();
    const [currentPage, setCurrentPage] = useState(1);
    const contactsPerPageLimit: number = 15;
    const [totalRecords, setTotalRecords] = useState(1);

    const [exporting, setExporting] = useState(false);

    const [requestTotalRecords, setRequestTotalRecords] = useState(1);
    const [exportFailedFlag, setExportFailedFlag] = useState(false);
    const [reFetchContactsFlag, setReFetchContactsFlag] = useState(true);

    useEffect(() => {
        const fetchContacts = async () => {
            setLoading(true);

            let params: any = {
                sort: sort,
                page: currentPage - 1,
                size: contactsPerPageLimit
            };

            let postData: any = {
                jobTitles: filters?.jobTitles,
                roles: filters?.roles,
                seniority: filters?.seniority,
                countries: filters?.countries,
                userId: filters?.userId,
                from: filters?.from,
                to: filters?.to
            };

            if (list && list !== "null") {
                postData["list"] = list;
            }

            await axios.post(organisationHostname + "organisation/contacts/imported", postData, { params: params })
                .then(r => {
                    if (r.status >= 300 && r.status < 600) {
                        setContacts([]);
                        setTotalRecords(0);
                        setLoading(false);
                        throw new Error("Unsuccessful Query Response from server, fetch contacts, Status Code: " + r.status.toString());
                    }
                    setContacts(r.data.content);
                    setTotalRecords(r.data.totalElements);
                }).catch((error) => {
                    setContacts([]);
                    setTotalRecords(0);
                    setLoading(false);
                }).finally(
                    () => setLoading(false));
        };

        if (reFetchContactsFlag) {
            fetchContacts().catch(
                () => {
                    setContacts([]);
                    setTotalRecords(0);
                    setLoading(false);
                    setReFetchContactsFlag(false);
                }
            );
        }

    }, [sort, filters, list, currentPage]);

    // Download Contacts from button click
    const exportContacts = async (paged: boolean, salesforce: boolean) => {
        setExporting(true);
        setShowExportModal(true);
        setExportFailedFlag(false);

        let reqTotalRecords = totalRecords;
        if (totalRecords >= 500) {
            setRequestTotalRecords(500);
            reqTotalRecords = 500;
        } else {
            setRequestTotalRecords(totalRecords);
        }

        let params: any = {
            sort: sort,
            page: paged ? (currentPage - 1) : 0,
            size: paged ? contactsPerPageLimit : reqTotalRecords
        };

        let postData: any = {
            jobTitles: filters?.jobTitles,
            roles: filters?.roles,
            seniority: filters?.seniority,
            countries: filters?.countries,
            userId: filters?.userId,
            from: filters?.from,
            to: filters?.to
        };

        if (list && list !== "null") {
            postData["list"] = list;
        }

        const endpointUrl = `${organisationHostname}organisation/export-contacts${salesforce ? '/salesforce' : ''}`;
        await axios.post(endpointUrl, postData, { params: params })
            .then((queryResponse) => {
                if (queryResponse.status >= 300 && queryResponse.status < 600) {
                    setExportFailedFlag(true);
                    setLoading(false);
                    throw new Error("Unsuccessful Query Response from server, export contacts, Status Code: " + queryResponse.status.toString());
                }
                let now = new Date().toLocaleDateString();
                let fileName = `contacts_export${salesforce ? '_salesforce' : ''}_${paged ? 'page_' + currentPage : 'all'}_${now}.csv`;
                FileDownload(queryResponse.data, fileName);
            }).catch(() => {
                    setExportFailedFlag(true);
                    setReFetchContactsFlag(true);
                }
            ).finally(() => {
                setExporting(false);
                setReFetchContactsFlag(true);
            });
    };

    const exportRecentContacts = async (salesforce: boolean) => {
        setExporting(true);
        setShowExportModal(true);
        setExportFailedFlag(false);

        const params = {
            salesforce: salesforce
        };

        const endpointUrl = `${organisationHostname}organisation/export-contacts/recent`;
        await axios.get(endpointUrl, { params: params })
            .then((queryResponse) => {
                if (queryResponse.status >= 300 && queryResponse.status < 600) {
                    setExportFailedFlag(true);
                    setLoading(false);
                    throw new Error("Unsuccessful Query Response from server, export contacts, Status Code: " + queryResponse.status.toString());
                }
                let now = new Date().toLocaleDateString();
                let fileName = `contacts_export${salesforce ? '_salesforce' : ''}_${now}.csv`;
                FileDownload(queryResponse.data, fileName);
            }).catch(() => {
                    setExportFailedFlag(true);
                    setReFetchContactsFlag(true);
                }
            ).finally(() => {
                setExporting(false);
                setReFetchContactsFlag(true);
            });
    };

    const [showExportModal, setShowExportModal] = useState<boolean>(false);

    const handleClose = () => {
        setShowExportModal(false);
    };

    const setSortBy = (e: ChangeEvent<HTMLSelectElement>) => {
        setSort(e.target.value);
    };

    const setListFilter = (e: ChangeEvent<HTMLSelectElement>) => {
        setList(e.target.value);
        setReFetchContactsFlag(true);
    };

    const [toggleShowDropdown, setToggleShowDropdown] = useState(false);
    function toggleClass() {
        if (toggleShowDropdown) {
            setToggleShowDropdown(true);
        } else {
            setToggleShowDropdown(false);
        }
    }

    return (
        <div style={{ paddingTop: 0, padding: 0, marginTop: 15, width: "100%" }}>
            <Row className="mb-4">
                <Col xs={3} sm={3} md={3} lg={2} xl={2}>
                    <div className="iq-headline">
                        <span className="text">
                            {translate("contacts.title")}
                        </span>
                    </div>
                </Col>
                <Col  xs={3} sm={3} md={3} lg={2} xl={2}>
                    {loading ? (
                        <Dropdown>
                            <Dropdown.Toggle className="iqx-button primary md-size ms-xl-0 ms-lg-1"
                                             variant="link" as="button"  id="export-dropdown" disabled={true} title="If no contacts are found, it is not possible to export an empty CSV file.">
                                {translate("lists.exportcontacts")}
                                {exporting &&
                                    <div className="spinner-border spinner-border-sm" style={{marginLeft:3}} role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item disabled={true}>
                                    Export CSV
                                </Dropdown.Item>

                                <Dropdown.Item disabled={true}>
                                    Export CSV in Salesforce Format
                                </Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        contacts?.length > 0 ? (

                            <Dropdown>
                                <Dropdown.Toggle className="iqx-button primary md-size ms-xl-0 ms-lg-1"
                                                 variant="link" as="button" id="export-dropdown">
                                    {translate("lists.exportcontacts")}
                                    {exporting &&
                                        <div className="spinner-border spinner-border-sm" style={{marginLeft:3}} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    }
                                </Dropdown.Toggle>

                                <Dropdown.Menu>

                                    <Dropdown.Item onClick={() => exportRecentContacts(false)}>
                                        Export My Recent Contacts
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => exportRecentContacts(true)}>
                                        Export My Recent Contacts (Salesforce)
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={() => exportContacts(false, false)}>
                                        Export All
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => exportContacts(false, true)}>
                                        Export All (Salesforce)
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={() => exportContacts(true, false)}>
                                        Export Current Page
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => exportContacts(true, true)}>
                                        Export Current Page (Salesforce)
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        ) : (
                            <Dropdown>
                                <Dropdown.Toggle className="iqx-button primary md-size dark-blue ms-xl-0 ms-lg-1"
                                                 variant="link" as="button" id="export-contacts-dropdown" disabled={true} title="If no contacts are found, it is not possible to export an empty CSV file." style={{ borderRadius: "25px" }}>
                                    {translate("lists.exportcontacts")}
                                    {exporting &&
                                        <div className="spinner-border spinner-border-sm" style={{marginLeft:3}} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    }
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item disabled={true}>
                                        Export CSV
                                    </Dropdown.Item>

                                    <Dropdown.Item disabled={true}>
                                        Export CSV in Salesforce Format
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )
                    )}
                </Col>
                <Col className="text-end">
                    <div style={{display: "flex", flexDirection: "row-reverse"}}>
                        <div className="me-2">
                            <ContactFiltersModal setFilters={setFilters} />
                        </div>
                        <div className="me-2">
                            <ContactListFilter setList={setListFilter} />
                        </div>
                        <div className="me-2">
                            <ContactSortSelect setSortBy={setSortBy} />
                        </div>
                    </div>
                </Col>
            </Row>
            <Modal show={showExportModal} onHide={handleClose} dialogClassName="modal-dialog modal-full-height modal-right modal-notify modal-success">
                <Modal.Header closeButton>
                    {requestTotalRecords >= 500 ? <Modal.Title>Export Contacts - Maximum Export Reached</Modal.Title> : <Modal.Title>Export Contacts</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {requestTotalRecords >= 500 ? <p>Warning: Only 500 Contacts can be exported at once, if you require more please contact us.</p> : null}
                    {loading ? <p>Exporting Contacts, Please wait....</p> : null}
                    {loading ? <IqLoadingIcon /> : (
                        exportFailedFlag ?
                            (<p>Export of Contacts Failed!<br/>Please try again.<br />However if you require assistance please contact us.</p>)
                            :
                            (<p>Exported Contacts, Please check your downloads folder for a new CSV file.</p>)
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {translate("dashboard.close")}
                    </Button>
                </Modal.Footer>
            </Modal>
            {loading ? <IqLoadingIcon /> : (
                <div>
                    {contacts?.length > 0 ? (
                        <div className="contacts-container">
                            {contacts.map((contact, index) => <OrganisationContactEntry key={index} contact={contact} showOrganisation={true} />)}
                        </div>
                    ) : (
                        <Alert variant="warning" style={{ width: "100%", marginTop: 25 }}>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", alignContent: "center" }}>
                                <FontAwesomeIcon icon={faExclamationCircle} color="#367CDB" size="2x" />
                                <span style={{ marginLeft: 15 }}>{translate("contacts.nocontacts.imported")}</span>
                            </div>
                        </Alert>
                    )}
                    <div style={{marginTop: 10}} className="pull-right">
                        <Pagination
                            totalRecords={totalRecords}
                            pageLimit={contactsPerPageLimit}
                            initPage={currentPage}
                            pageRangeDisplayed={1}
                            onChangePage={setCurrentPage}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImportedContacts;