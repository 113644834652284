import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useContext, useEffect, useState} from 'react';
import {Utils as QbUtils} from 'react-awesome-query-builder-opti';
import AdvancedSearchContext from '../../../context/advanced-search/AdvancedSearchContext';
import ActionFieldButtons from './ActionFieldButtons';
import {Select, TreeSelect} from "antd";
import ITreeSelectField from './ITreeSelectField';
import {getAccreditationFilterValues, locations} from "../AdvancedSearchConfig";
import {QuickFilters} from "../FilterBubbleContainer";

const Option = Select.Option;

const TreeSelectField: React.FC<ITreeSelectField> = (props: ITreeSelectField) => {
    const { tree, setTree, filters, config } = useContext(AdvancedSearchContext);
    const [selected, setSelected] = useState(props.selected);
    const [filterId, setFilterId] = useState(QbUtils.uuid());

    const onChange = (val: string[]) => {
        if (props.filterId === QuickFilters.Accreditations) {
            let filterValues = getAccreditationFilterValues(filters);
            let selection: string[] = [];
            for (let current of val) {
                selection.push(current);

                if (current.indexOf("-") === -1) {
                    selection.push(...filterValues.filter((accreditation: any) => accreditation.parent === current).map((a: any) => a.value));
                }
            }
            setSelected(selection);
        }
        else {
            setSelected(val);
        }
    };

    const { SHOW_PARENT } = TreeSelect;


    const onCancel = () => {
        props.setFilterState("")
    }

    const onRemove = () => {
        let objectTree = QbUtils.getTree(tree);
        delete objectTree!.children1![filterId];
        setTree(QbUtils.checkTree(QbUtils.loadTree(objectTree), config));
        reset()
    }

    const reset = () => {
        props.setFilterState("")
        setSelected(props.selected)
    }

    const onApply = () => {
        let objectTree = QbUtils.getTree(tree);
        if (!objectTree.hasOwnProperty('children1')) {
            objectTree.children1 = {}
        }

        objectTree!.children1![filterId] = {
            "type": "rule",
            "properties": {
                "field": props.graphField,
                "operator": "select_any_in",
                "value": [
                    getSelection()
                ],
                "valueSrc": [
                    "value"
                ],
                "valueType": [
                    "treemultiselect"
                ]
            }
        }
        setTree(QbUtils.checkTree(QbUtils.loadTree(objectTree), config));
        props.setFilterState("active");
    }

    const getSelection = () => {
        if (props.graphField === QuickFilters.HeadOffice) {
            return selected.flatMap(s => flattenLocation(s));
        }

        return selected;
    }

    const flattenLocation: any = (location: any) => {
        const childLocations = locations.filter(l => l.parent === location);
        if (childLocations.length === 0) {
            return location;
        }

        return childLocations.flatMap(child => flattenLocation(child.value));
    }

    useEffect(() => {
        let objectTree = QbUtils.getTree(tree);
        if (objectTree.hasOwnProperty('children1')) {
            if (objectTree!.children1!.hasOwnProperty(filterId)) {
                let properties = objectTree!.children1![filterId]!.properties;
                if ((properties as any).value[0] != null && (properties as any).field === props.graphField) {
                    setSelected((properties as any).value[0])
                    props.setFilterState("active");
                } else {
                    reset()
                }
            } else {
                reset()
            }
        }
    }, [tree])

    const defaultTreeDataMap = { id: "value", pId: "parent", rootPId: undefined };

    const filterTreeNode = (input: any, option: any) => {
        const dataForFilter = option.title;
        return dataForFilter.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    return (
        <>
            {props.filterState === "editable" &&
                <>
                    <TreeSelect
                        dropdownClassName={"filter-bubble-overriden-ant-select-dropdown"}
                        disabled={false}
                        style={{
                            minWidth: 200
                        }}
                        dropdownStyle={{
                            minWidth: "200px !important",
                            width: "400px !important"
                        }}
                        key={"widget-multiselect"}
                        placeholder={"Please select"}
                        treeData={props.options}
                        treeDataSimpleMode={defaultTreeDataMap}
                        treeCheckable={true}
                        showCheckedStrategy={SHOW_PARENT}
                        size={"small"}
                        value={selected}
                        onChange={onChange}
                        filterTreeNode={filterTreeNode}
                    />
                    <ActionFieldButtons onCancel={onCancel} onApply={onApply} />
                </>
            }
            {props.filterState === "active" &&
                <div>
                    <div>
                        {selected.join(", ").substring(0, 28) + "..."}
                    </div>

                    <div onClick={() => onRemove()} className="remove">
                        <FontAwesomeIcon icon={faTimes} color="white" size="1x" />
                    </div>
                </div>
            }
            {props.filterState === "" &&
                <div>
                    Any
                </div>
            }
        </>
    );
}

export default TreeSelectField;