import React, {useContext, useEffect, useState} from "react";
import YoutubeAccount from "../../../model/organisation/social/YoutubeAccount";
import YoutubeVideo from "../../../model/organisation/social/YoutubeVideo";
import OrganisationSocialContext from "../../../context/organisation/OrganisationSocialContext";
import {Alert, Card, Col, Image, Row} from "react-bootstrap";
import {matchPath, useLocation} from "react-router-dom";
import {socialHostName} from "../../../utils/Configuration";
import axios from "axios";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {format} from "d3-format";
import YoutubeHistory from "../../../model/organisation/social/YoutubeHistory";
import "./OrganisationYouTube.css";
import translate from "../../../i18n/translate";

const OrganisationYouTube: React.FC = () => {
    let location = useLocation();

    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/organisation/:organisationId',
        exact: false,
        strict: false
    });

    const {socialHandles, setSocialHandles} = useContext(OrganisationSocialContext);

    const [error, setError] = useState<boolean>(false);
    const [loadingAccount, setLoadingAccount] = useState<boolean>(false);
    const [account, setAccount] = useState<YoutubeAccount>();
    useEffect(() => {
        const fetchAccount = async () => {
            setLoadingAccount(true);
            await axios.get(socialHostName + "organisation/" + childMatchedPath?.params.organisationId + "/youtube")
                .then(r => {
                    setAccount(r.data)
                })
                .catch(() => setError(true))
                .finally(() => setLoadingAccount(false));
        };

        fetchAccount();
    }, []);

    const [loadingVideos, setLoadingVideos] = useState<boolean>(false);
    const [videos, setVideos] = useState<YoutubeVideo[]>([]);
    useEffect(() => {
        const fetchVideos = async () => {
            setLoadingVideos(true);
            await axios.get(socialHostName + "youtube/" + account?.id + "/latest-videos")
                .then(r => setVideos(r.data))
                .finally(() => setLoadingVideos(false));
        };

        if (account) {
            fetchVideos();
        }
    }, [account]);

    const getLatestValue = (property: string) => {
        if (account) {
            let history = account.youtubeHistories;

            if (history.length !== 0) {
                let latest = history[0];
                return latest[property as keyof YoutubeHistory];
            }
        }

        return null;
    };

    const formatValue = (property: string) => {
        let latestValue = Number(getLatestValue(property));
        return isNaN(latestValue) ? "-" : format(',.3~s')(latestValue).replace(/G/, "B");
    };

    const BannerImage: React.FC<any> = (props: any) => {
        const [src, setSrc] = useState<string>(props.url);

        return (
            <div className="yt-banner text-center">
                {src && (<Image src={src} />)}
            </div>
        );
    };

    const ProfileImage: React.FC<any> = (props: any) => {
        const [src, setSrc] = useState<string>(props.url ? atob(props.url) :  "/icons_profil.png");
        return (
            <Image className="profile-image yt-logo mb-4"
                   src={src}
                   onError={() => setSrc("/icons_profil.png")}
                   roundedCircle />
        );
    };

    return (
        <div style={{paddingTop: 35, overflowX: "hidden"}}>
            {(!socialHandles.youtube || error) ? (
                <Alert variant="warning">
                    {translate("organisation.social.youtube.nouser")}
                </Alert>
            ) : (
                <div>
                    {loadingAccount ? <IqLoadingIcon /> : (
                        <Card className="mb-4">
                            <Card.Body>
                                <div className="text-center mb-2">
                                    <Card.Title>
                                        <Row>
                                            <Col>
                                                <ProfileImage url={account?.logoUrl}/>
                                                <BannerImage url={account?.bannerUrl} />
                                            </Col>
                                        </Row>
                                    </Card.Title>
                                    <Card.Subtitle>
                                        <h4 className="mt-n3 mb-3">{account?.title}</h4>
                                        <div className="mb-5">
                                            <a className="iq-button iq-button-gradient ps-3 pe-3"
                                               href={account?.link}>
                                                Subscribe
                                            </a>
                                        </div>
                                    </Card.Subtitle>

                                    <Row>
                                        <Col className="twitter-user-details detail-left">
                                            <div>
                                                <b>{formatValue("videoCount")}</b>
                                            </div>
                                            <span className="subtitle">Videos</span>
                                        </Col>
                                        <Col className="twitter-user-details detail-centre">
                                            <div>
                                                <b>{formatValue("subscribers")}</b>
                                            </div>
                                            <span className="subtitle">Subscribers</span>
                                        </Col>
                                        <Col className="twitter-user-details detail-centre">
                                            <div>
                                                <b>{formatValue("views")}</b>
                                            </div>
                                            <span className="subtitle">Views</span>
                                        </Col>
                                        <Col className="twitter-user-details detail-right">
                                            <div>
                                                <b>{formatValue("commentCount")}</b>
                                            </div>
                                            <span className="subtitle">Comments</span>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="p-2">
                                    {account?.channelDescription}
                                </div>
                                {account?.topics?.length !== 0 && (
                                    <div className="text-center mt-1 p-2">
                                        {account?.topics?.map((topic: string) => (
                                            <span className="badge badge-primary badge-tag">
                                                {topic}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    )}

                    {account && (
                        <div className="organisation-card">
                            <div className="organisation-card-label">
                                Videos
                            </div>

                            {loadingVideos ? <IqLoadingIcon /> : (
                                <Row>
                                    {videos.map((video: YoutubeVideo) => (
                                        <Col xl={4} md={4} sm={6} className="text-center mb-3">
                                            <div className="embed-responsive-16by9">
                                                <iframe className="embed-responsive-item" src={`https://www.youtube.com/embed/${video.id}`}/>
                                            </div>
                                        </Col>
                                    ))}

                                    {videos.length === 0 && (
                                        <Alert variant="warning">
                                            No videos found for youtube account.
                                        </Alert>
                                    )}
                                </Row>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default OrganisationYouTube;