import React, {useContext, useEffect} from 'react';
import LoginContext from '../../context/LoginContext';

const Logout: React.FC = () => {

    const { logged, setLogged } = useContext(LoginContext);

    useEffect(() => {
        localStorage.removeItem("login");
        setLogged(false);    
    });

    return (
            <div>
            </div>
    );
}

export default Logout;