import React from "react";
import {Spinner} from "react-bootstrap";

const IqXSmallLoadingIcon: React.FC = () => {
    return (
        <Spinner style={{display: "block", marginLeft: "auto", marginRight: "auto", height: "1rem", width: "1rem"}}
                 className="mt-1"
                 animation="border"
                 variant="primary"/>
    );
};

export default IqXSmallLoadingIcon;