import React, {useEffect, useState} from "react";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import CCSFramework from "../../../model/publicsector/CCSFramework";
import axios from "axios";
import {contractsHostName} from "../../../utils/Configuration";
import {Alert, Col, Form, Image, Modal, Row, Table} from "react-bootstrap";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import translate from "../../../i18n/translate";
import "./CCSFrameworkView.css";
import DateFormatter from "../../../utils/formatters/DateFormatter";
import Pagination from "../../../utils/PaginationHook";
import CCSLot from "../../../model/publicsector/CCSLot";

const CCSFrameworkView: React.FC = () => {
    let history = useHistory();
    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/public-sector/frameworks/ccs/:frameworkId',
        exact: false,
        strict: false
    });
    const organisationPath: any = matchPath(location.pathname, {
        path: '/organisation/:organisationId/public-sector-organisation/frameworks/ccs/:frameworkId',
        exact: false,
        strict: false
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [framework, setFramework] = useState<CCSFramework>();
    useEffect(() => {
        const fetchFramework = async (frameworkId: number) => {
            setLoading(true);
            setError(false);
            await axios.get(`${contractsHostName}ccs-framework/${frameworkId}`)
                .then(r => setFramework(r.data))
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }
        const fetchFrameworkByOrganisation = async (frameworkId: number) => {
            setLoading(true);
            setError(false);
            await axios.get(`${contractsHostName}ccs-framework/organisation/${organisationPath?.params.organisationId}/framework/${frameworkId}`)
                .then(r => setFramework(r.data))
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }


        const frameworkId = childMatchedPath?.params.frameworkId;
        const organisationFrameworkId = organisationPath?.params.frameworkId;
        console.log(frameworkId, organisationFrameworkId)
        if (frameworkId) {
            fetchFramework(frameworkId);
        } else if (organisationFrameworkId){
            fetchFrameworkByOrganisation(organisationFrameworkId)
        }
    }, []);

    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== pageSize) {
            setPageSize(size);
            setPage(1);
        }
    };

    const getPagedLots = () => {
        const lots = framework!.lots;
        const start = (page - 1) * pageSize;
        const end = start + pageSize;

        if (lots.length > start && lots.length > end) {
            return lots.slice(start, end);
        }

        if (lots.length > start && lots.length < end) {
            return lots.slice(start);
        }

        return [];
    }

    const [selectedLot, setSelectedLot] = useState<CCSLot | null>(null);

    const getBody = () => {
        if (!framework) {
            return null;
        }

        if (error) {
            return <Alert variant="danger">{translate("errors.loading")}</Alert>
        }

        return (
            <div>
                <div className="organisation-card mb-3">
                    <div className="ccs-title">{framework.name}</div>
                    <div className="ccs-subtitle mb-4 mt-n2">{framework.shortDescription}</div>

                    <div className="ccs-main">
                        <div className="ccs-details" style={{width: "40%"}}>
                            <div className="organisation-card-label">Details</div>
                            <div className="ccs-detail-item">
                                <span className="detail-label me-2">Framework ID</span>
                                <span>{framework.code}</span>
                            </div>
                            <div className="ccs-detail-item">
                                <span className="detail-label me-2">Start Date</span>
                                <span>{DateFormatter.formatDate(framework.startDate)}</span>
                            </div>
                            <div className="ccs-detail-item">
                                <span className="detail-label me-2">End Date</span>
                                <span>{DateFormatter.formatDate(framework.endDate)}</span>
                            </div>
                        </div>
                        <div className="ccs-details" style={{width: "60%"}}>
                            <div className="organisation-card-label">Description</div>
                            <div>{framework.longDescription}</div>
                        </div>
                    </div>
                </div>

                {(framework.lots && framework.lots.length > 0) && (
                    <>
                        <div className="organisation-card-label">Lots</div>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>Lot Number</th>
                                    <th style={{width: "70%"}}>Description</th>
                                    <th className="text-center">
                                        Suppliers
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {getPagedLots().map(lot => (
                                    <tr key={lot.id}>
                                        <td>{lot.lotNumber}</td>
                                        <td>{lot.description}</td>
                                        <td className="text-center">
                                            <button className="iqx-button secondary sm-size"
                                                    disabled={!lot.organisations || lot.organisations.length === 0}
                                                    onClick={() => setSelectedLot(lot)}>
                                                <span className="me-3">View Suppliers</span>
                                                <span className="badge badge-primary mt-1 pull-right">{lot.organisations?.length || 0}</span>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="mt-2">
                            <div className="justify-content-md-end pagination mt-n2">
                                <span className="me-2">
                                    <Form.Control as="select"
                                                  style={{ minWidth: "13%" }}
                                                  defaultValue={pageSize}
                                                  onChange={(event) => changePageSize(event)}
                                                  >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                    </Form.Control>
                                </span>
                                <span>
                                    <Pagination totalRecords={framework.lots.length}
                                                pageLimit={pageSize}
                                                pageRangeDisplayed={1}
                                                initPage={page}
                                                onChangePage={setPage}
                                    />
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }

    interface CompanyLogo {
        imageUrl: string | undefined,
        companyName: string | undefined
    }

    const CompanyLogo: React.FC<CompanyLogo> = (props: CompanyLogo) => {
        const [src, setSrc] = useState<string>(props.imageUrl ? props.imageUrl : "./icons_company.svg");

        return (
            <Image title={props.companyName}
                   style={{
                       height: "50px",
                       width: "50px",
                       objectFit: "cover",
                       border: "2px solid #5FD1F9"
                   }}
                   src={src}
                   onError={() => setSrc("/icons_company.svg")}
                   roundedCircle />
        )
    };

    return (
        <div style={{
            paddingTop: "10px",
            backgroundColor: "white",
            borderRadius: "0.45rem",
            padding: 25,
            marginTop: 10
        }}>
            <Row className="mb-4">
                <Col className="pt-2">
                    <div className="organisation-card-label">Crown Commercial Service</div>
                </Col>
                <Col className="pull-right">
                    <button className="iqx-button md-size pull-right" onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                        <span className="me-1">Back</span>
                    </button>
                </Col>
            </Row>

            {error && <Alert variant="danger">{translate("errors.loading")}</Alert>}
            {loading ? <IqLoadingIcon /> : getBody()}

            <Modal size="lg"
                   show={selectedLot != null}
                   onHide={() => setSelectedLot(null)}>
                <Modal.Header closeButton className="ps-4 pe-4">
                    <Modal.Title>Suppliers</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <div style={{maxHeight: "50em", overflowY: "scroll"}}>
                        {selectedLot?.organisations.sort((a, b) => a.companyName.localeCompare(b.companyName)).map(org => (
                            <div className="ccs-title mb-4">
                                <span className="me-3"><CompanyLogo imageUrl={org.imageUrl} companyName={org.companyName} /></span>
                                {(org && org.id) ? (
                                    <a href={`/organisation/${org.id}`} className="iqx-link">{org.companyName}</a>
                                ) : (
                                    <span style={{cursor: "not-allowed"}}>{org.companyName}</span>
                                )}
                            </div>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CCSFrameworkView;