import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {contractsHostName, searchHostname, socialHostName} from "../../utils/Configuration";
import translate from "../../i18n/translate";
import AsyncSelect from "react-select/async";

import AwesomeDebouncePromise from "awesome-debounce-promise";
import {Col, Form, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faSave} from "@fortawesome/free-solid-svg-icons";
import {format} from "d3-format";
import { JobsFilters } from "./UserJobHome";
import { SelectOption } from "../news/GlobalNews";
import {matchPath, useLocation } from "react-router-dom";

interface Props {
    jobFilters: JobsFilters
    setJobFilters: any
}

const JobFilters: React.FC<Props> = (props: Props) => {

    const [searchTerm, setSearchTerm] = useState<string>(props.jobFilters.searchTerm);
    const [selectedOrganisations, setSelectedOrganisations] = useState<string[]>(props.jobFilters.organisations || []);

    const [createdDateStart, setCreatedDateStart] = useState<string>(props.jobFilters.createdDateStart);
    const [createdDateTo, setCreatedDateTo] = useState<string>(props.jobFilters.createdDateTo);
    const [selectedLocations, setSelectedLocations] = useState<string[]>(props.jobFilters.locations || [])
    const [locations, setLocations] = useState<string[]>()

    interface SearchCompany {
        companyId: string,
        companyNumber: string,
        companyName: string,
        imageUrl: string,
        country: string,
        employees: number,
        revenues: number
    }

    interface Country {
        label: string,
        value: string
    }

    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/lists/:listId/jobs',
        exact: false,
        strict: false
    });

    useEffect( () => {

        const fetchLocations = async () => {

            await axios.get(socialHostName + "job/locations")
                .then(r => {
                    if (r.status < 299) {
                        console.log(r.data)
                        setLocations(r.data);
                    }
                }).catch((error) => {
                    setLocations([]);
                })
        }

        fetchLocations()

    }, []);


    const updateOrganisations = (e: SelectOption[]) => {
        if (e) {
            let selection = e.map(opt => opt.label);
            setSelectedOrganisations(selection);
        }
    };

    const updateLocations = (e: SelectOption[]) => {
        if (e) {
            let selection = e.map(opt => opt.value);
            setSelectedLocations(selection);
        }
    };

    const loadOrganisations = (q: string) => {
        let data = {
            searchQuery: q,
            filters: ["Active"]
        };

        return new Promise<SelectOption[]>((resolve: any, reject: any) => {
            axios.post(searchHostname + "quick-search", data)
                .then((r) => {
                    resolve(
                        r.data.map((o: SearchCompany) => {
                            return {
                                label: o.companyName,
                                value: o.companyId
                            }
                        })
                    );
                })
                .catch(reject);
        });
    };

    const fetchOrganisationOptions = async (q: string) => {
        if (q.length < 3) {
            return;
        }

        let params = {
            q: q
        };

        return new Promise<SelectOption[]>((resolve: any, reject: any) => {
            axios.get(`${searchHostname}quick-search`, {params: params})
                .then(r => {
                    resolve(r.data.map((o: string) => {
                        return {
                            label: <div style={{display: "flex"}}>
                                <span>{o}</span>
                            </div>,
                            value: o
                        }
                    }));
                })
                .catch(reject);
        });
    }
    const searchOrganisations = AwesomeDebouncePromise(fetchOrganisationOptions, 300);

    const applyFilters = () => {
        let filters = {
            organisations: selectedOrganisations,
            searchTerm: searchTerm,
            locations: selectedLocations,
            createdDateStart: createdDateStart,
            createdDateTo: createdDateTo,

        } as JobsFilters;

        props.setJobFilters(filters);
    }

    const clearFilters = () => {
        props.setJobFilters({} as JobsFilters);
    }

    const clearDisabled = () => {
        return selectedOrganisations?.length === 0 &&
            !searchTerm &&
            !createdDateTo &&
            !createdDateStart
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Filters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
                    <div>
                        <div className="organisation-card-label">Search Term</div>
                        <Form.Control name="key" type="text"
                                      placeholder={translate("advancedsearch.search")}
                                      value={searchTerm}
                                      onChange={(e: any) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div>
                        <div className="organisation-card-label">
                            Locations
                        </div>
                        <AsyncSelect
                            placeholder={translate("advancedsearch.search")}
                            defaultOptions={locations?.filter(s => !!s).map(o => ({label: translate("locations." + o), value: o}))}
                            isClearable
                            isMulti
                            defaultValue={selectedLocations?.filter(s => !!s).map(o => ({label: translate("locations." + o), value: o}))}
                            onChange={(e: any) => updateLocations(e)}
                            />
                    </div>
                    {!childMatchedPath?.params?.listId && (
                        <div>
                            <div className="organisation-card-label">
                                Organisations
                            </div>
                            <AsyncSelect
                                placeholder={translate("advancedsearch.search")}
                                defaultOptions={[]}
                                isClearable
                                isMulti
                                defaultValue={selectedOrganisations?.map(o => ({label: o, value: o}))}
                                onChange={(e: any) => updateOrganisations(e)}
                                loadOptions={loadOrganisations}/>
                        </div>
                        )}


                    <div className="mb-2">
                        <div className="organisation-card-label">
                            Start Date
                        </div>
                        <Row>
                            <Col className="col-5">
                                <input type="date" className="form-control" value={createdDateStart} onChange={(e: any) => setCreatedDateStart(e.target.value)}/>
                            </Col>
                            <Col className="text-center pt-2 col-1">
                                <span>{translate("advancedsearch.to")}</span>
                            </Col>
                            <Col className="col-5">
                                <input type="date" className="form-control" value={createdDateTo} onChange={(e: any) => setCreatedDateTo(e.target.value)}/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="iqx-button secondary md-size me-3"
                        disabled={clearDisabled()}
                        onClick={clearFilters}>
                    Clear Filters
                    <FontAwesomeIcon icon={faFilter} className="ms-2"/>
                </button>

                <button className="iqx-button primary md-size" onClick={applyFilters}>
                    Apply
                    <FontAwesomeIcon icon={faSave} className="me-1 ms-2" />
                </button>
            </Modal.Footer>
        </>
    );
};

export default JobFilters;
