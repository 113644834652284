export const seniorityOptions: string[] = [
	"Entry-Level",
	"Executive Level",
	"Experienced Staff",
	"Middle-Management",
	"Team-Lead",
	"Top-Tier Leadership"
]

export const countriesOptions: string[] = [
	"United Kingdom",
	"France"
]

export const rolesOptions: string[] = [
	"CxO",
	"Director",
	"Manager",
	"Owner",
	"Partner",
	"VP"
]


export const countryOptions: string[] = [
	"Germany",
	"United Kingdom",
]