import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {frontEndHostName, searchHostname} from "../../utils/Configuration";
import {SelectOption} from "../news/GlobalNews";
import SearchCompany from "../../model/advanced-search/SearchCompany";
import AsyncSelect from "react-select/async";
import translate from "../../i18n/translate";
import {Form, Spinner} from "react-bootstrap";
import List from "../../model/list/List";
import IqLoadingIcon from "../common/IqLoadingIcon";
import I18nContext from "../../context/I18nContext";
import IqFlag from "../common/CountryFlag";
import './OnboardingCustomers.css'
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface OnboardingCustomersProps {
    continue: Function
}

const OnboardingCustomers: React.FC<OnboardingCustomersProps> = (props: OnboardingCustomersProps) => {

    const [customersList, setCustomersList] = useState<List>();
    const [loading, setLoading] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const {lang, setLang} = useContext(I18nContext);


    useEffect(() => {
        const fetchList = async () => {
            setLoading(true);
            await axios.get(frontEndHostName + "user-lists/customers")
                .then(r => setCustomersList(r.data))
                .finally(() => setLoading(false));
        };

        fetchList();
    }, []);

    const [customers, setCustomers] = useState<Map<number, string> | undefined>(undefined);


    const updateCustomers = (value: string, i: number) => {
        setCustomers(customers === undefined
            ? new Map<number, string>([[i, value]])
            : customers.set(i, value));
    };



    interface CompanyProperty {
        icon: IconProp,
        tooltip: string,
        value: string | number,
    }



    const loadOrganisations = (q: string) => {
        let data = {
            searchQuery: q,
            filters: ["Active"]

        };

        return new Promise<SelectOption[]>((resolve: any, reject: any) => {
            axios.post(searchHostname + "quick-search", data)
                .then((r) => {
                    resolve(
                        r.data.map((o: SearchCompany) => {
                            return {
                                label: <div style={{display: "flex"}}>
                                    <span>{o.companyName +" - "+ o.companyId}</span>
                                    <span className={"option-country-flag"}><IqFlag country={o.country} width={25} height={25} /></span>
                                </div>,
                                value: o.companyId
                            }
                        })
                    );
                })
                .catch(reject);
        });
    };

    useEffect(() => {
    console.log(customers)
    },[customers])

    const save = () => {
        console.log(customers);
        if (customers?.size === 0) {
            props.continue();
        }

        else {
            setSaving(true);
            let customerData: string[] = []
            customers?.forEach(value =>
                 customerData.push(value)
            );
            setSaving(true);
            axios.put(frontEndHostName + 'user-lists/' + customersList?.id + '/add-companies', customerData)
                .then(() => props.continue())
                .finally(() => setSaving(false));
        }


    };


    return (
        <div className="form-group">
            {loading ? <IqLoadingIcon /> : (
                <div>
                    <div className="row mb-3">
                        <div className="col-xs-4">
                            <span className="welcome">Help us by selecting some of your customers.</span>
                        </div>
                    </div>

                    {Array.from(Array(5).keys()).map(i =>
                        (<Form.Group key={i}>
                            <Form.Label>Customer {i + 1}</Form.Label>

                            <AsyncSelect

                                         placeholder={translate("onboarding.typeandselect")}
                                         defaultOptions={[]}
                                         isClearable
                                         onChange={(e: any) => {
                                             if (e !== null){
                                                 updateCustomers(e.value,i)
                                             } else {
                                                 customers?.delete(i)
                                             }
                                         }}
                                         loadOptions={loadOrganisations}/>
                        </Form.Group>)
                    )}

                    {saving ? (
                        <button className="iq-button iq-button-primary pull-right"
                                disabled={true}>
                            <span className="me-2">
                                {translate("onboarding.continue")}
                            </span>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </button>
                    ) : (
                        <button className="iq-button iq-button-primary pull-right"
                                onClick={() => save()}>
                            <span>
                                {translate("onboarding.continue")}
                            </span>
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default OnboardingCustomers;