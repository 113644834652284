import React, {useState} from "react";
import {Card, Col, Image, Row} from "react-bootstrap";
import DateFormatter from "../../../utils/formatters/DateFormatter";
import translate from "../../../i18n/translate";
import {EmailIcon, EmailShareButton, LinkedinIcon, LinkedinShareButton, TwitterShareButton, XIcon} from "react-share";
import CaseStudy from "../../../model/organisation/CaseStudy";
import InvestorRelations from "../../../model/organisation/InvestorRelations";

const InvestorRelationsCard: React.FC<{investorRelation: InvestorRelations}> = ({investorRelation}) => {

    const Thumbnail: React.FC<{url: string}> = ({url}) => {
        const index = url.lastIndexOf("/") + 1;
        const fileName = url.substring(index).replace(".pdf", ".png");
        const imageUrl = "https://iqblade-preprod.s3.eu-west-1.amazonaws.com/investor-relations-thumbnails/" + fileName;
        const [thumb, setThumb] = useState<string>(imageUrl);
        return (
            <Image
                src={thumb}
                style={{ maxHeight: "50vh",
                    maxWidth: "29em",
                    marginRight: 15,
                    marginBottom: 15 }}
                //@ts-ignore
                onError={(event) => event.target.style.display = 'none'}
            />
        );
    }

    return (
        <Card>
            <Card.Body className="mb-2">
                <Card.Title>
                    <a className="news-title" href={investorRelation.sourceUrl} target="_blank">{investorRelation.title}</a>
                </Card.Title>
                <Card.Text className="mt-1 text-center">
                    <Thumbnail url={investorRelation.sourceUrl} />
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col className="pt-1">
                        {!!investorRelation.createdAt && (
                            <span>
                                <small style={{ fontWeight: 500 }} className="text-muted">Created: {DateFormatter.formatDate(investorRelation.createdAt)}</small>
                            </span>
                        )}
                    </Col>
                    <Col>
                        <span className="float-end">
                            <small className="text-muted me-2">{translate("organisation.temporarylink.share")}:</small>
                            <span title="LinkedIn" className="me-2">
                                <LinkedinShareButton title={investorRelation.title}
                                                     url={investorRelation.sourceUrl}>
                                    <LinkedinIcon size={32} round={true}/>
                                </LinkedinShareButton>
                            </span>
                            <span title="Twitter" className="me-2">
                                <TwitterShareButton title={investorRelation.title}
                                                    url={investorRelation.sourceUrl}>
                                    <XIcon size={32} round={true}/>
                                </TwitterShareButton>
                            </span>
                            <span title="Email">
                                <EmailShareButton title={investorRelation.title}
                                                  url={investorRelation.sourceUrl}>
                                    <EmailIcon size={32} round={true}/>
                                </EmailShareButton>
                            </span>
                        </span>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    );
};

export default InvestorRelationsCard;