import React, {useEffect, useState} from "react";
import translate from "../../../i18n/translate";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {Alert, Form, Image, Table} from "react-bootstrap";
import {ListEvent} from "../../lists/activity/ListActivity";
import DefaultAvatar from "../../../utils/formatters/DefaultAvatar";
import EventType from "../../lists/activity/EventType";
import DateFormatter from "../../../utils/formatters/DateFormatter";
import axios from "axios";
import {frontEndHostName} from "../../../utils/Configuration";
import {useHistory} from "react-router-dom";
import Pagination from "../../../utils/PaginationHook";

interface Props {
    teamId: number
}
const ListEventsTable: React.FC<Props> = (props: Props) => {
    let history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [listActivity, setListActivity] = useState<ListEvent[]>([]);
    const [totalRecords, setTotalRecords] = useState<number>();

    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== pageSize) {
            setPageSize(size);
        }
    };

    useEffect(() => {
        const fetchListEvents = async () => {
            setLoading(true);

            let params: any = {
                page: page - 1,
                size: pageSize
            };

            await axios.get(frontEndHostName + "teams/" + props.teamId + "/dashboard/list-events", {
                    params: params
                })
                .then(r => {
                    let data = r.data;
                    setListActivity(data.content);
                    setTotalRecords(data.totalElements);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchListEvents();
    }, [page, pageSize]);

    return (
        <div className="organisation-card">
            <div className="organisation-card-label">
                {translate("teams.lists.listevents")}
            </div>

            {loading ? <IqLoadingIcon /> : (
                <div className="organisation-card-text">
                    <Table striped hover size="sm">
                        <thead>
                        <tr>
                            <th>{translate("teams.lists.user")}</th>
                            <th>{translate("teams.lists.listname")}</th>
                            <th>{translate("teams.lists.eventtype")}</th>
                            <th className="text-center">{translate("teams.lists.date")}</th>
                            <th>{translate("teams.lists.sourced")}</th>
                            <th>{translate("teams.lists.organisations")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {listActivity?.map((event: ListEvent) => (

                            <tr>
                                <td>
                                    <Image style={{height: "40px", width: "40px", objectFit: "cover", marginRight: 15}}
                                           title={event.user.login}
                                           src={DefaultAvatar.displayDefaultAvatar(event.user.imageUrl)}
                                           roundedCircle/>
                                    {event.user.firstName} {event.user.lastName}
                                </td>
                                <td>
                                    <a className="iq-link" onClick={() => history.push(`/lists/${event.listId}`)}>
                                        {event.listName}
                                    </a>
                                </td>
                                <td>{translate("teams." + EventType.ALL_TYPES[event.type].displayName)}</td>
                                <td className="text-center">
                                    {DateFormatter.formatMillis(event.created)}
                                </td>
                                <td>
                                    {event.advancedSearchId !== null ? (
                                    <a className="iq-link" onClick={() => history.push(`/advanced-search/${event.advancedSearchId}`)}>
                                        {event.advancedSearchName}
                                    </a>
                                ) : (
                                    "QuickSearch"
                                )}
                                </td>
                                <td>
                                    {event.organisationList.map(org => (
                                        <div>{org.companyName}</div>
                                    ))}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                    {error && (
                        <Alert variant="danger">
                            {translate("errors.loading")}
                        </Alert>
                    )}

                    <div className="justify-content-md-end pagination mt-n2">
                        <span className="me-2">
                            <Form.Control as="select"
                                          style={{ minWidth: "13%" }}
                                          defaultValue={pageSize}
                                          onChange={(event) => changePageSize(event)}
                                          >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                            </Form.Control>
                        </span>
                        <span>
                            {totalRecords && (
                                <Pagination totalRecords={totalRecords}
                                            pageLimit={pageSize}
                                            pageRangeDisplayed={1}
                                            initPage={page}
                                            onChangePage={setPage}
                                />
                            )}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListEventsTable;