import React, {createRef, useState} from 'react';
import {faCog} from "@fortawesome/free-solid-svg-icons";
import OnboardingPreferences from "./OnboardingPreferences";
import Card from "../profile/ProfileCard";
import OnboardingPreferencesControl from "./OnboardingPreferencesControl";

const OnboardingPreferencesForm = () => {

    const [page, setPage] = useState<number>(1);
    const [nextButtonEnabled, setNextButtonEnabled] = useState<boolean>(false);
    const [complete, setComplete] = useState<boolean>(false);
    const scrollToRef = createRef<HTMLDivElement>();

    const nextPage = () => {
        setPage(p => p + 1);
    }

    const prevPage = () => {
        setPage(p => p - 1);
    }

    const restart = () => {
        setComplete(false);
        setPage(0);
    }

    const renderPrefs = () => {
        return complete ? (
            <>
                <div className="mb-4">
                    <span className="welcome">User Settings Updated</span>
                </div>
                <button className="iq-button iq-button-primary" onClick={restart}>
                    <span>Start Again</span>
                </button>
            </>
        ) : (
            <>
                <OnboardingPreferences page={page} setEnableContinueCallback={setNextButtonEnabled} continueCallback={nextPage} onCompleteCallback={() => setComplete(true)} showWelcome={false} scrollToRef={scrollToRef}/>
                <OnboardingPreferencesControl page={page} lastPage={7} nextButtonEnabled={nextButtonEnabled} nextPageCallback={nextPage} prevPageCallback={prevPage}/>
            </>
        );
    }

    return (
        <Card heading={"User Settings"} iconDefinition={faCog}>
            {renderPrefs()}
            <div ref={scrollToRef}/>
        </Card>
    )
}

export default OnboardingPreferencesForm;