import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {socialHostName} from "../../../utils/Configuration";
import YoutubeVideo from "../../../model/organisation/social/YoutubeVideo";
import UserListContext from "../../../context/list/UserListContext";
import ScrollPaginationContext from "../../../context/ScrollPaginationContext";
import {Alert} from "react-bootstrap";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import translate from "../../../i18n/translate";

const UserListYouTube: React.FC = () => {
    const {list, setList} = useContext(UserListContext);
    const {page, setPage} = useContext(ScrollPaginationContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [videos, setVideos] = useState<YoutubeVideo[]>([]);
    useEffect(() => {
        const fetchVideos = async () => {
            setLoading(true);
            await axios.get(socialHostName + "youtube/latest-videos?page=" + page +
                "&size=10&source=" + list?.id + "&type=all")
                .then(r => {

                    setVideos((prevVideos: YoutubeVideo[]) => {
                        return [...prevVideos, ...r.data.content];
                    })
                })
                .catch(error => setError(true))
                .finally(() => setLoading(false));
        };


        if (list) {
            fetchVideos();
        }

        return () => {
            setPage(0);
        }
    }, [list, page]);

    return (
        <div style={{maxHeight: "100%", width: "100%", overflowY: "scroll", overflowX: "hidden", marginLeft: 0, marginRight: 0, marginTop: 15 }}>
            {error ? (
                <Alert variant="danger">
                    {translate("errors.loading")}
                </Alert>
            ) : (
                <div style={{width: 550, margin: "auto"}}>
                    {!loading && videos.length === 0 && (
                        <Alert variant="primary">
                            We couldn't find any recent tweets for this list.
                        </Alert>
                    )}

                    {videos.map((video: YoutubeVideo) => (
                        <div className="embed-responsive-16by9">
                            <iframe style={{width: "100%", height: 400}} className="embed-responsive-item" src={`https://www.youtube.com/embed/${video.id}`} />
                        </div>
                    ))}
                    <div>{loading && <IqLoadingIcon/>}</div>
                </div>
            )}
        </div>
    );
};

export default UserListYouTube;