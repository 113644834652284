import React, {useContext, useEffect, useState} from "react";
import {Alert} from "react-bootstrap";
import NewsItem from "../../model/news/NewsItem";
import axios from "axios";
import {socialHostName} from "../../utils/Configuration";
import OfficerContext from "../../context/officer/OfficerContext";
import NewsFeed from "../news/NewsFeed";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import ScrollPaginationContext from "../../context/ScrollPaginationContext";

const OfficerNews: React.FC = () => {
    const {officer, setOfficer} = useContext(OfficerContext);
    const {page, setPage} = useContext(ScrollPaginationContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [news, setNews] = useState<NewsItem[]>([]);

    useEffect(() => {
        const fetchNews = async () => {
            setLoading(true);

            await axios.get(socialHostName + "officer/" + officer.companiesHouseId + "/news?page=" + page + "&size=18")
                .then(r => {
                    setNews((previousNews: NewsItem[]) => {
                        let allItems: NewsItem[] = [...previousNews, ...r.data];

                        let uniqueItems: NewsItem[] = [];
                        for (let item of allItems) {
                            if (!uniqueItems.find((existing: NewsItem) => item.id === existing.id)) {
                                uniqueItems.push(item);
                            }
                        }

                        return uniqueItems;
                    });
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));

        };

        fetchNews();
    }, [page]);

    useEffect(() => {
        setPage(0);
    }, []);

    return (
        <div style={{marginTop: 25}}>
            {news.length === 0 && !loading ? (
                <Alert variant="warning" style={{ width: "100%", marginTop: 25 }}>
                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", alignContent: "center" }}>
                        <FontAwesomeIcon icon={faExclamationCircle} color="#367CDB" size="2x" />
                        <span style={{ marginLeft: 15 }}>We don't have news articles mentioning this officer.</span>
                    </div>
                </Alert>
            ) : (
                <NewsFeed news={news}/>
            )}

            {loading && <IqLoadingIcon />}
        </div>
    );
};

export default OfficerNews;
