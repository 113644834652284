import React, {useState} from "react";
import {UserResponse} from "../../model/users/UserResponse";
import {Col, Row} from "react-bootstrap";
import ProfileContainer from "./ProfileContainer";
import ChangePasswordForm from "./ChangePasswordForm";
import CurrentAllowance from "./CurrentAllowance";
import ChangeAvatarForm from "./ChangeAvatarForm";
import OnboardingPreferencesForm from "../onboarding/OnboardingPreferencesForm";
import ChangeInterests from "./ChangeInterests";
import {showGenericErrorDialog, showGenericSuccessDialog} from "../common/CommonAlerts";

const ProfileSettings: React.FC = () => {

    const [user, setUser] = useState<UserResponse>({} as UserResponse);

    return (
        <div>
            {}
            <ProfileContainer/>
            <Row className="mt-3">
                <Col xs={12} sm={6} lg={6}>
                    <ChangePasswordForm/>
                </Col>
                <Col xs={6} sm={3} lg={3}>
                    <CurrentAllowance/>
                </Col>
                <Col xs={6} sm={3} lg={3}>
                    <ChangeInterests/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12} sm={6} lg={6}>
                    <ChangeAvatarForm setUserCallback={setUser} setSuccessCallback={showGenericSuccessDialog} setErrorCallback={showGenericErrorDialog}/>
                </Col>
                <Col xs={12} sm={6} lg={6}>
                    <OnboardingPreferencesForm/>
                </Col>
            </Row>
        </div>
    );
}


export default ProfileSettings;