import React, {useState} from "react";
import {frontEndHostName} from "../../utils/Configuration";
import axios from "axios";
import translate from "../../i18n/translate";
import {Form, Spinner} from "react-bootstrap";
import Select from 'antd/lib/select';

interface Props {
    continue: Function
}

const OnboardingInterests: React.FC<Props> = (props: Props) => {

    const [interests, setInterests] = useState<string[]>([]);
    const handleInterestsChange = (val: string[]) => {
                setInterests(val);
    };

    const [saving, setSaving] = useState<boolean>(false);
    const save = async () => {
        setSaving(true);
        await axios.post(frontEndHostName + "interests/save", interests)
            .finally(() => {
                setSaving(false);
                props.continue();
            });
    };

    return (
        <div className="form-group">
            <Form.Group>
                <Form.Label>Interests</Form.Label>
                <Form.Text className="mb-3">
                    We will use these to add some companies to your dashboard that you may be interested in e.g. IOT, security
                </Form.Text>
                <Select
                    disabled={false}
                    mode={'tags'}
                    style={{
                        minWidth: 200,
                        width: "100%"
                    }}
                    dropdownStyle={{
                        width: 250,
                    }}
                    key="widget-multiselect"
                    placeholder="Input interests"
                    size="large"
                    value={interests}
                    tokenSeparators={[',']}
                    onChange={(val: string[]) => { handleInterestsChange(val) }}
                    filterOption={true}
                />
            </Form.Group>

            {saving ? (
                <button className="iq-button iq-button-primary pull-right"
                        disabled={true}>
                            <span className="me-2">
                                {translate("onboarding.continue")}
                            </span>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                </button>
            ) : (
                <button className="iq-button iq-button-primary pull-right"
                        onClick={() => save()}>
                    <span>
                        {translate("onboarding.continue")}
                    </span>
                </button>
            )}
        </div>
    );
};

export default OnboardingInterests;