import React from "react";
import NewsItem from "../../model/news/NewsItem";
import NewsCard from "./NewsCard";
import Masonry from "react-masonry-css";
import "./NewsFeed.css";

interface Props {
    news: NewsItem[]
}

const NewsFeed: React.FC<Props> = (props: Props) => {

    return (
        <Masonry breakpointCols={3}
                 className="news-grid"
                 columnClassName="news-grid-column">
            {props.news.map((newsItem: NewsItem, index: number) => (
                <div key={`key${index}`} className="mb-3">
                    <NewsCard item={newsItem}/>
                </div>
            ))}
        </Masonry>
    );
};

export default NewsFeed;
