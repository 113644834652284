import React from "react";
import {Modal} from "react-bootstrap";
import "./CSVUploadInfo.css";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FileDownload from "js-file-download";

const CSVUploadInfo: React.FC = () => {
    const csvHeaders = ["companyName", "companyNumber", "url", "postCode", "country"];

    const downloadFile = () => {
        let data = csvHeaders.join(",");
        FileDownload(data, "IQBlade-CSV-Template.csv");
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    CSV Upload
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <div>A CSV file uploaded here expects the following headers:</div>
                <div className="csv-header-block mt-2">
                    {csvHeaders.map((h: string, idx: number) => (
                        <span className="pe-3">{h}{(idx < csvHeaders.length - 1) && ","}</span>
                    ))}
                </div>
                <div className="mt-3">
                    For more accurate results, it is advisable to include at least 2 of these fields.<br />
                    Country code is a mandatory field and accepts values UK, DE, FR.
                </div>
                <div className="row pull-right mt-3 pe-3">
                    <button className="iqx-button dark-blue sm-size pt-1 csv-download-button" onClick={downloadFile}>
                        <span className="pe-3">Download CSV Template</span>
                        <span className="csv-icon">
                            <FontAwesomeIcon icon={faDownload} />
                        </span>
                    </button>
                </div>
            </Modal.Body>
        </>
    );
};

export default CSVUploadInfo;