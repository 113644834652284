import React, {useContext, useEffect, useRef, useState} from "react";
import NewsItem from "../../../model/news/NewsItem";
import DashboardItemContext from "../../../context/dashboard/DashboardItemContext";
import axios from "axios";
import {frontEndHostName, socialHostName} from "../../../utils/Configuration";
import {Alert, Col, Row} from "react-bootstrap";
import NewsCard from "../../news/NewsCard";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import translate from "../../../i18n/translate";
import Select from "react-select";
import I18nContext from "../../../context/I18nContext";
import Item from "./Item";
import DashboardContext from "../../../context/dashboard/DashboardContext";
import DashboardKeyContext from "../../../context/dashboard/DashboardKeyContext";

interface SelectOption {
    value: string,
    label: string
}

interface NewsPreferences {
    id: number | null,
    userId: number,
    title: string,
    sectors: string[],
    topics: string[],
    terms: string[]
    default: boolean
}

interface Props {
    element: Item
}


const DashboardFollowedNews: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [news, setNews] = useState<NewsItem[]>([]);
    const {layout} = useContext(DashboardContext);
    const {widgetId} = useContext(DashboardKeyContext)
    const [dashboardId] = useState<number>(layout[0].dashboardId)

    const typeOptions: SelectOption[] = [
        {
            value: "all",
            label: "All"
        },
        {
            value: "organisation",
            label: "Organisation"
        },
        {
            value: "officer",
            label: "Officer"
        }
    ];
    const sourceInitialOptions: SelectOption[] = [
        {
            value: "globalnews",
            label: "Global News"
        }
    ]
    const {lang, setLang} = useContext(I18nContext);
    const [sourceOptions, setSourceOptions] = useState<SelectOption[]>();
    const [selectedSource, setSelectedSource] = useState<SelectOption>(sourceInitialOptions[0]);
    const [preferences, setPreferences] = useState<NewsPreferences[]>([]);
    const [sectors, setSectors] = useState<string[]>([]);
    const [topics, setTopics] = useState<string[]>([]);
    const [language, setLanguage] = useState<string[]>([lang]);
    const [terms, setTerms] = useState<string[]>([]);
    const [loadingPreferences, setLoadingPreferences] = useState<boolean>(false);
    const {maxHeight, setMaxHeight} = useContext(DashboardItemContext);
    const [isPreferenceRetrieved, setIsPreferenceRetrieved] = useState<boolean>(false);


    const scroller = useRef<any>();
    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        const getWidgetPreferences = async () => {
            await axios.get(frontEndHostName + "api/dashboard/filter?widgetId=" + widgetId + "&dashboardId=" + dashboardId)
                .then(r => {
                    if (!r.data.selectedSource) {
                        setSelectedSource(sourceInitialOptions[0])
                    }
                    setSelectedSource(r.data.selectedSource)
                })
        }
        setIsPreferenceRetrieved(true)
        getWidgetPreferences()
    }, [])
    const handleScroll = () => {
        if (!loading && !error) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            let currentScroll = scroller.current.scrollTop;

            if (currentScroll === maxScroll) {
                setPage(prev => prev + 1);
            }
        }
    };

    useEffect(() => {
        const fetchNews = async () => {
            setLoading(true);

            let query = "?page=" + page + "&size=18";
            if (sectors?.length > 0) {
                for (let i = 0; i < sectors.length; i++) {
                    query += "&sectors=" + sectors[i];
                }
            }

            if (topics?.length > 0) {
                for (let i = 0; i < topics.length; i++) {
                    query += "&topics=" + topics[i];
                }
            }

            if (terms?.length > 0) {
                for (let i = 0; i < terms.length; i++) {
                    query += "&terms=" + terms[i];
                }
            }

            if (language?.length > 0) {
                for (let i = 0; i < language.length; i++) {
                    query += "&lang=" + language[i];
                }
            }

            await axios.get(socialHostName + "news" + query)
                .then(r => {
                    if (page === 0) {
                        setNews([...r.data]);
                    } else {
                        setNews((previousNews: NewsItem[]) => {
                            return [...previousNews, ...r.data];
                        });
                    }
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchNews();
    }, [sectors, terms, topics]);

    useEffect(() => {
        const fetchPreferences = async () => {
            setLoadingPreferences(true);
            await axios.get(socialHostName + "preferences")
                .then(r => {
                    setPreferences(r.data)


                })
                .catch(() => setError(true))
                .finally(() => setLoadingPreferences(false));
        };

        fetchPreferences();
    }, []);

    useEffect(() => {
        const saveWidgetPreferences = () => {
            if (isPreferenceRetrieved) {
                let data = {selectedSource}
                axios.post(frontEndHostName + "api/dashboard/save/filter?widgetId=" + widgetId + "&dashboardId=" + dashboardId, data)
            }
        }
        saveWidgetPreferences()
    }, [selectedSource])

    useEffect(() => {
        let s = preferences.find((item: NewsPreferences) => item.id === parseInt(selectedSource.value));
        if (s) {
            setTopics(s!.topics);
            setSectors(s!.sectors);
            setTerms(s!.terms);
        }
    }, [selectedSource])


    useEffect(() => {
        let listOptions: SelectOption[] = preferences.map((l: { id: any; title: any; }) =>
            ({
                value: String(l.id),
                label: l.title
            })
        )
        setSourceOptions([...listOptions])

        preferences.map(p => {
            if (p.default) {
                let defaultOption: SelectOption = ({
                    value: String(p.id),
                    label: p.title
                })
                setSelectedSource(defaultOption)
            }
        })
    }, [preferences])


    useEffect(() => {
        if (loading && page !== 0) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            scroller.current.scrollTop = maxScroll;
        }
    }, [loading]);

    const getHeight = () => {
        return maxHeight - 110;
    };

    return (
        <div>
            <Row className="pb-2">
                <Col>
                    <div className="organisation-card-label">
                        {translate("dashboard.followednews.title")}
                    </div>
                </Col>
            </Row>
            <Row style={{paddingBottom: "10px"}}>
                <Col className="pt-2" xl={6} lg={6} md={3} sm={6} xs={6}>
                    <Select
                        options={sourceOptions}
                        value={selectedSource}
                        onChange={(e: any) => setSelectedSource(e)}
                        placeholder="Select source..."
                        className="basic-multi-select"
                        classNamePrefix="select"/>
                </Col>
            </Row>
            {error ? (
                <Alert variant="danger">
                    {translate("errors.loading")}
                </Alert>
            ) : (
                <div className="nonIqbladeDraggableWidget"  style={{maxHeight: getHeight(), overflowY: "scroll"}}
                     ref={scroller} onScroll={handleScroll}>
                    {news.map((item: NewsItem, index: number) => (
                        <div key={index} className="pb-2">
                            <NewsCard item={item}/>
                        </div>
                    ))}
                    <div style={{alignItems: "center"}}>{loading && <IqLoadingIcon/>}</div>
                </div>
            )}
        </div>
    );
};

export default DashboardFollowedNews;
