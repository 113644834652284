import React, {useContext} from 'react';
import {Table} from 'react-bootstrap';
import UserOrganisationContext from '../../context/organisation/OrganisationContext';
import OrganisationFilingHistory from '../../model/organisation/OrganisationFilingHistory';
import './OrganisationSummaryDetails.css';
import translate from "../../i18n/translate";
import DateFormatter from "../../utils/formatters/DateFormatter";
import OrganisationFilingHistoryFR from "../../model/organisation/OrganisationFilingHistoryFR";

const OrganisationSummaryFilingHistory: React.FC = () => {

    const { organisation } = useContext(UserOrganisationContext);

    const getBodaccLink = (bodaccIdentifier: string, date: string, id: string) => {
        return "https://www.bodacc.fr/annonce/detail-annonce/"+bodaccIdentifier+"/"+date+"/"+id;
    }
    return (
        <div style={{ backgroundColor: "white", borderRadius: "0.45rem", padding: 15, width: "100%" }}>
            <Table responsive striped hover size="sm">
                <thead>
                    <tr>
                        <th style={{minWidth: 100}}>{translate("organisation.summary.filinghistory.date")}</th>
                        <th>{translate("organisation.summary.filinghistory.title")}</th>
                    </tr>
                </thead>
                <tbody>
                    {organisation?.filingHistory?.map((item: OrganisationFilingHistory, index: number) => (
                        <tr key={index}>
                            <td>{DateFormatter.formatDate(item.datePublished)}</td>
                            <td><a target="_blank" href={item.documentLink}> {item.description}</a></td>
                        </tr>
                    ))}
                    {organisation?.filingHistoryFR?.map((item: OrganisationFilingHistoryFR, index: number) => (
                        <tr key={index}>
                            <td>{DateFormatter.formatDate(item.dateOfPublication)}</td>
                            <td><a target="_blank" href={getBodaccLink(item.publicationNotices, item.release, item.adNumber)}> {item.familyReviewLib}</a></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default OrganisationSummaryFilingHistory;
