import React, {useEffect, useState} from "react";
import {Alert, Row, Table} from "react-bootstrap";
import DateFormatter from "../../../utils/formatters/DateFormatter";
import TeamDashboardTable, {ConsumptionData} from "./TeamDashboardTable";
import {TeamUser} from "../Teams";
import {frontEndHostName} from "../../../utils/Configuration";
import {matchPath, useLocation} from "react-router-dom";
import axios from "axios";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import translate from "../../../i18n/translate";
import UserAvatar from "../../common/UserAvatar";


interface RunData {
    name: string;
    user: TeamUser;
    expiryDate: string;
}

const TeamSearches: React.FC = () => {
    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/team/:teamId',
        exact: false,
        strict: false
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [date, setDate] = useState<string>();
    const [userSearchData, setUserSearchData] = useState<RunData[]>([]);
    const [searchCreditUsage, setSearchCreditUsage] = useState<ConsumptionData[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            await axios.get(frontEndHostName + "teams/" + childMatchedPath?.params.teamId + "/dashboard/searches")
                .then(r => {
                    let data = r.data;
                    setDate(data.date);
                    setSearchCreditUsage(data.searchCreditUsages);
                    setUserSearchData(data.userSearches);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchData();
    }, []);

    return (
        <div style={{ paddingTop: 35 }}>
            {loading ? <IqLoadingIcon /> : (
                <div>
                    {error ? (
                        <Alert variant="danger">
                            {translate("errors.loading")}
                        </Alert>
                    ) : (
                        <Row>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div className="organisation-card">
                                    <div className="organisation-card-label">
                                        {translate("teams.searches.advancedsearchesshared", { date: date })}
                                    </div>
                                    <div className="organisation-card-text">
                                        <Table striped hover>
                                            <thead>
                                                <tr>
                                                    <th>{translate("teams.common.username")}</th>
                                                    <th>{translate("teams.searches.search")}</th>
                                                    <th>{translate("advancedsearch.expiringon", { date: "" })}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userSearchData.map((data: RunData) => (
                                                    <tr>
                                                        <td>
                                                            <UserAvatar user={data.user} height="40px" width="40px" marginRight={15} />
                                                            {data.user.firstName} {data.user.lastName}
                                                        </td>
                                                        <td>{data.name}</td>
                                                        <td>{DateFormatter.formatDate(data.expiryDate)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div className="organisation-card">
                                    <div className="organisation-card-label">
                                        {translate("teams.searches.searchcreditusage")}
                                    </div>
                                    <div className="organisation-card-text">
                                        <TeamDashboardTable data={searchCreditUsage} />
                                    </div>
                                </div>
                            </div>
                        </Row>
                    )}

                </div>
            )}
        </div>
    );
};

export default TeamSearches;
