import React, {useContext, useEffect, useRef, useState} from "react";
import {matchPath, Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import OrganisationOfficer, {getOfficerName} from "../../model/organisation/officer/OrganisationOfficer";
import OfficerContext from "../../context/officer/OfficerContext";
import axios from "axios";
import OfficerHeader from "./OfficerHeader";
import SubmenuContext from "../../context/SubmenuContext";
import {faNewspaper, faTools, faUndo, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import OfficerNews from "./OfficerNews";
import Officer from "./Officer";
import OfficerAppointment from "../../model/organisation/officer/OfficerAppointment";
import {organisationHostname} from "../../utils/Configuration";
import OfficerSocial from "./social/OfficerSocial";
import IqLoadingIcon from "../common/IqLoadingIcon";
import ScrollPaginationContext from "../../context/ScrollPaginationContext";
import {hasUserRole, Role} from "../../utils/Security";
import OfficerEdit from "./OfficerEdit";

const OfficerContainer: React.FC = () => {
    const match = useRouteMatch();
    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/officer/:officerId',
        exact: false,
        strict: false
    });

    const [officer, setOfficer] = useState<OrganisationOfficer>({} as OrganisationOfficer);
    const [appointments, setAppointments] = useState<OfficerAppointment[]>([]);
    const {setSubmenuTitle, setSubmenuItems} = useContext(SubmenuContext);

    const successHandler = (officer: OrganisationOfficer) => {
        setOfficer(officer);
        const query = new URLSearchParams(location.search);
        const appointment = query.get("appointment");

        let officerName = getOfficerName(officer);
        setSubmenuTitle(officerName);
        let submenuItems = [
            {
                name: officerName,
                url: "/officer/" + childMatchedPath?.params.officerId,
                icon: faUserCircle
            },
            {
                name: "News",
                url: "/officer/" + childMatchedPath?.params.officerId + "/news",
                icon: faNewspaper
            }
        ];

        // FR-978 hide twitter: https://iqblade.atlassian.net/jira/software/projects/FR/issues/FR-798
        /*if (officer.twitter) {
            submenuItems.push({
                name: "Social",
                url: "/officer/" + childMatchedPath?.params.officerId + "/social",
                icon: faAddressCard
            })
        }*/

        if (hasUserRole(Role.ADMIN)) {
            submenuItems.push({
                name: "Edit Officer",
                url: `/officer/${childMatchedPath?.params.officerId}/edit`,
                icon: faTools
            });
        }

        if (appointment) {
            submenuItems.push(
                {
                    name: "Back to Organisation",
                    url: "/organisation/" + appointment + "/people",
                    icon: faUndo
                }
            )
        }
        setSubmenuItems(submenuItems);
    };

    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        const fetchOfficer = async () => {
            setLoading(true);

            await axios.get(organisationHostname + "officer/" + childMatchedPath?.params.officerId)
                .then(r => successHandler(r.data))
                .finally(() => setLoading(false));
        };

        fetchOfficer();

        return () => {
            setSubmenuTitle("");
            setSubmenuItems([]);
        };
    }, []);

    let scroller = useRef<any>();
    const [page, setPageState] = useState<number>(0);
    const setPage = (p: number) => {
        setPageState(p);
    };

    const handleScroll = () => {
        if (!loading) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            let currentScroll = scroller.current.scrollTop;

            if (currentScroll === maxScroll) {
                setPage(page + 1);
            }
        }
    };

    return (
        <ScrollPaginationContext.Provider value={{page, setPage}}>
            <div style={{maxHeight: "100%", overflowY: "scroll", overflowX: "hidden"}} ref={scroller} onScroll={handleScroll}>
                <OfficerContext.Provider value={{officer, setOfficer, appointments, setAppointments}}>
                    {loading ? <IqLoadingIcon /> : (
                        <div>
                            <OfficerHeader />
                            <Switch>
                                <Route exact path={`${match.url}/:officerId`} component={Officer}/>
                                <Route path={`${match.url}/:officerId/news`} component={OfficerNews}/>
                                <Route path={`${match.url}/:officerId/social`} component={OfficerSocial}/>
                                <Route path={`${match.url}/:officerId/edit`} component={OfficerEdit}/>
                            </Switch>
                        </div>
                    )}
                </OfficerContext.Provider>
            </div>
        </ScrollPaginationContext.Provider>
    )
};

export default OfficerContainer;
