import React from "react";
import {Service, ServiceInstance} from "./ServiceStatusContainer";
import "./ServiceStatusContainer.css";
import {Accordion, Alert, Table} from "react-bootstrap";

interface Props {
    service: Service,
    serviceInstances: ServiceInstance[]
}

const ServiceStatus: React.FC<Props> = (props: Props) => {
    const { service, serviceInstances } = props;

    const getServiceStatus = (instanceCount: number, expected: number) => {
        if (instanceCount === 0) {
            return "red";
        }

        if (instanceCount < expected) {
            return "orange"
        }

        return "green";
    }

    const servicePercentage = (serviceInstances.length / service.expectedInstances) * 100 || 0;
    return (
        <div className="status-card">
            <div className="header">
                <span className="title">{service.name}</span>
                <span className={`status-icon ${getServiceStatus(serviceInstances.length, service.expectedInstances)}`}></span>
            </div>
            <div className="service-indicator mt-2">
                <div>
                    Status:
                </div>
                <div className="progress-container mt-1" style={{height: "23px"}}>
                    <div className="progress-bar"
                         style={{width: `${servicePercentage}%`, backgroundColor: "#0E67DD"}}>
                        <span>{serviceInstances.length} / {service.expectedInstances}</span>
                    </div>
                </div>
            </div>
            <Accordion className="mt-2">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        View Instance Info
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="p-1">
                            <div className="instance-info mt-2">
                                {serviceInstances.length === 0 ? (
                                    <Alert>
                                        No instances found for service
                                    </Alert>
                                ) : (
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Instance ID</th>
                                                <th>Status</th>
                                                <th>Last Updated Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {serviceInstances.map(instance => (
                                            <tr>
                                                <td>{instance.instanceId}</td>
                                                <td>{instance.instanceInfo.status}</td>
                                                <td>{new Date(instance.instanceInfo.lastUpdatedTimestamp).toLocaleString()}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
};

export default ServiceStatus;