import React from "react";
import "./IqProgressBar.css";

interface Props {
    now: number;
    label?: string;
}

const IqProgressBar: React.FC<Props> = (props: Props) => {
    const getWidth = () => {
        return Math.min(props.now, 100);
    };

    return (
        <div className="iq-progress-container">
            {props.now > 0 && (
                <div className="iq-progress-bar" style={{width: `${getWidth()}%`, minWidth: 40}}>
                    {props.label ? (
                        <span>{props.label}</span>
                    ) : (
                        <span>{props.now}%</span>
                    )}
                </div>
            )}
        </div>
    )
};

export default IqProgressBar;
