import {Hint, HorizontalGridLines, VerticalBarSeries, XAxis, XYPlot, YAxis} from "react-vis";
import React, {useState} from "react";
import {AutoSizer} from "react-virtualized";
import './Chart.css';

interface Props {
    data: any[],
    colour: string,
    title: string,
    formatValue: (value: string) => {},
    yAxisFormat: (value: any) => string
}

const OrganisationBarChart: React.FC<Props> = (props: Props) => {

    const [hover, setHover] = useState<any>();

    const yDomainMinBeforeScaling = props.data.reduce((min: number, p: { y: number; }) => p.y < min ? p.y : min, props.data[0].y);

    // Make sure it is higher than 0
    const yDomainMinimum = yDomainMinBeforeScaling - 0.02 * yDomainMinBeforeScaling >= 0 ? yDomainMinBeforeScaling - 0.02 * yDomainMinBeforeScaling : yDomainMinBeforeScaling;
    const yDomainMaximum = props.data.reduce((max: number, p: { y: number; }) => p.y > max ? p.y : max, props.data[0].y);

    const xMin = props.data.reduce((min: number, p: { x: number; }) => p.x < min ? p.x : min, props.data[0].x);
    const xMax = props.data.reduce((max: number, p: { x: number; }) => p.x > max ? p.x : max, props.data[0].x);

    const getData = () => {
        let data = props.data.filter((datum: any) => datum.x && datum.y);

        if (data.length > 1) {
            return data;
        }
        else {
            return [
                {
                    x: xMin - 1,
                    y: null
                },
                ...data
            ];
        }
    };

    return (
        <AutoSizer>
            {({ height, width }) => (
                <XYPlot width={width}
                        height={300}
                        margin={{left: 50}}
                        xDomain={[xMin, xMax]}
                        yDomain={[yDomainMinimum, yDomainMaximum]}>

                    <HorizontalGridLines />
                    <VerticalBarSeries color={props.colour}
                                       barWidth={0.7}
                                       data={getData()}
                                       onValueMouseOver={(v: any) => {
                                           setHover(v);
                                       }}
                                       onValueMouseOut={(v: any) => {
                                           setHover(null);
                                       }} />

                    <XAxis tickFormat={val => Math.round(val) === val ? val : ""} title="Year" />
                    <YAxis tickFormat={v => props.yAxisFormat(v)} title={props.title} />

                    {hover && (
                        <Hint value={hover}>
                            <div className="chart-hint fade-in">
                                <div className="x">{hover.x}</div>
                                <div className="y">{props.formatValue(hover.y) as string}</div>
                            </div>
                        </Hint>
                    )}
                </XYPlot>
            )}
        </AutoSizer>
    );
};

export default OrganisationBarChart;
