import React, {useState} from "react";
import {Button, Card, Image} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLinkedin, faMedium, faTwitter} from "@fortawesome/free-brands-svg-icons";
import OfficerAppointment from "../../../model/organisation/officer/OfficerAppointment";
import {getOfficerName} from "../../../model/organisation/officer/OrganisationOfficer";
import {faCalendarCheck, faCalendarTimes} from "@fortawesome/free-solid-svg-icons";
import "./ActiveOfficer.css";
import DateFormatter from "../../../utils/formatters/DateFormatter";

interface Props {
    appointment: OfficerAppointment
}

const ActiveOfficer: React.FC<Props> = (props: Props) => {

    const [appointment, setAppointment] = useState<OfficerAppointment>(props.appointment);
    const [displayText, setDisplayText] = useState<string>("");


    const getResignationDate = () => {
        return DateFormatter.formatDate(appointment.resignationDate);
    };

    const ProfileImage: React.FC = () => {
        const [src, setSrc] = useState<string>(appointment.officer.profileImageURL ? appointment.officer.profileImageURL :  "/icons_profil.svg");
        return (
            <Image src={src}
                   onError={() => setSrc("/icons_profil.svg")}
                   style={{
                       height: "140px",
                       width: "140px",
                       objectFit: "cover",
                       marginRight: "auto",
                       marginLeft: "auto",
                       marginBottom: 15,
                       border: "2px solid #5FD1F9"
                   }}
                   roundedCircle/>
        );
    };

    return (
        <Card className="officer-card pb-3" style={{ margin: "5px", marginBottom: "4em" }}>
            <div className="text-center pt-3">
                <div className="pt-1">
                    <a className="officer-title" href={`/officer/${appointment.officer.id}?appointment=${appointment.organisation.id}`}>
                        {getOfficerName(appointment.officer)}
                    </a>
                </div>
                <hr />
                <ProfileImage />
                <div className="pb-3">
                    <span className="officer-occupation">{appointment.role}</span>
                </div>
            </div>
            <Card.Footer className="officer-footer">
                <div className="pull-left">
                    {appointment.appointmentDate && (
                        <Button
                            onMouseOver={() => setDisplayText(`Appointed on ${DateFormatter.formatDate(appointment.appointmentDate)}`)}
                            onMouseOut={() => setDisplayText("")}
                            variant="link"
                            size="sm"
                            className="officer-footer-link">
                            <FontAwesomeIcon icon={faCalendarCheck} size="lg" />
                        </Button>
                    )}

                    {appointment.resignationDate && (
                        <Button
                            onMouseOver={() => setDisplayText(`Resigned on ${getResignationDate()}`)}
                            onMouseOut={() => setDisplayText("")}
                            variant="link"
                            size="sm"
                            className="officer-footer-link">
                            <FontAwesomeIcon icon={faCalendarTimes} size="lg" />
                        </Button>
                    )}
                </div>
                <div className="pull-right">
                    {displayText === '' ?
                        <>
                            {appointment.officer.twitter && (
                                <Button className="officer-footer-link" variant="link" size="sm" href={`https://www.twitter.com/${appointment.officer.twitter}`}>
                                    <FontAwesomeIcon icon={faTwitter} size="lg" />
                                </Button>
                            )}
                            {appointment.officer.medium && (
                                <Button className="officer-footer-link" variant="link" size="sm" href={appointment.officer.medium}>
                                    <FontAwesomeIcon icon={faMedium} size="lg" />
                                </Button>
                            )}
                            {appointment.officer.linkedIn && (
                                <Button className="officer-footer-link" variant="link" size="sm" href={appointment.officer.linkedIn}>
                                    <FontAwesomeIcon icon={faLinkedin} size="lg" />
                                </Button>
                            )}
                        </>
                        :
                        <>
                            {displayText}

                        </>
                    }
                </div>
            </Card.Footer>
        </Card>
    );
};

export default ActiveOfficer;
