import {faChevronCircleDown, faChevronCircleUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {Container, Image, Spinner, Table} from 'react-bootstrap';
import {Route, Switch, useHistory, useParams, useRouteMatch} from 'react-router-dom';
import ManagedCampaign from '../../model/campaigns/ManagedCampaign';
import List from '../../model/list/List';
import Organisation from '../../model/organisation/Organisation';
import {frontEndHostName, socialHostName} from '../../utils/Configuration';
import Pagination from '../../utils/PaginationHook';
import IqLoadingIcon from '../common/IqLoadingIcon';
import CampaignView from './CampaignView';
import './ManagedCampaignView.css';

const ManagedCampaignView: React.FC = () => {

    let { campaignId } = useParams<any>();

    let match = useRouteMatch();
    let history = useHistory();

    const [managedCampaign, setManagedCampaign] = useState<ManagedCampaign>();
    const [managedCampaignList, setManagedCampaignList] = useState<List>();
    const [viewListOrgs, setViewListOrgs] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const fetchManagedCampaign = async () => {
        setIsLoading(true);
        await axios.get(socialHostName + "managedcampaign/" + campaignId)
            .then(r => {
                setManagedCampaign(r.data as ManagedCampaign);
            })
            .catch(error => console.error(error.message))
            .finally(() => setIsLoading(false));
    };

    const fetchCampaignList = async () => {
        setIsLoading(true);
        await axios.get(frontEndHostName + "user-lists/" + managedCampaign?.list + "/campaign/" + campaignId)
            .then(r => {
                setManagedCampaignList(r.data as List);
            })
            .catch(error => console.error(error.message))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        fetchManagedCampaign();
    }, []);

    useEffect(() => {
        if (managedCampaign?.list) {
            fetchCampaignList();
        }
    }, [managedCampaign]);

    interface CompanyLogo {
        imageUrl: string | undefined,
        companyName: string | undefined
    }
    const CompanyLogo: React.FC<CompanyLogo> = (props: CompanyLogo) => {
        const [src, setSrc] = useState<string>(props.imageUrl ? props.imageUrl : "./icons_company.svg");

        return (
            <Image title={props.companyName}
                style={{
                    height: "50px",
                    width: "50px",
                    objectFit: "cover",
                    border: "2px solid #5FD1F9"
                }}
                src={src}
                onError={() => setSrc("/icons_company.svg")}
                roundedCircle />
        )
    };

    interface UserLogo {
        imageUrl: string | undefined,
        name: string | undefined
    }
    const UserLogo: React.FC<UserLogo> = (props: UserLogo) => {
        const [src, setSrc] = useState<string>(props.imageUrl ? props.imageUrl : "./icons_company.svg");

        return (
            <Image title={props.name}
                style={{
                    height: "25px",
                    width: "25px",
                    objectFit: "cover",
                    border: "2px solid #5FD1F9"
                }}
                src={src}
                onError={() => setSrc("/icons_company.svg")}
                roundedCircle />
        )
    };


    const OrganisationsTable: React.FC = () => {
        const [currentPage, setCurrentPage] = useState(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [loadingOrganisations, setLoadingOrganisations] = useState<boolean>(false);
        const [totalRecords, setTotalRecords] = useState<number>();
        const [organisations, setOrganisations] = useState<Organisation[]>([]);

        useEffect(() => {
            const fetchOrganisations = async () => {

                setLoadingOrganisations(true);

                let params: any = {
                    page: currentPage - 1,
                    size: pageSize
                };


                await axios.post(frontEndHostName + "user-lists/" + managedCampaignList?.id + "/campaign/" + campaignId + "/organisations",
                    {
                        params: params
                    })
                    .then(r => {
                        setOrganisations(r.data.content);
                        setTotalRecords(r.data.totalElements);
                    })
                    .catch(() => setError(true))
                    .finally(() => setLoadingOrganisations(false));
            };

            fetchOrganisations();

        }, [currentPage])

        return (
            <>
                {loadingOrganisations ? <IqLoadingIcon /> :
                    <Table style={{ backgroundColor: "white", width: "100%", marginLeft: "auto", marginRight: "auto" }} striped bordered hover>
                        <thead>
                            <tr>
                                <th>Organisation Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {organisations.map((entry, index) => (
                                <tr key={index}>
                                    <td className="pt-3">{entry.companyName}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>}
                {totalRecords && (
                    <Pagination totalRecords={totalRecords}
                        pageLimit={pageSize}
                        pageRangeDisplayed={1}
                        initPage={currentPage}
                        onChangePage={setCurrentPage}
                    />
                )}
            </>
        )
    };


    return (
        <Container fluid style={{ marginTop: 15 }}>
            {isLoading ? <div style={{ display: "flex", flexFlow: "center" }}>
                <Spinner style={{ marginLeft: "auto", marginRight: "auto" }} animation="border" variant="primary" />
            </div>
                :
                <Switch>
                    <Route exact path={`${match.url}/`}>
                        <div id="managed-campaign-view-container">

                            <span id="campaign-title">{managedCampaign?.campaignName}</span>
                            <span id="campaign-status">{managedCampaign?.status}</span>
                            {managedCampaign?.list &&
                                <>
                                    {managedCampaignList ?
                                        <>
                                            <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <span id="campaign-status">
                                                    {managedCampaignList?.title
                                                        .concat(" (ID ")
                                                        .concat(managedCampaignList?.id.toString())
                                                        .concat(")")
                                                        .concat(" featuring ")
                                                        .concat(managedCampaignList?.organisations?.length.toString())
                                                        .concat(" organisations")}
                                                </span>
                                                <span style={{ marginLeft: 10, cursor: "pointer" }}>
                                                    <FontAwesomeIcon onClick={() => setViewListOrgs(!viewListOrgs)} icon={viewListOrgs ? faChevronCircleUp : faChevronCircleDown} color="grey" size="2x" />
                                                </span>
                                            </span>
                                            {viewListOrgs && <OrganisationsTable />}
                                        </>
                                        :
                                        <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <span id="campaign-status">
                                                List does not exist anymore.
                                            </span>
                                        </span>
                                    }
                                </>}

                            {managedCampaign?.personas && managedCampaign?.personas?.length > 0 &&
                                <>
                                    <span id="campaign-headline">Target personas</span>
                                    <div id="campaign-personas">
                                        {managedCampaign?.personas.map(persona =>
                                        (<span className="persona">
                                            {persona}
                                        </span>)
                                        )}
                                    </div>
                                </>
                            }

                            <span id="campaign-headline">Involved parties</span>
                            <div id="campaign-parties">
                                {managedCampaign?.creator &&
                                    <div className="party purple">
                                        <div className="type">Campaign Manager</div>
                                        <div className="identity">
                                            <CompanyLogo imageUrl={managedCampaign?.creator.imageUrl} companyName={[managedCampaign?.creator.firstName, managedCampaign?.creator.lastName].join(" ")} />
                                            <span className="name">{[managedCampaign?.creator.firstName, managedCampaign?.creator.lastName].join(" ")}</span>
                                        </div>
                                    </div>
                                }
                                {managedCampaign?.distributor &&
                                    <div className="party pink">
                                        <div className="type">Distributor</div>
                                        <div className="identity">
                                            <CompanyLogo imageUrl={managedCampaign?.distributor.imageUrl} companyName={managedCampaign?.distributor.companyName} />
                                            <span className="name">{managedCampaign?.distributor.companyName}</span>
                                        </div>
                                    </div>
                                }
                                {managedCampaign?.partner &&
                                    <div className="party green">
                                        <div className="type">Partner</div>
                                        <div className="identity">
                                            <CompanyLogo imageUrl={managedCampaign?.partner.imageUrl} companyName={managedCampaign?.partner.companyName} />
                                            <span className="name">{managedCampaign?.partner.companyName}</span>
                                        </div>
                                    </div>
                                }
                                {managedCampaign?.vendor &&
                                    <div className="party orange">
                                        <div className="type">Vendor</div>
                                        <div className="identity">
                                            <CompanyLogo imageUrl={managedCampaign?.vendor.imageUrl} companyName={managedCampaign?.vendor.companyName} />
                                            <span className="name">{managedCampaign?.vendor.companyName}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <span id="campaign-headline">Involved users</span>
                            <div id="campaign-users">
                                {managedCampaign?.distributorUsers.map(user =>
                                (<div className="user pink">
                                    <div className="identity">
                                        <UserLogo imageUrl={user.imageUrl} name={[user.firstName, user.lastName].join(" ")} />
                                        <span className="name">{[user.firstName, user.lastName].join(" ")}</span>
                                    </div>
                                </div>)
                                )}
                                {managedCampaign?.partnerUsers.map(user =>
                                (<div className="user green">
                                    <div className="identity">
                                        <UserLogo imageUrl={user.imageUrl} name={[user.firstName, user.lastName].join(" ")} />
                                        <span className="name">{[user.firstName, user.lastName].join(" ")}</span>
                                    </div>
                                </div>)
                                )}
                                {managedCampaign?.vendorUsers.map(user =>
                                (<div className="user orange">
                                    <div className="identity">
                                        <UserLogo imageUrl={user.imageUrl} name={[user.firstName, user.lastName].join(" ")} />
                                        <span className="name">{[user.firstName, user.lastName].join(" ")}</span>
                                    </div>
                                </div>)
                                )}
                            </div>
                            <span id="campaign-headline">Stages</span>
                            <div id="campaign-stages">
                                {managedCampaign?.linkedinUrns.map(urn => (
                                    <span className="stage end-stage" onClick={() => history.push(`${match.url}/${urn.urn}`)}>{urn.name}</span>
                                ))}
                                <span className="stage end-stage">{managedCampaign?.status}</span>

                            </div>
                        </div>
                    </Route>
                    <Route exact path={`/campaigns/:campaignId/:urn`}>
                        <CampaignView />
                    </Route>
                </Switch>
            }
        </Container>
    );
};

export default ManagedCampaignView;