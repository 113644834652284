import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {Button, NavItem} from 'react-bootstrap';
import {Link} from 'react-router-dom';

interface NavBarActionButtonProps {
    to: any,
    type: IconDefinition,
    onClick: (e: any) => void,
    children?: React.ReactNode
}
const NavBarActionButton: React.FC<NavBarActionButtonProps> = (props: NavBarActionButtonProps) => {
    return (
        <NavItem style={{ paddingLeft: "15px" }}>
            <Link to={props.to}>
                <Button type="button"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        width: 35,
                        height: 35,
                        borderRadius: 25,
                        borderColor: "#3378D1",
                        position: "relative"
                    }}
                    onClick={props.onClick}>
                    <FontAwesomeIcon icon={props.type} color="#3378D1" />
                    {props.children}
                </Button>
            </Link>
        </NavItem>
    )
};

export default NavBarActionButton;