import React, {useRef, useState} from "react";
import {Alert, Table} from "react-bootstrap";
import translate from "../../i18n/translate";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {CompanyLogo} from "../marketintelligence/MarketIntelligenceContainer";
import {MergerDetail} from "../../model/organisation/MergerDetail";
import DateFormatter from "../../utils/formatters/DateFormatter";
import AppointmentDetails from "../../model/organisation/AppointmentDetails";
import ArticleLink from "../marketintelligence/ArticleLink";
import {formatPosition, titleCase} from "../../utils/formatters/TextFormatter";
import {useHistory} from "react-router-dom";

interface Props{
    mergerDetails: MergerDetail[]
    appointmentDetails: AppointmentDetails[]
}
const OrganisationMergerDetails: React.FC<Props> = (props:Props) => {

    let history = useHistory();


    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const scroller = useRef<any>();


    const handleScroll = () => {
        if (!loading && !error) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            let currentScroll = scroller.current.scrollTop;

            if (currentScroll === maxScroll) {
                setPage(prev => prev + 1);
            }
        }
    };
    
    const getDescription = (item: MergerDetail) => {
      if (item.type === 'acquisition'){
          return <><a target="_blank" href={`/organisation/${item.orgOne.id}`}>{item.orgOne.companyName}</a>{" acquired "}<a target="_blank"  href={`/organisation/${item.orgTwo.id}`}>{item.orgTwo.companyName}</a></>
      }

      if (item.type === 'merger'){
          return <><a target="_blank" href={`/organisation/${item.orgOne.id}`}>{item.orgOne.companyName}</a>{" merged with  "}<a target="_blank"  href={`/organisation/${item.orgTwo.id}`}>{item.orgTwo.companyName}</a></>
      }

      return <><a target="_blank" href={`/organisation/${item.orgOne.id}`}>{item.orgOne.companyName}</a> {item.type}</>
    }

    const getAmount = (item: MergerDetail) => {
        if (item.amount){
            return item.amount
        }

    }


 return (
     <div>
         {error ? (
             <Alert variant="danger">
                 {translate("errors.loading")}
             </Alert>
         ) : (
             <div className="filing-history-widget-container" style={{overflowY: "scroll" }} ref={scroller} onScroll={handleScroll}>
               <Table>
                  <tbody>
                  {/*{props.mergerDetails.map((mergerDetail: MergerDetail) => (*/}
                  {/*    <tr key={mergerDetail.articleId}>*/}
                  {/*        <td>*/}
                  {/*            <span>{DateFormatter.formatDate(mergerDetail.identifiedOn.toString())}</span>*/}
                  {/*        </td>*/}
                  {/*        <td>*/}
                  {/*            <span className="pe-3">*/}
                  {/*              <CompanyLogo {...mergerDetail.orgTwo} />*/}
                  {/*            </span>*/}
                  {/*            <span className="iqx-link pt-2" onClick={() => history.push("/organisation/" + mergerDetail.orgTwo.id)}>*/}
                  {/*               <span  className="description">{getDescription(mergerDetail)}</span>*/}
                  {/*            </span>*/}
                  {/*        </td>*/}
                  {/*        <td>*/}
                  {/*            <span className="description">{getAmount(mergerDetail)}</span>*/}
                  {/*        </td>*/}
                  {/*        <td>&nbsp;</td>*/}
                  {/*        <td className="text-end">*/}
                  {/*            <ArticleLink articleId={mergerDetail.articleUuid}/>*/}
                  {/*        </td>*/}
                  {/*    </tr>*/}
                  {/*))}*/}

                  {props.appointmentDetails.map((appointment: AppointmentDetails) => (
                      <tr key={appointment.id + (Math.random() + 1).toString(36).substring(5)}>
                          <td>
                              {DateFormatter.formatDate(appointment.date)}
                          </td>
                          <td>
                              <span className="pe-3">
                                <CompanyLogo {...appointment.organisation} />
                              </span>
                              <span className="iqx-link pt-2" onClick={() => history.push("/organisation/" + appointment.organisation.id)}>
                                  {appointment.organisation.companyName.toUpperCase()}
                              </span>
                          </td>
                          <td>
                              <span className="pt-2">
                               {titleCase(appointment.name)}
                               </span>
                          </td>
                          <td>
                              Appointed as {formatPosition(appointment.position)}
                          </td>

                          <td className="text-end">
                              <ArticleLink articleId={appointment.articleUuid}/>
                          </td>
                      </tr>
                  ))}



                  </tbody>
               </Table>
                 <div>{loading && <IqLoadingIcon />}</div>
             </div>
         )}
     </div>
 )
}

export default OrganisationMergerDetails;