import React, {useState} from "react";
import {Row} from "react-bootstrap";
import ListFinancials from "./ListFinancials";
import ListClassification from "./ListClassification";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import translate from "../../../i18n/translate";

enum TabKeys {
    FINANCIAL = "financial",
    CLASSIFICATION = "classification",
    TWITTER = "twitter"
}

const ListDashboard: React.FC = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [key, setKey] = useState<string>(TabKeys.FINANCIAL);

    return (
        <div>
            {loading ? <IqLoadingIcon /> : (
                <>
                    <Row className="" style={{ marginLeft: 0, marginRight: 0, marginTop: 15 }}>
                        <div style={{ marginLeft: "auto", marginRight: "auto" }} className="iq-button-group">
                            <button className={key === TabKeys.FINANCIAL ? "selected-inverted" : ""}
                                onClick={(e) => { setKey(TabKeys.FINANCIAL) }}>
                                {translate("lists.financial")}
                            </button>
                            <button className={key === TabKeys.CLASSIFICATION ? "selected-inverted" : ""}
                                onClick={(e) => { setKey(TabKeys.CLASSIFICATION) }}>
                                {translate("lists.classification")}
                            </button>

                        </div>
                    </Row>
                    {key === TabKeys.FINANCIAL && <ListFinancials />}
                    {key === TabKeys.CLASSIFICATION && <ListClassification />}
                </>
            )}
        </div>
    )
};

export default ListDashboard;
