import React, { FormEvent, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Alert, Button, Form } from "react-bootstrap";

import './LoginForm.css';
import { useForm } from 'react-hook-form';
import { frontEndHostName } from '../../utils/Configuration';
import axios from 'axios';

interface ResetPasswordForUserProps {
    email: string;
}

const ResetPasswordFormForUser: React.FC<ResetPasswordForUserProps> = (props) => {

    const { register, watch, handleSubmit, errors, triggerValidation, getValues, setValue } = useForm();
    const [success, setSuccess] = useState<boolean | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [passwordValid, setPasswordValid] = useState<boolean>(false)

    const onSubmit = (values, event) => {
        event.preventDefault();
        triggerValidation().then(success => {
            if (success) {
                setLoading(true);
                axios.post(frontEndHostName + 'users/account/manual-reset-password?email=' + props.email, {newPassword: values['newPassword']}, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
                ).then((response) => {
                    if (response.status === 200) {
                        setSuccess(true);
                    }
                }).catch(error => {
                    console.log(error.message)
                    setSuccess(false);
                }).finally(() => {setLoading(false); event.target.reset();});
            }
        });

    }

    const handlePasswordChange = (e: any) => {
        let password = e.target.value
        if (password.length > 0 && password.length <= 8) {
            setPasswordValid(true)
        } else {
            setPasswordValid(false)
        }
    }

    return (
        <div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="formNewPassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control name="newPassword" type="password" placeholder="Enter new password"
                        ref={register({ required: true, minLength: 8, maxLength: 100 })}
                        isInvalid={errors.newPassword} onChange={handlePasswordChange}
                    />
                    {passwordValid && <span style={{ color: "red" }}>Password must be greater than 8 characters</span>}
                    <Form.Control.Feedback type="invalid">
                        {errors.newPassword && errors.newPassword.type === "required" && "Please fill in the new password."}
                        {errors.newPassword && errors.newPassword.type === "minLength" && "Minimum length of password is 8."}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formRepeatPassword">
                    <Form.Label>Repeat Password</Form.Label>
                    <Form.Control name="repeatPassword" type="password" placeholder="Repeat new password"
                        ref={register({ required: true, minLength: 8, maxLength: 100, validate: (value) => value === watch('newPassword') })}
                        isInvalid={errors.repeatPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.repeatPassword && errors.repeatPassword.type === "required" && "Please repeat the new password."}
                        {errors.repeatPassword && errors.repeatPassword.type === "minLength" && "Minimum length of password is 8."}
                        {errors.repeatPassword && errors.repeatPassword.type === "validate" && "Passwords do not match."}
                    </Form.Control.Feedback>

                </Form.Group>
                {success == false &&
                    <Alert variant="danger">
                        A problem occured, please try again.
                    </Alert>
                }
                {success == true &&
                    <Alert variant="success">
                        Password has been reset successfully.
                    </Alert>
                }
                <Button style={{ marginTop: 15 }} type="submit" variant="primary">Reset password</Button>
            </Form>
        </div >
    );
}

export default ResetPasswordFormForUser;
