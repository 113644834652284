import React, {FormEvent, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {Alert, Button, Form} from "react-bootstrap";

import './LoginForm.css';
import axios from 'axios';
import {socialHostName} from '../../utils/Configuration';
import {useForm} from 'react-hook-form';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';

const ResetPasswordForm: React.FC = () => {

    const { register, watch, handleSubmit, errors,  setValue } = useForm();
    const [success, setSuccess] = useState<boolean | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [passwordValid, setPasswordValid] = useState<boolean>(true)


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    const history = useHistory();

    const LoginLogo = () => {
        return (
            <div id="login-logo">
                <img src="/logo-large.png" alt="logo" />
            </div>
        );
    }

    const repeatPassword = "repeatPassword";
    type PasswordChange = "key" | "newPassword" | "repeatPassword";
    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        let key = e.target[0].value;
        let newPassword = e.target[1].value;

        let dataFormatted = {
            key: key,
            newPassword: newPassword
        };

        setLoading(true);
        axios.post(socialHostName + 'users/account/reset-password/finish', dataFormatted, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        ).then((response) => {
            if (response.status === 200) {
                setSuccess(true);
            }
        }).catch(error => {
            console.log(error.message)
            setSuccess(false);
        }).finally(() => setLoading(false));
    }

    const handlePasswordChange = (e: any) => {
        let password = e.target.value
        if (password.length > 0 && password.length <= 8){
            setPasswordValid(true)
        } else {
            setPasswordValid(false)
        }
    }

    return (
        <div id="login-container">
            <div id="login-form-container">
                <LoginLogo />
                <div id="login-form-header">
                    <div className="title">Reset your password</div>
                    <div className="description">Fill in the form below to reset your password</div>
                </div>
                <Form id="login-form" onSubmit={onSubmit}>
                    <Form.Group controlId="formCurrentPassword">
                        <Form.Label>Reset Key</Form.Label>
                        <Form.Control name="key" type="text"
                            placeholder="Input your current password"
                            ref={register({ required: true, minLength: 8 })}
                            value={query.get("key")?.toString()}
                            disabled={true}
                        />
                    </Form.Group>

                    <Form.Group controlId="formNewPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control name="newPassword" type="password" placeholder="Enter new password"
                            ref={register({ required: true, minLength: 8, maxLength: 100 })}
                            isInvalid={errors.newPassword} onChange={handlePasswordChange} minLength={8}
                            style={{boxShadow: passwordValid ? "": "0 0 0 0.15em green"}}
                        />
                        {passwordValid && <span style={{color: "red", fontSize:"80%"}}>Password must be greater than 8 characters</span>}

                        <Form.Control.Feedback type="invalid">
                            {errors.newPassword && errors.newPassword.type === "required" && "Please fill in the new password."}
                            {errors.newPassword && errors.newPassword.type === "minLength" && "Minimum length of password is 8."}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formRepeatPassword">
                        <Form.Label>Repeat Password</Form.Label>
                        <Form.Control name="repeatPassword" type="password" placeholder="Repeat new password"
                            ref={register({ required: true, minLength: 8, maxLength: 100, validate: (value) => value === watch('newPassword') })}
                            isInvalid={errors.repeatPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.repeatPassword && errors.repeatPassword.type === "required" && "Please repeat the new password."}
                            {errors.repeatPassword && errors.repeatPassword.type === "minLength" && "Minimum length of password is 8."}
                            {errors.repeatPassword && errors.repeatPassword.type === "validate" && "Passwords do not match."}
                        </Form.Control.Feedback>

                    </Form.Group>
                    {success == false &&
                        <Alert variant="danger">
                            A problem occured, please try again.
                        </Alert>
                    }
                    {success == true &&
                        <Alert variant="success">
                            Password has been reset successfully.
                        </Alert>
                    }
                    <span style={{ marginTop: 25 }} onClick={() => history.push("/login")} className="forgot-password">Go back to login<FontAwesomeIcon style={{ marginLeft: 5 }} icon={faChevronRight} color="#3177c8" size="1x" /></span>
                    <Button style={{ marginTop: 15 }} type="submit" variant="primary">Reset password</Button>
                </Form>
            </div>
        </div >
    );
}

export default ResetPasswordForm;
