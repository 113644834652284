import React, { useContext } from 'react';
import { formatLocale, FormatLocaleDefinition } from 'd3-format';
import translate from "../../../i18n/translate";
import UserOrganisationContext from '../../../context/organisation/OrganisationContext';

interface Props {
    name: string,
    value: string,
    diff: string,
    symbol: string
}
const FinancialStat: React.FC<Props> = (props: Props) => {

    const { organisation } = useContext(UserOrganisationContext);

    let UK = formatLocale({
        currency: ["£", ""]
    } as FormatLocaleDefinition);

    let EU = formatLocale({
        currency: ["€", ""]
    } as FormatLocaleDefinition);

    let US = formatLocale({
        currency: ["$", ""]
    } as FormatLocaleDefinition);

    return (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
            <div className="organisation-card">
                <div className="organisation-card-label">
                    {translate(props.name)}
                </div>
                <h3 className={"iq-h3 iq-tx-normal iq-tx-rubik iq-mg-r-5 iq-mg-b-0 iq-lh-1" + (Number.isNaN(+props.value) && " iq-h4")}>
                    {props.symbol === "currency" && (Number.isNaN(+props.value) ? translate("organisation.financials.notavailable") : organisation.countryCode === "UK" ? UK.format('$,.3s')(+props.value) : organisation.countryCode === "US" ? US.format('$,.3s')(+props.value) : EU.format('$,.3s')(+props.value)).replace(/G/, "B")}
                    {props.symbol === "%" && (Number.isNaN(+props.value) ? translate("organisation.financials.notavailable") : (+props.value) + " " + props.symbol)}
                    {props.symbol === "" && (Number.isNaN(+props.value) ? translate("organisation.financials.notavailable") : (+props.value))}

                    {!Number.isNaN(+props.diff) && +props.diff !== 0 ? +props.diff >= 0 ? <span className="iq-tx-12 iq-mg-b-0 iq-tx-medium growth-text growth-positive">+{(+props.diff).toFixed(2)}%</span>
                        : <span className="iq-tx-12 iq-mg-b-0 iq-tx-medium growth-text growth-negative">{(+props.diff).toFixed(2)}%</span> : ""}
                </h3>
            </div>
        </div>
    )
}

export default FinancialStat;
