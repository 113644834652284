import React from "react";
import Campaign from "../../model/campaigns/Campaign";

const CampaignContext = React.createContext({
    campaigns: {} as Campaign[],
    setCampaigns: (campaigns: Campaign[]) => {},
    isLoading: false,
    setIsLoading: (isLoading: boolean) => {},
});

export default CampaignContext;
