import React, {useEffect, useState} from "react";
import {Row, Table} from "react-bootstrap";
import {TeamUser} from "../Teams";
import DateFormatter from "../../../utils/formatters/DateFormatter";
import axios from "axios";
import {matchPath, useLocation} from "react-router-dom";
import {frontEndHostName} from "../../../utils/Configuration";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import translate from "../../../i18n/translate";
import UserAvatar from "../../common/UserAvatar";

interface LoginResponse {
    user: TeamUser;
    lastLoginDate: string;
    lastLoginTime: string;
    totalLogins: number;
}

const TeamLogins: React.FC = () => {
    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/team/:teamId',
        exact: false,
        strict: false
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [logins, setLogins] = useState<LoginResponse[]>([]);

    useEffect(() => {
        const fetchLoginData = async () => {
            setLoading(true);
            await axios.get(frontEndHostName + "teams/" + childMatchedPath?.params.teamId + "/dashboard/logins")
                .then(r => setLogins(r.data))
                .catch(error => console.error(error.message))
                .finally(() => setLoading(false));
        };

        fetchLoginData();
    }, []);

    return (
        <div style={{ paddingTop: 35 }}>
            {loading ? <IqLoadingIcon /> : (
                <Row>
                    <div className="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7">
                        <div className="organisation-card">
                            <div className="organisation-card-label">
                                {translate("teams.logins.loginactivity")}
                            </div>
                            <div className="organisation-card-text">
                                <Table striped hover>
                                    <thead>
                                        <tr>
                                            <th>{translate("teams.common.username")}</th>
                                            <th style={{ textAlign: "center" }}>{translate("teams.logins.lastloggedin")}</th>
                                            <th style={{ textAlign: "center" }}>{translate("teams.logins.time")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logins.map(login => (
                                            <tr>
                                                <td>
                                                    <UserAvatar user={login.user} height="40px" width="40px" marginRight={15} />
                                                    {login.user.firstName} {login.user.lastName}
                                                </td>
                                                <td style={{ textAlign: "center" }}>{DateFormatter.formatDate(login.lastLoginDate)}</td>
                                                <td style={{ textAlign: "center" }}>{login.lastLoginTime}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                        <div className="organisation-card">
                            <div className="organisation-card-label">
                                {translate("teams.logins.totallogins")}
                            </div>
                            <div className="organisation-card-text">
                                <Table striped hover>
                                    <thead>
                                        <tr>
                                            <th>{translate("teams.common.username")}</th>
                                            <th style={{ textAlign: "right" }}>{translate("teams.logins.totallogins")}</th>
                                            <th style={{ textAlign: "center" }}>{translate("teams.logins.usercreatedon")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logins.map(login => (
                                            <tr>
                                                <td>
                                                    <UserAvatar user={login.user} height="40px" width="40px" marginRight={15} />
                                                    {login.user.firstName} {login.user.lastName}
                                                </td>
                                                <td style={{ textAlign: "right" }}>{login.totalLogins}</td>
                                                <td style={{ textAlign: "center" }}>{DateFormatter.formatDate(login.user.createdDate)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </Row>
            )}
        </div>
    )
};

export default TeamLogins;
