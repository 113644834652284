import React, { useEffect, useRef, useState } from 'react';
import { Client, Message, SubscribeHeaders } from 'webstomp-client';
import { StompSessionProviderProps } from '../model/providers/StompSessionProviderProps';
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import { StompSessionSubscription } from '../model/providers/StompSessionSubscription';
import StompContext from '../context/StompContext';

function StompSessionProvider(props: StompSessionProviderProps) {

    let { url, accessToken, fingerprint, logged, children } = props;
    const [client, setClient] = useState<Client | undefined>(undefined);
    const subscriptionRequests = useRef(new Map());

    useEffect(() => {
        if (accessToken != null && fingerprint != null && logged === true) {
            let stompClient = Stomp.over(new SockJS(url));
            stompClient.connect({
                Authorization: ["Bearer", accessToken].join(" "),
                fingerprint: fingerprint
            }, function (frame) {
                console.log('Connected: ' + frame);
                subscriptionRequests.current.forEach((value) => {
                    value.subscription = stompClient.subscribe(
                        value.destination,
                        value.callback,
                        value.headers
                    );
                    // stompClient.subscribe("/user/topic/notifications", (message) => {
                    //     console.log(message.body);
                    // });
                });
            });


            setClient(stompClient);
            return () => {
                stompClient.disconnect();
            }
        }
    }, [logged])

    const subscribe = (
        destination: string,
        callback: ((message: Message) => any) | undefined,
        headers: SubscribeHeaders = {}
    ) => {
        const subscriptionId = (Math.random() + 1).toString(36).substring(2);
        const subscriptionRequest: StompSessionSubscription = {
            destination,
            callback,
            headers
        };
        subscriptionRequests.current.set(subscriptionId, subscriptionRequest);
        if (client && client.connected) {
            subscriptionRequest.subscription = client.subscribe(
                destination,
                callback,
                headers
            );
        }

        return () => {
            const subscriptionData = subscriptionRequests.current.get(subscriptionId) as StompSessionSubscription;
            if (subscriptionData.subscription) {
                subscriptionData.subscription.unsubscribe();
            }
            subscriptionRequests.current.delete(subscriptionId);
        };
    }

    return (
        <StompContext.Provider value={{
            client,
            subscribe
        }}>
            {children}
        </StompContext.Provider>
    )
}

export default StompSessionProvider;