export default {
    jobs: {
        requestJobs: "Request Jobs"
    },
    featuredon: {
        title: "Aufgeführt in",
        nolists: "Diese Organisation wird in keiner Liste angezeigt",
        remove: "Entfernen",
        deleteconfirm: "Möchten Sie diese Organisation wirklich aus {list} entfernen?"
    },
    addtolist: {
        title: "Zur Liste hinzufügen",
        multipletitle: "Add Batch To List",
        subtitle: "Füge Organization zur Liste hinzu",
        multiplesubtitle: "Add Companies To List",
        chooselist: "Wähle eine Liste darunter",
        listplaceholder: "Wähle oder schreibe, um eine neue Liste zu erstellen",
        selectplaceholder: "Wähle eine Liste",
        listlabel: "{title} ({count} Unternehmen)",
        addsuccess: "Erfolgreich zur Liste hinzugefügt",
        duplicatename: "Eine Liste mit diesem Namen existiert bereits!",
        frozen: {
            campaign: "You cannot add organisations because this list is being used in a campaign."
        },
        maxcapacity: "Sie haben keine Kapazität um weitere Listen zu erstellen"
    },
    follow: "Folgen",
    followed: "Gefolgt",
    followcapacity: "Sie haben keine Kapazität, um mehr Organisationen zu folgen",
    updatecompanyinfo: {
        title: "Unternehmensinformationen aktualisieren",
        text: "Ein Update zum Unternehmen wird innerhalb von 1 Werktag abgeschlossen",
        popover: "Aktualisierung der Unternehmensinformationen anfragen"
    },
    temporarylink: {
        button: "Vorübergehenden Link",
        title: "Temporären Link generieren",
        email: "E-mail eingeben",
        share: "Teilen"
    },
    summary: {
        title: "Zusammenfassung",
        introduction: "Einleitung",
        socials: "Soziales",
        tags: "Stichworte",
        competencies: "Kompetenz",
        techpartners: "Technologiepartnerschaften",
        companydetails: "Unternehmensdetails",
        funding: {
            title: "Finanzierung",
            date: "Datum",
            round: "Runde",
            value: "Betrag",
            leadinvestor: "Hauptinvestor",
            acquired: "Acquired"
        },
        companyinfo: {
            title: "Firmeninfo",
            website: "Website",
            address: "Büro adresse",
            phone: "Telefonnummer",
            financialyearend: "Ende des Geschäftsjahres",
            legalform: "Rechtsform",
            investment: "Investition beschafft",
            digitalmaturity: "Digitale Reife",
            fiscalyearend: "Geschäftsjahresende",
            ultimateparentcompany: "Ultimative Muttergesellschaft",
            immediateparentcompany: "Unmittelbare Muttergesellschaft",
            subsidiarycompany: "Tochterunternehmen"
        },
        linkedinsearch: {
            poweredby: "Unterstützt von",
            seniorcontacts: "Finde leitende Ansprechtpartner",
            sales: "Finde Sales & Marketing Kontakte",
            infrastructure: "Find Kontakte zur technischen Infrastruktur",
            security: "Finde Anwendungs-, Daten-, DevOps- und Sicherheitskontakte"
        },
        classification: {
            sector: "Sektor",
            industrygroup: "Industriegruppe",
            industry: "Industrie",
            subindustry: "Unterindustrie",
            isic: "ISIC Codes",
            sic: "SIC Codes",
            ussic: "US SIC Codes",
            wz: "WZ Codes",
            naf: "NAF Codes",
            expand: "Ansicht erweitern",
            isichierarchy: "ISIC-Hierarchie"
        },
        headlinenumbers: {
            title: "Schlagzeilen-Nummern",
            revenues: "Umsatz",
            employees: "Mitarbeiter in dieser Organisation"
        },
        filinghistory: {
            name: "Archivierungsverlauf",
            title: "Titel",
            date: "Datum eingereicht"
        },
        map: {
            title: "Bürostandort",
            locations: "Alle Standorte anzeigen"
        }
    },
    financials: {
        title: "Finanziell",
        notavailable: "Nicht verfügbar",
        turnover: "Umsatz",
        turnoverchange: "1YR Growth",
        investmentraised: "Investment Raised",
        cagr: "3YR Growth",
        grossprofit: "Bruttogewinn",
        operatingprofit: "Betriebsgewinn",
        weeks: "Wochen",
        currency: "Währung",
        consolidatedaccounts: "Konsolidierter Abschluss",
        costofsales: "Umsatzkosten",
        wages: "Gehälter & Löhne",
        emoluments: "Direktor Einkünfte",
        depreciation: "Wertminderung ",
        auditfees: "Vergütung der Rechnungsprüfer",
        interestpayments: "Zinszahlungen",
        pretax: "Gewinn vor Steuer",
        tax: "Steuer",
        pat: "Gewinn nach Steuer",
        retainedprofit: "Einbehaltener Gewinn",
        export: "Export",
        cash: "Kapital",
        networth: "Nettowert",
        liquidity: "Liquiditäts-Säuretest",
        gearing: "Verschuldungsgrad",
        roce: {
            short: "RoCE",
            full: "Kapitalrendite %"
        },
        incomestatement: "Ertragsrechnung",
        balancesheet: "Rechnungsabschluss",
        ratios: "Verhältnis/Rate",
        profitandloss: "Gewinn-und Verlust",
        debtors: {
            trade: "Warenschuldner",
            other: "Andere Debitoren"
        },
        longtermfinance: {
            total: "Gesamte langfristige Finanzen",
            other: "Andere langfristige Finanzen"
        },
        shorttermfinance: "Andere kurzfristige Finanzen",
        assets: {
            assets: "Anlagen",
            tangible: "Sachanlagen",
            intangible: "Immaterielle Werte",
            current: "Gesamte kurzfrisige Sachanlagen",
            misc: "Verschiedenes Umlaufvermögen",
            fixed: "Gesamte feste Sachanlagen"
        },
        liabilities: {
            liabilities: "Verbindlichkeiten",
            current: "Gesamte kurzfrisige Verbindlichkeiten",
            misc: "Verschiedene kurzfristige Verbindlichkeiten"
        },
        creditors: "Lieferantenschulden",

        pretaxmargin: "Gewinnmarge vor Steuern",
        currentratio: "Verhältnis von Umlaufvermögen zu kurzfristigen Verbindlichkeiten",
        salesnetworkingcapital: "Vertriebsnetzwerkkapital",
        equity: "Eigenkapital (%)",
        creditordays: "Kreditorenziel in Tagen",
        rotae: "Anlagerendite (%)",
        currentdebt: "Momentane Schuldenquote",
        totaldebt: "Gesamte Schuldenquote",
        stockturnover: "Lagerumsatzquote (%)",
        ronae: "Nettorendite auf das Anlagevermögen (%)"
    },
    people: {
        title: "Personen",
        upgradeAccountMessage: "Bitte aktualisieren Sie Ihr Konto auf ein kostenpflichtiges, um Kontakte zu sehen."
    },
    techstack: {
        title: "Technology-Stack",
        datefirstverified: "Datum der ersten Verifizierung",
        datelastverified: "Datum der letzten Verifizierung",
        vendor: "Anbieter",
        product: "Produkt",
        category: "Kategorie",
        intensity: "Intensität"
    },
    news: {
        title: "Nachrichten"
    },
    social: {
        title: "Sozial",
        twitter: {
            nouser: "X Nutzerdaten sind für diese Organisation nicht verfügbar",
            followon: "Auf X folgen",
            top5mentions: "Top 5 Erwähnungen",
            top5hashtags: "Top 5 Hashtags"
        },
        youtube: {
            nouser: "YouTube Nutzerdaten sind für diese Organisation nicht verfügbar"
        }
    },
    shareholders: {
        title: "Aktionäre"
    },
    channelpartners: {
        title: "Channel-Partner"
    },
    edit: {
        title: "Bearbeiten"
    },
    previousnames: {
        title: "Vorherige Namen",
        name: "Name",
        effectivefrom: "Gültig ab",
        ceased: "Eingestellt"
    },
    casestudies: {
        title: "Case Studies"
    },
    whitepaper: {
        title: "Whitepaper"
    }
}