import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import OrganisationContext from "../../../../context/organisation/OrganisationContext";
import {contractsHostName} from "../../../../utils/Configuration";
import "./ContractsDashboard.css";
import IqLoadingIcon from "../../../common/IqLoadingIcon";
import {format} from "d3-format";
import ContractValuePerYear from "./ContractValuePerYear";
import PieChartData from "../../../../model/list/PieChartData";
import DashboardPieChart from "../../../lists/dashboard/common/DashboardPieChart";
import {Table} from "react-bootstrap";
import IqXSmallLoadingIcon from "../../../common/IqXSmallLoadingIcon";

interface ContractDashboardAggregation {
    name: string,
    value: number,
    description?: string
}

const ContractsDashboard: React.FC = () => {

    const {organisation} = useContext(OrganisationContext);

    const [loadingCpv, setLoadingCpv] = useState<boolean>(false);
    const [loadingBuyer, setLoadingBuyer] = useState<boolean>(false);
    const [loadingYear, setLoadingYear] = useState<boolean>(false);
    const [loadingTotalValue, setLoadingTotalValue] = useState<boolean>(false);
    const [totalValue, setTotalValue] = useState<number>();
    const [cpv, setCpv] = useState<ContractDashboardAggregation[]>([]);
    const [buyer, setBuyer] = useState<ContractDashboardAggregation[]>([]);
    const [year, setYear] = useState<ContractDashboardAggregation[]>([]);
    useEffect(() => {
        const fetchAggregateByCPV = async () => {
            const params = {
                companyNumber: organisation?.companyNumber,
                supplier: organisation?.companyName,
                businessType: organisation?.primaryType
            };

            setLoadingCpv(true);
            await axios.get(contractsHostName + "contract/dashboard/cpv", {params: params})
                .then(r => setCpv(r.data))
                .finally(() => setLoadingCpv(false));
        }

        fetchAggregateByCPV();
    }, []);

    useEffect(() => {
        const fetchAggregateByBuyer = async () => {
            const params = {
                companyNumber: organisation?.companyNumber,
                supplier: organisation?.companyName,
                businessType: organisation?.primaryType
            };

            setLoadingBuyer(true);
            await axios.get(contractsHostName + "contract/dashboard/buyer", {params: params})
                .then(r => setBuyer(r.data))
                .finally(() => setLoadingBuyer(false));
        }

        fetchAggregateByBuyer();
    }, []);

    useEffect(() => {
        const fetchAggregateByYear = async () => {
            const params = {
                companyNumber: organisation?.companyNumber,
                supplier: organisation?.companyName,
                businessType: organisation?.primaryType
            };

            setLoadingYear(true);
            await axios.get(contractsHostName + "contract/dashboard/year", {params: params})
                .then(r => setYear(r.data))
                .finally(() => setLoadingYear(false));
        }

        fetchAggregateByYear();
    }, []);

    useEffect(() => {
        const fetchTotalValue = async () => {
            const params = {
                companyNumber: organisation?.companyNumber,
                supplier: organisation?.companyName,
                businessType: organisation?.primaryType
            };

            setLoadingTotalValue(true);
            await axios.get(contractsHostName + "contract/dashboard/total", {params: params})
                .then(r => setTotalValue(r.data))
                .finally(() => setLoadingTotalValue(false));
        }

        fetchTotalValue();
    }, []);

    const getYearData = () => {
        return year.map(year => { return {x: year.name, y: year.value} });
    }

    const getCpvData = () => {
        return cpv.map(code => {
            const label = code.description ? `${code.description} (${code.name})` : code.name;
            return {
                label: label,
                value: code.value
            } as PieChartData
        });
    }

    return (
        <>
            <div className="row mb-3 mt-5 ms-3">
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div className="organisation-card-label me-2">Total Contract Value:</div>
                    <div>{loadingTotalValue ? <IqXSmallLoadingIcon/> : `£${format(",.0f")(totalValue || 0)}`}</div>
                </div>
            </div>
            <div className="contracts-dashboard-container">
                <div className="contracts-dashboard-item">
                    <div className="organisation-card">
                        <div className="organisation-card-label">Top 10 {organisation?.primaryType === 'PublicSector' ? "Suppliers" : "Buyers"} by Contract Value</div>
                        {loadingBuyer ? <IqLoadingIcon/> : (
                            <div>
                                <Table striped hover>
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Buyer</th>
                                            <th className="text-end">Value (GBP)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {buyer.map((buyer: ContractDashboardAggregation, index: number) => (
                                            <tr key={index}>
                                                <td className="text-end">{index + 1}</td>
                                                <td>{buyer.name.toUpperCase()}</td>
                                                <td className="text-end">£{format(",.0f")(buyer.value)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        )}
                    </div>
                </div>
                <div className="contracts-dashboard-item">
                    <div className="organisation-card">
                        <div className="organisation-card-label">Contract Value Per CPV Code</div>
                        {loadingCpv ? <IqLoadingIcon/> : (
                            <div style={{width: "100%", height: 320}}>
                                <DashboardPieChart title="Contract Value Per CPV Code"
                                                   chartId="cpvChart"
                                                   data={getCpvData()}
                                                   formatValue={(value: any) => {
                                                       return "£" + format('.2s')(value).replace(/G/, "B");
                                                   }}
                                                   height={320}/>
                            </div>
                        )}
                    </div>
                </div>
                <div className="contracts-dashboard-item">
                    <div className="organisation-card">
                        <div className="organisation-card-label">Contract Value By Year Awarded</div>
                        {loadingTotalValue ? <IqLoadingIcon/> : (
                            <div style={{width: "100%", height: 300}}>
                                {(year && year.length > 1) ?
                                    <ContractValuePerYear data={getYearData()}
                                                          colour="#52CAF9"
                                                          title="Total Contract Value"
                                                          formatValue={(value: any) => {
                                                              return "£" + format('.2s')(value);
                                                          }}
                                                          yAxisFormat={(value: any) => `£${format('.2s')(value)}`}/>
                                    : null}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContractsDashboard;