import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronRight,
    faFilter,
    faSort,
    faSortAmountDown,
    faSortAmountDownAlt
} from "@fortawesome/free-solid-svg-icons";
import {format} from "d3-format";
import {matchPath, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import Switch from "antd/lib/switch";
import IqLoadingIcon from "../common/IqLoadingIcon";
import DateFormatter from "../../utils/formatters/DateFormatter";
import Pagination from "../../utils/PaginationHook";
import {Col, Container, Form, Modal, OverlayTrigger, Popover, Row, Table} from "react-bootstrap";
import { socialHostName } from "../../utils/Configuration";
import axios from "axios";
import JobFilters from "./JobFilters";
import ActiveJobInfo from "../organisation/people/ActiveJobInfo";
import AdvertisedJob from "../../model/organisation/AdvertisedJob";
import translate from "../../i18n/translate";
import { CompanyLogo } from "../marketintelligence/MarketIntelligenceContainer";
import ListOrganisationJobModal from "../lists/jobs/ListOrganisationJobModal";
export interface JobsFilters {
    organisations: string[],
    searchTerm: string,
    locations: string[],
    createdDateStart: string,
    createdDateTo: string,
    hideExpired: boolean
}


export enum JobSort {
    ORGANISATION = "organisation.companyName.keyword",
    JOB_TITLE = "jobTitle.keyword",
    CREATED_DATE = "postDate",
    LOCATION = "location.keyword",
}

const UserJobHome: React.FC = () => {

    let history = useHistory();
    let match = useRouteMatch();

    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/lists/:listId/jobs',
        exact: false,
        strict: false
    });


    const [hideExpired, setHideExpired] = useState<boolean>(true);
    // useEffect(() => {
    //     const toggleHideExpiredJob = (switchStateValue: boolean) => {
    //         setLoadingJob(true);
    //        
    //        
    //         setJobFilters(jobFilters);
    //         setHideExpired(switchStateValue);
    //         setLoadingJob(false);
    //
    //     }
    //     toggleHideExpiredJob(hideExpired);
    // }, [hideExpired]);



    const [jobPage, setJobPage] = useState<number>(1);
    const [jobPagingSize, setJobPagingSize] = useState<number>(10);
    const [loadingJob, setLoadingJob] = useState<boolean>(false)
    const [jobFilters, setJobFilters] = useState<JobsFilters>({} as JobsFilters)
    const [jobSort, setJobSort] = useState<JobSort>(JobSort.CREATED_DATE)
    const [jobDesc, setJobDesc] = useState<boolean>(false)
    const [jobTotal, setJobTotal] = useState<number>(0)
    const [job, setJobs] = useState<AdvertisedJob[]>([])
    const [selectedJob, setSelectedJob] = useState<AdvertisedJob>()
    const [showInfo, setShowInfo] = useState<boolean>(false)


    useEffect(() => {
        const fetchJobs = async () => {
            setLoadingJob(true);
            let params: any = {
                page: jobPage - 1,
                size: jobPagingSize,
                sort: jobSort + "," + (jobDesc ? "DESC" : "ASC")
            }

            if(childMatchedPath?.params?.listId){
                params.listId = childMatchedPath?.params?.listId
            }

            jobFilters.hideExpired = hideExpired;

            await axios.post(socialHostName + "jobs/all", jobFilters, {params: params})
                .then(r => {
                    setJobs(r.data.content);
                    setJobTotal(r.data.totalElements);
                })
                .finally(() => setLoadingJob(false));
        }

        fetchJobs();
    }, [jobPage, jobPagingSize, jobSort, jobDesc, jobFilters, hideExpired]);

    useEffect(() => {
        setJobPage(1)
    }, [jobFilters]);

    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== jobPagingSize) {
            setJobPagingSize(size);
            setJobPage(1);
        }
    };

    const popover = (
        <Popover id="popover-basic">
            <div>
                Hides jobs which are more than 60 days old
            </div>
        </Popover>
    );

    const getSortButton = (s: JobSort) => {
        if (s === jobSort) {
            return (
                <a className="sort-button pull-right"
                   onClick={() => {
                       setJobDesc(!jobDesc);
                   }}>
                    <FontAwesomeIcon icon={jobDesc ? faSortAmountDown : faSortAmountDownAlt} />
                </a>
            );
        }

        return (
            <a className="sort-button pull-right"
               onClick={() => {
                   setJobSort(s);
                   setJobDesc(true);
               }}>
                <FontAwesomeIcon icon={faSort} />
            </a>
        );
    };
    const showClearFilters = () => {
        if (hideExpired) {
            if (!jobFilters.searchTerm && !jobFilters.organisations && !jobFilters.createdDateStart && !jobFilters.createdDateTo) {
                return true;
            }
        }
        return false;
    }

    const [showFilters, setShowFilters] = useState<boolean>(false);
    useEffect(() => {
        setShowFilters(false);
    }, [jobFilters]);

    return (
        <Container fluid style={{ marginTop: 15, marginRight: "auto", paddingLeft: 15 }}>
            {!childMatchedPath?.params?.listId && (

                <Row className="mb-3">
                    <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                        <div className="iq-headline">
                                <span className="text">
                                    Jobs
                                </span>
                        </div>
                    </Col>
                </Row>
            )}
            <div style={{
                paddingTop: "10px",
                backgroundColor: "white",
                borderRadius: "0.45rem",
                padding: 15,
                marginTop: 10,
                paddingBottom: 25
            }}>
                <div className="row row-reverse mb-3 pull-right me-2">
                    {(jobFilters.searchTerm || jobFilters.organisations?.length > 0) && (
                        showClearFilters()  ? null : (
                            <button className="iqx-button secondary md-size pull-right me-3"
                                    onClick={() => {setJobFilters({} as JobsFilters); setHideExpired(false)}}>
                                Clear Filters
                                <FontAwesomeIcon icon={faFilter} className="ms-2"/>
                            </button>
                        )
                    )}


                    <button className="iqx-button primary md-size pull-right"
                            onClick={() => setShowFilters(true)}>
                        Filters
                        <FontAwesomeIcon icon={faFilter} className="ms-2"/>
                    </button>
                </div>
                <div className="row row-reverse mb-3 pull-right me-2">
                    <div className="col me-4 mt-2" style={{textAlign: "end"}}>
                        <span className="me-2">Hide expired</span>
                        <OverlayTrigger overlay={popover}>
                            <Switch checked={hideExpired} onChange={(switchStateValue: boolean) => setHideExpired(switchStateValue)}/>
                        </OverlayTrigger>
                    </div>
                </div>

                {loadingJob ? <IqLoadingIcon/> : (
                    <>
                        <Table striped hover>
                            <thead>
                            <tr>
                                <th>Organisation {getSortButton(JobSort.ORGANISATION)}</th>
                                <th>Job Title {getSortButton(JobSort.JOB_TITLE)}</th>
                                <th>Location {getSortButton(JobSort.LOCATION)}</th>
                                <th>Created Date {getSortButton(JobSort.CREATED_DATE)}</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            {job.map(j => (
                                <tr key={j.id}>
                                    <td>
                                        <span className="me-2">
                                            <CompanyLogo {...j.organisation}/>
                                        </span>
                                        <span onClick={() => history.push(`/organisation/${j.organisation?.id}`)} className="iqx-link">
                                            {j.companyName.toUpperCase()}
                                        </span>
                                    </td>
                                    <td>{j.jobTitle}</td>
                                    <td>{j.location}</td>
                                    <td>{DateFormatter.formatMillis(j.postDate)}</td>
                                    <td className="text-end" style={{width: "11%"}}>
                                        <button className="iqx-button sm-size primary"
                                                onClick={() => {
                                                    setSelectedJob(j)
                                                    setShowInfo(true)
                                                }}>
                                            <span className="me-2">View</span>
                                            <FontAwesomeIcon icon={faChevronRight}/>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <div className="mt-4">
                            <div className="justify-content-md-end pagination mt-n2">
                                <span className="me-2">
                                    <Form.Control as="select"
                                                  style={{minWidth: "13%"}}
                                                  defaultValue={jobPagingSize}
                                                  onChange={(event) => changePageSize(event)}
                                    >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                    </Form.Control>
                                </span>
                                <span>
                                    {!!jobTotal && (
                                        <Pagination totalRecords={jobTotal}
                                                    pageLimit={jobPagingSize}
                                                    pageRangeDisplayed={1}
                                                    initPage={jobPage}
                                                    onChangePage={setJobPage}
                                        />
                                    )}
                                </span>
                            </div>
                        </div>
                    </>
                )}
                <Modal show={showFilters}
                       onHide={() => setShowFilters(false)}
                       size="lg">
                    <JobFilters jobFilters={jobFilters} setJobFilters={setJobFilters}></JobFilters>
                </Modal>
                <Modal show={showInfo} onHide={() => {setShowInfo(false); setSelectedJob(null)}} size="lg">
                    <ActiveJobInfo job={selectedJob}/>
                </Modal>
                <ListOrganisationJobModal />
            </div>
        </Container>
    );
};

export default UserJobHome;
