import {faStar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {Spinner} from "react-bootstrap";
import {frontEndHostName} from "../../../utils/Configuration";
import translate from "../../../i18n/translate";
import {ConsumptionData} from "../../teams/dashboard/TeamDashboardTable";
import {showGenericErrorDialog} from "../../common/CommonAlerts";


interface FollowButton {
    companyId: string,
    variant: string,
}
const FollowButton: React.FC<FollowButton> = (props: FollowButton) => {
    const [followed, setFollowed] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingPermission, setLoadingPermission] = useState<boolean>(false);
    const [hasFollowCapacity, setFollowCapacity] = useState<boolean>(false);

    useEffect(() => {
        const fetchFollowedCapacity = async () => {
            setLoadingPermission(true);
            await axios.get(frontEndHostName + "organisation/follow-consumption")
                .then(r => {
                    let data: ConsumptionData = r.data;
                    setFollowCapacity(data.consumed < data.capacity);
                })
                .finally(() => setLoadingPermission(false))
        };

        fetchFollowedCapacity();
    }, []);

    const followOrganisation = async () => {
        setLoading(true);
        await axios.put(frontEndHostName + "follow-organisation/" + props.companyId)
            .then(() => setFollowed(true))
            .catch(() => showGenericErrorDialog())
            .finally(() => setLoading(false));
    };

    const unfollowOrganisation = async () => {
        setLoading(true);
        await axios.delete(frontEndHostName + "unfollow-organisation/" + props.companyId)
            .then(() => setFollowed(false))
            .catch(() => showGenericErrorDialog())
            .finally(() => setLoading(false));
    };

    const FollowableButton: React.FC = () => {

        return (
            <button type="button" className={"iq-action-button m-1 " + props.variant}
                    onClick={() => followOrganisation()}
                    disabled={!hasFollowCapacity}
                    title={hasFollowCapacity ? translate("organisation.follow") : translate("organisation.followcapacity")}>
                <div className="organisation-button-text">
                <span className="text">{translate("organisation.follow")}</span>
                </div>
                {(!loading && !loadingPermission) ? (
                    <i className="fa fa-star-o fa-1x icon" style={{ color: "#2F6FC3" }} />
                ) : (
                    <Spinner className="icon" style={{ display: "block", height: "1rem", width: "1rem" }}
                        animation="border"
                        variant="primary" />
                )}
            </button>
        );
    };

    const UnfollowableButton: React.FC = () => {
        return (
            <button type="button" className={"iq-action-button m-1 " + props.variant} onClick={() => unfollowOrganisation()}>
                <div className="organisation-button-text">
                <span className="text">{translate("organisation.followed")}</span>
                </div>
                {!loading
                    ?
                    <FontAwesomeIcon className="icon" icon={faStar} color="#2F6FC3" size="1x" />
                    :
                    <Spinner className="icon" style={{ display: "block", height: "1rem", width: "1rem" }}
                        animation="border"
                        variant="primary" />
                }
            </button>
        );
    };

    useEffect(() => {
        const isFollowing = () => {
            setLoading(true);
            axios.get(frontEndHostName + "following/" + props.companyId)
                .then(r => {
                    setFollowed(r.data)
                    setLoading(false)
                })
                .catch(() => setFollowed(false));
        };

        isFollowing();

    }, []);


    return (
        <>
            {followed
                ?
                <UnfollowableButton />
                :

                <FollowableButton />
            }
        </>

    );
};

export default FollowButton;