import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Table} from 'react-bootstrap';
import Organisation from '../../model/organisation/Organisation';
import Pagination from '../../utils/PaginationHook';
import IqLoadingIcon from '../common/IqLoadingIcon';
import './AdminDataAnalytics.css';


enum Switch {
    LISTS = "lists",
    FOLLOWED = "followed"
}

interface OrganisationCard {
    organisations: Organisation[],
    switch: Switch,
    totalPages: number,
    loading: boolean
}

interface AdminDataAnalyticsCard {
    title: string,
    totalPages: number
    values: OrganisationCard
    setter: Dispatch<SetStateAction<OrganisationCard>>
    retriever: (page: number) => void,
    isServiceRunning: boolean,
    setIsServiceRunning: Dispatch<SetStateAction<boolean>>,
    updateButton?: any
}
const AdminDataAnalyticsCard: React.FC<AdminDataAnalyticsCard> = (props: AdminDataAnalyticsCard) => {

    const [totalPages, setTotalPages] = useState<number>(props.totalPages);
    const [currentPage, setCurrentPage] = useState(1);



    useEffect(() => {
        props.retriever(currentPage)
    }, [currentPage])

    useEffect(() => {
        setTotalPages(props.values.totalPages)
    }, [props.values.totalPages])

    useEffect(() => {
        setCurrentPage(1)
        props.retriever(1)
    }, [props.values.switch])


    return (
        <div className="card">
            <div className="header">
                <div className="title">
                    {props.title}
                </div>
                <div className="switcher">
                    <div style={{ marginLeft: "auto", marginRight: "auto" }} className="iq-button-group">

                        <button className={props.values.switch === Switch.LISTS ? "selected-inverted" : ""}
                            onClick={(e) => { props.setter({ ...props.values, switch: Switch.LISTS }) }}>
                            Lists
                        </button>

                        <button className={props.values.switch === Switch.FOLLOWED ? "selected-inverted" : ""}
                            onClick={(e) => { props.setter({ ...props.values, switch: Switch.FOLLOWED }) }}>
                            Followed
                        </button>
                    </div>
                </div>
            </div>
            <div className="content">
                {props.values.loading ? <IqLoadingIcon /> :
                    <>
                        <Table style={{ backgroundColor: "white", width: "auto", marginLeft: "auto", marginRight: "auto" }} striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Company Name</th>
                                    <th>ID</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.values.organisations.map((entry, index) => (
                                    <tr key={index}>
                                        <td className="pt-3"><a target="_blank" href={["/organisation/", entry.id].join("")}>{entry.companyName}</a></td>
                                        <td className="pt-3">{entry.id}</td>
                                        <td className="pt-3">{props.updateButton && (props.updateButton(entry.id) || "N/A") || "N/A"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="pull-right">
                            <Pagination
                                totalRecords={totalPages}
                                pageLimit={10}
                                initPage={currentPage}
                                pageRangeDisplayed={1}
                                onChangePage={setCurrentPage}
                            />
                        </div>
                    </>
                }

            </div>
        </div>
    )
}

export default AdminDataAnalyticsCard;
function setLoading(arg0: boolean) {
    throw new Error('Function not implemented.');
}

