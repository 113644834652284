import {
    faAward,
    faBoxOpen,
    faBriefcase,
    faBuilding,
    faChartLine,
    faCommentsDollar,
    faFlag,
    faFolder,
    faGavel,
    faGlobe,
    faMapMarker,
    faMoneyBill,
    faSignal,
    faTags,
    faUser,
    faUserGraduate
} from '@fortawesome/free-solid-svg-icons';
import React, {useContext, useEffect, useState} from 'react';
import AdvancedSearchContext from '../../context/advanced-search/AdvancedSearchContext';
import BetweenSelectField from './fields/BetweenSelectField';
import MultiSelectField from './fields/MultiSelectField';
import TreeSelectField from './fields/TreeSelectField';
import FilterBubble from './FilterBubble';
import './FilterBubbleContainer.css';
import {countries, getAccreditationFilterValues, getNutsFilterValues, locations} from './AdvancedSearchConfig';
import translate from "../../i18n/translate";
import I18nContext from '../../context/I18nContext';
import AdvancedSearchSelectedContext from "../../context/advanced-search/AdvancedSearchSelectedContext";
import {hasAnyRole, Role} from "../../utils/Security";

interface Props {
    searchType: string,
    setEditing: (filter: string) => void
    clearEdit: (filter: string) => void
}

export enum QuickFilters {
    Employees = "employee_quick_filter",
    Revenues = "revenue_quick_filter",
    InvestmentRaised = "investment_raised_filter",
    Vendors = "vendor_quick_filter",
    Products = "products_quick_filter",
    CompanyTypes = "company_types_quick_filter",
    CompanyLegalTypes = "company_legal_types_quick_filter",
    Location = "location_quick_filter",
    Country = "country_quick_filter",
    NUTS = "nuts_quick_filter",
    HeadOffice = "headOffice",
    OneYearCAGR = "one_year_cagr_quick_filter",
    ThreeYearCAGR = "three_year_cagr_quick_filter",
    Accreditations = "accreditations_quick_filter",
    Competencies = "competencies_quick_filter",
    Tags = "tags_quick_filter",
    ISIC = "isic_quick_filter",
    Status = "status_quick_filter",
    Established = "year_established_quick_filter"
}

const FilterBubbleContainer: React.FC<Props> = (props: Props) => {
    const { tree, setTree, filters, config } = useContext(AdvancedSearchContext);
    const { selectedNuts, selectedISIC} = useContext(AdvancedSearchSelectedContext);
    const [filterSettings, setFilterSettings] = useState({} as any);
    const { lang, setLang } = useContext(I18nContext);

    const getCurrentYear = () => {
        return new Date().getFullYear();
    }

    const getYears = () => {
        const now = getCurrentYear();
        const min = 1900;
        let range = [];
        for (let i = min; i <= now; i++) {
            range.push(i);
        }

        return range;
    }

    useEffect(() => {
        setFilterSettings({
            [QuickFilters.Employees]: {
                icon: faUser,
                title: "advancedsearch.employees",
                widget: (filterState: any, setFilterState: any) => {
                    return <BetweenSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selectedFrom={1}
                        selectedTo={1000000}
                        optionsFrom={[1, 10, 50, 100, 200, 500, 1000, 5000, 10000, 50000, 100000, 500000, 1000000]}
                        optionsTo={[1, 10, 50, 100, 200, 500, 1000, 5000, 10000, 50000, 100000, 500000, 1000000]}
                        filterId={QuickFilters.Employees}
                        graphField={"financialSummary.numberOfEmployees"}
                        unit={translate("advancedsearch.employees")}
                        type={"default"}
                    />
                }
            },
            [QuickFilters.Established]: {
                icon: faUser,
                title: "advancedsearch.established",
                widget: (filterState: any, setFilterState: any) => {
                    return <BetweenSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selectedFrom={getCurrentYear() - 10}
                        selectedTo={getCurrentYear()}
                        optionsFrom={getYears()}
                        optionsTo={getYears()}
                        filterId={QuickFilters.Established}
                        graphField={"yearEstablished"}
                        unit={""}
                        valueExtractor={
                            (v) => {
                                let date = new Date();
                                date.setHours(0, 0, 0, 1);
                                date.setMonth(0, 1);
                                date.setFullYear(v);
                                return date.getTime();
                            }
                        }
                        type={"year"}
                    />
                }
            },
            [QuickFilters.Revenues]: {
                icon: faMoneyBill,
                title: "organisation.financials.turnover",
                widget: (filterState: any, setFilterState: any) => {
                    return <BetweenSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selectedFrom={0}
                        selectedTo={100000000000}
                        optionsFrom={[0, 1000000, 5000000, 10000000, 100000000, 500000000, 1000000000, 10000000000, 50000000000, 100000000000]}
                        optionsTo={[0, 1000000, 5000000, 10000000, 100000000, 500000000, 1000000000, 10000000000, 50000000000, 100000000000]}
                        filterId={QuickFilters.Revenues}
                        graphField={"financialSummary.turnover"}
                        unit={""}
                        type={"currency"}
                    />
                }
            },
            [QuickFilters.InvestmentRaised]: {
                icon: faCommentsDollar,
                title: "advancedsearch.investmentraised",
                widget: (filterState: any, setFilterState: any) => {
                    return <BetweenSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selectedFrom={0}
                        selectedTo={5000000000}
                        optionsFrom={[0, 100000, 500000, 1000000, 10000000, 50000000, 100000000, 1000000000, 5000000000]}
                        optionsTo={[0, 100000, 500000, 1000000, 10000000, 50000000, 100000000, 1000000000, 5000000000]}
                        filterId={QuickFilters.InvestmentRaised}
                        graphField={"financialSummary.investmentRaised"}
                        unit={""}
                        type={"currency"}
                    />
                }
            },
            [QuickFilters.OneYearCAGR]: {
                icon: faChartLine,
                title: "organisation.financials.turnoverchange",
                widget: (filterState: any, setFilterState: any) => {
                    return <BetweenSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selectedFrom={0}
                        selectedTo={1000}
                        optionsFrom={[0, 1, 2, 5, 10, 20, 50, 100, 200, 500, 1000]}
                        optionsTo={[0, 1, 2, 5, 10, 20, 50, 100, 200, 500, 1000]}
                        filterId={QuickFilters.OneYearCAGR}
                        graphField={"financialSummary.turnoverChange"}
                        unit={"%"}
                        type={"default"}
                    />
                }
            },
            [QuickFilters.ThreeYearCAGR]: {
                icon: faChartLine,
                title: "organisation.financials.cagr",
                widget: (filterState: any, setFilterState: any) => {
                    return <BetweenSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selectedFrom={0}
                        selectedTo={1000}
                        optionsFrom={[0, 1, 2, 5, 10, 20, 50, 100, 200, 500, 1000]}
                        optionsTo={[0, 1, 2, 5, 10, 20, 50, 100, 200, 500, 1000]}
                        filterId={QuickFilters.ThreeYearCAGR}
                        graphField={"financialSummary.threeYearCagr"}
                        unit={"%"}
                        type={"default"}
                    />
                }
            },
            [QuickFilters.Vendors]: {
                icon: faBuilding,
                title: "advancedsearch.vendors",
                widget: (filterState: any, setFilterState: any) => {
                    return <MultiSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selected={[]}
                        options={filters.vendors}
                        filterId={QuickFilters.Vendors}
                        graphField={"hgData.vendor.keyword"}
                    />
                }
            },
            [QuickFilters.Products]: {
                icon: faBoxOpen,
                title: "advancedsearch.products",
                widget: (filterState: any, setFilterState: any) => {
                    return <MultiSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selected={[]}
                        options={filters.products}
                        filterId={QuickFilters.Products}
                        graphField={"hgData.product.keyword"}
                    />
                }
            },
            [QuickFilters.Accreditations]: {
                icon: faAward,
                title: "advancedsearch.accreditations",
                widget: (filterState: any, setFilterState: any) => {
                    return <TreeSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selected={[]}
                        options={getAccreditationFilterValues(filters)}
                        filterId={QuickFilters.Accreditations}
                        graphField={"accreditations.accreditation.concatenatedName.keyword"}
                    />
                }
            },
            [QuickFilters.Competencies]: {
                icon: faUserGraduate,
                title: "advancedsearch.competencies",
                widget: (filterState: any, setFilterState: any) => {
                    return <MultiSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selected={[]}
                        options={filters.competencies}
                        filterId={QuickFilters.Competencies}
                        graphField={"competencies.keyword"}
                    />
                }
            },
            [QuickFilters.Tags]: {
                icon: faTags,
                title: "advancedsearch.tags",
                widget: (filterState: any, setFilterState: any) => {
                    return <MultiSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selected={[]}
                        options={filters.tags}
                        filterId={QuickFilters.Tags}
                        graphField={"tags.name.keyword"}
                    />
                }
            },
            [QuickFilters.CompanyTypes]: {
                icon: faBriefcase,
                title: "lists.companytype",
                widget: (filterState: any, setFilterState: any) => {
                    return <MultiSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selected={[]}
                        options={filters.companyTypes?.filter(companyType => companyType != "EndUser")}
                        filterId={QuickFilters.CompanyTypes}
                        graphField={"primaryType.keyword"}
                    />
                }
            },
            [QuickFilters.CompanyLegalTypes]: {
                icon: faGavel,
                title: "lists.companylegaltype",
                widget: (filterState: any, setFilterState: any) => {
                    return <MultiSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selected={[]}
                        options={filters.companyLegalTypes}
                        filterId={QuickFilters.CompanyLegalTypes}
                        graphField={"companyType.keyword"}
                    />
                }
            },
            [QuickFilters.Country]: {
                icon: faFlag,
                title: "advancedsearch.country",
                widget: (filterState: any, setFilterState: any) => {
                    return <TreeSelectField
                        options={countries}
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selected={[]}
                        filterId={QuickFilters.Country}
                        graphField={"country.keyword"}
                    />
                }
            },
            [QuickFilters.NUTS]: {
                icon: faGlobe,
                title: "advancedsearch.location",
                widget: (filterState: any, setFilterState: any) => {
                    return <TreeSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selected={selectedNuts}
                        options={getNutsFilterValues(filters)}
                        filterId={QuickFilters.NUTS}
                        graphField={"nutsClassification.nuts*Code.keyword"}
                    />
                }
            },
            [QuickFilters.HeadOffice]: {
                icon: faMapMarker,
                title: "Head Office",
                widget: (filterState: any, setFilterState: any) => {
                    return <TreeSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selected={filters.headOffice}
                        options={locations}
                        filterId={QuickFilters.HeadOffice}
                        graphField={"headOffice"}
                    />
                }
            },
            [QuickFilters.ISIC]: {
                icon: faFolder,
                title: "ISIC Codes",
                widget: (filterState: any, setFilterState: any) => {
                    return <TreeSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selected={selectedISIC}
                        options={filters.isicCodes?.map(isicCode => {
                            let jsonIsic = JSON.parse(isicCode);
                            return Object.assign({ value: jsonIsic.code, title: jsonIsic.label[lang] }, jsonIsic.parent === null ? null : { parent: jsonIsic.parent })
                        })}
                        filterId={QuickFilters.ISIC}
                        graphField={"isicClassification.isicLevel*Code.keyword"}
                    />
                }
            },
            [QuickFilters.Status]: {
                icon: faSignal,
                title: "Status",
                widget: (filterState: any, setFilterState: any) => {
                    return <MultiSelectField
                        filterState={filterState}
                        setFilterState={setFilterState}
                        selected={["Active"]}
                        //options={filters.statuses.filter(s => s !== '')}
                        options={["Active", "Dissolved", "In Administration"]}
                        filterId={QuickFilters.Status}
                        graphField={"companyStatus.keyword"}
                    />
                }
            }
        })
    }, [filters, props.searchType])

    return (
        <div id="filter-container">
            {props.searchType === "endUser"
                ?
                <>
                    <FilterBubble key={[props.searchType, QuickFilters.Status].join("-")} filterId={QuickFilters.Status} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit} />
                    <FilterBubble key={[props.searchType, QuickFilters.Employees].join("-")} filterId={QuickFilters.Employees} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit} />
                    <FilterBubble key={[props.searchType, QuickFilters.Revenues].join("-")} filterId={QuickFilters.Revenues} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit} />
                    <FilterBubble key={[props.searchType, QuickFilters.InvestmentRaised].join("-")} filterId={QuickFilters.InvestmentRaised} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit} />
                    <FilterBubble key={[props.searchType, QuickFilters.Vendors].join("-")} filterId={QuickFilters.Vendors} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit} />
                    <FilterBubble key={[props.searchType, QuickFilters.Products].join("-")} filterId={QuickFilters.Products} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit} />
                    <FilterBubble key={[props.searchType, QuickFilters.Tags].join("-")} filterId={QuickFilters.Tags} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit} />
                    <FilterBubble key={[props.searchType, QuickFilters.ISIC].join("-")} filterId={QuickFilters.ISIC} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit} />
                    {hasAnyRole([Role.GEO_UK, Role.ADMIN]) && <FilterBubble key={[props.searchType, QuickFilters.HeadOffice].join("-")} filterId={QuickFilters.HeadOffice} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit} />}
                    <FilterBubble key={[props.searchType, QuickFilters.NUTS].join("-")} filterId={QuickFilters.NUTS} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit} />
                    <FilterBubble key={[props.searchType, QuickFilters.OneYearCAGR].join("-")} filterId={QuickFilters.OneYearCAGR} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit} />
                    <FilterBubble key={[props.searchType, QuickFilters.ThreeYearCAGR].join("-")} filterId={QuickFilters.ThreeYearCAGR} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit} />
                    <FilterBubble key={[props.searchType, QuickFilters.Established].join("-")} filterId={QuickFilters.Established} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit} />
                </>
                :
                <>
                    <FilterBubble key={[props.searchType, QuickFilters.Status].join("-")} filterId={QuickFilters.Status} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit}/>
                    <FilterBubble key={[props.searchType, QuickFilters.Employees].join("-")} filterId={QuickFilters.Employees} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit}/>
                    <FilterBubble key={[props.searchType, QuickFilters.Revenues].join("-")} filterId={QuickFilters.Revenues} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit}/>
                    <FilterBubble key={[props.searchType, QuickFilters.Tags].join("-")} filterId={QuickFilters.Tags} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit}/>
                    <FilterBubble key={[props.searchType, QuickFilters.InvestmentRaised].join("-")} filterId={QuickFilters.InvestmentRaised} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit}/>
                    <FilterBubble key={[props.searchType, QuickFilters.CompanyTypes].join("-")} filterId={QuickFilters.CompanyTypes} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit}/>
                    <FilterBubble key={[props.searchType, QuickFilters.CompanyLegalTypes].join("-")} filterId={QuickFilters.CompanyLegalTypes} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit}/>
                    <FilterBubble key={[props.searchType, QuickFilters.Vendors].join("-")} filterId={QuickFilters.Vendors} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit}/>
                    <FilterBubble key={[props.searchType, QuickFilters.Products].join("-")} filterId={QuickFilters.Products} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit}/>
                    {hasAnyRole([Role.GEO_UK, Role.ADMIN]) && <FilterBubble key={[props.searchType, QuickFilters.HeadOffice].join("-")} filterId={QuickFilters.HeadOffice} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit}/>}
                    <FilterBubble key={[props.searchType, QuickFilters.NUTS].join("-")} filterId={QuickFilters.NUTS} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit}/>
                    <FilterBubble key={[props.searchType, QuickFilters.OneYearCAGR].join("-")} filterId={QuickFilters.OneYearCAGR} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit}/>
                    <FilterBubble key={[props.searchType, QuickFilters.ThreeYearCAGR].join("-")} filterId={QuickFilters.ThreeYearCAGR} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit}/>
                    <FilterBubble key={[props.searchType, QuickFilters.Accreditations].join("-")} filterId={QuickFilters.Accreditations} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit}/>
                    <FilterBubble key={[props.searchType, QuickFilters.Competencies].join("-")} filterId={QuickFilters.Competencies} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit}/>
                    <FilterBubble key={[props.searchType, QuickFilters.Established].join("-")} filterId={QuickFilters.Established} filterSettings={filterSettings} setEditing={props.setEditing} clearEdit={props.clearEdit} />
                </>
            }
            {/* <FilterBubble filterId={QuickFilters.Accreditations}/>
            <FilterBubble filterId={QuickFilters.Location}/> */}
        </div>
    );
}

export default FilterBubbleContainer;

