import React, {useContext} from "react";
import OrganisationContext from "../../../context/organisation/OrganisationContext";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import Masonry from "react-masonry-css";
import WhitePaper from "../../../model/organisation/WhitePaper";
import WhitePaperCard from "./WhitePaperCard";
import {Col, Row} from "react-bootstrap";
import translate from "../../../i18n/translate";
import AwesomeDebouncePromise from "awesome-debounce-promise";

const OrganisationWhitePaper: React.FC = () => {
    const {
        whitePaperResponse,
        setWhitePaperSearchTerm,
        whitePaperState
    } = useContext(OrganisationContext);

    const debounced = AwesomeDebouncePromise(setWhitePaperSearchTerm, 300);
    const handleSearchQueryChange = async (e: any) => {
        e.persist();
        await debounced(e.target.value);
    };

    return (
        <div style={{
            paddingTop: 10,
            backgroundColor: "white",
            borderRadius: "0.45rem",
            padding: 15,
            marginTop: 10
        }}>
            <Row className="mb-3">
                <Col lg={{ span: 3, offset: 9 }} md={{ span: 3, offset: 9 }} sm={{ span: 4, offset: 8 }} xs={{ span: 6, offset: 6 }}>
                    <input className="form-control float-end"
                           type="text"
                           placeholder={translate("advancedsearch.search")}
                           onKeyUp={handleSearchQueryChange}
                    />
                </Col>
            </Row>

            {whitePaperState.loading ? <IqLoadingIcon /> : (
                <Masonry breakpointCols={3}
                         className="cs-grid"
                         columnClassName="cs-grid-column">
                    {whitePaperResponse.map((whitePaper: WhitePaper, index: number) => (
                        <div key={`key${index}`} className="mb-3">
                            <WhitePaperCard whitePaper={whitePaper} />
                        </div>
                    ))}
                </Masonry>
            )}
        </div>
    );
};

export default OrganisationWhitePaper;