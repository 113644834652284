import React from 'react';

const PageNotFound: React.FC = () => {
    return (
            <div style={{paddingTop: 35, textAlign: "center"}}>
               <p>We're sorry, we couldn't find the page you requested.</p>
            </div>
    );
};

export default PageNotFound;
