import React, {useEffect, useState} from "react";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import NylasContactStat from "../../model/organisation/social/NylasContactStat";
import {useHistory, useLocation} from "react-router-dom";
import Organisation from "../../model/organisation/Organisation";
import {socialHostName} from "../../utils/Configuration";
import NylasContact from "../../model/organisation/social/NylasContact";
import IqLoadingIcon from "../common/IqLoadingIcon";

const ContactEmail: React.FC = () => {
    const myContacts = useLocation().state;
    const history = useHistory();

    const [contacts, setContacts] = useState<NylasContact[]>([]);
    const [messageStats, setMessageStats] = useState<NylasContactStat>({} as NylasContactStat);
    const [inboundData, setInboundData] = useState<any[]>([]);
    const [outboundData, setOutboundData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [organisation, setOrganisation] = useState<Organisation>({} as Organisation);


    useEffect(() => {
        setLoading(true)
        let locationStateData: any;
        if (myContacts) {
            locationStateData = myContacts;
            setOrganisation(locationStateData.organisation)
            setContacts(locationStateData.contacts)
        }

        const fetchMessageStats = async () => {
            setLoading(true);
            await axios.post(socialHostName + "messagestats", locationStateData.contactEmailId)
                .then(r => setMessageStats(r.data))
                .finally(() => setLoading(false));
        }
        fetchMessageStats();

    }, [history.location, myContacts])

    const ContactsStats = () => {

        return (
            <div id="nylas-stats-card-container" style={{paddingTop: 15}}>
                <div className="nylas-stats-card">
                    <div className="nylas-stats-label">
                        Message Stats:
                    </div>
                    <div>
                        <b>Last Message Received:</b>
                        <span style={{float: "right"}}> {messageStats.latestMessageReceived} </span>
                    </div>
                    <div><b>Last Message Sent:</b>
                        <span style={{float: "right"}}>{messageStats.latestMessageSent}</span></div>
                </div>

                <div className="nylas-stats-card">
                    <div className="nylas-stats-label">
                        Last 90 Days:
                    </div>
                    <div>
                        <div><b>Received:</b>
                            <span
                                style={{float: "right"}}>{messageStats.inboundCount}
                                <FontAwesomeIcon style={{marginLeft: 5}} icon={faEnvelope} color="blue"
                                                 size="1x"/>
                                    </span></div>
                        <div><b>Sent:</b>
                            <span
                                style={{float: "right"}}>{messageStats.outboundCount}
                                <FontAwesomeIcon style={{marginLeft: 5}} icon={faEnvelope} color="blue"
                                                 size="1x"/>
                                    </span></div>
                        <div><b>Copied Received:</b>
                            <span
                                style={{float: "right"}}>{messageStats.copiedInboundCount}
                                <FontAwesomeIcon style={{marginLeft: 5}} icon={faEnvelope} color="blue"
                                                 size="1x"/>
                                    </span></div>
                        <div><b>Copied Sent:</b>
                            <span
                                style={{float: "right"}}>{messageStats.copiedOutboundCount}
                                <FontAwesomeIcon style={{marginLeft: 5}} icon={faEnvelope} color="blue"
                                                 size="1x"/>
                                    </span></div>
                    </div>
                </div>
            </div>

        );
    }

    const returnDate = (date: string) => {
        let year = +date.split("/")[2]
        let month = +date.split("/")[1]
        let day = +date.split("/")[0]
        return new Date(year, month - 1, day)
    }

    const setGraphData = () => {
        let inBoundPlots: any[];
        if (messageStats.messageInboundGraph) {
            inBoundPlots = messageStats.messageInboundGraph.map(data => ({
                x: returnDate(data.x),
                y: data.y
            }))
            setInboundData(inBoundPlots)
        }
        let outBoundPlots: any[];
        if (messageStats.messageOutboundGraph) {
            outBoundPlots = messageStats.messageOutboundGraph.map(data => ({
                x: returnDate(data.x),
                y: data.y
            }))
            setOutboundData(outBoundPlots)
        }
    }

    useEffect(() => {
        setGraphData();
    }, [messageStats])

    let chart: any;
    const Graph = () => {

        useEffect(() => {

            // Themes begin
            am4core.useTheme(am4themes_animated);
// Themes end
            chart = am4core.create("chartdiv", am4charts.XYChart);
            chart.paddingRight = 60;

            var data: any[] = [];

            inboundData.forEach(value => {
                data.push({date: value.x, inboundValue: value.y})
            })

            outboundData.forEach(value => {
                data.push({date: value.x, outboundValue: value.y})
            })

            chart.data = data;

            // Create axes
            let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.minGridDistance = 40;
            dateAxis.groupData = true;
            dateAxis.skipEmptyPeriods = true;
            dateAxis.title.text = "Date";

            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.maxPrecision = 0;
            valueAxis.title.text = "No of Emails";

            let inboundSeries = chart.series.push(new am4charts.ColumnSeries());
            inboundSeries.dataFields.valueY = "inboundValue";
            inboundSeries.dataFields.dateX = "date";
            inboundSeries.name = "Inbound"
            inboundSeries.tooltipText = "{name}: [bold]{inboundValue}[/]"

            let outboundSeries = chart.series.push(new am4charts.ColumnSeries());
            outboundSeries.dataFields.valueY = "outboundValue";
            outboundSeries.dataFields.dateX = "date";
            outboundSeries.name = "Outbound"
            outboundSeries.tooltipText = "{name}: [bold]{outboundValue}[/]"
            outboundSeries.stacked = true;


            chart.legend = new am4charts.Legend();
            chart.cursor = new am4charts.XYCursor();

            chart.scrollbarX = new am4core.Scrollbar();

            return () => {
                chart.dispose()
            }
        })


        return (
            <div id="chartdiv"/>
        );
    }



    const ContactGraph = () => {
        return (
                <div style={{paddingTop: 15}}>
                    <div className="nylas-contact-graph">
                        <Graph/>
                    </div>
                </div>
        );
    }


    return (
        <div>
            {loading ? <IqLoadingIcon/> : (
                <div>
            <ContactsStats/>
            <ContactGraph/>
                </div>
            )}
        </div>
    );
};

export default ContactEmail;
