import React, {useEffect, useState} from 'react';
import {Route, Switch, useRouteMatch} from "react-router-dom";

import './AdvancedSearchContainer.css';
import AdvancedSearchList from './AdvancedSearchList';
import AdvancedSearchCreation from './AdvancedSearchCreation';
import AdvancedSearchView from './AdvancedSearchView';
import axios from "axios";
import {frontEndHostName} from "../../utils/Configuration";
import AdvancedSearchSelectedContext from "../../context/advanced-search/AdvancedSearchSelectedContext";

const AdvancedSearchContainer: React.FC = () => {

    let match = useRouteMatch();
    const [selectedNuts, setSelectedNuts] = useState<any>();
    const [selectedISIC, setSelectedISIC] = useState<any>();


    const setNutsOrIsic = (data: any) => {
        if (data?.field === "nutsClassification.nuts*Code.keyword"){
            setSelectedNuts(data?.value)
        }

        if (data?.field === "isicClassification.isicLevel*Code.keyword"){
            setSelectedISIC(data?.value)
        }
    }

    useEffect(() => {
        const fetchOnboardingSearchGraph = async () => {
            await axios.get(frontEndHostName + 'onboarding/filter')
                .then((response) => {
                    setNutsOrIsic(response.data)
                })
        };
        fetchOnboardingSearchGraph();

    }, []);

    return (
        <AdvancedSearchSelectedContext.Provider value={{ selectedNuts, setSelectedNuts, selectedISIC, setSelectedISIC }}>
            <Switch>
                <Route exact path={`${match.url}`} component={AdvancedSearchList} />
                <Route path={`${match.url}/create`} component={AdvancedSearchCreation}/>
                <Route path={`${match.url}/:advancedSearchId/edit`} component={AdvancedSearchCreation}/>
                <Route path={`${match.url}/:advancedSearchId`}>
                    <AdvancedSearchView />
                </Route>
            </Switch>
        </AdvancedSearchSelectedContext.Provider>
    );
};

export default AdvancedSearchContainer;
