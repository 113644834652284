import axios from "axios";
import React, {useContext, useEffect, useState} from "react";
import {Redirect, Route, useRouteMatch} from "react-router-dom";
import UserOrganisationContext from "../../../context/organisation/OrganisationContext";
import PublicSectorContext, {
    CCSFilters,
    CCSSort,
    FundingSort,
    GCloudFilters,
    GCloudSort,
    ResearchFundingFilters
} from "../../../context/PublicSectorContext";
import CCSFramework from "../../../model/publicsector/CCSFramework";
import CCSFrameworkIdsAndName from "../../../model/publicsector/CCSFrameworkIdsAndName";
import Funding from "../../../model/publicsector/Funding";
import GCloudSupplier from "../../../model/publicsector/GCloudSupplier";
import {contractsHostName} from "../../../utils/Configuration";
import {hasUserRole, Role} from "../../../utils/Security";
import CCSFrameworks from "../../publicsector/framework/CCSFrameworks";
import CCSFrameworkView from "../../publicsector/framework/CCSFrameworkView";
import GCloudView from "../../publicsector/framework/GCloudView";
import PublicResearchFunding from "../../publicsector/funding/PublicResearchFunding";
import PublicResearchFundingView from "../../publicsector/funding/PublicResearchFundingView";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import { StateHandler } from "../../../model/organisation/editor/StateHandler";
import OrganisationCaseStudies from "../casestudy/OrganisationCaseStudies";
import OrganisationCaseStudiesView from "./OrganisationCaseStudiesView";
import OrganisationWhitePaper from "../whitepaper/OrganisationWhitePaper";
import OrganisationInvestorRelations from "./OrganisationInvestorRelations";



const OrganisationCaseStudiesContainer: React.FC = () => {

    const {
        organisation,
        caseStudiesResponse,
        caseStudiesState,
        whitePaperResponse,
        whitePaperState,
        youtubeCaseStudies
    } = useContext(UserOrganisationContext);

    let match = useRouteMatch();
    
    return (
        <div>
            <OrganisationCaseStudiesView></OrganisationCaseStudiesView>
            {hasUserRole(Role.GEO_UK) &&
            <Route exact strict path={`${match.url}/casestudies`}>
                <div style={{
                    paddingTop: "10px",
                    backgroundColor: "white",
                    borderRadius: "0.45rem",
                    padding: 15,
                    marginTop: 10
                }}>
                    <OrganisationCaseStudies/>
                </div>

            </Route>}
            {hasUserRole(Role.GEO_UK) &&
            <Route exact strict path={`${match.url}/whitepaper`} component={OrganisationWhitePaper}/>}

            {hasUserRole(Role.GEO_UK) &&
            <Route exact path={`${match.url}/investor-relations`} component={OrganisationInvestorRelations}/>}
            {hasUserRole(Role.GEO_UK) &&
            <Route exact path={`${match.url}`}>
                {caseStudiesResponse?.length > 0  || youtubeCaseStudies.length > 0 ? (
                    <Redirect to={`${match.url}/casestudies`} />
                ) : whitePaperResponse?.length > 0 ? (
                    <Redirect to={`${match.url}/whitepaper`} />
                ) : (
                    <Redirect to={`${match.url}/investor-relations`} />
                )}
            </Route>
            }
        </div>
    );
}

export default OrganisationCaseStudiesContainer;