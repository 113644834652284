import React, {useContext, useEffect, useRef, useState} from "react";
import DashboardItemContext from "../../../context/dashboard/DashboardItemContext";
import axios from "axios";
import {organisationHostname} from "../../../utils/Configuration";
import {Alert, Col, Image, Row} from "react-bootstrap";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import translate from "../../../i18n/translate";
import OrganisationFilingHistory from "../../../model/organisation/OrganisationFilingHistory";
import './DashboardFollowedFilingHistory.css';
import {SelectOption} from "../../news/GlobalNews";
import Select from "react-select";

const DashboardFollowedFilingHistory: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [filingHistory, setFilingHistory] = useState<OrganisationFilingHistory[]>([]);

    const { maxHeight, setMaxHeight } = useContext(DashboardItemContext);

    const scroller = useRef<any>();
    const [page, setPage] = useState<number>(0);

    const handleScroll = () => {
        if (!loading && !error) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            let currentScroll = scroller.current.scrollTop;

            if (currentScroll === maxScroll) {
                setPage(prev => prev + 1);
            }
        }
    };

    const [loadingTypes, setLoadingTypes] = useState<boolean>(false);
    const [typeOptions, setTypeOptions] = useState<SelectOption[]>([]);
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    useEffect(() => {
        const fetchFilingHistoryTypes = async () => {
            setLoadingTypes(true);

            await axios.get(organisationHostname + "filing-history/types")
                .then(r => setTypeOptions(r.data))
                .finally(() => setLoadingTypes(false));
        };

        fetchFilingHistoryTypes();
    }, []);

    const setTypeFilters = (value: SelectOption[]) => {
        setSelectedTypes(value?.map((item: SelectOption) => item.value));
        setPage(0);
    };

    useEffect(() => {
        const fetchFilingHistory = async () => {
            setLoading(true);

            let params = "?page=" + page + "&size=10";
            if (selectedTypes?.length !== 0) {
                params += selectedTypes.map((type: string) => `&types=${type}`).join("");
            }

            await axios.get(organisationHostname + "organisation/followed/filing-history" + params)
                .then(r => {
                    if (page === 0) {
                        setFilingHistory([...r.data.content]);
                    }
                    else {
                        setFilingHistory((previousArray: OrganisationFilingHistory[]) => {
                            return [...previousArray, ...r.data.content];
                        });
                    }
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchFilingHistory();
    }, [page, selectedTypes]);

    useEffect(() => {
        if (loading && page !== 0) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            scroller.current.scrollTop = maxScroll;
        }

    }, [loading]);

    const getHeight = () => {
        return maxHeight - 50;
    };

    const CompanyLogo: React.FC<OrganisationFilingHistory> = (org: OrganisationFilingHistory) => {
        const [src, setSrc] = useState<string>(org.organisationLogo ? org.organisationLogo : "./icons_company.svg");

        return (
            <Image title={org.organisationName}
                style={{
                    height: "50px",
                    width: "50px",
                    objectFit: "cover",
                    marginRight: 15,
                    marginBottom: 15,
                    border: "2px solid #5FD1F9"
                }}
                src={org.organisationLogo}
                onError={() => setSrc("/icons_company.svg")}
                roundedCircle />
        )
    };

    return (
        <div>
            <Row className="pb-2">
                <Col>
                    <div className="organisation-card-label">
                        {translate("dashboard.filinghistory.title")}
                    </div>
                </Col>
            </Row>
            {error ? (
                <Alert variant="danger">
                    {translate("errors.loading")}
                </Alert>
            ) : (
                    <div className="filing-history-widget-container" style={{ maxHeight: getHeight(), overflowY: "scroll" }} ref={scroller} onScroll={handleScroll}>
                        <Row className="mb-3">
                            <Col>
                                <Select isMulti
                                        isDisabled={loadingTypes}
                                        options={typeOptions}
                                        onChange={(e: any) => setTypeFilters(e)}
                                        placeholder="Filter by type..."
                                        className="basic-multi-select"
                                        classNamePrefix="select" />
                            </Col>
                        </Row>
                        {filingHistory.map((item: OrganisationFilingHistory) => (
                            <div key={item.transactionId} className="entry nonIqbladeDraggableWidget">
                                <CompanyLogo {...item} />
                                <div className="content">
                                    <span className="description">{item.description}</span>
                                    <span className="date-published">{item.datePublished}</span>
                                    <a target="_blank" href={item.documentLink}>View Document</a>
                                </div>
                            </div>
                        ))}
                        <div>{loading && <IqLoadingIcon />}</div>
                    </div>
                )}
        </div>
    );
};

export default DashboardFollowedFilingHistory;
