import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {frontEndHostName} from "../../../utils/Configuration";
import {Alert, Col, Image, Row} from "react-bootstrap";
import Organisation from "../../../model/organisation/Organisation";
import DashboardItemContext from "../../../context/dashboard/DashboardItemContext";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import translate from "../../../i18n/translate";
import Select from "react-select";


interface SelectOption {
    value: string,
    label: string
}

const DashboardFollowedYearEnd: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const { maxHeight, setMaxHeight, width, setWidth } = useContext(DashboardItemContext);

    const sourceInitialOptions: SelectOption[] = [
        {
            value: "10",
            label: translate("dashboard.followedorganisationsyearend.limit", { count: "10" })
        }
    ]

    const [selectedSource, setSelectedSource] = useState<SelectOption>(sourceInitialOptions[0]);


    const pageLimitOptions: SelectOption[] = [10, 25, 50, 100].map(i =>
    ({
        value: String(i),
        label: translate("dashboard.followedorganisationsyearend.limit", { count: String(i) })
    }))

    useEffect(() => {
        const fetchFollowedOrganisations = async () => {
            setLoading(true);

            await axios.get(frontEndHostName + "followed/year-end?limit=" + parseInt(selectedSource.value))
                .then(r => setOrganisations(r.data))
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchFollowedOrganisations();
    }, [selectedSource.value]);

    const getHeight = () => {
        if (width < 3) {
            return maxHeight - 160;
        }

        return maxHeight - 130;
    };

    const CompanyLogo: React.FC<Organisation> = (org: Organisation) => {
        const [src, setSrc] = useState<string>(org.imageUrl ? org.imageUrl : "./icons_company.svg");

        return (
            <Image title={org.companyName}
                style={{
                    height: "50px",
                    width: "50px",
                    objectFit: "cover",
                    marginRight: 15,
                    marginBottom: 15,
                    border: "2px solid #5FD1F9"
                }}
                src={src}
                onError={() => setSrc("/icons_company.svg")}
                roundedCircle />
        )
    };

    const getClass = () => {
        let className = "followed-company-name pb-1";

        if (width < 4) {
            className += " sm";
        }

        return className;
    };


    const fixDateFormat = (date: string) => {
        let dateSplit = date.split("/");
        if (parseInt(dateSplit[1]) > 12) {
            return dateSplit[1] + "/" + (dateSplit[0]?.length < 2 ? ["0", dateSplit[0]].join("") : dateSplit[0])
        }
        return date.substring(0, date.lastIndexOf('/'))
    }


    return (
        <div>
            <Row className="pb-2">
                <Col>
                    <div className="organisation-card-label">
                        {translate("dashboard.followedorganisationsyearend.title")}
                    </div>
                </Col>
            </Row>
            <Row className="nonIqbladeDraggableWidget">
                <Col className="pt-2" xl={6} lg={6} md={3} sm={6} xs={6}>
                    <Select
                        options={pageLimitOptions}
                        value={selectedSource}
                        onChange={(e: any) => setSelectedSource(e)}
                        placeholder="Select source..."
                        className="basic-multi-select"
                        classNamePrefix="select" />
                </Col>
            </Row>
            {error ? (
                <Alert variant="danger">
                    {translate("errors.loading")}
                </Alert>
            ) : (
                <div>
                    {loading ? <IqLoadingIcon /> : (
                        <div style={{ maxHeight: getHeight(), overflowY: "scroll", paddingTop: "10px" }}>
                            <div className="nonIqbladeDraggableWidget" style={{ paddingRight: "10px" }}>
                                {organisations.map((org: Organisation) => (
                                    <div key={org.id} className="mb-2">
                                        <a className={getClass()} href={`/organisation/${org.id}`}>
                                            <CompanyLogo {...org} />
                                            <span className="iqx-link">
                                                {org.companyName}
                                            </span>
                                            <span style={{ float: "right" }}>
                                                {org.financialSummary[0]?.dateOfAccounts ? (
                                                    <span>{fixDateFormat(org.financialSummary[0].dateOfAccounts)}</span>
                                                ) : (
                                                    <span style={{ float: "right" }}>
                                                        -
                                                    </span>
                                                )}
                                            </span>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>

                    )}
                </div>
            )}
        </div>
    );
};

export default DashboardFollowedYearEnd;
