import React from "react";


const SearchContext = React.createContext({

    searchQuery: "",
    setSearchQuery: (title: string) => {}

    
});

export default SearchContext;
