import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Select} from 'antd';
import axios from 'axios';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Alert, Form} from 'react-bootstrap';
import {useHistory, useRouteMatch} from 'react-router-dom';
import AsyncSelect from "react-select/async";
import SearchCompany from '../../model/advanced-search/SearchCompany';
import Campaign from '../../model/campaigns/Campaign';
import ManagedCampaign from '../../model/campaigns/ManagedCampaign';
import ManagedCampaignURN from '../../model/campaigns/ManagedCampaignURN';
import {Region} from '../../model/campaigns/Region';
import {Status} from '../../model/campaigns/Status';
import {Type} from '../../model/campaigns/Type';
import {User} from '../../model/campaigns/User';
import {searchHostname, socialHostName} from '../../utils/Configuration';
import {getUserNameTuple} from '../../utils/Security';
import IqSmallLoadingIcon from '../common/IqSmallLoadingIcon';
import {SelectOption} from '../news/GlobalNews';
import './ManagedCampaignEditor.css';

const Option = Select.Option;


interface ManagedCampaignEditor {
    mc: ManagedCampaign | null
    setEditor: Dispatch<SetStateAction<any>>
}
const ManagedCampaignEditor: React.FC<ManagedCampaignEditor> = (props: ManagedCampaignEditor) => {

    let match = useRouteMatch();
    let history = useHistory();

    const [error, setError] = useState<boolean>(false);

    const [id, setId] = useState<number>();
    const [listId, setListId] = useState<number>();
    const [campaignName, setCampaignName] = useState<string>();
    const [campaignType, setCampaignType] = useState<string>();
    const [campaignRegions, setCampaignRegions] = useState<string[]>();
    const [campaignPersonas, setCampaignPersonas] = useState<string[]>();
    const [campaignStatus, setCampaignStatus] = useState<Status>();
    const [creator, setCreator] = useState<{ name: string, id: number | null }>();

    const [distributor, setDistributor] = useState<{ label: string, value: string }>();
    const [selectedDistributorUsers, setSelectedDistributedUsers] = useState<number[]>([]);
    const [distributorUsers, setDistributorUsers] = useState([]);

    const [partner, setPartner] = useState<{ label: string, value: string }>();
    const [selectedPartnerUsers, setSelectedPartnerUsers] = useState<number[]>([]);
    const [partnerUsers, setPartnerUsers] = useState([]);
    const [partnerRecipientUsers, setRecipientUsers] = useState([]);
    const [partnerRecipient, setPartnerRecipient] = useState<number | undefined>();

    const [vendor, setVendor] = useState<{ label: string, value: string }>();
    const [selectedVendorUsers, setSelectedVendorUsers] = useState<number[]>([]);
    const [vendorUsers, setVendorUsers] = useState([]);

    const [selectedLinkedinUrns, setSelectedLinkedInUrns] = useState<{ name: string, urnid: number | undefined, componentId: number }[]>([]);
    const [linkedInUrns, setLinkedInUrns] = useState<ManagedCampaignURN[]>();
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);

    //Loading

    const [loadingDistributorUsers, setLoadingDistributorUsers] = useState<boolean>(false);
    const [loadingPartnerUsers, setLoadingPartnerUsers] = useState<boolean>(false);
    const [loadingVendorUsers, setLoadingVendorUsers] = useState<boolean>(false);
    const [loadingPartnerRecipientUsers, setLoadingPartnerRecipientUsers] = useState<boolean>(false);
    const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

    const loadOrganisations = (q: string) => {
        let data = {
            searchQuery: q,
            filters: ["Active"]
        };

        return new Promise<SelectOption[]>((resolve: any, reject: any) => {
            axios.post(searchHostname + "quick-search", data)
                .then((r) => {
                    resolve(
                        r.data.map((o: SearchCompany) => {
                            return {
                                label: o.companyName,
                                value: o.companyId
                            }
                        })
                    );
                })
                .catch(reject);
        });
    };

    useEffect(() => {
        if (props.mc) {

            setId(props.mc?.id);
            setListId(props.mc?.list);
            setCampaignName(props.mc?.campaignName)
            setCampaignType(props.mc?.campaignType)
            setCampaignStatus(props.mc?.status)
            setCampaignRegions(props.mc?.regions)
            setCampaignPersonas(props.mc?.personas)
            setCreator({ name: props.mc?.creator.firstName + " " + props.mc?.creator.lastName, id: props.mc?.creator.id })
            setPartnerRecipient(props.mc?.recipient?.id)
            setSelectedLinkedInUrns(props.mc.linkedinUrns.map((inurn, index) => ({ name: inurn.name, urnid: inurn.urn, componentId: index })))
            setSelectedDistributedUsers(props.mc.distributorUsers.map(value => value.id))
            setSelectedPartnerUsers(props.mc.partnerUsers.map(value => value.id))
            setSelectedVendorUsers(props.mc.vendorUsers.map(value => value.id))

            if (props.mc.distributor) {
                setDistributor({ label: props.mc.distributor.companyName, value: props.mc.distributor.id })
            }
            if (props.mc.partner) {
                setPartner({ label: props.mc.partner.companyName, value: props.mc.partner.id })
            }
            if (props.mc.vendor) {
                setVendor({ label: props.mc.vendor.companyName, value: props.mc.vendor.id })
            }
        } else {
            setCreator({ name: getUserNameTuple().join(" "), id: null });
            setCampaignType(Type.THROUGH_PARTNER)
            setCampaignStatus(Status.AWAITING_LIST)
        }
    }, [])

    useEffect(() => {
        // recall options
        distributor && fetchUsers(distributor?.value, setDistributorUsers, setLoadingDistributorUsers);
    }, [distributor])

    useEffect(() => {
        // recall options
        partner && fetchUsers(partner?.value, setPartnerUsers, setLoadingPartnerUsers);
        partner && fetchRecipientUsers(partner?.value, setRecipientUsers, setLoadingPartnerRecipientUsers);
    }, [partner])

    useEffect(() => {
        // recall options
        vendor && fetchUsers(vendor?.value, setVendorUsers, setLoadingVendorUsers);
    }, [vendor])


    const fetchUsers = async (organisationId: string, setUsers: any, setLoading: any) => {
        setLoading(true);
        await axios.get(socialHostName + "managedcampaign/" + organisationId + "/users")
            .then(r => {
                setUsers(r.data as User[]);
            })
            .catch(error => console.error(error.message))
            .finally(() => { setLoading(false) });
    };

    const fetchRecipientUsers = async (organisationId: string, setUsers: any, setLoading: any) => {
        setLoading(true)
        await axios.get(socialHostName + "managedcampaign/" + organisationId + "/recipient-users")
            .then(r => {
                setUsers(r.data as User[]);
            })
            .catch(error => console.error(error.message))
            .finally(() => { setLoading(false) });
    };


    const fetchCampaigns = async () => {
        await axios.get(socialHostName + "linkedin/campaigns")
            .then(r => {
                setCampaigns(r.data as Campaign[]);
            })
            .catch(error => console.error(error.message))
            .finally(() => { });
    };

    const appendCampaignInput = () => {
        setSelectedLinkedInUrns([...selectedLinkedinUrns,
        { name: "", urnid: undefined, componentId: selectedLinkedinUrns.length === 0 ? 0 : selectedLinkedinUrns?.reduce((prev, current) => prev.componentId > current.componentId ? prev : current).componentId + 1 }])
    }

    const removeCampaignInput = (id: number) => {
        setSelectedLinkedInUrns(selectedLinkedinUrns.filter(input => input.componentId !== id));
    }

    const changeCampaignName = (id: number, name: string) => {
        setSelectedLinkedInUrns(selectedLinkedinUrns.map(input => {
            if (input.componentId === id) {
                input.name = name;
            }
            return input;
        }))
    }

    const changeCampaignUrn = (id: number, urn: number) => {
        setSelectedLinkedInUrns(selectedLinkedinUrns.map(input => {
            if (input.componentId === id) {
                input.urnid = +urn;
            }
            return input;
        }))
    }

    const save = () => {
        const dto = {
            id: id,
            campaignName: campaignName,
            campaignType: campaignType,
            distributorId: distributor?.value,
            partnerId: partner?.value,
            recipientId: partnerRecipient,
            vendorId: vendor?.value,
            status: campaignStatus,
            regions: campaignRegions,
            personas: campaignPersonas,
            distributorUsers: selectedDistributorUsers,
            partnerUsers: selectedPartnerUsers,
            vendorUsers: selectedVendorUsers,
            linkedinUrns: selectedLinkedinUrns.map(input => ({ name: input.name, urn: input.urnid }))
        };

        setSaveInProgress(true);
        axios.post(socialHostName + 'managedcampaign', dto,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(() => { props.setEditor({ show: false, campaign: null }) })
            .catch(error => {
                let responseData = error.response.data;
                setError(true);
            }).finally(() => setSaveInProgress(false));
    }

    useEffect(() => {
        fetchCampaigns();
    }, []);

    return (

        <div id="campaigns-manager-editor">

            <div id="title">{props.mc ? "Editing " + campaignName : "Create new campaign"}</div>

            {id &&
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>ID</Form.Label>
                    <Form.Control value={id} type="text" placeholder="ID" disabled={true} />
                    <Form.Text className="text-muted">
                        This ID cannot be changed and it is used for informational purposes.
                    </Form.Text>
                </Form.Group>
            }

            {creator &&
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Campaign Manager</Form.Label>
                    <Form.Control value={creator.name} type="text" placeholder="ID" disabled={true} />
                    <Form.Text className="text-muted">
                        Campaign manager cannot be changed and it is used for informational purposes.
                    </Form.Text>
                </Form.Group>
            }

            {listId &&
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>List ID</Form.Label>
                    <Form.Control value={listId} type="text" placeholder="ID" disabled={true} />
                    <Form.Text className="text-muted">
                        This list ID cannot be changed and it is used for informational purposes.
                    </Form.Text>
                </Form.Group>
            }

            <Form.Group controlId="formBasicEmail">
                <Form.Label>Campaign Name</Form.Label>
                <Form.Control value={campaignName} type="text" placeholder="Campaign name" onChange={(e: any) => setCampaignName(e.target.value)} />
            </Form.Group>


            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Campaign Type</Form.Label>
                <Form.Control onChange={(e: any) => setCampaignType(e.target.value)} value={campaignType} as="select">
                    {Object.values(Type).map(type => (<option>{type}</option>))}
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Campaign Status</Form.Label>
                <Form.Control onChange={(e: any) => setCampaignStatus(e.target.value)} value={campaignStatus} as="select">
                    {Object.values(Status).map(status => (<option>{status}</option>))}
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Campaign Regions</Form.Label>
                <Select
                    disabled={false}
                    mode={"multiple"}
                    style={{
                        minWidth: "100%"
                    }}
                    dropdownStyle={{
                        width: 250,
                    }}
                    key={"widget-multiselect-regions"}
                    placeholder={"Choose regions"}
                    size={"large"}
                    value={campaignRegions}
                    onChange={(val: string[]) => { setCampaignRegions(val) }}
                    filterOption={true}
                >
                    {Object.values(Region).map((region: string) => {
                        return (<Option key={region} value={region}>{region}</Option>);
                    })}
                </Select>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Campaign Personas</Form.Label>
                <Select
                    disabled={false}
                    mode={"tags"}
                    style={{
                        minWidth: "100%"
                    }}
                    dropdownStyle={{
                        width: 250,
                    }}
                    key={"widget-multiselect-regions"}
                    placeholder={"Input personas"}
                    size={"large"}
                    value={campaignPersonas}
                    onChange={(val: string[]) => { setCampaignPersonas(val) }}
                    filterOption={true}
                >
                </Select>
            </Form.Group>

            <Form.Group controlId="formOrganisationId">
                <Form.Label>Distributor</Form.Label>

                <AsyncSelect
                    value={distributor}
                    onChange={(e: any) => { setSelectedDistributedUsers([]); setDistributor({ label: e.label, value: e.value }) }}
                    loadOptions={loadOrganisations} />

                {distributor ?
                    loadingDistributorUsers ?
                        <IqSmallLoadingIcon />
                        :
                        <div className="children">
                            <Form.Label>Users of the distributor</Form.Label>

                            <Select
                                disabled={false}
                                mode={"multiple"}
                                style={{
                                    minWidth: "100%"
                                }}
                                dropdownStyle={{
                                    width: 250,
                                }}
                                key={"widget-multiselect-distributors"}
                                placeholder={"Choose users from distributor organisation"}
                                size={"large"}
                                value={selectedDistributorUsers}
                                onChange={(val: number[]) => { setSelectedDistributedUsers(val) }}
                                filterOption={true}
                            >
                                {distributorUsers.map((o: User) => {
                                    return (<Option key={o.id} value={o.id}>{o.firstName} {o.lastName}</Option>);
                                })}
                            </Select>
                        </div>
                    :
                    <></>
                }
            </Form.Group>

            <Form.Group controlId="formOrganisationId">
                <Form.Label>Partner</Form.Label>

                <AsyncSelect
                    value={partner}
                    defaultOptions={[]}
                    onChange={(e: any) => { setSelectedPartnerUsers([]); setPartnerRecipient(undefined); setPartner({ label: e.label, value: e.value }) }}
                    loadOptions={loadOrganisations} />
                {partner ?
                    loadingPartnerUsers ?
                        <IqSmallLoadingIcon />
                        :
                        <div className="children">
                            <Form.Label>Users of the partner</Form.Label>
                            <Select
                                disabled={false}
                                mode={"multiple"}
                                style={{
                                    minWidth: "100%"
                                }}
                                dropdownStyle={{
                                    width: 250,
                                }}
                                key={"widget-multiselect-partners"}
                                placeholder={"Choose users from partner organisation"}
                                size={"large"}
                                value={selectedPartnerUsers}
                                onChange={(val: number[]) => { setSelectedPartnerUsers(val) }}
                                filterOption={true}
                            >
                                {partnerUsers.map((o: User) => {
                                    return (<Option key={o.id} value={o.id}>{o.firstName} {o.lastName}</Option>);
                                })}
                            </Select>
                        </div>
                    :
                    <></>
                }
                {partner ?
                    loadingPartnerRecipientUsers ?
                        <IqSmallLoadingIcon />
                        :
                        <div className="children">
                            <Form.Label>Recipient of the partner (list provider)</Form.Label>
                            <Select
                                disabled={false}
                                // mode={"multiple"}
                                style={{
                                    minWidth: "100%"
                                }}
                                dropdownStyle={{
                                    width: 250,
                                }}
                                key={"widget-multiselect-partner-recipients"}
                                placeholder={"Choose a recipient from partner organisation"}
                                size={"large"}
                                value={partnerRecipient}
                                onChange={(val: number) => { setPartnerRecipient(val) }}
                                filterOption={true}
                            >
                                {partnerRecipientUsers.map((o: User) => {
                                    return (<Option key={o.id} value={o.id}>{o.firstName} {o.lastName}</Option>);
                                })}
                            </Select>
                        </div>
                    :
                    <></>
                }
            </Form.Group>

            <Form.Group controlId="formOrganisationId">
                <Form.Label>Vendor</Form.Label>

                <AsyncSelect
                    value={vendor}
                    defaultOptions={[]}
                    onChange={(e: any) => { setSelectedVendorUsers([]); setVendor({ label: e.label, value: e.value }) }}
                    loadOptions={loadOrganisations} />

                {vendor ?
                    loadingVendorUsers ?
                        <IqSmallLoadingIcon />
                        :
                        <div className="children">
                            <Form.Label>Users of the vendor</Form.Label>
                            <Select
                                disabled={false}
                                mode={"multiple"}
                                style={{
                                    minWidth: "100%"
                                }}
                                dropdownStyle={{
                                    width: 250,
                                }}
                                key={"widget-multiselect-vendors"}
                                placeholder={"Choose users from vendor organisation"}
                                size={"large"}
                                value={selectedVendorUsers}
                                onChange={(val: number[]) => { setSelectedVendorUsers(val) }}
                                filterOption={true}
                            >
                                {vendorUsers.map((o: User) => {
                                    return (<Option key={o.id} value={o.id}>{o.firstName} {o.lastName}</Option>);
                                })}
                            </Select>
                        </div>
                    :
                    <></>
                }
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Linkedin URNs (campaigns)</Form.Label>
                <div className="campaign-input-container">
                    {selectedLinkedinUrns.map(input => (<div key={input.componentId} className="campaign-input">
                        <Form.Control value={input.name} onChange={(e: any) => changeCampaignName(input.componentId, e.target.value)} type="text" placeholder="Campaign name/stage" />
                        <Form.Control value={input.urnid} onChange={(e: any) => changeCampaignUrn(input.componentId, e.target.value)} as="select">
                            <option key='blankChoice' hidden> Choose Linkedin campaign to link </option>
                            {campaigns.map(c => (<option value={c.campaign.id}>{c.campaign.name}</option>))}
                        </Form.Control>
                        <button style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="iqx-button md-size"
                            onClick={() => removeCampaignInput(input.componentId)}>
                            <span style={{ flexShrink: 0 }}><FontAwesomeIcon icon={faTrash} size="1x" /></span>
                        </button>
                    </div>))}
                </div>
                <button style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="iqx-button iconic md-size pe-2 pe-3"
                    onClick={() => appendCampaignInput()}>
                    <span>Add</span>
                </button>
            </Form.Group>
            {error &&
                <Alert variant="danger">
                    An error was thrown when processing your managed campaign. Check the fields and try again.
                </Alert>}
            {saveInProgress ? <IqSmallLoadingIcon /> :
                <button
                    disabled={loadingDistributorUsers ||
                        loadingPartnerRecipientUsers ||
                        loadingPartnerUsers ||
                        loadingPartnerUsers ||
                        loadingVendorUsers}
                    className="iqx-button primary iconic md-size pe-2 pe-3"
                    onClick={() => save()}>
                    <span className="pe-2">Save</span>
                </button>}
            <button style={{ marginTop: 10 }} className="iqx-button iconic md-size pe-2 pe-3"
                onClick={() => props.setEditor({ show: false, campaign: null })}>
                <span className="pe-2">Close</span>
            </button>
        </div>
    );
};

export default ManagedCampaignEditor;