import React, {useEffect, useState} from "react";
import List from "../../model/list/List";
import axios from "axios";
import {frontEndHostName, searchHostname} from "../../utils/Configuration";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {SelectOption} from "../news/GlobalNews";
import SearchCompany from "../../model/advanced-search/SearchCompany";
import {Form, Spinner} from "react-bootstrap";
import AsyncSelect from "react-select/async";
import translate from "../../i18n/translate";
import IqFlag from "../common/CountryFlag";

interface Props {
    continue: Function
}

const OnboardingCompetitors: React.FC<Props> = (props: Props) => {
    const [competitorsList, setCompetitorsList] = useState<List>();
    const [loading, setLoading] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    useEffect(() => {
        const fetchList = async () => {
            setLoading(true);
            await axios.get(frontEndHostName + "user-lists/competitors")
                .then(r => setCompetitorsList(r.data))
                .finally(() => setLoading(false));
        };

        fetchList();
    }, []);

    const [competitors, setCompetitors] = useState<Map<number, string> | undefined>(undefined);

    const updateCompetitors = (value: string, i: number) => {
        setCompetitors(competitors === undefined
            ? new Map<number, string>([[i, value]])
            : competitors.set(i, value));
    };

    const loadOrganisations = (q: string) => {
        let data = {
            searchQuery: q,
            filters: ["Active"]
        };

        return new Promise<SelectOption[]>((resolve: any, reject: any) => {
            axios.post(searchHostname + "quick-search", data)
                .then((r) => {
                    resolve(
                        r.data.map((o: SearchCompany) => {
                            return {
                                label: <div style={{display: "flex"}}>
                                    <span>{o.companyName +" - "+ o.companyId}</span>
                                    <span className={"option-country-flag"}><IqFlag country={o.country} width={25} height={25} /></span>
                                </div>,
                                value: o.companyId
                            }
                        })
                    );
                })
                .catch(reject);
        });
    };

    const save = () => {
        if (competitors?.size === 0) {
            props.continue();
        }
        else {
            let competitorsData: string[] = []
            competitors?.forEach(value =>
                competitorsData.push(value)
            );
            setSaving(true);
            axios.put(frontEndHostName + 'user-lists/' + competitorsList?.id + '/add-companies', competitorsData)
                .then(() => props.continue())
                .finally(() => setSaving(false));
        }
    };

    return (
        <div className="form-group">
            {loading ? <IqLoadingIcon /> : (
                <div>
                    <div className="row mb-3">
                        <div className="col-xs-4">
                            <span className="welcome">Help us by selecting some of your competitors.</span>
                        </div>
                    </div>
                    {Array.from(Array(3).keys()).map(i =>
                        (<Form.Group key={i}>
                            <Form.Label>Competitor {i + 1}</Form.Label>

                            <AsyncSelect
                                         placeholder={translate("onboarding.typeandselect")}
                                         defaultOptions={[]}
                                         isClearable
                                         onChange={(e: any) => {
                                             if (e !== null){
                                                 updateCompetitors(e.value,i)
                                             } else {
                                                 competitors?.delete(i)
                                             }
                                         }}
                                         loadOptions={loadOrganisations}/>
                        </Form.Group>)
                    )}

                    {saving ? (
                        <div>
                        <button className="iq-button iq-button-primary pull-right"
                                disabled={true}>
                            <span className="me-2">
                                {translate("onboarding.continue")}
                            </span>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </button>


                        </div>
                    ) : (
                        <button className="iq-button iq-button-primary pull-right"
                                onClick={() => save()}>
                            <span>
                                {translate("onboarding.continue")}
                            </span>
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default OnboardingCompetitors;
