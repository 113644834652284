import React, { useContext, useEffect } from "react";
import { useState } from "react";
import {Col, Container, Row } from "react-bootstrap";
import { Route, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import OrganisationContext from "../../../context/organisation/OrganisationContext";
import PublicSectorContext from "../../../context/PublicSectorContext";
import translate from "../../../i18n/translate";


export enum SectorType{
    CASE_STUDIES = "case studies",
    WHITEPAPER = "whitepaper",
    INVESTOR_RELATIONS = "investor relations"
}

const OrganisationPublicSectorView: React.FC = () => {

    const {
        caseStudiesResponse,
        whitePaperResponse,
        investorRelationsResponse,
        youtubeCaseStudies
    } = useContext(OrganisationContext);

    const {organisation} = useContext(OrganisationContext);

    let match = useRouteMatch();
    let history = useHistory();
    const location = useLocation();

    const [sectorSelected, setSectorSelected] = useState<SectorType>(SectorType.CASE_STUDIES);
    
    return (
            <Container fluid style={{marginTop: 15, marginRight: "auto", paddingLeft: 15}}>
                <Row className="" style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                    <div style={{marginLeft: "auto", marginRight: "auto"}} className="iq-button-group">
                        {((caseStudiesResponse?.length > 0 || youtubeCaseStudies?.length > 0) && organisation.primaryType != "PublicSector") && (
                            <button className={window.location.pathname.includes("casestudies") ? "selected-inverted" : ""}
                                    onClick={() => { history.push(`${match.url}/casestudies`) }}>
                                Case-Studies
                            </button>
                        )}
                        {(whitePaperResponse?.length > 0  && organisation.primaryType != "PublicSector") && (
                            <button className={window.location.pathname.includes("whitepaper") ? "selected-inverted" : ""}
                                    onClick={() => { history.push(`${match.url}/whitepaper`) }}>
                                White Paper
                            </button>
                        )}
                        {(investorRelationsResponse?.length > 0  && organisation.primaryType != "PublicSector") && (
                            <button className={window.location.pathname.includes("investor") ? "selected-inverted" : ""}
                                    onClick={() => { history.push(`${match.url}/investor-relations`) }}>
                                Investor Relations
                            </button>
                        )}
                    </div>
                </Row>
            </Container>


        );
}

export default OrganisationPublicSectorView;