import React, {RefObject, useEffect, useState} from 'react';
import OnboardingQuestion from "./OnboardingQuestion";
import OnboardingChannelPartners from "./OnboardingChannelPartners";
import OnboardingCustomers from "./OnboardingCustomers";
import OnboardingCompetitors from "./OnboardingCompetitors";
import OnboardingInterests from "./OnboardingInterests";
import OnboardingThankYou from "./OnboardingThankyou";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {Alert, Container} from "react-bootstrap";
import translate from "../../i18n/translate";
import Organisation from "../../model/organisation/Organisation";
import axios from "axios";
import {frontEndHostName, organisationHostname} from "../../utils/Configuration";
import {useHistory} from "react-router-dom";
import OnboardingSettings from "../../model/onboarding/OnboardingSettings";

const valueUnspecified = "UNSPECIFIED";

enum Role {
    SALES = "Sales",
    MARKETING = "Marketing",
    BOTH = "Sales & Marketing"
}

enum RoleFocus {
    CHANNEL = "Channel Only",
    END_USERS = "End Users Only",
    BOTH = "Channel & End Users"
}

enum Account {
    DEDICATED_ACCOUNTS = "Dedicated Accounts Only",
    NEW_BUSINESS = "New Business Only",
    BOTH = "Dedicated Accounts & New Business"
}

enum BusinessTarget {
    SECTOR = "Sector",
    GEOGRAPHY = "Geography",
    BOTH = "Sector & Geography"
}


interface OnboardingPreferencesProps {
    page: number;
    continueCallback: Function;
    onCompleteCallback: Function;
    setEnableContinueCallback: Function;
    scrollToRef?: RefObject<HTMLDivElement>;
    showWelcome?: boolean;
}

const OnboardingPreferences: React.FC<OnboardingPreferencesProps> = ({
                                                                         page,
                                                                         continueCallback,
                                                                         onCompleteCallback,
                                                                         setEnableContinueCallback,
                                                                         scrollToRef,
                                                                         showWelcome = true
                                                                     }) => {

    let history = useHistory();
    const [role, setRole] = useState<string>(valueUnspecified);
    const [roleFocus, setRoleFocus] = useState<string>(valueUnspecified);
    const [account, setAccount] = useState<string>(valueUnspecified);
    const [businessTarget, setBusinessTarget] = useState<string>(valueUnspecified);
    const [saving, setSaving] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [tree, setTree] = useState<string>();
    const [loadingChannelPartners, setLoadingChannelPartners] = useState<boolean>(false);
    const [onboardingSettings, setOnboardingSettings] = useState<OnboardingSettings>();
    const [channelPartners, setChannelPartners] = useState<Organisation[]>([]);
    const [loading, setLoading] = useState<boolean>(false);


    const valuesAreSet = (...values: Array<string>) => {
        return values.every(v => v !== valueUnspecified)
    }

    useEffect(() => {
        setLoading(true)
        const getOnboardingSettings = async () => {
            await axios.get(frontEndHostName + "onboarding")
                .then(r => setOnboardingSettings(r.data))
                .finally(() => setLoading(false));
        };

        getOnboardingSettings();

    }, [])


    const showSave = () => {
        let isNewBusiness = account === "NEW_BUSINESS" || account === "BOTH";
        return isNewBusiness ?
            valuesAreSet(roleFocus, businessTarget) :
            valuesAreSet(roleFocus, account);
    };

    useEffect(() => {
        if (page > 0 || valuesAreSet(role)) scrollToRef?.current?.scrollIntoView({behavior: "smooth"});
    }, [scrollToRef, page, role, roleFocus, account, businessTarget]);

    useEffect(() => {
        const getChannelPartners = async () => {
            setLoadingChannelPartners(true);

            await axios.get(organisationHostname + "organisation/vendors")
                .then(r => setChannelPartners(r.data))
                .finally(() => setLoadingChannelPartners(false));
        };

        getChannelPartners();
    }, []);

    const goToDashboard = () => {
        history.push("/");
    };

    useEffect(() => {
        switch (page) {
            case 0:
                setEnableContinueCallback(true);
                break;
            case 1:
                setEnableContinueCallback(role !== valueUnspecified);
                break;
            case 2:
                setEnableContinueCallback(showSave());
                break;
            case 3:
                setSaving(true);
                let data = {
                    role: role,
                    roleFocus: roleFocus,
                    account: account,
                    businessTarget: businessTarget,
                    isicLocationFilter: JSON.stringify(tree)
                };
                axios.post(frontEndHostName + "onboarding/save", data)
                    .catch(() => setError(true))
                    .finally(() => setSaving(false))
                break;
        }
    }, [page, role, roleFocus, account, businessTarget]);


    useEffect(() => {

        if (onboardingSettings?.role) {
            setRole(onboardingSettings.role)
        }

        if (onboardingSettings?.roleFocus){
            setRoleFocus(onboardingSettings.roleFocus)
        }

        if (onboardingSettings?.account){
            setAccount(onboardingSettings.account)
        }

        if (onboardingSettings?.businessTarget){
            setBusinessTarget(onboardingSettings.businessTarget)
        }


    }, [onboardingSettings])


    return (
        <>
            {loading ? <IqLoadingIcon/> :

                <Container className="ps-4 pe-4">
                    {page === 0 && (
                        <div>
                            To be able to accelerate your onboarding, and to give you more insights in the IQBlade
                            platform,
                            we'd like to ask you a series of questions. This data will only be used for the purposes of
                            setting
                            your preferences in IQBlade and not used for any other purpose.
                        </div>
                    )}
                    {page === 1 && (
                        <OnboardingQuestion
                            question="How would you define your role?"
                            name="role"
                            currentValue={role}
                            radioValues={
                                [
                                    {
                                        valueName: "SALES",
                                        text: Role.SALES,
                                        hint: "Your role is exclusively Sales focused",
                                    },
                                    {
                                        valueName: "MARKETING",
                                        text: Role.MARKETING,
                                        hint: "Your role is exclusively Marketing focused",
                                    },
                                    {
                                        valueName: "BOTH",
                                        text: Role.BOTH,
                                        hint: "You have a combined responsibility for Sales and Marketing"
                                    }
                                ]
                            }
                            onChangeCallback={(e: React.SetStateAction<string>) => {
                                setRole(e);
                            }}
                            saveLocationOrISIC={setTree}
                        />
                    )}

                    {page === 2 && (
                        <div>
                            <OnboardingQuestion
                                question="What kind of companies do you sell to?"
                                name="roleFocus"
                                currentValue={roleFocus}
                                radioValues={
                                    [
                                        {
                                            valueName: "CHANNEL",
                                            text: RoleFocus.CHANNEL,
                                            hint: "You only work with Channel partners and resellers"
                                        },
                                        {
                                            valueName: "END_USERS",
                                            text: RoleFocus.END_USERS,
                                            hint: "You only work with End User business accounts who consume/buy technology"
                                        },
                                        {
                                            valueName: "BOTH",
                                            text: RoleFocus.BOTH,
                                            hint: "You work with both Channel organisations and End User accounts"
                                        }
                                    ]
                                }
                                onChangeCallback={setRoleFocus}
                                saveLocationOrISIC={setTree}
                            />
                            <OnboardingQuestion
                                question="Is your role focused on managing existing account or recruiting new clients/partners?"
                                name={account}
                                currentValue={account}
                                radioValues={
                                    [
                                        {
                                            valueName: "DEDICATED_ACCOUNTS",
                                            text: Account.DEDICATED_ACCOUNTS,
                                            hint: "You have a set group of companies you look after which doesn't change"
                                        },
                                        {
                                            valueName: "NEW_BUSINESS",
                                            text: Account.NEW_BUSINESS,
                                            hint: "You have to find new clients to work with, so finding new businesses is important to your role"
                                        },
                                        {
                                            valueName: "BOTH",
                                            text: Account.BOTH,
                                            hint: "You work with existing accounts, as well as having a responsibility to find new companies to work with"
                                        }
                                    ]
                                }
                                onChangeCallback={setAccount}
                                saveLocationOrISIC={setTree}
                            />

                            {(account === "NEW_BUSINESS" || account === "BOTH") && (
                                <OnboardingQuestion
                                    question="Are the accounts you are looking to target based on geography or sector?"
                                    name="businessTarget"
                                    currentValue={businessTarget}
                                    radioValues={
                                        [
                                            {
                                                valueName: "GEOGRAPHY",
                                                text: BusinessTarget.GEOGRAPHY,
                                                hint: "You target companies in a specific: Country, Region, City or Postcode"
                                            },
                                            {
                                                valueName: "SECTOR",
                                                text: BusinessTarget.SECTOR,
                                                hint: "You target companies based on the Sector or Industry classification of that company"
                                            }
                                        ]
                                    }
                                    onChangeCallback={setBusinessTarget}
                                    saveLocationOrISIC={setTree}
                                />
                            )}
                        </div>
                    )}

                    {page === 3 &&
                        <OnboardingChannelPartners partners={channelPartners} continueCallback={continueCallback}
                                                   showWelcome={showWelcome}/>}
                    {page === 4 && <OnboardingCustomers continue={continueCallback}/>}
                    {page === 5 && <OnboardingCompetitors continue={continueCallback}/>}
                    {page === 6 && <OnboardingInterests continue={continueCallback}/>}
                    {page === 7 &&
                        <OnboardingThankYou continue={goToDashboard} role={role} roleFocus={roleFocus} account={account}
                                            businessFocus={businessTarget}/>}

                    {saving && <IqLoadingIcon/>}
                    {error && (
                        <Alert variant="danger">
                            {translate("errors.generic")}
                        </Alert>
                    )}

                </Container>
            }
        </>
    )
}

export default OnboardingPreferences;