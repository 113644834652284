import React, {useEffect, useState} from 'react';
import MainNavbar from './common/MainNavbar'
import './App.css';
import MainContainer from './common/MainContainer';
import MainSidebar from './common/MainSidebar';
import {BrowserRouter as Router} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import LoginContext from '../context/LoginContext';
import SubmenuContext from '../context/SubmenuContext';
import {
    getFingerprint,
    getUserFirstName,
    getUserLangKey,
    getUserLastName,
    getUserUuid,
    getUserMail,
    getUserRoles,
    getUserToken,
    setupInterceptors
} from '../utils/Security';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {useMediaQuery} from 'beautiful-react-hooks';
import "fontsource-roboto/500.css";
import "fontsource-roboto";
import {IntlProvider} from "react-intl";
import {flattenMessages, messages} from "../i18n/translate";
import I18nContext from '../context/I18nContext';
import Cookies from "universal-cookie";
import SearchContext from '../context/SearchContext';
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import StompSessionProvider from '../providers/StompSessionProvider';
import {QueryClient, QueryClientProvider} from 'react-query';
import {hotjar} from "react-hotjar";
import axios from 'axios';
import { organisationHostname, IQBLADE_HOSTNAME } from '../utils/Configuration';

declare global {
    interface Window { ga: any; }
}


const App: React.FC = () => {

    const getAuthStatus = () => {
        return localStorage.getItem("login") === null ? false : true
    };

    const getCookiesAcceptedStatus = () => {
        return localStorage.getItem("cookiesAccepted") === null ? "notYet" : localStorage.getItem("cookiesAccepted")
    };

    const isVerySmall = useMediaQuery('(max-width: 990px)');
    const isSmall = useMediaQuery('(max-width: 1200px)');
    const isLarge = useMediaQuery('(min-width: 1200px)');

    const [cookiesAccepted, setCookiesAccepted] = useState(getCookiesAcceptedStatus());

    const [logged, setLogged] = useState(getAuthStatus());
    const [interceptorExists, setInterceptorExists] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [submenuTitle, setSubmenuTitleState] = useState("");
    const [submenuItems, setSubmenuItemsState] = useState([]);

    const setSubmenuTitle = (title: string) => setSubmenuTitleState(title);
    const setSubmenuItems = (items: []) => setSubmenuItemsState(items);

    useEffect(() => {
        if (localStorage.getItem("login") !== null) {
            setupInterceptors(setLogged);
            setInterceptorExists(true);
        } else {
            setInterceptorExists(true);
        }
    }, [logged]);

    useEffect(() => {
        if (cookiesAccepted === "yes") {
            injectGoogleAnalytics()
            // injectHotjar()
        }
    }, [cookiesAccepted]);

    const acceptCookies = (accepted: boolean) => {
        if (accepted) {
            localStorage.setItem("cookiesAccepted", "yes");
        } else {
            localStorage.setItem("cookiesAccepted", "no");
        }
        setCookiesAccepted(localStorage.getItem("cookiesAccepted"));
    }

    const initFcWidget = (): void => {
        var fcConfig: any = {
            token: '3076f043-1211-404e-8b45-968fab4aa784',
            host: 'https://wchat.freshchat.com',
            config: {
                cssNames: {
                    widget: "iqblade_fc_frame"
                }
            },
            siteId: "iqblade-preprod",
            externalId: getUserMail(),
            firstName: getUserFirstName(),
            lastName: getUserLastName(),
            email: getUserMail()
        };

        (window as any).fcWidget.init(fcConfig);
        (window as any).fcWidget.user.setProperties({
            environment: "preprod",
            roles: getUserRoles().join(",")
        });
    };

    useEffect(() => {
        FingerprintJS.load().then((fp) => fp.get()).then(result => {
            const { ...components } = result.components
            const fingerprint = FingerprintJS.hashComponents(components as any)
            localStorage.setItem("fingerprint", result.visitorId)
        })
    }, []);

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://wchat.freshchat.com/js/widget.js";
        script.async = true;

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    useEffect(() => {
        (async () => {
            while (!window.hasOwnProperty("fcWidget")) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }

            if (logged) {
                initFcWidget();
            }
            else {
                if (window.hasOwnProperty("fcWidget")) {
                    (window as any).fcWidget.destroy();
                }
            }

            return () => {
                (window as any).fcWidget.destroy();
            }
        })();
    }, [logged]);

    useEffect(() => {
        const updateLastOpen = async () => {

            await axios.put(`/api/update-last-open/` + getUserUuid())
                .then(r => {
                })
                .catch(error => {
                    if (error.response.status === 400) {
                    }
                    console.error(error.message);
                });
        }

        if(logged){
            updateLastOpen()
        }

    }, [])


    const [sideBarCollapsed, setSideBarCollapsed] = useState(false);



    useEffect(() => {
        if (isSmall) {
            setSideBarCollapsed(true);
        }
        if (isLarge) {
            setSideBarCollapsed(false);
        }
    }, [isSmall, isLarge]);

    const cookies = new Cookies();
    let locale = cookies.get("locale");
    const [lang, setLang] = useState<string>(locale ? locale : getUserLangKey());


    // const injectHotjar = () => {
    //
    //     hotjar.initialize(3007651, 6);
    //
    // // Identify the user
    //     hotjar.identify(getUserMail(), { userProperty: (getUserRoles())});
    //
    // // Add an event
    //     hotjar.event('button-click');
    //
    // // Update SPA state
    //     hotjar.stateChange('https://portal.iqblade.com/');
    // }

    const injectGoogleAnalytics = () => {
        (function (i: any, s: any, o: any, g: any, r: any) {
            let a: any;
            let m: any;
            i['GoogleAnalyticsObject'] = r;
            i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments)
            }
            i[r].l = 1 * new Date().valueOf();
            a = s.createElement(o);
            m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

        window.ga('create', 'UA-82755662-1', 'auto');
        window.ga('set', 'anonymizeIp', true);
        window.ga('send', 'pageview');
    }

    const queryClient = new QueryClient()

    if (interceptorExists) {
        return (
            <Router>

                <QueryClientProvider client={queryClient}>

                    <I18nContext.Provider value={{ lang, setLang }}>
                        <IntlProvider locale={lang} messages={flattenMessages(messages[lang])}>
                            <LoginContext.Provider value={{ logged, setLogged }}>
                                <StompSessionProvider
                                    url={"/iqws"}
                                    accessToken={getUserToken()}
                                    fingerprint={getFingerprint()}
                                    logged={logged}
                                >
                                    <SubmenuContext.Provider value={{ submenuTitle, setSubmenuTitle, submenuItems, setSubmenuItems }}>
                                        <SearchContext.Provider value={{ searchQuery, setSearchQuery }}>
                                            {logged &&
                                                // (isVerySmall === false &&
                                                <aside
                                                    className={"aside aside-fixed " + ((sideBarCollapsed && !isVerySmall) ? "minimize" : "") + ((sideBarCollapsed && isVerySmall) ? "remove" : "")}>
                                                    <div>
                                                        <MainSidebar sideBarCollapsed={sideBarCollapsed}
                                                            setSideBarCollapsed={setSideBarCollapsed} />
                                                    </div>
                                                </aside>
                                            }
                                            <div
                                                className={((isSmall || isLarge) && !isVerySmall ? "content " : (isVerySmall && !sideBarCollapsed ? "content-overlay-expanded" : "content-overlay")) + (((isSmall || isLarge) && !isVerySmall && sideBarCollapsed) ? "content-minimized" : "")}
                                                onClick={() => (!sideBarCollapsed && isVerySmall) && setSideBarCollapsed(true)}
                                                style={{ height: "100vh" }}>
                                                <Container fluid className="h-100 d-flex w-100" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                    <Row className="h-100 flex-grow-1" style={{ marginLeft: 0, marginRight: 0 }}>
                                                        <Col className="flex-column d-flex" id="page-content-wrapper">
                                                            {logged &&

                                                                <Row id="main-navbar-row" style={{ marginLeft: 0, marginRight: 0 }}>
                                                                    <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                        <MainNavbar sideBarCollapsed={sideBarCollapsed}
                                                                            setSideBarCollapsed={setSideBarCollapsed} />
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            <Row className="flex-grow-1" style={{ marginLeft: 0, marginRight: 0 }}>
                                                                <Col style={{ padding: 0 }}>
                                                                    <MainContainer logged={logged} setLogged={setLogged}></MainContainer>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </div>
                                        </SearchContext.Provider>
                                    </SubmenuContext.Provider>
                                </StompSessionProvider>
                            </LoginContext.Provider>
                            {cookiesAccepted === "notYet" &&
                                <div className="accept-cookies">
                                    <span className="text">We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.</span>
                                    <span className="actions"><Button variant="secondary" onClick={() => acceptCookies(false)}>Deny</Button><Button onClick={() => acceptCookies(true)}>Accept</Button></span>
                                </div>}
                        </IntlProvider>
                    </I18nContext.Provider>
                </QueryClientProvider>
            </Router>
        );
    } else {
        return (<></>);
    }
}

export default App;
