import React, {useEffect, useState} from 'react';
import {faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {Button, Form} from "react-bootstrap";
import translate from "../../i18n/translate";
import IqSmallLoadingIcon from "../common/IqSmallLoadingIcon";
import Card from "./ProfileCard";
import {socialHostName} from "../../utils/Configuration";
import axios from "axios";
import UserSocialHandles from "./UserSocialHandles";
import {getUserUuid} from "../../utils/Security";

interface ChangeAvatarFormProps {
    setUserCallback: Function;
    setSuccessCallback: Function;
    setErrorCallback: Function;
}

const ChangeAvatarForm: React.FC<ChangeAvatarFormProps> = ({setUserCallback, setSuccessCallback, setErrorCallback}) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [avatarSource, setAvatarSource] = useState<"device" | "twitter" | null>(null);
    const [avatarBase64, setAvatarBase64] = useState<string>();
    const [socialHandles, setSocialHandles] = useState<UserSocialHandles>({} as UserSocialHandles);

    useEffect(() => {
        if (getUserUuid() != null) {
            const fetchSocialHandles = async () => {
                await axios.get(socialHostName + "user/social-handles")
                    .then((response) => setSocialHandles(response.data))
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                    })
            };

            fetchSocialHandles();
        }

    }, [])

    const uploadAvatar = (e: any) => {
        e.preventDefault();
        const endpoint = [socialHostName, 'user/settings/update-avatar', "?twitter=", avatarSource === "twitter"].join("")
        setLoading(true);
        axios.post(endpoint, avatarSource === "device" ? avatarBase64 : null,
            {
                headers: {
                    'Content-Type': 'text/plain'
                }
            })
            .then((response) => {
                setSuccessCallback(true)
                setUserCallback(response.data)
            })
            .catch(() => {
                setErrorCallback(true);
            })
            .finally(() => setLoading(false));
    }

    const handleAvatarFileInput = (e: any) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function () {
                setAvatarBase64(reader.result as string)
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }

    return (
        <Card heading={"Change Avatar"} iconDefinition={faUserCircle}>
            <Form style={{marginTop: 15}}>
                <Form.Check
                    disabled={!socialHandles?.twitter}
                    type={"radio"}
                    label={translate("profile.avatartwitter")}
                    name="avatarSource"
                    onChange={(e: any) => e.target.value ? setAvatarSource("twitter") : setAvatarSource(null)}
                    checked={avatarSource === "twitter"}
                />
                <Form.Check
                    type={"radio"}
                    label={translate("profile.avatardevice")}
                    name="avatarSource"
                    onChange={(e: any) => e.target.value ? setAvatarSource("device") : setAvatarSource(null)}
                    checked={avatarSource === "device"}
                />
                {avatarSource === "device" &&
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>{translate("profile.chooseavatar")}</Form.Label>
                        <Form.Control type="file" onChange={(e: any) => handleAvatarFileInput(e)} accept=".png"/>
                    </Form.Group>}
                {loading ? <IqSmallLoadingIcon/>
                    :
                    (avatarSource === "twitter" || avatarBase64) &&
                    <Button style={{marginLeft: "auto", marginTop: 15, float: "right"}}
                            variant="primary"
                            type="submit"
                            onClick={(e: any) => uploadAvatar(e)}>
                        Save
                    </Button>
                }
            </Form>
        </Card>
    )
}

export default ChangeAvatarForm;