import {Button, Modal} from "react-bootstrap";
import React, {useEffect} from "react";
import {organisationHostname} from "../../utils/Configuration";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import "./OrganisationTeckStackModal.css"
import {getUserToken} from "../../utils/Security";


interface OrgTechStackModal {
    showRequestContactsModal: any,
    setShowRequestContactsModal: (show: boolean) => void,
    handleClose: () => void,
    category: string,
    organisationId: string
}

const OrganisationTechStackModal: React.FC<OrgTechStackModal> = (props: OrgTechStackModal) => {

        const Graph = () => {
            useEffect(() => {
                am4core.useTheme(am4themes_animated);
                var chart = am4core.create("techstack-graph", am4charts.XYChart);

                //configure chart
                chart.dateFormatter.dateFormat = "dd-MM-yyyy";

                //data source url for chart
                chart.dataSource.url = organisationHostname + "organisation/tech-stack/intensity?organisationId=" + props.organisationId + "&category=" + encodeURIComponent(props.category);
                chart.dataSource.requestOptions.requestHeaders = [{
                    "key": "Authorization",
                    "value": "Bearer "+ getUserToken()
                }]
                chart.events.on("beforedatavalidated", function(ev) {
                    let source = ev.target.data;
                    let data: any[] = [];
                    let products: any[] = [];

                    if (source.length <= 0) return;
                    source = source[0];

                    for (let date in source) {
                        let curData = source[date];
                        for (let i = 0; i < curData.length; i ++) {
                            data.push({
                                date,
                                ['intensity-'+curData[i].product]: curData[i].intensity
                            })
                            if (products.indexOf(curData[i].product) == -1) {
                                products.push(curData[i].product);
                            }
                        }
                    }
                    data.sort(
                        function(obj1, obj2) {
                            let date1 = new Date(obj1.date);
                            let date2 = new Date(obj2.date);
                            if (date1 > date2) return 1;
                            else if (date1 < date2) return -1;
                            return 0;
                        }
                    );

                    for (let proIndex in products) {
                        let product = products[proIndex];
                        //configure series
                        const chart = ev.target;
                        let series = chart.series.push(new am4charts.LineSeries());
                        series.name = product;
                        series.dataFields.valueY = "intensity-"+product;
                        series.dataFields.dateX = "date";
                        series.strokeWidth = 3;
                        series.sequencedInterpolation = true;
                        let bullet1 = series.bullets.push(new am4charts.CircleBullet());
                        series.heatRules.push({
                            target: bullet1.circle,
                            min: 5,
                            max: 20,
                            property: "radius"
                        });
                        bullet1.tooltipText = "Date: {dateX}\nProduct: {name}\nIntensity: {valueY}";
                    }

                    ev.target.data = data;
                });

                //create two axes
                let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                dateAxis.dataFields.date = "date";
                dateAxis.title.text = "Date";
                dateAxis.groupData = true;

                let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.title.text = "Intensity";


                chart.legend = new am4charts.Legend();
                chart.legend.scrollable = true;
                chart.legend.maxHeight = 150;

                return () => {
                    chart.dispose()
                }
            });
            return (
                <div id="techstack-graph"/>
            );
        };


        return (
            <Modal show={props.showRequestContactsModal} onHide={props.handleClose}
                   dialogClassName="techstack-modal" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Tech Stack Intensity Graph</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Graph/>
                </Modal.Body>
                <Modal.Footer>
                    <>
                        <Button variant="secondary" onClick={props.handleClose}>
                            Close
                        </Button>
                    </>
                </Modal.Footer>
            </Modal>
        );
    }
;
export default OrganisationTechStackModal;