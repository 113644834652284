import {Hint, HorizontalGridLines, LineMarkSeries, XAxis, XYPlot, YAxis} from "react-vis";
import React, {useState} from "react";
import {AutoSizer} from "react-virtualized";
import {format} from "d3-format";

interface Props {
    data: any[],
    colour: string,
    title: string,
    formatValue: (value: string) => {},
    yAxisFormat: (value: any) => string
}

const OrganisationLineSeriesChart: React.FC<Props> = (props: Props) => {

    const [hover, setHover] = useState<any>();

    const xMin = props.data.reduce((min: number, p: { x: number; }) => p.x < min ? p.x : min, props.data[0].x);
    const xMax = props.data.reduce((max: number, p: { x: number; }) => p.x > max ? p.x : max, props.data[0].x);

    return (
        <AutoSizer>
            {({ height, width }) => (
                <XYPlot width={width}
                        margin={{left: 50}}
                        height={300}
                        xDomain={[xMin, xMax]}>

                    <HorizontalGridLines />
                    <LineMarkSeries
                        getNull={(d) => d.y != null}  //only draw if different than null
                        color={props.colour}
                        data={props.data}
                        onValueMouseOver={(v: any) => {
                            setHover(v);
                        }}
                        onValueMouseOut={(v: any) => {
                            setHover(null);
                        }} />

                    <XAxis tickFormat={val => Math.round(val) === val ? val : ""} title="Year" />
                    <YAxis tickFormat={tick => format('.2s')(tick).replace(/G/, "B")} title={props.title} />

                    {hover && (
                        <Hint value={hover}>
                            <div className="chart-hint">
                                <div className="x">{hover.x}</div>
                                <div className="y">{(props.formatValue(hover.y) as String)?.replace(/G/, "B")}</div>
                            </div>
                        </Hint>
                    )}
                </XYPlot>
            )}
        </AutoSizer>
    );
};

export default OrganisationLineSeriesChart;
