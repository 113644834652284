import React, {useContext, useEffect, useState} from "react";
import TeamContext from "../../context/teams/TeamContext";
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import OverviewSummary from "./OverviewSummary";
import axios from "axios";
import List from "../../model/list/List";
import {frontEndHostName} from "../../utils/Configuration";
import IqLoadingIcon from "../common/IqLoadingIcon";
import "./Teams.css";
import {matchPath, useLocation} from "react-router-dom";
import {ListEvent} from "../lists/activity/ListActivity";
import DateFormatter from "../../utils/formatters/DateFormatter";
import EventType from "../lists/activity/EventType";
import translate from "../../i18n/translate";
import UserAvatar from "../common/UserAvatar";

const TeamOverview: React.FC = () => {
    const { team, setTeam } = useContext(TeamContext);

    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/team/:teamId',
        exact: false,
        strict: false
    });

    const [lists, setLists] = useState<List[]>([]);
    const [activity, setActivity] = useState<ListEvent[]>([]);
    const [totalLogins, setTotalLogins] = useState<number>(0);
    const [totalSearches, setTotalSearches] = useState<number>(0);
    const [loadingLists, setLoadingLists] = useState<boolean>(false);
    const [loadingActivity, setLoadingActivity] = useState<boolean>(false);
    const [loadingLogins, setLoadingLogins] = useState<boolean>(false);
    const [loadingSearches, setLoadingSearches] = useState<boolean>(false);

    useEffect(() => {

        let baseUrl = frontEndHostName + "teams/" + childMatchedPath?.params.teamId;
        const fetchLists = async () => {
            setLoadingLists(true);
            await axios.get(baseUrl + "/user-lists")
                .then(r => {
                    setLists(r.data as List[]);
                })
                .catch(error => console.error(error.message))
                .finally(() => setLoadingLists(false));
        };

        const fetchActivity = async () => {
            setLoadingActivity(true);
            await axios.get(baseUrl + "/activity")
                .then(r => {
                    setActivity(r.data as ListEvent[]);
                })
                .catch(error => console.error(error.message))
                .finally(() => setLoadingActivity(false));
        };

        const fetchLogins = async () => {
            setLoadingLogins(true);
            await axios.get(baseUrl + "/logins")
                .then(r => {
                    setTotalLogins(r.data as number);
                })
                .catch(error => console.error(error.message))
                .finally(() => setLoadingLogins(false));
        };

        const fetchSearches = async () => {
            setLoadingSearches(true);
            await axios.get(baseUrl + "/searches")
                .then(r => {
                    setTotalSearches(r.data as number);
                })
                .catch(error => console.error(error.message))
                .finally(() => setLoadingSearches(false));
        };

        fetchLists();
        fetchActivity();
        fetchLogins();
        fetchSearches();
    }, []);

    const isLoading = () => {
        return loadingLists || loadingActivity || loadingSearches || loadingLogins;
    };

    const getAction = (type: string) => {
        let eventType = EventType.ALL_TYPES[type];

        if (eventType === EventType.NEW_COMPANY) {
            return "teams.overview.addedcompanies";
        }
        else if (eventType === EventType.REMOVED_COMPANY) {
            return "teams.overview.removedcompanies";
        }
        else {
            return "teams.overview.commentedon";
        }
    };

    return (
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 35, maxHeight: 300 }}>
            <div className="row" style={{ maxHeight: "80%" }}>
                {isLoading() ? <IqLoadingIcon /> : (
                    <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                        <OverviewSummary totalLists={lists.length} totalLogins={totalLogins} totalSearches={totalSearches} />

                        <div className="team-overview-card mt-3">
                            <div className="organisation-card-label">
                                {translate("teams.manage.usersinteam")}
                            </div>
                            <div className="users-team-flex-container">
                                {team?.users?.map(user => (
                                    <div className="profile">
                                        <UserAvatar user={user} height="70px" width="70px" />
                                        <div className="title">{user.firstName} {user.lastName}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" style={{ maxHeight: "60%", overflowY: "scroll" }}>
                    <div className="team-overview-card mb-2">
                        <div className="organisation-card-label">
                            {translate("teams.overview.recentactivity")}
                        </div>
                        <div>
                            {loadingActivity ? <IqLoadingIcon /> : (
                                <ol className="team-activity-feed">
                                    {activity.map(event => (
                                        <li className="item">
                                            <div className="date">{DateFormatter.formatMillis(event.created)}</div>
                                            <div className="avatar">
                                                <UserAvatar user={event.user} height="50px" width="50px" />
                                            </div>
                                            <span className="text">
                                                {event.user.firstName} {event.user.lastName} {translate(getAction(event.type))} <a href={`/lists/${event.listId}`}>{event.listName}</a>
                                            </span>
                                        </li>
                                    ))}
                                    <div>
                                        {activity.length === 0 && (
                                            <div className="pt-2 pb-1">No recent activity to show</div>
                                        )}
                                    </div>
                                </ol>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div className="team-overview-card">
                        <div className="organisation-card-label">
                            {translate("lists.title")}
                        </div>
                        <div>
                            {loadingLists ? <IqLoadingIcon /> : (
                                <div style={{ maxHeight: 600, overflowY: "scroll", overflowX: "hidden" }}>
                                    {lists.map(list => (
                                        <Row key={list.id} className="mb-2 pt-1 pb-1 pe-2">
                                            <Col>
                                                <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                                    <div className="team-overview-list">
                                                        {list.title}
                                                    </div>
                                                    <div>
                                                        Shared by {list.userDisplayName}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col>
                                                <a className="iq-button iq-button-tertiary pull-right"
                                                    href={`/lists/${list.id}`}>
                                                    {translate("lists.viewlist")}
                                                </a>
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default TeamOverview;
