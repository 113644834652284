import React, {useEffect, useState} from "react";
import axios from "axios";
import {organisationHostname} from "../../utils/Configuration";
import TechDataSalesAggregate from "../../model/techsales/TechDataSalesAggregate";
import {Col, Row} from "react-bootstrap";
import {AutoSizer} from "react-virtualized";
import {DiscreteColorLegend, Hint, RadialChart} from "react-vis";
import {COLOURS} from "./TechSales";
import IqLoadingIcon from "../common/IqLoadingIcon";

const SalesByCategory: React.FC = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [techSalesByYear, setTechSalesByYear] = useState<any>({});

    useEffect(() => {
        const fetchSalesByManufacturer = async () => {
            setLoading(true);

            await axios.get(organisationHostname + "tech-sales/category")
                .then(r => setTechSalesByYear(r.data))
                .catch(e => setError(true))
                .finally(() => setLoading(false))
        };

        fetchSalesByManufacturer();
    }, []);

    const [hoverKey, setHoverKey] = useState<any>();
    const [hover, setHover] = useState<any>();

    const formatValue = (key: string, label: string) => {
        let value = techSalesByYear[key]?.find((item: TechDataSalesAggregate) => item.label === label)?.totalRevenueLocal;

        if (value) {
            return "£" + value.toLocaleString();
        }
    };

    return (
        <div>
            <div style={{ paddingTop: "10px", padding: 15, marginTop: 10, minHeight: 400 }}>
                {loading && <IqLoadingIcon />}
                <Row>
                    {Object.keys(techSalesByYear).map(key => (
                        <Col>
                            <div className="organisation-card" style={{ minHeight: 400 }}>
                                <div className="organisation-card-label">
                                    {key}
                                </div>
                                <AutoSizer>
                                    {({height, width}) => (
                                        <div style={{ display: "flex", flexDirection: "row", height: height, width: width, justifyContent: "center", alignItems: "center" }}>
                                            <RadialChart
                                                angleDomain={[0, 100]}
                                                data={techSalesByYear[key]?.map((item: TechDataSalesAggregate, index: number) => {
                                                    return {
                                                        angle: item.totalRevenueLocal,
                                                        label: item.label,
                                                        color: COLOURS[index]
                                                    }
                                                })}
                                                onValueMouseOver={(v: any) => {
                                                    setHover(v);
                                                    setHoverKey(key);
                                                }}
                                                onValueMouseOut={(v: any) => {
                                                    setHover(null);
                                                    setHoverKey(null);
                                                }}
                                                width={width * 0.7}
                                                height={300}
                                                colorType="literal"
                                            >
                                                {(hover && hoverKey === key) && (
                                                    <Hint value={hover}>
                                                        <div style={{
                                                            color: 'white',
                                                            backgroundColor: 'black',
                                                            opacity: 0.9,
                                                            alignItems: 'center',
                                                            padding: '5px',
                                                            borderRadius: '5px',
                                                            border: '2px solid',
                                                            fontWeight: 500
                                                        }}>
                                                            <div>{hover?.label}</div>
                                                            <div>
                                                                {formatValue(key, hover.label)}
                                                            </div>
                                                        </div>
                                                    </Hint>
                                                )}
                                            </RadialChart>
                                            <DiscreteColorLegend
                                                height={300}
                                                orientation="vertical"
                                                width={width * 0.3}
                                                items={techSalesByYear[key]?.map((item: any, index: number) => {
                                                    return {
                                                        title: item.label,
                                                        color: COLOURS[index],
                                                        strokeWidth: 25
                                                    }
                                                })}
                                            />
                                        </div>
                                    )}
                                </AutoSizer>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
};

export default SalesByCategory;