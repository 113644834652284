import React, {useContext, useEffect, useRef, useState} from 'react';
import {matchPath, Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import UserListContext from "../../context/list/UserListContext";
import List from "../../model/list/List";
import SubmenuContext from "../../context/SubmenuContext";
import {faAddressBook, faBriefcase, faChartPie, faLaptop, faList, faNewspaper, faRss} from "@fortawesome/free-solid-svg-icons";
import ListDashboard from "./dashboard/ListDashboard";
import ListActivity from "./activity/ListActivity";
import axios from "axios";
import UserListSummary from "./home/UserListSummary";
import UserListHome from "./home/UserListHome";
import {frontEndHostName} from "../../utils/Configuration";
import IqLoadingIcon from "../common/IqLoadingIcon";
import PageNotFound from "../common/PageNotFound";
import UserListNews from "./news/UserListNews";
import ScrollPaginationContext from "../../context/ScrollPaginationContext";
import UserListSocial from "./social/UserListSocial";
import UserListContacts from "./contacts/UserListContacts";
import UserListJobs from './jobs/UserListJobs';

const UserListContainer: React.FC = () => {
    const match = useRouteMatch();
    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/lists/:listId',
        exact: false,
        strict: false
    });

    const [list, setList] = useState<List>({} as List);
    const [loading, setLoadingState] = useState<boolean>(false);
    const setLoading = (isLoading: boolean) => {
        setLoadingState(isLoading);
    };

    const {setSubmenuTitle, setSubmenuItems} = useContext(SubmenuContext);

    const [error, setError] = useState<number>();
    const fetchList = async () => {
        setLoading(true);

        await axios.get(frontEndHostName + "user-lists/" + childMatchedPath?.params.listId)
            .then(r => successHandler(r.data as List))
            .catch(error => setError(error.response.status))
            .finally(() => setLoading(false));
    };

    const successHandler = (l: List) => {
        setList(l);

        setSubmenuTitle(l.title);
        let submenuItems = [
            {
                name: "lists.overview",
                url: "/lists/" + childMatchedPath?.params.listId,
                icon: faList
            },
            {
                name: "lists.dashboard",
                url: "/lists/" + childMatchedPath?.params.listId + "/dashboard",
                icon: faChartPie
            },
            {
                name: "lists.contacts",
                url: "/lists/" + childMatchedPath?.params.listId + "/contacts",
                icon: faAddressBook
            },
            {
                name: "lists.jobs",
                url: "/lists/" + childMatchedPath?.params.listId + "/jobs",
                icon: faBriefcase
            },
            {
                name: "lists.listactivity",
                url: "/lists/" + childMatchedPath?.params.listId + "/activity",
                icon: faLaptop
            },
            {
                name: "news.title",
                url: "/lists/" + childMatchedPath?.params.listId + "/news",
                icon: faNewspaper
            },
            {
                name: "organisation.social.title",
                url: "/lists/" + childMatchedPath?.params.listId + "/social",
                icon: faRss         
            }
        ];

        setSubmenuItems(submenuItems);
    };

    useEffect(() => {
        fetchList();

        return () => {
            setSubmenuTitle("");
            setSubmenuItems([]);
        }
    }, []);


    const [showTransferModal, setShowTransferModalState] = useState<boolean>(false);
    const [showRequestContactsModal, setShowRequestContactsModal] = useState<boolean>(false);
    const [showDataQualityModal, setShowDataQualityModal] = useState<boolean>(false);
    const [showJobsModal, setShowJobsModal] = useState<boolean>(false);

    const setShowTransferModal = (show: boolean) => setShowTransferModalState(show);

    let scroller = useRef<any>();
    const [page, setPageState] = useState<number>(0);
    const setPage = (p: number) => {
        setPageState(p);
    };

    const handleScroll = () => {
        if (!loading && !error) {
            let maxScroll = scroller.current.scrollHeight - scroller.current.offsetHeight;
            let currentScroll = scroller.current.scrollTop;

            if (currentScroll === maxScroll) {
                setPage(page + 1);
            }
        }
    };

    return (
        <ScrollPaginationContext.Provider value={{ page, setPage }}>
            <UserListContext.Provider value={{list, setList, loading, setLoading, showTransferModal, setShowTransferModal,
            showRequestContactsModal, setShowRequestContactsModal, showDataQualityModal, setShowDataQualityModal, showJobsModal, setShowJobsModal}}>
                <div className="container-div-without-scroll" ref={scroller} onScroll={handleScroll}>
                    {loading ? <IqLoadingIcon /> : (
                        <div>
                            {!error ? (
                                <div className="user-list-data">
                                    <UserListSummary/>
                                    <Switch>
                                        <Route exact path={`${match.url}/:listId`} component={UserListHome} />
                                        <Route path={`${match.url}/:listId/dashboard`} component={ListDashboard} />
                                        <Route path={`${match.url}/:listId/activity`} component={ListActivity} />
                                        <Route path={`${match.url}/:listId/contacts`} component={UserListContacts} />
                                        <Route path={`${match.url}/:listId/jobs`} component={UserListJobs} />
                                        <Route path={`${match.url}/:listId/news`} component={UserListNews} />
                                        <Route path={`${match.url}/:listId/social`} component={UserListSocial} />
                                    </Switch>
                                </div>
                            ) : (
                                <PageNotFound />
                            )}
                        </div>
                    )}
                </div>
            </UserListContext.Provider>
        </ScrollPaginationContext.Provider>
    );
};

export default UserListContainer;
