import React, {useContext} from 'react';
import './MainSidebar.css';
import {Link, useHistory, useLocation} from "react-router-dom";
import {Nav, NavItem} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faAddressBook,
    faBullhorn,
    faChartLine,
    faCrown,
    faFileInvoiceDollar,
    faGraduationCap,
    faHome,
    faLandmark,
    faList,
    faNewspaper,
    faRedo,
    faSearch,
    faSignOutAlt,
    faUsers,
    faBriefcase,
    IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import {useMediaQuery} from 'beautiful-react-hooks';
import SubmenuContext from '../../context/SubmenuContext';
import {hasTeamsPermission} from "./MainContainer";
import IqCopyright from './IqCopyright';
import translate from "../../i18n/translate";
import {hasUserRole, Role} from '../../utils/Security';
import {faHubspot} from "@fortawesome/free-brands-svg-icons";

interface Props {
    sideBarCollapsed: boolean,
    setSideBarCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}

const MainSidebar: React.FC<Props> = (props: Props) => {

    const isVerySmall = useMediaQuery('(max-width: 990px)');
    const isSmall = useMediaQuery('(max-width: 1200px)');
    const isLarge = useMediaQuery('(min-width: 1200px)');

    const { submenuTitle, submenuItems } = useContext(SubmenuContext);
    const location = useLocation();

    let defaultMenu = [
        {
            name: "Home Feed",
            url: "/dashboard",
            icon: faHome
        },
        {
            name: translate("lists.title"),
            url: "/lists",
            icon: faList
        },
        {
            name: translate("news.title"),
            url: "/news",
            icon: faNewspaper
        },
        {
            name: translate("advancedsearch.title"),
            url: "/advanced-search",
            icon: faSearch
        },
        {
            name: "People",
            url: "/people",
            icon: faAddressBook
        },
        {
            name: translate("marketintelligence.title"),
            url: "/marketintelligence/investments",
            icon: faGraduationCap
        }
    ];

    if (hasUserRole(Role.GEO_UK)) {
        defaultMenu.push({
            name: translate("contracts.nav"),
            url: "/public-sector/contracts",
            icon: faLandmark
        });
    }

    
    if (hasUserRole(Role.CAMPAIGN_VIEWER)) {
        defaultMenu.push(
            {
                name: translate("campaigns.nav"),
                url: "/campaigns",
                icon: faBullhorn
            }
        );
    }

    if (hasUserRole(Role.CAMPAIGN_CREATOR) || hasUserRole(Role.CAMPAIGN_SUPERADMIN)) {
        defaultMenu.push(
            {
                name: translate("campaigns.manager"),
                url: "/campaigns-manager",
                icon: faCrown
            }
        );
    }

    if (hasUserRole(Role.HUBSPOT)) {
        defaultMenu.push(
            {
                name: "HubSpot",
                url: "/hubspot",
                icon: faHubspot
            }
        )
    }

    if (hasUserRole(Role.TD_SALES)) {
        defaultMenu.push({
            name: translate("techsales.nav"),
            url: "/tech-sales",
            icon: faFileInvoiceDollar
        });
    }

    if (hasUserRole(Role.TD_RENEWALS)) {
        defaultMenu.push({
            name: translate("renewals.nav"),
            url: "/renewals",
            icon: faRedo
        });
    }

    defaultMenu.push({
        name: translate("signout"),
        url: "/logout",
        icon: faSignOutAlt
    });

    if (hasTeamsPermission()) {
        defaultMenu.splice(2, 0, {
            name: translate("teams.title"),
            url: "/teams",
            icon: faUsers
        });
    }

    interface SideBarGenericButtonProps {
        to: any,
        type: IconDefinition
    }
    const SideBarGenericButton: React.FC<SideBarGenericButtonProps> = (props: SideBarGenericButtonProps) => {
        return (
            <NavItem style={{ marginBottom: 15 }}>
                <Link to={props.to} className="nav-link">
                    <FontAwesomeIcon className="sidebar-button" icon={props.type} color="black" size="lg" />
                </Link>
            </NavItem>
        )
    };

    const isActive = (url: string) => {
        if (url.indexOf("public-sector-organisation") !== -1) {
            return location.pathname.indexOf("public-sector-organisation") !== -1;
        }

        if ((url.indexOf("people") !== -1) && !(url.indexOf("organisation/people") !== 1) ){
            return location.pathname.indexOf("people") !== -1;
        }

        return url === location.pathname;
    };

    function SideBarSmallLogo() {
        const history = useHistory();
        function handleHomeLogoClick() {
            history.push("/dashboard");
        }

        return (
            <div id="sidebar-logo-small" style={{ cursor: "pointer"}} onClick={handleHomeLogoClick}>
                <img className="sidebar-logo-small-styling" src="/logo-small.png" alt="logo" style={{
                    minWidth: "30px",
                    minHeight: "30px",
                    height: "45px",
                    width: "45px"
                }} />
            </div>
        );
    }

    function SideBarLargeLogo() {
        const history = useHistory();
        function handleHomeLogoClick() {
            history.push("/dashboard");
        }

        return (
            <div id="sidebar-logo" style={{ cursor: "pointer"}} onClick={handleHomeLogoClick}>
                <img src="/logo-large.png" alt="logo" />
            </div>
        );
    }

    const getSubmenuItemTitle = (item: string) => {
        if (item.indexOf(".") !== -1) {
            return translate(item);
        }
        return item;
    };

    const SideMenuExpanded = () => {
        return (
            <div className="aside-body">
                <Nav className="nav nav-aside"
                    activeKey="/dashboard">
                    <NavItem>
                        <SideBarLargeLogo />
                    </NavItem>
                </Nav>
                <hr />
                <div className="nav-content">
                    <div className="nav-content-submenu">
                        <Nav className="nav nav-aside"
                            activeKey="/dashboard">
                            <NavItem>
                                <span className="nav-label">{submenuTitle}</span>
                            </NavItem>
                            {submenuItems.map((item: any, index: any) => {
                                return (
                                    <NavItem key={index}>
                                        <Link to={item.url} className={isActive(item.url) ? "nav-link active" : "nav-link"}>
                                            <FontAwesomeIcon style={{ marginRight: 15, width: 15 }} icon={item.icon} />
                                            <span>{getSubmenuItemTitle(item.name)}</span>
                                        </Link>
                                    </NavItem>
                                )
                            })}
                        </Nav>
                        <Nav className="nav nav-aside"
                            activeKey="/dashboard">
                            <NavItem>
                                <span className="nav-label">Menu</span>
                            </NavItem>
                            {defaultMenu.map((item: any, index: any) => {
                                return (
                                    <NavItem key={index}>
                                        <Link to={item.url} className={isActive(item.url) ? "nav-link active" : "nav-link"}>
                                            <FontAwesomeIcon style={{ marginRight: 15, width: 15 }} icon={item.icon} />
                                            <span >{item.name}</span>
                                        </Link>
                                    </NavItem>
                                )
                            })}
                        </Nav>
                    </div>
                    <IqCopyright />
                </div>
            </div>
        );
    };

    const SideMenuCollapsed = () => {
        return (
            <div className="aside-body">
                <Nav className="nav nav-aside"
                    activeKey="/home">
                    <SideBarSmallLogo />
                    <div className="sidebar-buttons-container">
                        <SideBarGenericButton to="/dashboard" type={faHome} />
                        <SideBarGenericButton to="/lists" type={faList} />
                        <SideBarGenericButton to="/teams" type={faUsers} />
                        <SideBarGenericButton to="/marketintelligence/investments" type={faChartLine} />
                        <SideBarGenericButton to="/advanced-search" type={faSearch} />
                    </div>
                </Nav>
            </div>
        );
    };

    return (<div>
        {
            props.sideBarCollapsed
                ?
                (() => {
                    if (isSmall)
                        return <SideMenuCollapsed />
                    else if (isLarge)
                        return <SideMenuCollapsed />
                })()
                :
                (() => {
                    if (isVerySmall)
                        return <SideMenuExpanded />
                    else if (isSmall)
                        return <SideMenuExpanded />
                    else if (isLarge)
                        return <SideMenuExpanded />
                })()
        }
    </div>)
};

export default MainSidebar;
