import React, {useEffect, useState} from "react";
import axios from "axios";
import {frontEndHostName} from "../../utils/Configuration";
import {Col, Image, Row} from "react-bootstrap";
import IqLoadingIcon from "../common/IqLoadingIcon";
import "./ListNavigation.css";
import {useHistory} from "react-router-dom";
import translate from "../../i18n/translate";
import {ListOrganisation} from "../../model/organisation/ListOrganisation";

interface Props {
    listId: string,
    organisationId: string,
    sort?: string | null
}

const ListNavigation: React.FC<Props> = (props: Props) => {
    const [loadingList, setLoadingList] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [organisations, setOrganisations] = useState<ListOrganisation[]>([]);
    const [previousOrg, setPreviousOrg] = useState<ListOrganisation>();
    const [nextOrg, setNextOrg] = useState<ListOrganisation>();

    let history = useHistory();

    useEffect(() => {
        if (props.listId) {
            setLoadingList(true);
            let params: any = {};
            if (props.sort) {
                params["sort"] = props.sort;
            }

            axios.get(frontEndHostName + "user-lists/" + props.listId + "/organisations/minified", {
                params: params
            })
                .then(r => {
                    setOrganisations(r.data);
                    let i = r.data.map((o: ListOrganisation) => o.id).indexOf(props.organisationId);
                    if (i > 0) {
                        let prev = i - 1;
                        setPreviousOrg(r.data[prev]);
                    }

                    if (i < r.data.length) {
                        let next = i + 1;
                        setNextOrg(r.data[next]);
                    }
                })
                .catch(() => setError(true))
                .finally(() => setLoadingList(false));
        }
    }, []);

    const CompanyLogo: React.FC<ListOrganisation> = (org: ListOrganisation) => {
        const [src, setSrc] = useState<string>(org.imageUrl ? org.imageUrl : "./icons_company.svg");

        return (
            <Image style={{ height: "40px", width: "40px", objectFit: "cover" }}
                title={org.companyName}
                src={src}
                onError={() => setSrc("/icons_company.svg")}
                roundedCircle />
        );
    };

    const getOrganisationLink = (orgId: string) => {
        let link = `/organisation/${orgId}?list=${props.listId}`;

        if (props.sort) {
            link += "&sort=" + props.sort;
        }

        return link;
    };

    return (
        <Row className="pt-3" style={{ marginLeft: 0, marginRight: 0 }}>
            {(loadingList && !error) ? <IqLoadingIcon /> : (
                <Col>
                    <div className="list-navigation-container">
                        {previousOrg ? (
                            <a className="list-navigation-link" href={getOrganisationLink(previousOrg.id)}>

                                <div className="arrow-left">
                                    <CompanyLogo {...previousOrg} />
                                    <span className="text">
                                        {translate("lists.previous")}
                                    </span>
                                </div>
                            </a>
                        )
                            :
                            <div />
                        }
                        {nextOrg ? (
                            <a className="list-navigation-link" href={getOrganisationLink(nextOrg.id)}>

                                <div className="arrow-right">
                                    <span className="text">
                                        {translate("lists.next")}
                                    </span>
                                    <CompanyLogo {...nextOrg} />
                                </div>
                            </a>
                        )
                            :
                            <div />
                        }
                    </div>
                </Col>
            )
            }
        </Row >
    )
};

export default ListNavigation;
