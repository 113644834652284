import React from "react";
import {DateText, Highlight, Icon, Item, ItemContent, NotificationItem, Text} from "../Notifications";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding} from "@fortawesome/free-solid-svg-icons";
import {Payload} from "../../../model/notification/Payload";
import ReactTimeAgo from "react-time-ago";

const EventsSinceLastLoginNotification: React.FC<NotificationItem> = ({ element }) => {

    return (
        <Item read={element.read}>
            <Icon>
                <FontAwesomeIcon color="white" icon={faBuilding} />
            </Icon>
            <ItemContent>
                {element.middlewares.map((middleware: Payload) => (
                    <div key={middleware.id}>
                        <Text>
                            <span>Check out what you have missed since your </span>
                            <span className="me-2">
                                <Highlight>
                                    <a target={"_blank"} href={["/notification/social-events?since=", middleware.placeholder].join("")}>
                                        last visit <ReactTimeAgo date={new Date(middleware.placeholder)} locale="en-US" />.
                                    </a>
                                </Highlight>
                            </span>
                        </Text>
                    </div>
                ))}
                <DateText>
                    <ReactTimeAgo date={new Date(element.modified)} locale="en-US" />
                </DateText>
            </ItemContent>
        </Item>
    );
};

export default EventsSinceLastLoginNotification;