import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {frontEndHostName} from "../../../utils/Configuration";
import List from "../../../model/list/List";
import {Alert, Col, Row} from "react-bootstrap";
import DashboardItemContext from "../../../context/dashboard/DashboardItemContext";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {useHistory} from "react-router-dom";
import translate from "../../../i18n/translate";
import ListLink from "../../lists/ListLink";

const DashboardLists: React.FC = () => {
    let history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [lists, setLists] = useState<List[]>([]);
    const {maxHeight, setMaxHeight} = useContext(DashboardItemContext);

    useEffect(() => {
        const fetchLists = async () => {
            setLoading(true);
            await axios.get(frontEndHostName + "user-lists?number=0size=5")
                .then(r => {
                    let l = r.data.slice(0, 5);
                    setLists(l as List[]);
                })
                .catch(error => setError(true))
                .finally(() => setLoading(false));
        };

        fetchLists();
    }, []);


    const getHeight = () => {
        return maxHeight - 60;
    };

    return (
        <div>
            <Row className="pb-2">
                <Col>
                    <div className="organisation-card-label">
                        {translate("lists.title")}
                    </div>
                </Col>
                <Col>
                    <a className="iq-link pull-right" onClick={() => history.push("/lists")}>
                        {translate("dashboard.viewall")}
                    </a>
                </Col>
            </Row>
            {loading ? <IqLoadingIcon /> : (
                <div className="container-div-with-y-scroll" style={{maxHeight: getHeight()}}>
                    {error ? (
                        <Alert variant="danger">
                            {translate("errors.loading")}
                        </Alert>
                    ) : (
                        <div>
                            {lists.map((list: List, index: number) => (
                                <div key={index} className="pe-2 nonIqbladeDraggableWidget">
                                    <ListLink list={list}/>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
};

export default DashboardLists;
