import axios from "axios";
import React, {useContext, useEffect, useState} from "react";
import {Alert, Modal, Table} from "react-bootstrap";
import UserListContext from "../../../context/list/UserListContext";
import {organisationHostname} from "../../../utils/Configuration";
import IqLoadingIcon from "../../common/IqLoadingIcon";

interface ListDataQualityModal {
    selected: string[]
}

interface DataQualityEntryPayload {
    hgDataCount: number,
    newsCount: number,
    contactsCount: number,
    latestFinancialYear: number
}

type DataQualityEntry = Record<string, DataQualityEntryPayload>

const ListDataQualityModal: React.FC<ListDataQualityModal> = (props: ListDataQualityModal) => {
    const { list, showDataQualityModal, setShowDataQualityModal } = useContext(UserListContext);

    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [entries, setEntries] = useState<DataQualityEntry>({} as DataQualityEntry);

    const handleClose = () => {
        setShowDataQualityModal(false);
    };


    useEffect(() => {
        setLoading(true);
        axios.post(organisationHostname + "organisation/data-quality", props.selected)
            .then((response) => {
                setEntries(response.data);
            })
            .catch((error) => {
                setError(true);
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <Modal show={showDataQualityModal} onHide={handleClose} dialogClassName="modal-dialog modal-full-height modal-right modal-notify modal-success">
            <Modal.Header closeButton>
                <Modal.Title>Computed data quality for {props.selected?.length} orgs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ?
                    <IqLoadingIcon />
                    :
                    error ?
                        <Alert variant="danger">
                            An error occurred while computing the data quality.
                        </Alert>
                        :
                        <Table style={{ backgroundColor: "white", width: "100%", marginLeft: "auto", marginRight: "auto" }} striped bordered hover>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th># of Tech Stack</th>
                                    <th># of News Articles</th>
                                    <th># of Contacts</th>
                                    <th>Last Financial Year</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(entries).map(key => (
                                    <tr key={key}>
                                        <td className="pt-3"><a target="_blank" href={"/organisation/".concat(key)}>{key}</a></td>
                                        <td className="pt-3">{entries[key].contactsCount}</td>
                                        <td className="pt-3">{entries[key].newsCount}</td>
                                        <td className="pt-3">{entries[key].contactsCount}</td>
                                        <td className="pt-3">{entries[key].latestFinancialYear}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                }
            </Modal.Body>
        </Modal>
    );
};

export default ListDataQualityModal;
