import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Alert, Col, Container, Row, Table} from 'react-bootstrap';
import './OrganisationTechStack.css';
import axios from 'axios';
import {organisationHostname} from '../../utils/Configuration';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';

import {format} from 'd3-format';
import {Hint, RadialChart} from 'react-vis';
import {AutoSizer} from 'react-virtualized';
import IqLoadingIcon from '../common/IqLoadingIcon';

const OrganisationShareholders: React.FC = () => {

    let { organisationId } = useParams<{organisationId: string}>();

    const [hover, setHover] = useState<any>();

    interface OrganisationShareholder {
        id: number,
        name: string,
        shareType: string,
        numberOfShares: number,
        valueOfShare: number,
        currency: string,
        shareholderType: string,
        percentage: number
    }

    interface ShareholderPieValue {
        angle: number,
        label: string
    }

    interface OrganisationShareholdersResponse {
        organisationShareholders: OrganisationShareholder[],
        otherOrganisationShareholders: OrganisationShareholder
    }

    const [loading, setLoading] = useState<boolean>(false);
    const [organisationShareholders, setOrganisationShareholders] = useState<OrganisationShareholdersResponse>({} as OrganisationShareholdersResponse);
    const [organisationPieValues, setOrganisationPieValues] = useState<any[]>([] as any[]);
    const myData = [{ angle: 1 }, { angle: 5 }, { angle: 2 }]

    const mapToPieValues = (shareholders: OrganisationShareholdersResponse) => {
        let pieValues = shareholders.organisationShareholders.map(shareholder => {
            return {
                angle: shareholder.percentage,
                label: [shareholder.name].filter(Boolean).join(" ")
            }
        })
        if (shareholders.otherOrganisationShareholders != null) {
            pieValues.push({
                angle: shareholders.otherOrganisationShareholders.percentage,
                label: "Others"
            })
        }
        setOrganisationPieValues(pieValues);
        console.log(pieValues)
    };

    useEffect(() => {
        setLoading(true);
        const fetchTechStack = async () => {
            const result = await axios.get(organisationHostname + "organisation/" + organisationId + "/shareholders")
                .then((response) => {
                    setOrganisationShareholders(response.data);
                    setLoading(false);
                }).catch(error => console.log(error.message));
        };

        fetchTechStack();

    }, []);

    useEffect(() => {
        if (organisationShareholders.organisationShareholders != null) {
            mapToPieValues(organisationShareholders)
        }
    }, [organisationShareholders])

    return (
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0, marginTop: 15 }}>
            {loading == false
                ?
                (organisationShareholders.organisationShareholders !== undefined && organisationShareholders.organisationShareholders?.length > 0)
                    ?
                    <div>
                        <Row className="" style={{ marginLeft: 0, marginRight: 0, paddingTop: 15 }}>
                            <Col sm="12" md="8" lg="8">
                                <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10, width: "100%" }}>
                                    <Table striped hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Shareholders</th>
                                                <th>Number of Shares (at Value)</th>
                                                <th>Share Type</th>
                                                <th>Shareholder Type</th>
                                                <th>Ownership (%, approx.)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {organisationShareholders.organisationShareholders.map(shareholder => (
                                                <tr key={shareholder.id}>
                                                    <td>{shareholder.name}</td>
                                                    <td>{format(",")(shareholder.numberOfShares)} at {shareholder.valueOfShare} {shareholder.currency}</td>
                                                    <td>{shareholder.shareType}</td>
                                                    <td>{shareholder.shareholderType || '-'}</td>
                                                    <td>{shareholder.percentage} %</td>
                                                </tr>
                                            ))}
                                            {organisationShareholders.otherOrganisationShareholders != null &&
                                                <tr key={organisationShareholders.otherOrganisationShareholders.id}>
                                                    <td>{organisationShareholders.otherOrganisationShareholders.name}</td>
                                                    <td>{format(",")(organisationShareholders.otherOrganisationShareholders.numberOfShares)}</td>
                                                    <td>{organisationShareholders.otherOrganisationShareholders.shareType}</td>
                                                    <td>{organisationShareholders.otherOrganisationShareholders.shareholderType || '-'}</td>
                                                    <td>{organisationShareholders.otherOrganisationShareholders.percentage} %</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                            <Col sm="12" md="4" lg="4">
                                <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10, width: "100%", height: 450 }}>
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <div>
                                                <RadialChart
                                                    angleDomain={[0, 100]}
                                                    data={organisationPieValues}
                                                    onValueMouseOver={(v: any) => {
                                                        setHover(v);
                                                    }}
                                                    onValueMouseOut={(v: any) => {
                                                        setHover(null);
                                                    }}
                                                    width={width}
                                                    height={height}>
                                                    {hover && (
                                                        <Hint value={hover}>
                                                            <div style={{
                                                                display: 'flex',
                                                                color: '#fff',
                                                                background: '#000',
                                                                opacity: 0.9,
                                                                alignItems: 'center',
                                                                padding: '5px',
                                                                borderRadius: '5px'
                                                            }}>
                                                                <div>{hover.label}</div>
                                                            </div>
                                                        </Hint>
                                                    )}
                                                </RadialChart>
                                            </div>
                                        )}
                                    </AutoSizer>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    :
                    <div>
                        <Alert variant="warning" style={{ width: "100%", marginTop: 25 }}>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", alignContent: "center" }}>
                                <FontAwesomeIcon icon={faExclamationCircle} color="#367CDB" size="2x" />
                                <span style={{ marginLeft: 15 }}>We don't have shareholder data for this organisation.</span>
                            </div>
                        </Alert>
                    </div>
                :
                <IqLoadingIcon />
            }
        </Container>
    );
}

export default OrganisationShareholders;
