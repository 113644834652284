import { Payload } from "./Payload";

export enum EventType {
    LIST_SHARE = "LIST_SHARE",
    LIST_ADDITION = "LIST_ADDITION",
    ORGANISATION_UPDATED = "ORGANISATION_UPDATED",
    CONTACTS_UPDATED = "CONTACTS_UPDATED",
    JOBS_UPDATED = "JOBS_UPDATED",
    TEAM_CREATION="TEAM_CREATION",
    TEAM_USER_ADDITION="TEAM_USER_ADDITION",
    ADVANCED_SEARCH_SHARE="ADVANCED_SEARCH_SHARE",
    SHARE_WITH_TEAM = "SHARE_WITH_TEAM",
    ORGANISATION_RESEARCH_REQUEST = "ORGANISATION_RESEARCH_REQUEST",
    EVENTS_SINCE_LAST_LOGIN = "EVENTS_SINCE_LAST_LOGIN",
    SEC_FILINGS_UPDATE = "SEC_FILINGS_UPDATE"
}

export interface Notification {
    id: string,
    eventType: EventType,
    source: Payload,
    middlewares: Payload[],
    targets: Payload[],
    destination: Payload,
    created: string,
    modified: string,
    read: boolean
}