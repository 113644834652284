import React, {useContext, useEffect, useState} from "react";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import {Alert, Col, Form, Image, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faLink} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {contractsHostName} from "../../../utils/Configuration";
import GCloudSupplier from "../../../model/publicsector/GCloudSupplier";
import translate from "../../../i18n/translate";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import Pagination from "../../../utils/PaginationHook";
import {Switch} from "antd";
import OrganisationContext from "../../../context/organisation/OrganisationContext";

const GCloudView: React.FC = () => {

    const {organisation} = useContext(OrganisationContext);

    let history = useHistory();
    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/public-sector/frameworks/g-cloud/:supplierId',
        exact: false,
        strict: false
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [supplier, setSupplier] = useState<GCloudSupplier>();
    const [hideExpired, setHideExpired] = useState<boolean>(true);

    useEffect(() => {

        const fetchSupplierOrganisation = async () => {
            console.log("Here")
            setLoading(true);
            setError(false);
            await axios.get(contractsHostName + "g-cloud/organisation/" + organisation?.id)
                .then(r => {
                    setSupplier(r.data);
                    console.log(r);
                })
                .finally(() => setLoading(false));


        }
        const fetchSupplier = async (frameworkId: number) => {
            setLoading(true);
            setError(false);
            await axios.get(`${contractsHostName}g-cloud/${frameworkId}`)
                .then(r => setSupplier(r.data))
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }

        if(organisation?.id && supplier == null){
            fetchSupplierOrganisation()
        } else {
            const supplierId = childMatchedPath?.params.supplierId;
            if (supplierId) {
                fetchSupplier(supplierId);
            }
        }
    }, [organisation]);


    interface CompanyLogo {
        imageUrl: string | undefined,
        companyName: string | undefined
    }

    const CompanyLogo: React.FC<CompanyLogo> = (props: CompanyLogo) => {
        const [src, setSrc] = useState<string>(props.imageUrl ? props.imageUrl : "./icons_company.svg");

        return (
            <Image title={props.companyName}
                   style={{
                       height: "50px",
                       width: "50px",
                       objectFit: "cover",
                       border: "2px solid #5FD1F9"
                   }}
                   src={src}
                   onError={() => setSrc("/icons_company.svg")}
                   roundedCircle />
        )
    };

    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== pageSize) {
            setPageSize(size);
            setPage(1);
        }
    };

    const getFilteredProducts = () => {
        if (hideExpired){
            return supplier?.products.filter(p=>  p.framework == "G-Cloud 13") || [];
        }
        return supplier?.products || []
    }

    const getPagedProducts = () => {
        const products = getFilteredProducts();
        const start = (page - 1) * pageSize;
        const end = start + pageSize;

        if (products.length > start && products.length > end) {
            return products.slice(start, end);
        }

        if (products.length > start && products.length < end) {
            return products.slice(start);
        }

        return [];
    }

    const getBody = () => {
        if (!supplier) {
            return (
                <div className="justify-content-md-center mt-3">
                    <Alert variant="info">
                        {translate("gcloudSupplier.notfound")}
                    </Alert>
                </div>
            );
        }

        if (error) {
            return <Alert variant="danger">{translate("errors.loading")}</Alert>
        }

        return (
            <div>
                <div className="ccs-title mb-4">
                    <span className="me-3"><CompanyLogo imageUrl={supplier.organisation?.imageUrl} companyName={supplier.supplierName} /></span>
                    {(supplier.organisation && supplier.organisation.id) ? (
                        <a href={`/organisation/${supplier.organisation?.id}`} className="iqx-link">{supplier.supplierName}</a>
                    ) : (
                        <span>{supplier.supplierName}</span>
                    )}
                </div>

                {(supplier.products && supplier.products.length > 0) && (
                    <>
                        <div className="organisation-card-label">Products</div>
                        <div className="row row-reverse mb-3 pull-right me-2">
                            <div className="col me-4 mt-2" style={{textAlign: "end"}}>
                                <span className="me-2">Hide expired</span>
                                <Switch checked={hideExpired} onChange={(switchStateValue: boolean) => setHideExpired(switchStateValue)}/>
                            </div>
                        </div>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Product Description</th>
                                    <th>Supplier Type</th>
                                    <th>Framework</th>
                                    <th>Price</th>
                                    <th>Link</th>
                                    <th>Terms</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getPagedProducts().map(product => (
                                    <tr key={product.id}>
                                        <td>{product.productName}</td>
                                        <td>{product.productDescription}</td>
                                        <td>{product.supplierType}</td>
                                        <td>{product.framework}</td>
                                        <td>{product.price}</td>
                                        <td>
                                            <a className="iqx-link" target="_blank" href={product.pageLink}>
                                                <FontAwesomeIcon icon={faLink} size="1x"/>
                                            </a>
                                        </td>
                                        <td>
                                            <a className="iqx-link" target="_blank" href={product.termsLink}>
                                                <FontAwesomeIcon icon={faLink} size="1x"/>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="mt-2">
                            <div className="justify-content-md-end pagination mt-n2">
                                <span className="me-2">
                                    <Form.Control as="select"
                                                  style={{ minWidth: "13%" }}
                                                  defaultValue={pageSize}
                                                  onChange={(event) => changePageSize(event)}
                                                  >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                    </Form.Control>
                                </span>
                                <span>
                                    <Pagination totalRecords={getFilteredProducts().length}
                                                pageLimit={pageSize}
                                                pageRangeDisplayed={1}
                                                initPage={page}
                                                onChangePage={setPage}
                                    />
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }

    return (
        <div style={{
            paddingTop: "10px",
            backgroundColor: "white",
            borderRadius: "0.45rem",
            padding: 25,
            marginTop: 10
        }}>
            <Row className="mb-4">
                <Col className="pt-2">
                    <div className="organisation-card-label">G-Cloud Supplier</div>
                </Col>
                <Col className="pull-right">
                    <button className="iqx-button md-size pull-right" onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                        <span className="me-1">Back</span>
                    </button>
                </Col>
            </Row>

            {error && <Alert variant="danger">{translate("errors.loading")}</Alert>}
            {loading ? <IqLoadingIcon /> : getBody()}

        </div>
    );
};

export default GCloudView;