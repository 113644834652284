import AwesomeDebouncePromise from "awesome-debounce-promise";
import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import {Alert, Col, Row, Table} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import translate from "../../i18n/translate";
import TechStack from "../../model/organisation/TechStack";
import {organisationHostname} from '../../utils/Configuration';
import Pagination from '../../utils/PaginationHook';
import IqLoadingIcon from "../common/IqLoadingIcon";
import {getSort, getSortButton} from './OrganisationTechStack';
import './OrganisationTechStack.css';
import OrganisationTechStackModal from "./OrganisationTechStackModal";
import HoverToolTip from "../../utils/HoverToolTip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

export enum Sort {
    VENDOR = "vendor",
    PRODUCT = "product",
    CATEGORY = "category",
    INTENSITY = "intensity",
    DATE_FIRST_VERIFIED = "date_first_verified",
    DATE_LAST_VERIFIED = "date_last_verified"
}


const OrganisationTechStackUnfiltered: React.FC = () => {
    let { organisationId } = useParams<{organisationId: string}>();
    const searchQueryRef = useRef<string>();

    const [techStack, setTechStack] = useState<TechStack[]>([] as TechStack[]);
    const [loadingTechStackData, setLoadingTechStackData] = useState<boolean>(false);
    const [totalRecords, setTotalRecords] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const techPerPageLimit: number = 10;
    const [sort, setSort] = useState<string>(Sort.DATE_LAST_VERIFIED);
    const [desc, setDesc] = useState<boolean>(true);
    const [showTechStackIntensityGraphModal, setTechStackIntensityGraphModal] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<string>('');

    const [error, setError] = useState<boolean>(false);


    const handleClose = () => {
        setTechStackIntensityGraphModal(false);
    };

    const fetchTechStack = async () => {
        const springCurrentPage: number = currentPage - 1;

        setLoadingTechStackData(true);
        let requestParams = {
            params: {
                page: springCurrentPage,
                size: techPerPageLimit,
                sort: getSort(sort, desc),
                q: searchQueryRef.current
            }
        };

        let url = `${organisationHostname}organisation/${organisationId}/technology-stack`;
        await axios.get(url, requestParams)
            .then((response) => {
                const totalCount: number = Number(response.headers['x-total-count']);
                setTotalRecords(totalCount);
                setTechStack(response.data);
            })
            .catch(() => setError(true))
            .finally(() => setLoadingTechStackData(false));
    };

    // const getEndpoint = () => {
    //     if (techStackView === undefined) {
    //         return "technology-stack";
    //     } else if (techStackView === "filtered" && !editFilters) {
    //         return "technology-filtered-stack";
    //     }
    // };

    useEffect(() => {
        fetchTechStack();
    }, [currentPage, sort, desc]);

    const updateSearchQuery = (value: string) => {
        searchQueryRef.current = value;
        if (currentPage === 1) {
            fetchTechStack();
        } else {
            setCurrentPage(1);
        }
    };

    const debounced = AwesomeDebouncePromise(updateSearchQuery, 300);
    const handleSearchQueryChange = async (e: any) => {
        e.persist();
        await debounced(e.target.value);
    };

    return (
        <div className="pe-1">
            <Row className="mb-3">
                <Col lg={{ span: 3, offset: 9 }} md={{ span: 3, offset: 9 }} sm={{ span: 4, offset: 8 }} xs={{ span: 6, offset: 6 }}>
                    <input className="form-control float-end" type="text" placeholder={translate("advancedsearch.search")} onKeyUp={handleSearchQueryChange} />
                </Col>
            </Row>
            {!loadingTechStackData
                ?
                totalRecords !== 0
                    ?
                    <div>
                        <Row className="" style={{ marginLeft: 0, marginRight: 0, paddingTop: 15 }}>
                            <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10, width: "100%" }}>
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>
                                                {translate("organisation.techstack.vendor")}
                                                {getSortButton(Sort.VENDOR, setDesc, desc, setSort, sort)}
                                            </th>
                                            <th>
                                                {translate("organisation.techstack.product")}
                                                {getSortButton(Sort.PRODUCT, setDesc, desc, setSort, sort)}
                                            </th>
                                            <th>
                                                {translate("organisation.techstack.category")}
                                                {getSortButton(Sort.CATEGORY, setDesc, desc, setSort, sort)}
                                            </th>
                                            <th>
                                                {translate("organisation.techstack.intensity")}
                                                <HoverToolTip id={"intensity-hover"} tooltip={"Intensity is the number of times we have discovered a link to this technology at this organisation"} tooltipPosition={"top"}>
                                                    <FontAwesomeIcon className="ms-2" icon={faInfoCircle} size="1x" color="#206fc3"/>
                                                </HoverToolTip>
                                                {getSortButton(Sort.INTENSITY, setDesc, desc, setSort, sort)}
                                            </th>
                                            <th>
                                                {translate("organisation.techstack.datefirstverified")}
                                                <HoverToolTip id={"date-first-hover"} tooltip={"This is the date we first identified this technology being used at this organisation"} tooltipPosition={"top"}>
                                                    <FontAwesomeIcon className="ms-2" icon={faInfoCircle} size="1x" color="#206fc3"/>
                                                </HoverToolTip>
                                                {getSortButton(Sort.DATE_FIRST_VERIFIED, setDesc, desc, setSort, sort)}
                                            </th>
                                            <th>
                                                {translate("organisation.techstack.datelastverified")}
                                                <HoverToolTip id={"date-last-hover"} tooltip={"This is the date we most recently saw this technology being used at this organisation"} tooltipPosition={"top"}>
                                                    <FontAwesomeIcon className="ms-2" icon={faInfoCircle} size="1x" color="#206fc3"/>
                                                </HoverToolTip>
                                                {getSortButton(Sort.DATE_LAST_VERIFIED, setDesc, desc, setSort, sort)}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {techStack.map(ts => (
                                            <tr key={ts.id}>
                                                <td>{ts.vendor}</td>
                                                <td>{ts.product}</td>
                                                <td onClick={() => {
                                                    setTechStackIntensityGraphModal(true);
                                                    setSelectedCategory(ts.category)
                                                }}
                                                    id="techstack-category"
                                                >{ts.category}</td>
                                                <td>{ts.intensity}</td>
                                                <td>{new Date(ts.dateFirstVerified).toDateString()}</td>
                                                <td>{new Date(ts.dateLastVerified).toDateString()}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Row>
                        <OrganisationTechStackModal
                            showRequestContactsModal={showTechStackIntensityGraphModal}
                            setShowRequestContactsModal={setTechStackIntensityGraphModal}
                            handleClose={handleClose}
                            organisationId={organisationId}
                            category={selectedCategory}

                        />

                        <Row className="" style={{ marginLeft: 0, marginRight: 0, marginTop: 15, float: "right" }}>
                            <Pagination
                                totalRecords={totalRecords}
                                pageLimit={techPerPageLimit}
                                pageRangeDisplayed={1}
                                initPage={currentPage}
                                onChangePage={setCurrentPage}
                            />
                        </Row>
                    </div>
                    :
                    <Alert style={{ marginTop: 25 }} variant="warning">
                        We don't have technology stack data for this organisation.
                    </Alert>
                :
                <IqLoadingIcon />
            }
        </div>
    );
}

export default OrganisationTechStackUnfiltered;