import React, {useEffect, useState} from "react";
import ContractsSearch from "../../publicsector/contracts/ContractsSearch";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Row} from "react-bootstrap";
import {faChartPie, faFileContract} from "@fortawesome/free-solid-svg-icons";
import ContractsDashboard from "./dashboard/ContractsDashboard";

enum Tabs {
    SEARCH = "search",
    DASHBOARD = "dashboard"
}
const OrganisationContractsContainer: React.FC = () => {
    let location = useLocation();
    const history = useHistory();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/organisation/:organisationId',
        exact: false,
        strict: false
    });

    const parsed = queryString.parse(location.search);
    const k = parsed.key ? parsed.key as string : Tabs.SEARCH;
    const [key, setKey] = useState<string>(k);

    useEffect(() => {
        if (key) {
            let link = "/organisation/" + childMatchedPath?.params.organisationId + "/public-sector-organisation/contracts?key=" + key;
            history.push(link);
        }
    }, [key]);

    return (
        <div style={{ paddingTop: "10px", backgroundColor: "white", borderRadius: "0.45rem", padding: 15, marginTop: 10 }}>
            <Row className="" style={{ marginLeft: 0, marginRight: 0, marginTop: 15 }}>
                <div style={{ marginLeft: "auto", marginRight: "auto" }} className="iq-button-group">
                    <button className={"ps-3 " + (key === Tabs.SEARCH ? "selected-inverted" : "")}
                            onClick={(e) => { setKey(Tabs.SEARCH) }}>
                        <FontAwesomeIcon icon={faFileContract} />
                    </button>
                    <button className={"pe-3 " + (key === Tabs.DASHBOARD ? "selected-inverted" : "")}
                            onClick={(e) => { setKey(Tabs.DASHBOARD) }}>
                        <FontAwesomeIcon icon={faChartPie} />
                    </button>
                </div>
            </Row>

            {key === Tabs.SEARCH && <ContractsSearch /> }
            {key === Tabs.DASHBOARD && <ContractsDashboard /> }
        </div>
    );
}

export default OrganisationContractsContainer;