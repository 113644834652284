import React, {useState} from "react";
import {Row} from "react-bootstrap";
import ContactEmail from "./ContactEmail";
import {faCalendar, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ContactEvents from "./ContactEvents";

enum TabKeys {
    EMAIL = "email",
    MEETING = "meeting",
}

const ContactType: React.FC = () => {

    const [key, setKey] = useState<string>(TabKeys.EMAIL);

    return (
            <div style={{paddingTop: "10px"}}>
                <Row className="" style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                    <div style={{marginLeft: "auto", marginRight: "auto"}} className="iq-button-group">
                        <button className={key === TabKeys.EMAIL ? "selected-inverted" : ""}
                                onClick={(e) => {
                                    setKey(TabKeys.EMAIL)
                                }}><FontAwesomeIcon icon={faEnvelope} size="1x" />
                        </button>
                        <button className={key === TabKeys.MEETING ? "selected-inverted" : ""}
                                onClick={(e) => {
                                    setKey(TabKeys.MEETING)
                                }}><FontAwesomeIcon icon={faCalendar} size="1x" />
                        </button>
                    </div>
                </Row>
                <Row className="" style={{marginLeft: 0, marginRight: 0}}>
                    <div style={{width: "100%"}} hidden={key !== TabKeys.EMAIL}>
                        <ContactEmail/>
                    </div>
                    <div style={{width: "100%"}} hidden={key !== TabKeys.MEETING}>
                        <ContactEvents/>
                    </div>
                </Row>
            </div>
    );
};

export default ContactType;
