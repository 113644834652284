import React, {useEffect, useState} from "react";
import axios from "axios";
import qs from "qs";
import {organisationHostname} from "../../utils/Configuration";
import {Alert, Row} from "react-bootstrap";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {AutoSizer} from "react-virtualized";
import {DiscreteColorLegend, Hint, RadialChart} from "react-vis";
import {format} from "d3-format";
import {COLOURS} from "../techsales/TechSales";

interface TopVendor {
    vendorName: string,
    value: number
}

enum TabKeys {
    COUNT = "count",
    VALUE = "value"
}

const RenewalsByVendor: React.FC = () => {

    const [key, setKey] = useState<TabKeys>(TabKeys.VALUE);

    const [hover, setHover] = useState<any>();

    const [loadingCount, setLoadingCount] = useState<boolean>(false);
    const [topCount, setTopCount] = useState<TopVendor[]>([]);

    const [loadingValue, setLoadingValue] = useState<boolean>(false);
    const [topValue, setTopValue] = useState<TopVendor[]>([]);

    const [today] = new Date().toISOString().split("T");
    const [startDate, setStartDate] = useState<string | undefined>(today);
    const [endDate, setEndDate] = useState<string | undefined>();
    const fetchTopVendorsByValue = async () => {
        setLoadingValue(true);
        let params: any = {};
        if (startDate && startDate.length > 0) {
            params["startDate"] = startDate;
        }

        if (endDate && endDate.length > 0) {
            params["endDate"] = endDate;
        }

        await axios.get(`${organisationHostname}renewals/dashboard/vendor-value`, {
                params: params,
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                }
            })
            .then(r => setTopValue(r.data))
            .finally(() => setLoadingValue(false));
    }

    const fetchTopVendorsByCount = async () => {
        setLoadingCount(true);
        let params: any = {};
        if (startDate && startDate.length > 0) {
            params["startDate"] = startDate;
        }

        if (endDate && endDate.length > 0) {
            params["endDate"] = endDate;
        }

        await axios.get(`${organisationHostname}renewals/dashboard/vendor-count`, {
            params: params,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            }
        })
            .then(r => setTopCount(r.data))
            .finally(() => setLoadingCount(false));
    }

    useEffect(() => {
        fetchTopVendorsByValue();
        fetchTopVendorsByCount();
    }, [startDate, endDate]);

    return (
        <div className="organisation-card">
            <div className="organisation-card-label">Renewals By Vendor</div>
            <Row className="pb-3">
                <div className="col-6">
                    Start Date
                    <input type="date"
                           className="form-control"
                           value={startDate}
                           onChange={(e: any) => setStartDate(e.target.value)}
                    />
                </div>
                <div className="col-6">
                    End Date
                    <input type="date"
                           className="form-control"
                           value={endDate}
                           onChange={(e: any) => setEndDate(e.target.value)}
                    />
                </div>
            </Row>
            <div style={{ marginLeft: "auto", marginRight: "auto", paddingBottom: "10px" }} className="iq-button-group">
                <button className={"ps-3 " + (key === TabKeys.VALUE ? "selected-inverted" : "")}
                        onClick={(e) => { setKey(TabKeys.VALUE) }}>
                    Renewals by value
                </button>
                <button className={"pe-3 " + (key === TabKeys.COUNT ? "selected-inverted" : "")}
                        onClick={(e) => { setKey(TabKeys.COUNT) }}>
                    Renewals by count
                </button>
            </div>
            {key === TabKeys.VALUE && (
                <div>
                    {loadingValue ? <IqLoadingIcon/> : (
                        <div style={{
                            paddingBottom: "10px",
                            paddingTop: "10px",
                            backgroundColor: "white",
                            borderRadius: "0.45rem",
                            padding: 15,
                            marginTop: 10,
                            height: 280
                        }}>
                            {(!topValue || topValue.length === 0) ? (
                                <Alert variant="info" className="mt-3">
                                    No renewals for time period
                                </Alert>
                            ) : (
                                <AutoSizer>
                                    {({height, width}) => (
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            height: height,
                                            width: width,
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <RadialChart
                                                angleDomain={[0, 100]}
                                                data={topValue.map((item: TopVendor, index: number) => {
                                                    return {
                                                        label: format(",.2f")(item.value) + " (" + item.vendorName + ")",
                                                        angle: item.value,
                                                        color: COLOURS[index]
                                                    }
                                                })}
                                                onValueMouseOver={(v: any) => {
                                                    setHover(v);
                                                }}
                                                onValueMouseOut={(v: any) => {
                                                    setHover(null);
                                                }}
                                                width={width * 70 / 100}
                                                height={height}
                                                colorType="literal">
                                                {hover && (
                                                    <Hint value={hover}>
                                                        <div style={{
                                                            display: 'flex',
                                                            color: 'white',
                                                            backgroundColor: 'black',
                                                            opacity: 0.9,
                                                            alignItems: 'center',
                                                            padding: '5px',
                                                            borderRadius: '5px',
                                                            border: '2px solid',
                                                            fontWeight: 500
                                                        }}>
                                                            <div>{hover?.label}</div>
                                                        </div>
                                                    </Hint>
                                                )}
                                            </RadialChart>

                                            <DiscreteColorLegend
                                                height={height}
                                                orientation={"vertical"}
                                                width={width * 30 / 100}
                                                items={topValue.map((item, index) => {
                                                    return {title: item.vendorName, color: COLOURS[index], strokeWidth: 25}
                                                })}/>
                                        </div>
                                    )}
                                </AutoSizer>
                            )}
                        </div>
                    )}
                </div>
            )}

            {key === TabKeys.COUNT && (
                <div>
                    {loadingCount ? <IqLoadingIcon /> : (
                        <div style={{
                            paddingBottom: "10px",
                            paddingTop: "10px",
                            backgroundColor: "white",
                            borderRadius: "0.45rem",
                            padding: 15,
                            marginTop: 10,
                            height: 280
                        }}>
                            {(!topCount || topCount.length === 0) ? (
                                <Alert variant="info" className="mt-3">
                                    No renewals for time period
                                </Alert>
                            ) : (
                                <AutoSizer>
                                    {({height, width}) => (
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            height: height,
                                            width: width,
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <RadialChart
                                                angleDomain={[0, 100]}
                                                data={topCount.map((item: TopVendor, index: number) => {
                                                    return {
                                                        label: format(",.0f")(item.value) + " (" + item.vendorName + ")",
                                                        angle: item.value,
                                                        color: COLOURS[index]
                                                    }
                                                })}
                                                onValueMouseOver={(v: any) => {
                                                    setHover(v);
                                                }}
                                                onValueMouseOut={(v: any) => {
                                                    setHover(null);
                                                }}
                                                width={width * 70 / 100}
                                                height={height}
                                                colorType="literal">
                                                {hover && (
                                                    <Hint value={hover}>
                                                        <div style={{
                                                            display: 'flex',
                                                            color: 'white',
                                                            backgroundColor: 'black',
                                                            opacity: 0.9,
                                                            alignItems: 'center',
                                                            padding: '5px',
                                                            borderRadius: '5px',
                                                            border: '2px solid',
                                                            fontWeight: 500
                                                        }}>
                                                            <div>{hover?.label}</div>
                                                        </div>
                                                    </Hint>
                                                )}
                                            </RadialChart>

                                            <DiscreteColorLegend
                                                height={height}
                                                orientation={"vertical"}
                                                width={width * 30 / 100}
                                                items={topCount.map((item, index) => {
                                                    return {title: item.vendorName, color: COLOURS[index], strokeWidth: 25}
                                                })}/>
                                        </div>
                                    )}
                                </AutoSizer>
                            )}
                        </div>
                    )}
                </div>
            )}

        </div>
    );
}

export default RenewalsByVendor;