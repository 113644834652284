import React, {useEffect, useState} from "react";
import axios from "axios";
import {organisationHostname} from "../../utils/Configuration";
import SicCodeAggregate from "../../model/marketintelligence/SicCodeAggregate";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {Form, Image, Table} from "react-bootstrap";
import translate from "../../i18n/translate";
import {format} from "d3-format";
import Select from "react-select";
import DateFormatter from "../../utils/formatters/DateFormatter";
import Pagination from "../../utils/PaginationHook";
import TotalCompanies from "../../model/marketintelligence/TotalCompanies";
import IqXSmallLoadingIcon from "../common/IqXSmallLoadingIcon";
import "./SicCodeAnalytics.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowAltCircleDown,
    faArrowAltCircleUp,
    faSort,
    faSortAmountDown,
    faSortAmountDownAlt
} from "@fortawesome/free-solid-svg-icons";
import Locale from "../common/Locale";

enum Sort {
    CATEGORY = "sicCodeCategory",
    COUNT = "secondMonthCount",
    PREVIOUS_MONTH = "firstMonthCount",
    DIFF = "diff",
    PCT_CHANGE = "pctChange"
}

const SicCodeAnalytics: React.FC = () => {

    const [startDate, setStartDate] = useState<string>();
    const updateStartDate = (e: any) => {
        setStartDate(e.value);
    };

    const [startDateOptions, setStartDateOptions] = useState<string[]>([]);
    const countryOptions = [Locale.ENGLISH];
    const [selectedCountry, setSelectedCountry] = useState<Locale>(Locale.ENGLISH);
    const updateCountry = (e: any) => {
        setSelectedCountry(e.value);
    }

    const [loadingDateOptions, setLoadingDateOptions] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTotal, setLoadingTotal] = useState<boolean>(false);
    const [totalCompanies, setTotalCompanies] = useState<TotalCompanies>();
    const [sicCodeAggregates, setSicCodeAggregates] = useState<SicCodeAggregate[]>([]);
    useEffect(() => {
        const fetchDateOptions = async () => {
            setLoadingDateOptions(true);
            await axios.get(organisationHostname + "sic-code-analytics/month-options")
                .then(r => {
                    const data = r.data;
                    setStartDateOptions(data);
                    if (data.length > 0) {
                        setStartDate(data[0]);
                    }
                })
                .finally(() => setLoadingDateOptions(false));
        }

        fetchDateOptions();
    }, []);

    const [page, setPage] = useState<number>(1);
    const [totalRecords, setTotalRecords] = useState<number>();
    const [pageSize, setPageSize] = useState<number>(10);
    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== pageSize) {
            setPageSize(size);
            setPage(1);
        }
    };

    const [sort, setSort] = useState<Sort>();
    const [desc, setDesc] = useState<boolean>(false);
    useEffect(() => {
        const fetchAggregates = async () => {
            setLoading(true);
            let params = {
                start: startDate,
                page: page - 1,
                size: pageSize
            };

            if (sort) {
                //@ts-ignore
                params["sort"] = sort + "," + (desc ? "DESC" : "ASC");
            }

            await axios.get(organisationHostname + "sic-code-analytics/aggregate", { params: params })
                .then(r => {
                    setSicCodeAggregates(r.data.content);
                    setTotalRecords(r.data.totalElements);
                })
                .finally(() => setLoading(false));
        }

        if (startDate) {
            fetchAggregates();
        }
    }, [startDate, page, pageSize, sort, desc]);

    useEffect(() => {
        const fetchTotalCompanies = async () => {
            setLoadingTotal(true);
            const params = {
                start: startDate
            };

            await axios.get(organisationHostname + "sic-code-analytics/total", {params: params})
                .then(r => setTotalCompanies(r.data))
                .finally(() => setLoadingTotal(false));
        }

        if (startDate) {
            fetchTotalCompanies();
        }
    }, [startDate]);

    const getVariance = (formatString: string, diff: number, symbol?: string) => {
        return <span className={"variance " + (diff >= 0 ? "positive" : "negative")}>{format(formatString)(diff)}{symbol}</span>
    }

    const getDisplayDate = (date: string) => {
        let dateAsString = DateFormatter.formatDate(date);
        return dateAsString.substr(dateAsString.indexOf("/") + 1);
    }

    const getSortButton = (s: Sort) => {
        if (s === sort) {
            return (
                <a className="sort-button"
                   onClick={() => {
                       setDesc(!desc);
                   }}>
                    <FontAwesomeIcon icon={desc ? faSortAmountDown : faSortAmountDownAlt} />
                </a>
            );
        }

        return (
            <a className="sort-button"
               onClick={() => {
                   setSort(s);
                   setDesc(true);
               }}>
                <FontAwesomeIcon icon={faSort} />
            </a>
        );
    };

    const getFlag = (l: Locale) => {
        const country = l === Locale.ENGLISH ? 'UK' : l.key;
        return (
            <>
                <span className="organisation-card-label me-2">{country}</span><Image roundedCircle style={{width: "30px", height: "30px", objectFit: "cover", border: "1px solid #2F70C4"}} src={l.flag}/>
            </>
        );
    }

    return (
        <div style={{paddingTop: 35}}>
            {loadingDateOptions ? <IqLoadingIcon/> : (
                <div className="organisation-card">
                    <div className="organisation-card-label">
                        {translate("marketintelligence.sic.header")}
                    </div>

                    <div className="flex-row justify-end align-center center mb-4">
                        <div className="item w-50 me-3">
                            {loadingTotal ? <IqXSmallLoadingIcon/> :
                                    totalCompanies && (
                                        <div className="flex-row center align-center">
                                            <h1 className="iq-tx-rubik me-4">
                                                {format(',')(totalCompanies.total)}
                                            </h1>

                                            <div className={"flex-row pt-2 pb-2 ps-3 pe-3 center change-indicator " + (totalCompanies.diff >= 0 ? "positive" : "negative")}>
                                                <div className="flex-col text-end">
                                                    <div style={{fontSize: "16px", fontWeight: "bold"}}>
                                                        {format('+,')(totalCompanies.diff)}
                                                    </div>
                                                    <div className="mt-1">
                                                        {format(".2f")(totalCompanies.pctChange)}%
                                                    </div>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon size="3x" icon={totalCompanies.diff >= 0 ? faArrowAltCircleUp : faArrowAltCircleDown} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                        <div className="item w-25">
                            <div className="flex-col">
                                <div className="flex-row">
                                    <div className="item organisation-card-label pt-3  w-50 text-end">
                                        MM/YYYY
                                    </div>
                                    <div className="item w-50">
                                        <Select isDisabled={false}
                                                options={startDateOptions.map(opt => {
                                                    return {
                                                        label: getDisplayDate(opt),
                                                        value: opt,
                                                    }
                                                })}
                                                value={{label: getDisplayDate(startDate!), value: startDate}}
                                                onChange={updateStartDate}
                                                className="basic-multi-select"
                                                classNamePrefix="select"/>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="item organisation-card-label pt-3  w-50 text-end">
                                        {translate("contacts.filter.country")}
                                    </div>
                                    <div className="item w-50">
                                        <Select isDisabled={false}
                                                options={countryOptions.map(opt => {
                                                    return {
                                                        label: getFlag(opt),
                                                        value: opt,
                                                    }
                                                })}
                                                value={{label: getFlag(selectedCountry), value: selectedCountry}}
                                                onChange={updateCountry}
                                                className="basic-multi-select"
                                                classNamePrefix="select"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {loading ? <IqLoadingIcon/> : (
                        <div>
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>
                                            {translate("marketintelligence.sic.category")}
                                            {getSortButton(Sort.CATEGORY)}
                                        </th>
                                        <th>{translate("marketintelligence.sic.description")}</th>
                                        <th>{translate("marketintelligence.sic.date")}</th>
                                        <th className="text-end">
                                            {translate("marketintelligence.sic.count")}
                                            {getSortButton(Sort.COUNT)}
                                        </th>
                                        <th className="text-end">
                                            {translate("marketintelligence.sic.prev")}
                                            {getSortButton(Sort.PREVIOUS_MONTH)}
                                        </th>
                                        <th className="text-end">
                                            {translate("marketintelligence.sic.variance")}
                                            {getSortButton(Sort.DIFF)}
                                        </th>
                                        <th className="text-end">
                                            {translate("marketintelligence.sic.pctchange")}
                                            {getSortButton(Sort.PCT_CHANGE)}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sicCodeAggregates.map(aggregate => (
                                        <tr key={aggregate.id}>
                                            <td>{aggregate.sicCodeCategory}</td>
                                            <td>{aggregate.categoryDescription}</td>
                                            <td>{getDisplayDate(aggregate.secondMonth)}</td>
                                            <td className="text-end">{format(',')(aggregate.secondMonthCount)}</td>
                                            <td className="text-end">{format(',')(aggregate.firstMonthCount)}</td>
                                            <td className="text-end">
                                                {getVariance('+,', aggregate.diff)}
                                            </td>
                                            <td className="text-end">
                                                {getVariance("+,.2f", aggregate.pctChange, '%')}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <div className="mt-4">
                                <div className="justify-content-md-end pagination mt-n2">
                                    <span className="me-2">
                                        <Form.Control as="select"
                                                      style={{minWidth: "13%"}}
                                                      defaultValue={pageSize}
                                                      onChange={(event) => changePageSize(event)}
                                                      >
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={15}>15</option>
                                        </Form.Control>
                                    </span>
                                    <span>
                                        {totalRecords && (
                                            <Pagination totalRecords={totalRecords}
                                                        pageLimit={pageSize}
                                                        pageRangeDisplayed={1}
                                                        initPage={page}
                                                        onChangePage={setPage}
                                            />
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SicCodeAnalytics;