import React from "react";
import {Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import SocialEventsNotification from "./SocialEventsNotification";

const NotificationContainer: React.FC = () => {

    const match = useRouteMatch();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    return (
        <>
            <Switch>
                <Route path={`${match.url}/social-events`} component={SocialEventsNotification} />
            </Switch>
        </>
    )
};

export default NotificationContainer;
