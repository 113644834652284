import React, {useEffect, useState} from "react";
import {Button, Col, Form, InputGroup, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle, faPlus, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {frontEndHostName} from "../../utils/Configuration";
import {OrganisationUser} from "./edit/EditTeam";
import {Team, TeamUser} from "./Teams";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {useHistory} from "react-router-dom";
import UserAvatar from "../common/UserAvatar";
import NewFreemiumUserModal from "../admin/NewFreemiumUserModal";
import translate from "../../i18n/translate";
import Pagination from "../../utils/PaginationHook";
import {showErrorWithMessage, showGenericErrorDialog, showGenericSuccessDialog} from "../common/CommonAlerts";

const CreateTeam: React.FC = () => {
    let history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [organisationUsers, setOrganisationUsers] = useState<OrganisationUser[]>([]);
    const [organisationId, setOrganisationId] = useState<number>();
    const [teamName, setTeamName] = useState<string>("");
    const [teamUsers, setTeamUsers] = useState<TeamUser[]>([]);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [duplicateName, setDuplicateName] = useState<boolean>(false);

    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState(1);

    const fetchOrganisationUsers = async () => {
        setLoading(true);

        let params = {
            page: currentPage - 1,
            size: 10
        };

        await axios.get(frontEndHostName + "users/organisation/", {
            params: params
        })
            .then(r => {
                let data = r.data;
                setOrganisationUsers(data.content);
                setTotalRecords(data.totalElements);
                if (data.content.length > 0) {
                    setOrganisationId(data.content[0].organisationId);
                }
            })
            .catch((error) => console.error(error.message))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchOrganisationUsers();
    }, [currentPage]);

    const handleNameChange = (event: any) => {
        setTeamName(event.target.value);
    };

    const toggleAdmin = (userId: number) => {
        let current = teamUsers.find(u => u.userId == userId);
        if (current) {
            let users = teamUsers.filter(u => u.userId !== userId);

            let isManager = current.manager;
            let user = {
                ...current,
                manager: !isManager
            };

            let index = teamUsers.indexOf(current);
            users.splice(index, 0, user);
            setTeamUsers(users);
        }
    };

    const removeUser = (userId: number) => {
        let users = teamUsers.filter(u => u.userId !== userId);
        setTeamUsers(users);
    };

    const addUser = (user: OrganisationUser) => {
        let u = {
            userId: user.id,
            login: user.login,
            firstName: user.firstName,
            lastName: user.lastName,
            imageUrl: user.imageUrl,
            manager: false
        } as TeamUser;

        setTeamUsers([
            ...teamUsers,
            u
        ]);
    };

    const existsInTeam = (userId: number) => {
        return teamUsers.find(user => user.userId === userId);
    };

    const [savedId, setSavedId] = useState<number>();
    const saveChanges = async () => {
        let team = {
            name: teamName,
            users: teamUsers,
            organisationId: organisationId,
        } as Team;

        await axios.post(frontEndHostName + "teams", team)
            .then(r => {
                showGenericSuccessDialog()
                    .then(() => history.push("/team/" + r.data.id + "/edit"))
            })
            .catch(error => {
                let data = error.response.data;
                if (data.errorKey === "nameexists") {
                    showErrorWithMessage("A team with this name already exists.", "Team names must be unique");
                }
                else {
                    showGenericErrorDialog();
                }
            });
    };

    const [showCreateUser, setShowCreateUser] = useState<boolean>(false);
    const hideCreateModal = () => {
        setShowCreateUser(false);
        fetchOrganisationUsers();
    };

    return (
        <div>
            <NewFreemiumUserModal show={showCreateUser} onHide={hideCreateModal} />
            <Row style={{ paddingTop: 35 }}>
                <div className="col-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
                    <div className="organisation-card">
                        <div className="organisation-card-label">
                            Team Details
                        </div>
                        <div className="mt-2">
                            <Row>
                                <Col md={9}>
                                    <InputGroup className="mb-3">
                                        {/*<InputGroup.Prepend>*/}
                                            <InputGroup.Text>Team Name</InputGroup.Text>
                                        {/*</InputGroup.Prepend>*/}
                                        <Form.Control type="text" value={teamName} onChange={(e) => handleNameChange(e)} />
                                    </InputGroup>
                                </Col>
                                <Col md={3}>
                                    <button className="iq-button iq-button-primary pull-right" onClick={() => saveChanges()} disabled={teamUsers.length === 0 || teamName.length === 0}>
                                        Save Changes
                                    </button>
                                </Col>
                            </Row>
                        </div>
                        <div className="mt-2">
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th style={{ width: "65%" }}>Team Members</th>
                                        <th style={{ width: "10%" }}>Admin</th>
                                        <th style={{ width: "25%" }}>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {teamUsers?.map(user => (
                                        <tr key={user.login}>
                                            <td>
                                                <UserAvatar user={user} height="40px" width="40px" marginRight={15} />
                                                {user.firstName} {user.lastName}
                                            </td>
                                            <td>
                                                {user.manager ? (
                                                    <Button variant="link" title="Remove as admin" onClick={() => toggleAdmin(user.userId)}>
                                                        <span className="fa fa-star fa-lg"></span>
                                                    </Button>
                                                ) : (
                                                    <Button variant="link" title="Promote to admin" onClick={() => toggleAdmin(user.userId)}>
                                                        <span className="fa fa-star-o fa-lg"></span>
                                                    </Button>
                                                )}
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                Remove Member
                                                <Button className="remove-member" variant="link" onClick={() => removeUser(user.userId)}>
                                                    <FontAwesomeIcon icon={faMinusCircle} size="lg" />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="col-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                    <div className="organisation-card">
                        <div className="organisation-card-label">
                            <span>
                                {translate("teams.manage.addmembers")}
                            </span>
                            <span className="pull-right mt-n2">
                                <button className="iqx-button sm-size primary" onClick={() => setShowCreateUser(true)}>
                                    <span className="me-2">Create User</span>
                                    <span><FontAwesomeIcon icon={faPlus} /></span>
                                </button>
                            </span>
                        </div>
                        <div className="mt-2">
                            {loading ? <IqLoadingIcon /> : (
                                <Table striped hover>
                                    <thead>
                                        <tr>
                                            <th >Username</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {organisationUsers
                                            .filter((u: OrganisationUser) => !existsInTeam(u.id))
                                            .map((user: OrganisationUser) => (
                                                <tr key={user.login}>
                                                    <td>
                                                        <UserAvatar user={user} height="40px" width="40px" marginRight={15} />
                                                        {user.firstName} {user.lastName}
                                                    </td>
                                                    <td style={{ textAlign: "right" }}>
                                                        Add Member
                                                        <Button className="add-member" variant="link" onClick={() => addUser(user)} >
                                                            <FontAwesomeIcon icon={faPlusCircle} size="lg" />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                            )}
                                        <Pagination
                                            totalRecords={totalRecords}
                                            pageLimit={10}
                                            initPage={currentPage}
                                            pageRangeDisplayed={1}
                                            onChangePage={setCurrentPage}
                                        />
                                    </tbody>
                                </Table>
                            )}
                        </div>
                    </div>
                </div>
            </Row>
        </div>
    );
};

export default CreateTeam;
