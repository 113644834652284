import React, {useEffect, useReducer} from 'react';
import {Button, Container, Spinner} from 'react-bootstrap';
import {userLanguage} from "../../i18n/translate";
import './TermsAgreement.css'
import {frontEndHostName} from '../../utils/Configuration';
import axios from 'axios';
import {refreshToken} from '../../utils/Security';
import {useHistory} from 'react-router-dom';

interface Props {
    setTermsAgreed: React.Dispatch<React.SetStateAction<boolean>>
}

enum Types {
    LOADING,
    SHOW_TERMS,
    TERMS_AGREED,
    SET_TERMS_TEXT
}

const TermsAgreement: React.FC<Props> = (props: Props) => {

    const reducer = (state: any, {type, payload}: { type: Types, payload: boolean | string }) => {
        switch (type) {
            case Types.LOADING:
                return {...state, loading: payload};
            case Types.SHOW_TERMS:
                return {...state, showTerms: payload};
            case Types.TERMS_AGREED:
                return {...state, termsAgreed: payload};
            case Types.SET_TERMS_TEXT:
                return {...state, terms: payload};
            default:
                throw new Error(`Unrecognised type [${type}]`);
        }
    }

    const initialState = {
        loading: false,
        showTerms: false,
        termsAgreed: false,
        terms: "Loading..."
    };

    const [{loading, showTerms, termsAgreed, terms}, dispatch] = useReducer(reducer, initialState);

    let history = useHistory();

    const onAgree = () => {
        dispatch({type: Types.LOADING, payload: true});
        axios.post(frontEndHostName + 'agree-terms')
            .then((response) => {
                if (response.status === 200) {
                    refreshToken(() => {
                    }, () => {
                        props.setTermsAgreed(true);
                        history.push("/onboarding");
                    });
                } else {
                    dispatch({type: Types.LOADING, payload: false});
                }
            }).catch(error => {
            console.log(error.message);
            dispatch({type: Types.LOADING, payload: false});
        });
    };

    useEffect(() => {
        fetch(`/terms_and_conditions_${userLanguage}.htm`)
            .then(r => {
                if (r.ok) r.text().then(t => dispatch({type: Types.SET_TERMS_TEXT, payload: t}));
                else dispatch({type: Types.SET_TERMS_TEXT, payload: "Error loading Terms & Conditions"});
            })
            .catch(e => {
                console.log(e);
            })
    }, []);
    return (
        <Container className="terms-agreement-container" fluid style={{paddingLeft: 0, paddingRight: 0}}>
            <div className="introduction">
                <h1 className="introduction-heading">Welcome to<span> </span>
                    <img className="welcome-logo" src="/logo-large.png"/>
                </h1>
                <div className="introduction-message">
                    Before proceeding, please accept the <div className="terms-text" onClick={() => dispatch({
                    type: Types.SHOW_TERMS,
                    payload: !showTerms
                })}>Terms & Conditions.</div>
                </div>
            </div>
            <div className={(showTerms ? "grow" : "shrink") + "-terms-agreement"}>
                <div className="terms-agreement-content" onScroll={(e) => {
                    if (termsAgreed) return;
                    const {scrollTop, scrollHeight, clientHeight} = e.currentTarget;
                    let scrollPosition = (scrollTop + clientHeight);
                    if ((scrollHeight - scrollPosition) < 10) {
                        console.log("Read Terms");
                        dispatch({type: Types.TERMS_AGREED, payload: true});
                    }
                }}>
                    <div dangerouslySetInnerHTML={{__html: terms}}/>
                </div>
            </div>

            <div className="terms-agreement-actions">
                {!loading
                    ?
                    <Button variant="primary" disabled={!termsAgreed && showTerms}
                            onClick={() => {
                                showTerms ? onAgree() : dispatch({type: Types.SHOW_TERMS, payload: true})
                            }}>{(showTerms ? termsAgreed ? "Confirm Agreement" : "Please Read Terms & Conditions" : "Click For Terms & Conditions")}</Button>
                    :
                    <Spinner style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        height: "2rem",
                        width: "2rem"
                    }}
                             animation="border"
                             variant="primary"/>
                }
            </div>
        </Container>
    );
}

export default TermsAgreement;