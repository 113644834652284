import React, {useContext, useEffect} from "react";
import {matchPath, Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import SubmenuContext from "../../context/SubmenuContext";
import {faPlus, faUsers} from "@fortawesome/free-solid-svg-icons";
import AllTeams from "./AllTeams";
import CreateTeam from "./CreateTeam";
import {hasUserRole, Role} from "../../utils/Security";
import translate from "../../i18n/translate";

export interface Team {
    id: number;
    organisationId: number;
    name: string;
    users: TeamUser[];
    createdDate: string;
    createdBy: number;
}

export interface TeamUser {
    id: number;
    teamId: string;
    userId: number;
    userUuid: string;
    login: string;
    firstName: string;
    lastName: string;
    email: string;
    imageUrl: string;
    manager: boolean;
    createdDate: string;
}

export const hasCreatePermission = () => {
    return hasUserRole(Role.BASIC_ACCOUNT_MANAGER) || hasUserRole(Role.ADVANCED_ACCOUNT_MANAGER) || hasUserRole(Role.CLIENT_ADMIN) || hasUserRole(Role.ADMIN);
};

const Teams: React.FC = () => {
    const match = useRouteMatch();
    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/teams',
        exact: false,
        strict: false
    });

    const {setSubmenuTitle, setSubmenuItems} = useContext(SubmenuContext);

    useEffect(() => {
        setSubmenuTitle(translate("teams.title"));

        let submenuItems = [
            {
                name: "teams.allteams",
                url: "/teams",
                icon: faUsers
            }
        ];

        if (hasCreatePermission()) {
            submenuItems.push({
                name: "teams.createteam",
                url: childMatchedPath.path + "/create",
                icon: faPlus
            });
        }

        setSubmenuItems(submenuItems);

        return () => {
            setSubmenuTitle("");
            setSubmenuItems([]);
        };
    }, []);

    return (
        <div>
            <Switch>
                <Route exact path={`${match.url}`} component={AllTeams} />
                {hasCreatePermission() && <Route path={`${match.url}/create`} component={CreateTeam} />}
            </Switch>
        </div>
    );
};

export default Teams;
