import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Col, Image, Row} from "react-bootstrap";
import UserListContext from "../../../context/list/UserListContext";
import DateFormatter from "../../../utils/formatters/DateFormatter";
import {Timeline, TimelineEvent} from "react-event-timeline";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderOpen} from "@fortawesome/free-solid-svg-icons";
import EventType from "./EventType";
import axios from "axios";
import {frontEndHostName, IQBLADE_HOSTNAME} from "../../../utils/Configuration";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import {OrganisationUser} from "../../teams/edit/EditTeam";
import translate from "../../../i18n/translate";

export interface ListEventOrganisation {
    id: number,
    companyNumber: string,
    companyName: string,
    imageUrl: string
}

export interface ListEvent {
    id: string,
    listId: number,
    listName: string,
    user: OrganisationUser,
    description: string,
    type: string,
    created: number,
    advancedSearchId: number,
    advancedSearchName: string,
    organisationList: ListEventOrganisation[]
}

const ListActivity: React.FC = () => {
    const {listId} = useParams<any>();
    const {list, setList} = useContext(UserListContext);
    const [loading, setLoading] = useState<boolean>(false);

    const [events, setEvents] = useState<ListEvent[]>([]);

    const successHandler = (events: ListEvent[]) => {
        setEvents(events);
        setLoading(false);
    };

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            setLoading(true);

            await axios.get(frontEndHostName + "user-lists/" + listId + "/dashboard/list-events")
                .then(r => (isMounted && successHandler(r.data)))
                .catch(error => console.error(error.message))
                .finally(() => setLoading(false));
        };

        fetchData();

        return () => {
            isMounted = false;
        }
    }, []);

    const CompanyLogo: React.FC<ListEventOrganisation> = (data: ListEventOrganisation) => {
        const [src, setSrc] = useState<string>(data.imageUrl ? data.imageUrl : "./icons_company.svg");

        return (
            <Image title={data.companyName}
                   style={{
                       height: "30px",
                       width: "30px",
                       objectFit: "cover"
                   }}
                   src={src}
                   onError={() => setSrc("/icons_company.svg")}
                   roundedCircle/>
        )
    };

    return (
        <div>
            {loading ? <div style={{paddingTop: 35}}><IqLoadingIcon /></div> : (
                <div>
                    <Timeline>
                        {events
                            .map((e: ListEvent) => {
                                let eventType = EventType.ALL_TYPES[e.type];

                                return (
                                    <>
                                    <TimelineEvent key={e.id}
                                                   title={translate(eventType.displayName, { firstname: e.user.firstName, lastname: e.user.lastName})}
                                                   titleStyle={{fontWeight: "bold", fontSize: 14}}
                                                   subtitle={[(<>Sourced from: </>), e.advancedSearchId ? <a href={IQBLADE_HOSTNAME + "/advanced-search/" + e.advancedSearchId}>{e.advancedSearchName}</a> : "Quick Search"]}
                                                   createdAt={DateFormatter.formatMillis(e.created)}
                                                   icon={eventType.icon}
                                                   contentStyle={{borderRadius: 10}}
                                                   style={{paddingBottom: "3%"}}>

                                        {e.organisationList.map((org: ListEventOrganisation) => (
                                                <Row style={{padding: "0.5%"}}
                                                     key={org.id}>
                                                    <Col xs={1}>
                                                        <CompanyLogo {...org}/>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <a href={`/organisation/${org.id}`}>
                                                            {org.companyName}
                                                        </a>
                                                    </Col>
                                                </Row>
                                            )
                                        )}
                                    </TimelineEvent>
                                    </>
                                )
                            }
                        )}
                        <TimelineEvent title={translate("lists.listcreated")}
                                       titleStyle={{fontWeight: "bold", fontSize: 14}}
                                       contentStyle={{borderRadius: 10}}
                                       createdAt={DateFormatter.formatDate(list.createdOn)}
                                       icon={<FontAwesomeIcon icon={faFolderOpen} color="green" size="1x" /> }>
                            {translate("lists.createdby", { username: list.userDisplayName })}
                        </TimelineEvent>
                    </Timeline>
                </div>
            )}
        </div>
    );
};

export default ListActivity;
