import organisation_ro from "./organisation_ro";
import notifications_ro from "./notifications_ro";

export default {
    lang: "English",
    signout: "Sign Out",
    signingout: "Signing Out",
    months: {
        jan: "January",
        feb: "February",
        mar: "March",
        apr: "April",
        may: "May",
        jun: "June",
        jul: "July",
        aug: "August",
        sep: "September",
        oct: "October",
        nov: "November",
        dec: "December"
    },
    locations: {
        UK: "United Kingdom",
        FR: "France",
        DE: "Germany",
        US: "United States"
    },
    onboarding: {
        welcome: "Welcome {name}!",
        vendors: "We found the following vendors for your organisation. Would you like to follow them?",
        continue: "Continue",
        typeandselect: "Type and select"
    },
    nav: {
        filters: "Filters",
        recentsearches: "Recent Searches"
    },
    errors: {
        loading: "Error loading. Please contact support.",
        generic: "An error occured, please try again."
    },
    login: {
        signin: "Login",
        subtitle: "Enter your details below to continue",
        email: "Email",
        password: "Password",
        forgotpassword: "Forgot Password?",
        rememberme: "Remember me",
        recoverpassword: "Recover your password",
        enteremail: "Enter your email below to recover your password",
        emailsent: "Recovery mail has been sent",
        backtologin: "Go back to login",
        error: {
            incorrectpassword: "Your password is incorrect",
            maxfailedattempts: "You have exceeded your maximum failed login attempts and have been locked out. Please contact support.",
            notactivatedpart1: "User is not activated, please contact our Customer Success Team ",
            notactivatedpart2: " at IQBlade to activate",
            concurrentsession: "User {user} is already logged in elsewhere.",
            endsession: "Login on this browser instead?",
            confirmendsession: "Login here",
            other: "There are problems with authentication. Try again."
        }
    },
    dashboard: {
        additem: "Add Item",
        addnewitem: "Add a new item to your dashboard",
        close: "Close",
        add: "Add",
        viewall: "View All",
        view: "View",
        profile: {
            title: "My Profile",
            welcome: "Welcome back, {name}!",
            listscreated: "{count} lists created",
            listcompanies: "{count} companies in lists",
            searchcredits: "{count} search credits consumed",
            followedorganisations: "{count} followed organisations",
            followedofficers: "{count} followed officers",
            contactrequests: "{count} contact request credits consumed",
            creditrefresh: "Refreshes in {days} days"
        },
        followedorganisations: {
            title: "Followed Organisations",
            shownames: "Show Company Names"
        },
        twitter: {
            title: "X",
            filteredTitle: "X Filtered on Terms",
            followed: "Followed",
            all: "All",
            organisation: "Organisation",
            officer: "Officer",
            popoverTitle: "Load Terms",
            popoverMessage: "Saved interests will be loaded"
        },
        globalnews: {
            title: "Global News",
        },
        followednews: {
            title: "My News"
        },
        filinghistory: {
            title: "Filing History"
        },
        calendar:{
            title: "My Calendar Events"
        },
        videos: {
            title: "Latest Youtube Videos",
            filteredTitle: "Latest Youtube Videos Filtered By Terms",
        },
        followedorganisationsyearend: {
            title: "Followed Companies with Upcoming Year End",
            limit: "{count} companies"
        },
        welcome: {
            title: "Welcome to your dashboard"
        }
    },
    news: {
        title: "News"
    },
    lists: {
        title: "Lists",
        createdby: "Created by {username}",
        createdon: "Created on {date}",
        companies: "{count} companies",
        yours: "Private",
        jobs: "Jobs",
        requestjobs: "Request Jobs",
        shared: "Shared",
        viewlist: "View List",
        deletelist: "Delete List",
        companynumber: "Company Number",
        companyname: "Company Name",
        established: "Established",
        employees: "Employees",
        turnover: "Turnover",
        growth: "Growth",
        decline: "Decline",
        companytype: "Company Type",
        companylegaltype: "Company Legal Type",
        deleteselected: "Delete Selected",
        selectall: "Select All",
        deselectall: "Deselect All",
        overview: "Overview",
        dashboard: "Dashboard",
        contacts: "Contacts",
        listactivity: "List Activity",
        averagegrossprofit: "Average Gross Profit",
        averagerevenue: "Average Revenue",
        averageemployees: "Average Employees",
        distributionbyrevenue: "Distribution By Revenue",
        financial: "Financial",
        classification: "Classification",
        listcreated: "List Created",
        createlist: "Create List",
        transferlist: "Transfer List",
        exportselected: "Export Selected",
        exportlinkedin: "Export For LinkedIn",
        exportPopoverTitle: "Higher Role Permission Needed",
        exportPopoverMessage:"Please subscribe to a Discovery role, to access this feature.",
        freemiumPopoverTitle: "Upgrade to a paid account",
        freemiumPopoverMessage:"Please, upgrade to a paid account to see full list",
        exportfailed: "Unable to export list",
        rowsselected: "{count} rows selected",
        deleting: "Deleting...",
        requestcontacts: "Request Contacts",
        exportcontacts: "Export Contacts",
        top5revenue: "Top 5 Revenue",
        top5growth: "Top 5 - 3 Year Growth",
        top5decline: "Top 5 - 3 Year Decline",
        top5growth1year: "Top 5 - 1 Year Growth",
        top5decline1year: "Top 5 - 1 Year Decline",
        deleteconfirm: "Are you sure you want to delete {title}?",
        deleteconfirmtitle: "Are you sure?",
        confirmdelete: "Yes, delete it!",
        deletesuccess: "Successfully deleted!",
        deletefailed: "Unable to delete list",
        deletecompaniesfailed: "Unable to delete companies",
        deletecompaniesfailedcampaignlsit: "You cannot delete companies from this list because it is being used in a campaign.",
        deletefailedcampaignlist: "You cannot delete this list because it is being used in a campaign.",
        companiesadded: "Companies added by {firstname} {lastname}",
        companiesremoved: "Companies removed by {firstname} {lastname}",
        comment: "Comment",
        next: "Next in List",
        previous: "Previous in List"
    },
    teams: {
        title: "Teams",
        allteams: "All Teams",
        createteam: "Create Team",
        main: {
            createdby: "Created by {username}",
            createdon: "Created on {date}",
            members: "{count} members",
            viewteam: "View Team",
            manage: "Manage",
            deleteteam: "Delete Team",
            backtoteams: "Back to Teams"
        },
        common: {
            username: "Username",
            total: "Total",
            searches: "Searches",
        },
        overview: {
            title: "Overview",
            lists: "Lists",
            recentactivity: "Recent Activity",
            addedcompanies: "added companies to",
            removedcompanies: "removed companies from",
            commentedon: "commented on"
        },
        logins: {
            title: "Logins",
            loginactivity: "Login Activity",
            totallogins: "Total Logins",
            lastloggedin: "Last Logged In",
            time: "Time",
            usercreatedon: "User Created On",
        },
        lists: {
            user: "User",
            listevents: "List Events",
            listname: "List Name",
            eventtype: "Event Type",
            date: "Date",
            sourced: "Sourced from",
            description: "Description",
            organisations: "Organisations",
            totallists: "Total Lists",
            totalcompaniesinlists: "Total Companies in Lists",
            totalfollowedcompanies: "Total Followed Companies",
            companiesadded: "Companies added",
            companiesremoved: "Companies removed",
            comment: "Comment"
        },
        searches: {
            advancedsearchesshared: "Advanced Searches shared",
            lastrun: "Last Run",
            search: "Search",
            searchcreditusage: "Search Credit Usage",
        },
        manage: {
            teamdetails: "Team Details",
            teamname: "Team Name",
            usersinteam: "Users in Team",
            savechanges: "Save Changes",
            addmembers: "Add Members",
            addmember: "Add Member",
            removemember: "Remove Member",
            promote: "Promote to admin",
            demote: "Remove as admin"
        }
    },
    advancedsearch: {
        title: "Advanced Search",
        name: "Give your search a name",
        confirmtitle: "Are you sure?",
        confirm: "Creating a new search will consume credits. Are you sure that you want to continue?",
        unappliedtitle: "You have unsaved changes!",
        unappliedfilters: "You have some unsaved filters in your configuration. Are you sure you want to continue?",
        goback: "Go back",
        search: "Search",
        enduser: "End User",
        channel: "Channel",
        quickfilters: "Pre-defined filters",
        searchtype: "Search Type",
        remainingcredits: "Credits Remaining",
        companiesfound: "Companies Found",
        employees: "Employees",
        established: "Year Established",
        hostingproviders: "Hosting Providers",
        locations: "Locations",
        country: "Country",
        reset: "Reset",
        morefilters: "Custom filters",
        createdon: "Created on {date}",
        expiringon: "Expiring on {date}",
        expiredon: "Expired on {date}",
        to: "to",
        new: "New Search",
        reuse: "Reuse",
        rerun: "Rerun",
        delete: "Delete",
        view: "View Search",
        expired: "This search expired on {date}. Please re-run the search to view results.",
        rerunconfirm: "When you rerun a search, credit will be consumed and expiration date extended.",
        createsuccess: "Your search has been created",
        perpage: "{count} per page",
        sort: {
            relevance: "Relevance",
            nameasc: "Company Name A-Z",
            namedesc: "Company Name Z-A",
            revenueasc: "Revenue: Low-High",
            revenuedesc: "Revenue: High-Low",
            investmentasc: "Investment Raised: Low-High",
            investmentdesc: "Investment Raised: High-Low",
            employeesasc: "Employees: Low-High",
            employeesdesc: "Employees: High-Low"
        },
        vendors: "Vendors",
        products: "Products",
        location: "Location (NUTS)",
        tags: "Tags",
        accreditations: "Accreditations",
        competencies: "Competencies",
        investmentraised: "Investment raised"
    },
    organisation: organisation_ro,
    contracts: {
        nav: "Public Sector",
        title: "Public Sector Contracts",
        notfound: "No contracts found",
        back: "Back to Contracts",
        contracttitle: "Title",
        organisation: "Organisation",
        startdate: "Start Date",
        enddate: "End Date",
        publisheddate: "Published Date",
        awarded: "Awarded",
        value: "Value",
        suppliername: "Supplier Name",
        description: "Description",
        awardedsupplier: "Awarded Supplier",
        estimatedvalue: "Estimated Total Value",
        follow: "Follow",
        followed: "Followed",
        showfollowed: "Only show followed",
        status: "Status",
        tender: "Tender",
        contractawarded: "Contract Awarded",
        unsuccessful: "Unsuccessful",
        buyers: "Buyers",
        suppliers: "Suppliers",
        nomin: "No min",
        nomax: "No max",
        cpvcodes: "CPV Codes",
        save: "Save",
        clearfilters: "Clear Filters",
        filtername: "Filter Name",
        contractsfound: "Contracts found",
        create: "Create Filter",
        searchterm: "Search Term"
    },
    campaigns: {
        nav: "Campaigns",
        manager: "Campaigns Manager",
        title: "Campaigns"
    },
    marketintelligence: {
        title: "Market Intelligence",
        abbr: "M&A",
        investments: "Investments",
        appointments: {
            title: "Appointments",
            name: "Name",
            position: "Position"
        },
        sic: {
            title: "Industry Analysis",
            header: "Active Companies by Industry",
            category: "Category",
            description: "Description",
            date: "Date",
            count: "Count",
            prev: "Previous Month",
            variance: "Variance",
            pctchange: "% Change"
        },
        marketintelligence: {
            title: "Market Intelligence",
            name: "Name",
            position: "Position"
        }
    },
    contacts: {
        title: "Contacts",
        employment: "Employment",
        education: "Education",
        discoveredon: "Discovered on",
        lastmodified: "Last modified",
        noemployment: "Contact has no employment history.",
        noeducation: "Contact has no education history.",
        sort: {
            sortby: "Sort by...",
            datediscoveredasc: "Date Discovered - Ascending",
            datediscovereddesc: "Date Discovered - Descending",
            lastnameasc: "Last Name - Ascending",
            lastnamedesc: "Last Name - Descending",
            firstnameasc: "First Name - Ascending",
            firstnamedesc: "First Name - Descending",
            jobtitleasc: "Job Title - Ascending",
            jobtitledesc: "Job Title - Descending"
        },
        filter: {
            filters: "Filters",
            jobtitles: "Job Titles",
            jtplaceholder: "Input your job titles",
            seniority: "Seniority",
            seniorityplaceholder: "Input desired seniority",
            roles: "Roles",
            roleplaceholder: "Input desired roles",
            country: "Country",
            countryplaceholder: "Input desired countries",
            alllists: "All lists",
            apply: "Apply Filters"
        },
        nocontacts: {
            imported: "Unfortunately, we don't have contact data for users in your organisation.",
            organisation: "Unfortunately, we don't have contact data for this organisation.",
            lists: "Unfortunately, we don't have contact data for this list."
        }
    },
    techsales: {
        nav: "Our TD SYNNEX Sales",
        month: "Sales Over Time",
        calendarmonth: "Calendar Month",
        manufacturer: "Sales By Manufacturer",
        category: "Sales By Category",
        enduser: "Sales By End User"
    },
    renewals: {
        nav: "Renewals"
    },
    profile: {
        changepassword: "Change password",
        currentpassword: "Current Password",
        inputpassword: "Input your current password",
        newpassword: "New Password",
        enternewpassword: "Enter new password",
        repeatpassword: "Repeat password",
        repeatnewpassword: "Repeat new password",
        avatartwitter: "Upload avatar from my X profile",
        avatardevice: "Upload avatar from my device",
        chooseavatar: "Choose avatar",
        social: {
            twitter: "X (only the handle/tag)"
        }
    },
    notifications: notifications_ro
}
