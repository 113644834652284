import React, {useState} from "react";
import {Tab, Tabs} from "react-bootstrap";
import {Aggregates, TopData} from "./OfficerSocial";
import './TwitterTopData.css'

enum Keys {
    ONE_MONTH = "1M",
    THREE_MONTHS = "3M",
    SIX_MONTHS = "6M"
}

interface Props {
    id: string;
    prefix: string;
    aggregates: Aggregates;
}

const TwitterTopData: React.FC<Props> = (props: Props) => {

    const [key, setKey] = useState<string>(Keys.ONE_MONTH);

    const sort = (interval: TopData[]) => {
        return interval.sort((a: TopData, b: TopData) => b.count - a.count);
    };

    const getLink = (text: string) => {
        if (props.prefix === "#") {
            return `https://twitter.com/hashtag/${text}?src=hashtag_click`
        }
        else {
            return `https://twitter.com/${text}`;
        }
    };
    return (
        <Tabs id={props.id}
            defaultActiveKey={Keys.ONE_MONTH}
            onSelect={(k: string | null) => setKey(k || Keys.ONE_MONTH)}>
            <Tab eventKey={Keys.ONE_MONTH} title={Keys.ONE_MONTH}>
                <div className="mt-3 pt-3">
                    {sort(props.aggregates.oneMonth).map(item => (
                        <div className="mb-3" title={`${props.prefix}${item.text} - ${item.count}`}>
                            <span className="top-data">
                                <a className="text"
                                   href={getLink(item.text)}
                                   target="_blank">
                                    {props.prefix}{item.text}
                                </a>
                                <span className="count">
                                    {item.count}
                                </span>
                            </span>
                        </div>
                    ))}
                </div>
            </Tab>
            <Tab eventKey={Keys.THREE_MONTHS} title={Keys.THREE_MONTHS}>
                <div className="mt-3 pt-3">
                    {sort(props.aggregates.threeMonth).map(item => (
                        <div className="mb-3" title={`${props.prefix}${item.text} - ${item.count}`}>
                            <span className="top-data">
                                <a className="text"
                                   href={getLink(item.text)}
                                   target="_blank">
                                    {props.prefix}{item.text}
                                </a>
                                <span className="count">
                                    {item.count}
                                </span>
                            </span>
                        </div>
                    ))}
                </div>
            </Tab>
            <Tab eventKey={Keys.SIX_MONTHS} title={Keys.SIX_MONTHS}>
                <div className="mt-3 pt-3">
                    {sort(props.aggregates.sixMonth).map(item => (
                        <div className="mb-3" title={`${props.prefix}${item.text} - ${item.count}`}>
                            <span className="top-data">
                                <a className="text"
                                   href={getLink(item.text)}
                                   target="_blank">
                                    {props.prefix}{item.text}
                                </a>
                                <span className="count">
                                    {item.count}
                                </span>
                            </span>
                        </div>
                    ))}
                </div>
            </Tab>
        </Tabs>
    );
};

export default TwitterTopData;
