import React, {useState} from "react";
import {Col, Form, Modal, Row} from "react-bootstrap";
import translate from "../../i18n/translate";
import Select from "react-select";
import {SelectOption} from "../news/GlobalNews";
import {format} from "d3-format";
import {RenewalsFilters} from "../../model/renewals/RenewalsFilters";

interface Props {
    setFilters: (f: RenewalsFilters | null) => void,
    initialFilters?: RenewalsFilters | null,
    vendorOptions: string[]
    endUserOptions: string[]
}

const RenewalsFiltersModal: React.FC<Props> = (props: Props) => {

    const statusOptions: SelectOption[] = [
        {value: "Active", label: "Active"} as SelectOption,
        {value: "Due", label: "Due"} as SelectOption,
        {value: "Urgent", label: "Urgent"} as SelectOption,
        {value: "Scheduled", label: "Scheduled"} as SelectOption
    ];

    const valueOptions = [1, 500, 1000, 2500, 5000, 10000, 25000, 50000, 100000];

    const [endDateTo, setEndDateTo] = useState<string | undefined>(props.initialFilters?.endDateTo);
    const [endDateFrom, setEndDateFrom] = useState<string | undefined>(props.initialFilters?.endDateFrom);
    const [status, setStatus] = useState<string[] | undefined>(props.initialFilters?.status);
    const [vendors, setVendors] = useState<string[] | undefined>(props.initialFilters?.vendors);
    const [endUsers, setEndUsers] = useState<string[] | undefined>(props.initialFilters?.endUsers);
    const [valueFrom, setValueFrom] = useState<number | undefined>(props.initialFilters?.valueFrom);
    const [valueTo, setValueTo] = useState<number | undefined>(props.initialFilters?.valueTo);

    const clearFilters = () => {
        props.setFilters(null);
    }

    const applyFilters = () => {
        const filters = {
            endDateFrom: endDateFrom,
            endDateTo: endDateTo,
            status: status,
            vendors: vendors,
            endUsers: endUsers,
            valueFrom: valueFrom,
            valueTo: valueTo
        }

        props.setFilters(filters);
    }

    const updateStatus = (selection: SelectOption[]) => {
        setStatus(selection?.map((item: SelectOption) => item.value));
    }

    const updateVendors = (selection: SelectOption[]) => {
        setVendors(selection?.map((item: SelectOption) => item.value));
    }

    const updateEndUsers = (selection: SelectOption[]) => {
        setEndUsers(selection?.map((item: SelectOption) => item.value));
    }

    const updateMinimumValue = (e: any) => {
        let val = e.target.value;
        if (val === "No min") {
            setValueFrom(undefined);
            return;
        }

        setValueFrom(val);
    }

    const updateMaximumValue = (e: any) => {
        let val = e.target.value;
        if (val === "No max") {
            setValueTo(undefined);
            return;
        }

        setValueTo(val);
    }

    return (
        <>
            <Modal.Header closeButton>
                <span className="pe-2">{translate("contacts.filter.filters")}</span>
            </Modal.Header>
            <Modal.Body className="p-4">
                <Row className="mb-2">
                    <Col className="pt-2 col-3">
                        <span className="organisation-card-label">
                            Agreement End Date
                        </span>
                    </Col>
                    <Col className="col-4">
                        <input type="date" className="form-control" value={endDateFrom} onChange={(e: any) => setEndDateFrom(e.target.value)}/>
                    </Col>
                    <Col className="text-center pt-2 col-1">
                        <span>{translate("advancedsearch.to")}</span>
                    </Col>
                    <Col className="col-4">
                        <input type="date" className="form-control" value={endDateTo} onChange={(e: any) => setEndDateTo(e.target.value)}/>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="pt-2 col-3">
                        <span className="organisation-card-label">
                            {translate("contracts.status")}
                        </span>
                    </Col>
                    <Col className="col-9">
                        <Select isMulti
                                isDisabled={false}
                                options={statusOptions}
                                value={status?.map((s: string) => {
                                    return {
                                        value: s,
                                        label: s
                                    }
                                })}
                                onChange={(e: any) => updateStatus(e)}
                                placeholder={translate("contracts.status")}
                                classNamePrefix="select" />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="pt-2 col-3">
                        <span className="organisation-card-label">
                            {translate("advancedsearch.vendors")}
                        </span>
                    </Col>
                    <Col className="col-9">
                        <Select isMulti
                                isDisabled={false}
                                options={props.vendorOptions.map(opt => {
                                    return {
                                        value: opt,
                                        label: opt
                                    }
                                })}
                                value={vendors?.map((s: string) => {
                                    return {
                                        value: s,
                                        label: s
                                    }
                                })}
                                onChange={(e: any) => updateVendors(e)}
                                placeholder={translate("advancedsearch.vendors")}
                                classNamePrefix="select" />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="pt-2 col-3">
                        <span className="organisation-card-label">
                            {translate("advancedsearch.enduser")}
                        </span>
                    </Col>
                    <Col className="col-9">
                        <Select isMulti
                                isDisabled={false}
                                options={props.endUserOptions.map(opt => {
                                    return {
                                        value: opt,
                                        label: opt
                                    }
                                })}
                                value={endUsers?.map((s: string) => {
                                    return {
                                        value: s,
                                        label: s
                                    }
                                })}
                                onChange={(e: any) => updateEndUsers(e)}
                                placeholder={translate("advancedsearch.enduser")}
                                classNamePrefix="select" />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="pt-2 col-3">
                        <span className="organisation-card-label">{translate("contracts.value")}</span>
                    </Col>
                    <Col className="col-4">
                        <Form.Select className="form-control" value={valueFrom} onChange={updateMinimumValue}>
                            <option value={undefined}>{translate("contracts.nomin")}</option>
                            {
                                valueOptions
                                    .filter((option: number) => !valueTo || option < valueTo)
                                    .map((opt: number) => (
                                        <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                    ))
                            }
                        </Form.Select>
                    </Col>
                    <Col className="text-center pt-2 col-1">
                        <span>{translate("advancedsearch.to")}</span>
                    </Col>
                    <Col className="col-4">
                        <Form.Select className="form-control" value={valueTo} onChange={updateMaximumValue}>
                            <option value={undefined}>{translate("contracts.nomax")}</option>
                            {
                                valueOptions
                                    .filter((option: number) => (!valueFrom) || option > (valueFrom || 0))
                                    .map((opt: number) => (
                                        <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                    ))
                            }
                        </Form.Select>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button className="iqx-button sm-size"
                        onClick={clearFilters}>
                    {translate("contracts.clearfilters")}
                </button>
                <button className="iqx-button primary sm-size"
                        onClick={applyFilters}>
                    {translate("contacts.filter.apply")}
                </button>
            </Modal.Footer>
        </>
    );
}

export default RenewalsFiltersModal;