import React, {useEffect, useState} from 'react';
import './AdvancedSearchList.css'
import {Alert, Col, Container, Row} from 'react-bootstrap';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import AdvancedSearch from '../../model/advanced-search/AdvancedSearch';
import {searchHostname} from '../../utils/Configuration';
import IqLoadingIcon from '../common/IqLoadingIcon';
import translate from "../../i18n/translate";
import AdvancedSearchItem from './AdvancedSearchItem';
import {
    showErrorWithMessage,
    showGenericErrorDialog,
    showGenericSuccessDialog,
    showSuccessWithMessage
} from "../common/CommonAlerts";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

interface Props {
}

const AdvancedSearchList: React.FC<Props> = (props: Props) => {

    let history = useHistory();

    const [searches, setSearches] = useState<AdvancedSearch[]>([]);
    const [changeOccured, setChangeOccured] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    interface DeletePayload {
        show: boolean,
        searchId: number
    }

    interface RerunPayload {
        show: boolean,
        searchId: number
    }

    interface GenericPayload {
        show: boolean,
        message: string,
        success: boolean
    }

    useEffect(() => {
        setLoading(true);
        const fetchSearches = async () => {
            const result = await axios.get(searchHostname + 'advanced-search')
                .then((response) => {
                    setSearches(response.data);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };
        fetchSearches();
    }, [changeOccured]);


    const onDelete = (searchId: number) => {

        axios.delete(searchHostname + 'advanced-search/' + searchId).then((response) => {
            if (response.status === 200) {
                setChangeOccured(!changeOccured);
                showGenericSuccessDialog();
            }
        }).catch(error => console.log(error.message));
    };

    const onRerun = (searchId: number) => {
        axios.post(searchHostname + 'advanced-search/' + searchId + '/rerun').then((response) => {
            if (response.status === 200) {
                setChangeOccured(!changeOccured);
                showSuccessWithMessage("Success!", "We have rerun your search");
            }
        }).catch(error => {
            if (error.response?.data?.errorKey === "nocredit") {
                showErrorWithMessage("Error", "No search credits left.")
            } else {
                showGenericErrorDialog();
            }
        });
    };


    const showConfirmDelete = (search: AdvancedSearch) => {
        const ConfirmDeleteDialog = withReactContent(Swal);
        ConfirmDeleteDialog.fire({
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: translate("lists.confirmdelete"),
                title: translate("lists.deleteconfirmtitle"),
                text: translate("lists.deleteconfirm", {title: search.name}),
                focusCancel: true
            })
            .then(result => {
                if (result.isConfirmed) {
                    onDelete(search.id);
                }
            })
    }

    const showRerunConfirm = (search: AdvancedSearch) => {
        const RerunConfirmDialog = withReactContent(Swal);
        RerunConfirmDialog.fire({
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: translate("advancedsearch.rerun"),
                title: translate("lists.deleteconfirmtitle"),
                focusCancel: true,
                text: translate("advancedsearch.rerunconfirm")
            })
            .then(result => {
                if (result.isConfirmed) {
                    onRerun(search.id);
                }
            });
    }

    return (
        <Container className="advanced-search-container" fluid style={{ marginTop: 15 }}>
            <Row className="" style={{ marginLeft: 0, marginRight: 0 }}>
                <Col>
                    <div className="iq-headline"><span className="text">{translate("advancedsearch.title")}</span></div>
                </Col>
                <Col className="text-end">
                    <span style={{ marginLeft: "auto" }}>
                        <button onClick={() => { history.push("/advanced-search/create") }} className="iqx-button md-size dark-blue">
                            <span style={{ paddingRight: 10 }}>{translate("advancedsearch.new")}</span>
                            <FontAwesomeIcon icon={faSearch} color="white" size="1x" />
                        </button>
                    </span>
                </Col>
            </Row>
            {loading ? <IqLoadingIcon /> : (
                error ?
                    <Alert className="mt-2" variant="danger">
                        {translate("errors.loading")}
                    </Alert>
                    :
                    <>
                        <div id="adv-search-list-container">
                            {
                                searches
                                    .map(search => (
                                        <AdvancedSearchItem search={search} setShowDelete={() => showConfirmDelete(search)} setShowRerun={() => showRerunConfirm(search)} />
                                    ))
                            }
                        </div>
                    </>
            )}
        </Container>
    );
}

export default AdvancedSearchList;
